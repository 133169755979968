import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'toggle-button-modal',
  templateUrl: './toggle-button-modal.component.html',
  styleUrls: ['./toggle-button-modal.component.scss'],
})
export class ToggleButtonModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ToggleButtonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onClose(isCompatible: boolean = null): void {
    this.dialogRef.close(isCompatible === null ? this.data.buttonToggle : isCompatible);
  }
}
