import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of, throwError } from 'rxjs';
import { CredentialsService } from './credentials.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/shared/services/api.service';
import { map, catchError } from 'rxjs/operators';
import { LoginTokenResponse } from '@app/shared/models/loginResponse';
import { Logger } from './logger.service';
import { Functions } from '@app/shared/functions';
import { Constants } from '@app/shared/constants';
import { Credentials } from '@app/shared/models/credentials';
import { IResponseAPI } from '@app/shared/models/api-response';
import { MedicationService } from '@app/shared/services/medication.service';
import { AuthenticatedUser } from '@app/shared/models/authenticated-user';
import { LoginContext } from '@app/shared/models/login-context';
import { CompletePasswordResetDTO } from '@app/shared/models/completePasswordResetDTO';
import { ResetPasswordDTO } from '@app/shared/models/resetPasswordDTO';
import { TokenLoginContext } from '@app/shared/models/token-login-context';
import { environment } from '@env/environment';
import { InitiateVerifyMobilePhoneDTO } from '@src/app/shared/models/initiateVerifyMobilePhoneDTO';
import { CompleteVerifyMobilePhoneDTO } from '@src/app/shared/models/completeVerifyMobilePhoneDTO';
import { CompleteVerifyEmailViaTokenDTO } from '@src/app/shared/models/completeVerifyEmailViaTokenDTO';
import { InitiateVerifyEmailDTO } from '@src/app/shared/models/initiateVerifyEmailDTO';
import { CompleteVerifyEmailDTO } from '@src/app/shared/models/completeVerifyEmailDTO';
import { InitiateVerifyEmailViaTokenDTO } from '@src/app/shared/models/initiateVerifyEmailViaTokenDTO';
import { InitiateVerifyEmailSignupDTO } from '@src/app/shared/models/initiateVerifyEmailSignupDTO';
import { APIResponseError } from '@src/app/shared/models/error-handling/api-response-error';

const log = new Logger('AuthenticationService');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly transferTokenUrl: string = 'Auth/transfer-login';
  private readonly tokenUrl: string = 'Auth/login';
  private readonly removeTokenUrl: string = 'Auth/logout';
  private readonly refreshUrl: string = 'Auth/refreshtoken';
  private readonly forgotUrl: string = 'Auth/forgot';
  private readonly initiateResetUrl: string = 'Auth/reset-password/initiate';
  private readonly completeResetUrl: string = 'Auth/reset-password/complete';
  private readonly initiateVerifyEmailViaTokenUrl: string = 'Auth/verifyemailviatoken/initiate';
  private readonly completeVerifyEmailViaTokenUrl: string = 'Auth/verifyemailviatoken/complete';
  private readonly initiateVerifyEmailSignupUrl: string = 'Auth/verifyemailsignup/initiate';
  private readonly initiateVerifyEmailUrl: string = 'Auth/verifyemail/initiate';
  private readonly completeVerifyEmailSignupUrl: string = 'Auth/verifyemailsignup/complete';
  private readonly completeVerifyEmailUrl: string = 'Auth/verifyemail/complete';
  private readonly initiateVerifyMobilePhoneUrl: string = 'Auth/verifymobilephone/initiate';
  private readonly completeVerifyMobilePhoneUrl: string = 'Auth/verifymobilephone/complete';
  private readonly apiBase: string = `${environment.apiBaseUrl}${Constants.EndPoint_Prefix}`;

  static notVerifiedText: string = 'Account is not verified';

  private get transferLoginUrl(): string {
    return `${this.apiBase}/${this.transferTokenUrl}`;
  }
  private get loginUrl(): string {
    return `${this.apiBase}/${this.tokenUrl}`;
  }
  private get logoutUrl(): string {
    return `${this.apiBase}/${this.removeTokenUrl}`;
  }
  private get refreshTokenUrl(): string {
    return `${this.apiBase}/${this.refreshUrl}`;
  }
  private get resetPasswordUrl(): string {
    return `${this.apiBase}/${this.forgotUrl}`;
  }
  private get initiatePasswordResetUrl(): string {
    return `${this.apiBase}/${this.initiateResetUrl}`;
  }
  private get completePasswordResetUrl(): string {
    return `${this.apiBase}/${this.completeResetUrl}`;
  }
  private get fullInitiateVerifyEmailSignupUrl(): string {
    return `${this.apiBase}/${this.initiateVerifyEmailSignupUrl}`;
  }
  private get fullInitiateVerifyEmailUrl(): string {
    return `${this.apiBase}/${this.initiateVerifyEmailUrl}`;
  }
  private get fullCompleteVerifyEmailSignupUrl(): string {
    return `${this.apiBase}/${this.completeVerifyEmailSignupUrl}`;
  }
  private get fullCompleteVerifyEmailUrl(): string {
    return `${this.apiBase}/${this.completeVerifyEmailUrl}`;
  }
  private get fullInitiateVerifyMobilePhoneUrl(): string {
    return `${this.apiBase}/${this.initiateVerifyMobilePhoneUrl}`;
  }
  private get fullCompleteVerifyMobilePhoneUrl(): string {
    return `${this.apiBase}/${this.completeVerifyMobilePhoneUrl}`;
  }

  private _mobilePhoneNumberVerified: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public mobilePhoneNumberVerified: Observable<string> = this._mobilePhoneNumberVerified.asObservable();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private functions: Functions,
    private credentialsService: CredentialsService,
    private medicationService: MedicationService
  ) {}

  /**
   * @function transferLogin
   * @description Authenticates the user using token string and saves user credentials
   *
   * @param {LoginTransferContext} context The login parameters (token string).
   *
   * @return {Observable<string>} response message
   */
  transferLogin(context: TokenLoginContext): Observable<string> {
    const associatedEmail: string = this.credentialsService.getEmailAddressAssociatedWithAccount();
    const result$: Observable<string> = this.apiService.post(this.transferLoginUrl, context).pipe(
      map(
        (res: IResponseAPI) => {
          let message: string = null;

          if (res?.success) {
            const result = res.response as LoginTokenResponse;

            const credentials: Credentials = {
              email: result.email || this.functions.parseEmailFromJWT(result.accessToken),
              accessToken: result.accessToken,
              refreshToken: result.refreshToken,
              expiresInSeconds: result.expiresInSeconds,
              isAdmin: true
            };

            if (associatedEmail && associatedEmail !== credentials.email) {
              // Clear the saved patientId from any previous logins, but only if the
              // stored email address associated with that patient is different to the login one
              this.removePatientData();
              this.logout();

              // In addition, clear any medications in the user's cart that may have
              // been retained from a different user's session
              this.medicationService.resetService();
            }

            this.credentialsService.setCredentials(credentials);
          } else {
            message = this.functions.getErrorMessage(res);
          }

          return message;
        },
        catchError((error: any) => {
          log.debug('Login error: ', error);
          return throwError(() => new Error('Login failed'));
        })
      )
    );

    return result$;
  }

  /**
   * @function login
   * @description Authenticates the user and saves user credentials
   *
   * @param {LoginContext} context The login parameters (email & password).
   *
   * @return {Observable<string>} response message
   */
  login(context: LoginContext): Observable<string> {
    // First, clear the saved patientId from any previous logins, but only if the
    // stored email address associated with that patient is different to the login one
    const associatedEmail: string = this.credentialsService.getEmailAddressAssociatedWithAccount();

    if (associatedEmail && associatedEmail !== context.email) {
      this.credentialsService.removePatientData();

      // In addition, clear any medications in the user's cart that may have
      // been retained from a different user's session
      this.medicationService.resetService();
    }

    const result$: Observable<string> = this.apiService.post(this.loginUrl, context).pipe(
      map(
        (res: IResponseAPI) => {
          let message: string = null;

          if (res.success) {
            const result = res.response as LoginTokenResponse;

            const credentials: Credentials = {
              email: context.email,
              accessToken: result.accessToken,
              refreshToken: result.refreshToken,
              expiresInSeconds: result.expiresInSeconds
            };
            this.credentialsService.setCredentials(credentials);
          } else {
            message = this.functions.getErrorMessage(res);
          }

          return message;
        },
        catchError((error: any) => {
          log.debug('Login error: ', error);
          return throwError(() => new Error('Login failed'));
        })
      )
    );

    return result$;
  }

  /**
   * @function logout
   * @description Clear user credentials and log the user out
   *
   * @return {Promise<boolean>} true or false response from the API
   */
  logout(): Promise<boolean> {
    if (this.credentialsService.isAuthenticated()) {
      const user: AuthenticatedUser = {
        clientID: this.getAccountHolderEmailAddress(),
        refreshToken: this.credentialsService.credentials.refreshToken
      };

      return this.apiService
        .post(this.logoutUrl, user)
        .toPromise()
        .then((res: IResponseAPI) => {
          if (res?.success) {
            this.resetAndLogin(true);
            return true;
          }
          return false;
        })
        .catch((error: any) => {
          // log.debug('Logout error: ', error);
          return false;
        });
    } else {
      this.resetAndLogin(true);
      return Promise.resolve(true);
    }
  }

  /**
   * @function logoutQuiet
   * @description Clear user credentials and log the user out if anyone is logged in. No redirects after.
   *
   * @return {Promise<boolean>} true or false response from the API
   */
  logoutQuiet(): Promise<boolean> {
    if (this.credentialsService.isAuthenticated()) {
      const user: AuthenticatedUser = {
        clientID: this.getAccountHolderEmailAddress(),
        refreshToken: this.credentialsService.credentials.refreshToken
      };

      this.apiService
        .post(this.logoutUrl, user)
        .toPromise()
        .then((res: IResponseAPI) => {
          if (res?.success) {
            this.removePatientData();
            this.removeAppointmentData();
            return true;
          }
          return false;
        })
        .catch((error: any) => {
          return false;
        });
    }

    return Promise.resolve(false);
  }

  getAccountHolderEmailAddress(): string {
    // Grab email from credentials service (in memory)
    let email: string = this.credentialsService.credentials?.email || null;

    // Otherwise, retrieve credentials from storage
    if (!email) {
      email = this.credentialsService.retrieveStoredCredentials();
    }

    // If no credentials are found, retrieve associated account email from storage
    if (!email) {
      email = this.credentialsService.getEmailAddressAssociatedWithAccount();
    }

    return email;
  }

  /**
   * @function removePatientData
   * @description Remove Patient related data from storage and credentials from memory
   */
  removePatientData(): void {
    this.credentialsService.removePatientData();
    this.credentialsService.removeCredentials();
  }

  /**
   * @function removeAppointmentData
   * @description Remove Appointment and Waiting room step service data from storage
   */
  removeAppointmentData(): void {
    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.appointment);
      sessionStorage.removeItem(Constants.LocalStorage_Key.waitingroom);
    } catch (_err: any) {}
  }

  /**
   * @function resetAndLogin
   * @description Clear user credentials and navigate to the login screen
   *
   * @param {boolean} [noRedirect=false] determines whether to add the current path as a redirect back from login
   */
  resetAndLogin(noRedirect: boolean = false): void {
    try {
      // Remove sign-up step data from session storage
      sessionStorage.removeItem(Constants.LocalStorage_Key.signup);
    } catch (_err: any) {}

    this.removePatientData();
    this.removeAppointmentData();

    // Remove any medications in the user's cart
    // this.medicationService.resetService();

    // Clear patient id in analytics
    // this.analytics.userId = null;

    this.functions.navigateToLogin(noRedirect);
  }

  /**
   * @function refreshToken
   * @description Get a new JWT token by posting the user's Email and refreshToken to the API
   * @param {string} refreshToken Not needed anymore as a parameter here since it's stored as an http only cookie
   *
   * @returns {Observable<any>}
   */
  refreshToken(refreshToken?: string): Observable<string | boolean | null> {
    const email: string = this.getAccountHolderEmailAddress();

    if (email) {
      return this.getNewTokenObs(email, refreshToken);
    } else {
      // return an observable
      return of(null);
    }
  }

  // POST /api/v1/Auth/forgot
  resetPassword(email: string): Promise<any> {
    const resetPasswordDTO: ResetPasswordDTO = {
      email
    };

    return this.http
      .post(this.resetPasswordUrl, resetPasswordDTO)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        return this.functions.handleError(err);
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifyemailviatoken/initiate
  initiateVerifyEmailViaToken(userId: string, agencyCode: string): Promise<boolean> {
    const body: InitiateVerifyEmailViaTokenDTO = {
      userId: userId,
      agencyCode
    };

    return this.http
      .post(this.initiateVerifyEmailViaTokenUrl, body)
      .toPromise()
      .then((response: IResponseAPI) => {
        return Boolean(response?.success);
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return false;
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifyemailviatoken/complete
  completeVerifyEmailViaToken(userId: string, token: string): Promise<boolean> {
    const model: CompleteVerifyEmailViaTokenDTO = {
      userId,
      token
    };

    return this.http
      .post(this.completeVerifyEmailViaTokenUrl, model)
      .toPromise()
      .then((response: IResponseAPI) => {
        return Boolean(response?.success);
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return false;
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifyemail/initiate
  async initiateVerifyEmailSignup(emailAddress: string): Promise<InitiateVerifyEmailSignupDTO> {
    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(
          `${this.fullInitiateVerifyEmailSignupUrl}?emailAddress=${encodeURIComponent(emailAddress)}`,
          null
        )
      );

      if (response?.success && response.response) {
        return {
          tempPatientId: response.response.tempPatientId,
          mfaEnrolmentId: response.response.mfaEnrolmentId
        };
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      this.showSendErrorToastEmail();
      console.error(
        `Failed to send code for email address "${emailAddress}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifyemailsignup/complete
  async completeVerifyEmailSignup(
    patientId: string,
    code: string,
    mfaEnrolmentId: string,
    emailAddress: string
  ): Promise<boolean> {
    const body: CompleteVerifyEmailDTO = {
      patientId: patientId,
      code: code,
      mfaEnrolmentId: mfaEnrolmentId
    };
    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(this.fullCompleteVerifyEmailSignupUrl, body)
      );

      if (response?.success && typeof response.response === 'boolean') {
        return response.response as boolean;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to verify email verification email code for "${emailAddress}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifyemail/initiate
  async initiateVerifyEmail(
    patientId: string,
    emailAddress: string,
    retryCount: number = 0,
    maxRetries: number = Constants.API_Polling_Attempts.patientVerificationMfaRetryCounts
  ): Promise<string> {
    const body: InitiateVerifyEmailDTO = {
      patientId: patientId,
      emailAddress: emailAddress
    };

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(this.fullInitiateVerifyEmailUrl, body)
      );

      if (response?.success && typeof response.response === 'string') {
        return response.response;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      const errorObj: APIResponseError = this.functions.handleAPIResponseError(err);

      const isServerError: boolean = err?.status === 500;

      const isMfaCooldownError: boolean =
        errorObj.errorCode === Constants.API_ERROR_CODES.MFA_ERROR &&
        errorObj.errorSubCode === Constants.MFA_ERROR_CODES.MFA_MEDIUM_IN_COOLDOWN;

      if (isServerError && isMfaCooldownError && retryCount < maxRetries) {
        // Retry after a 5-second delay
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            Constants.API_Polling_Times.patientVerificationMfaRetry_seconds * Constants.MILLISECONDS_IN_SECOND
          )
        );

        // Recursive call with increased retry count
        return this.initiateVerifyEmail(patientId, emailAddress, retryCount + 1, maxRetries);
      } else {
        this.showSendErrorToastEmail();
        console.error(
          `Failed to send code for email address "${emailAddress}". Error:`,
          this.functions.getErrorMessage(err),
          err
        );
        return null;
      }
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifyemail/complete
  async completeVerifyEmail(
    patientId: string,
    code: string,
    mfaEnrolmentId: string,
    emailAddress: string
  ): Promise<boolean> {
    const body: CompleteVerifyEmailDTO = {
      patientId: patientId,
      code: code,
      mfaEnrolmentId: mfaEnrolmentId
    };
    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(this.fullCompleteVerifyEmailUrl, body)
      );

      if (response?.success && typeof response.response === 'boolean') {
        return response.response as boolean;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to verify email verification email code for "${emailAddress}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifymobilephone/initiate
  async initiateVerifyMobilePhone(
    patientId: string,
    mobilePhoneNumber: string,
    retryCount: number = 0,
    maxRetries: number = Constants.API_Polling_Attempts.patientVerificationMfaRetryCounts
  ): Promise<string> {
    const body = new InitiateVerifyMobilePhoneDTO({
      patientId: patientId,
      mobilePhoneNumber: mobilePhoneNumber
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(this.fullInitiateVerifyMobilePhoneUrl, body)
      );

      if (response?.success && typeof response.response === 'string') {
        return response.response;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      const errorObj: APIResponseError = this.functions.handleAPIResponseError(err);

      const isServerError: boolean = err?.status === 500;

      const isMfaCooldownError: boolean =
        errorObj.errorCode === Constants.API_ERROR_CODES.MFA_ERROR &&
        errorObj.errorSubCode === Constants.MFA_ERROR_CODES.MFA_MEDIUM_IN_COOLDOWN;

      if (isServerError && isMfaCooldownError && retryCount < maxRetries) {
        // Retry after a 5-second delay
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            Constants.API_Polling_Times.patientVerificationMfaRetry_seconds * Constants.MILLISECONDS_IN_SECOND
          )
        );

        // Recursive call with increased retry count
        return this.initiateVerifyMobilePhone(patientId, mobilePhoneNumber, retryCount + 1, maxRetries);
      } else {
        this.showSendErrorToastMobilePhone();
        console.error(
          `Failed to send code for mobile phone number "${mobilePhoneNumber}". Error:`,
          this.functions.getErrorMessage(err),
          err
        );
        return null;
      }
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/auth/verifymobilephone/complete
  async completeVerifyMobilePhone(
    tempUserId: string,
    code: string,
    mfaEnrolmentId: string,
    mobilePhoneNumber: string
  ): Promise<boolean> {
    const body = new CompleteVerifyMobilePhoneDTO({
      patientId: tempUserId,
      mfaEnrolmentId: mfaEnrolmentId,
      code: code
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(this.fullCompleteVerifyMobilePhoneUrl, body)
      );

      if (response?.success && typeof response.response === 'boolean') {
        return response.response as boolean;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to verify mobile phone verification SMS code for "${mobilePhoneNumber}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  private showSendErrorToastEmail(): void {
    this.functions.showToast(`Could not send a code to your email address. Please check your inputs.`);
  }

  private showSendErrorToastMobilePhone(): void {
    this.functions.showToast(`Could not send a code to your mobile phone number. Please check your inputs.`);
  }

  initiatePasswordReset(email: string): Promise<any> {
    const resetPasswordDTO: ResetPasswordDTO = {
      email
    };

    return this.http
      .post(this.initiatePasswordResetUrl, resetPasswordDTO)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        return this.functions.handleError(err);
      });
  }

  completePasswordReset(token: string, email: string, newPassword: string): Promise<any> {
    const completePasswordResetDTO: CompletePasswordResetDTO = {
      email,
      newPassword,
      token
    };

    return this.http
      .post(this.completePasswordResetUrl, completePasswordResetDTO)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        return this.functions.handleError(err);
      });
  }

  setVerifiedMobilePhoneNumber(mobilePhoneNumber: string): void {
    this._mobilePhoneNumberVerified.next(mobilePhoneNumber);
  }

  /*
  getNewToken(email: string, refreshToken: string): Promise<any> {
    const url = `${this.refreshTokenUrl}?clientId=${email}&refreshToken=${refreshToken}`;
    console.log('Executing getNewToken() @ ', url);

    return this.http
      .get(url)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response) {
          if (response && response.success) {
            const credentials = response.response as Credentials;
            console.log('===TOKEN REFRESH SUCCESSFUL===  response: ', credentials);
            // Update credentials
            this.credentialsService.setCredentials(
              {
                email,
                accessToken: credentials.accessToken,
                refreshToken: credentials.refreshToken,
                expiresInSeconds: credentials.expiresInSeconds
              },
              true,
            );
            return credentials.accessToken;
          // Check whether the refreshToken has expired
          } else if (response.error && response.error.code === 'INVALID_TOKEN') {
            console.log('Your refreshToken has completely expired. Login is required!');
            this.resetAndLogin();
            return response.error.code;
          }
        }
        console.log('[Authentication-Service] No response from refreshToken API');
        return null;
      })
      .catch((error: any) => {
        console.warn('Error refreshing token: ', error);
        this.resetAndLogin();
        return null;
      });
  }
  */

  getNewTokenObs(email: string, refreshToken?: string): Observable<string | boolean | null> {
    const url: string = `${this.refreshTokenUrl}?clientId=${encodeURIComponent(email)}&refreshToken=${refreshToken}`;

    console.log('[AUTHENTICATION-SERVICE] Executing getNewTokenObs() @ ', url);

    return this.http.get(url).pipe(
      map((res: IResponseAPI) => {
        if (res?.success && res.response) {
          const result = res.response as Credentials;

          this.credentialsService.setCredentials({
            email,
            accessToken: result.accessToken,
            refreshToken: result.refreshToken,
            expiresInSeconds: result.expiresInSeconds
          });

          console.log('===TOKEN REFRESH SUCCESSFUL===  response: ', result);
          return result.accessToken || true;
        } else if (this.functions.getErrorCode(res) === Constants.API_ERROR_CODES.INVALID_TOKEN) {
          console.log('Your accessToken and refreshToken have both expired. Login is required!');

          // Let parent service handle this error
          return Constants.API_ERROR_CODES.INVALID_TOKEN;
        } else {
          console.warn('Token refresh failed with error: ', this.functions.getErrorMessage(res));
          return null;
        }
      })
    );
  }
}
