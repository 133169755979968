import { Injector } from "@angular/core";
import { AIContext, AppInsightsService } from "../appinsights.service";

type WhitelabelAnalyticsEventHandler = (type: WhitelabelAnalyticsEventType) => void;

export class WhitelabelAnalyticsHandler {
    private static loadedScripts: string[] = [];

    private handlers: Record<number, WhitelabelAnalyticsEventHandler[]> = {};

    protected aiContext: AIContext;

    constructor(injector: Injector, handlerName: string) {
        const aiService = injector.get<AppInsightsService>(AppInsightsService);
        this.aiContext = aiService.createContext(handlerName ?? 'WhiteLabelAnalyticsHandler');
    }

    protected On(event: WhitelabelAnalyticsEventType, handler: WhitelabelAnalyticsEventHandler): void {
        if (!this.handlers[event]) this.handlers[event] = [];

        this.handlers[event].push(handler);
    }

    public Trigger(event: WhitelabelAnalyticsEventType): void {
        if (!this.handlers[event]) {
            this.aiContext.info('TrackEvent', { status: 'NoHandlers', event });
            return;
        }

        this.handlers[event].forEach((delegate) => {
            try {
                delegate(event);
            } catch (ex) {
                this.aiContext.trackException(ex, 'TrackEvent', { status: 'Fail', event });
            }
        });

        this.aiContext.info('TrackEvent', { status: 'Complete', event });
    }

    protected LoadScript(url: string, isLoaded?: () => boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!isLoaded) isLoaded = () => WhitelabelAnalyticsHandler.loadedScripts.indexOf(url) !== -1;

            const isScriptLoaded = isLoaded();

            if (isScriptLoaded) {
                this.aiContext.info('LoadCustomScript', { status: 'AlreadyLoaded', url });
                return;
            }

            this.aiContext.info('LoadCustomScript', { status: 'Start', url });

            const scriptElem = document.createElement('script');

            scriptElem.onload = () => {
                this.aiContext.info('LoadCustomScript', { status: 'Success', url });
                resolve();
            };

            scriptElem.onerror = () => {
                this.aiContext.error('LoadCustomScript', { status: 'Fail', url });
                reject();
            };

            scriptElem.src = url;
            document.body.appendChild(scriptElem);
            WhitelabelAnalyticsHandler.loadedScripts.push(url);
        });
    }

    protected SetWindowProperty(propKey: string, prop: any): void {
        if (!!window[propKey]) {
            this.aiContext.info('SetWindowProperty', { status: 'AlreadySet', propKey });
            return;
        }

        window[propKey] = prop;

        this.aiContext.info('SetWindowProperty', { status: 'Success', propKey });
    }
}

enum WhitelabelAnalyticsEventType {
    None = 'None',
    PageView = 'PageView',
    AppointmentBooked = 'AppointmentBooked',
}

export { WhitelabelAnalyticsEventType }