import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, takeWhile } from 'rxjs';
import { Labels } from '../constants';
import { Patient } from '../models/patient';
import { PatientService } from './patient.service';
import { StepService } from './step.service';
import {
  PatientVerificationMode,
  PatientVerificationResponseDTO
} from '../models/patient/patientVerificationResponseDTO';
import { Step } from '../models/step';
import { STEP_CONFIGURATION } from '../step-configuration';
import { WhitelabelService } from './whitelabel.service';
import { AppointmentService } from './appointment.service';

@Injectable({
  providedIn: 'root'
})
export class PatientVerificationService {
  private subscription = new Subscription();

  private _patientVerificationInfo: BehaviorSubject<PatientVerificationResponseDTO> =
    new BehaviorSubject<PatientVerificationResponseDTO>(null);

  public patientVerificationInfoObs = this._patientVerificationInfo.asObservable();

  requiresPatientVerificationPageDisplay: boolean;
  lastVerificationCheckPatientId: string;
  patientEmailVerified: boolean;
  patientMobileVerified: boolean;

  constructor(
    private patientService: PatientService,
    private stepService: StepService,
    private whiteLabelService: WhitelabelService,
    private appointmentService: AppointmentService
  ) {
    this._init();
  }

  private _init(): void {
    this.subscription.add(
      this.patientService.patientChangeBehaviorObs.subscribe((patient: Patient) => {
        if (patient) {
          if (this.lastVerificationCheckPatientId != patient.patientId) {
            this.lastVerificationCheckPatientId = patient.patientId;
            this.clearAndGetPatientVerificationInfo();
          }
        } else {
          this.lastVerificationCheckPatientId = null;
        }
      })
    );

    // STEPS
    this.stepService.listChangeObsBehavior.subscribe((steps: Step[]) => {
      if (steps) {
        this.clearAndGetPatientVerificationInfo();
      }
    });
  }

  public clearAndGetPatientVerificationInfo() {
    if (this.stepService.isQuickScriptStepType()) {
      this.stepService.setServiceData(
        STEP_CONFIGURATION.QUICK_SCRIPT.PATIENT_VERIFICATION.path,
        null,
        true, // halt update
        false // don't append items
      );
    } else {
      const appointmenServiceType: string = this.appointmentService.appointment?.serviceType;
      const appointmentServiceType: string = appointmenServiceType || this.whiteLabelService.getPrimaryServiceType();
      const appointmentStepType: string = this.whiteLabelService.getAppointmentStepType(appointmentServiceType);

      if (appointmentServiceType) {
        this.stepService.setServiceData(
          STEP_CONFIGURATION[appointmentStepType].PATIENT_VERIFICATION.path,
          null,
          true, // halt update
          false // don't append items
        );
      }
    }

    this._patientVerificationInfo.next(null);
    this.updatePatientVerification();
  }

  updatePatientVerification() {
    // only get this once per patient - getting info about the initial state of their verification
    if (this._patientVerificationInfo.value) {
      return;
    }

    this.patientService.patientChangeBehaviorObs.pipe(takeWhile((x) => !x, true)).subscribe((patient: Patient) => {
      if (patient) {
        this.patientEmailVerified = false;
        this.patientMobileVerified = false;

        this.patientService
          .getPatientVerificationInfo(patient.patientId)
          .then((result: PatientVerificationResponseDTO) => {
            if (result && result.emailVerified !== false) {
              this.patientEmailVerified = true;
            }

            if (result && result.mobilePhoneVerified !== false) {
              this.patientMobileVerified = true;
            }

            this.requiresPatientVerificationPageDisplay = result
              ? result.patientVerificationMode != PatientVerificationMode.Off &&
                (!this.patientEmailVerified || !this.patientMobileVerified)
              : false;

            this._patientVerificationInfo.next(result);

            this.stepService.showHideSteps(
              [Labels.patientVerification],
              this.requiresPatientVerificationPageDisplay == null ? true : this.requiresPatientVerificationPageDisplay
            );
          });
      }
    });
  }

  get patientVerificationInfo(): PatientVerificationResponseDTO {
    return this._patientVerificationInfo.value;
  }
}
