import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { B2BAuthenticationGuard } from '@src/app/core/services/b2b-authentication.guard';
import { B2BBookingGuard } from '@src/app/core/services/b2b-booking.guard';
import { BusinessPortalContainerComponent } from './components/business-portal-container/business-portal-container.component';
import { BusinessPortalLandingPageComponent } from './components/business-portal-landing-page/business-portal-landing-page.component';
import { BusinessPortalPatientBookingGenerateNewPolicyComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-generate-new-policy/business-portal-patient-booking-generate-new-policy.component';
import { BusinessPortalPatientBookingLoginComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-login/business-portal-patient-booking-login.component';
import { BusinessPortalPatientBookingPatientAddComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-patient-add/business-portal-patient-booking-patient-add.component';
import { BusinessPortalPatientBookingPatientCodeComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-patient-code/business-portal-patient-booking-patient-code.component';
import { BusinessPortalPatientBookingPatientEmailComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-patient-email/business-portal-patient-booking-patient-email.component';
import { BusinessPortalPatientBookingPatientSelectComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-patient-select/business-portal-patient-booking-patient-select.component';
import { BusinessPortalPatientBookingPolicyNumberComponent } from './components/business-portal-patient-booking/business-portal-patient-booking-policy-number/business-portal-patient-booking-policy-number.component';

export const B2B_STEP_PATH = {
  BOOK_APPOINTMENT: 'book-appointment',
  LOGIN: 'login',
  POLICY_NUMBER: 'policy-number',
  PATIENT_EMAIL: 'patient-email',
  PATIENT_ADD: 'patient-add',
  PATIENT_SELECT: 'patient-select',
  PATIENT_CODE: 'patient-code',
  GENERATE_NEW_POLICY: 'generate-new-policy'
};

const routes: Routes = [
  {
    path: ':b2bcode',
    component: BusinessPortalContainerComponent,
    children: [
      {
        path: '',
        component: BusinessPortalLandingPageComponent
      },
      {
        path: B2B_STEP_PATH.BOOK_APPOINTMENT,
        children: [
          {
            path: '',
            component: BusinessPortalPatientBookingLoginComponent
          },
          {
            path: `${B2B_STEP_PATH.LOGIN}/:serviceType`,
            component: BusinessPortalPatientBookingLoginComponent
          },
          {
            path: B2B_STEP_PATH.POLICY_NUMBER,
            canActivate: [B2BAuthenticationGuard, B2BBookingGuard],
            component: BusinessPortalPatientBookingPolicyNumberComponent
          },
          {
            path: B2B_STEP_PATH.PATIENT_EMAIL,
            canActivate: [B2BAuthenticationGuard, B2BBookingGuard],
            component: BusinessPortalPatientBookingPatientEmailComponent
          },
          {
            path: B2B_STEP_PATH.PATIENT_ADD,
            canActivate: [B2BAuthenticationGuard, B2BBookingGuard],
            component: BusinessPortalPatientBookingPatientAddComponent
          },
          {
            path: B2B_STEP_PATH.PATIENT_SELECT,
            canActivate: [B2BAuthenticationGuard, B2BBookingGuard],
            component: BusinessPortalPatientBookingPatientSelectComponent
          },
          {
            path: B2B_STEP_PATH.PATIENT_CODE,
            canActivate: [B2BAuthenticationGuard, B2BBookingGuard],
            component: BusinessPortalPatientBookingPatientCodeComponent
          },
          {
            path: B2B_STEP_PATH.GENERATE_NEW_POLICY,
            canActivate: [B2BAuthenticationGuard, B2BBookingGuard],
            component: BusinessPortalPatientBookingGenerateNewPolicyComponent
          }
        ]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class BusinessPortalRoutingModule {}
