<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">Medication Not Available</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-secondary">
    <div class="row m-0">
      <div class="col-12 desc">
        <span class="desc-name">{{medicationName}} </span>
        <span class="desc-shortinfo">is illegal medication </span>
        <span class="desc-longinfo">and cannot be prescribed via our service.</span>
      </div>
      <div class="col-12">
        <div class="card-message">
          <span class="message">
            {{ controlledDrugWarning }}
          </span>
          <span class="message-bold">
            {{ controlledDrugsProceed }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer container-fluid bg-muted">
    <div class="row m-0">
      <div class="col-12">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
