<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">Individual Reference Number</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-primary">
    <div class="col-12 px-0 m-0">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 align-items-start">
          <p class="padded-text">
            This is the single digit number to the left of your name on the Medicare Card
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
          <p>
            <img src="assets/images/ind-ref-no.jpeg" alt="Where to find Individual Reference Number" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="row m-0 w-100">
      <div class="col-12">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
