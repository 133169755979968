import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HealthcareComponent } from '@app/pages/home/components/profile/healthcare/healthcare.component';
import { Functions } from '@app/shared/functions';

@Component({
  selector: 'healthcare-cards-modal',
  templateUrl: './healthcare-cards-modal.component.html',
  styleUrls: ['./healthcare-cards-modal.component.scss'],
  providers: [HealthcareComponent]
})
export class HealthcareCardsModalComponent implements OnInit, AfterViewInit {
  @ViewChild('healthcareCards') healthcareCards: HealthcareComponent;

  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<HealthcareCardsModalComponent>,
    private functions: Functions
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  scrollToTop(): void {
    setTimeout(() => {
      try {
        document.getElementById('health-card-modal-wrapper').scrollTo(0, 0);
      } catch (err: any) {}
    }, 150);
  }

  saveAll(): void {
    this.isLoading = true;

    this.healthcareCards
      .saveAll()
      .then((cardsValid: boolean) => {
        if (cardsValid) {
          this.onClose(true);
          this.functions.showToast('Healthcare Cards successfully updated!');
        }
      })
      .catch((err: any) => {
        console.log(
          '[HealthcareCardsModal] Unable to save healthcare card details. Error:',
          this.functions.getErrorMessage(err)
        );
        this.functions.showToast('Update failed. Please check your inputs');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onClose(cardsUpdated: boolean = false): void {
    this.dialogRef.close(cardsUpdated);
  }
}
