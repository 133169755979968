import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@src/environments/environment';
import { TimeZone } from '../models/time-zone';
import { AIContext, AppInsightsService } from './appinsights.service';
import { SegmentedAvailabilitiesFilter } from '../models/availabilities/segmented/SegmentedAvailabilitiesFilter';
import { WorkerRegistration } from '../models/worker/worker-registration';
import { WorkerAdapterService } from './worker-adapter.service';
import {
  SegmentedAvailabilities,
  SegmentedAvailabilityGroupPeriod,
  SegmentedAvailabilityGroupPeriodAvailability
} from '../models/availabilities/segmented/SegmentedAvailabilities';

@Injectable({
  providedIn: 'root'
})
export class AvailabilitySynthService implements OnDestroy {
  private workerRegistration: WorkerRegistration = null;

  private aiCtx: AIContext;

  constructor(
    aiService: AppInsightsService,
    private workerAdapterService: WorkerAdapterService
  ) {
    this.aiCtx = aiService.createContext('AvailabilitiesService');
    this.init();
  }

  private init(): void {
    this.workerRegistration = this.workerAdapterService.registerWorker(
      `${environment.appFolder}/static/availability-sw/availability-sw.js`
    );

    this.workerRegistration.sendMessage('DoD.Availabilities.Preload', null);
  }

  ngOnDestroy(): void {
    this.workerRegistration?.terminate();
  }

  /**
   * @async
   * @function calculateAvailabilities
   * @description New method of calculating appointment availabilities
   *
   * @param {SegmentedAvailabilitiesFilter} filter availability filter params
   * @param {TimeZone} timezone patient's timezone
   *
   * @returns {Promise<any>}
   */
  public async calculateAvailabilities(filter: SegmentedAvailabilitiesFilter, timezone: TimeZone): Promise<any> {
    this.aiCtx.info('calculateAvailabilities', { filter });

    const result: SegmentedAvailabilities = await this.workerRegistration.sendRequest<SegmentedAvailabilities>(
      'DoD.Availabilities.Calculate',
      {
        details: {
          timezoneName: timezone?.ianaTimeZones[0],
          timezoneOffset: timezone?.offset,
          timezoneOffsetText: timezone?.offsetText,
          filter
        }
      }
    );

    try {
      this.logAvailabilityMetrics(result);
    } catch (ex) {
      this.aiCtx.trackException(ex, 'logAvailabilityMetricsFailed');
    }

    return result;
  }

  /**
   * @async
   * @function onModifiedLockUnavilabilitiesEventReceived
   * @description used to do a targeted update of the availabilities affected by an availability lock being acquired or released
   *
   * @param {SegmentedAvailabilitiesFilter} filter availability filter params
   * @param {TimeZone} timezone patient's timezone
   *
   * @returns {Promise<any>}
   */
  public async onModifiedLockUnavilabilitiesEventReceived(modifiedLockUnavailabilitiesEvent: any): Promise<any> {
    //TEMPORARY CODE FOR TESTING
    const result: SegmentedAvailabilities = await this.workerRegistration.sendRequest<SegmentedAvailabilities>(
      'DoD.Availabilities.UpdateSegmentsFromModifiedLockUnavailabilities',
      modifiedLockUnavailabilitiesEvent
    );

    // try {
    //   this.logAvailabilityMetrics(result); TODO
    // } catch (ex) {
    //   this.aiCtx.trackException(ex, 'logAvailabilityMetricsFailed'); TODO
    // }

    return result;
  }

  private logAvailabilityMetrics(availabilities: SegmentedAvailabilities): void {
    const metricsData = {
      today: {
        nextAvailability: null,
        remainingSessionTime: 0,
        remainingSessionCount: 0
      }
    };

    const todayAg = availabilities.availabilityGroups[0];

    let earliestAvailability: SegmentedAvailabilityGroupPeriodAvailability = null;
    let earliestAvailabilityIndex: number = 1000000;

    for (const price of todayAg.displayPrices) {
      const period = todayAg.periods[price.hash];
      if (period) {
        todayAg.periods[price.hash].availabilities.forEach((a) => {
          metricsData.today.remainingSessionCount += a.practitionerIds.length;
          metricsData.today.remainingSessionTime += a.practitionerIds.length * price.appointmentDuration;
        });

        if (todayAg.periods[price.hash].availabilities.length) {
          const seg: SegmentedAvailabilityGroupPeriodAvailability = todayAg.periods[price.hash].availabilities[0];

          if (seg.segIndex < earliestAvailabilityIndex) {
            earliestAvailabilityIndex = seg.segIndex;
            earliestAvailability = seg;
          }
        }
      }
    }

    metricsData.today.nextAvailability = {
      offsetMins: Math.round((new Date(earliestAvailability.start.dateValueUTC).getTime() - Date.now()) / 60000),
      absolute: earliestAvailability.start.dateValueUTC
    };

    this.aiCtx.info('availabilityMetrics', metricsData);
  }

  /**
   * @async
   * @function getQueueSizes
   * @description Retrieve queue sizes for all available on-demand practitioners
   *
   * @param {string} serviceType
   * @param {TimeZone} timezone
   * @param {string} [policyId]
   *
   * @returns {Promise<Record<string, number>>}
   */
  public async getQueueSizes(
    serviceType: string,
    timezone: TimeZone,
    policyId?: string
  ): Promise<Record<string, number>> {
    return await this.workerRegistration.sendRequest('DoD.Availabilities.GetQueueSizes', {
      serviceType,
      policyId,
      timezoneOffset: timezone?.offset
    });
  }
}
