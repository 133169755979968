<div class="modal-wrapper error-message-modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">{{ dialogData.title }}</h4>
    </div>
    <div class="col-2 px-0">
      <button *ngIf="!data.hideHeaderCloseButton" type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-primary">
    <p class="col-12 px-0 m-0" [innerHtml]="dialogData.message"></p>
  </div>
  <div class="modal-footer error-message-modal-footer container-fluid bg-muted">
    <button
      *ngIf="enableAppointments && enableOnDemand && dialogData.isSpeakToDoctor"
      mat-stroked-button
      color="accent"
      class="btn-block"
      (click)="speakToDoctor()"
    >
      Speak To Doctor
    </button>
    <button
      *ngIf="enableAppointments && dialogData.isCancelAppointment"
      mat-stroked-button
      color="accent"
      class="btn-block"
      (click)="cancelAppointment()"
    >
      Cancel Appointment
    </button>
    <button
      *ngIf="enableAppointments && dialogData.newAppointment"
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="scheduleAppointment()"
    >
      Book a New Appointment
    </button>
    <button *ngIf="dialogData.isContactUs" mat-flat-button color="accent" class="btn-block" (click)="contactUs()">
      Contact Us
    </button>
    <button
      *ngIf="enableAppointments && dialogData.isRescheduleAppointment"
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="rescheduleAppointment()"
    >
      Reschedule Appointment
    </button>
    <button
      *ngIf="enableAppointments && dialogData.isDoctorSelect"
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="selectPractitioner()"
    >
      Select Doctor
    </button>
    <button
      *ngIf="enableAppointments && dialogData.isGoToWaitingRoom"
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="goToWaitingRoom()"
    >
      Go to Waiting Room
    </button>
    <button
      *ngIf="dialogData.updatePatientProfileButton"
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="updatePatientProfile()"
    >
      Patient Profile
    </button>
    <button
      *ngIf="dialogData.showDashboardButton"
      mat-stroked-button
      color="accent"
      class="btn-block"
      (click)="goToDashboard()"
    >
      Dashboard
    </button>
    <button
      *ngIf="dialogData.showSwitchPatient"
      mat-stroked-button
      color="accent"
      class="btn-block"
      (click)="switchPatient()"
    >
      Switch Patient
    </button>
    <button
      *ngIf="dialogData.updateHealthcareIdentifiers"
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="updateHealthcareIdentifiers()"
    >
      Update Healthcare Card Details
    </button>
    <button
      *ngIf="dialogData.showRemoveBenefitButton"
      mat-stroked-button
      color="accent"
      class="btn-block"
      (click)="removeBenefit()"
    >
      Remove Applied Benefit
    </button>
    <button *ngIf="dialogData.isClose" mat-flat-button color="accent" class="btn-block" (click)="onClose()">
      {{ dialogData.customCloseButtonText || 'Close' }}
    </button>
  </div>
</div>
