<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 pl-0">
      <p class="subtitle">{{ dialogData.subtitle }}</p>
      <p class="title">{{ dialogData.title }}</p>
    </div>
    <div class="col-2">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div id="webcam-body" class="modal-body container-fluid text-primary text-center">
    <!-- <div class="wrapper" (window:resize)="onResize($event)"> -->
    <div class="wrapper">
      <webcam
        [width]="width"
        [height]="height"
        (cameraSwitched)="handleCameraSwitch($event)"
        (initError)="handleInitError($event)">
      </webcam>
    </div>
    <div *ngIf="mobileCamSupport" class="webcam-caption">
      <span *ngIf="errors?.length">
        <span *ngFor="let error of errorStrings">
          {{ error }}
        </span>
      </span>
      <span *ngIf="!errors?.length">
        Can you see yourself? If you can, your camera is working properly.
      </span>
    </div>
    <div *ngIf="!mobileCamSupport" class="webcam-caption">
      <span>
        Webcam is not supported on your device!
      </span>
    </div>
  </div>

  <div class="modal-footer bg-muted flex-nowrap mx-0 bg-muted">
    <div class="col-12 m-0">
      <button mat-flat-button color="accent" class="btn-block" (click)="onClose()">Close</button>
    </div>
  </div>
</div>
