<form [formGroup]="appointmentCancelFormGroup" (submit)="confirmCancel()">
  <div id="cancellation-modal-wrapper" class="modal-wrapper">
    <div class="modal-header container-fluid align-items-center">
      <div class="col-10 pl-0">
        <div class="modal-header-subtitle">Practitioner Type - {{ getPractitionerType() | titlecase }}</div>
        <div class="text-primary modal-header-title">Cancel Appointment</div>
      </div>
      <div class="col-2 pr-0">
        <button type="button" class="close" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div *ngIf="!isConfirmation" class="modal-body container-fluid text-secondary">
      <div *ngIf="isLoading" class="my-5">
        <loading-spinner></loading-spinner>
      </div>
      <div [class.d-none]="isLoading">
        <p class="col-12 px-0">
          Are you sure you wish to cancel this appointment? <span class="d-none d-lg-inline d-xl-inline">
          The details of the appointment you are about to cancel are set out below.</span>
        </p>
        <template-card *ngIf="!isMobile" [subtitleBottom]="true">
          <span card-type="title" class="text-secondary">Appointment Details</span>
          <div card-type="content">
            <div class="col-12 p-0">
              <div class="row list-row m-0">
                <!--
                <div class="col-xl-4 col-lg-4 col-12 list-item custom-border-left border-right row mx-0 align-items-center justify-content-center">
                  <div class="white-box d-flex flex-column justify-content-around mr-1">
                    <span class="white-box-text">
                      {{ appointmentDayDate ? appointmentDayDate : '' }}
                    </span>
                    <span>
                      {{ appointmentDayMonth ? appointmentDayMonth : '' }}
                    </span>
                  </div>
                  <div class="white-box white-box__larger d-flex flex-column justify-content-around ml-1">
                    <span class="white-box-text-day">
                      {{ appointmentDayWeekday ? appointmentDayWeekday : '' }}
                    </span>
                    <span class="text-primary">
                      {{ appointmentDayTime ? appointmentDayTime : '' }}
                    </span>
                  </div>
                </div>
                -->
                <div class="col-12 col-xl-4 my-auto date-container">
                  <div
                    class="date-box date-shorter"
                    [title]="(appointmentDayDate || '') + ' ' + (appointmentDayMonth || '')">
                    <span class="date-box-date">
                      {{ appointmentDayDate || '' }}
                    </span>
                    <span class="date-box-month">
                      {{ appointmentDayMonth || '' }}
                    </span>
                  </div>
                  <div
                    class="date-box date-longer"
                    [title]="(appointmentDayWeekday || '') + ' ' + (appointmentDayTime || '') + ' - ' + appointmentTimeZoneLabel">
                    <span class="date-box-day">
                      {{ appointmentDayWeekday || '' }}
                    </span>
                    <span class="date-box-time">
                      {{ appointmentDayTime || '' }}
                    </span>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-12 list-item row mx-0">
                  <div
                    class="col-sm-4 px-0 text-left text-primary d-flex flex-column justify-content-around"
                    [title]="'Practitioner Type: ' + (appointmentToBeCancelled?.serviceType || 'doctor')">
                    <span class="list-item__header">Practitioner Type</span>
                    <span class="list-item__sub">{{ (appointmentToBeCancelled?.serviceType || 'doctor') | titlecase }}</span>
                  </div>
                  <div
                    class="col-sm-6 text-left text-primary d-flex flex-column justify-content-around"
                    [title]="'Practitioner: ' + (appointmentToBeCancelled?.practitionerName || 'first available')">
                    <span class="list-item__header">Practitioner</span>
                    <span class="list-item__sub">{{ appointmentToBeCancelled?.practitionerName || '' }}</span>
                  </div>
                  <div
                    class="col-sm-2 px-0 text-left text-primary d-flex flex-column justify-content-around"
                    [title]="'Appointment Price: ' + (appointmentToBeCancelled?.price | currency)">
                    <span class="list-item__header">Fee</span>
                    <span class="list-item__sub">{{ appointmentToBeCancelled?.price | currency }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template-card>
        <!-- <form [formGroup]="appointmentCancelFormGroup"> -->
        <template-card *ngIf="!isMobile" [subtitleBottom]="true" class="cancellation-reason">
          <span card-type="title" class="text-secondary">Cancellation Reason</span>
          <div card-type="content">
            <div class="col-12 p-0">
              <div class="row m-0">
                <mat-form-field appearance="outline" class="cancel-reason-input">
                  <input
                    formControlName="cancelReason"
                    maxlength="255"
                    type="text"
                    matInput
                    autocomplete="off" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </template-card>
        <!-- </form> -->

        <!-- <form [formGroup]="appointmentCancelFormGroup">
          <div class="cancel-reason">
            <span class="text-secondary">Cancellation Reason</span>
            <mat-form-field appearance="outline">
              <mat-label>Cancellation Reason</mat-label>
              <input type="text" matInput formControlName="cancelReason" />
            </mat-form-field>
          </div>
        </form> -->

        <!-- MOBILE -->
        <mat-card appearance="outlined" *ngIf="isMobile" class="detail-box">
          <div class="row header">
            <div class="col-sm-12 col-xl-6">
              <span class="title">Appointment Details</span>
            </div>
          </div>
          <mat-card-content class="content">
            <div class="row list-row margin-separator">
              <div class="col-12 col-xl-2 my-auto date-container">
                <div
                  class="date-box date-shorter"
                  [title]="(appointmentDayDate || '') + ' ' + (appointmentDayMonth || '')">
                  <span class="date-box-date">
                    {{ appointmentDayDate || '' }}
                  </span>
                  <span class="date-box-month">
                    {{ appointmentDayMonth || '' }}
                  </span>
                </div>
                <div
                  class="date-box date-longer"
                  [title]="(appointmentDayWeekday || '') + ' ' + (appointmentDayTime || '') + ' - ' + appointmentTimeZoneLabel">
                  <span class="date-box-day">
                    {{ appointmentDayWeekday || '' }}
                  </span>
                  <span class="date-box-time">
                    {{ appointmentDayTime || '' }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-xl-6 my-auto practitioner-info">
                <div class="row">
                  <div
                    class="col-xl-3 list-item"
                    [title]="'Practitioner Type: ' + (appointmentToBeCancelled?.serviceType || 'doctor')">
                    <span class="list-item__header">Practitioner Type</span>
                    <span class="list-item__sub">{{ (appointmentToBeCancelled?.serviceType || 'doctor') | titlecase }}</span>
                  </div>
                  <div
                    class="col-xl-5 list-item"
                    [title]="'Practitioner: ' + (appointmentToBeCancelled?.practitionerName || 'first available')">
                    <span class="list-item__header">Practitioner</span>
                    <span class="list-item__sub">{{ appointmentToBeCancelled?.practitionerName }}</span>
                  </div>
                  <div
                    class="col-xl-4 px-xl-0 list-item"
                    [title]="'Appointment Price: ' + (appointmentToBeCancelled?.price | currency)">
                    <span class="list-item__header">Fee</span>
                    <span class="list-item__sub">{{ appointmentToBeCancelled?.price | currency }}</span>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <template-card *ngIf="isMobile" [subtitleBottom]="true" class="cancellation-reason">
          <span card-type="title" class="text-secondary">Cancellation Reason</span>
          <span card-type="subtitle"></span>
          <div card-type="content">
            <div class="col-12 p-0">
              <div class="row m-0">
                <mat-form-field appearance="outline">
                  <input
                    formControlName="cancelReason"
                    maxlength="255"
                    type="text"
                    matInput
                    autocomplete="off" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </template-card>
      </div>
    </div>
    <div *ngIf="isConfirmation" class="modal-body-confirmation container-fluid text-secondary">
      <p *ngIf="!appointmentToBeCancelled?.price" class="col-12 px-0">
        Your appointment has been successfully cancelled.
      </p>
      <p *ngIf="appointmentToBeCancelled?.price" class="col-12 px-0">
        Your appointment will be cancelled and your consultation fee
        of <strong>{{ appointmentToBeCancelled.price | currency }}</strong> will be refunded.
        Please note it may take a few days for the bank to process your refund.
      </p>
    </div>
    <div *ngIf="!isConfirmation && !isMobile" class="modal-footer container-fluid bg-muted">
      <div class="first-button col-xl-6 col-xs-12 mx-0">
        <button
          mat-stroked-button
          type="button"
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
      <div class="second-button col-xl-6 col-xs-12 mx-0">
        <button
          mat-flat-button
          type="submit"
          color="accent"
          class="btn-block"
          [disabled]="!cancelReason.value?.length || isLoading"
          (keydown.enter)="$event.currentTarget.blur()">
          Confirm Cancellation
        </button>
      </div>
    </div>
    <div *ngIf="!isConfirmation && isMobile" class="modal-footer container-fluid bg-muted">
      <div class="first-button col-xl-6 col-xs-12 mx-0">
        <button
          mat-flat-button
          type="submit"
          color="accent"
          class="btn-block"
          [disabled]="!cancelReason.value?.length || isLoading"
          (keydown.enter)="$event.currentTarget.blur()">
          Confirm Cancellation
        </button>
      </div>
      <div class="second-button col-xl-6 col-xs-12 mx-0">
        <button
          mat-stroked-button
          type="button"
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
    <div *ngIf="isConfirmation" class="modal-footer single-button container-fluid bg-muted">
      <div class="second-button col-12 mx-0">
        <button
          mat-flat-button
          type="button"
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</form>
