<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 pl-0">
      <div class="text-primary title" [innerHTML]="trustedHtmlHeader"></div>
    </div>
    <div class="col-2 pr-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="modal-body container-fluid text-secondary">
    <div class="grey-box-question-wrapper">
      <div *ngIf="model.instructionsHtml?.length" [innerHTML]="trustedHtmlBody"></div>
    </div>
  </div>

  <div class="d-flex modal-footer button-wrapper container-fluid bg-muted">
    <div class="col-xl-6 col-xs-12 mx-0">
      <button mat-stroked-button type="button" color="accent" class="btn-block" (click)="onClose()">Close</button>
    </div>
  </div>
</div>
