import { Component, Inject, NgZone, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SERVICE_STEP_PATH_MAPPING } from '@app/shared/step-configuration';
import { ErrorMessageModalComponent } from '@app/shared/components/error-message-modal/error-message-modal.component';
import { Router } from '@angular/router';
import { Constants } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { Medication } from '@app/shared/models/medication';
import { Prescription } from '@app/shared/models/prescription';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { QuickscriptService } from '@app/shared/services/quickscript.service';
import { StepService } from '@app/shared/services/step.service';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'resend-prescription',
  templateUrl: './resend-prescription.component.html',
  styleUrls: ['./resend-prescription.component.scss']
})
export class ResendPrescriptionComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  isLoading: boolean;
  isUsed: boolean = false;
  isResent: boolean = false;
  // isConstraint: boolean = false;
  prescriptionSent: boolean = false;
  canProvidePrescriptions: boolean = true;
  appointmentStepType: string = 'APPOINTMENT_TYPE_DOCTOR';
  appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ResendPrescriptionComponent>,
    private appointmentService: AppointmentService,
    private quickScriptService: QuickscriptService,
    private stepService: StepService,
    private ngZone: NgZone,
    private router: Router,
    private functions: Functions,
    private dialog: MatDialog,
    private whiteLabelService: WhitelabelService
  ) {}

  ngOnInit(): void {
    // WHITELABEL CONFIGURATION
    this.updateWhiteLabelConfiguration();
    this.subscription.add(
      this.whiteLabelService.whitelabelConfigObs.subscribe(() => {
        this.updateWhiteLabelConfiguration();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateWhiteLabelConfiguration(): void {
    if (this.whiteLabelService.canProvideAppointments()) {
      this.updateServiceType();
    }
    if (!this.whiteLabelService.canProvidePrescriptions()) {
      this.canProvidePrescriptions = false;
    }
  }

  updateServiceType(serviceType?: string): void {
    this.appointmentServiceType = serviceType || this.whiteLabelService.getPrimaryServiceType();
    this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
  }

  resendScript(): void {
    if (this.data && this.data.id) {
      if (this.data.type === 'order') {
        this.isLoading = true;
        this.quickScriptService
          .scriptResend(this.data.id, this.data.prescription)
          .then((response: any) => {
            if (response?.success && response.response) {
              this.prescriptionHasBeenSent();
            }
          })
          .catch((err: any) => {
            if (this.functions.getErrorCode(err) === Constants.API_ERROR_CODES.CANT_RESEND_SCRIPT) {
              // Long error message, extend toast display time
              this.functions.showToast(this.functions.getErrorMessage(err), 10);
            } else {
              this.functions.showToast(this.functions.getErrorMessage(err));
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (this.data.type === 'appointment') {
        this.isLoading = true;
        this.appointmentService
          .scriptResend(this.data.id, this.data.prescription)
          .then((response) => {
            if (response?.success && response.response) {
              this.prescriptionHasBeenSent();
            }
          })
          .catch((err: any) => {
            if (this.functions.getErrorCode(err) === Constants.API_ERROR_CODES.CANT_RESEND_SCRIPT) {
              // Long error message, extend toast display time
              this.functions.showToast(this.functions.getErrorMessage(err), 10);
            } else {
              this.functions.showToast(this.functions.getErrorMessage(err));
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    } else {
      this.onClose();
      this.functions.showToast(
        'Cannot resend prescription. This prescription does not have a valid Appointment or Order ID!'
      );
    }
  }

  onClose(value?: string): void {
    this.dialogRef.close();

    if (value === 'renew') {
      this.renewPrescription();
    }
  }

  prescriptionHasBeenSent(): void {
    this.isResent = true;
    this.prescriptionSent = true;

    setTimeout(() => {
      this.ngZone.run(() => (this.prescriptionSent = false));
    }, Constants.MILLISECONDS_IN_SECOND * 2.5);
  }

  onYesClick(): void {
    this.isUsed = true;
  }

  async renewPrescription(): Promise<void> {
    const prescription: Prescription = this.data?.prescription ? (this.data.prescription as Prescription) : null;

    if (prescription?.appointmentId) {
      this.scheduleAppointment();
    } else if (prescription?.orderId) {
      this.goToQuickScript(prescription.medications);
    }
  }

  resetAppointment(): void {
    this.stepService.resetService();
    this.appointmentService.resetService();
  }

  scheduleAppointment(): void {
    this.resetAppointment();
    this.stepService.setList(this.appointmentServiceType);

    this.router.navigate(SERVICE_STEP_PATH_MAPPING[this.appointmentServiceType]);
  }

  /**
   * @function goToQuickScript
   * @description Start a QuickScript flow with the specified medications (take patient to the first quickscript step)
   *
   * @param {Medication[]} medications
   */
  goToQuickScript(medications: Medication[]): void {
    this.quickScriptService.startNewQuickScriptFlowFromMedications(medications);
  }

  showErrorMessage(message: string, dialogData: any = {}): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Prescription Problem',
      message,
      ...dialogData
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(ErrorMessageModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.functions.triggerResizeEvent();
      });
  }
}
