<div class="row goal-detail-wrapper">
  <div class="col-12 inline-goal goal-detail-container extra-margin">
    <span class="content-title">Your {{ measurementLabel }} progress</span>
    <span class="separator">|</span>
    <span class="subtitle text-primary" [class.goalreached]="goalReached">
      <img
        *ngIf="goalReached"
        src="assets/images/trophy-success-icon.svg"
        class="img-fluid"
        alt="goal reached" />
      <img
        *ngIf="!goalReached"
        src="assets/images/trophy-icon.svg"
        class="img-fluid"
        alt="goal" />
      {{ goalLabel }}
    </span>
  </div>
</div>
<div class="row d-flex goal-detail-wrapper">
  <div class="col-12 inline-goal">
    <span class="subtitle text-secondary">Goal {{ measurementLabel }}: {{ goalMeasurement }}{{ measurementUnits }}</span>
    <span class="separator">|</span>
    <span class="subtitle text-secondary">
      Current {{ measurementLabel }}:
      <span [ngClass]="cssClass">{{ currentMeasurement }}{{ measurementUnits }}</span>
    </span>
  </div>
</div>
