import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { Functions } from "@app/shared/functions";

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('pdfsandbox') sandboxFrame: ElementRef<HTMLIFrameElement>;
  @Input('pdfUrl') url: string;

  constructor(
    private functions: Functions
  ) {}

  ngAfterViewInit(): void {
    if (this.sandboxFrame?.nativeElement) {
      this.sandboxFrame.nativeElement.onload = (() => {
        this.onFrameLoaded();
      });

      this.sandboxFrame.nativeElement.src = 'static/pdf-sandbox/pdf-sandbox.html';
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', (e: MessageEventInit) => this.handleFrameMessage(e));
  }

  async onFrameLoaded(): Promise<void> {
    if (!this.url) {
      return;
    }

    // Fetch the PDF from the blob:// URL (this is an entirely local process)
    const pdfBuffer: ArrayBuffer = await fetch(this.url)
      .catch((err: any) => {
        console.log('(onFrameLoaded) unable to retrieve attachment file data. Error:', this.functions.getErrorMessage(err));
        return null;
      })
      .then((r: Response) => r.blob())      // Turn it into a blob
      .then((b: Blob) => b.arrayBuffer());  // Turn that into a buffer

    if (pdfBuffer) {
      // Send the buffer over to the PDF sandbox frame
      this.sandboxFrame.nativeElement.contentWindow.postMessage({
        action: 'renderPDF',
        data: {
          pdf: pdfBuffer
        }
      }, window.location.origin, [ pdfBuffer ]);
    } else {
      this.functions.showToast('Failed to load PDF. File is corrupt or missing.');
    }

    window.addEventListener('message', (e: MessageEventInit) => this.handleFrameMessage(e));
  }

  handleFrameMessage(event: MessageEventInit): void {
    try {
      // Only permit same-origin messages
      if (event.origin !== window.location.origin) {
        return;
      }

      switch (event.data?.action) {
        case 'resizeIframe':
          this.handleFrameResize(parseInt(event.data.data.height));
          break;
      }
    } catch (_err: any) {}
  }

  handleFrameResize(height: number): void {
    this.sandboxFrame.nativeElement.style.height = `${height}px`;
  }
}
