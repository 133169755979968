<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">{{ pageTitle }}</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose(null)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-secondary">
    <div class="col-12" [innerHtml]="pageText"></div>
  </div>
  <div *ngIf="enableAppointments" class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="d-none d-xl-flex row m-0 w-100">
      <div
        *ngIf="isOnline"
        class="first-button button-wrapper col-12 col-xl-6">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(serviceType)">
          {{ scheduledAppointment }}
        </button>
      </div>
      <div
        *ngIf="!isOnline || (enableOnDemand && isOnline)"
        class="second-button button-wrapper col-12 col-xl-6 mx-0"
        [class.col-xl-12]="!isOnline">
        <button
          *ngIf="!isOnline"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(serviceType)">
          {{ scheduledAppointment }}
        </button>
        <button
          *ngIf="enableOnDemand && isOnline"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(serviceType, true)">
          {{ speakToDoctor }}<br />{{queueSize}}
        </button>
      </div>
    </div>
    <div class="d-flex d-xl-none row m-0 w-100">
      <div
        *ngIf="!isOnline || (enableOnDemand && isOnline)"
        class="first-button button-wrapper col-12 col-xl-6 mx-0"
        [class.mb-0]="!isOnline">
        <button
          *ngIf="!isOnline"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(serviceType)">
          {{ scheduledAppointment }}
        </button>
        <button
          *ngIf="enableOnDemand && isOnline"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(serviceType, true)">
          {{ speakToDoctor }}<br />{{queueSize}}
        </button>
      </div>
      <div *ngIf="isOnline" class="second-button button-wrapper col-12 col-xl-6">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(serviceType)">
          {{ scheduledAppointment }}
        </button>
      </div>
    </div>
  </div>
</div>
