import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestDeviceType } from '@app/shared/models/test-device';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { WebcamInitError } from 'ngx-webcam';

const defaultWebcamViewportSize: any = {
  width: 240,
  height: 180
}

@Component({
  selector: 'test-webcam',
  templateUrl: './test-webcam.component.html',
  styleUrls: ['./test-webcam.component.scss'],
})
export class TestWebcamComponent implements OnInit {
  public errors: WebcamInitError[] = [];
  public errorStrings: string[] = [];

  mobileCamSupport: boolean = 'mediaDevices' in navigator;

  dialogData: any;
  width: number = defaultWebcamViewportSize.width;
  height: number = defaultWebcamViewportSize.height;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TestWebcamComponent>,
    private analytics: GoogleAnalyticsService
  ) {
    this.dialogData = this.data;

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win: Window = !!event ? (event.target as Window) : window;
    this.calculateWidth(win);
    this.calculateHeight();
  }

  calculateWidth(win: any): void {
    // Desktop
    if (win.innerWidth >= 1200) {
      this.width = Math.round(win.innerWidth * 0.4 - 64);
    // Tablet
    } else if (win.innerWidth >= 768 && win.innerWidth < 960) {
      this.width = Math.round(win.innerWidth * 0.75 - 140);
    // Mobile
    } else {
      this.width = Math.round(win.innerWidth - 100);
    }
  }

  calculateHeight(): void {
    if (!this.width) {
      this.width = defaultWebcamViewportSize.width;
    }

    // Try to calculate height by measuring the modal-body
    try {
      const visibleAreaHeight: number = document.getElementById('webcam-body').clientHeight;
      this.height = visibleAreaHeight
        ? Math.round(Math.min(visibleAreaHeight - 40, (this.width * 3 / 4) - 40))
        : defaultWebcamViewportSize.height;
    } catch (err: any) {
      this.height = Math.round((this.width * 3 / 4));
    }
  }

  ngOnInit(): void {
    this.analytics.testDevice(TestDeviceType.webcam);
    this.onResize();
  }

  onClose(): void {
    try {
      this.dialogRef.close();
    } catch (err: any) {
      console.log('Failed to close Test Webcam modal. Error = ', err?.message);
    }
  }

  public handleCameraSwitch(event: any): void {
    this.errorStrings = [];
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError?.name) {
      switch (error.mediaStreamError.name) {
        case 'NotAllowedError':
          console.warn('Camera access was not allowed by user!');
          this.errorStrings.push('Access to the camera was disallowed by the user!');
          break;
        case 'NotFoundError':
          console.warn('Webcam device not found!');
          this.errorStrings.push('Webcam device not found!');
          break;
        default:
          // do nothing
          break;
      }
    } else if (error.message) {
      console.warn(error.message);
      this.errorStrings.push(error.message)
    }

    this.errors.push(error);
  }
}
