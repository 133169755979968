import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VerifyPatientStepData } from '../../models/verifyEmailStepData';
import { Functions } from '../../functions';

@Component({
  selector: 'email-verification-signup-modal',
  templateUrl: './email-verification-signup-modal.component.html',
  styleUrls: ['./email-verification-signup-modal.component.scss']
})
export class EmailVerificationSignupModalComponent {
  emailAddress: string;
  emailVerified: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<EmailVerificationSignupModalComponent>,
    private functions: Functions
  ) {
    if (data) {
      this.emailAddress = data.emailAddress;
      if (data.emailVerified) {
        this.emailVerified = data.emailVerified;
      }
    } else {
      this.functions.showToast('Failed to load email verification component. Email address not provided.');
      this.onClose();
    }
  }

  emailVerifiedActions(data: VerifyPatientStepData): void {
    this.emailVerified = data.formData.emailVerified;
  }

  onClose(): void {
    this.dialogRef.close(this.emailVerified);
  }
}
