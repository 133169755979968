import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DownloadService } from '@app/shared/services/download.service';
import { Functions } from '@app/shared/functions';
import { Capacitor } from '@capacitor/core';
import { Constants } from '@app/shared/constants';

@Component({
  selector: 'pdf-viewer-template',
  templateUrl: './pdf-viewer-template.component.html',
  styleUrls: ['./pdf-viewer-template.component.scss']
})
export class PdfViewerTemplateComponent implements OnInit {
  dialogData: any;
  extList: Array<boolean> = [];
  heightList: Array<number> = [];
  urlList: Array<SafeResourceUrl | string> = [];
  isLoading: boolean = false;
  isNative: boolean = Capacitor.isNativePlatform();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PdfViewerTemplateComponent>,
    private downloadService: DownloadService,
    private sanitizer: DomSanitizer,
    private functions: Functions
  ) {
    this.dialogData = this.data || {};

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    if (this.dialogData.attachments) {
      this.dialogData.attachments.forEach((attachmentId: string) => {
        this.downloadService
          .getAttachmentBlob(attachmentId)
          .then((response: Blob) => {
            if (response && response.type !== 'application/json') {
              this.processValidAttachment(response, attachmentId);
            } else {
              this.invalidAttachment();
            }
          });
      });
    } else if (this.dialogData.attachmentId) {
      const attachmentId: string = this.dialogData.attachmentId;

      this.downloadService
        .getAttachmentBlob(attachmentId)
        .then((response: Blob) => {
          if (response && response.type !== 'application/json' && response.size) {
            this.processValidAttachment(response, attachmentId);
          } else {
            this.invalidAttachment();
          }
        });
    }
  }

  processValidAttachment(response: Blob, attachmentId: string): void {
    const typeMapping: string = Constants.FileAttachment_Configuration.contentTypeMapping.get(response.type);

    // All image types are valid by default
    let documentTypes: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    // Do not support previewing PDFs on native mobile
    if (!this.isNative) {
      documentTypes.push('pdf');
    }

    // If not in the list of previewable formats, display error message and download the file
    if (documentTypes.indexOf(typeMapping) === -1) {
      // Do not display error message for mobile PDFs, proceed to download
      if (this.isNative && typeMapping === 'pdf') {
        this.invalidAttachment('Previewing PDF files is not supported on mobile, please wait while we download the file to your device.');
      } else {
        this.invalidAttachment('Previewing \'' + typeMapping + '\' attachments is not supported. Please wait while we download the attachment to be viewed locally.');
      }

      const fileName: string = `${this.dialogData.attachmentFileName || 'Attachment'}_${attachmentId.substring(0, 8)}`;
      this.downloadService.downloadFile(
        response,
        fileName,
        null
      );

      // Leave modal window open to allow patient to read the warning message
      // this.dialogRef.close();
    } else {
      this.addData(response);
    }
  }

  invalidAttachment(message?: string): void {
    this.isLoading = false;
    this.dialogData.note = this.dialogData.note || '';
    this.dialogData.note = message?.length
      ? (this.dialogData.note + '<p>' + message + '</p>')
      : '<p>The attachment could not be downloaded. The file is corrupt or invalid.</p>';
  }

  addData(blob: Blob): void {
    if (!blob.type) {
      return;
    }

    let isPdf: boolean = blob.type.includes('pdf');
    this.extList.push(isPdf);

    if (isPdf) {
      this.urlList.push(URL.createObjectURL(blob));
      this.isLoading = false;

    } else {
      const reader = new FileReader();

      reader.readAsDataURL(blob);
      reader.onload = (event: ProgressEvent<any>) => {
        if (event?.target?.result) {
          this.urlList.push(this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result))
          this.isLoading = false;
        }
      };
      reader.onerror = (event: ProgressEvent<FileReader>) => {
        if (event?.target?.error) {
          console.log("Could not read file:", this.functions.getErrorMessage(event.target.error));
        }
        this.isLoading = false;
      };
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
