import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { GoogleAnalyticsService } from './google-analytics.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Functions } from '../functions';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Capacitor } from '@capacitor/core';
// import { CanShareResult, Share, ShareOptions } from '@capacitor/share';
import { FileOpener, OpenFileOptions } from '@capawesome-team/capacitor-file-opener';
import {
  Directory,
  // Encoding,
  Filesystem,
  // GetUriResult,
  // PermissionStatus,
  // ReadFileResult,
  WriteFileOptions,
  WriteFileResult
} from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}/attachment`;

  isNative: boolean = Boolean(Capacitor.isNativePlatform());

  constructor(
    private analytics: GoogleAnalyticsService,
    private functions: Functions,
    private http: HttpClient,
  ) { }

  /**
   * @function downloadFile
   * @description Convert file blob into a sharable/downloadable media item on mobile devices or
   * trigger a saveAs dialog on Mac and PC.
   *
   * @param {Blob} data file data
   * @param {string} attachmentFileName file name to save as
   * @param {string} [label] friendly file descriptor when sharing the downloaded file
   */
  downloadFile(data: Blob, attachmentFileName: string, label?: string): void {
    if (!data?.type || Constants.FileAttachment_Configuration.acceptedAttachmentTypes.indexOf(data.type) === -1) {
      return;
    }

    // We default unknown file types to 'png' so that they can be opened with an image viewer.
    // Most image viewers will detect the type of image before attempting to display it
    const ext: string = Constants.FileAttachment_Configuration.contentTypeMapping.get(data.type) ?? 'png';
    const fileName: string = (attachmentFileName || 'dod_attachment').split('/').join('_') + '.' + ext;
    const fileLabel: string = label || fileName;

    // console.log('FILE NAME:', fileName);

    // Track file download in GA4
    this.analytics.downloadFile(fileLabel, ext);

    if (this.isNative) {
      const reader = new FileReader();
      // Read the contents of the file as a data URI
      reader.readAsDataURL(data);
      reader.onload = async () => {
        let fileUri: string;

        try {
          // const hasPermissions: PermissionStatus = await Filesystem.checkPermissions();

          // // Check whether permission to the Documents folder have already been granted, if not, request permission now
          // if (hasPermissions?.publicStorage !== 'granted') {
          //   console.warn('HAS PERMISSIONS');
          //   await Filesystem.requestPermissions();
          // }

          await Filesystem.requestPermissions();
          // const hasPermissions: PermissionStatus = await Filesystem.requestPermissions();
          // console.warn('hasPermissions.publicStorage =', hasPermissions?.publicStorage);

        } catch (err: any) {
          console.warn('FAILED TO REQUEST PERMISSIONS! Error:', this.functions.getErrorMessage(err));
        }

        try {
          const writeOptions: WriteFileOptions = {
            path: fileName,
            directory: Directory.Documents,
            data: reader.result as string,
            // encoding: Encoding.UTF8
          };

          // Write the contents of the file to the device's storage
          const writeFile: WriteFileResult = await Filesystem.writeFile(writeOptions);

          fileUri = writeFile.uri;
        } catch (err: any) {
          console.warn('File FAILED TO WRITE! Error:', this.functions.getErrorMessage(err));
        }

        /*
        try {
          // Read the contents of the file (it's better if we just trigger Share instead)
          const readFile: ReadFileResult = await Filesystem.readFile({
            path: fileName,
            directory: Directory.Documents,
            // encoding: Encoding.UTF8
          });
          console.warn('FILE READ SUCCESSFULLY!');
          console.warn(readFile.data);
        } catch (err: any) {
          console.warn('File FAILED TO READ! Error:', this.functions.getErrorMessage(err));
        }
        */

        try {
          const mimeType: string = this.functions.getMapKeyByValue(Constants.FileAttachment_Configuration.contentTypeMapping, ext);

          // file stored in: /storage/emulated/0/
          const fileOptions: OpenFileOptions = {
            path: fileUri || fileName,
            mimeType
          };

          await FileOpener.openFile(fileOptions);
        } catch (err: any) {
          console.warn('File FAILED TO OPEN! Error:', this.functions.getErrorMessage(err));
        }

        /*
        try {
          const canShare: CanShareResult = await Share.canShare();

          if (canShare?.value) {
            // Get the URI of the file on the device's storage
            const shareUriResult: GetUriResult = await Filesystem.getUri({
              path,
              directory: Directory.Documents,
            });

            console.warn('CAN SHARE! Path:', shareUriResult.uri);

            const shareOptions: ShareOptions = {
              title: fileLabel,
              url: shareUriResult.uri
            };

            // Open the sharing panel to share the file
            Share.share(shareOptions);
          } else {
            console.warn('CANNOT SHARE!', path);
          }
        } catch (err: any) {
          console.warn('FAILED TO SHARE FILE! Error:', this.functions.getErrorMessage(err));
        }
        */
      }
      reader.onerror = async () => {
        this.functions.showToast('Unable to download ' + fileLabel);
      }
    } else {
      // Note: saves .JPEG files as .JFIF
      importedSaveAs(data, fileName, { autoBom: false });
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/attachment/{attachmentId}/download
  getAttachmentBlob(attachmentId: string): Promise<Blob> {
    return this.http
      .get(`${this.url}/${attachmentId}/download`, { responseType: 'blob' })
      .toPromise()
      .then((response: Blob) => response)
      .catch((err: any) => this.functions.handleError(err));
  }
}
