import { Component, Input, OnInit } from '@angular/core';
import { Functions } from '@app/shared/functions';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent implements OnInit {
  @Input('isInModalFooter') isInModalFooter: boolean = false;

  isMobile: boolean = Capacitor.isNativePlatform();

  constructor(
    private functions: Functions
  ) {}

  ngOnInit(): void {}

  /**
   * @function onPrint
   * @description Instead of downloading the attachment in a separate window and then printint,
   * we are using a '@media print' stylesheet and then triggering window.print (or Print.print for native platforms)
   */
  onPrint(): void {
    if (!this.isMobile) {
      window.print();
    } else {
      this.functions.showToast('Printing functionality is unavailable on mobile!');
    }
  }
}
