import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@src/app/shared/constants';
import { Functions } from '@src/app/shared/functions';
import { ModalsService } from '@src/app/shared/services/modals.service';
import { SessionStorageService } from 'ngx-webstorage';
import { throwError as observableThrowError, Observable, catchError, concat, delay, finalize, of, tap } from 'rxjs';

const MAX_DELAY = 48000;
const BASE_DELAY = 6000;

@Injectable()
export class MedicareValidationInterceptor implements HttpInterceptor {
  constructor(
    private readonly storage: SessionStorageService,
    private readonly modalService: ModalsService,
    private readonly functions: Functions
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const path: string = Constants.API_PATHS.validateHealthcareCards;

    let hideLoader: () => void = () => {};

    if (request.url.toLowerCase().includes(path.toLowerCase())) {
      hideLoader = this.modalService.showLoader();

      const currentDelay = this.getDelay(path);
      return concat(
        of(null).pipe(delay(currentDelay)),
        next.handle(request).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status === 200) {
              this.removeDelay(path);
            }
          }),
          catchError((error: any) => {
            const errorCode: string = this.functions.getErrorCode(error);

            switch (errorCode) {
              case 'MEDICARE_MULTIPLE_USE':
              case 'INVALID_MEDICARE_DETAILS_DONT_MATCH':
              case 'INVALID_MEDICARE_NUMBER':
              case 'INVALID_MEDICARE_IRN':
              case 'INVALID_MEDICARE_EXPIRY':
                this.increaseDelay(path, currentDelay);
                break;
              default:
                // Not a Medicare error, reset delay
                this.removeDelay(path);
                break;
            }

            // TODO: save delay as a timestamp, so that we can reset the delay after MAX_DELAY seconds of user inactivity

            // return EMPTY;
            return observableThrowError(() => error);
          }),
          finalize(() => {
            hideLoader();
          })
        )
      );
    } else {
      return next.handle(request);
    }
  }

  private getDelay(path: string): number {
    const delayKey: string = this.getDelayKey(path);
    let currentDelay: number = 0;

    try {
      currentDelay = parseInt(this.storage.retrieve(delayKey));
      currentDelay = isNaN(currentDelay) ? 0 : Math.min(currentDelay, MAX_DELAY);
    } catch {}

    return currentDelay;
  }

  private increaseDelay(path: string, currentDelay: number): void {
    const delayKey: string = this.getDelayKey(path);
    const delay: number = Math.min(currentDelay < BASE_DELAY ? BASE_DELAY : currentDelay * 2, MAX_DELAY);

    try {
      this.storage.store(delayKey, delay.toString());
    } catch {}
  }

  private removeDelay(path: string): void {
    const delayKey: string = this.getDelayKey(path);

    try {
      this.storage.clear(delayKey);
    } catch {}
  }

  private getDelayKey(path: string): string {
    const delayKey: string = `delay_ms_${path.replaceAll('/', '_')}`;
    return delayKey;
  }
}
