import { environment } from '@env/environment';
import { CustomerType } from './models/customer-type';
import { PractitionerType, PractitionerTypeConstants } from './models/practitioner-type';
import { QuickScriptServiceType } from './models/quickscriptServiceType';
import { ReimbursementType } from './models/reimbursement-type';
import { HttpContextToken } from '@angular/common/http';

export enum Labels {
  beforeProceed = 'Before you proceed',
  chooseTime = 'Choose Appointment Time',
  appointment = 'Appointment',
  bookAppointment = 'Book Your Appointment',
  confirmAppointment = 'Confirm Your Appointment',
  confirmAppointmentDetails = 'Confirm Your Appointment Details',
  inAppointment = 'In Appointment',
  appointmentComplete = 'Appointment Complete',
  appointmentBooked = 'Appointment Successfully Booked',
  registerLogin = 'Sign Up or Login',
  accountHolder = 'Account Holder Data',
  patientData = 'Patient Data',
  additionalPatientData = 'Additional Patient Data',
  payment = 'Payment',
  confirmPayment = 'Checkout',
  redeemBenefit = 'Redeem Benefit & Consent',
  healthcareDetails = 'Your Healthcare Identifiers',
  qeQuestion = 'Questionnaire Question',
  chatWithPractitioner = 'Chat With Your Practitioner',
  consentQuestions = 'Consent Questions',
  patientVerification = 'Account Verification',
  selectPharmacy = 'Select Pharmacy',
  pharmacyPickup = 'Pharmacy Pickup',
  signUp = 'Sign-Up',
  login = 'Login',
  newPatient = 'New Patient',
  quickScript = 'QuickScript',
  quickScriptEvent = 'Quickscript',
  qsComplete = 'QuickScript Complete',
  qsDelivery = 'Prescription Delivery',
  qsPayment = 'Prescription Delivery and Payment Details',
  addressConfirm = 'Confirm Shipping Address',
  waitingRoom = 'Waiting Room',
  confirmInfo = 'Additional Information Required',
  medicalHistory = 'Medical History',
  patientOther = 'Other Patient',
  confirmMedicalHistory = 'Confirm Medical History',
  medicationDetail = 'Medication Detail',
  doctorOnDemand = 'Doctor on Demand',
  sleepOnDemand = 'Sleep Specialist on Demand',
  doctorAppointment = 'Doctor Appointment',
  sleepAppointment = 'Sleep Appointment',
  sleepQuestionnaire = 'Sleep Questionnaire',
  fertilityQuestionnaire = 'Fertility Questionnaire',
  mentalHealthQuestionnaire = 'Mental Health Questionnaire',
  unspecified = 'unspecified',
  serverError = 'Server Error',
  med1 = 'MEDICATION_1',
  med2 = 'MEDICATION_2',
  prescriptionRequest = 'Request a Prescription',
  medicalCertificateRequest = 'Request a Medical Certificate',
  specialistReferral = 'Specialist Referral',
  quitSmokingAppointment = 'Quit Smoking',
  pathologyAppointment = 'Pathology Request',
  generalWeightLossAppointment = 'General Weight Loss',
  medicatedWeightLossAppointment = 'Weight Loss medication prescription',
  fertilityAppointment = 'Fertility specialist referral',
  onlineDoctors247 = '24/7 Online Doctors<br/>Provided By',
  weightLoss = 'Weight Loss',
  medicatedWeightLoss = 'medicated weight loss',
  generalWeightLoss = 'general weight loss',
  mentalHealthDefaultAppointmentReason = 'Mental Health Treatment Plan',
  mentalHealth = 'Mental Health',
  productSelection = 'Product Selection',
  orderPlaced = 'Order Placed',
  smokingCessation = 'Smoking Cessation',
  followUp = 'Follow-Up',
  onDemand = 'On-Demand',
  scheduled = 'Scheduled',
  eScript = 'eScript'
}

export enum timeLabels {
  morning = 'Morning',
  afternoon = 'Afternoon',
  evening = 'Evening',
  all = 'All Times'
}

export enum genderLabels {
  male = 'Male',
  female = 'Female',
  all = 'All Genders'
}

export enum liveChatVisibilityStatus {
  open = 'maximized',
  showMin = 'minimized',
  closed = 'hidden'
};

export enum PendingTaskKey {
  SendCertificateToEmployerOnceCertificateUploaded = 'SendCertificateToEmployerOnceCertificateUploaded',
  SendReferralToAdditionalEmailAddressOnceUploaded = 'SendReferralToAdditionalEmailAddressOnceUploaded'
}

export enum ApprovalStatus {
  no = 'No',
  yes = 'Yes',
  pending = 'Pending',
  defer = 'Defer'
}

export const Constants = {
  DefaultPageTitle: 'Doctors on Demand',
  DefaultStoragePrefix: 'dod.',
  Default_TimeZone_Offset: 10,
  Default_TimeZone_Name: 'E. Australia Standard Time',
  Default_TimeZone_Label: 'Brisbane, Australia (UTC+10:00)',
  Default_Date_Format: 'DD-MMM-YYYY',

  // Regular Expressions
  DateRegex:
    /^(((19|20)[0-9]{2})[ \/-](0[1-9]|1[0-2]|[1-9])[ \/-](0[1-9]|[12][0-9]|3[01]|[1-9]))|((0[1-9]|[12][0-9]|3[01]|[1-9])[ \/-](0[1-9]|1[0-2]|[1-9])[ \/-](((19|20)[0-9]{2})|([0-9]{2})))$/,
  DateYearFirstRegex: /^((19|20)[0-9]{2})[ \/-](0[1-9]|1[0-2]|[1-9])[ \/-](0[1-9]|[12][0-9]|3[01]|[1-9])$/,
  DateAURegex: /^(0[1-9]|[12][0-9]|3[01]|[1-9])[ \/-](0[1-9]|1[0-2]|[1-9])[ \/-](((19|20)[0-9]{2})|([0-9]{2}))$/,
  YearFirstRegex: /^\d{4}[ \/-]\d{1,2}[ \/-]\d{1,2}$/,
  YearLastRegex: /^\d{1,2}[ \/-]\d{1,2}[ \/-]\d{4}$/,
  YearFirstDate: 'YYYY-MM-DD',
  YearLastUSDate: 'MM-DD-YYYY',
  YearLastAUDate: 'DD-MM-YYYY',
  InboxAUDate: 'DD/MM/YYYY',
  InboxAUDateTime: 'DD/MM/YYYY | h:mm A',
  homePhoneRegex: /(^[+][0-9]*$)|(^0?[0-9]{9}$)/,
  homePhoneRegexString: '(^[+][0-9]*$)|(^0?[0-9]{9}$)',
  workPhoneRegex:
    /(^[+][0-9]*)|(^[+][6][1][0-9]{9}$)|(^[6][1][0-9]{9}$)|(^[0][0-9]{9}$)|(^[4-5][0-9]{8}$)|(^0?[0-9]{9}$)/, //allow mobile or landline numbers
  workPhoneRegexString:
    '(^[+][0-9]*)|(^[+][6][1][0-9]{9}$)|(^[6][1][0-9]{9}$)|(^[0][0-9]{9}$)|(^[4-5][0-9]{8}$)|(^0?[0-9]{9}$)',
  // Supports international phone numbers
  mobilePhoneRegex:
    /(^[+][0-9]{8,19})|(^[+][6][1][0]?[0-9]{9}$)|(^[6][1][0]?[0-9]{9}$)|(^[4-5][0-9]{8}$)|(^[0][0-9]{9}$)/,
  mobilePhoneRegexString:
    '(^[+][0-9]{8,19})|(^[+][6][1][0]?[0-9]{9}$)|(^[6][1][0]?[0-9]{9}$)|(^[4-5][0-9]{8}$)|(^[0][0-9]{9}$)',
  // Australian only
  mobilePhoneRegexAus: /^(([+]61)?0?4([0-9\s-]){8,10})$/,
  mobilePhoneRegexStringAus: '^(([+]61)?0?4([0-9\\s-]){8,10})$',
  // homePhoneRegex: /^(0?[1-9])[0-9]{8}$/,
  // homePhoneRegexString: '^(0?[1-9])[0-9]{8}$',
  // Alternative intl. phone number
  // mobilePhoneRegexString: '(^[+][0-9]{9,13}$)|(^[+][6][1][0-9]{9}$)|(^[6][1][0-9]{9}$)|(^[0][0-9]{9}$)|(^[4-5][0-9]{8}$)',
  attachmentsDateTime: 'DD-MM-YYYY @ h:mm A',
  prescriptionRenewalDate: 'DD-MM-YYYY',
  appointmentBookingDateTime: 'h:mm A, Do MMMM',
  emailRegex:
    /^([A-Za-z0-9!$%&\'+/=?^_~-]+((\.)?[A-Za-z0-9!$%&\'+/=?^_~-])+@(?:([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])+\.))+([A-Za-z0-9]{2,}|[0-9]{1,3})(?:[A-Za-z0-9-]*[A-Za-z0-9])?)$/,
  emailRegexString:
    "^([A-Za-z0-9!$%&\\'+/=?^_~-]+((\\.)?[A-Za-z0-9!$%&\\'+/=?^_~-])+@(?:([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])+.))+([A-Za-z0-9]{2,}|[0-9]{1,3})(?:[A-Za-z0-9-]*[A-Za-z0-9])?)$",
  emailListRegexString: '([A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+.[A-Za-z0-9]{2,}[,]?\\s*)+',
  emailB2BCustomerPortalRegexString: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$',
  irnRegexString: '^[1-9]{1}$',
  numberRegexString: '^[0-9]*$',
  addressInvalidCharsRegex: /[^A-Za-z0-9\s\/)('\.,&-]/,
  addressValidCharsRegexString: "^[A-Za-z0-9\\s/)('.,&-]+$",
  postCodeRegex: /\b(0[289][0-9]{2})|([1-9][0-9]{3})\b/g,
  postCodeRegexString: '^(0[289][0-9]{2})|([1-9][0-9]{3})$',
  base64StringRegex: /^[A-Za-z0-9\+\/=]+=$/,
  specialCharacters: ';:!"#$%&\')(*+,./<=>?@][^_`{|}\\~-',
  specialCharactersRegExp: /^[;:!"#$%&)(\*\+,\./<=>?@\'\]\[\\^_`{|}~-]$/,
  specialCharactersStringRegExp: '^[;:!"#$%&\')(*+,./<=>?@\\][^_`{|}\\\\~-]$',
  specialCharactersNameRegExp: /[\.\+\*\\\/\$\^\(\)\[\]\?\!":;|><}{@&#%~=0-9]/,
  // /^[;:!"#$%&)(\*\+\/<=>?@\]\[\^_`{|}~0-9]$/,

  // passwordRegex: /^[a-zA-Z0-9!@#$%^&*)(|.,_=+-)]{8,}$/,
  // passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
  // passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, // no special char required
  passwordRegex:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[;:!"#$%&\'\(\)*+,\.\/<=>?@\[\]^_`{|}~-])(?=.+([\\s]))?.{8,}$/,
  // passwordRegexString: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  passwordRegexString:
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[;:!"#$%&\\\'\\(\\)*+,\\.\\/<=>?@\\[\\]^_`{|}~-])(?=.+([\\s]))?.{8,}$',
  guidRegex: /^([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})$/i,
  guidRegexGlobal: /([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/gi,
  cardNameRegexString: "^[a-zA-Z'`. -]{2,26}$",
  cardNumberRegexString: '^[0-9]{13,19}$',
  cardExpiryRegexString: '^[0-9]{2}/[0-9]{2}$',
  cardCVCRegexString: '^[0-9]{3,4}$',
  UTC_Date_Format: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  UTC_Date_Format_Shortened: 'YYYY-MM-DDTHH:mm:ssZ',
  UTC_Date_Response_Format: 'YYYY-MM-DDTHH:mm:ss',
  YearFirst_DateTime_Format: 'YYYY-MM-DD HH:mm:ss',
  TimeFormat_Meridiem: 'hh:mm A',
  TimeFormat_24Hour: 'HH:mm:ss',
  GenericMed: /\b(generic)\b/i,
  EndPoint_Prefix: 'v1',
  MaxFailedAuthentications: 4,
  GUID_Length: 36,
  BlankGUID: '00000000-0000-0000-0000-000000000000',
  EnglishLanguageGUID: '590d5b6a-ee90-4f90-8984-4cdc6c0f90b2',
  API_Respose: {
    Success: 'success',
    Response: 'response',
    Error: 'error',
    ErrorData: 'errorData',
    Warning: 'warning'
  },
  API_PATHS: {
    validateHealthcareCards: '/healthCards/validate'
  },
  MICROFORM_ERROR_CODES: {
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    CREATE_TOKEN_VALIDATION_SERVERSIDE: 'CREATE_TOKEN_VALIDATION_SERVERSIDE',
    CREATE_TOKEN_NO_FIELDS_LOADED: 'CREATE_TOKEN_NO_FIELDS_LOADED'
  },
  API_ERROR_CODES: {
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    INVALID_TOKEN: 'INVALID_TOKEN',
    INVALID_ORDER_ID: 'INVALID_ORDER_ID',
    INVALID_DATA: 'INVALID_DATA',
    INVALID_USER: 'INVALID_USER',
    PHARMACY_NOT_FOUND: 'VERIFIED_PHARMACY_NOT_FOUND',
    USER_NOT_AUTHORIZED_FOR_RESOURCE: 'The user is not authorized to access the requested resource',
    CANT_CREATE_PATIENT_ACCOUNT_ALREADY_EXISTS: 'CANT_CREATE_PATIENT_ACCOUNT_ALREADY_EXISTS',
    PRACTITIONER_UNAVAILABLE: 'CANT_CREATE_APPOINTMENT_SELECTED_PRACTITIONER_UNAVAILABLE',
    CANT_CREATE_APPOINTMENT_NO_PRACTITIONER_AVAILABLE: 'CANT_CREATE_APPOINTMENT_NO_PRACTITIONER_AVAILABLE',
    ON_DEMAND_PRACTITIONER_UNAVAILABLE: 'ON_DEMAND_PRACTITIONER_UNAVAILABLE',
    ONDEMAND_QUEUE_EXPIRED: 'APPOINTMENT_ENDS_AFTER_SESSION',
    CANT_RESEND_SCRIPT: 'CANT_RESEND_SCRIPT',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    API_SERVER_500: 'Unexpected token < in JSON at position 0',
    API_SERVER_500_ALT: "Unexpected token '<'",
    POLICY_MAX_NUMBER_OF_USES_EXCEEDED: 'POLICY_MAX_NUMBER_OF_USES_EXCEEDED',
    INVALID_AGENCY_FOR_POLICY: 'INVALID_AGENCY_FOR_POLICY',
    INVALID_POLICY_METADATA_TOKEN: 'INVALID_POLICY_METADATA_TOKEN',
    POLICY_METADATA_TOKEN_REQUIRED: 'POLICY_METADATA_TOKEN_REQUIRED',
    INVALID_POLICY_NUMBER: 'INVALID_POLICY_NUMBER',
    MEDICARE_REQUIRED_FOR_POLICY: 'MEDICARE_REQUIRED_FOR_POLICY',
    PRICING_CHANGED: 'PRICING_CHANGED',
    NO_REDEMPTIONS_REMAINING: 'NO_REDEMPTIONS_REMAINING',
    CANT_CREATE_QUICKSCRIPT: 'CANT_CREATE_QUICKSCRIPT',
    INVALID_MEDICARE_NUMBER: 'INVALID_MEDICARE_NUMBER',
    INVALID_STATE: 'INVALID_STATE',
    INVALID_MED_SELECTION: 'INVALID_MED_SELECTION',
    MEDICARE_IHI_REQUIRED_FOR_ORDER: 'MEDICARE_IHI_REQUIRED_FOR_ORDER',
    NO_LINKED_EPT: 'NO_LINKED_EPT',
    CANT_CREATE_QUICKSCRIPT_SERVICE_PRICE_CHANGED: 'CANT_CREATE_QUICKSCRIPT_SERVICE_PRICE_CHANGED',
    INVALID_ADDRESS_DATA: 'INVALID_ADDRESS_DATA',
    DATA_EXPIRED: 'DATA_EXPIRED',
    MFA_ERROR: 'MFA_ERROR',

    APPOINTMENT_NOT_CLOSED: 'APPOINTMENT_NOT_CLOSED',
    CLOSED_WITHOUT_ESCRIPT: 'CLOSED_WITHOUT_ESCRIPT',
    INVALID_PATIENT: 'INVALID_PATIENT',
    APPOINTMENT_HAS_ORDER: 'APPOINTMENT_HAS_ORDER',
    SCRIPT_EXHAUSTED: 'SCRIPT_EXHAUSTED',
    CANT_CREATE_ORDER: 'CANT_CREATE_ORDER',

    // AvailabilityLocks
    CANT_RENEW_AVAILABILITY_LOCK_INVALID_TOKEN: 'CANT_RENEW_AVAILABILITY_LOCK_INVALID_TOKEN',
    CANT_RENEW_AVAILABILITY_LOCK_NOT_FOUND: 'CANT_RENEW_AVAILABILITY_LOCK_NOT_FOUND',
    CANT_RENEW_AVAILABILITY_LOCK_ALREADY_RELEASED: 'CANT_RENEW_AVAILABILITY_LOCK_ALREADY_RELEASED',
    CANT_RENEW_AVAILABILITY_LOCK_PAST_EXPIRATION: 'CANT_RENEW_AVAILABILITY_LOCK_PAST_EXPIRATION',

    POLICY_CONSTRAINTS_VIOLATED: 'POLICY_CONSTRAINTS_VIOLATED'
  },
  API_ERROR_DESCRIPTORS: {
    // The [startTime|endTime] can not be in the past
    SESSION_EXPIRED: 'can not be in the past',
    // There are no practitioners available at DD/MM/YYYY hh:MM:SS A TZ
    NO_PRACTITIONERS_AVAILABLE: 'no practitioners available'
  },
  POLICY_ERROR_CODES: {
    SELECTED_PRACTITIONER_DOES_NOT_SUPPORT_BENEFIT: 'SELECTED_PRACTITIONER_DOES_NOT_SUPPORT_BENEFIT',
    NO_PRACTITIONERS_SUPPORT_BENEFIT: 'NO_PRACTITIONERS_SUPPORT_BENEFIT'
  },
  MFA_ERROR_CODES: {
    MFA_MEDIUM_IN_COOLDOWN: 'MFA_MEDIUM_IN_COOLDOWN'
  },
  SCREEN_SIZE: {
    mobile: 576,
    tablet: 768,
    tablet_wide: 992,
    desktop: 1200,
    desktop_wide: 1440
  },
  TooltipDefaults: {
    showDelay: 350,
    hideDelay: 1200,
    touchendHideDelay: 250
  },
  PAGE_ELEMENTS: {
    mainScrollingContainer: 'main-scrolling-container',
    appointmentScrollingContainer: 'main-appointment-wrapper'
  },
  MedicationWarnings: {
    emergencyCare: `PLEASE CALL '000' IF THIS ENQUIRY IS AN EMERGENCY FOR IMMEDIATE CARE.`,
    controlledDrugs: `No Schedule 8 Controlled Drug medications or drugs of addiction will be prescribed.
      Schedule 4 drugs which have the potential to cause harm or be misused such as
      benzodiazepines, duromine, zolpidem, zopiclone, quetiapine and high strength
      codeine are not routinely prescribed and should be sourced from your regular GP.`,
    controlledDrugsProceed: `Please do not proceed if you are requesting these medications.`,
    quickScriptRepeatOnly: `Sorry. Our Quickscript service is only available for repeat prescriptions for
      medications you are currently taking. Please speak to a doctor online now or
      schedule an appointment to discuss your prescription needs.`,
    smokingCessationMedicationWarning: `The medication you've searched for requires a different type of
      consultation that's specific to smoking cessation and related medications. But don't worry, it's still
      only $19.90 and you can book via the button below.`,
    safeMedicationQuestionnaire: `This questionnaire forms the basis of today's medical consultation. Just as
      with your regular doctor, we need you to be honest with your answers so we can prescribe medication
      safely and give you the right advice. If you forget to tell us something or tell us something false,
      we may prescribe a medicine that harms rather than helps you. It is also important that you
      tell us about any other medication/vitamins/herbs you are taking regularly. The fee for the
      doctor's prescription is $19.90, which will be added to your cart upon completing the
      questionnaire along with the cost of the medication if requested.`,
    safeMedicationDisclaimer: `Our doctor will assess the answers to your questionnaire in conjunction
      with any medical history you have provided in your 'General Medical Questions'. Completion of the
      questionnaire does not automatically mean that your prescription will be approved. Further
      information may be sought by the doctor assessing your request.`,
    medicationDeliveryDisclaimer: `It is recommended to provide a work address for delivery if no one
      is home during business hours. Due to security issues, the parcel cannot be left unattended
      (will require a signature on receipt).`,
    prescriptionApprovalWarning: `Upon submitting this form a doctor will phone you to discuss your
      suitability for the requested medication. Approval of the medication request, and any repeat
      prescriptions, are at the doctor's discretion.`,
    prescriptionConstraintMessage: `We recommend a doctor reviews your condition every twelve months.
      Please proceed to a video consultation with a Doctor to review your medication request.`,
    medicareRebatesWarning: `Medicare rebates are no longer available due to the Government's
      withdrawal of eligibility of GP services through telehealth providers.`
  },
  BenefitCustomText: {
    medibankPrivateRioCustomerTypes: ['b2b Medibank Private' /*, 'b2b Rio'*/],
    medibankPrivateRioMessage:
      `You are redeeming a free consultation courtesy of Medibank Private.<br/><br/>
      This service is not provided by or governed by your employer. Your employer will not share any members'
      personal data with Doctors on Demand. If you register, Doctors on Demand will share your employee ID
      and full name with Medibank Private for billing purposes only. All data will be stored securely in
      accordance with the Provider <a href="` +
      environment.privacyPolicy +
      `" target="_blank">privacy policies</a>.
      Your use of and registration with Doctors on Demand is entirely voluntary and at your discretion.`,
    medicareBulkBillingSupported: `This is a Bulk Billed service supported by Medicare. No fees will be
      applied to your consultation.`
  },
  BookingFeeTooltipText: `We build and maintain our own virtual health platform so we can ensure we have the best
    tools available for both clinical and patient experience. A booking fee of $2.95 per appointment supports
    investment in our technology and the development of new healthcare services.`,
  WaitingRoomMessaging: {
    waitingForDoctor: `Please keep this tab open and the video appointment will commence automatically as
      soon as your doctor joins.`,
    doctorMayBeEarly: 'Please note that the doctor may be here early.'
  },
  HealthcareIdentifiersMessaging: {
    freeBulkBillAppointment: `Please provide your Medicare card details to receive a free
      bulk-billed appointment.`,
    rebatedBulkBillAppointment: `Please provide your Medicare card details to receive a Medicare Rebate for your appointment.`,
    provideIdentifiersForEScript: `If you receive a prescription, make sure it is an eScript straight to
      your phone. How? Provide us with one of these Australian Government healthcare identifiers.`,
    noValidMedicare: `If you do not have a valid Medicare card we are unable to provide you with a
      Medicare Rebate for this appointment. You can proceed without a Medicare card, however you will
      <strong>not receive</strong> an automatic Medicare Rebate for this appointment.`,
    serviceRequiresMedicareOrIHI: `This service requires a valid Medicare Card or Individual Healthcare Identifier (IHI) number. Please provide your Medicare Card details or IHI number to proceed.`,
  },
  ResApp_CompatibleDeviceList: {
    iOS: [
      'Apple iPhone 6s, 6s Plus',
      'Apple iPhone 7, 7 Plus',
      'Apple iPhone 8, 8 Plus',
      'Apple iPhone X, XR, XS Max, XS',
      'Apple iPhone 11, 11 Pro, 11 Pro Max',
      'Apple iPhone 12, 12 Mini, 12 Pro, 12 Pro Max',
      'Apple iPhone SE (1st generation)',
      'Apple iPhone SE (2nd generation)',
      'Apple iPhone 13, 13 Mini, 13 Pro, 13 Pro Max'
    ],
    android: [
      'Samsung Galaxy S9 (SM-G960F)',
      'Samsung Galaxy S9+ (SM-G965F)',
      'Samsung Galaxy S10 (SM-G973F)',
      'Samsung Galaxy S10+ (SM-G975F)',
      'Samsung Galaxy S20 (SM-G980F)',
      'Samsung Galaxy S21 (SM-G991B)',
      'Samsung Galaxy A51 (SM-A515F)'
    ]
  },
  ErrorMessages: {
    CANT_CREATE_ADDRESS: `We were unable to generate an address identifier for the specified
      address. Please check that the address is valid before proceeding. If you are using the
      autocomplete, you must select a street address from the list of matches displayed below it.
      If you are entering your address manually, you must fill in all of the address parts.`,
    NO_POSTAL_DROPOFF: 'The address you specified cannot be delivered to as it is missing a postal drop-off point.',
    APPOINTMENT_BOOKING_ERROR: `Apologies, an error occurred during the booking request. Please check that all
      of your payment details are correct and try again.<br/><br/>If the error persists, please contact support
      for assistance.`,
    APPOINTMENT_PRICING_ERROR: `An unspecified error has occurred while retrieving pricing for your
      appointment.<br/><br/>This can happen if you have already paid for this appointment. You may refresh
      the page to try again, or proceed to the Dashboard to see your current appointment bookings.`,
    NO_BENEFIT_REDEMPTIONS_REMAINING: `The currently applied benefit has reached its maximum number of redemptions.
      To proceed with booking please remove the current benefit and use a different benefit if applicable. If you believe that your
      benefit should not yet be at its maximum number of redemptions, please contact our customer support team.`,
    CONSENT_TERMS_POST_FAILED: `We were unable to save your consent to our terms and conditions. This is likely
      due to a server error or a browser extension that is blocking the request.`,
    CANT_CREATE_QUICKSCRIPT: `An issue occurred while processing your order.`,
    ONDEMAND_PRACTITIONER_NOT_AVAILABLE: `The selected practitioner is no longer available for an on-demand
      appointment.<br/><br/>Please schedule your appointment for another time or select a
      different practitioner.`
  },
  EXTERNAL_RESOURCES: {
    ngSpeedTestFile: {
      path: 'https://doctors-on-demand.imgix.net/wp-content/uploads/2023/06/1mb1.jpg',
      sizeInBytes: 1197292
    }
  },
  DEFAULT_THEME: {
    logoImg: 'assets/images/DOD_Logo_Horizontal_BlueBKG_RGB.png',
    coBrandLogoImg: 'assets/images/DOD_Logo_Horizontal_RGB.png',
    logoBg: '#2FB4D6',
    logoBgComplement: '#fff'
  },
  LanguageJson: {
    English: 'en',
    Indonesia: 'id'
  },
  ContactUs_EnquiryTypes: [
    'General Enquiry',
    'Patient Enquiry',
    'Corporate Enquiry',
    'Doctor Recruitment Enquiry',
    'What medications can I get via Doctors on Demand?',
    'Delete my Account'
  ],
  ResApp_Keywords: [
    'cough',
    'chest',
    'breath',
    'throat',
    'wheez',
    'weez', // misspelt 'wheez'
    'covid',
    'respiratory',
    'numonia', // misspelt 'pneumonia'
    'neumonia', // will match 'pneumonia'
    'bronchit', // will match 'bronchitis'
    'croup',
    'flu'
  ],
  ATTACHMENT_TYPES: {
    REFERRAL: 'Referral',
    RESULTS: 'MessageAttachment',
    MEDICAL_CERTIFICATE: 'MedicalCertificate',
    INVOICE: 'Invoice',
    INVOICE_LEGACY: 'InvoiceLegacy',
    SCRIPT: 'Script',
    MESSAGE_ATTACHMENT: 'MessageAttachment',
    CERTIFICATE_REFERRAL_LEGACY: 'CertificateOrReferralLegacy',
    SCRIPT_LEGACY: 'ScriptLegacy'
  },
  LocalStorage_Key: {
    // Do not change the following keys, they need to match the Wordpress site
    email: 'email',
    /**
     * @deprecated Security credentials are now stored in HTTP-only cookies
     */
    accessTokenKey: 'accessToken',
    /**
     * @deprecated Security credentials are now stored in HTTP-only cookies
     */
    refreshTokenKey: 'refreshToken',
    expiresInSecondsKey: 'expiresInSeconds',
    patientId: '_p',
    // Custom keys
    appointment: '_appointment',
    appointments: '_appointments',
    availabilityLockResourceAccessToken: '_availabilityLockResourceAccessToken',
    availabilityLockId: '_availabilityLockId',
    availabilityLockAbsoluteExpirationDateUtc: '_availabilityLockAbsoluteExpirationDateUtc',
    availabilityLockAcquiredLockDateUtc: '_availabilityLockAcquiredLockDateUtc',
    availabilityLockUserInactivityPromptThresholdSeconds: '_availabilityLockUserInactivityPromptThresholdSeconds',
    acceptedAppointmentTerms: '_acceptedAppointmentTerms',
    inappointment: '_inapp',
    benefit: '_benefit',
    benefit_object: '_benefit_object',
    quickscript: '_quickscript',
    quickScriptSession: '_quickScriptSession',
    associatedAccountEmail: '_associatedEmail',
    medicalHistory: '_medicalHistory',
    medicalHistoryFilled: '_medicalHistoryFilled',
    medicationsList: '_medicationList',
    medicationsListExpiryDate: '_medicationListExpiry',
    selectedMedications: '_selectedMedications',
    selectedMedicationsMap: '_qsMedications',
    selectedMedicationsArray: '_selectedMedicationsArray',
    associatedMedications: '_associatedMedications',
    medicalHistoryConstraints: '_medicalHistoryConstraints',
    patientsData: '_patientsData',
    serviceData: '_serviceData',
    stepList: '_stepList',
    stepServiceData: '_stepServiceData',
    pharmacies: '_pharmacies',
    titles: '_titles',
    relationshipTypes: '_relationshipTypes',
    timezones: '_timeZoneOptions',
    timezoneExpiry: '_timeZoneExpiry',
    practitioners: '_practitioners',
    practitionerList: '_practitionerList',
    availabilities: '_availabilities',
    availabilityDayGroups: '_availabilityDayGroups',
    prescriptions: '_prescriptions',
    medicalCertificates: '_medicalCertificates',
    referrals: '_referrals',
    results: '_results',
    legacyAttachments: '_legacy',
    scriptConstraints: '_scriptConstraints',
    currentlyTakingMedication: '_ctMedication',
    patientHasHadPolicyEligibilityNotification: '_patientPolicyEligibilityNotification',
    waitingroom: '_waitingroom',
    signup: '_signup',
    customDimensions: '_customDimensions',
    accountPatients: '_accountPatients',
    sessionAgencyCode: '_sessionAgencyCode',
    sessionB2BCode: '_sessionB2BCode',
    sessionServiceType: '_serviceType',
    isAuthenticated: '_isAuthenticated',
    agencyServiceConfiguration: '_agencyServiceConfiguration',
    agencyUIConfiguration: '_agencyUIConfiguration',
    userCustomerType: '_userCustomerType',
    activeCampaign: '_activeCampaign',
    ssaClientId: '_ssaClientId',
    ssaSessionId: '_ssaSessionId',
    defaultLanguage: '_defaultLanguage',
    publicHolidays: '_publicHolidays',
    patientAttributionAgencyId: '_patientAttributionAgencyId',
    patientAttributionB2BCustomerId: '_patientAttributionB2BCustomerId',
    smokingCessationCart: '_smokingCessationCart',
    smokingCessationAvailableMedications: '_smokingCessationAvailableMedications',
    smokingCessationFlattenedMedicationSelection: '_smokingCessationFlattenedMedicationSelection',
    smokingCessationOrderDetails: '_smokingCessationOrderDetails',
  },
  APPOINTMENT_BOOKING_TYPE: {
    SCHEDULED: 'Scheduled',
    ON_DEMAND: 'OnDemand',
    ALL: 'All'
  },
  APPOINTMENT_BILLING_TYPE: {
    ALL: 'All',
    PRIVATE: 'PrivateOnly',
    REBATE: 'RebateOnly',
    BULKBILL: 'BulkBillOnly',
    PRIVATE_REBATE: 'PrivateAndRebateAggregated'
  },
  Appointment_Status: {
    AppointmentPendingResponse: 'Appointment Pending Response',
    AppointmentPendingConfirmation: 'Appointment Pending Confirmation',
    AppointmentCancelled: 'Appointment Cancelled',
    AppointmentConfirmed: 'Appointment Confirmed',
    Delayed: 'Delayed',
    WaitingForDoctor: 'Waiting For Doctor',
    WaitingForDoctorRestart: 'Waiting For Doctor Restart',
    DoctorInSession: 'Doctor In Session',
    DoctorInSessionandPatientInSession: 'Doctor In Session and Patient In Session',
    SessionEnded: 'Session Ended',
    AppointmentFinished: 'Appointment Finished',
    AppointmentExpired: 'Appointment Expired',
    AppointmentClosed: 'Appointment Closed',
    AppointmentFinalised: 'Appointment Finalised'
  },
  AppointmentDataKey: {
    PrescriptionType: 'ReceivePrescriptionType',
    EmployerEmail: 'EmployerEmailAddress',
    PharmacyId: 'PharmacyId',
    ReferralEmailAddress: 'ReferralEmailAddress'
  },
  QUESTIONNAIRE_CONFIG: {
    questionTypes: {
      text: 'text', // text input
      check: 'check', // checkbox
      choiceGroup: 'choiceGroup', // radio buttons
      option: 'option', // drop-down
      'boolean': 'boolean', // toggle button (Yes/No)
      bloodPressure: 'bloodPressure' // mm/Hg
    },
    subQuestions: {
      choiceSubFields: 'choiceSubfields',
      subQuestionAnswer: 'subquestionAnswer',
      longAnswer: 'longAnswer',
      bloodPressureMm: 'bloodPressureMm',
      bloodPressureHg: 'bloodPressureHg'
    }
  },
  SERVICE_TIME_CONFIG: {
    BUSINESS_HOUR: 8,
    AFTER_HOURS: 18,
    BUSINESS_HOURS_FEE: 60,
    AFTER_HOURS_FEE: 90,
    BUSINESS_HOURS_BOOKING_FEE: 2.95,
    AFTER_HOURS_BOOKING_FEE: 2.95,
    // minutes before the appointment countdown button changes to 'Join'
    JOIN_TIME_THRESHOLD: 5,
    // to determine when to show countdown, join, and reshedule button (in minutes)
    APPOINTMENT_TIME_THRESHOLD: 30,
    // to determine when to show reshedule button after the appointment missed (in minutes)
    APPOINTMENT_TIME_THRESHOLD_BOTTOM: -30,
    // how long after starting an appointment would it's "recently booked" status expire?
    RECENTLY_BOOKED_THRESHOLD_BOTTOM: 5, // minutes
    // check that there isn't another appointment booked within a given timeframe
    RECENTLY_BOOKED_THRESHOLD_MINUTES: 360, // 6 hours,
    CANCELLATION_THRESHOLD: 1, // 1 minute before appointment start SMS
    // user can only partake in cough test if they have more than
    // half an hour before their appointment starts
    RESAPP_MINIMUM_MILLISECONDS: 1800000 // 30 mins
  },
  AppointmentTimeTable_Configuration: {
    initialDaysToDisplay: 2, // start by loading 2 days of appointments
    displayDaysBuffer: 2, // load 2 days at a time
    maxDaysToDisplay: 14, // do not display availabilities past the 2 week mark
    defaultAppointmentDuration: 15 // default duration in minutes
  },
  Medications_Configuration: {
    maxMedicationsPerSession: 1, //2,
    quickScriptOrderLimit_Private: 3,
    quickScriptOrderLimit_PBS: 2,
    quickScriptOrderLimit_Other: 1,
    resendPrescriptionDays: 30,
    expireMedicationListHours: 24
  },
  Weight_Loss_Configuration: {
    prescriptionPeriodDays: 90,
    reorderPeriodDays: 75,
    checkInOverdueDays: 7,
    displayDaysOnGraph: 10,
    weightGraphYAxisInterval: 5, // 10
    waistGraphYAxisInterval: 5,
    waistGoalDeviation: 0.2, // acceptable proximity to waist goal
    weightGoalDeviation: 0.5, // acceptable proximity to weight goal
    approvalStatuses: [ApprovalStatus.no, ApprovalStatus.yes, ApprovalStatus.pending]
  },
  Mental_Health_Configuration: {
    approvalStatuses: [ApprovalStatus.no, ApprovalStatus.yes, ApprovalStatus.pending],
    termNameConsentReferralToPsychPartner: 'MentalHealthSendReferralToPsychPartnerOptionalTerm',
    psychologyPartnerLabel: 'psychology partner',
    psychologyPartnerTooltipText: `We've partnered with Recharge Wellness so you can speak to a therapist via video or phone, generally within 24 hours.\n
      * Doctors on Demand may receive sponsorship fees. Recharge Wellness is not an emergency service. If you need emergency support, please call 000.`
    // `We've partnered with Recharge Wellness so you can speak to a therapist via video or phone, generally within 24 hours. We believe you should be able to access healthcare when you need it, wherever you are - which is why we've chosen a partner that allows you to receive therapy online.
    // * Doctors on Demand has partnered with online psychology providers and may receive sponsorship fees. Recharge Wellness is not an emergency service. If you need emergency support, please call 000.`
  },
  Search_Configuration: {
    medication_minSearchChars: 2,
    needHelp_minSearchChars: 3,
    needHelp_maxSearchResults: 3
  },
  Password_Strength_Configuration: {
    patternErrorMessage: 'Minimum 8 chars, 4 lower & uppercase letters, a number and a special character',
    DEFAULT_DEBOUNCE_MS: 500,
    MIN_LENGTH: 8,
    MAX_LENGTH: 32,
    RECOMMENDED_LENGTH: 16,
    MIN_NUMERIC_CHARS: 1,
    RECOMMENDED_NUMERIC_CHARS: 4,
    MIN_ALPHA_CHARS: 4,
    RECOMMENDED_ALPHA_CHARS: 10,
    MIN_UPPERCASE_ALPHA_CHARS: 1,
    MIN_SPECIAL_CHARS: 1,
    RECOMMENDED_SPECIAL_CHARS: 4,
    SPACES_NOT_ALLOWED: false
  },
  ListDisplay_Configuration: {
    appointmentsDashboardMaxShow: 3,
    previousAppointmentsMaxShow: 20,
    previousPrescriptionsMaxShow: 25,
    previousMedicalCertificatesMaxShow: 10,
    previousInvoicesMaxShow: 20,
    previousReferralsMaxShow: 20,
    previousResultsMaxShow: 20,
    prescriptionsDashboardMaxShow: 3,
    prescriptionsPageMaxShow: 0, // no limit,
    medicalCertificatesDashboardMaxShow: 3,
    medicalCertificatesPageMaxShow: 0,
    referralsDashboardMaxShow: 3,
    referralsPagesMaxShow: 0
  },
  API_Polling_Times: {
    // Polling times are purposefully staggered in order of priority
    pollAvailability_seconds: 35, // practitionerOnline
    pollAvailabilitySynth_seconds: 30, // unavailabilities
    pollAppointmentPricing_seconds: 105, // 1 min 45 sec
    pollUnreadMessages_seconds: 60,
    pollAppointmentDetails_seconds: 20,
    pollVideoConferenceRoomUrl_seconds: 12,
    postAppointmentTasks_seconds: 15,
    updateAppointmentAvailabilityFilter_seconds: 60,
    updatePractitionerList_seconds: 64,
    prescriptionList_seconds: 300, // 5 minutes
    medicalCerficitatesList_seconds: 325, // ~5.5 minutes
    referralsList_seconds: 355, // ~6 minutes
    resultsList_seconds: 340, // ~5.5 minutes
    patientNotifyWaiting_seconds: 3,
    legacyAttachmentsList_seconds: 385, // ~6.5 minutes
    customDimensionSessionTimes: 300, // 5 minutes
    customDimensionQueueSize: 310, // ~5 minutes,
    practitionerOnline_SecondsBetweenRequests: 5,
    appointmentPricing_SecondsBetweenRequests: 12,
    retrieveAppointmentList_SecondsBetweenRequests: 10,
    createAppointment_SecondsBetweenRequests: 60,
    cancelAppointment_SecondsBetweenRequests: 30,
    practitionerList_SecondsBetweenRequests: 10,
    googleAnalyticsSessionIds_SecondsBetweenRequests: 30,
    quickScriptServicePricing_SecondsBetweenRequests: 30,
    quickScriptMedicationQuestionnaire_SecondsBetweenRequests: 60,
    quickScriptConsentTerms_SecondsBetweenRequests: 30,
    quickScriptPurchaseOrder_SecondsBetweenRequests: 20,
    quickScriptOrderTotalPricing_SecondsBetweenRequests: 30,
    quickScriptMedicationConstraints_SecondsBetweenRequests: 60,
    findPharmacyByAddress_SecondsBetweenRequests: 3600, // 1hr
    getPatientById_SecondsBetweenRequests: 10,
    renewAvailabilityLockInterval_seconds: 30,
    patientVerificationMfaRetry_seconds: 5
  },
  API_Polling_Attempts: {
    patientVerificationMfaRetryCounts: 6
  },
  FileAttachment_Configuration: {
    maxFileSize: 4194304,
    acceptedAttachmentTypes: [
      'application/pdf',
      'image/jpeg',
      'image/pjpeg', // legacy
      'image/png',
      'image/x-png', // legacy
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'application/msword', // Microsoft Word
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // ODF Document
      'application/vnd.ms-excel', // Microsoft Excel
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // ODF Spreadsheet
      'application/vnd.ms-powerpoint', // Microsoft PowerPoint
      'application/vnd.openxmlformats-officedocument.presentationml.presentation' // ODF Presentation
    ],
    documentTypes: [
      'pdf', // Adobe PDF
      'docx', // Microsoft document
      'doc', // Microsoft document
      'odt', // Open Office document
      'ods', // Open Office spreadsheet
      'xlsx', // Microsoft Spreadsheet
      'xls', // Microsoft Spreadsheet
      'rtf', // Rich Text Format
      'txt' // Text file
    ],
    contentTypeMapping: new Map<string, string>([
      ['application/pdf', 'pdf'],
      ['image/jpg', 'jpeg'],
      ['image/jpeg', 'jpeg'], // this is not a real file type
      ['image/pjpeg', 'jpg'], // legacy jpeg
      ['image/png', 'png'],
      ['image/x-png', 'png'], // legacy png
      ['image/gif', 'gif'],
      ['image/bmp', 'bmp'],
      ['image/webp', 'webp'], // Google web picture
      ['application/msword', 'doc'], // microsoft document
      ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'docx'], // open office document
      ['application/vnd.ms-excel', 'xls'], // microsoft excel (spreadsheet)
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx'], // open office spreadsheet
      ['application/vnd.ms-powerpoint', 'ppt'], // microsoft powerpoint (presentation)
      ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'pptx'] // open office presentation
    ])
  },
  COMMUNICATION_PREFERENCES: [
    { value: 'account holder', text: "Send communications to the account holder's email" },
    { value: 'patient', text: "Send communication to the patient's email" },
    { value: 'both', text: "Send communications to both account holder and patient's email" }
  ],
  SALUTATIONS: [
    {
      name: 'Mr',
      displayOrder: 1
    },
    {
      name: 'Mrs',
      displayOrder: 2
    },
    {
      name: 'Ms',
      displayOrder: 3
    },
    {
      name: 'Miss',
      displayOrder: 4
    },
    {
      name: 'Mast',
      displayOrder: 5
    },
    {
      name: 'Dr',
      displayOrder: 6
    },
    {
      name: 'Prof',
      displayOrder: 7
    }
  ],
  PRODUCT_TYPE: {
    BOOKING_FEE: 'appointmentBookingFee'
  },
  DEFAULT_SERVICE_TYPES: JSON.stringify([PractitionerType.doctor]),
  SERVICE_TYPE: {
    DOCTOR: PractitionerType.doctor,
    BULKBILL_GP: PractitionerType.bulkbillgp,
    PSYCHOLOGY: PractitionerType.psychologist,
    DIETITIAN: PractitionerType.dietitian,
    WELLNESS: PractitionerType.wellness,
    FERTILITY: PractitionerType.fertility,
    WEIGHT_LOSS: PractitionerType.weightloss,
    MENTAL_HEALTH: PractitionerType.mentalhealth,
    SLEEP_SPECIALIST: PractitionerType.sleep,
    SLEEP_GP: PractitionerType.sleepgp,
    QUICKSCRIPT_DELIVERY: QuickScriptServiceType.quickscriptdeliver,
    QUICKSCRIPT_PHARMACY: QuickScriptServiceType.quickscriptpharm,
    QUICKSCRIPT: 'quickscript',
    SMOKING_CESSATION: PractitionerTypeConstants.smokingcessation
  },
  MODAL_TITLES: {
    changePractitioner: 'Change Practitioner',
    rescheduleAppointment: 'Reschedule Appointment',
    changeAppointmentTime: 'Change Appointment Time',
    cancelAppointment: 'Cancel Appointment',
    issueCreatingAppointment: 'Issue Creating Appointment',
    issueCreatingOrder: 'Issue Creating QuickScript Order',
    managePrescriptions: 'Manage Prescriptions',
    viewPrescription: 'View Prescription',
    manageAppointments: 'Manage Appointments',
    followUpAppointment: 'Follow-Up Appointment',
    preAppointmentQuestionnaire: 'Pre-appointment Questionnaire',
    viewQuickScriptOrder: 'View QuickScript Order',
    manageMedicalCertificates: 'Manage Medical Certificates',
    redeemABenefit: 'Redeem a Benefit',
    policyValidationRequired: 'Policy Validation Required',
    issueWithPolicy: 'Issue with Policy',
    issueWithOnDemandAppointment: 'Issue with On-Demand Appointment',
    issueWithPayment: 'Issue with Payment',
    issueWithAppointment: 'Issue with Appointment',
    issueSavingPatientProfile: 'Issue Saving Patient Profile',
    issueWithConsentTerms: 'Issue with Consent Terms',
    practitionersUnavailable: 'Practitioners Unavailable',
    practitionerNotAvailable: 'Practitioner Not Available',
    onDemandPractitionerNotAvailable: 'On-Demand Practitioner Not Available',
    sessionTimeHasPassed: 'Session Time has Passed',
    sessionTimeUnavailable: 'Session Time Unavailable',
    medicareCardRequired: 'Medicare Card Required',
    modalCart: 'Edit Cart',
    changePharmacy: 'Change Pharmacy',
    changeAddress: 'Change Address',
    confirmationDialog: 'Confirmation Dialog',
    paymentFormValidation: 'Payment Form Validation',
    issueWithPaymentProvider: 'Issue with Payment Provider',
    issueWithAppointmentPricing: 'Issue with Appointment Pricing',
    appointmentPricingChanged: 'Appointment Pricing Changed',
    appointmentAlreadyPaidFor: 'Appointment Already Paid For',
    noRedemptionsRemaining: 'No Redemptions Remaining for the Applied Benefit',
    patientDetailsUnavailable: 'Patient Details Unavailable',
    medicareCardDetailsRequired: 'Medicare Card Details Required for Bulk-Billed Appointment'
  },
  B2B_AGENCY: {
    BLUA: 'Blua',
    CITYFERTILITY: 'CityFertility',
    RAINBOWFERTILITY: 'RainbowFertility',
    SUPERPHARMACY: 'SuperPharmacy',
    ALLIANZMASTERCARD: 'AllianzMastercard',
    TALINSURANCE: 'TALInsurance'
  },
  AUTO_POLICY_AGENCIES: ['SUPERPHARMACY', 'TouchBiometrics', 'AllianzMastercard', 'TALInsurance', 'CoreLithium', 'WestFund', 'Jellinbah', 'Wyndham', 'MedAdvisor', 'HEALTHWISE'],
  PRESCRIPTION_STATUS: {
    // transitional state
    ACCEPTED: 'ACCEPTED',
    MODIFIED: 'MODIFIED',
    AWAITINGREVIEW: 'AWAITING_REVIEW',
    AWAITINGPAYMENT: 'AWAITINGPAYMENT',
    ORDERED: 'ORDERED',
    // finished state (good)
    SHIPPED: 'SHIPPED',
    PHARMACY: 'PHARMACY',
    FINISHED: 'FINISHED',
    // bad
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED',
    CLOSED: 'CLOSED'
  },
  QUICKSCRIPT_PRICING_TYPE: {
    PRIVATE: 'private',
    PBS: 'pbs',
    PENSION: 'pension',
    SAFETYNET: 'safetynet',
    HEALTH_CARE: 'healthcare'
  },
  ANALYTICS_DEFAULTS: {
    userCustomerType: CustomerType.b2c,
    reimbursementType: ReimbursementType.private,
    onDemand: 'on-demand',
    scheduled: 'scheduled'
  },
  CUSTOM_DIMENSIONS: new Map([
    ['onDemandAppointmentAvailable', 1],
    ['onDemandAppointmentQueueSize', 2],
    ['nextAppointmentAvailability', 3],
    ['userId', 4],
    ['userType', 5],
    ['userAgeAndGender', 6],
    ['userLocation', 7],
    ['multiAccountRelationship', 8],
    ['userSmoker', 9],
    ['userDrinker', 10],
    ['userHasHeartProblems', 11],
    ['userHasAllergies', 12],
    ['userTakesMedication', 13],
    ['userBMI', 14],
    ['userBloodPressureRange', 15],
    ['userMedicalCards', 16],
    ['userRegisteredThisSession', 17],
    ['userCustomerType', 18],
    ['HotjarId', 19],
    ['allowAdwords', 20]
  ]),
  ACTIVE_CAMPAIGN_EVENTS: {
    // Appointment
    BrowseAppointment: 'browse_appointment',
    StartAppointment: 'start_appointment',
    BookAppointment: 'book_appointment',
    CompleteAppointment: 'complete_appointment',
    CancelApppointment: 'cancel_appointment',
    FollowUpAppointment: 'followup_appointment',
    NoShowAppointment: 'noshow_appointment',
    // QuickScript
    BrowseQuickscript: 'browse_quickscript',
    StartQuickscript: 'start_quickscript',
    CompleteQuickscript: 'complete_quickscript',
    CancelQuickscript: 'cancel_quickscript',
    QuickScriptReorder: 'quickscript_reorder',
    ClosedUnreachable: 'closed_unreachable', // Admin event
    QuickScriptRequested: 'quickscript_requested',
    QuickScriptConfirmed: 'quickscript_confirmed', // Admin event
    QuickScriptDeclined: 'quickscript_declined', // Admin event
    // WeightLoss
    WeightLossStart: 'weight_loss_start',
    WeightLossEligibilityCheck: 'weight_loss_eligibility_check',
    WeightLossQualified: 'weight_loss_qualified',
    WeightLossNotQualified: 'weight_loss_not_qualified',
    WeightLossUpdated: 'weight_loss_updated',
    WeightLossRevisit: 'weight_loss_revisit',
    // MentalHealth
    DoctorComplete: 'doctor_complete',
    MentalHealthPaidFollowUpCreated: 'mhp_followup',
    MentalHealthPlanSentToPsychPartner: 'mhp_sent_to_partner',           // sent from Admin (not implemented)
    MentalHealthPlanConsentToPsychPartner: 'mhp_consent_provided',       // part of Book event
    MentalHealthPlanNoConsentToPsychPartner: 'mhp_consent_not_provided', // part of Book event
    // SmokingCessation
    SmokingCessationBrowseMedication: 'sc_browse_medication',
    SmokingCessationViewEScript: 'sc_view_escript',
    SmokingCessationCheckoutComplete: 'sc_complete_checkout',
    SmokingCessationCheckoutIncomplete: 'sc_incomplete_checkout',
    SmokingCessationEligibilityConfirmed: 'sc_confirmed',
    SmokingCessationEligibilityDeclined: 'sc_declined',
    SmokingCessationMedicationShipped: 'sc_medication_shipped'
  },
  ACTIVE_CAMPAIGN_EVENT_NAME_MAP: {
    // Appointment
    browse_appointment: 'Browse',
    start_appointment: 'Start',
    book_appointment: 'Book',
    followup_appointment: 'Book',
    cancel_appointment: 'Cancel',
    complete_appointment: 'Complete',
    noshow_appointment: 'No Show',
    // Quickscript
    browse_quickscript: 'Browse',
    start_quickscript: 'Start',
    complete_quickscript: 'Complete',
    cancel_quickscript: 'Cancel',
    quickscript_reorder: 'Reorder',
    closed_unreachable: 'Closed Unreachable',
    quickscript_requested: 'QuickScript Requested',
    quickscript_confirmed: 'QuickScript Order Confirmed',
    quickscript_declined: 'QuickScript Order Declined',
    // WeightLoss
    weight_loss_eligibility_check: 'Eligibility Check',
    weight_loss_qualified: 'Qualified',
    weight_loss_not_qualified: 'Not Qualified',
    weight_loss_updated: 'Updated',
    weight_loss_revisit: 'Revisit',
    weight_loss_start: 'Start',
    // MentalHealth
    doctor_complete: 'Doctor Complete',
    mhp_followup: 'Payment Pending',
    mhp_sent_to_partner: 'Sent', // not implemented in AC schema
    mhp_consent_provided: 'Consent Provided', // not implemented in AC schema
    mhp_consent_not_provided: 'Consent Not Provided', // not implemented in AC schema
    // SmokingCessation
    sc_browse_medication: 'Browse',
    sc_complete_checkout: 'Complete',
    sc_incomplete_checkout: 'Incomplete',
    sc_view_escript: 'View EScript',
    sc_confirmed: 'Confirmed',
    sc_declined: 'Declined',
    sc_medication_shipped: 'Shipped'
  },
  MONTHS: [
    {
      code: '01',
      name: 'January'
    },
    {
      code: '02',
      name: 'February'
    },
    {
      code: '03',
      name: 'March'
    },
    {
      code: '04',
      name: 'April'
    },
    {
      code: '05',
      name: 'May'
    },
    {
      code: '06',
      name: 'June'
    },
    {
      code: '07',
      name: 'July'
    },
    {
      code: '08',
      name: 'August'
    },
    {
      code: '09',
      name: 'September'
    },
    {
      code: '10',
      name: 'October'
    },
    {
      code: '11',
      name: 'November'
    },
    {
      code: '12',
      name: 'December'
    }
  ],
  MILLISECONDS_IN_SECOND: 1000,
  SECONDS_IN_MINUTE: 60,
  MINUTES_IN_HOUR: 60,
  HOURS_IN_DAY: 24,
  MEDICAL_SMOKING_PERIOD_OPTIONS: [
    {
      value: null,
      label: 'Please Select'
    },
    {
      value: '2 years or less',
      label: '2 years or less'
    },
    {
      value: '3 to 4 years',
      label: '3 to 4 years'
    },
    {
      value: '5 years or more',
      label: '5 years or more'
    }
  ],
  MEDICAL_SMOKING_AMOUNT_OPTIONS: [
    {
      value: null,
      label: 'Please Select'
    },
    {
      value: '10 or fewer',
      label: '10 or fewer'
    },
    {
      value: '11 to 20',
      label: '11 to 20'
    },
    {
      value: '20 or more',
      label: '20 or more'
    }
  ],
  MEDICAL_ALCOHOL_OPTIONS: [
    {
      value: null,
      label: 'Please Select'
    },
    {
      value: 'Not at all',
      label: 'Not at all'
    },
    {
      value: 'Rarely',
      label: 'Rarely'
    },
    {
      value: 'Less than 2 standard drinks per day',
      label: 'Less than 2 standard drinks per day'
    },
    {
      value: 'More than 2 standard drinks per day',
      label: 'More than 2 standard drinks per day'
    },
    {
      value: 'More than 4 standard drinks per day',
      label: 'More than 4 standard drinks per day'
    }
  ],
  COOKIES: {
    PWA_AFFINITY: '__PwaAffinity'
  },
  B2B_CUSTOMER_NAME: {
    BLUA_BVAC: 'Blua BVAC'
  },
  ExternalURLs: {
    SERVICES_AUSTRALIA: {
      HOW_TO_GET_AN_IHI: 'https://www.servicesaustralia.gov.au/how-to-get-individual-healthcare-identifier'
    }
  },
  VERIFICATION_INPUT_CHAR_LENGTH: 6,
};

export const ConstantsAux = {
  ServiceWarnings: {
    publiHolidayServiceFeeWarningPrelim: `Please note that public holiday video appointments are $${Constants.SERVICE_TIME_CONFIG.AFTER_HOURS_FEE}.`,
    weekendServiceFeeWarningPrelim: `Please note that healthcare video appointments are $${Constants.SERVICE_TIME_CONFIG.AFTER_HOURS_FEE} on weekends.`,
    publiHolidayServiceFeeWarning: 'Public Holiday video appointment fees have been applied to your booking.',
    saturdayServiceFeeWarning: 'Saturday video appointment fees have been applied to your booking.',
    sundayServiceFeeWarning: 'Sunday video appointment fees have been applied to your booking.',
    serviceAvailabilityAustralia: 'This service is only available to patients whilst in Australia.'
  }
};

export const PatientMissedAppointmentText = 'This appointment is over. The doctor has marked the status as "Patient did not attend".';

export const PREVENT_EXPIRED_TOKEN_LOGOUT = new HttpContextToken<boolean>(() => false);
