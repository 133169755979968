import { Labels } from './constants';
import { Step } from './models/step';

// Temporarily separated into another file to avoid conflicts.
// This can be applied in the future.

const COMMON_STEP_CONFIGURATION = {
  APPOINTMENT_TYPE_DOCTOR: {
    // BEFORE_YOU_PROCEED: { path: 'appointment-intro', virtualPath: 'before-you-proceed', label: Labels.beforeProceed },
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  }
};

const COMMON_STEP_PATH = {
  SIGN_UP: 'signup'
};

export type StepHierarchy = Record<
  Exclude<keyof typeof COMMON_STEP_CONFIGURATION, 'APPOINTMENT_TYPE_PSYCHOLOGY' | 'ONDEMAND_TYPE_DOCTOR'>,
  Step[]
>;

export const commonStepHierarchy = {
  chooseTime: {
    label: Labels.chooseTime,
    link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.virtualPath,
    stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.path,
    display: true,
    finish: false,
    isClickable: false,
    partList: []
  },
  registerLogin: {
    label: Labels.registerLogin,
    link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.SIGNUP_OR_LOGIN.virtualPath,
    stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.SIGNUP_OR_LOGIN.path,
    display: true,
    finish: false,
    isClickable: false,
    partList: [
      {
        label: Labels.accountHolder,
        link:
          COMMON_STEP_PATH.SIGN_UP + '/' + COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.ACCOUNT_HOLDER.virtualPath,
        stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.ACCOUNT_HOLDER.path,
        display: false,
        selected: false,
        finish: false,
        isClickable: false
      },
      {
        label: Labels.patientData,
        link:
          COMMON_STEP_PATH.SIGN_UP + '/' + COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_DATA.virtualPath,
        stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_DATA.path,
        display: false,
        selected: false,
        finish: false,
        isClickable: false
      },
      {
        label: Labels.medicalHistory,
        link:
          COMMON_STEP_PATH.SIGN_UP +
          '/' +
          COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.MEDICAL_HISTORY.virtualPath,
        stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.MEDICAL_HISTORY.path,
        display: false,
        selected: false,
        finish: false,
        isClickable: false
      }
    ]
  },
  confirmMedicalHistory: {
    label: Labels.confirmMedicalHistory,
    link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CONFIRM_MEDICAL_HISTORY.virtualPath,
    stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CONFIRM_MEDICAL_HISTORY.path,
    display: false, // do not show by default
    finish: false,
    isClickable: false,
    partList: []
  },
  healthcareDetails: {
    label: Labels.healthcareDetails,
    link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.HEALTHCARE_CARD_DETAILS.virtualPath,
    stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.HEALTHCARE_CARD_DETAILS.path,
    display: true,
    finish: false,
    isClickable: false,
    partList: []
  },
  confirmAppointment: {
    label: Labels.confirmAppointment,
    link: null,
    stepPath: null,
    display: true,
    finish: false,
    isClickable: false,
    partList: [
      {
        label: Labels.redeemBenefit,
        link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
        stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.REDEEM_BENEFIT_AND_CONSENT.path,
        display: true,
        selected: false,
        finish: false,
        isClickable: false
      },
      {
        label: Labels.patientVerification,
        link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_VERIFICATION.virtualPath,
        stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_VERIFICATION.path,
        display: true,
        selected: false,
        finish: false,
        isClickable: false
      },
      {
        label: Labels.payment,
        link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PAYMENT.virtualPath,
        stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PAYMENT.path,
        display: true,
        selected: false,
        finish: false,
        isClickable: false
      }
    ]
  },
  chatWithPractitioner: {
    label: Labels.chatWithPractitioner,
    link: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
    stepPath: COMMON_STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHAT_WITH_YOUR_PRACTITIONER.path,
    display: true,
    finish: false,
    isClickable: false,
    partList: []
  }
} as const;
