import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Functions } from '@src/app/shared/functions';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { CredentialsService } from './credentials.service';
import { Logger } from './logger.service';
import { first } from 'rxjs';

const log = new Logger('B2BAuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class B2BAuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private b2bCustomerService: B2BCustomerService,
    private functions: Functions
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.credentialsService.isAuthenticated()) {
      this.credentialsService.retrieveStoredCredentials();
    }

    const authenticated: boolean = this.checkIsAuthenticated();

    if (!authenticated) {
      return Promise.resolve(authenticated);
    }

    const validB2BCustomerInfo = this.checkValidB2BCustomerInfo();

    return Promise.resolve(validB2BCustomerInfo);
  }

  checkValidB2BCustomerInfo(): boolean {
    const infoLoaded = this.b2bCustomerService.b2bCustomerPortalInfoLoaded;
    if (infoLoaded) {
      return true;
    }

    this.functions.showToast('Business information not correctly set. Please try the action again.');
    if (this.b2bCustomerService.b2bCode) {
      this.router.navigate(['/bp/' + this.b2bCustomerService.b2bCode]);
    } else {
      this.router.navigate(['/']);
    }

    return false;
  }

  checkIsAuthenticated(): boolean {
    if (this.credentialsService.isAuthenticated()) {
      return true;
    }

    log.info('B2B customer user not authenticated, redirecting back to the B2B portal');

    this.functions.showToast('User login information is incorrect. Please try the action again.');
    if (this.b2bCustomerService.b2bCode) {
      this.router.navigate(['/bp/' + this.b2bCustomerService.b2bCode]);
    } else {
      this.router.navigate(['/']);
    }

    return false;
  }
}
