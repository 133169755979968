import { Directive, HostListener, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { AuthenticationService } from '@src/app/core/services/authentication.service';
import { CodeInputComponent } from 'angular-code-input';

@Directive()
export abstract class BaseVerificationFormComponent implements OnInit {
  @Input() showSuccess: boolean = false;
  @Input() patientId: string;
  @Input() prevCode: string;
  @Input() prevVerified: boolean;
  @Input() serviceType: string = Constants.SERVICE_TYPE.DOCTOR;
  @Input() nextStepLabel: string = 'Next';
  @Input() signupMode: boolean = false;

  @ViewChild(CodeInputComponent) codeInput: CodeInputComponent;

  Constants = Constants;
  mfaEnrolmentId: string;

  // State
  isMobile: boolean = false;
  isNarrowMobile: boolean = false;
  isWideDesktop: boolean = false;
  isLoading: boolean = false;
  codeSent: boolean = false;
  codeComplete: boolean = false;
  code: string;
  saveSuccessful: boolean;
  invalidInput: boolean;
  error: boolean;

  protected functions: Functions;
  protected authService: AuthenticationService;

  constructor(injector: Injector) {
    this.functions = injector.get(Functions);
    this.authService = injector.get(AuthenticationService);

    this.onResize();
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
    this.isNarrowMobile = window.innerWidth < 490;
    this.isWideDesktop = window.innerWidth > 1354 || (window.innerWidth >= 991 && window.innerWidth < 1200);
  }

  ngOnInit(): void {
    if (this.prevVerified) {
      this.saveSuccessful = true;
    }
    this._init();
  }

  protected async _init(): Promise<void> {
    if (!this.prevVerified && (this.patientId || this.signupMode)) {
      this.sendCode();
    }
  }

  onCodeChanged(code: string) {
    this.codeComplete = code?.length == Constants.VERIFICATION_INPUT_CHAR_LENGTH || false;
    this.code = code;
  }

  onCodeCompleted(code: string) {
    this.code = code;
    this.validateCode();
  }

  resendCode() {
    this.codeInput.reset();
    this.sendCode();
    this.showResendSuccessMessage();
  }

  protected abstract sendCode(): Promise<void>;
  protected abstract validateCode(): Promise<void>;
  protected abstract showResendSuccessMessage(): void;
  protected abstract showFailedToast(): void;
  protected abstract notifyVerified(): void;
}
