<div class="modal-wrapper">
  <form [formGroup]="inputForm" (submit)="onSubmit()">
    <div class="modal-header container-fluid align-items-center">
      <div class="col-10 pl-0">
        <h4 class="text-primary header-title mb-0" *ngIf="!isShowConfirm">{{ titles.redeemABenefit }}</h4>
        <h4 class="text-primary header-title mb-0" *ngIf="isShowConfirm">Benefit has been applied</h4>
      </div>
      <div class="col-2 pr-0">
        <button type="button" class="close" aria-label="Close" (click)="onClose(isCodeInvalid ? 'invalid' : '')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div *ngIf="!isShowConfirm" class="modal-body container-fluid">
      <ng-container *ngIf="isLoading; else loaded">
        <div class="mt-5" style="min-height: 143px;">
          <loading-spinner></loading-spinner>
        </div>
      </ng-container>
      <ng-template #loaded>
        <p class="col-12 px-0 text-left text-secondary">
          Does your insurer, employer or other provider offer Doctors on Demand services to you?
          To verify your benefit, please provide your membership, policy or voucher code.
        </p>
        <mat-card appearance="outlined">
          <mat-card-subtitle class="text-secondary">
            Please enter your membership or policy number:
          </mat-card-subtitle>
          <mat-card-content [class.error-padding]="benefit.invalid && (benefit.dirty || benefit.touched)">
            <div class="form-container">
              <div class="form-group m-0">
                <mat-form-field appearance="outline" class="custom-field w-100">
                  <input
                    formControlName="benefit"
                    type="text"
                    matInput
                    required />
                  <mat-error *ngIf="benefit.invalid && benefit.errors && (benefit.dirty || benefit.touched)">
                    <span *ngIf="benefit.hasError('required')" class="custom-error">
                      This field can't be empty
                    </span>
                    <span *ngIf="benefit.hasError('invalidCode')" class="custom-error">
                      {{ invalidCodeErrorMessage }}
                    </span>
                    <span *ngIf="benefit.hasError('invalidDiscount')" class="custom-error">
                      The benefit you have entered is not valid for your chosen appointment time
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </div>

    <div *ngIf="isShowConfirm" class="modal-body modal-body-confirm container-fluid d-flex flex-column align-items-center">
      <p class="text-secondary">You have successfully redeemed this code</p>

      <img [src]="benefitImage" class="img-fluid" alt="company logo" style="border: 0" />

      <span class="b2c-dod text-secondary text-small">
        <span *ngIf="customerType === 'b2c DoD'">Doctors on Demand Pty Ltd</span>
      </span>

      <span class="text-info text-small">Type Voucher/Membership</span>
      <span *ngIf="showBenefitCode" class="text-accent text-large">{{benefit.value}}</span>
      <span class="text-accent text-small">{{benefitText}}</span>

      <div *ngIf="benefitDisclaimerText?.length" [innerHtml]="benefitDisclaimerText" class="benefit-disclaimer"></div>
    </div>

    <div *ngIf="!isShowConfirm" class="modal-footer container-fluid bg-muted flex-nowrap justify-content-between">
      <ng-container *ngIf="isCodeInvalid === false">
        <div *ngIf="!isMobile" class="row m-0 w-100">
          <div class="first-button col-xl-6 col-xs-12">
            <button
              mat-stroked-button
              type="button"
              color="accent"
              class="btn-block"
              (click)="onClose()">
              Cancel
            </button>
          </div>
          <div class="second-button col-xl-6 col-xs-12">
            <button
              mat-flat-button
              type="submit"
              color="accent"
              class="btn-block"
              [disabled]="inputForm.invalid || !benefit.value || isLoading">
              Submit
            </button>
          </div>
        </div>
        <div *ngIf="isMobile" class="row m-0 w-100">
          <div class="first-button col-xl-6 col-xs-12">
            <button
              mat-flat-button
              type="submit"
              color="accent"
              class="btn-block"
              [disabled]="inputForm.invalid || !benefit.value || isLoading">
              Submit
            </button>
          </div>
          <div class="second-button col-xl-6 col-xs-12">
            <button
              mat-stroked-button
              type="button"
              color="accent"
              class="btn-block"
              (click)="onClose()">
              Cancel
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isCodeInvalid">
        <div *ngIf="!isMobile" class="row m-0 w-100">
          <div class="first-button col-xl-6 col-xs-12">
            <button
              mat-stroked-button
              type="button"
              color="accent"
              class="btn-block"
              [disabled]="isLoading"
              (click)="onClose('time')">
              Please Choose a New <br />
              Appointment Time
            </button>
          </div>
          <div class="second-button col-xl-6 col-xs-12">
            <button
              mat-flat-button
              type="button"
              color="accent"
              class="btn-block"
              [disabled]="isLoading"
              (click)="onClose('remove')">
              Continue without redeeming <br />
              benefit
            </button>
          </div>
        </div>
        <div *ngIf="isMobile" class="row m-0 w-100">
          <div class="first-button col-xl-6 col-xs-12">
            <button
              mat-flat-button
              type="button"
              color="accent"
              class="btn-block"
              [disabled]="isLoading"
              (click)="onClose('remove')">
              Continue without redeeming <br />
              benefit
            </button>
          </div>
          <div class="second-button col-xl-6 col-xs-12">
            <button
              mat-stroked-button
              type="button"
              color="accent"
              class="btn-block"
              [disabled]="isLoading"
              (click)="onClose('time')">
              Please Choose a New <br />
              Appointment Time
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="isShowConfirm" class="modal-footer container-fluid bg-muted flex-nowrap justify-content-between">
      <div *ngIf="!isMobile" class="row m-0 w-100">
        <div *ngIf="isShowRemove" class="first-button col-xl-6 col-xs-12">
          <button
            mat-stroked-button
            type="button"
            color="accent"
            class="btn-block"
            [disabled]="isLoading"
            (click)="onRemove()">
            Remove the benefit
          </button>
        </div>
        <div class="second-button" [ngClass]="isShowRemove ? 'col-6' : 'col-12'">
          <button
            mat-flat-button
            type="button"
            color="accent"
            class="btn-block"
            (click)="onClose()">
            Close
          </button>
        </div>
      </div>
      <div *ngIf="isMobile" class="row m-0 w-100">
        <div class="first-button col-xl-6 col-xs-12">
          <button
            mat-flat-button
            type="button"
            color="accent"
            class="btn-block"
            (click)="onClose()">
            Close
          </button>
        </div>
        <div *ngIf="isShowRemove" class="second-button col-xl-6 col-xs-12">
          <button
            mat-stroked-button
            type="button"
            color="accent"
            class="btn-block"
            [disabled]="isLoading"
            (click)="onRemove()">
            Remove the benefit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
