<div *ngIf="bmi" class="bmi-meter-wrapper" [class.with-data]="!!bmi">
  <div class="bmi-meter">
    <div
      *ngFor="let pill of colouredPills; let x = index"
      [class]="'col-auto pill-container ' + pill?.cssClass"
      [ngClass]="{ 'selected-pill': highlightPillNumber === x }">
      <div *ngIf="pill.bmiValue" class="pill-content">{{ pill.bmiValue }}</div>
    </div>
  </div>
</div>
