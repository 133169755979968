import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  HostBinding,
  OnDestroy,
} from '@angular/core';
import { PasswordStrengthMeterService } from './password-strength-meter.service';
import { Subscription } from 'rxjs';
import { FeedbackType, ZxcvbnResult } from '@zxcvbn-ts/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'password-strength-meter',
  templateUrl: './password-strength-meter.component.html',
  styleUrls: ['./password-strength-meter.component.scss'],
})
export class PasswordStrengthMeterComponent implements OnChanges, OnDestroy {
  @Input() password: string;
  @Input() minPasswordLength: number = 8;
  @Input() enableFeedback: boolean = false;
  @Input() colors: string[] = [];
  @Input() numberOfProgressBarItems: number = 5;
  @Input() userInputs: string[];
  @Input() enableDebounce: boolean = false;
  @Input() enableStrengthLabel: boolean = true;

  @Output() resultUpdated: EventEmitter<ZxcvbnResult> = new EventEmitter<ZxcvbnResult>();

  @HostBinding('class') baseClass: string = 'psm';

  private _prevPasswordStrength = null;
  private subscription = new Subscription();

  passwordStrength: number = null;
  feedback: FeedbackType = null;
  latestResult: ZxcvbnResult;
  debounceFunction: any;
  passwordScoreString: string;
  passwordScoreStringClass: string;

  constructor(
    private passwordStrengthMeterService: PasswordStrengthMeterService
  ) {
    this.subscription.add(
      this.passwordStrengthMeterService.resultUpdatedObs.subscribe((result: ZxcvbnResult) => {
        this.passwordStrength = result?.score || 0;
        this.feedback = result?.feedback || null;

        if (this.enableStrengthLabel) {
          // Check password score and update the output for password strength component
          switch (this.passwordStrength) {
            case 0: {
              this.passwordScoreString = 'Very Weak';
              this.passwordScoreStringClass = 'weak';
              break;
            }
            case 1: {
              this.passwordScoreString = 'Weak';
              this.passwordScoreStringClass = 'weak';
              break;
            }
            case 2: {
              this.passwordScoreString = 'Moderate';
              this.passwordScoreStringClass = 'moderate';
              break;
            }
            case 3: {
              this.passwordScoreString = 'Strong';
              this.passwordScoreStringClass = 'strong';
              break;
            }
            case 4: {
              this.passwordScoreString = 'Very Strong';
              this.passwordScoreStringClass = 'strong';
              break;
            }
          }
        }

        this.resultUpdated.next(result);

        if (this._prevPasswordStrength !== this.passwordStrength) {
          this._prevPasswordStrength = this.passwordStrength;
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.password) {
      this.calculatePasswordStrength();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async calculatePasswordStrength() {
    if (!this.password?.length) {
      this.passwordStrength = null;
      this.feedback = null;
      this.resultUpdated.next(null);

    // Don't score passwords with less characters than specified
    } else if (this.password.length < this.minPasswordLength) {
      this.passwordStrength = 0;
      this.feedback = null;
      this.resultUpdated.next(null);

    } else {
      if (this.enableDebounce && !this.debounceFunction) {
        this.debounceFunction = this.passwordStrengthMeterService.debouncedZxcvbn(this.enableFeedback, 500);
      }

      if (this.enableFeedback) {
        await this.passwordStrengthMeterService.scoreWithFeedbackAsync(
          this.password,
          this.userInputs
        );
      } else {
        await this.passwordStrengthMeterService.scoreAsync(
          this.password,
          this.userInputs
        );
      }
    }
  }
}
