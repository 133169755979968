import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Practitioner } from '@app/shared/models/practitioner';
import { PractitionerService } from '@app/shared/services/practitioner.service';
import { Functions } from '@app/shared/functions';
import { Language, LanguageCode } from '@app/shared/models/language';
import externalUrls from '@app/shared/config/external-urls.json';

@Component({
  selector: 'practitioner-profile',
  templateUrl: './practitioner-profile.component.html',
  styleUrls: ['./practitioner-profile.component.scss']
})
export class PractitionerProfileComponent implements OnInit {
  practitioner: Practitioner;
  closeButtonOnly: boolean;
  redirectUrl: string = externalUrls.practitionerRegistry;
  isMobile: boolean;
  isLoading: boolean;
  languageCodes: LanguageCode[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PractitionerProfileComponent>,
    private practitionerService: PractitionerService,
    private functions: Functions
  ) {
    this.onResize();
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const practitionerId: string = this.data.practitionerId;
    this.closeButtonOnly = this.data.closeButtonOnly;

    if (practitionerId) {
      this.isLoading = true;
      // this.data.practitioner ??
      const practitioner: Practitioner = await this.practitionerService
        .getPractitionerById(practitionerId)
        .catch((err: any) => {
          console.log('Unable to retrieve Practitioner profile. Error:', this.functions.getErrorMessage(err));
          return this.data.practitioner || null;
        });
      this.isLoading = false;

      if (practitioner) {
        this.practitioner = practitioner;

        const languages: Language[] = practitioner.languagesSpoken;

        if (languages) {
          languages.forEach((language: Language) => {
            this.languageCodes.push({
              languageCode: 'fi-' + language.flag,
              languageName: language.name
            });
          });
        }
      }
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSelect(): void {
    this.dialogRef.close(this.practitioner);
  }

  redirectTo(registrationNumber: string): void {
    if (registrationNumber) {
      window.open(`${this.redirectUrl}?reg=${registrationNumber}`, '_blank');
    }
  }
}
