<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 pl-0">
      <p class="subtitle">{{ dialogData.subtitle }}</p>
      <p class="title">{{ dialogData.title }}</p>
    </div>
    <div class="col-2">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-center">
    <ng-container *ngIf="isLoading; else loaded">
      <div class="my-5 processing-spinner">
        <loading-spinner [loadingText]="'Testing speed...'"></loading-spinner>
      </div>
    </ng-container>
    <ng-template #loaded>
      <div class="caption-regular">The suggested speed for a video call is <span class="bolder">1 Mbps</span></div>
      <div class="caption-bold header-title">Your speed was:</div>
      <h2 class="header-title">{{ internetSpeed }} Mbps</h2>
      <div class="caption-regular" *ngIf="isSufficient">
        Your connection is likely <span class="good">sufficient for our service</span>
      </div>
      <div class="caption-regular" *ngIf="!isSufficient">
        Your connection is likely <span class="bad">not sufficient for our service</span>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer bg-muted flex-nowrap mx-0">
    <div class="col-12 m-0">
      <button mat-flat-button color="accent" class="btn-block" (click)="onClose()">Close</button>
    </div>
  </div>
</div>
