import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Constants, Labels } from '../constants';
import { Step, StepPart } from '../models/step';
import { STEP_CONFIGURATION, STEP_PATH, STEP_HIERARCHY } from '../step-configuration';
import { MedicationBrand } from '../models/associated-medications';
import { AppointmentStepData } from '../models/appointment-step-data';
import { QuickScriptStepData } from '../models/quick-script-step-data';
import { RegistrationStepData } from '../models/sign-up-step-data';
import { WaitingRoomStepData } from '../models/waiting-room-step-data';
import { CoreStepData } from '../models/core-step-data';

export enum SpecialPaths {
  regularMeds = 'medications',
  qsMeds = 'quick-script-medications',
  quickScript = 'quick-script'
}

@Injectable({
  providedIn: 'root'
})
export class StepService implements OnDestroy {
  private subscription = new Subscription();

  private _serviceData: any = {};
  private _stepServiceDataChange: Subject<any> = new Subject<any>();
  public stepServiceDataObs = this._stepServiceDataChange.asObservable();
  private _serviceType: string = Constants.SERVICE_TYPE.DOCTOR;

  private _stepType: string;
  private _stepList: Step[] = [];

  private _listChange: Subject<Step[]> = new Subject<Step[]>();
  public listChangeObs = this._listChange.asObservable();

  private _listChangeBehaviorSubject: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>(null);
  public listChangeObsBehavior = this._listChangeBehaviorSubject.asObservable();

  private _totalStep: number = 0; // Total number of steps in current flow
  private _completeStep: number = 0; // Number of steps completed
  private _stepperIndex: number = 0; // Selected parent step
  private _currentStep: number = 0; // Current step or sub-step number

  private _stepChange: Subject<any> = new Subject<any>();
  public stepChangeObs = this._stepChange.asObservable();

  private _mobileStepIndicatorTrigger: Subject<boolean> = new Subject<boolean>();
  public mobileStepIndicatorTriggerObs = this._mobileStepIndicatorTrigger.asObservable();

  constructor(private router: Router) {
    this._init();
  }

  private _init(): void {
    // STEP LIST UPDATED
    // this.subscription.add(
    //   this.listChangeObs.subscribe(() => {
    //     // this.copyStepsToServiceData();
    //   })
    // );

    // STEP DATA UPDATED
    this.subscription.add(
      this.stepChangeObs.subscribe(() => {
        this.copyStepsToServiceData();
      })
    );

    // STEP SERVICE DATA UPDATED
    this.subscription.add(
      this.stepServiceDataObs.subscribe(() => {
        this.copyStepsToServiceData();
      })
    );
  }

  /**
   * @function getStepPath
   * @description Given a step type and router link (virtualPath), return the associated stepPath / step name.
   *
   * @param {string} stepType The step configuration type (key) to search through
   * @param {string} link The route name / link to search for
   *
   * @returns Step or sub-step path/name
   */
  getStepPath(stepType: string, link: string): string {
    let stepPath: string = '';

    if (stepType && STEP_CONFIGURATION[stepType] && this.stepList?.length) {
      const steps: string[] = Object.keys(STEP_CONFIGURATION[stepType]);
      for (let i = 0; i < steps.length; i++) {
        const key: string = steps[i];
        if (STEP_CONFIGURATION[stepType][key].virtualPath === link) {
          stepPath = STEP_CONFIGURATION[stepType][key].path;
          break;
        }
      }
    }

    return stepPath;
  }

  /**
   * @function getVirtualPath
   * @description Given a step type and path (stepPath), return the associated router link (virtualPath)
   *
   * @param {string} stepType The step configuration type (key) to search through
   * @param {string} path The step or sub-step name (stepPath) to search for
   *
   * @returns Step or sub-step router link (virtualPath)
   */
  getVirtualPath(stepType: string, path: string): string {
    let virtualPath: string = '';

    if (stepType && STEP_CONFIGURATION[stepType] && this.stepList?.length) {
      const steps: string[] = Object.keys(STEP_CONFIGURATION[stepType]);
      for (let i = 0; i < steps.length; i++) {
        const key: string = steps[i];
        if (STEP_CONFIGURATION[stepType][key].stepPath === path) {
          virtualPath = STEP_CONFIGURATION[stepType][key].link;
          break;
        }
      }
    }

    return virtualPath;
  }

  setListChangeData(steps: Step[]) {
    this._listChange.next((this._stepList = steps));
    this._listChangeBehaviorSubject.next(this._stepList);
  }

  /**
   * Sets the steps list and the service data
   * @param path keyof STEP
   */
  setList(path: string): void {
    this._stepType = Object.keys(STEP_PATH).find((key: string) => STEP_PATH[key] === path);

    if (!this._serviceData) {
      this._serviceData = {};
    }

    if (path === STEP_PATH.QUICK_SCRIPT) {
      this._serviceData[SpecialPaths.regularMeds] = [];
    }

    if (this._stepType && STEP_CONFIGURATION[this._stepType]) {
      Object.keys(STEP_CONFIGURATION[this._stepType]).forEach((stepName: string) => {
        this._serviceData[STEP_CONFIGURATION[this._stepType][stepName].path] = null;
      });

      this._serviceData['stepType'] = this._stepType;
    }

    switch (path) {
      case STEP_PATH.APPOINTMENT_TYPE_DOCTOR:
        this.setListChangeData(STEP_HIERARCHY.APPOINTMENT_TYPE_DOCTOR);
        break;
      // case STEP_PATH.ONDEMAND_TYPE_DOCTOR:
      //   this._listChange.next((this._stepList = STEP_HIERARCHY.ONDEMAND_TYPE_DOCTOR));
      //   break;

      /*
      case STEP_PATH.APPOINTMENT_TYPE_PSYCHOLOGY:
        this._listChange.next((this._stepList = STEP_HIERARCHY.APPOINTMENT_TYPE_PSYCHOLOGY));
        break;
      // case STEP_PATH.ONDEMAND_TYPE_PSYCHOLOGY:
      //   this._listChange.next((this._stepList = STEP_HIERARCHY.ONDEMAND_TYPE_PSYCHOLOGY));
      //   break;
      case STEP_PATH.APPOINTMENT_TYPE_DIETITIAN:
        this._listChange.next((this._stepList = STEP_HIERARCHY.APPOINTMENT_TYPE_DIETITIAN));
        break;
      // case STEP_PATH.ONDEMAND_TYPE_DIETITIAN:
      //   this._listChange.next((this._stepList = STEP_HIERARCHY.ONDEMAND_TYPE_DIETITIAN));
      //   break;
      case STEP_PATH.APPOINTMENT_TYPE_WELLNESS:
        this._listChange.next((this._stepList = STEP_HIERARCHY.APPOINTMENT_TYPE_WELLNESS));
        break;
      // case STEP_PATH.ONDEMAND_TYPE_WELLNESS:
      //   this._listChange.next((this._stepList = STEP_HIERARCHY.ONDEMAND_TYPE_WELLNESS));
      //   break;
      */
      case STEP_PATH.APPOINTMENT_TYPE_WEIGHTLOSS:
        this.setListChangeData(STEP_HIERARCHY.APPOINTMENT_TYPE_WEIGHTLOSS);
        break;
      case STEP_PATH.APPOINTMENT_TYPE_FERTILITY:
        this.setListChangeData(STEP_HIERARCHY.APPOINTMENT_TYPE_FERTILITY);
        break;
      case STEP_PATH.APPOINTMENT_TYPE_MENTALHEALTH:
        this.setListChangeData(STEP_HIERARCHY.APPOINTMENT_TYPE_MENTALHEALTH);
        break;
      case STEP_PATH.APPOINTMENT_TYPE_SMOKINGCESSATION:
        this.setListChangeData(STEP_HIERARCHY.APPOINTMENT_TYPE_SMOKINGCESSATION as unknown as Step[]);
        break;
      //  case STEP_PATH.APPOINTMENT_TYPE_SUPERPHARMACY:
      //     this._listChange.next((this._stepList = STEP_HIERARCHY.APPOINTMENT_TYPE_SUPERPHARMACY));
      //     break;
      //  case STEP_PATH.APPOINTMENT_TYPE_SLEEP:
      //     this._listChange.next((this._stepList = STEP_HIERARCHY.APPOINTMENT_TYPE_SLEEP));
      //     break;
      case STEP_PATH.QUICK_SCRIPT:
        this.setListChangeData(STEP_HIERARCHY.QUICK_SCRIPT);
        break;
      case STEP_PATH.WAITING_ROOM:
        this.setListChangeData(STEP_HIERARCHY.WAITING_ROOM);
        break;
      case STEP_PATH.SIGN_UP:
        this.setListChangeData(STEP_HIERARCHY.SIGN_UP);
        break;
      default:
        break;
    }
  }

  /**
   * @function updateProgress
   * @description Update the progress for a given step or sub-step. Sets the step as 'selected' (currently active)
   *
   * @param {string} currentPath Step or sub-step path/name
   * @param {boolean} [isClickable] Determine whether the user can click on the step link to return to this step
   * @param {boolean} [haltUpdateEvent] Do not trigger observer updates (in case you have multiple updates in a row)
   */
  updateProgress(currentPath: string, isClickable?: boolean, haltUpdateEvent?: boolean): void {
    let visibleSteps: number = 0; // top level steps that are visible to the user
    let indexStep: number = 0; // index of top-level step highlighted in the stepper component
    let totalSteps: number = 0; // total number of steps and sub-steps in current flow
    let completedSteps: number = 0; // number of steps the user has completed
    let currentStep: number = 0; // current step number (in reference to total number of steps)

    this._stepList.forEach((step: Step) => {
      if (step.display === true) {
        visibleSteps += 1;

        // If stepPath matches input, this is the step we're currently on
        if (step.stepPath === currentPath) {
          step.selected = true;
          step.isClickable = !!isClickable;
          indexStep = visibleSteps - 1;
          currentStep = totalSteps + 1;
        } else {
          step.selected = false;
        }

        // A step must have a stepPath to be counted in the total number of steps
        if (step.stepPath !== null) {
          totalSteps += 1;
          if (step.finish) {
            completedSteps += 1;
          }
        }

        // Traverse the sub-steps (step parts) and add them to the step tally
        if (Array.isArray(step.partList)) {
          step.partList.forEach((part: StepPart) => {
            if (part.display === true) {
              // If stepPath matches input, this is the sub-step we're currently on
              if (part.stepPath === currentPath) {
                part.selected = true;
                part.isClickable = !!isClickable;
                indexStep = visibleSteps - 1;
                currentStep = totalSteps + 1;
              } else {
                part.selected = false;
              }

              totalSteps += 1;

              if (part.finish) {
                completedSteps += 1;
              }
            }
          });
        }
      }
    });

    this._totalStep = totalSteps;
    this._completeStep = completedSteps;
    this._currentStep = currentStep;
    this._stepperIndex = indexStep;

    if (!haltUpdateEvent) {
      this._stepChange.next(null);
    }
  }

  /**
   * @function completeStep
   * @description Complete a step and update the stepList
   *
   * @param {string} path Step path/name to complete
   * @param {any} [data] Data to be stored in step service under the specified step/path
   * @param {boolean} [clickable] Whether the user can click (return to) this step in the sidenav
   * @param {boolean} [scrollToTop] Trigger scroll to top of page after completing this step
   * @param {boolean} [haltUpdateEvent] Do not trigger observer updates (in case you have multiple updates in a row)
   */
  completeStep(path: string, data?: any, clickable?: boolean, scrollToTop?: boolean, haltUpdateEvent?: boolean): void {
    let stepUpdated: boolean = false;

    if (Array.isArray(this._stepList) && this._stepList.length) {
      const index: number = this._stepList.findIndex((step: Step) => step.display && step.stepPath === path);
      if (index >= 0) {
        this._stepList[index].finish = true;
        this._stepList[index].isClickable = !!clickable;
        stepUpdated = true;
      }
    }

    if (!this._serviceData) {
      this._serviceData = {};
    }

    if (data) {
      this._serviceData[path] = data;
    }

    if (stepUpdated) {
      if (!haltUpdateEvent) {
        this._stepChange.next(null);
        // this._listChange.next(null);
      }

      if (scrollToTop) {
        try {
          document.getElementById(Constants.PAGE_ELEMENTS.mainScrollingContainer).scrollTo(0, 0);
        } catch (err: any) {}
      }
    }
  }

  /**
   * @function completePart
   * @description Complete a sub-step and update the stepList
   *
   * @param {string} path Sub-step path/name to complete
   * @param {any} [data] Data to be stored in step service under the specified sub-step/path
   * @param {boolean} [clickable] Whether the user can click (return to) this sub-step in the sidenav
   * @param {boolean} [selected] Whether this sub-step/route is still selected/active
   * @param {boolean} [scrollToTop] Trigger scroll to top of page after completing this sub-step
   * @param {boolean} [haltUpdateEvent] Do not trigger observer updates (in case you have multiple updates in a row)
   */
  completePart(
    path: string,
    data?: any,
    clickable?: boolean,
    selected?: boolean,
    scrollToTop?: boolean,
    haltUpdateEvent?: boolean
  ): void {
    if (!(Array.isArray(this._stepList) && this._stepList.length)) {
      return;
    }

    let subStepUpdated: boolean = false;
    let completePart: number = 0;
    let displayPart: number = 0;
    let index: number = -1;

    try {
      index = this._stepList.findIndex(
        (step: Step) => step.partList.findIndex((part: StepPart) => part.stepPath === path) >= 0
      );

      if (index >= 0) {
        this._stepList[index].partList.forEach((part: StepPart) => {
          if (part.stepPath === path) {
            part.finish = true;
            part.selected = !!selected;
            part.isClickable = !!clickable;
            subStepUpdated = true;
          }
          if (part.display === true) {
            displayPart += 1;

            if (part.finish === true) {
              completePart += 1;
            }
          }
        });
      }
    } catch (err: any) {
      console.warn('Failed to complete subStep at path: ', path);
    }

    if (completePart >= displayPart && index !== -1) {
      this._stepList[index].finish = true;
      subStepUpdated = true;
    }

    if (!this._serviceData) {
      this._serviceData = {};
    }

    if (data) {
      this._serviceData[path] = data;
    }

    if (subStepUpdated && !haltUpdateEvent) {
      if (scrollToTop) {
        try {
          document.getElementById(Constants.PAGE_ELEMENTS.mainScrollingContainer).scrollTo(0, 0);
        } catch (err: any) {}
      }

      // this._listChange.next(null);
      this._stepChange.next(null);
    }
  }

  /**
   * @function resetStep
   * @description Reset a step to its pristine state (remove data, set finished to false)
   *
   * @param {string} path Step path/name to reset
   * @param {boolean} haltUpdateEvent do  not trigger observer update
   */
  resetStep(path: string, haltUpdateEvent?: boolean): void {
    let stepUpdated: boolean = false;

    if (Array.isArray(this._stepList) && this._stepList.length) {
      const index: number = this._stepList.findIndex((step: Step) => step.display && step.stepPath === path);
      if (index >= 0) {
        // this._stepList[index].selected = false;
        this._stepList[index].finish = false;
        stepUpdated = true;
      }
    }

    if (!this._serviceData) {
      this._serviceData = {};
    }

    this._serviceData[path] = null;

    if (stepUpdated && !haltUpdateEvent) {
      // this._listChange.next(null);
      this._stepChange.next(null);
    }
  }

  /**
   * @function canStepBack
   * @description Determine whether we can take 1 step back
   */
  canStepBack(): boolean {
    return Boolean(this._totalStep && this._currentStep > 0);
  }

  /**
   * @function stepBack
   * @description Go back to the previous 'valid' step - last step that is displayed and clickable
   */
  stepBack(activatedRoute?: ActivatedRoute): void {
    if (this.stepType && this.stepList?.length && this.canStepBack()) {
      if (this._currentStep === 1) {
        this.resetService();
        this.removeAppointmentFromStorage();
        this.removeWaitingRoomFromStorage();
        this.removeSignUpFromStorage();

        this.navigateToDashboard();
      } else if (this._currentStep > 1) {
        let lastValidStep: Step | StepPart;
        const stepNumberToStepBackTo: number = this._currentStep - 1;

        let currentStepNumber: number = 0;
        let shouldBreak: boolean = false;

        for (let i = 0; i < this.stepList.length; i++) {
          const step: Step = this.stepList[i];

          if (step.display) {
            if (step.link) {
              currentStepNumber++;

              // Only allow returning to 'clickable' steps
              if (step.isClickable) {
                lastValidStep = step;
              }

              // If we've reached our desired step number, navigate to the last 'valid' step
              if (currentStepNumber === stepNumberToStepBackTo) {
                if (
                  lastValidStep &&
                  lastValidStep?.link !== STEP_CONFIGURATION.WAITING_ROOM.CONFIRM_YOUR_INFORMATION.virtualPath
                ) {
                  this.navigateToStep(lastValidStep.link, lastValidStep.label, activatedRoute);
                } else {
                  this.navigateToDashboard();
                }
                break;
              }
            }

            if (Array.isArray(step.partList) && step.partList.length) {
              for (let x = 0; x < step.partList.length; x++) {
                const part: StepPart = step.partList[x];

                if (part.display && part.link) {
                  currentStepNumber++;

                  // Only allow returning to 'clickable' steps
                  if (part.isClickable) {
                    lastValidStep = part;
                  }

                  // If we've reached our desired step number, navigate to the last 'valid' step
                  if (currentStepNumber === stepNumberToStepBackTo) {
                    if (lastValidStep) {
                      this.navigateToStep(lastValidStep.link, lastValidStep.label, activatedRoute);
                    } else {
                      this.navigateToDashboard();
                    }
                    shouldBreak = true;
                    break;
                  }
                }
              }
            }
          }

          if (shouldBreak) {
            break;
          }
        }
      }
    } else {
      this.navigateToDashboard();
    }
  }

  navigateToDashboard(): void {
    this.router.navigate([STEP_PATH.DASHBOARD], { replaceUrl: true });
  }

  navigateToStep(virtualPath: string, stepLabel: string, activatedRoute?: ActivatedRoute): void {
    if (!(this.stepType && stepLabel)) {
      return;
    }

    let url: string = '';

    // Waiting-Room and Sign-Up flows have a flat url hierarchy, so navigate relative to current route
    if (this.isWaitingRoomStepType() || this.stepType === 'SIGN_UP') {
      this.router.navigate([virtualPath], { relativeTo: activatedRoute /*, replaceUrl: true*/ });
      return;

      // For Appointments and QuickScript, which have sign-up steps, we need to parse the step hierarchy
    } else if (this.isAppointmentStepType()) {
      url = '/' + STEP_PATH.APPOINTMENT + '/' + STEP_PATH[this.stepType];
    } else if (this.stepType === 'QUICK_SCRIPT') {
      url = '/' + STEP_PATH.QUICK_SCRIPT;
    }

    if (!url) {
      this.navigateToDashboard();
      return;
    }

    let stepFound: boolean = false;
    let pathToUpdate: string;

    for (let i = 0; i < STEP_HIERARCHY[this.stepType].length; i++) {
      const hierarchyStep: any = STEP_HIERARCHY[this.stepType][i];

      if (hierarchyStep.label === stepLabel) {
        url += '/' + hierarchyStep.link;
        pathToUpdate = hierarchyStep.stepPath;
        stepFound = true;
        break;
      } else if (hierarchyStep.partList?.length) {
        for (let x = 0; x < hierarchyStep.partList.length; x++) {
          const hierarchySubStep: any = hierarchyStep.partList[x];

          if (hierarchySubStep.label === stepLabel) {
            url += '/' + hierarchySubStep.link;
            pathToUpdate = hierarchySubStep.stepPath;
            stepFound = true;
            break;
          }
        }
      }

      if (stepFound) {
        break;
      }
    }

    if (stepFound) {
      if (pathToUpdate) {
        // 'Select' the step we're returning to, but don't trigger an update event
        this.updateProgress(pathToUpdate, true, true);
      }
      this.router.navigateByUrl(url, { replaceUrl: true });
    }
  }

  isAppointmentStepType(stepType?: string): boolean {
    const flow: string = stepType || this.stepType;
    return Boolean(flow && (flow.indexOf('APPOINTMENT_TYPE_') !== -1 || flow.indexOf('ONDEMAND_TYPE_') !== -1));
  }

  isWaitingRoomStepType(): boolean {
    return this.stepType === 'WAITING_ROOM';
  }

  isQuickScriptStepType(): boolean {
    return this.stepType === 'QUICK_SCRIPT';
  }

  isSmokingCessationStepType(): boolean {
    return this.stepType === 'SMOKING_CESSATION';
  }

  isSignUpStepType(): boolean {
    return this.stepType === 'SIGN_UP';
  }

  /**
   * @function setQuickScriptSessionData
   * @description Set the current step service data held in memory. Retrieve from local storage if
   * no QuickScript step data was provided to the function.
   *
   * @param {QuickScriptStepData} [quickscriptData] QuickScript step service data
   */
  setQuickScriptSessionData(quickscriptData?: QuickScriptStepData): void {
    let data: QuickScriptStepData = null;

    try {
      data = quickscriptData ?? JSON.parse(sessionStorage.getItem(Constants.LocalStorage_Key.quickscript));
      this.serviceType = data?.serviceType ?? data.quickscript.serviceType;
    } catch (_err: any) {}

    this._serviceData = data ?? {};
    this._stepServiceDataChange.next(null);
  }

  /**
   * @function setAppointmentSessionData
   * @description Set the current step service data held in memory. Retrieve from local storage if
   * no Appointment step data was provided to the function.
   *
   * @param {AppointmentStepData} [appointmentData] Appointment step service data
   */
  setAppointmentSessionData(appointmentData?: AppointmentStepData): void {
    let data: AppointmentStepData = null;

    try {
      data = appointmentData ?? JSON.parse(sessionStorage.getItem(Constants.LocalStorage_Key.appointment));
      this.serviceType = data?.serviceType ?? data?.appointment?.serviceType;
    } catch (_err: any) {}

    this._serviceData = data ?? {};
    this._stepServiceDataChange.next(null);
  }

  /**
   * @function setRegistrationSessionData
   * @description Set the current step service data held in memory. Retrieve from local storage if
   * no Registration/Sign-Up step data was provided to the function.
   *
   * @param {RegistrationStepData} [registrationData] Registration/Sign-Up step service data
   */
  setRegistrationSessionData(registrationData?: RegistrationStepData): void {
    let data: RegistrationStepData = null;

    try {
      data = registrationData ?? JSON.parse(sessionStorage.getItem(Constants.LocalStorage_Key.signup));
    } catch (_err: any) {}

    this._serviceData = data ?? {};
    this._stepServiceDataChange.next(null);
  }

  /**
   * @function setWaitingRoomSessionData
   * @description Set the current step service data held in memory. Retrieve from local storage if
   * no Waiting Room step data was provided to the function.
   *
   * @param {WaitingRoomStepData} [waitingRoomData] Waiting Room / In-Appointment step service data
   */
  setWaitingRoomSessionData(waitingRoomData?: WaitingRoomStepData): void {
    let data: WaitingRoomStepData = null;

    try {
      data = waitingRoomData ?? JSON.parse(sessionStorage.getItem(Constants.LocalStorage_Key.waitingroom));
      this.serviceType = data?.serviceType ?? data?.appointment?.serviceType;
    } catch (_err: any) {}

    this._serviceData = data ?? {};
    this._stepServiceDataChange.next(null);
  }

  /**
   * @function saveQuickScriptToStorage
   * @description Save the current QuickScript service data held in memory to Session Storage
   */
  saveQuickScriptToStorage(): void {
    try {
      sessionStorage.setItem(Constants.LocalStorage_Key.quickscript, JSON.stringify(this._serviceData));
    } catch (_err: any) {}
  }

  /**
   * @function saveAppointmentToStorage
   * @description Save the current Appointment service data held in memory to Session Storage
   */
  saveAppointmentToStorage(): void {
    try {
      sessionStorage.setItem(Constants.LocalStorage_Key.appointment, JSON.stringify(this._serviceData));
    } catch (_err: any) {}
  }

  /**
   * @function saveWaitingRoomToStorage
   * @description Save the current Waiting Room service data held in memory to Session Storage
   */
  saveWaitingRoomToStorage(): void {
    try {
      sessionStorage.setItem(Constants.LocalStorage_Key.waitingroom, JSON.stringify(this._serviceData));
    } catch (_err: any) {}
  }

  /**
   * @function saveSignUpToStorage
   * @description Save the current Registration / Sign-Up service data held in memory to Session Storage
   */
  saveSignUpToStorage(): void {
    try {
      sessionStorage.setItem(Constants.LocalStorage_Key.signup, JSON.stringify(this._serviceData));
    } catch (_err: any) {}
  }

  /**
   * @function removeQuickScriptFromStorage
   * @description Remove QuickScript step service data that was saved to Session Storage
   */
  removeQuickScriptFromStorage(): void {
    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.quickscript);
    } catch (_err: any) {}
  }

  /**
   * @function removeAppointmentFromStorage
   * @description Remove Appointment step service data that was saved to Session Storage
   */
  removeAppointmentFromStorage(): void {
    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.appointment);
    } catch (_err: any) {}
  }

  /**
   * @function removeWaitingRoomFromStorage
   * @description Remove Waiting Room step service data that was saved to Session Storage
   */
  removeWaitingRoomFromStorage(): void {
    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.waitingroom);
    } catch (_err: any) {}
  }

  /**
   * @function removeSignUpFromStorage
   * @description Remove Sign-Up step service data that was saved to Session Storage
   */
  removeSignUpFromStorage(): void {
    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.signup);
    } catch (_err: any) {}
  }

  /**
   * @function clearSelectedMedications
   * @description Empty the quickscript medications array currently held in step service memory
   */
  clearSelectedMedications(): void {
    this.setServiceData(SpecialPaths.regularMeds, [], false, true);
  }

  /**
   * @function isStepDisplayed
   * @description Check if a particular step is displayed based on the step label
   * @param {string} label Step label to search for
   *
   * @returns {boolean} True if the specified step has property 'display === true'
   */
  isStepDisplayed(label: string): boolean {
    let isDisplayed: boolean = false;

    let index: number = this._stepList.findIndex((step: Step) => step.label === label);

    if (index >= 0) {
      isDisplayed = this._stepList[index].display;
    }

    return isDisplayed;
  }

  /**
   * @function isSubStepDisplayed
   * @description Check if a particular sub-step is displayed based on the step label and sub-step label
   * @param {string} label Step label containing the sub-step
   * @param {string} subStepLabel Sub-step label
   *
   * @returns {boolean} True if the specified sub-step has property 'display === true'
   */
  isSubStepDisplayed(label: string, subStepLabel: string): boolean {
    let isDisplayed: boolean = false;

    let index: number = this._stepList.findIndex((step: Step) => step.label.indexOf(label) !== -1);

    if (index >= 0 && Array.isArray(this._stepList[index].partList)) {
      const subStepIndex: number = this._stepList[index].partList.findIndex(
        (part: StepPart) => subStepLabel.indexOf(part.label) !== -1
      );
      if (subStepIndex >= 0) {
        isDisplayed = this._stepList[index].partList[subStepIndex].display;
      }
    }

    return isDisplayed;
  }

  /**
   * @function isAccountHolderDisplayed
   * @description Check if the Sign-Up or Login / Account Holder sub-step is displayed
   * @returns {boolean} true if the Account Holder sub-step is displayed
   */
  isAccountHolderDisplayed(): boolean {
    return this.isSubStepDisplayed(Labels.registerLogin, Labels.accountHolder);
  }

  /**
   * @function showHideSteps
   * @description Display or hide a set of steps or sub-steps based on their labels
   * @param {string[]} labels list of labels to search for within steps and sub-steps
   * @param {boolean} display true to display label, false to hide
   * @param {boolean} [haltUpdateEvent=false] do not trigger update event
   */
  showHideSteps(labels: string[], display: boolean, haltUpdateEvent: boolean = false): void {
    if (!(Array.isArray(this._stepList) && this._stepList.length)) {
      return;
    }

    // console.log('show-hide-steps: ', labels.toString(), ' show?: ', display);

    let found: boolean = false;
    let index: number = this._stepList.findIndex((step: Step) => labels.indexOf(step.label) !== -1);

    if (index >= 0) {
      this._stepList[index].display = display;
      found = true;
    } else {
      index = this._stepList.findIndex(
        (step: Step) =>
          Array.isArray(step.partList) &&
          step.partList.findIndex((part: StepPart) => labels.indexOf(part.label) !== -1) >= 0
      );
      if (index >= 0 && Array.isArray(this._stepList[index].partList)) {
        this._stepList[index].partList.forEach((part: StepPart) => {
          if (labels.indexOf(part.label) !== -1) {
            part.display = display;
            found = true;
          }
        });
      }
    }

    if (found && !haltUpdateEvent) {
      this._listChange.next(null);
      this._listChangeBehaviorSubject.next(null);
    }
  }

  /**
   * @function showHideSubSteps
   * @description Show or hide sub-steps based on the specified step label and list of sub-step labels
   * @param {string} label step label
   * @param {string[]} subLabels list of sub-step labels, or null for all sub-steps
   * @param {boolean} display true to display sub-steps, false to hide
   * @param {boolean} [haltUpdateEvent=false] do not trigger update event
   * @returns
   */
  showHideSubSteps(label: string, subLabels: string[], display: boolean, haltUpdateEvent: boolean = false): void {
    if (!(Array.isArray(this._stepList) && this._stepList.length)) {
      return;
    }

    let found: boolean = false;
    const index: number = this._stepList.findIndex((step: Step) => step.label === label);

    if (index >= 0 && Array.isArray(this._stepList[index].partList)) {
      this._stepList[index].partList.forEach((part: StepPart) => {
        if (!subLabels || (subLabels.length && subLabels.indexOf(part.label) !== -1)) {
          part.display = display;
          found = true;
        }
      });
    }

    if (found && !haltUpdateEvent) {
      this._listChange.next(this._stepList);
      this._listChangeBehaviorSubject.next(this._stepList);
    }
  }

  hideAppointmentTime(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.chooseTime], false, haltUpdateEvent);
  }

  showAppointmentTime(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.chooseTime], true, haltUpdateEvent);
  }

  hideRegisterAndLogin(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.registerLogin], false, haltUpdateEvent);
  }

  hideRegisterSubSteps(): void {
    this.showHideSubSteps(Labels.registerLogin, null, false);
  }

  hideRegister(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.registerLogin], false, true);
    this.showHideSteps([Labels.confirmMedicalHistory], true, haltUpdateEvent);
  }

  showRegister(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.registerLogin], true, true);
    this.showHideSteps([Labels.confirmMedicalHistory], false, true);

    this.showHideSubSteps(Labels.registerLogin, [Labels.patientData], false, true);
    this.showHideSubSteps(Labels.registerLogin, [Labels.accountHolder, Labels.medicalHistory], true, haltUpdateEvent);
  }

  showRegisterAdditionalPatientQS(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.registerLogin], true, true);
    this.showHideSteps([Labels.healthcareDetails], false, true);

    this.showHideSubSteps(Labels.registerLogin, [Labels.accountHolder], false, true);
    this.showHideSubSteps(Labels.registerLogin, [Labels.patientData], true, haltUpdateEvent);
  }

  hideAccountHolder(haltUpdateEvent: boolean = false): void {
    this.showHideSubSteps(Labels.registerLogin, [Labels.accountHolder], false, haltUpdateEvent);
  }

  showAdditionalPatient(): void {
    this.showHideSubSteps(Labels.registerLogin, [Labels.patientData], true);
  }

  /**
   * @function showPatientData
   * @description Show the additional patient data step within the QuickScript/Appointment sign-up flow
   */
  showPatientData(): void {
    this.showHideSubSteps(Labels.registerLogin, [Labels.patientData], true);
  }

  /**
   * @function hidePatientData
   * @description Hide the additional patient data step within the QuickScript/Appointment sign-up flow
   */
  hidePatientData(): void {
    this.showHideSubSteps(Labels.registerLogin, [Labels.patientData], false);
  }

  /**
   * @function showSignUpPatientData
   * @description Show the additional patient data step within the SignUp flow
   */
  showSignUpPatientData(): void {
    this.showHideSteps([Labels.patientData], true);
  }

  /**
   * @function hideSignUpPatientData
   * @description Hide the additional patient data step within the SignUp flow
   */
  hideSignUpPatientData(): void {
    this.showHideSteps([Labels.patientData], false);
  }

  showAdditionalPatientOnly(): void {
    this.showRegister(true);
    this.hideAccountHolder(true);
    this.showPatientData();
  }

  showConfirmDeliveryAddressStep(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.addressConfirm], true, haltUpdateEvent);
  }

  hideConfirmDeliveryAddressStep(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.addressConfirm], false, haltUpdateEvent);
  }

  showSelectPharmacyStep(): void {
    this.showHideSteps([Labels.addressConfirm], false, true);
    this.showHideSteps([Labels.selectPharmacy], true);
  }

  hideSelectPharmacyStep(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.addressConfirm], false, true);
    this.showHideSteps([Labels.selectPharmacy], false, !haltUpdateEvent);
  }

  showHealthcareIdentifiersStep(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.registerLogin], false, true);
    this.showHideSteps([Labels.healthcareDetails], true, !haltUpdateEvent);
  }

  hideHealthcareIdentifiersStep(haltUpdateEvent: boolean = false): void {
    this.showHideSteps([Labels.registerLogin], true, true);
    this.showHideSteps([Labels.healthcareDetails], false, !haltUpdateEvent);
  }

  /**
   * @function getPercentage
   * @description Get the flow completion percentage value based on 'current step' from 'total steps'
   * @returns {number} a number between 0 and 100
   */
  getPercentage(): number {
    try {
      if (this._totalStep) {
        return Math.round((this._currentStep / this._totalStep) * 100);
      }
    } catch (err: any) {
      console.log('Could not calculate step percentage. Error: ', err);
    }
    return 0;
  }

  copyStepsToServiceData(): void {
    if (!(this.stepType && this.stepList?.length)) {
      return;
    }

    this.setServiceData(
      null, // overwrite entire Step Service data store
      {
        stepType: this.stepType,
        stepList: this.stepList
      },
      true, // halt update
      true // append params instead of overwriting
    );

    if (this.stepType?.indexOf('APPOINTMENT_TYPE_') === 0 || this.stepType?.indexOf('ONDEMAND_TYPE_') === 0) {
      this.saveAppointmentToStorage();
    } else {
      switch (this.stepType) {
        case 'WAITING_ROOM':
          this.saveWaitingRoomToStorage();
          break;
        case 'QUICK_SCRIPT':
          this.saveQuickScriptToStorage();
          break;
        case 'SIGN_UP':
          this.saveSignUpToStorage();
          break;
      }
    }
  }

  reinitialiseQuickScriptData(): void {
    const qsData: QuickScriptStepData = this.getServiceData(null, true);

    if (qsData?.stepType !== 'QUICK_SCRIPT') {
      this.serviceType = Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY;
      this.setList(STEP_PATH.QUICK_SCRIPT);
    } else {
      this.updateStepServiceCoreData(qsData);
    }
  }

  /**
   * @function reinitialiseAppointmentData
   * @description If appointment session storage data exists, load it, otherwise initialise a new
   * appointment flow of the specified service type by resetting the steps and step data
   *
   * @param {string} [appointmentStepType='doctor']
   */
  reinitialiseAppointmentData(appointmentStepType: string = STEP_PATH.APPOINTMENT_TYPE_DOCTOR): void {
    const appointmentData: AppointmentStepData = this.getServiceData(null, false, true);

    if (
      !appointmentData?.stepType ||
      !this.isAppointmentStepType(appointmentData.stepType) ||
      (appointmentStepType &&
        appointmentData?.stepType &&
        STEP_PATH[appointmentData.stepType] &&
        STEP_PATH[appointmentData.stepType] !== appointmentStepType)
    ) {
      this.serviceType = appointmentData?.serviceType || Constants.SERVICE_TYPE.DOCTOR;
      this.setList(appointmentStepType);
    } else {
      this.updateStepServiceCoreData(appointmentData);
    }
  }

  reinitialiseRegistrationData(): void {
    const registrationData: RegistrationStepData = this.getServiceData(null, false, false, true);

    if (registrationData?.stepType !== 'SIGN_UP') {
      this.serviceType = null;
      this.setList(STEP_PATH.SIGN_UP);
    } else {
      this.updateStepServiceCoreData(registrationData);
    }
  }

  reinitialiseWaitingRoomData(): void {
    const waitingRoomStepData: WaitingRoomStepData = this.getServiceData(null, false, false, false, true);

    if (waitingRoomStepData?.stepType !== 'WAITING_ROOM') {
      this.serviceType = waitingRoomStepData?.serviceType || Constants.SERVICE_TYPE.DOCTOR;
      this.setList(STEP_PATH.WAITING_ROOM);
    } else {
      this.updateStepServiceCoreData(waitingRoomStepData);
    }
  }

  /**
   * @function updateStepServiceCoreData
   * @description Update stepType and stepList that was saved in step data storage
   *
   * @param {any} data appointment, waitingroom, quickscript or registration step data
   */
  updateStepServiceCoreData(data: CoreStepData): void {
    if (data) {
      this._stepType = data?.stepType || null;
      this.serviceType = data?.serviceType || null;
      this.setListChangeData(data.stepList || null);
    }
  }

  /**
   * @function getServiceData
   * @description Retrieve a part or all of the Step Service step data
   *
   * @param {string} [path] if falsy, return the entire serviceData object, otherwise retrieve the data at given path
   * @param {boolean} [loadQuickScript=false] Retrieve QuickScript step data from storage
   * @param {boolean} [loadAppointment=false] Retrieve Appointment step data from storage
   * @param {boolean} [loadRegistration=false] Retrieve SignUp/Registration step data from storage
   * @param {boolean} [loadWaitingRoom=false] Retrieve Waiting Room / In-Appointment step data from stora
   *
   * @returns {any} step service data for the specified step, or the entire step data object
   */
  getServiceData(
    path?: string,
    loadQuickScript: boolean = false,
    loadAppointment: boolean = false,
    loadRegistration: boolean = false,
    loadWaitingRoom: boolean = false
  ): any {
    // Retrieve QuickScript data from storage?
    if (
      loadQuickScript &&
      (!(this._serviceData && Object.keys(this._serviceData).length) ||
        !this._serviceData[STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_DETAIL.path])
    ) {
      this.setQuickScriptSessionData();
    }

    // Retrieve Appointment data from storage?
    if (
      loadAppointment &&
      (!(this._serviceData && Object.keys(this._serviceData).length) ||
        (this.stepType && !this._serviceData[STEP_CONFIGURATION[this.stepType].CHOOSE_APPOINTMENT_TIME.path]))
    ) {
      this.setAppointmentSessionData();
    }

    // Retrieve Registration (Sign-Up) data from storage?
    if (
      loadRegistration &&
      (!(this._serviceData && Object.keys(this._serviceData).length) ||
        (this.stepType && !this._serviceData[STEP_CONFIGURATION.SIGN_UP.ACCOUNT_HOLDER.path]))
    ) {
      this.setRegistrationSessionData();
    }

    // Retrieve Waiting Room / In-Appointment data from storage?
    if (
      loadWaitingRoom &&
      (!(this._serviceData && Object.keys(this._serviceData).length) ||
        (this.stepType && !this._serviceData[STEP_CONFIGURATION.WAITING_ROOM.WAITING_ROOM_BEFORE.path]))
    ) {
      this.setWaitingRoomSessionData();
    }

    if (!this._serviceData) {
      this._serviceData = {};
    }

    return path ? this._serviceData[path] : this._serviceData;
  }

  /**
   * @function setServiceData
   * @description Set the data for the specified step
   *
   * @param {string} path Step Service storage path for the incoming data
   * @param {any} data step data
   * @param {boolean} [haltUpdate=false] do not trigger the stepServiceDataChange observer?
   * @param {boolean} [append=false] append to existing data instead of overwriting the whole object?
   */
  setServiceData(path: string, data: any, haltUpdate: boolean = false, append: boolean = false): void {
    if (path) {
      if (!this._serviceData) {
        this._serviceData = {};
      }
      if (path === 'serviceType') {
        this.serviceType = data;
      }
      if (append && !Array.isArray(data) && typeof data !== 'string') {
        this._serviceData[path] = {
          ...this._serviceData[path],
          ...data
        };
      } else {
        this._serviceData[path] = data;
      }
    } else {
      if (append && !Array.isArray(data) && typeof data !== 'string') {
        this._serviceData = {
          ...this._serviceData,
          ...data
        };
      } else {
        this._serviceData = data;
      }
    }

    if (!haltUpdate) {
      this._stepServiceDataChange.next(this._serviceData);
    }
  }

  clearServiceData(): void {
    this._serviceData = {};
  }

  setFlowPatientId(patientId: string, stepType?: string): void {
    if (!stepType) {
      stepType = this.stepType;
    }

    if (!this._serviceData) {
      this._serviceData = {};
    }

    if (stepType && this._stepList?.length) {
      this._serviceData['patientId'] = patientId;
    }
    // if (patientId && stepType && this._stepList?.length) {
    //   this.setServiceData('patientId', patientId, true, true);
    // }
  }

  getFlowPatientId(stepType?: string): string {
    if (!stepType) {
      stepType = this.stepType;
    }
    if (stepType) {
      const loadQuickScript: boolean = stepType === 'QUICK_SCRIPT';
      const loadAppointment: boolean =
        stepType === 'APPOINTMENT_TYPE_DOCTOR' ||
        stepType === 'APPOINTMENT_TYPE_FERTILITY' ||
        stepType === 'ONDEMAND_TYPE_DOCTOR' ||
        stepType === 'ONDEMAND_TYPE_FERTILITY';

      return this.getServiceData('patientId', loadQuickScript, loadAppointment);
    }

    return null;
  }

  setMedicationLabel(path: string, brand: MedicationBrand, haltUpdateEvent: boolean = false): void {
    const name: string = brand.name;
    const shortDescription: string = brand.shortDescription;
    this.updateLabel(path, `${name}${shortDescription ? ' - ' + shortDescription : ''}`, haltUpdateEvent);
  }

  updateLabel(path: string, label: string, haltUpdateEvent: boolean = false): void {
    if (!(Array.isArray(this._stepList) && this._stepList.length)) {
      return;
    }
    let labelUpdated: boolean = false;
    let index: number = this._stepList.findIndex((step: Step) => step.stepPath === path);
    if (index >= 0) {
      if (this._stepList[index].label !== label) {
        this._stepList[index].label = label;
        labelUpdated = true;
      }
    } else {
      try {
        index = this._stepList.findIndex(
          (step: Step) => step.partList.findIndex((part: StepPart) => part.stepPath === path) >= 0
        );
        const indexPart: number = this._stepList[index].partList.findIndex((part: StepPart) => part.stepPath === path);

        if (this._stepList[index].partList[indexPart].label !== label) {
          this._stepList[index].partList[indexPart].label = label;
          labelUpdated = true;
        }
      } catch (_err) {
        console.log('Cannot find step path to update: ', path);
      }
    }

    if (labelUpdated && !haltUpdateEvent) {
      this._listChange.next(this._stepList);
      this._listChangeBehaviorSubject.next(this._stepList);
    }
  }

  /**
   * @function showQuickScriptMedication
   * @description Show medication2 in the sidenav step list
   */
  showQuickScriptMedication(): void {
    if (!(Array.isArray(this._stepList) && this._stepList.length)) {
      return;
    }
    try {
      this._stepList[0].partList[1].display = true;
      this._listChange.next(this._stepList);
      this._listChangeBehaviorSubject.next(this._stepList);
    } catch (err: any) {
      console.warn(
        'showQuickScriptMedication() :: ',
        'Failed to find second stepPart within the first step! Error: ',
        err.message
      );
    }
  }

  /**
   * @function hideQuickScriptMedication
   * @description Hide medication2 in the sidenav step list
   */
  hideQuickScriptMedication(): void {
    if (!(Array.isArray(this._stepList) && this._stepList.length)) {
      return;
    }
    try {
      if (this._stepList[0].partList?.length > 1) {
        this._stepList[0].partList[1].display = false;
        this._listChange.next(this._stepList);
        this._listChangeBehaviorSubject.next(this._stepList);
      }
    } catch (err: any) {
      console.warn(
        'hideQuickScriptMedication() :: ',
        'Failed to find second stepPart within the first step! Error: ',
        err.message
      );
    }
  }

  addMedication(medicationId: string): void {
    if (this._serviceData) {
      try {
        if (this._serviceData && !Array.isArray(this._serviceData[SpecialPaths.regularMeds])) {
          this._serviceData[SpecialPaths.regularMeds] = [];
        }
        // Only add unique medication ids
        if (
          medicationId &&
          this._serviceData &&
          Array.isArray(this._serviceData[SpecialPaths.regularMeds]) &&
          (!this._serviceData[SpecialPaths.regularMeds].length ||
            this._serviceData[SpecialPaths.regularMeds].indexOf(medicationId) === -1)
        ) {
          this._serviceData[SpecialPaths.regularMeds].push(medicationId);
        }
      } catch (err: any) {
        console.warn('Failed to add medicationId to the stepService medications list! Error: ', err.message);
      }
    }
  }

  removeMedication(index: number): void {
    if (
      this._serviceData &&
      Array.isArray(this._serviceData[SpecialPaths.regularMeds]) &&
      this._serviceData[SpecialPaths.regularMeds].length > index
    ) {
      try {
        this._serviceData[SpecialPaths.regularMeds].splice(index, 1);
        this._stepServiceDataChange.next(null);
      } catch (err: any) {
        console.warn(
          'Failed to remove medicationId at index ',
          index,
          ' from the stepService medications list! Error: ',
          err.message
        );
      }
    }
  }

  removeMedicationById(medicationId: string): void {
    if (this._serviceData && Array.isArray(this._serviceData[SpecialPaths.regularMeds])) {
      try {
        const index: number = this._serviceData[SpecialPaths.regularMeds].indexOf(medicationId);
        if (index >= 0) {
          this.removeMedication(index);
        }
      } catch (err: any) {
        console.warn(
          'Failed to remove medicationId ',
          medicationId,
          ' from the stepService medications list! Error: ',
          err?.message
        );
      }
    }
  }

  mobileStepIndicatorTriggered(): void {
    this._mobileStepIndicatorTrigger.next(true);
  }

  /**
   * @function disablePreviousSteps
   * @description Set all displayed steps and sub-steps to be unclickable (ie. isClickable = false)
   *
   * @param {boolean} [haltUpdateEvent=false] do not trigger update event
   */
  disablePreviousSteps(haltUpdateEvent: boolean = false): void {
    if (this._stepList?.length) {
      this._stepList.forEach((step: Step) => {
        if (step.display && !step.selected) {
          step.isClickable = false;
          if (step.partList?.length) {
            step.partList.forEach((part: StepPart) => {
              if (part.display && !part.selected) {
                part.isClickable = false;
              }
            });
          }
        }
      });

      if (!haltUpdateEvent) {
        this._listChange.next(this._stepList);
        this._listChangeBehaviorSubject.next(this._stepList);
      }
    }
  }

  resetService(): void {
    this._currentStep = 0;
    this._totalStep = 0;
    this._completeStep = 0;
    this._stepperIndex = 0;
    this._stepType = null;
    this.serviceType = null;
    this._stepList = [];
    // this._serviceData = {};
    this._stepServiceDataChange.next((this._serviceData = {}));
  }

  resetServiceAndClearStoredData(): void {
    this.removeQuickScriptFromStorage();
    this.removeAppointmentFromStorage();
    this.removeWaitingRoomFromStorage();
    this.removeSignUpFromStorage();
    this.resetService();
  }

  ngOnDestroy(): void {
    // this._stepList = [];
    // this._serviceData = {};
    this.subscription.unsubscribe();
  }

  get stepList(): Step[] {
    return this._stepList;
  }
  set stepList(steps: Step[]) {
    this._stepList = steps;
  }
  get stepType(): string {
    return this._stepType;
  }
  get stepperIndex(): number {
    return this._stepperIndex || 0;
  }
  get currentStep(): number {
    return this._currentStep || 0;
  }
  get completedSteps(): number {
    return this._completeStep || 0;
  }
  get totalStep(): number {
    return this._totalStep || 0;
  }
  get serviceType(): string {
    return this._serviceType || null;
  }
  set serviceType(serviceType: string) {
    this._serviceType = serviceType;

    try {
      sessionStorage.setItem(
        Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.sessionServiceType,
        this._serviceType
      );
    } catch (_err: any) {}
  }
}
