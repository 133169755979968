export enum InboxInteraction {
  getMessages = 'retrieve messages',
  getMessagesError = 'retrieve messages ERROR',
  read = 'read message',
  readError = 'read message ERROR',
  send = 'send message',
  sendError = 'send message ERROR',
  delete = 'delete message',
  deleteError = 'delete message ERROR',
  upload = 'upload attachment',
  uploadError = 'upload attachment ERROR'
}
