import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Constants } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { IResponseAPI } from '@app/shared/models/api-response';
import { MedicationPrice } from '@app/shared/models/associated-medications';
import { Benefit } from '@app/shared/models/benefit';
import { HealthCareCard } from '@app/shared/models/health-care-card';
import { MonthOption } from '@app/shared/models/month-option';
import { Patient } from '@app/shared/models/patient';
import { ProductPrice } from '@app/shared/models/product-price';
import { ProductServiceType } from '@app/shared/models/product-service-type';
import { QuickScriptMedication } from '@app/shared/models/quick-script-medication';
import { QuickScript } from '@app/shared/models/quickscript';
import { QuickscriptPricingDTO } from '@app/shared/models/quickscriptPricingDTO';
import { QuickscriptSharedDataMedication } from '@app/shared/models/quickscriptSharedData';
import { ValidateHealthCardsRequestDTO } from '@app/shared/models/validateHealthCardsRequestDTO';
import { PatientService } from '@app/shared/services/patient.service';
import { QuickscriptService } from '@app/shared/services/quickscript.service';
import { StepService } from '@app/shared/services/step.service';
import { STEP_CONFIGURATION } from '@app/shared/step-configuration';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { PricingType } from '@app/shared/models/pricing-type';
import { HealthcareComponent } from '@app/pages/home/components/profile/healthcare/healthcare.component';

@Component({
  selector: 'pensioner-concession-card',
  templateUrl: './pensioner-concession-card.component.html',
  styleUrls: ['./pensioner-concession-card.component.scss']
})
export class PensionerConcessionCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('medicareIRNField') medicareIRNField: ElementRef;
  @ViewChild('medicareExpiryMonthField') medicareExpiryMonthField: MatSelect;
  @ViewChild('healthcareCards') healthcareCards: HealthcareComponent;

  private subscription = new Subscription();

  updateCardForm = new FormGroup({
    cardNumber: new FormControl('', [Validators.required]),
    irnNumber: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    month: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required])
  });

  pricingType: any = Constants.QUICKSCRIPT_PRICING_TYPE;
  policyId: string;
  showModal: boolean = false;
  isInvalidCardSection: boolean = false;
  isFormCardSection: boolean = false;
  showErrMsg: boolean = false;
  medicationDetailsData: QuickscriptSharedDataMedication;
  medications: QuickScriptMedication[];
  currentMedication: QuickScriptMedication;
  currentMedicationIndex: number = 0;
  patient: Patient;
  patientId: string;
  privatePricing: any; // a weird combination of MedicationPrice and QuickScriptMedication
  healthCareCardsData: ValidateHealthCardsRequestDTO;
  months: MonthOption[] = [];
  years: string[] = [];
  servicePath: string;
  cardErrorMessage: string;
  hasMedicare: boolean = false;
  isNarrowMobile: boolean = false;
  patientUpdated: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PensionerConcessionCardComponent>,
    private stepService: StepService,
    private patientService: PatientService,
    private quickscriptService: QuickscriptService,
    private functions: Functions
  ) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event?: Event) {
    this.isNarrowMobile = window.innerWidth <= 378;
  }

  ngOnInit(): void {
    this.initStoredMedicationData();
    this.checkPatientHealthCards();
  }

  ngAfterViewInit(): void {
    this.scrollToTop();

    this.subscription.add(
      this.patientService.benefitChangeObs.subscribe(() => {
        this.calculatePrices();
      })
    );

    this.subscription.add(
      this.patientService.patientChangeObs.subscribe(() => {
        this.patientUpdated = true;
        this.updatePatient();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initStoredMedicationData(): void {
    const med1: string = STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_1.path;
    // const med2: string = STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_2.path;
    // const med2Data: QuickscriptSharedDataMedication = this.stepService.getServiceData(med2/*, true*/);

    this.servicePath = med1;
    // this.medicationDetailsData = this.stepService.getServiceData(med1);
    this.medicationDetailsData = this.stepService.getServiceData(
      STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_DETAIL.path
    );

    // if (med2Data) {
    //   this.servicePath = med2;
    //   this.medicationDetailsData = med2Data;
    // } else {
    //   this.servicePath = med1;
    //   this.medicationDetailsData = this.stepService.getServiceData(med1);
    // }

    if (this.medicationDetailsData) {
      this.medications = this.medicationDetailsData.qsMedications;
    }
  }

  scrollToTop(): void {
    setTimeout(() => {
      try {
        document.getElementById('health-card-modal-wrapper').scrollTo(0, 0);
      } catch (err: any) {}
    }, 150);
  }

  /**
   * @async
   * @function calculatePrices
   * @description Recalculate medication pricing
   *
   * @returns {Promise<boolean>}
   */
  async calculatePrices(): Promise<boolean> {
    const benefit: Benefit = await this.patientService.retrieveBenefit();

    if (benefit) {
      const patientBenefit: Benefit = this.patientService.benefit;
      // Make sure benefit is set in the patient service
      if (!patientBenefit || (patientBenefit && patientBenefit.policyId !== benefit.policyId)) {
        this.patientService.setBenefit(benefit);
      }

      this.policyId = benefit.policyId;
    } else {
      this.policyId = null;
    }

    const qsData: QuickscriptSharedDataMedication = this.medicationDetailsData;
    const getPricingPayload: QuickscriptPricingDTO = {
      patientId: this.patientId,
      serviceType: qsData.qsDetailMedicationDelivery,
      listOfOrderItemsStringifiedJSON: JSON.stringify(qsData.qsListOfOrderedItems),
      policyId: this.policyId
    };

    const totalPricingRes: IResponseAPI = await this.quickscriptService.getQuickscriptTotalPricing(getPricingPayload);

    if (!(totalPricingRes && totalPricingRes.success)) {
      return false;
    }

    const totalPricing: QuickScript = totalPricingRes.response;
    const productPrices: ProductPrice[] = totalPricing?.servicePrice?.productPrices;

    qsData.qsMedicationConsultationFee = totalPricing?.servicePrice?.serviceAmount ?? 0;
    qsData.qsMedicationTotalCost = totalPricing?.totalCost ?? 0;

    for (let index = 0; index < productPrices.length; index++) {
      const product: ProductPrice = productPrices[index];

      if (product?.serviceType) {
        if (product.serviceType == ProductServiceType.productTotal) {
          qsData.qsMedicationCost = product.productAmount;
        }
        if (product.serviceType == ProductServiceType.sendToPatient) {
          qsData.qsMedicationDeliveryFee = product.productAmount;
        }
        if (product.serviceType == ProductServiceType.sendToPharmacy) {
          qsData.qsMedicationSendScriptFee = product.productAmount;
        }
      }
    }

    // Added by Seny
    this.medicationDetailsData = qsData;

    return true;
  }

  /**
   * @async
   * @function checkPatientHealthCards
   * @description Check that the pricing type for the currently selected medication matches the patient's
   * healthcare cards and the cards are valid
   *
   * @returns {Promise<void>}
   */
  async checkPatientHealthCards(): Promise<void> {
    this.updatePatient();

    if (Array.isArray(this.medications) && this.medications.length) {
      this.currentMedication = this.medications[this.currentMedicationIndex];

      this.isInvalidCardSection = !this.doesPatientHaveRelevantHealthCards(this.medications);

      if (!this.isInvalidCardSection) {
        await this.checkCardValidity(this.currentMedication);
      }

      // if (this.isInvalidCardSection) {
      this.getPrivatePrice(this.currentMedication);
      // }
    }
  }

  /**
   * @async
   * @function updatePatient
   * @description Update current patient details. Retrieve patient details from
   * the API if only a partial profile is loaded
   *
   * @returns {Promise<void>}
   */
  async updatePatient(): Promise<void> {
    this.patientId = this.patientService.getStoredPatientId();

    if (
      this.patientService.patient &&
      this.patientService.patient.patientId === this.patientId &&
      this.patientService.patient.sex // check that we have the full patient profile loaded
    ) {
      this.patient = this.patientService.patient;
    } else {
      this.patient = await this.patientService.getPatientById(this.patientId);
      if (this.patient) {
        this.patientService.setPatient(this.patient);
      }
    }
  }

  /**
   * @function doesPatientHaveRelevantHealthCards
   * @description For each selected medication, check that the patient has a relevant healthcare card
   *
   * @param {QuickScriptMedication[]} medications
   *
   * @returns {boolean}
   */
  doesPatientHaveRelevantHealthCards(medications: QuickScriptMedication[]): boolean {
    let validCardData: boolean = true;

    medications.forEach((medication: QuickScriptMedication) => {
      if (validCardData) {
        validCardData = this.patientService.doesPatientHaveRelevantHealthCard(
          medication.qsMedicationHealthCard?.pricingType
        );
      }
    });

    return validCardData;
  }

  /**
   * @function getPrivatePrice
   * @description Set private pricing to the specified medication's undiscounted price
   *
   * @param {QuickScriptMedication} medication
   */
  getPrivatePrice(medication: QuickScriptMedication): void {
    const pricings: MedicationPrice[] = medication.qsMedicationDosage.pricings;

    for (let index = 0; index < pricings.length; index++) {
      const pricing: MedicationPrice = pricings[index];

      if (pricing.pricingType === this.pricingType.PRIVATE) {
        this.privatePricing = pricing as any;
        const qsMedicationQtyTimesPrice: number = this.privatePricing.price * medication.qsMedicationQty;
        this.privatePricing.qsMedicationQtyTimesPrice = qsMedicationQtyTimesPrice;

        break;
      }
    }
  }

  /**
   * @async
   * @function checkCardValidity
   * @description Validate patient's healthcare cards for the specified medication
   *
   * @param {QuickScriptMedication} medication
   *
   * @returns {Promise<void>}
   */
  async checkCardValidity(medication: QuickScriptMedication): Promise<void> {
    const selectedCard: MedicationPrice = medication?.qsMedicationHealthCard;
    const pricingType: string = selectedCard?.pricingType ?? PricingType.Private;

    if (pricingType === this.pricingType?.PRIVATE) {
      this.checkComplete();
      return;
    }

    const cardAvailable: boolean = this.patientService.doesPatientHaveRelevantHealthCard(pricingType);
    if (cardAvailable) {
      const isValid: boolean = await this.validateHealthCards(
        this.patient?.medicareCard,
        this.patient?.healthCareCard,
        this.patient?.pensionCard,
        this.patient?.safetyNetCardNumber,
        null // ihiNumber
      );
      if (!isValid) {
        this.isInvalidCardSection = true;
      } else {
        this.hasMedicare = Boolean(this.patient?.medicareCard);
        this.checkComplete();
        return;
      }
    } else {
      this.isInvalidCardSection = true;
    }
  }

  /**
   * @function handleKeyPress
   * @description Handle Tab and Enter keypress events
   *
   * @param {any} $event keypress event
   * @param {any} [focusTarget] target element to focus on if keypress is Tab or Enter
   */
  handleKeyPress($event: any, focusTarget?: any): void {
    const target: any = focusTarget?.nativeElement ? focusTarget.nativeElement : focusTarget;
    this.functions.handleKeyPress($event, target);
  }

  /**
   * @function validateNumber
   * @description Validate a keypress to be a numeric character. Prevent character entry if not a number.
   *
   * @param {any} event keyboard event
   *
   * @returns {boolean} true if numeric character was pressed
   */
  validateNumber(event: any): boolean {
    const isValid: boolean = this.functions.validateNumber(event);

    if (!isValid) {
      event.preventDefault();
    }

    return isValid;
  }

  /**
   * @async
   * @function validateHealthCards
   * @description Validate the patient's healthcare cards
   *
   * @param {HealthCareCard} medicareCard
   * @param {HealthCareCard} pensionCard
   * @param {HealthCareCard} healthCareCard
   * @param {string} safetyNetCardNumber
   * @param {string} individualHealthcareNumber
   *
   * @returns {Promise<boolean>}
   */
  async validateHealthCards(
    medicareCard: HealthCareCard,
    pensionCard: HealthCareCard,
    healthCareCard: HealthCareCard,
    safetyNetCardNumber: string,
    individualHealthcareNumber: string
  ): Promise<boolean> {
    const dateOfBirth: string = this.functions.formatDateYearFirst(this.patient.dateOfBirth);
    const firstName: string = this.patient.firstName.trim();
    const lastName: string = this.patient.lastName.trim();
    const patientId: string = this.patientId;

    this.healthCareCardsData = {
      patientId,
      firstName,
      lastName,
      dateOfBirth,
      medicareCard,
      healthCareCard,
      pensionCard,
      safetyNetCardNumber,
      individualHealthcareNumber
    };

    const response: IResponseAPI = await this.patientService
      .validateHealthCards(this.healthCareCardsData, true)
      .catch((err: any) => {
        console.warn(this.functions.getErrorMessage(err));
        this.cardErrorMessage = this.functions.getErrorMessage(err);
        return null;
      });

    if (response?.success && response.response) {
      return true;
    }

    return false;
  }

  /**
   * @function onClose
   * @description Close the popup
   *
   * @param {boolean} [patientUpdated=this.patientUpdated] has the patient profile been updated
   */
  onClose(patientUpdated: boolean = this.patientUpdated): void {
    this.dialogRef.close(patientUpdated);
  }

  /**
   * @function checkComplete
   * @description Check whether we are finished with the Pensioned Consession Card popup
   */
  checkComplete(): void {
    // If there is only one medication, close the popup
    if (this.currentMedicationIndex === this.medications?.length - 1) {
      this.onClose();

      // If there is more than one medication, and the same pricing type is used for all of the ordered medications,
      // then close the popup, otherwise re-check patient's healthcare cards for other pricing types
    } else {
      if (
        this.medicationDetailsData?.qsMedications[this.currentMedicationIndex]?.qsMedicationHealthCard?.pricingType ===
        this.medicationDetailsData?.qsMedications[this.currentMedicationIndex + 1]?.qsMedicationHealthCard?.pricingType
      ) {
        this.onClose();
      } else {
        this.currentMedicationIndex += 1;
        this.isFormCardSection = false;
        this.isInvalidCardSection = false;
        this.checkPatientHealthCards();
      }
    }
  }

  /**
   * @async
   * @function proceedWithPrivatePrice
   * @description Change the pricing to 'private' (not discounted) values
   *
   * @returns {Promise<void>}
   */
  async proceedWithPrivatePrice(): Promise<void> {
    // this.showModal = false;
    try {
      this.medicationDetailsData.qsMedications[this.currentMedicationIndex].qsMedicationHealthCard = {
        displayName: this.privatePricing.displayName,
        pricingType: this.privatePricing.pricingType,
        price: this.privatePricing.price
      };
      this.medicationDetailsData.qsMedications[this.currentMedicationIndex].qsMedicationQtyTimesPrice =
        this.privatePricing.qsMedicationQtyTimesPrice;
      this.medicationDetailsData.qsListOfOrderedItems[this.currentMedicationIndex].price = this.privatePricing.price;
      this.medicationDetailsData.qsListOfOrderedItems[this.currentMedicationIndex].pricingType =
        this.privatePricing.pricingType;
    } catch (err: any) {
      console.log('Unable to set private pricing for medications:', this.medicationDetailsData?.qsMedications);
      console.log('Error:', err);
    }

    await this.calculatePrices();

    if (this.medicationDetailsData?.qsListOfOrderedItems?.length) {
      this.stepService.completePart(this.servicePath, null, true, false, false, true);
      this.stepService.completeStep(
        STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_DETAIL.path,
        this.medicationDetailsData,
        true,
        true
      );
    }

    this.checkComplete();
  }

  /**
   * @function updateHealthCards
   * @description Trigger 'saveAll' card details inside the healthcare cards component
   */
  updateHealthCards(): void {
    this.healthcareCards.saveAll().then((savedSuccessfully: boolean) => {
      if (savedSuccessfully) {
        this.onClose(true);
      }
    });
  }

  /**
   * @function switchToForm
   * @description Switch to the healthcare card entry form view
   */
  switchToForm(): void {
    this.isFormCardSection = true;
    this.isInvalidCardSection = false;
    this.scrollToTop();
  }
}
