import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Functions } from '@app/shared/functions';
import { Patient } from '@app/shared/models/patient';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { PatientAttributionService } from '@app/shared/services/patient-attribution.service';
import { PatientService } from '@app/shared/services/patient.service';
import { STEP_PATH } from '@app/shared/step-configuration';
import { Subscription } from 'rxjs';
import { Benefit, BenefitService } from '../../models/benefit';
import { PolicyService } from '../../services/policy.service';
import { ModalsService } from '../../services/modals.service';
import { ErrorModalService } from '../../services/error-modal.service';

@Component({
  selector: 'switch-patient',
  templateUrl: './switch-patient.component.html',
  styleUrls: ['./switch-patient.component.scss']
})
export class SwitchPatientComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private _startingPatientId: string;
  selectedPatientId: string;
  patients: Patient[];
  isMobile: boolean;
  isLoading: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<SwitchPatientComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private functions: Functions,
    private analytics: GoogleAnalyticsService,
    private patientService: PatientService,
    private patientAttributionService: PatientAttributionService,
    private policyService: PolicyService,
    private errorModalService: ErrorModalService
  ) {
    this.patientService.trackSwitchPatientPopupOpen(true);
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  async ngOnInit(): Promise<void> {
    await this.getPatients();

    let patient: Patient;

    // This can only happen if user refreshes the page while switching patients
    if (!this.patientService.patient) {
      patient = await this.patientService.setDefaultPatient();
    } else {
      patient = this.patientService.patient;
    }

    if (patient?.patientId) {
      this.selectedPatientId = patient.patientId;
    }

    // Keep track of the originally selected patient
    this._startingPatientId = this.patientService.patient?.patientId || this.selectedPatientId;

    // List of patients has changed
    this.subscription.add(
      this.patientService.patientListByEmailChangeObs.subscribe((patientList: Patient[]) => {
        this.patients = patientList;
        this.processPatientsList();
      })
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      try {
        document.getElementById('selected-patient').scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } catch (err: any) {}
    }, 300);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async getPatients(): Promise<void> {
    this.patients = this.data?.patients ?? null;

    if (this.patients?.length) {
      this.processPatientsList();
    } else {
      this.isLoading = true;
      await this.patientService
        .getPatientsByEmail()
        .catch((err: any) => {
          console.log('Unable to retrieve patients list by email address. Error:', this.functions.getErrorMessage(err));
        })
        .then((response: Patient[]) => {
          if (Array.isArray(response) && response.length) {
            this.patients = response;
            this.processPatientsList();
            this.changeDetectorRef.detectChanges();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  processPatientsList(): void {
    let patients: Patient[] = [...this.patients];

    /*
    patients.forEach((patient: Patient) => {
      if (patient.relationship === 'AccountHolder') {
        patient.relationship = 'Account Holder';
      }
    });
    */

    // Sort patients in alphabetical order (asc)
    patients.sort((a: Patient, b: Patient) => {
      if (a.relationship === 'AccountHolder') {
        return -1;
      } else if (b.relationship === 'AccountHolder') {
        return 1;
      } else {
        return ((a.firstName || '') + (a.lastName || '')).toLocaleLowerCase() >
          ((b.firstName || '') + (b.lastName || '')).toLocaleLowerCase()
          ? 1
          : -1;
      }
    });

    this.patients = patients;

    this.selectPatient(this.patientService.getStoredPatientId());
  }

  onClose(value: string): void {
    const patientChanged = this._startingPatientId !== this.selectedPatientId;

    if (patientChanged) {
      this.selectPatient(this.selectedPatientId);
      this.analytics.switchPatient(this._startingPatientId + ' => ' + this.selectedPatientId);
    }

    if (value === 'newPatient') {
      this.closeDialog(value);

      // If 'Add New Patient' was triggered outside a flow, add new patient within user profile page
      if (this.router.url.startsWith('/' + STEP_PATH.DASHBOARD)) {
        this.router.navigate([STEP_PATH.DASHBOARD, 'your-account', 'add-new-patient']);
      }
    } else {
      this.closeDialog(this.selectedPatientId);
      this.patientService.notifyRequestPolicyEligibilityCheck();
    }
  }

  closeDialog(value: string): void {
    try {
      this.patientService.trackSwitchPatientPopupOpen(false);
      this.dialogRef.close(value);
    } catch (err: any) {
      console.log('Unable to close Switch Patient modal. Error = ', this.functions.getErrorMessage(err));
    }
  }

  updateSelectedPatientId(patientId: string): void {
    this.selectedPatientId = patientId;
    this.onClose(null);
  }

  selectPatient(patientId: string): void {
    this.patients.forEach((patient) => {
      if (patient.patientId === patientId) {
        patient.isSelected = true;

        // Check whether we have the full patient profile for this patient or not
        if (!patient.sex) {
          this.patientService
            .getPatientById(patientId) //, true)
            .catch((err: any) => {
              console.log(
                'Unable to retrieve patient data with id',
                patientId,
                'Error:',
                this.functions.getErrorMessage(err)
              );
            })
            .then((patientFull: Patient) => {
              this.updatePatient(patientFull);
            });
        } else {
          this.updatePatient(patient);
        }
      } else {
        patient.isSelected = false;
      }
    });
  }

  updatePatient(patient: Patient): void {
    // Seny - We will only keep track of the patient being selected (clear patientOther)
    // Account holder email is stored in local storage, so we can always retrieve them if needed
    // patientOther will only be used within the sign-up flows when both account holder and additional
    // patient need to be accessible

    // Update selected patient and save to storage
    this.patientService.setPatientOther(null);
    this.patientService.setPatient(patient);

    // Update patient data mapping to include selected patient
    this.patientService.setPatientData(patient);

    this.patientAttributionService.saveAttribution();

    // We'll also need to validate the currently applied policy's constraints against the selected patient
    this.policyService.validatePolicyPatientConstraints(patient).then((constraintValidationResult) => {
      if (!constraintValidationResult.valid) {
        const benefit: Benefit = this.patientService.benefit;


      }
    });
  }
}
