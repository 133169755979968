import { Attachment } from "./attachment";
import { Medication } from "./medication";

export class Prescription {
  appointmentId?: string;
  appointmentNumber?: string;
  isElectronicPrescription?: boolean;
  medications?: Medication[];
  medicationsNames?: string;
  orderId?: string;
  orderNumber?: string;
  pharmacyName?: string;
  orderCreatedDateUTC?: string;
  prescriptionProvidedDateUTC?: string;
  prescriptionStatus?: string;
  scriptAttachments?: Attachment[];
  price?: number;
  prescriptionDate?: Date;
  isResendable?: boolean;
  canReSendScriptToPharmacy?: boolean;
  isExpired?: boolean;
  isQuickScript?: boolean;
  quickScriptPrice?: number;
  hidePolicyNumber?: boolean;
  serviceType?: string;
}
