import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[safetynetFormat]',
})
export class SafetynetDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed: string = input.value.replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 12) {
      trimmed = trimmed.substring(0, 12);
    } else if (trimmed.length === 1) {
      trimmed += 'N-';
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 2) !== '') {
      numbers.push(trimmed.substring(0, 2) + '-');
    }
    if (trimmed.substring(2, 11) !== '') {
      numbers.push(trimmed.substring(2, 11));
    }

    input.value = numbers.join('').toUpperCase();
  }
}

@NgModule({
  declarations: [SafetynetDirective],
  exports: [SafetynetDirective],
})
export class SafetynetDirectiveModule {}
