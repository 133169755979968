import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@src/app/core/services/credentials.service';
import { Functions } from '@src/app/shared/functions';
import {
  B2BCustomerBookingStatus,
  B2BCustomerPortalBookingPatient,
  B2BPortalClaimViewModel
} from '@src/app/shared/models/b2b-customer-portal/models';
import { B2BCustomerPortalInfo } from '@src/app/shared/models/b2bCustomerPortalInfo';
import { IdName } from '@src/app/shared/models/common/models';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { PatientService } from '@src/app/shared/services/patient.service';
import { WhitelabelService } from '@src/app/shared/services/whitelabel.service';
import { STEP_CONFIGURATION, STEP_PATH } from '@src/app/shared/step-configuration';
import { first } from 'rxjs';

@Component({
  selector: 'business-portal-patient-booking-generate-new-policy',
  templateUrl: './business-portal-patient-booking-generate-new-policy.component.html',
  styleUrls: ['./business-portal-patient-booking-generate-new-policy.component.scss']
})
export class BusinessPortalPatientBookingGenerateNewPolicyComponent implements OnInit {
  isSubmitting: boolean;
  availablePolicies: IdName[] = null;
  policiesLoaded: boolean;

  constructor(
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private patientService: PatientService,
    private functions: Functions,
    private whiteLabelService: WhitelabelService,
    private credentialsService: CredentialsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.GenerateNewPolicy);

    this.b2bCustomerService.patient.pipe(first()).subscribe((patient: B2BCustomerPortalBookingPatient) => {
      if (patient) {
        this.b2bCustomerService.getPoliciesForPatient(patient.id).then((policies: IdName[]) => {
          this.availablePolicies = policies;
          this.policiesLoaded = true;
        });
      }
    });
  }

  createNewPolicy() {
    this.b2bCustomerService.b2bCustomerPortalInfo
      .pipe(first())
      .subscribe(async (b2bCustomerPortalInfo: B2BCustomerPortalInfo) => {
        if (b2bCustomerPortalInfo) {
          this.isSubmitting = true;
          const b2bPortalClaim: B2BPortalClaimViewModel = await this.b2bCustomerService.generateNewPolicy();

          this.isSubmitting = false;

          if (b2bPortalClaim) {
            this.b2bCustomerService.setVerifiedPolicy(b2bPortalClaim);
            this.patientService.getBenefit(b2bPortalClaim.number, null, true);
            this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.MakeBooking);
            this.functions.showToast('New policy successfully generated and applied');
            this.navigateOut();
          }
        } else {
          console.error('ERROR in createNewPolicy');
        }
      });
  }

  private navigateOut() {
    // login as the patient
    this.credentialsService.setCredentials(this.b2bCustomerService.getTempPatientCredentials());
    this.b2bCustomerService.setTempPatientCredentials(null);

    const appointmentStepType: string = this.whiteLabelService.getAppointmentStepType(
      this.b2bCustomerService.serviceType || 'doctor'
    );
    this.router.navigate([
      STEP_PATH.APPOINTMENT,
      this.b2bCustomerService.serviceType || 'doctor',
      STEP_CONFIGURATION[appointmentStepType].CHOOSE_APPOINTMENT_TIME.virtualPath
    ]);
  }

  selectPolicy(policy: IdName) {
    this.b2bCustomerService.b2bCustomerPortalInfo
      .pipe(first())
      .subscribe(async (b2bCustomerPortalInfo: B2BCustomerPortalInfo) => {
        if (b2bCustomerPortalInfo) {
          this.isSubmitting = true;
          const b2bPortalClaim: B2BPortalClaimViewModel = await this.b2bCustomerService.verifyPolicy(policy.name);

          this.isSubmitting = false;

          if (b2bPortalClaim) {
            this.b2bCustomerService.setVerifiedPolicy(b2bPortalClaim);
            this.patientService.getBenefit(policy.name, null, true);
            this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.MakeBooking);
            this.functions.showToast('Selected policy applied');
            this.navigateOut();
          }
        } else {
          console.error('ERROR in selectPolicy');
        }
      });
  }
}
