import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralMedical } from '@app/shared/models/general-medical';
import { BMICalculation } from '@app/shared/models/weightloss/BMICalculation';
import { WeightLossLogEntry } from '@app/shared/models/weightloss/weightLossLogEntry';
import { PatientService } from '@app/shared/services/patient.service';
import { WeightLossService } from '@app/shared/services/weight-loss.service';
import { GeneralMedicalService } from '@app/shared/services/general-medical.service';
import { Subscription } from 'rxjs';
import { Functions } from '@app/shared/functions';
import { STEP_PATH } from '@app/shared/step-configuration';
import { IResponseAPI } from '@app/shared/models/api-response';
import { ActiveCampaignService } from '@app/shared/services/active-campaign.service';
import { GoalProgressDetails } from '@app/shared/models/weightloss/goalProgressDetails';
import { Constants } from '@app/shared/constants';

enum ModalComponentDisplayTypes {
  checkIn = 'check-in',
  editGoals = 'edit-goals'
}

@Component({
  selector: 'weight-loss-check-in-modal',
  templateUrl: './weight-loss-check-in-modal.component.html',
  styleUrls: ['./weight-loss-check-in-modal.component.scss']
})
export class WeightLossCheckInModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();
  private _weightLossData: GeneralMedical;
  private _weightLossLogs: WeightLossLogEntry[] = [];

  patientId: string;
  checkInIsOverdue: boolean = false;
  bmiCalculation: BMICalculation;
  weightLossCheckInForm: FormGroup;
  isLoading: boolean = false;
  checkInComplete: boolean = false;
  isGoalEdit: boolean = false;
  weightGoalReached: boolean = false;
  waistGoalReached: boolean = false;
  currentWaistCssClass: string = null;
  weightGoalLabel: string = null;
  waistGoalLabel: string = null;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WeightLossCheckInModalComponent>,
    private router: Router,
    private functions: Functions,
    private patientService: PatientService,
    private weightLossService: WeightLossService,
    private medicalHistoryService: GeneralMedicalService,
    private activeCampaignService: ActiveCampaignService
  ) {
    this.isGoalEdit = Boolean(this.data?.displayType === ModalComponentDisplayTypes.editGoals);
    this.updateWeightLossData(this.data?.weightLossData);

    let currentWeight: number = null;
    let currentWaist: number = null;
    let goalWeight: number = null;
    let goalWaist: number = null;

    if (this.weightLossLogs?.length) {
      try {
        const latestLog: WeightLossLogEntry = this.functions.sortISO8601DateArray(
          this.weightLossLogs,
          'asOfDate'
        )[this.weightLossLogs.length - 1];

        currentWeight = latestLog.currentWeight || null;
        currentWaist = latestLog.currentWaist || null;
        goalWeight = this._weightLossData?.goalWeight || null;
        goalWaist = this._weightLossData?.goalWaist || null;
      } catch (err: any) {
        console.log('Could not parse latest weight loss log. Error:', this.functions.getErrorMessage(err));
      }
    }

    this.weightLossCheckInForm = new FormGroup({
      currentWeight: new FormControl(currentWeight, [Validators.required]),
      currentWaist: new FormControl(currentWaist, [Validators.required]),
      goalWeight: new FormControl(goalWeight, [Validators.required]),
      goalWaist: new FormControl(goalWaist, [Validators.required]),
    });
  }

  ngOnInit() {
    this.patientId = this.patientService.patient?.patientId || null;

    if (this.patientId && !this.weightLossData) {
      this.isLoading = true;
      this.weightLossService
        .init(this.patientId)
        .then((success: boolean) => {
          if (success) {
            this.updateWeightLossData();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.updateWeightLossData();
    }
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.medicalHistoryService.weightLossDataChangeObs.subscribe((genMedical: GeneralMedical) => {
        this.weightLossData = genMedical;
        /*
        if (!this.functions.deepCompare(this.weightLossData, genMedical)) {
          this.updateWeightLossData();
          // this.sendActiveCampaignEvent();
        } else {
          this.updateWeightLossData();
        }
        */
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateWeightLossData(weightLossData?: GeneralMedical): void {
    const goalDetails: GoalProgressDetails = this.weightLossService.getGoalProgressDetails(
      weightLossData ?? this.weightLossService.weightLossData,
      this.patientService.getPatientTimeZoneOffset()
    );

    this._weightLossData = goalDetails?.weightLossData || null;
    this.weightLossLogs = goalDetails?.weightLossLogs || this._weightLossData?.patientWeightLossLogs;
    this.checkInIsOverdue = Boolean(goalDetails?.checkInIsOverdue);
    this.bmiCalculation = goalDetails?.bmiCalculation || null;
    this.weightGoalReached = Boolean(goalDetails?.weightGoalReached);
    this.weightGoalLabel = goalDetails?.weightGoalLabel || null;
    this.waistGoalReached = Boolean(goalDetails?.waistGoalReached);
    this.waistGoalLabel = goalDetails?.waistGoalLabel || null;
    this.currentWaistCssClass = goalDetails?.waistCssClass || null;
  }

  submitCheckIn(): void {
    try {
      const goalWeight: number = this.functions.roundToOneDecimal(this.goalWeight.value);
      const goalWaist: number = this.functions.roundToOneDecimal(this.goalWaist.value);

      const oldGoalWeight: number = this._weightLossData?.goalWeight || null;
      const oldGoalWaist: number = this._weightLossData?.goalWaist || null;

      // Only save goals?
      if (this.isGoalEdit) {
        this.medicalHistoryService.saveWeightLossGoals(
          this.patientId,
          goalWeight,
          goalWaist
        ).then((response: IResponseAPI) => {
          if (response?.success) {
            this.checkInComplete = true;
            this.updateWeightLossData();

            // Send "Updated" Active Campaign event only if one of the goals has changed
            if (goalWeight !== oldGoalWeight || goalWaist !== oldGoalWaist) {
              this.sendActiveCampaignEvent();
            }
          } else {
            this.checkInComplete = false;
            this.functions.showToast('There was a problem saving your weight loss goal data.');
          }
        });
      } else {
        const currentWeight: number = this.functions.roundToOneDecimal(this.currentWeight.value);
        const currentWaist: number = this.functions.roundToOneDecimal(this.currentWaist.value);

        const oldCurrentWeight: number = this._weightLossData?.currentWeight || null;
        const oldCurrentWaist: number = this._weightLossData?.currentWaist || null;

        const weightLossData: GeneralMedical = {
          ...(this.weightLossData || {}),
          currentWeight,
          currentWaist,
          goalWeight,
          goalWaist
        };

        this.medicalHistoryService.saveWeightLoss(
          this.patientId,
          weightLossData
        ).then((response: IResponseAPI) => {
          if (response?.success) {
            this.checkInComplete = true;
            this.updateWeightLossData();

            // Send "Updated" Active Campaign event only if one of the measurements has changed
            if (
              currentWeight !== oldCurrentWeight ||
              currentWaist !== oldCurrentWaist ||
              goalWeight !== oldGoalWeight ||
              goalWaist !== oldGoalWaist
            ) {
              this.sendActiveCampaignEvent();
            }
          } else {
            this.checkInComplete = false;
            this.functions.showToast('There was a problem saving your weight loss check-in data.');
          }
        });
      }
    } catch (err: any) {
      console.log('Unable to upsert weight loss check-in data. Error: ', this.functions.getErrorMessage(err));
    }
  }

  sendActiveCampaignEvent(): void {
    this.activeCampaignService.createAndSendWeightLossCheckInEvent(
      this.weightLossData,
      null,
      Constants.ACTIVE_CAMPAIGN_EVENTS.WeightLossUpdated
    );
  }

  goToDashboard(): void {
    this.onClose();
    this.router.navigate([STEP_PATH.DASHBOARD, STEP_PATH.WEIGHT_LOSS_CHECKIN]);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  validateNumber(event: any): boolean {
    const isValid: boolean = this.functions.validateNumber(event);

    if (!isValid) {
      event.preventDefault();
    }

    return isValid;
  }

  get weightLossData(): GeneralMedical {
    return this._weightLossData || null;
  }
  set weightLossData(genMedical: GeneralMedical) {
    this.updateWeightLossData(genMedical);
  }
  get weightLossLogs(): WeightLossLogEntry[] {
    return this._weightLossLogs;
  }
  set weightLossLogs(logs: WeightLossLogEntry[]) {
    this._weightLossLogs = logs || [];
  }
  get currentWeight() {
    return this.weightLossCheckInForm.get('currentWeight');
  }
  get currentWaist() {
    return this.weightLossCheckInForm.get('currentWaist');
  }
  get goalWeight() {
    return this.weightLossCheckInForm.get('goalWeight');
  }
  get goalWaist() {
    return this.weightLossCheckInForm.get('goalWaist');
  }
}
