<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">Patient Mobile Phone Number</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-primary">
    <form [formGroup]="personalDataFormGroup">
      <p class="col-12">Please enter your mobile phone number below:</p>

      <div class="form-content">
        <div class="col-12 mt-3">
          <mat-form-field appearance="outline" class="default-input prefixed-input">
            <mat-label>Mobile Phone</mat-label>
            <input
              #mobilePhoneField
              formControlName="phone"
              autocomplete="mobile"
              placeholder="Mobile Phone"
              maxlength="20"
              type="tel"
              matInput
              required
              (keydown)="validateMobileNumber($event)" />
            <mat-error *ngIf="phone.invalid && phone.errors && (phone.touched || phone.dirty)">
              <span *ngIf="phone.hasError('required')">Mobile Phone is required</span>
              <span *ngIf="phone.hasError('pattern')">Mobile Phone is invalid</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="row">
      <div class="col-6 action-buttons">
        <button mat-stroked-button color="accent" class="text-wrap btn-block" (click)="onClose()">Cancel</button>
      </div>
      <div class="col-6 action-buttons">
        <button
          mat-flat-button
          color="accent"
          class="text-wrap btn-block"
          [disabled]="(phone.invalid && phone.errors) || isSaving"
          (click)="saveAll()">
          {{ isSaving ? 'Saving...' : 'Save' }}
        </button>
      </div>

      <!-- <div class="first-button col-xl-6 col-xs-12 mx-0">
        <button mat-stroked-button color="accent" class="btn-block" (click)="onClose()">Close</button>
      </div>
      <div class="second-button col-xl-6 col-xs-12 mx-0 button-header">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          [disabled]="(phone.invalid && phone.errors) || isSaving"
          (click)="saveAll()">
          Save
        </button>
      </div> -->
    </div>
  </div>
</div>
