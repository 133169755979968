<form [formGroup]="autoAddressForm" id="auto-address-form">
  <div>
    <mat-form-field class="auto-address-field" appearance="outline" class="address-input">
      <mat-label>Address</mat-label>
      <input
        formControlName="autoAddressLine"
        #addresstext
        matInput
        placeholder="Enter an address"
        autocomplete="off"
        required
        (blur)="onExitAddressField($event)" />
      <i matSuffix class="fa fa-map-marker-alt"></i>

      <mat-error *ngIf="autoAddressLine.invalid && autoAddressLine.errors">
        <!--div *ngIf="autoAddressLine.errors.required" class="d-none d-sm-inline">This field is required</div-->
        <div *ngIf="autoAddressLine.errors.message">{{ capitalise(autoAddressLine.errors.message) }}</div>
      </mat-error>
    </mat-form-field>

    <div class="mat-small caption text-right">
      <span *ngIf="!(autoAddressLine.invalid && autoAddressLine.errors?.message)">
        <span class="d-inline d-sm-none">Having </span><span class="d-inline d-sm-none">t</span><span class="d-none d-sm-inline">T</span>rouble
        <span class="d-inline d-sm-none">finding</span><span class="d-none d-sm-inline">with</span> your address?
      </span>
      <span
        [ngClass]="autoAddressLine.invalid && (autoAddressLine.dirty || autoAddressLine.touched)
          ? 'd-inline-block d-xl-inline'
          : 'd-sm-inline'">
        <a role="button" id="autoFormSwitchButton" (click)="switch()">Enter manually</a>
      </span>
    </div>
  </div>
</form>
