<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <div class="text-primary subtitle">Referrals</div>
      <div *ngIf="!isConfirm" class="text-primary title">Send to Specialist</div>
      <div *ngIf="isConfirm" class="text-primary title">Confirmation</div>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div *ngIf="!isConfirm" class="modal-body container-fluid text-primary">
    <p class="col-12 text-justify px-0">
      We can send a copy of your referral or test request directly to your specialist
      or laboratory via email.
    </p>
    <mat-card appearance="outlined" class="pb-2">
      <mat-card-subtitle class="text-secondary">Please enter the email address:</mat-card-subtitle>
      <mat-card-content>
        <div class="form-container">
          <form [formGroup]="emailForm">
            <div class="form-group m-0">
              <mat-form-field appearance="outline" class="default-input w-100">
                <input
                  formControlName="email"
                  autocomplete="email"
                  type="text"
                  id="email"
                  matInput
                  maxlength="255" />
                <mat-error *ngIf="email.hasError('required')">
                  Email address is required
                </mat-error>
                <mat-error *ngIf="email.hasError('pattern')">
                  Email format is not valid
                </mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="isConfirm" class="modal-body-confirm container-fluid text-primary">
    <p class="col-12 px-0 m-0">
      A copy of your referral/test request has been sent to your specialist/laboratory.
      Please ask them to check their junk mail/spam folder if they have not received
      it.<br/><br/><strong>Sent to: {{ email.value }}</strong>
    </p>
  </div>
  <div *ngIf="!isConfirm" class="modal-footer container-fluid bg-muted flex-nowrap">
    <div *ngIf="!isMobile" class="row m-0 w-100">
      <div class="first-button col-xl-6 col-xs-12">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
      <div class="second-button col-xl-6 col-xs-12">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          [class.spinner]="isLoading"
          [disabled]="!emailForm.valid || isLoading"
          (click)="onConfirm()">
          Send
        </button>
      </div>
    </div>
    <div *ngIf="isMobile" class="row m-0 w-100">
      <div class="first-button col-xl-6 col-xs-12">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          [disabled]="!emailForm.valid"
          (click)="onConfirm()">
          Send
        </button>
      </div>
      <div class="second-button col-xl-6 col-xs-12">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isConfirm" class="modal-footer container-fluid bg-muted flex-nowrap">
    <button
      mat-flat-button
      color="accent"
      class="btn-block"
      (click)="onClose()">
      Close
    </button>
  </div>
</div>
