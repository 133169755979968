import { Injector } from "@angular/core";
import { WhitelabelAnalyticsEventType, WhitelabelAnalyticsHandler } from "@app/shared/services/whitelabel/whitelabel-analytics-handler";
import { WhitelabelAnalyticsProvider } from "@app/shared/services/whitelabel/whitelabel-analytics-provider";
import { environment } from "@env/environment";

export class BluaAnalyticsHandler extends WhitelabelAnalyticsHandler {
  private readonly DATALAYER_NAME: string = environment.whitelabels.Blua.analytics.gtm.dataLayerName;
  private readonly GTAG_ID: string = environment.whitelabels.Blua.analytics.gtm.id;
  private readonly FLOODLIGHT_SEND_TO: string = environment.whitelabels.Blua.analytics.floodlight.send_to;
  private readonly FB_ID: string = environment.whitelabels.Blua.analytics.facebook.id;

  private gtag: Function = null;
  private fbq: Function = null;

  constructor(injector: Injector) {
    super(injector, 'BluaAnalyticsHandler');

    this.InitGA();
    this.InitFB();

    this.InitEventHandlers();
  }

  private InitEventHandlers(): void {
    this.On(WhitelabelAnalyticsEventType.AppointmentBooked, () => {
      this.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': this.FLOODLIGHT_SEND_TO
      });
    });

    this.On(WhitelabelAnalyticsEventType.PageView, () => {
      this.fbq('track', 'PageView');
    });

    this.On(WhitelabelAnalyticsEventType.AppointmentBooked, () => {
      this.fbq('track', 'CompleteRegistration', { Campaign: 'Blua', ActionType: 'BluaConfirmation' });
    });
  }

  private async InitGA(): Promise<void> {
    try {
      const dataLayer: any[] = [];

      this.SetWindowProperty(this.DATALAYER_NAME, dataLayer);

      this.gtag = function() {
          dataLayer.push(arguments);
      };

      await this.LoadScript(`https://www.googletagmanager.com/gtag/js?id=${this.GTAG_ID}&l=${this.DATALAYER_NAME}`);

      this.gtag('js', new Date());
      this.gtag('config', this.GTAG_ID);
    } catch (err: any) {
      this.aiContext.trackException(err, 'FailedToInitConsumer', { consumer: 'GA' });
    }
  }

  private async InitFB(): Promise<void> {
    try {
      let n: any = this.fbq = function() {
        n.callMethod ?
          n.callMethod.apply(n, arguments) :
          n.queue.push(arguments);
      };

      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];

      this.SetWindowProperty('_fbq', this.fbq);
      this.SetWindowProperty('fbq', this.fbq);

      await this.LoadScript('https://connect.facebook.net/en_US/fbevents.js');

      this.fbq('init', this.FB_ID);
    } catch (err: any) {
      this.aiContext.trackException(err, 'FailedToInitConsumer', { consumer: 'FB' });
    }
  }

  public static Register(): void {
    if (!environment.whitelabels.Blua.analytics.enabled) {
      if (!environment.production) {
        console.log('[BluaAnalyticsHandler]', 'Tracking is disabled in the current environment');
        return;
      }
    }

    WhitelabelAnalyticsProvider.Register('Blua', (injector: Injector) => new BluaAnalyticsHandler(injector));
  }
}
