export enum PractitionerType {
  doctor = 'doctor',
  bulkbillgp = 'bulkbillgp',
  psychologist = 'psychologist',
  dietitian = 'dietitian',
  wellness = 'wellness-specialist',
  fertility = 'fertility',
  weightloss = 'weightloss',
  mentalhealth = 'mentalhealth',
  sleep = 'sleep-specialist',
  sleepgp = 'sleep-gp',
  smokingcessation = 'smokingcessation'
}

// const is a lot more flexible than enums especially given our current use of constants.
// Using consts instead of enums (which will be transpiled as functions in js) will allow us to type the existing code in the future.
export const PractitionerTypeConstants = {
  doctor: 'doctor',
  bulkbillgp: 'bulkbillgp',
  psychologist: 'psychologist',
  dietitian: 'dietitian',
  wellness: 'wellness-specialist',
  fertility: 'fertility',
  weightloss: 'weightloss',
  mentalhealth: 'mentalhealth',
  sleep: 'sleep-specialist',
  sleepgp: 'sleep-gp',
  smokingcessation: 'smokingcessation'
} as const;
