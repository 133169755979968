import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Functions } from '@app/shared/functions';
import { BMICalculation } from '@app/shared/models/weightloss/BMICalculation';

@Component({
  selector: 'bmi-meter',
  templateUrl: './bmi-meter.component.html',
  styleUrls: ['./bmi-meter.component.scss']
})
export class BmiMeterComponent implements OnInit {
  @Input('bmiCalculation') bmiCalculation: BMICalculation;

  bmi: number = 0;
  highlightPillNumber: number;
  colouredPills: Array<BMICalculation> = new Array(18);

  constructor(
    private functions: Functions
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'bmiCalculation':
            const previous: BMICalculation = changes.bmiCalculation.previousValue;
            const current: BMICalculation = changes.bmiCalculation.currentValue;
            if (previous && current && !this.functions.deepCompare(previous, current)) {
              this.initData();
            }
            break;
        }
      }
    }
  }

  ngOnInit() {
    this.initData();
  }

  initData(): void {
    if (this.bmiCalculation) {
      // BMI ranges from 15 to 36, convert to an index number on the bmi meter
      this.bmi = Math.round(this.bmiCalculation.bmiValue * 10) / 10;

      // Underweight
      if (this.bmi > 0 && this.bmi < 18.5) {
        if (this.bmi > 16.5) {
          this.highlightPillNumber = 2; // warning
        } else if (this.bmi > 15 && this.bmi <= 16.5) {
          this.highlightPillNumber = 1; // danger
        } else {
          this.highlightPillNumber = 0; // danger
        }

      // Normal Weight
      } else if (this.bmi >= 18.5 && this.bmi < 25) {
        if (this.bmi < 19.5) {
          this.highlightPillNumber = 3;
        } else if (this.bmi >= 19.5 && this.bmi < 21) {
          this.highlightPillNumber = 4;
        } else if (this.bmi >= 21 && this.bmi < 22.5) {
          this.highlightPillNumber = 5;
        } else if (this.bmi >= 22.5 && this.bmi < 24) {
          this.highlightPillNumber = 6;
        } else {
          this.highlightPillNumber = 7;
        }

      // Overweight
      } else if (this.bmi >= 25 && this.bmi < 30) {
        if (this.bmi < 26.5) {
          this.highlightPillNumber = 8;
        } else if (this.bmi >= 26.5 && this.bmi < 27.5) {
          this.highlightPillNumber = 9;
        } else if (this.bmi >= 27.5 && this.bmi < 28.5) {
          this.highlightPillNumber = 10;
        } else {
          this.highlightPillNumber = 11;
        }

      // Obese
      } else if (this.bmi >= 30) {
        if (this.bmi < 31.5) {
          this.highlightPillNumber = 12;
        } else if (this.bmi >= 31.5 && this.bmi < 33) {
          this.highlightPillNumber = 13;
        } else if (this.bmi >= 33 && this.bmi < 34.5) {
          this.highlightPillNumber = 14;
        } else if (this.bmi >= 34.5 && this.bmi < 36) {
          this.highlightPillNumber = 15;
        } else if (this.bmi >= 36 && this.bmi < 37.5) {
          this.highlightPillNumber = 16;
        } else {
          this.highlightPillNumber = 17;
        }
      }
    }

    for (let x = 0; x < this.colouredPills.length; x++) {
      this.colouredPills[x] = new BMICalculation();

      if (x < 2 || (x >= 12 && x <= 17)) {
        this.colouredPills[x].cssClass = 'danger';
      } else if (x === 2 || (x >= 8 && x <= 11)) {
        this.colouredPills[x].cssClass = 'warning';
      } else {
        this.colouredPills[x].cssClass = 'success';
      }

      this.colouredPills[x].bmiValue = x === this.highlightPillNumber && this.bmi
        ? this.bmi
        : null;
    }
  }
}
