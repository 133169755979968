import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject, Subscription } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { Term } from '../models/term';
import { AcceptedTermsRequestDTO } from '../models/acceptedTermsRequestDTO';
import { IResponseAPI } from '../models/api-response';
import { MedicationQuestionnaire } from '../models/medication-questionnaire';
import { Prescription } from '../models/prescription';
import { QuickScriptOrderResponse } from '../models/quick-script-order-response';
import { QuickScript } from '../models/quickscript';
import { QuickscriptPricingDTO } from '../models/quickscriptPricingDTO';
import { QuickscriptPricingOverview } from '../models/quickscriptPricingOverview';
import { QuickscriptRatingDTO } from '../models/quickscriptRatingDTO';
import { QuickScriptServiceType } from '../models/quickscriptServiceType';
import { GoogleAnalyticsService } from './google-analytics.service';
import { QuickscriptQuestionnaireDTO } from '../models/quickscriptQuestionnaireDTO';
import { PromiseHelperService } from './promise-helper.service';
import { ReceivePrescriptionType } from '../models/prescription-type';
import { QuickScriptOrderItem } from '../models/quick-script-order-item';
import { AssociatedMedications } from '../models/associated-medications';
import { MedicationService } from './medication.service';
import { QuickscriptSharedDataMedication } from '../models/quickscriptSharedData';
import { AIContext, AppInsightsService } from './appinsights.service';
import { Medication } from '../models/medication';
import { STEP_PATH } from '../step-configuration';
import { StepService } from './step.service';
import { environment } from '@env/environment';
import { Questionnaire } from '../models/questionnaire';
import { PostQuickScriptQuestionnaireDTO } from '../models/postQuickScriptQuestionnaireDTO';
import DoDPostQuickScriptQuestionnaire from '@app/shared/static-resources/dodPostQuickScriptQuestionnaire.json';

@Injectable({
  providedIn: 'root'
})
export class QuickscriptService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private readonly urlQuickScript: string = `${this.url}/quickscript`;

  private subscription = new Subscription();
  private _quickscript: any = null;
  private _quickScriptServiceType?: QuickScriptServiceType;

  private _quickScriptServiceTypeSubject: Subject<QuickScriptServiceType> = new Subject<QuickScriptServiceType>();
  public quickScriptServiceTypeObs: Observable<QuickScriptServiceType> =
    this._quickScriptServiceTypeSubject.asObservable();

  @LocalStorage()
  private _questionnaire: any;

  private _quickScriptPricing: QuickscriptPricingOverview;

  private _quickScriptChange: Subject<any> = new Subject<any>();
  public quickScriptChangeObs = this._quickScriptChange.asObservable();

  aiContext: AIContext;

  constructor(
    private http: HttpClient,
    private router: Router,
    private functions: Functions,
    private analytics: GoogleAnalyticsService,
    private medicationService: MedicationService,
    private stepService: StepService,
    private aiService: AppInsightsService,
    private promiseHelperService: PromiseHelperService
  ) {
    this.aiContext = this.aiService.createContext('QuickScriptService');
    this._init();
  }

  private _init() {
    console.log('[QUICK-SCRIPT-SERVICE] Init()');
  }

  setQuickscriptSharedData(quickscript?: any) {
    this._quickscript = quickscript || null;
    if (quickscript?.serviceType) this._quickScriptServiceType = quickscript?.serviceType;
  }

  setQuickScriptServiceType(serivceType: QuickScriptServiceType) {
    this._quickScriptServiceType = serivceType;
    this._quickScriptServiceTypeSubject.next(serivceType);
  }

  getMedicationCategoryIds(medicationDetailsData?: QuickscriptSharedDataMedication): string[] {
    let categoryIds: string[] = [];

    try {
      if (medicationDetailsData?.qsListOfOrderedItems?.length) {
        medicationDetailsData.qsListOfOrderedItems.forEach((orderItem: QuickScriptOrderItem) => {
          categoryIds.push(orderItem.medCategoryId);
        });
      } else if (this.medicationService.qsMedications?.size) {
        for (let index = 0; index < this.medicationService.qsMedications.size; index++) {
          const aMed: AssociatedMedications = this.medicationService.getQSMedicationAtIndex(index);
          if (aMed && aMed.medicationCategories) {
            for (const categoryId in aMed.medicationCategories) {
              categoryIds.push(categoryId);
            }
          }
        }
      }
    } catch (err: any) {}

    return categoryIds;
  }

  retrieveCurrentlyTakingMedicationFromStorage(medicationId: string): boolean {
    try {
      const value: string = sessionStorage.getItem(
        Constants.LocalStorage_Key.currentlyTakingMedication.concat('.', medicationId)
      );
      if (value) {
        return JSON.parse(value) === true;
      }
    } catch (err: any) {
      this.aiContext.debugLog('RetrieveCurrentlyTakingMedicationFromStorage', {
        message: 'Could not retrieve CurrentlyTakingMedication value from session storage',
        medicationId
      });
    }

    return false;
  }

  saveCurrentlyTakingMedicationToStorage(medicationId: string, isCurrentlyTakingMedication: boolean = true): boolean {
    try {
      sessionStorage.setItem(
        Constants.LocalStorage_Key.currentlyTakingMedication.concat('.', medicationId),
        isCurrentlyTakingMedication ? 'true' : 'false'
      );
    } catch (err: any) {
      this.aiContext.debugLog('SaveCurrentlyTakingMedicationToStorage', {
        message: 'Could not save CurrentlyTakingMedication value to session storage',
        medicationId
      });
      return false;
    }
    return true;
  }

  getPrescriptionStatusText(prescription: Prescription): string {
    if (prescription?.prescriptionStatus) {
      if (prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.CANCELLED) {
        return 'Order cancelled';
      } else if (prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.REJECTED) {
        return 'Order not approved';
      } else if (prescription.isExpired) {
        return 'Order has expired';
      } else if (
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.ORDERED ||
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.MODIFIED ||
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.AWAITINGREVIEW
      ) {
        return 'Awaiting phone call';
        // } else if (!prescription.isElectronicPrescription) {
        //   return 'Pending prescription upload';
      } else if (prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.SHIPPED) {
        return 'Medication shipped';
      } else if (
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.ACCEPTED ||
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.PHARMACY ||
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.FINISHED ||
        prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.CLOSED
      ) {
        return 'Order processed';
      } else {
        return 'Pending approval';
      }
    }

    return null;
  }

  getPrescriptionStatusStyle(prescription: Prescription): string {
    if (
      prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.CANCELLED ||
      prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.REJECTED ||
      prescription.isExpired
    ) {
      return 'warning';
    }

    return 'text-accent';
  }

  getPrescriptionPrice(prescription: Prescription, currencyPipe: any): string {
    if (!prescription || !currencyPipe) {
      return null;
    }

    let output: string = 'Fee pending';

    if (prescription.isExpired) {
      output = 'Expired';
    } else if (
      prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.CANCELLED ||
      prescription.prescriptionStatus === Constants.PRESCRIPTION_STATUS.REJECTED
    ) {
      output = 'Refunded';
    } else if (prescription.isElectronicPrescription && !prescription.isQuickScript) {
      output = (prescription.serviceType === Constants.SERVICE_TYPE.SMOKING_CESSATION)
        ? this.functions.getCurrencyString(prescription.price, currencyPipe)
        : this.functions.getCurrencyString(0, currencyPipe); // Display $0 for e-Scripts
    } else if (prescription.isQuickScript) {
      output = this.functions.getCurrencyString(prescription.quickScriptPrice, currencyPipe);
    } else if (typeof prescription.price === 'number') {
      output = this.functions.getCurrencyString(prescription.price, currencyPipe);
    }

    return output;
  }

  getPrescriptionPriceTitle(prescription: Prescription, currencyPipe: any): string {
    const prescriptionPrice: string = this.getPrescriptionPrice(prescription, currencyPipe);

    if (prescriptionPrice?.startsWith('$')) {
      return 'Fee: ' + prescriptionPrice;
    }

    return prescriptionPrice;
  }

  resetQuickScriptService(): void {
    this._quickscript = null;
    this.resetQuickScriptServiceType();
    this.stepService.removeQuickScriptFromStorage();
    this.stepService.resetService();
    this.medicationService.resetService();
    this.setStepListToQuickScript();
  }

  resetQuickScriptServiceType(): void {
    this._quickScriptServiceType = null;
  }

  setStepListToQuickScript(): void {
    this.stepService.setList(STEP_PATH.QUICK_SCRIPT);
  }

  getRedirectRoute(ids: string[]): string[] {
    if (!ids?.length) {
      return null;
    }

    return [STEP_PATH.QUICK_SCRIPT, 'medication', ids[0]];
  }

  /**
   * @function startNewQuickScriptFlow
   * @description Start a QuickScript flow with the specified medication id (take patient to the first quickscript step)
   *
   * @param {string} medicationId
   *
   * @returns {boolean} if operation was successful and the patient was redirected
   */
  startNewQuickScriptFlow(medicationId: string): boolean {
    if (medicationId?.length) {
      this.saveCurrentlyTakingMedicationToStorage(medicationId);

      this.resetQuickScriptService();

      this.aiContext.debug('StartNewQuickScriptFlow', { medicationId });

      this.router.navigate([STEP_PATH.QUICK_SCRIPT, 'medication', medicationId]);

      return true;
    } else {
      //this.functions.showToast('Could not find medications to create an order.');
      return false;
    }
  }

  /**
   * @function startNewQuickScriptFlowFromMedications
   * @description Start a QuickScript flow with the specified medications (take patient to the first quickscript step)
   *
   * @param {Medication[]} medications
   *
   * @returns {boolean} if operation was successful and the patient was redirected
   */
  startNewQuickScriptFlowFromMedications(medications: Medication[]): boolean {
    const medicationIds: string[] = medications?.map((med: Medication) => med.medicationId);
    const uniqueIds: string[] = medicationIds?.filter(
      (elem: string, index: number, arr: string[]) => index === arr.indexOf(elem)
    );

    if (uniqueIds?.length) {
      return this.startNewQuickScriptFlow(uniqueIds[0]);
    }

    return false;
  }

  /**
   * @function getDoDPostQuickScriptQuestions
   * @description Get static DoD NPS Questionnaire
   */
  getDoDPostQuickScriptQuestions(): Promise<Questionnaire> {
    return Promise.resolve(DoDPostQuickScriptQuestionnaire);
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/medicationCategory/{medicationCategoryId}/questionnaire
  getMedicationQuestionnaire(medicationCategoryId: string): Promise<MedicationQuestionnaire> {
    const promiseStorageKey: string = 'QuickScriptMedicationQuestionnaire';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      medicationCategoryId,
      Constants.API_Polling_Times.quickScriptMedicationQuestionnaire_SecondsBetweenRequests
    );

    // if (this._questionnaire && this._questionnaire[medicationCategoryId]) {
    //   return Promise.resolve(this._questionnaire[medicationCategoryId]);
    // } else {
    if (!returnExistingPromise) {
      const newPromise: Promise<MedicationQuestionnaire | null> = this.http
        .get(`${this.url}/quickscript/medicationCategory/${medicationCategoryId}/questionnaire`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.success && response.response) {
            const questionnaire = response.response as MedicationQuestionnaire;
            if (!this._questionnaire) {
              this._questionnaire = {};
            }
            this._questionnaire[medicationCategoryId] = questionnaire;
            this.aiContext.reportSuccessStatus('GetMedicationQuestionnaire', true, 'Questionnaire retrieved', {
              medicationCategoryId
            });
            return questionnaire;
          }
          return null;
        })
        .catch((err: any) => {
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          this.aiContext.reportSuccessStatus(
            'GetMedicationQuestionnaire',
            false,
            'Failed to retrieve medication Questionnaire',
            {
              medicationCategoryId,
              error: this.functions.getErrorMessage(err)
            }
          );
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<MedicationQuestionnaire>(
        promiseStorageKey,
        newPromise,
        medicationCategoryId
      );
    }

    return this.promiseHelperService.getPromiseByKey<MedicationQuestionnaire | null>(promiseStorageKey);
    // }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/quickscriptPricing
  getQuickscriptPricing(): Promise<QuickscriptPricingOverview | null> {
    const promiseStorageKey: string = 'QuickScriptServicePricing';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      null,
      Constants.API_Polling_Times.quickScriptServicePricing_SecondsBetweenRequests
    );

    // if (this._quickScriptPricing) {
    //   return Promise.resolve(this._quickScriptPricing);
    // } else {
    if (!returnExistingPromise) {
      const newPromise: Promise<QuickscriptPricingOverview | null> = this.http
        .get(`${this.url}/quickscript/quickscriptPricing`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.success && response.response) {
            const quickScriptPricing = response.response as QuickscriptPricingOverview;
            this._quickScriptPricing = quickScriptPricing;
            this.aiContext.reportSuccessStatus(
              'GetQuickscriptPricing',
              true,
              'Retrieved pricing for QuickScript services',
              {
                quickScriptPricing
              }
            );
            return quickScriptPricing;
          }
          return null;
        })
        .catch((err: any) => {
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          this.aiContext.reportSuccessStatus(
            'GetQuickscriptPricing',
            false,
            'Failed to retrieve pricing for QuickScript services',
            {
              error: this.functions.getErrorMessage(err)
            }
          );
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<QuickscriptPricingOverview>(promiseStorageKey, newPromise, null);
    }

    return this.promiseHelperService.getPromiseByKey<QuickscriptPricingOverview | null>(promiseStorageKey);
    // }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/pricingForAQuickScript
  getQuickscriptTotalPricing(quickScriptPricingDTO: QuickscriptPricingDTO): Promise<IResponseAPI> {
    const { patientId, serviceType, listOfOrderItemsStringifiedJSON, policyId } = quickScriptPricingDTO;

    const promiseStorageKey: string = 'QuickScriptOrderTotalPricing';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<QuickscriptPricingDTO>(
      promiseStorageKey,
      quickScriptPricingDTO,
      Constants.API_Polling_Times.quickScriptOrderTotalPricing_SecondsBetweenRequests
    );

    let params = new HttpParams()
      .set('patientId', patientId)
      .set('serviceType', serviceType)
      .set('listOfOrderItemsStringifiedJSON', listOfOrderItemsStringifiedJSON);

    if (policyId) {
      params = params.append('policyId', policyId);
    }

    if (!returnExistingPromise) {
      const newPromise: Promise<IResponseAPI | null> = this.http
        .get(`${this.url}/quickscript/pricingForAQuickScript`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
            this.aiContext.reportSuccessStatus(
              'GetQuickscriptTotalPricing',
              true,
              'Successfully retrieved pricing for QuickScript order',
              {
                quickScriptPricingDTO
              }
            );
            return response;
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          return null;
        })
        .catch((err: any) => {
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          this.aiContext.reportSuccessStatus(
            'GetQuickscriptTotalPricing',
            false,
            'Failed to retrieve pricing for QuickScript order',
            {
              quickScriptPricingDTO,
              error: this.functions.getErrorMessage(err)
            }
          );
          throw err;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<IResponseAPI>(promiseStorageKey, newPromise, quickScriptPricingDTO);
    }

    return this.promiseHelperService.getPromiseByKey<IResponseAPI | null>(promiseStorageKey);
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/{quickScriptServiceType}
  getQsTerms(
    quickScriptServiceType: QuickScriptServiceType | ReceivePrescriptionType,
    stringifiedJSONArrayOfMedCategoryIds: string
  ): Promise<Term[] | null> {
    const promiseVariables: QuickscriptQuestionnaireDTO = {
      quickScriptServiceType: quickScriptServiceType.toLowerCase(),
      stringifiedJSONArrayOfMedCategoryIds
    };

    const promiseStorageKey: string = 'QuickScriptConsentTerms';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<QuickscriptQuestionnaireDTO>(
      promiseStorageKey,
      promiseVariables,
      Constants.API_Polling_Times.quickScriptConsentTerms_SecondsBetweenRequests
    );

    const params = new HttpParams().append(
      'stringifiedJSONArrayOfMedCategoryIds',
      stringifiedJSONArrayOfMedCategoryIds
    );

    // if (this._quickScriptPricing) {
    //   return Promise.resolve(this._quickScriptPricing);
    // } else {
    if (!returnExistingPromise) {
      const newPromise: Promise<Term[] | null> = this.http
        .get(`${this.url}/terms/quickscript/${quickScriptServiceType}`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.success && response.response?.terms) {
            const terms = response.response.terms as Term[];
            this.aiContext.reportSuccessStatus('GetQsTerms', true, 'Retrieved consent terms for QuickScript order', {
              quickScriptServiceType,
              medCategoryIds: JSON.parse(stringifiedJSONArrayOfMedCategoryIds ?? null)
            });
            return terms;
          }
          return null;
        })
        .catch((err: any) => {
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          this.aiContext.reportSuccessStatus(
            'GetQsTerms',
            false,
            'Failed to retrieve consent terms for QuickScript order',
            {
              quickScriptServiceType,
              medCategoryIds: JSON.parse(stringifiedJSONArrayOfMedCategoryIds ?? null),
              error: this.functions.getErrorMessage(err)
            }
          );
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<Term[]>(promiseStorageKey, newPromise, promiseVariables);
    }

    return this.promiseHelperService.getPromiseByKey<Term[] | null>(promiseStorageKey);
    // }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/acceptedTerms/quickscript
  acceptQsTerms(createAcceptedTermsRequestDTO: AcceptedTermsRequestDTO): Promise<IResponseAPI> {
    return this.http
      .post(`${this.url}/acceptedTerms/quickscript`, createAcceptedTermsRequestDTO)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          this.aiContext.reportSuccessStatus(
            'AcceptQsTerms',
            true,
            'Successfully posted consent terms for QuickScript order',
            {
              createAcceptedTermsRequestDTO
            }
          );
        }
        return response;
      })
      .catch((err: any) => {
        const errorMessage: string = this.functions.getErrorMessage(err);
        this.functions.handleError(err);
        this.aiContext.reportSuccessStatus(
          'AcceptQsTerms',
          false,
          'Failed to post consent terms for QuickScript order',
          {
            createAcceptedTermsRequestDTO,
            error: errorMessage
          }
        );
        return { error: err, errorData: errorMessage, success: false, response: null } as IResponseAPI;
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/{orderId}/scriptResend
  scriptResend(orderId: string, prescription?: Prescription): Promise<IResponseAPI> {
    if (orderId) {
      return this.http
        .post(`${this.urlQuickScript}/${orderId}/scriptResend`, {})
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.analytics.resendPrescriptionToPharmacy(prescription);
          }
          return response;
        })
        .catch((err: any) => {
          this.functions.handleError(err);
          return null;
        });
    } else {
      return Promise.reject('OrderId not provided!');
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript
  postQuickscript(payload: QuickScript, pricingTypes?: string): Promise<QuickScriptOrderResponse | null> {
    const promiseStorageKey: string = 'QuickScriptPurchaseOrder';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<QuickScript>(
      promiseStorageKey,
      payload,
      Constants.API_Polling_Times.quickScriptPurchaseOrder_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      const newPromise: Promise<QuickScriptOrderResponse | null> = this.http
        .post(this.urlQuickScript, payload)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            const quickScriptOrder = response.response as QuickScriptOrderResponse;
            if (quickScriptOrder.didSucceed) {
              this.analytics.prescriptionComplete(quickScriptOrder.orderId, payload, pricingTypes, null, null);
              this.analytics.trackQuickScript(payload.serviceType, payload.servicePrice);
              this.aiContext.reportSuccessStatus('PostQuickscript', true, 'Successfully created QuickScript order', {
                payload,
                pricingTypes
              });
            }
            return quickScriptOrder;
          }
          return null;
        })
        .catch((err: any) => {
          this.functions.handleError(err);
          this.aiContext.reportSuccessStatus('PostQuickscript', false, 'Failed to create QuickScript order', {
            payload,
            pricingTypes,
            error: this.functions.getErrorMessage(err)
          });
          throw err;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<QuickScriptOrderResponse>(promiseStorageKey, newPromise, payload);
    }

    return this.promiseHelperService.getPromiseByKey<QuickScriptOrderResponse | null>(promiseStorageKey);
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/rate
  postRating(createOrderRatingRequestDTO: QuickscriptRatingDTO): Promise<IResponseAPI> {
    if (!createOrderRatingRequestDTO?.orderId) {
      return null;
    }

    return this.http
      .post(`${this.urlQuickScript}/rate`, createOrderRatingRequestDTO)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          this.analytics.rateExperience('quickscript', String(createOrderRatingRequestDTO.rating));
        }
        return response;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        // return null;
        throw err;
      });
  }

  // Retrieve post-quickscript questionnaire (static resource)
  getPostQuickScriptQuestions(orderId?: string, agencyCode?: string): Promise<Questionnaire | null> {
    // Currently only supports default agency (DoD)
    if (orderId && (!agencyCode || agencyCode === 'dod')) {
      return this.getDoDPostQuickScriptQuestions();
    }

    return Promise.resolve(null);
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/quickscript/{orderId}/postQuickScriptQuestions
  savePostQuickScriptQuestionnaire(questionnaireDTO: PostQuickScriptQuestionnaireDTO): Promise<boolean> {
    return this.http
      .post(`${this.urlQuickScript}/${questionnaireDTO.orderId}/postQuickScriptQuestions`, questionnaireDTO)
      .toPromise()
      .then((response: IResponseAPI) => {
        return response?.success ?? false;
      })
      .catch((err: any) => {
        console.log(
          'Failed to post custom questionnaire object',
          questionnaireDTO,
          'Error:',
          this.functions.getErrorMessage(err)
        );
        this.functions.handleError(err);
        return null;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get quickscriptSharedData(): any {
    return this._quickscript;
  }

  get quickScriptServiceType(): string {
    return this._quickScriptServiceType;
  }
}
