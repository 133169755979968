import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { TestDeviceType } from '@app/shared/models/test-device';
import DecibelMeter from 'decibel-meter';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js';
import WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'test-microphone',
  templateUrl: './test-microphone.component.html',
  styleUrls: ['./test-microphone.component.scss'],
})
export class TestMicrophoneComponent implements OnInit {
  decibals = 0;
  dialogData: any;
  meter = new DecibelMeter('unique-id');
  microphone: any;
  isStarted: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TestMicrophoneComponent>,
    private analytics: GoogleAnalyticsService
  ) {
    this.dialogData = data;
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    this.analytics.testDevice(TestDeviceType.mic);

    let wavesurfer: any = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'green',
      progressColor: 'purple',
      plugins: [MicrophonePlugin.create()],
    });

    wavesurfer.microphone.on('deviceReady', (stream: any) => {
      console.info('Device ready!', stream);
    });
    wavesurfer.microphone.on('deviceError', (code: string) => {
      console.warn('Device error: ' + code);
    });
    this.microphone = wavesurfer.microphone;
  }

  onClose(): void {
    try {
      this.microphone.stop();
      this.microphone.destroy();
    } catch (err: any) {
      console.log('Unable to stop microphone plugin. Error = ', err?.message);
    }

    try {
      this.dialogRef.close();
    } catch (err: any) {
      console.log('Failed to close Test Microphone modal. Error = ', err?.message);
    }
  }

  start(): void {
    this.isStarted = true;
    this.microphone.start();
  }
}
