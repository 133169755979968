import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuickScriptSharedDataPharmacy } from '@app/shared/models/quickscriptSharedData';
import { Address } from '@app/shared/models/address';
import { StepService } from '@app/shared/services/step.service';
import { STEP_CONFIGURATION } from '@app/shared/step-configuration';
import { Functions } from '@app/shared/functions';
import { Constants } from '@app/shared/constants';
import { noWhitespaceValidator } from '@app/shared/validators/no-whitespace-validator';

@Component({
  selector: 'address-input',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnChanges {
  @Input() inputAddress?: string | Address;
  @Input() isPharmacyAddress?: boolean = false;
  @Input() isChangePharmacy?: boolean = false;

  @Output() addressUpdate: EventEmitter<any> = new EventEmitter();

  autoCompleteForm = true;
  address = new Address();
  addressForm: FormGroup;
  isError: boolean;

  constructor(
    private stepService: StepService,
    private functions: Functions,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.isChangePharmacy) {
      let stepData: QuickScriptSharedDataPharmacy = this.stepService.getServiceData(
        STEP_CONFIGURATION.QUICK_SCRIPT.HEALTHCARE_CARD_DETAILS.path
      );
      if (stepData?.pharmacyData?.spAddress) {
        this.originalAddress.patchValue(stepData.pharmacyData.spAddress.originalAddress, { emitEvent: false });
      }
    }

    if (this.inputAddress) {
      if (typeof this.inputAddress === 'string') {
        this.addressForm.setValue({ originalAddress: this.inputAddress }, { emitEvent: false });
      } else if (typeof this.inputAddress === 'object') {
        this.address = this.inputAddress;
        this.setData({ address: this.address, isError: false });
      }
    }

    this.formValueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'inputAddress':
            if (changes.inputAddress.currentValue) {
              if (typeof changes.inputAddress.currentValue === 'string') {
                if (changes.inputAddress.previousValue !== changes.inputAddress.currentValue) {
                  this.address = new Address();
                  this.address.originalAddress = changes.inputAddress.currentValue;
                  this.addressForm.patchValue(
                    { originalAddress: this.address.originalAddress },
                    { onlySelf: true, emitEvent: false }
                  );
                }
              } else if (typeof changes.inputAddress.currentValue === 'object') {
                if (
                  !(changes.inputAddress.previousValue &&
                  this.functions.deepCompare(changes.inputAddress.previousValue, changes.inputAddress.currentValue))
                ) {
                  this.address = changes.inputAddress.currentValue;
                  this.addressForm.patchValue(
                    this.address,
                    { onlySelf: true, emitEvent: false }
                  );
                }
              }
            }
            break;
        }
      }
    }
  }

  createForm(): void {
    this.addressForm = new FormGroup({
      addressId: new FormControl(null),
      originalAddress: new FormControl(null, this.isPharmacyAddress ? [Validators.required] : null),
      // unitNumber: new FormControl(null),
      streetNumber: new FormControl(null, this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]),
      route: new FormControl(null, this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]),
      locality: new FormControl(null, this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]),
      administrativeAreaLevel1: new FormControl(null, this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]),
      administrativeAreaLevel2: new FormControl(null, this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]),
      country: new FormControl('Australia', this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.addressValidCharsRegexString), noWhitespaceValidator]),
      postalCode: new FormControl(null, this.isPharmacyAddress
        ? [Validators.required, Validators.pattern(Constants.postCodeRegexString), noWhitespaceValidator]
        : [Validators.pattern(Constants.postCodeRegexString), noWhitespaceValidator]
      ),
      lat: new FormControl(null),
      lng: new FormControl(null),
    });
  }

  formValueChanges(): void {
    // Whenever the manual address form is updated, remove saved
    // addressId and lat/lng values since they no longer apply
    this.addressForm.valueChanges.subscribe((selectedValue: any) => {
      let address: any = selectedValue;

      if (address) {
        if (typeof address === 'string') {
          address = {
            addressId: null,
            lat: null,
            lng: null,
            originalAddress: selectedValue
          };

          this.updateOriginalAddress(address);
        } else if (typeof address === 'object') {
          // Reset 'exact' coordinates within the address form values
          // this.address.addressId = null;
          // this.address.lat = null;
          // this.address.lng = null;

          this.addressId.setValue(null, { emitEvent: false });
          this.lat.setValue(null, { emitEvent: false });
          this.lng.setValue(null, { emitEvent: false });
          this.originalAddress.setValue(null, { emitEvent: false });

          this.updateOriginalAddress(this.addressForm.value);
        }
      }
    });
  }

  /**
   * @function updateOriginalAddress
   * @description Reconstruct originalAddress string from selected address object and wire updates up to parent
   *
   * @param {any} [selectedValue={}] address object from selected (autocompleted) address
   */
  updateOriginalAddress(selectedValue: any = {}): void {
    try {
      if (
        selectedValue?.route ||
        selectedValue?.postalCode ||
        selectedValue?.locality ||
        selectedValue?.administrativeAreaLevel1
      ) {
        let data: any = { ...selectedValue };
        // let unitNo: string = data.unitNumber || '';

        // // If unit number does not start with a word, prepend 'Unit' to it.
        // if (unitNo) {
        //   if (/^([1-9][0-9]*)/.test(unitNo.trim())) {
        //     // unitNo = 'Unit '.concat(unitNo, '  '); // Australia Post likes 2 spaces between unit and street number
        //     unitNo += '/'; // Simplified form. eg. 3/25 Street Name
        //   } else {
        //     unitNo += '  ';
        //   }
        // }

        // let originalAddress: string = unitNo || '';
        let originalAddress: string = '';
        originalAddress += data.streetNumber ? data.streetNumber + ' ' : '';
        originalAddress += data.route ? data.route + ', ' : '';
        originalAddress += data.locality ? data.locality + ' ' : '';
        originalAddress += data.administrativeAreaLevel1 ? data.administrativeAreaLevel1 + ' ' : '';
        originalAddress += data.postalCode ? data.postalCode + ', ' : '';
        originalAddress += data.country ? data.country : '';

        // Update original address string regardless of whether there is an exact address found (ie. lat/lng values)
        data.originalAddress = originalAddress;

        // Check if this is a fully formed address (check presense of geocoding data)
        if (!data.lat || !data.lng) {
          const { lat, lng, ...newData } = data;
          this.addressUpdate.emit({ address: newData, isError: !this.addressForm.valid });
        } else {
          this.addressUpdate.emit({ address: data, isError: false });
        }
      } else if (selectedValue?.originalAddress) {
        this.addressUpdate.emit({ address: { originalAddress: selectedValue.originalAddress }, isError: false });
      }
    } catch (err: any) {
      console.log('Unable to parse address: ', selectedValue);
    }
  }

  setData(data: any): void {
    if (data) {
      this.isError = data.isError || false;

      if (!this.isError) {
        this.addressForm.setErrors(null);

        if (data.address) {
          const address: Address = data.address;

          // An addressId means we've got a full address available
          if (address.addressId) {
            this.autoCompleteForm = false;
          }

          const updatedAddress: Address = {
            addressId: address.addressId || null,
            originalAddress: address.originalAddress || null,
            // unitNumber: address.unitNumber || null,
            streetNumber: address.streetNumber || null,
            route: address.route || null,
            locality: address.locality || null,
            administrativeAreaLevel1: address.administrativeAreaLevel1 || null,
            administrativeAreaLevel2: address.administrativeAreaLevel2 || null,
            country: address.country || 'Australia',
            postalCode: address.postalCode || null,
            lat: typeof address.lat === 'number' ? address.lat : null,
            lng: typeof address.lng === 'number' ? address.lng : null,
          }

          if (!this.functions.deepCompare(updatedAddress, this.addressForm.value)) {
            this.addressForm.setValue(updatedAddress, { emitEvent: false });
            this.updateOriginalAddress(updatedAddress);
          } else {
            this.addressUpdate.emit({ address: updatedAddress, isError: false });
          }

          // After populating the manual fields, we can re-initialise the autocomplete field with a formatted address
          if (address.addressId) {
            this.autoCompleteForm = true;
          }
        } else {
          // No address data - reset the form
          this.addressForm.setValue({
            addressId: null,
            originalAddress: null,
            // unitNumber: null,
            streetNumber: null,
            route: null,
            locality: null,
            administrativeAreaLevel1: null,
            administrativeAreaLevel2: null,
            country: null,
            postalCode: null,
            lat: null,
            lng: null,
          }, { emitEvent: false });

          this.addressUpdate.emit({ address: null, isError: false });
        }
      } else {
        // console.log('[ADDRESS-COMPONENT] Address error');
        this.addressUpdate.emit({ address: null, isError: true });
      }
    }
  }

  validateNumber(event: any): boolean {
    const isValid: boolean = this.functions.validateNumber(event);

    if (!isValid) {
      event.preventDefault();
    }

    return isValid;
  }

  switchForm(): void {
    this.autoCompleteForm = !this.autoCompleteForm;

    if (!this.autoCompleteForm && this.addressForm.valid) {
      this.isError = false;
      this.addressUpdate.emit({ address: this.addressForm.value, isError: false });
    }
  }

  stateMap(state: string): string {
    switch(state) {
      case 'Australian Capital Territory':
        return 'ACT';
      case 'New South Wales':
        return 'NSW';
      case 'Victoria':
        return 'VIC';
      case 'Queensland':
        return 'QLD';
      case 'Northern Territory':
        return 'NT';
      case 'Western Australia':
        return 'WA';
      case 'Tasmania':
        return 'TAS';
      default:
        return state;
    }
  }

  // Format the address using the Australian Address format
  get joinedAddress(): string {
    let output: string = '';
    let addressObj: any = this.addressForm.value;

    // Address sample:
    // addressId: "d75b1e70-17ec-450e-a3af-49b8d26f1e89"
    // administrativeAreaLevel1: "Victoria"
    // administrativeAreaLevel2: "Melbourne City"
    // country: "Australia"
    // lat: -37.8167035
    // lng: 144.9630496
    // locality: "Melbourne"
    // originalAddress: "2/333 Collins Street, Melbourne Victoria 3000, Australia"
    // postalCode: "3000"
    // route: "Collins Street"
    // streetNumber: "333"
    // unitNumber: "2"

    // If separate address fields are populated, remove the originalAddress string
    if (typeof addressObj['lat'] === 'number') {
      // addressObj['originalAddress'] = null;

      // Remove other redundant data
      // addressObj['administrativeAreaLevel2'] = null; // city
      // addressObj['lat'] = null; // latitude
      // addressObj['lng'] = null; // longitude

      // Try and make the address short and concise (remove city, country) and abbreviate states
      [
        'unitNumber',
        'streetNumber',
        'route', // street name
        'locality', // suburb
        'administrativeAreaLevel1', // state
        'postalCode',
        // 'country' // no need for country
      ].forEach((key) => {
        if (addressObj[key]) {
          // Instead of mapping the state names to their abbreviations, we retrieve the short_name to begin with
          if (key === 'administrativeAreaLevel1') {
            output += this.stateMap(addressObj[key]);
          } else if (key === 'unitNumber') {
            output += (addressObj[key] + '/');
          } else {
            output += addressObj[key];
          }

          // output += addressObj[key];

          // There is a comma after the street name and postcode
          if (['route', /*'postalCode'*/].indexOf(key) !== -1) {
            output += ', ';
          } else if (key !== 'unitNumber') {
            output += ' ';
          }
        }
      });
    } else {
      output = addressObj['originalAddress'];
    }

    return (output || '').trim();
  }

  get addressId() {
    return this.addressForm.get('addressId');
  }
  get originalAddress() {
    return this.addressForm.get('originalAddress'); // final address string
  }
  get locality() {
    return this.addressForm.get('locality'); // suburb
  }
  get administrativeAreaLevel1() {
    return this.addressForm.get('administrativeAreaLevel1'); // state
  }
  get administrativeAreaLevel2() {
    return this.addressForm.get('administrativeAreaLevel2'); // city
  }
  get country() {
    return this.addressForm.get('country'); // country, only 'Australia'
  }
  get postalCode() {
    return this.addressForm.get('postalCode'); // postcode
  }
  get streetNumber() {
    return this.addressForm.get('streetNumber'); // street number
  }
  // get unitNumber() {
  //   return this.addressForm.get('unitNumber'); // unit/apartment/shop number
  // }
  get route() {
    return this.addressForm.get('route'); // street name
  }
  get lat() {
    return this.addressForm.get('lat'); // latitude
  }
  get lng() {
    return this.addressForm.get('lng'); // longitude
  }
}
