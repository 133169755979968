import { Component, EventEmitter, Inject, Injector, Input, Optional, Output } from '@angular/core';
import { BaseVerificationFormComponent } from '@src/app/pages/appointment/components/appointment-patient-verification/base-verification-form';
import { Constants } from '../../constants';
import { PatientVerificationContent } from '../../models/patientVerificationContent';
import { VerifyPatientStepData } from '../../models/verifyEmailStepData';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InitiateVerifyEmailSignupDTO } from '../../models/initiateVerifyEmailSignupDTO';

@Component({
  selector: 'email-verification-form',
  templateUrl: './email-verification-form.component.html',
  styleUrls: ['./email-verification-form.component.scss']
})
export class EmailVerificationFormComponent extends BaseVerificationFormComponent {
  @Input() emailAddress: string;
  @Output() verified: EventEmitter<VerifyPatientStepData> = new EventEmitter<VerifyPatientStepData>();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    injector: Injector
  ) {
    super(injector);

    if (data?.emailAddress) {
      this.emailAddress = data.emailAddress;
    }

    if (data?.signupMode) {
      this.signupMode = data.signupMode;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected async sendCode(): Promise<void> {
    this.isLoading = true;
    this.error = false;

    if (!this.patientId && !this.signupMode) {
      this.functions.showToast('No patient active. Unable to proceed.');
      return;
    }

    this.mfaEnrolmentId = null;
    this.codeSent = false;

    if (!this.signupMode) {
      this.mfaEnrolmentId = await this.authService.initiateVerifyEmail(this.patientId, this.emailAddress);
    } else {
      const response: InitiateVerifyEmailSignupDTO = await this.authService.initiateVerifyEmailSignup(
        this.emailAddress
      );
      this.patientId = response.tempPatientId;
      this.mfaEnrolmentId = response.mfaEnrolmentId;
    }

    if (this.mfaEnrolmentId) {
      this.codeSent = true;
    } else {
      this.error = true;
      this.functions.showToast('An error occurred. Please try again in a few seconds.');
    }
    this.isLoading = false;

    this.invalidInput = false;
  }

  protected async validateCode(): Promise<void> {
    this.isLoading = true;

    if (!this.signupMode) {
      this.saveSuccessful = await this.authService
        .completeVerifyEmail(this.patientId, this.code, this.mfaEnrolmentId, this.emailAddress)
        .catch((err: any) => {
          const errorCode: string = this.functions.getErrorCode(err);
          if (
            errorCode === Constants.API_ERROR_CODES.INVALID_TOKEN ||
            errorCode === Constants.API_ERROR_CODES.TOKEN_EXPIRED
          ) {
            this.functions.navigateToLogin();
          } else {
            this.showFailedToast();
            this.isLoading = false;
          }
          return null;
        });
    } else {
      this.saveSuccessful = await this.authService
        .completeVerifyEmailSignup(this.patientId, this.code, this.mfaEnrolmentId, this.emailAddress)
        .catch((err: any) => {
          this.showFailedToast();
          this.isLoading = false;
          return null;
        });
    }

    if (this.saveSuccessful) {
      if (this.showSuccess) {
        this.functions.showToast('Email address successfully verified.');
      }
      this.notifyVerified();
    }

    this.invalidInput = !this.saveSuccessful;

    this.isLoading = false;
  }

  protected showResendSuccessMessage(): void {
    this.functions.showToast('Code will be resent to ' + this.emailAddress);
  }

  protected showFailedToast(): void {
    this.isLoading = false;
    this.functions.showToast('Failed to verify email address. Please try and resend another code.');
  }

  protected notifyVerified(): void {
    const verifyEmailContent: PatientVerificationContent = new PatientVerificationContent({
      emailCode: this.code,
      emailVerified: true
    });

    const verifyEmailDataToStore: VerifyPatientStepData = {
      formData: {
        ...verifyEmailContent
      },
      patientId: this.patientId
    };

    this.verified.emit(verifyEmailDataToStore);
  }
}
