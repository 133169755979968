import { AfterViewInit, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { Router } from '@angular/router';
import { Constants } from '@app/shared/constants';
import { ONDEMAND_STEP_PATH_MAPPING, SERVICE_STEP_PATH_MAPPING, STEP_PATH } from '@app/shared/step-configuration';
import { StepService } from '@app/shared/services/step.service';
import { AppointmentType } from '@app/shared/models/appointment-type';
import { AvailabilityService } from '@app/shared/services/availability.service';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'practitioner-type',
  templateUrl: './practitioner-type.component.html',
  styleUrls: ['./practitioner-type.component.scss'],
})
export class PractitionerTypeComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  showPractitionerOptions: boolean = false;
  appointmentTypes = AppointmentType;
  appointmentType: AppointmentType;
  isDoctorOnline: boolean = false;
  psychologistIsOnline: boolean = false;
  dietitianIsOnline: boolean = false;
  wellnessIsOnline: boolean = false;
  enableAppointments: boolean = false;
  enableOnDemand: boolean = false;
  appointmentStepType: string = 'APPOINTMENT_TYPE_DOCTOR';
  appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PractitionerTypeComponent>,
    private router: Router,
    private appointmentService: AppointmentService,
    private availabilityService: AvailabilityService,
    private stepService: StepService,
    private whiteLabelService: WhitelabelService
  ) {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    if (this.whiteLabelService.canProvideAppointments()) {
      this.enableAppointments = true;
      this.appointmentServiceType = this.appointmentService.appointment?.serviceType || this.whiteLabelService.getPrimaryServiceType();
      this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
      this.enableOnDemand = this.whiteLabelService.onDemandEnabledForServiceType(this.appointmentServiceType) &&
        ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType];

      if (this.enableOnDemand) {
        this.isPractitionerOnline();
      }
    }

    if (this.data.type == AppointmentType.onDemand) {
      this.appointmentType = AppointmentType.onDemand;
    } else {
      this.appointmentType = AppointmentType.scheduled;
    }

    this.showPractitionerOptions = true;
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.availabilityService.isPractitionerOnlineChangeObs.subscribe(() => {
        this.isPractitionerOnline();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isPractitionerOnline(): void {
    this.isDoctorOnline = this.enableOnDemand && this.availabilityService.isOnline(this.appointmentServiceType);

    // The following practitioner types do not exist yet
    this.psychologistIsOnline = false; // this.availabilityService.isPsychologistOnline;
    this.dietitianIsOnline = false; // this.availabilityService.isDietitianOnline;
    this.wellnessIsOnline = false; // this.availabilityService.isWellnessDoctorOnline;
  }

  resetAppointment(): void {
    this.stepService.resetService();
    this.appointmentService.resetService();
  }

  scheduleAppointment(): void {
    this.resetAppointment();

    switch (this.appointmentServiceType) {
      case Constants.SERVICE_TYPE.DOCTOR:
      case Constants.SERVICE_TYPE.FERTILITY:
      case Constants.SERVICE_TYPE.WEIGHT_LOSS:
        this.startDefaultAppointment();
        break;

      // case Constants.SERVICE_TYPE.PSYCHOLOGY:
      //   this.router.navigate([`/appointment/${STEP_PATH.APPOINTMENT_TYPE_PSYCHOLOGY}`]);
      //   break;
      // case Constants.SERVICE_TYPE.DIETITIAN:
      //   this.router.navigate([`/appointment/${STEP_PATH.APPOINTMENT_TYPE_DIETITIAN}`]);
      //   break;
      // case Constants.SERVICE_TYPE.WELLNESS:
      //   this.router.navigate([`/appointment/${STEP_PATH.APPOINTMENT_TYPE_WELLNESS}`]);
      //   break;

      default:
        break;
    }

    this.onClose();
  }

  speakTo(): void {
    switch (this.appointmentServiceType) {
      case Constants.SERVICE_TYPE.DOCTOR:
      case Constants.SERVICE_TYPE.FERTILITY:
        if (this.availabilityService.isOnline(this.appointmentServiceType)) {
          this.onDemandAppointment();
        } else {
          this.startDefaultAppointment();
        }
        break;

      // The following practitioner types do not exist yet
      // case Constants.SERVICE_TYPE.PSYCHOLOGY:
      //   this.router.navigate([`/appointment/${STEP_PATH.ONDEMAND_TYPE_PSYCHOLOGY}`]);
      //   break;
      // case Constants.SERVICE_TYPE.DIETITIAN:
      //   this.router.navigate([`/appointment/${STEP_PATH.ONDEMAND_TYPE_DIETITIAN}`]);
      //   break;
      // case Constants.SERVICE_TYPE.WELLNESS:
      //   this.router.navigate([`/appointment/${STEP_PATH.ONDEMAND_TYPE_WELLNESS}`]);
      //   break;

      default:
        break;
    }

    this.onClose();
  }

  startDefaultAppointment(): void {
    this.resetAppointment();
    this.stepService.setList(this.appointmentServiceType);

    this.router.navigate(SERVICE_STEP_PATH_MAPPING[this.appointmentServiceType]);
  }

  onDemandAppointment(): void {
    this.resetAppointment();
    this.router.navigate([
      STEP_PATH.APPOINTMENT,
      STEP_PATH[ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType]]
    ]);
  }

  onClose(value?: boolean): void {
    this.dialogRef.close(value);
  }
}
