import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'weight-loss-goal-detail',
  templateUrl: './weight-loss-goal-detail.component.html',
  styleUrls: ['./weight-loss-goal-detail.component.scss']
})
export class WeightLossGoalDetailComponent implements OnInit {
  @Input('cssClass') cssClass: string = null;
  @Input('currentMeasurement') currentMeasurement: string | number;
  @Input('goalMeasurement') goalMeasurement: string | number;
  @Input('goalLabel') goalLabel: string;
  @Input('goalReached') goalReached: boolean = false;
  @Input('measurementLabel') measurementLabel: string;
  @Input('measurementUnits') measurementUnits: string;

  constructor() {}

  ngOnInit() {}
}
