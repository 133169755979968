<div id="health-card-modal-wrapper" class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-12 px-0">
      <h4 *ngIf="isInvalidCardSection" class="text-primary title mb-0">
        <span [ngSwitch]="!hasMedicare &&
          (currentMedication?.qsMedicationHealthCard?.pricingType == pricingType.PBS ||
          currentMedication?.qsMedicationHealthCard?.pricingType == pricingType.SAFETYNET)">
          <span *ngSwitchCase="true">
            Invalid Medicare Card
          </span>
          <span *ngSwitchDefault>
            Invalid Health Card
          </span>
        </span>
      </h4>
      <h4 *ngIf="!isInvalidCardSection" class="text-primary title mb-0">
        Update Health Cards
      </h4>
    </div>
  </div>
  <div class="modal-body container-fluid text-secondary">
    <div *ngIf="isInvalidCardSection" class="row instruction">
      <div
        class="col-12"
        [ngSwitch]="!hasMedicare &&
          (currentMedication?.qsMedicationHealthCard?.pricingType == pricingType.PBS ||
          currentMedication?.qsMedicationHealthCard?.pricingType == pricingType.SAFETYNET)">

        <span *ngSwitchCase="true" class="invalid-msg">
          Your <strong>Medicare Card</strong> isn't valid, you will be charged for this prescription as follows:
        </span>

        <span *ngSwitchDefault class="invalid-msg">
          Your <strong>{{ currentMedication?.qsMedicationHealthCard?.displayName | titlecase }}</strong> isn't
          valid, you will be charged for this prescription as follows:
        </span>

      </div>
    </div>
    <div class="row m-0">
      <div class="col-12">
        <div *ngIf="isFormCardSection" class="row">
          <healthcare-cards #healthcareCards [displayType]="'modal'"></healthcare-cards>
        </div>
        <div *ngIf="isInvalidCardSection" class="shadow-box">
          <div class="row">
            <div class="col-12 mb-3">
              <span class="medication-name">
                {{ currentMedication?.qsMedicationType?.medicationTypeName }}
                {{
                  currentMedication?.qsMedicationType?.medicationShortDesc
                    ? ' - ' + currentMedication.qsMedicationType.medicationShortDesc
                    : ''
                }}
              </span>
              <span class="medication-dosage"> ({{ currentMedication?.qsMedicationDosage?.name }})</span>
            </div>
            <div class="col-12">
              <div class="row gray-box">
                <div class="col-12">
                  <div class="medication row">
                    <div class="medication-img-box col-3">
                      <img
                        *ngIf="currentMedication?.qsMedicationPhoto"
                        [src]="currentMedication.qsMedicationPhoto"
                        class="med-img"
                        alt="medication" />
                    </div>
                    <div class="medication-qty-box col-3">
                      <span class="label">Quantity</span>
                      <span class="value">{{ (currentMedication?.qsMedicationQty || 1) }}</span>
                    </div>
                    <div class="medication-oldprice-box col-3">
                      <span class="label">Old Price</span>
                      <span class="value red">{{ (currentMedication?.qsMedicationQtyTimesPrice || 0) | currency }}</span>
                    </div>
                    <div class="medication-newprice-box col-3">
                      <span class="label">New Price</span>
                      <span class="value green">{{ (privatePricing?.qsMedicationQtyTimesPrice || 0) | currency }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer container-fluid bg-muted">
    <div *ngIf="isInvalidCardSection" class="row action-buttons">
      <div class="col-12 col-xl-6">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="switchToForm()">
          Update health cards
        </button>
      </div>
      <div class="col-12 col-xl-6">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap mobile-separator"
          (click)="proceedWithPrivatePrice()">
          Proceed with new price
        </button>
      </div>
    </div>
    <div *ngIf="isFormCardSection" class="row action-buttons">
      <div class="col-12 col-xl-6">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose()">
          Close
        </button>
      </div>
      <div class="col-12 col-xl-6">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap mobile-separator"
          (click)="updateHealthCards()">
          Save all and continue
        </button>
      </div>
    </div>
  </div>
</div>
