import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Constants } from '../constants';
import { Functions } from '../functions';

@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  constructor(private functions: Functions) {}

  // When the HotJar script has loaded, but not initialised (in the case of HTTP sites), firing hotjar
  // functions causes an HTML response, which is caught by the ExceptionHandler class -
  // "SyntaxError: Unexpected token '<', "<!DOCTYPE "... is not valid JSON"
  get hotjar(): any {
    return window['hj'];
  }
  get hotjarSettings(): any {
    return window['hjSiteSettings'];
  }

  setUserId(userId: string, pathUrl: string): void {
    if (this.hotjar) {
      try {
        window['hj']('identify', userId || null, {
          page: pathUrl
        });
      } catch (err: any) {
        console.log('Failed to update userId for HotJar. Error: ', this.functions.getErrorMessage(err));
      }
    }
  }

  trackRouteChange(url: string): void {
    if (this.hotjar) {
      try {
        window['hj']('stateChange', url);
      } catch (err: any) {
        console.log('Failed to record route change for HotJar. Error: ', this.functions.getErrorMessage(err));
      }
    }
  }

  hotjarRecordingStatus(): string {
    return this.hotjarSettings?.record ? 'recording' : 'not recording';
  }

  getSessionIdFromCookie(): string {
    let sessionId: string = null;
    const cookie: string = document.cookie;
    const settingName: string = '_hjSession_' + String(environment.hotjarId);

    if (typeof cookie === 'string' && cookie.indexOf(settingName) !== -1) {
      const cookieObject: Record<string, string> = this.functions.cookieToRecordObject(cookie);

      if (cookieObject) {
        // Sample base64 strings:
        // eyJpZCI6IjQyZmQ2MWVhLWQ2NjAtNDE2ZS1hMGNjLTBmZTIyMTEyMDFkYiIsImNyZWF0ZWQiOjE2NjEzMTMxMDk4MDcsImluU2FtcGxlIjpmYWxzZX0=
        // eyJpZCI6ImZiMzc1ZTYyLWExNzMtNDA4Ni1iYzFmLWJkMzY1OTI4NTZiZCIsImNyZWF0ZWQiOjE2NjUzOTA4MTUzMjQsImluU2FtcGxlIjp0cnVlfQ==
        const hotJarEncodedSessionId: string = (cookieObject[settingName] || '').trim();

        if (hotJarEncodedSessionId && Constants.base64StringRegex.test(hotJarEncodedSessionId)) {
          try {
            // Sample decoded json:
            // {"id":"42fd61ea-d660-416e-a0cc-0fe2211201db","created":1661313109807,"inSample":false}
            const json: any = JSON.parse(atob(hotJarEncodedSessionId));
            const fullUserId: string = json['id'];
            sessionId = fullUserId.split('-').shift();
          } catch (err: any) {
            console.log('Unable to extract HotJar SessionID. Error: ', this.functions.getErrorMessage(err));
          }
        }
      }
    }

    return sessionId;
  }
}
