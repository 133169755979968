<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">Individual Healthcare Identifier</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-primary">
    <div class="col-12 px-0 m-0">
      <div class="row">
        <div class="col-12 col-xl-6 align-items-start">
          <p class="padded-text">
            An Individual Healthcare Identifier (IHI) is a unique number used to identify an individual for health care
            purposes. Even if you don't have a Medicare card, you may already have an IHI. If you have a
            <a
              [href]="myGovUrl"
              target="_blank"
              rel="noopener noreferrer nofollow"
              (click)="openExternalURL($event)">myGov account</a> for
              proof of vaccination, you will have an IHI. The quickest way to get an IHI is online through your myGov
              account. If you don't have an account, it's easy to
            <a
              [href]="createMyGovAccountUrl"
              target="_blank"
              rel="noopener noreferrer nofollow"
              (click)="openExternalURL($event)">create one</a>.
          </p>
          <p class="padded-text">
            An IHI will enable you to receive eScripts, which is a convenient way to receive any medication which
            is prescribed to you.
          </p>
          <p class="padded-text">
            You can call the Healthcare Identifiers Service on <strong>1300 361 457</strong> Monday to Friday
            8:30 am to 5 pm AEST to check your details.
          </p>
        </div>
        <div class="col-12 col-xl-6 text-center image-container">
          <p>
            <img src="assets/images/ihi-number.png" style="border: 0" alt="Where to find IHI number" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="row m-0 w-100">
      <div class="col-12">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
