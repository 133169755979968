export class PatientVerificationResponseDTO {
  patientVerificationMode: PatientVerificationMode;
  emailVerified?: boolean;
  mobilePhoneVerified?: boolean;

  constructor(init?: Partial<PatientVerificationResponseDTO>) {
    Object.assign(this, init);

    // Convert string to enum if needed
    if (typeof init?.patientVerificationMode === 'string') {
      this.patientVerificationMode =
        PatientVerificationMode[init.patientVerificationMode as keyof typeof PatientVerificationMode];
    }
  }
}

export enum PatientVerificationMode {
  Off = 'Off',
  On = 'On',
  Skip = 'Skip'
}
