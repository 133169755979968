import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { AppointmentDTO } from '../models/appointmentDTO';
import { Benefit, BenefitPractitioner, BenefitProduct, BenefitService, PractitionerDiscount } from '../models/benefit';
import { ProductPrice } from '../models/product-price';
import { QuickScript } from '../models/quickscript';
import { ServicePrice } from '../models/service-price';
import { ProductServiceType } from '@app/shared/models/product-service-type';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  private currencyPipe = new CurrencyPipe('en-AU', 'AUD');

  constructor() {}

  /**
   * @function calculatePrice
   * @description Calculate the total price for the purchased service and products including any discounts
   *
   * @param {ServicePrice} servicePrice the ServicePrice object containing service and product costs
   *
   * @returns {number} total price, not rounded (eg. 10.85454545)
   */
  calculatePrice(servicePrice: ServicePrice): number {
    const service: number = this.calculateServicePrice(servicePrice);
    // (servicePrice?.serviceAmount ?? 0) - this.getServiceDiscount(servicePrice);
    const products: number = this.calculateProductPrices(servicePrice);
    // servicePrice?.productPrices?.reduce<number>((prev: number, current: ProductPrice) => {
    //   return (current.productAmount || 0) - (current.productDiscount || 0) + (prev || 0);
    // }, 0) ?? 0;

    return Math.max(service, 0) + Math.max(products, 0);
  }

  /**
   * @function calculateServicePrice
   * @description Calculate the Service price (inclusive of taxes)
   *
   * @param {ServicePrice} servicePrice
   *
   * @returns {number} Service price plus taxes
   */
  calculateServicePrice(servicePrice: ServicePrice): number {
    const serviceAmount: number = servicePrice?.serviceAmount ?? 0;
    const serviceDiscount: number = servicePrice?.serviceDiscount ?? 0;

    return Math.max(serviceAmount - serviceDiscount, 0);
  }

  /**
   * @function calculateProductPrices
   * @description Calculate the total price of Products (inclusive of taxes)
   *
   * @param {ServicePrice} servicePrice
   *
   * @returns {number} Product prices plus taxes
   */
  calculateProductPrices(servicePrice: ServicePrice): number {
    const products: number =
      servicePrice?.productPrices?.reduce<number>((prev: number, current: ProductPrice) => {
        return (current.productAmount || 0) - (current.productDiscount || 0) + (prev || 0);
      }, 0) ?? 0;

    return Math.max(products, 0);
  }

  /**
   * @function calculateProductPrices
   * @description Gets the undiscounted price of the booking fee
   *
   * @param {ServicePrice} servicePrice
   *
   * @returns {number} The undiscounted booking fee price. Returns 0 if no booking fee product was
   * present in the ServicePrice
   */
  getBookingFeeNonDiscountedPrice(servicePrice: ServicePrice): number {
    if (!servicePrice) {
      return 0;
    }

    return (
      servicePrice?.productPrices?.find(
        (productPrice: ProductPrice) => productPrice.serviceType == ProductServiceType.appointmentBookingFee
      )?.productAmount ?? 0
    );
  }

  /**
   * @function calculateTax
   * @description Calculate the total tax for the purchased service and products
   *
   * @param {ServicePrice} servicePrice the ServicePrice object containing service and product costs
   *
   * @returns {number} total tax, not rounded (eg. 2.6777777)
   */
  calculateTax(servicePrice: ServicePrice): number {
    const serviceTax: number = servicePrice?.serviceGst ?? 0;
    const productTax: number =
      servicePrice?.productPrices?.reduce<number>((prev: number, current: ProductPrice) => {
        return (current.productGst || 0) + (prev || 0);
      }, 0) ?? 0;

    return Math.max(serviceTax, 0) + Math.max(productTax, 0);
  }

  /**
   * @function calculateTotal
   * @description Add up the service and product costs and taxes and round up to the nearest cent
   *
   * @param {ServicePrice} servicePrice the ServicePrice object containing service and product costs
   *
   * @returns {number} total cost, rounded to nearest cent (eg. 10.85)
   */
  calculateTotal(servicePrice: ServicePrice): number {
    return this.roundCurrency(this.calculateServicePrice(servicePrice) + this.calculateProductPrices(servicePrice));
  }

  /**
   * @function getServiceDiscount
   * @description Retrieve the discount value from a ServicePrice object
   *
   * @param {ServicePrice} servicePrice
   *
   * @returns {number} discount value
   */
  getServiceDiscount(servicePrice: ServicePrice): number {
    return servicePrice?.serviceDiscount || 0;
  }

  /**
   * @function getAppointmentDiscountedTotalPrice
   * @description Calculate total discounted price (service price + booking fee) from appointment and benefit data.
   * This function will need to be updated if we add additional serviceProducts
   *
   * @param {AppointmentDTO} appointmentData
   * @param {Benefit} [benefit]
   * @param {number} originalServicePrice
   * @param {number} originalBookingFee
   *
   * @returns {number}
   */
  getAppointmentDiscountedTotalPrice(
    appointmentData: AppointmentDTO,
    benefit: Benefit,
    originalServicePrice: number,
    originalBookingFee: number
  ): number {
    const discountedServicePrice: number = this.getAppointmentDiscountedServicePrice(
      appointmentData,
      benefit,
      originalServicePrice
    );
    const discountedBookingFee: number = this.getAppointmentDiscountedBookingFeePrice(
      appointmentData,
      benefit,
      originalBookingFee
    );

    return discountedServicePrice + discountedBookingFee;
  }

  /**
   * @function calculateAppointmentDiscountedServicePrice
   * @description Given a Session or Appointment object and a Benefit object, calculate the discounted service price
   *
   * @param {any} session a Session or Appointment object
   * @param {Benefit} [benefit] a Benefit object
   *
   * @returns {number} discounted service price
   */
  calculateAppointmentDiscountedServicePrice(session: any, benefit?: Benefit): number {
    let discountedPrice: number = session.originalPrice ?? (session.price || 0);

    let isAfterHours: boolean = false;
    if (typeof session.businessHours === 'boolean') {
      isAfterHours = !session.businessHours;
    } else if (typeof session.isAfterHours === 'boolean') {
      isAfterHours = session.isAfterHours;
    }

    const appointmentDTO: AppointmentDTO = {
      policyId: benefit?.policyId || null,
      serviceType: session.serviceType,
      isAfterHours,
      isOnDemand: false,
      practitionerId: null,
      servicePrice: null
    };

    return this.getAppointmentDiscountedServicePrice(appointmentDTO, benefit, discountedPrice);
  }

  /**
   * @function getAppointmentDiscountedServicePrice
   * @description Gets the discounted *service* price for the given appointment and benefit.
   * Does not take into account products for the appointment service, such as the booking fee.
   * This function should always be used to get the discountedPrice to display to the patient. It is possible for the discountedPrice
   * to be set to a different value without a customer discount percentage being specified. For example, a policy
   * could set the appointment price to $50 BH and $80 AH without specifying a discount percentage.
   *
   * @param {AppointmentDTO} appointmentData
   * @param {Benefit} benefit
   * @param {number} originalPrice
   *
   * @returns {number} the discounted price for the appointment
   */
  getAppointmentDiscountedServicePrice(
    appointmentData: AppointmentDTO,
    benefit: Benefit,
    originalPrice: number
  ): number {
    let discountedPrice: number = originalPrice;

    if (benefit && benefit.policyId === appointmentData?.policyId && appointmentData?.serviceType) {
      const serviceType: string = appointmentData.serviceType;

      if (serviceType && Array.isArray(benefit.services)) {
        const service: BenefitService = benefit.services.find(
          (bService: BenefitService) => bService.serviceType === serviceType
        );

        if (service) {
          discountedPrice = appointmentData.isAfterHours ? service.discountedPriceAH : service.discountedPriceBH;
        }
      }
    }

    return discountedPrice;
  }

  /**
   * @function getAppointmentDiscountedBookingFeePrice
   * @description Gets the discounted *booking fee product* price for the given appointment and benefit.
   *
   * @param {AppointmentDTO} appointmentData
   * @param {Benefit} benefit
   * @param {number} originalPrice
   *
   * @returns {number} the discounted price for the appointment
   */
  getAppointmentDiscountedBookingFeePrice(
    appointmentData: AppointmentDTO,
    benefit: Benefit,
    originalPrice: number
  ): number {
    let discountedPrice: number = originalPrice;

    if (benefit && benefit.policyId === appointmentData?.policyId && appointmentData?.serviceType) {
      if (Array.isArray(benefit.products)) {
        const combinedServiceType: string = `${appointmentData?.serviceType}-${ProductServiceType.appointmentBookingFee}`;
        const bookingFeeProduct: BenefitProduct = benefit.products.find(
          (bProduct: BenefitProduct) => bProduct.serviceType === combinedServiceType
        );

        if (bookingFeeProduct) {
          discountedPrice = appointmentData.isAfterHours
            ? bookingFeeProduct.discountedPriceAH
            : bookingFeeProduct.discountedPriceBH;
        }
      }
    }

    return discountedPrice;
  }

  /**
   * @function getAppointmentBenefitDiscount
   * @description Get the discount percentage from applying a benefit to an appointment
   * (based on before-hours / after-hours discounts by the practitioner or offered service)
   *
   * @param {AppointmentDTO} appointmentData
   * @param {Benefit} benefit
   *
   * @returns {number} 0 to 100 (percent discount)
   */
  getAppointmentBenefitDiscount(appointmentData: AppointmentDTO, benefit: Benefit): number {
    let discount: number = 0;

    if (benefit && benefit.policyId === appointmentData?.policyId && appointmentData?.serviceType) {
      const serviceType: string = appointmentData.serviceType;

      // Check practitioner is valid
      if (appointmentData.practitionerId && Array.isArray(benefit.validPractitioners)) {
        const validPractitioner: BenefitPractitioner = benefit.validPractitioners.find(
          (prac: BenefitPractitioner) => prac.practitionerId === appointmentData.practitionerId
        );

        if (validPractitioner && Array.isArray(validPractitioner.practitionerDiscountsForServices)) {
          const practitionerDiscount: PractitionerDiscount = validPractitioner.practitionerDiscountsForServices.find(
            (prac: PractitionerDiscount) => prac.serviceType === serviceType
          );

          if (practitionerDiscount) {
            discount = appointmentData.isAfterHours
              ? practitionerDiscount.ahDiscountPercentage
              : practitionerDiscount.bhDiscountPercentage;
          }
        }
        // Check services the benefit affects
      } else if (serviceType && Array.isArray(benefit.services)) {
        const service: BenefitService = benefit.services.find(
          (bService: BenefitService) => bService.serviceType === serviceType
        );

        if (service) {
          discount = appointmentData.isAfterHours
            ? service.totalDiscountPercentageAH
            : service.totalDiscountPercentageBH;
        }
      }
    }

    return discount;
  }

  /**
   * @function getQuickScriptBenefitDiscount
   * @description Get the discount percentage from applying a benefit to a quickscript order
   *
   * @param {QuickScript} qsData
   * @param {Benefit} benefit
   *
   * @returns {number} 0 to 100 (percent discount)
   */
  getQuickScriptBenefitDiscount(qsData: QuickScript, benefit: Benefit): number {
    let discount: number = 0;

    if (benefit && benefit.policyId === qsData?.policyId && qsData?.serviceType) {
      const serviceType: string = qsData.serviceType;

      if (serviceType && Array.isArray(benefit.services)) {
        const service: BenefitService = benefit.services.find(
          (bService: BenefitService) => bService.serviceType === serviceType
        );

        if (service) {
          const currentHour: number = new Date().getHours();
          const isBusinessHours: boolean =
            currentHour >= Constants.SERVICE_TIME_CONFIG.BUSINESS_HOUR &&
            currentHour < Constants.SERVICE_TIME_CONFIG.AFTER_HOURS;

          discount = isBusinessHours ? service.totalDiscountPercentageBH : service.totalDiscountPercentageAH;
        }
      }
    }

    return discount;
  }

  /**
   * @function roundCurrency
   * @description Round a currency value to the nearest cent. eg. 10.86868686 => 10.87
   *
   * @param {number} amount currency value
   *
   * @returns {number} currency value rounded to nearest cent
   */
  roundCurrency(amount: number): number {
    if (amount) {
      return Math.round(amount * 100) / 100;
    }
    return 0;
  }

  /**
   * @function formatPrice
   * @description Format a numeric or string price to Australian currency format. eg. 8.5 => '$8.50'
   *
   * @param {number|string} price
   *
   * @returns {string} Format currency, eg. $8.50
   */
  formatPrice(price: number | string): string {
    let formattedCurrency: string =
      typeof price === 'number' || price ? this.currencyPipe.transform(price, 'AUD', 'symbol-narrow') : null;

    return formattedCurrency;
  }
}
