// Modal Dialog custom width configuration
export enum DefaultModalWidths {
  mobile = '100vw',
  tablet = '50vw',
  desktop = '40vw'
}

// Modal Dialog custom width configuration
export interface ModalWidthSpec {
  mobile: string
  tablet?: string  // if not specified, default to 'mobile'
  desktop: string
}
