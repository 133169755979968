import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Functions } from '@app/shared/functions';
import { Constants, Labels } from '@app/shared/constants';
import { STEP_PATH } from '@app/shared/step-configuration';
import { ErrorMessageModalComponent } from '../error-message-modal/error-message-modal.component';
import { IResponseAPI } from '@app/shared/models/api-response';
import { Appointment } from '@app/shared/models/appointment';
import { CancelAppointmentDTO } from '@app/shared/models/cancelAppointmentDTO';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { ActiveCampaignService } from '@app/shared/services/active-campaign.service';
import { TimezoneService } from '../../services/timezone.service';
import { PatientService } from '@app/shared/services/patient.service';
import moment from 'moment';

@Component({
  selector: 'cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss'],
})
export class CancelAppointmentComponent implements OnInit {
  appointmentToBeCancelled: Appointment;
  appointmentCancelFormGroup: FormGroup;
  isLoading: boolean = false;
  isConfirmation: boolean = false;
  isCancelled: boolean = false;
  isMobile: boolean;
  timeZoneOffset: string;
  appointmentDayDate: string;
  appointmentDayMonth: string;
  appointmentDayWeekday: string;
  appointmentDayTime: string;
  appointmentTimeZoneLabel: string;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelAppointmentComponent>,
    private dialog: MatDialog,
    private router: Router,
    private functions: Functions,
    private appointmentService: AppointmentService,
    private activeCampaignService: ActiveCampaignService,
    private timezoneService: TimezoneService,
    private patientService: PatientService
  ) {
    this.onResize();

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.appointmentCancelFormGroup = new FormGroup({
      cancelReason: new FormControl('', []),
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  async ngOnInit(): Promise<void> {
    if (this.data?.appointmentId) {
      await this.getAppointmentDetail(this.data.appointmentId);
    } else {
      this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
        this.functions.showToast('Appointment details unavailable!');
      });
      this.onClose(true);
    }
  }

  onClose(value?: boolean): void {
    if (this.isCancelled) {
      this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
        this.functions.showToast('Appointment has been cancelled!');
      });
    }

    this.dialogRef.close(value);
  }

  async confirmCancel(): Promise<void> {
    if (!this.appointmentToBeCancelled) {
      return;
    }

    this.isLoading = true;

    try {
      const cancelAppointmentDTO: CancelAppointmentDTO = {
        cancelReason: this.cancelReason.value
      };

      const response: IResponseAPI = await this.appointmentService
        .cancelAppointment(
          this.appointmentToBeCancelled.appointmentId,
          this.appointmentToBeCancelled.patientId,
          cancelAppointmentDTO
        )
        .catch((err: any) => {
          console.warn('Failed to cancel appointment ', this.appointmentToBeCancelled.appointmentId, '. Error: ',
            this.functions.getErrorMessage(err));
          return null;
        })
        .finally(() => {
          this.isLoading = false;
        });

      // this.isLoading = false;

      if (response?.success && response.response) {
        // appointment cancelled - show final message to user
        this.isConfirmation = true;
        this.isCancelled = true;

        const appointmentDateTimeLocal: string = this.functions.getUTCMomentString(
          this.getTimeZoneOffset(),
          false,
          false,
          this.appointmentToBeCancelled?.startTimeUTC || this.appointmentToBeCancelled?.start || undefined
        );

        this.activeCampaignService.createAndSendEvent(Constants.ACTIVE_CAMPAIGN_EVENTS.CancelApppointment, {
          appointmentDateTimeLocal,
          appointmentDateTimeUTC: appointmentDateTimeLocal,
          cancellationReason: cancelAppointmentDTO?.cancelReason || null,
          doctorName: this.appointmentToBeCancelled?.practitionerName || null
        }, this.appointmentToBeCancelled);
      } else {
        // cancellation failed - close modal and show error message in a new modal popup
        this.isConfirmation = false;
        this.isCancelled = false;
        this.onClose(this.isCancelled);

        let errorMessage: string = this.functions.getErrorMessage(response);
        if (errorMessage == Labels.serverError) {
          errorMessage = 'Unable to cancel appointment. It may already be canceled, has expired, or the ' +
            'appointment was booked under a different account.';
        }
        this.showErrorMessage(errorMessage);
      }
    } catch (err: any) {
      // cancellation failed - close modal and show error message in a new modal popup
      this.isCancelled = false;
      this.isLoading = false;
      this.onClose(false);
      this.showErrorMessage(this.functions.getErrorMessage(err));
    }
  }

  async getAppointmentDetail(appointmentId: string): Promise<void> {
    this.isLoading = true;

    await this.appointmentService
      .getAppointmentDetail(appointmentId)
      .then((appointment: Appointment) => {
        if (appointment) {
          if (appointment.canBeCancelled) {
            this.appointmentToBeCancelled = appointment;
            this.timeZoneOffset = this.getTimeZoneOffset();

            const numericTimezoneOffset: number = this.timeZoneOffset
              ? parseFloat(this.timeZoneOffset)
              : Constants.Default_TimeZone_Offset;

            const appointmentToBeCancelledStartTime: string = this.appointmentToBeCancelled?.startTimeUTC
              || this.appointmentToBeCancelled?.start;

            const displayDate: moment.Moment = this.functions.getUTCMoment(
              this.timeZoneOffset,
              false,
              false,
              appointmentToBeCancelledStartTime
            );

            this.updateTimeZoneLabel();

            this.appointmentDayDate = displayDate.clone().utcOffset(numericTimezoneOffset).format('DD');
            this.appointmentDayMonth = displayDate.clone().utcOffset(numericTimezoneOffset).format('MMM').toUpperCase();
            this.appointmentDayWeekday = displayDate.clone().utcOffset(numericTimezoneOffset).format('dddd');
            this.appointmentDayTime = this.timezoneService.getFormattedSessionDisplayTime(appointmentToBeCancelledStartTime, this.timeZoneOffset)
          } else {
            this.appointmentToBeCancelled = null;
            this.onClose(true);
            this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
              this.functions.showToast('This appointment cannot be cancelled!');
            });
          }
        } else {
          this.appointmentToBeCancelled = null;
          this.onClose(true);
          this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
            this.functions.showToast('Appointment details unavailable!');
          });
        }
      })
      .catch((_err: any) => {
        this.appointmentToBeCancelled = null;
        this.onClose(true);
        this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
          this.functions.showToast('Could not retrieve appointment details!');
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  updateTimeZoneLabel(): void {
    this.appointmentTimeZoneLabel = this.functions.removeCountryFromTimeZone(this.getTimeZoneOffsetLabel());
  }

  getTimeZoneOffset(): string {
    return this.appointmentToBeCancelled?.patientTimezoneOffsetHours || this.patientService.getPatientTimeZoneOffset();
  }

  getTimeZoneOffsetLabel(): string {
    if (this.appointmentToBeCancelled?.patientTimezoneLabel) {
      return this.appointmentToBeCancelled.patientTimezoneLabel;
    } else if (this.appointmentToBeCancelled.patientTimezoneOffsetId) {
      return this.timezoneService.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
        this.appointmentToBeCancelled.patientTimezoneOffsetId,
        this.getTimeZoneOffset()
      );
    }

    return this.patientService.getPatientTimeZoneLabel();
  }

  getPractitionerType(): string {
    return this.appointmentToBeCancelled?.serviceType
      ? this.functions.getAppointmentType(this.appointmentToBeCancelled.serviceType)
      : Constants.SERVICE_TYPE.DOCTOR;
  }

  showErrorMessage(message: string): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Cancel Appointment Problem',
      message,
      isSpeakToDoctor: false,
      isClose: true,
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(ErrorMessageModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.functions.triggerResizeEvent();
      });
  }

  get cancelReason() {
    return this.appointmentCancelFormGroup.get('cancelReason');
  }
}
