import { Component, Input, OnInit } from '@angular/core';
import { Functions } from '@app/shared/functions';
import { Attachment } from '@app/shared/models/attachment';
import { DownloadService } from '@app/shared/services/download.service';

@Component({
  selector: 'download-attachment-button',
  templateUrl: './download-attachment-button.component.html',
  styleUrls: ['./download-attachment-button.component.scss']
})
export class DownloadAttachmentButtonComponent implements OnInit {
  isDownloading: boolean = false;
  downloadingAttachmentId: string;

  @Input('attachment') attachment: Attachment = null;
  @Input('attachmentId') attachmentId: string = null;
  @Input('attachmentFileName') attachmentFileName: string = null;
  @Input('isInModalFooter') isInModalFooter: boolean = false;

  constructor(
    private downloadService: DownloadService,
    private functions: Functions
  ) {}

  ngOnInit(): void {}

  onDownloadClicked(): void {
    if (this.attachment) {
      this.downloadAttachment(
        this.attachment.attachmentId,
        this.attachment.attachmentType,
        this.attachment.attachmentFileName
      );
    } else if (this.attachmentId) {
      this.downloadAttachment(this.attachmentId, null, this.attachmentFileName);
    } else {
      this.attachmentNotFound();
    }
  }

  downloadAttachment(attachmentId: string, source: string, attachmentFileName: string): void {
    this.isDownloading = true;
    this.downloadingAttachmentId = attachmentId;

    this.downloadService
      .getAttachmentBlob(attachmentId)
      .then((response: Blob) => {
        if (response && response.type !== 'application/json') {
          source = source?.replace(' ', '') || 'Attachment';
          const fileName: string = (attachmentFileName || source) + '_' + attachmentId.substring(0, 8);
          this.downloadService.downloadFile(
            response,
            fileName,
            source ? source.concat(': ', attachmentId) : null
          );
        } else {
          this.attachmentNotFound(source);
        }
      })
      .catch((err: any) => {
        console.log('Could not retrieve', source, 'attachment file for attachmentId', attachmentId + '. Error:',
          this.functions.getErrorMessage(err));
        this.attachmentNotFound(source);
      })
      .finally(() => {
        this.isDownloading = false;
        this.downloadingAttachmentId = null;
      });
  }

  attachmentNotFound(source?: string): void {
    this.functions.showToast('Could not find ' + (source ? (source.toLowerCase() + ' ') : '') + 'attachment.');
  }

  shouldShowSpinner(): boolean {
    if (this.attachment) {
      return this.attachment.attachmentId === this.downloadingAttachmentId;
    }
    if (this.attachmentId) {
      return this.attachmentId === this.downloadingAttachmentId;
    }
    return false;
  }
}
