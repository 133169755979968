<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="modal-header-title text-primary m-0">Please Select Current Patient</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose(null)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="modal-body mt-5 mb-2">
    <loading-spinner loadingText="Fetching patient list ..."></loading-spinner>
  </div>
  <div [class.d-none]="isLoading" class="modal-body container-fluid bg-muted p-0">
    <div *ngIf="!data.noAddPatient" class="add-patient-container list-item col-12 row mx-0 border-bottom border-white">
      <div class="col-12 col-xl-6 d-flex flex-column pl-0 header-section">
        <span class="text-large text-primary">Patient not listed?</span>
      </div>
      <div class="col-12 col-xl-6 d-flex flex-column button-section">
        <button mat-flat-button color="accent" class="btn-block" (click)="onClose('newPatient')">
          Add New Patient
        </button>
      </div>
    </div>
    <div
      *ngFor="let patient of patients; let i = index"
      class="list-item col-12 row mx-0 border-bottom border-white"
      [class.selected-patient]="patient.patientId === selectedPatientId">
      <div
        [id]="patient.patientId === selectedPatientId ? 'selected-patient' : 'switch-patient-' + i"
        class="col-12 col-xl-6 d-flex flex-column pl-0">
        <span class="text-large text-primary ellipsis" [title]="patient.firstName + ' ' + patient.lastName">
          {{ patient.firstName + ' ' + patient.lastName }}
        </span>
        <span class="text-small text-secondary">
          {{
            !patient.relationship || patient.relationship === 'AccountHolder' ? 'Account Holder' : patient.relationship
          }}
        </span>
      </div>
      <div class="col-12 col-xl-6 d-flex flex-column button-section">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block"
          (click)="updateSelectedPatientId(patient.patientId)">
          Select
        </button>
      </div>
    </div>
  </div>
</div>
