import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Constants, Labels, ApprovalStatus } from '../constants';
import { IResponseAPI } from '../models/api-response';
import { Functions } from '../functions';
import { Subscription } from 'rxjs';
import { PatientService } from './patient.service';
import { Patient } from '../models/patient';
import { SessionStorageService } from 'ngx-webstorage';
import { QuickScriptOrderItem } from '../models/quick-script-order-item';
import { ActiveCampaignEventDTO, ActiveCampaignEventTriggeredBy } from '../models/active-campaign/activeCampaignEventDTO';
import { ActiveCampaignWeightLossDTO } from '../models/active-campaign/activeCampaignWeightLossDTO';
import { GeneralMedical } from '../models/general-medical';
import { BMICalculation } from '../models/weightloss/BMICalculation';
import { Appointment } from '../models/appointment';
import { Prescription } from '../models/prescription';
import { PatientFlag } from '../models/patientFlag';
import { AIContext, AppInsightsService } from './appinsights.service';
import { PatientMHTPHistoryDTO } from '../models/mentalhealth/patientMHTPHistoryDTO';
import { AddressService } from './address.service';
import { Address } from '../models/address';
import { SmokingCessationMedAttributeModel } from '../models/verticals/smokingcessation/SmokingCessationMedAttributeModel';
import { SmokingCessationMedModel } from '../models/verticals/smokingcessation/SmokingCessationMedModel';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ActiveCampaignService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}/ssa`;

  private aiContext: AIContext;
  private subscription = new Subscription();
  private _sessionIdTracker: any = {};
  private isEnabled: boolean = true;

  constructor(
    private http: HttpClient,
    private aiService: AppInsightsService,
    private patientService: PatientService,
    private addressService: AddressService,
    private storage: SessionStorageService,
    private functions: Functions
  ) {
    this.aiContext = this.aiService.createContext('ActiveCampaignService');

    // PATIENT
    this.subscription.add(
      this.patientService.patientChangeObs.subscribe((patient: Patient) => {
        if (patient) {
          const enabled: boolean = patient.flags
            ? patient.flags.indexOf(PatientFlag.ACTIVECAMPAIGN_EXCLUDED) === -1
            : true;

          if (enabled !== this.isEnabled) {
            this.isEnabled = enabled;
            this.aiContext.debug('EnabledStateChanged', {
              patientId: patient.patientId,
              flags: patient.flags,
              enabled
            });
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * @function getExternalId
   * @description Retrieve the exernalId (primary AC record identifier) for the specified event from storage
   *
   * @param {string} serviceType service type to prepend to the storage key
   * @param {string} eventName AC event name to append to the storage key
   *
   * @returns {string} externalId found in storage, or null if not found
   */
  getExternalId(serviceType: string, eventName: string): string {
    const storageKey: string = serviceType.toLowerCase().replace(' ', '').concat('_', eventName.toLowerCase());

    if (this._sessionIdTracker && this._sessionIdTracker[storageKey]) {
      return this._sessionIdTracker[storageKey];
    }

    try {
      this._sessionIdTracker[storageKey] =
        this.storage.retrieve(Constants.LocalStorage_Key.activeCampaign.concat('.', storageKey)) || null;

      return this._sessionIdTracker[storageKey];
    } catch (_err: any) {}

    return null;
  }

  /**
   * @function setExternalId
   * @description Save the exernalId (primary AC record identifier) for the specified event to local storage
   *
   * @param {string} serviceType service type to prepend to the storage key
   * @param {string} eventName AC event name to append to the storage key
   * @param {string} externalId the id to save
   */
  setExternalId(serviceType: string, eventName: string, externalId: string): void {
    const storageKey: string = serviceType.toLowerCase().replace(' ', '').concat('_', eventName.toLowerCase());
    this._sessionIdTracker[storageKey] = externalId;

    try {
      this.storage.store(Constants.LocalStorage_Key.activeCampaign.concat('.', storageKey), externalId);
    } catch (_err: any) {}
  }

  /**
   * @function clearStoredIdsForEventsOfType
   * @description Remove from local storage all AC event keys for a specific service type
   *
   * @param {string} [eventType='appointment']
   */
  clearStoredIdsForEventsOfType(eventType: string = 'appointment'): void {
    const storageKey: string = Constants.LocalStorage_Key.activeCampaign.concat(
      '.',
      eventType.toLowerCase().replace(' ', '')
    );

    Object.keys(window.sessionStorage).forEach((key: string) => {
      if (key.indexOf(storageKey) !== -1) {
        sessionStorage.removeItem(key);
      }
    });
  }

  /**
   * @async
   * @function createAndSendEvent
   * @description Create an Active Campaign event and post it to the API
   *
   * @param {string} eventName event name eg. 'book_appointment'
   * @param {object} data Additional data to post. ie. Medication Name
   * @param {Appointment} [targetAppointment] reference appointment (to determine if on-demand or scheduled)
   *
   * @returns {Promise<void>}
   */
  async createAndSendEvent(
    eventName: string,
    data: any = {}, //Partial<ActiveCampaignEventDTO> = {},
    targetAppointment?: Appointment
  ): Promise<void> {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('createAndSendEvent', false, 'AC Disabled');
      return;
    }

    const eventStatus: string = Constants.ACTIVE_CAMPAIGN_EVENT_NAME_MAP[eventName || 'browse_appointment'];
    let additionalData: any = data && typeof data === 'object' ? data : {};
    let eventType: string = 'Appointment';
    let eventSubType: string = null;
    let serviceType: string = null;
    let appointment: Appointment;

    switch (eventName) {
      // Regular Appointments
      case Constants.ACTIVE_CAMPAIGN_EVENTS.BrowseAppointment:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.BookAppointment:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.StartAppointment:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.CompleteAppointment:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.CancelApppointment:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.FollowUpAppointment:
      // MentalHealth Appointments
      case Constants.ACTIVE_CAMPAIGN_EVENTS.DoctorComplete:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.MentalHealthPaidFollowUpCreated:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.MentalHealthPlanSentToPsychPartner:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.MentalHealthPlanConsentToPsychPartner:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.MentalHealthPlanNoConsentToPsychPartner:
        appointment = targetAppointment;
        serviceType = appointment?.serviceType?.toLowerCase();

        switch (serviceType) {
          case Constants.SERVICE_TYPE.MENTAL_HEALTH:
            eventType = Labels.mentalHealth;
            break;
          case Constants.SERVICE_TYPE.SMOKING_CESSATION:
            eventType = Labels.smokingCessation;
            additionalData.medicationCategory = Labels.smokingCessation;
            break;
          default:
            eventType = Labels.appointment;
            break;
        }

        break;

      // Quickscript
      case Constants.ACTIVE_CAMPAIGN_EVENTS.BrowseQuickscript:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.StartQuickscript:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.CompleteQuickscript:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.CancelQuickscript:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.QuickScriptReorder:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.QuickScriptRequested:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.QuickScriptConfirmed:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.QuickScriptDeclined:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.ClosedUnreachable:
        eventType = Labels.quickScriptEvent;

        if (typeof additionalData.isDelivered === 'boolean') {
          serviceType = additionalData.isDelivered
            ? Constants.SERVICE_TYPE.QUICKSCRIPT_DELIVERY
            : Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY;
        } else {
          serviceType = Constants.SERVICE_TYPE.QUICKSCRIPT;
        }

        break;

      // SmokingCessation
      case Constants.ACTIVE_CAMPAIGN_EVENTS.SmokingCessationBrowseMedication:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.SmokingCessationViewEScript:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.SmokingCessationCheckoutComplete:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.SmokingCessationCheckoutIncomplete:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.SmokingCessationEligibilityConfirmed:
      case Constants.ACTIVE_CAMPAIGN_EVENTS.SmokingCessationEligibilityDeclined:
        appointment = targetAppointment;
        eventType = Labels.smokingCessation;
        serviceType = Constants.SERVICE_TYPE.SMOKING_CESSATION;

        break;
    }

    // Quickscript
    if (eventType === Labels.quickScriptEvent) {
      eventSubType =
        typeof additionalData?.isDelivered === 'boolean'
          ? additionalData.isDelivered
            ? Labels.quickScriptEvent + ' HomeDelivery'
            : Labels.quickScriptEvent + ' eScript'
          : 'VOIP On-Demand';

      // MentalHealth
    } else if (eventType == Labels.mentalHealth) {
      eventSubType = Labels.appointment + (appointment?.followupId ? ' ' + Labels.followUp : ' ' + Labels.scheduled);

      // SmokingCessation
    } else if (eventType == Labels.smokingCessation && typeof additionalData.isDelivered === 'boolean') {
      eventSubType = additionalData.isDelivered ? 'SC HomeDelivery' : 'SC eScript';

      // Appointment
    } else if (eventType === Labels.appointment || eventType == Labels.smokingCessation) {
      if (appointment?.followupId) {
        eventSubType = Labels.appointment + ' ' + Labels.followUp;
      } else {
        const isOnDemand: boolean = typeof appointment?.isOnDemand === 'boolean' ? appointment.isOnDemand : null;

        eventSubType =
          isOnDemand !== null
            ? Labels.appointment + (isOnDemand ? ' ' + Labels.onDemand : ' ' + Labels.scheduled)
            : null;
      }
    }

    // Patient
    const patient: Patient = this.patientService.patientOther || this.patientService.patient || null;
    const isLoggedIn: string = this.patientService.isAuthenticatedPatient() ? ApprovalStatus.yes : ApprovalStatus.no;
    const patientTimezoneOffsetHours: string =
      appointment?.patientTimezoneOffsetHours ||
      this.patientService.getPatientTimeZoneOffset(undefined, Boolean(this.patientService.patientOther)) ||
      null;
    const timezoneOffset: number = patientTimezoneOffsetHours ? parseFloat(patientTimezoneOffsetHours) : null;

    // Policy
    const policyId: string = this.patientService.getStoredBenefitCode() || null;

    // Event timestamp
    const eventDateTime: string = this.functions.getUTCMomentString(patientTimezoneOffsetHours);

    // Active Campaign record identifier (only provide this value if you wish to overwrite an existing event)
    let externalId: string = null;

    // Practitioner
    let doctorName: string = null;
    if (appointment?.practitionerId && appointment?.practitionerName) {
      doctorName = appointment.practitionerName;
    }

    // Complete status means we have an appointmentId / orderId and we can use that as a unique identifier
    // for the Complete events
    if (eventStatus === 'Complete') {
      if (additionalData?.orderId) {
        externalId = additionalData.orderId;
      } else if (appointment?.appointmentId) {
        externalId = appointment.appointmentId;
      }
    } else {
      externalId = this.getExternalId(eventType, eventName);
    }

    // Address
    let address: Address = patient?.address;
    if (patient?.addressId && !patient?.address?.postalCode) {
      address = await this.addressService.getAddressById(patient.addressId);
    }

    // Mental Health
    if (appointment?.serviceType === Constants.SERVICE_TYPE.MENTAL_HEALTH && patient?.patientId) {
      additionalData = {
        ...additionalData,
        ...this.getUpdatedMentalHealthTreatmentPlanStatus(patient.patientId)
      };
    }

    // Does patient have a Medicare card? (Note: expiry is not validated)
    const hasMedicare: string = this.patientService.doesPatientHaveMedicare(patient)
      ? ApprovalStatus.yes
      : ApprovalStatus.no;

    // Who triggered the event?
    const eventTriggeredBy: string = this.patientService.isAdminUser()
      ? ActiveCampaignEventTriggeredBy.admin
      : ActiveCampaignEventTriggeredBy.patient;

    // IsHomeDelivery = Yes/No (applies to Quickscript and SmokingCessation)
    const isHomeDelivery: string =
      typeof additionalData?.isDelivered === 'boolean'
        ? additionalData.isDelivered
          ? ApprovalStatus.yes
          : ApprovalStatus.no
        : null;

    const activeCampaignDTO: ActiveCampaignEventDTO = {
      // Identifiers
      externalId,
      orderId: additionalData?.orderId ?? null,
      appointmentId: additionalData?.appointmentId || appointment?.appointmentId || null,
      followupId: appointment?.followupId ?? null,
      policyId,

      // Appointment
      appointmentDateTimeUTC: additionalData?.appointmentDateTimeUTC ?? null,
      appointmentDateTimeLocal: additionalData?.appointmentDateTimeLocal ?? null,
      cancellationReason: additionalData?.cancellationReason ?? null,
      doctorName,

      // Patient
      patientId: patient?.patientId || additionalData?.patientId || appointment?.followUpPatientId || null,
      email: patient?.email ?? null,
      mobile: patient?.mobilePhone ?? null,
      firstName: patient?.firstName ?? null,
      lastName: patient?.lastName ?? null,
      city: address?.locality ?? null,
      state: address?.administrativeAreaLevel1 ?? null,
      postcode: address?.postalCode || patient?.postCode || null,
      timezoneOffset,
      isLoggedIn,
      hasMedicare,
      isTakingMedication: additionalData?.isTakingMedication ?? null,

      // Service
      serviceType,
      isHomeDelivery,
      prescriptionIsCompleted: additionalData?.prescriptionIsCompleted ?? null,
      scConfirmed: additionalData?.scConfirmed ?? null,
      scOrderUrl: additionalData?.scOrderUrl ?? null,
      supplyEndDate: additionalData?.supplyEndDate ?? null,

      // Medication
      medicationId: additionalData?.medicationId ?? null,
      medicationName: additionalData?.medicationName ?? null,
      medicationBrandName: additionalData?.medicationBrandName ?? null,
      medicationCategory: additionalData?.medicationCategory ?? null,
      medicationDosage: additionalData?.medicationDosage ?? null,
      medicationQuantity: additionalData?.medicationQuantity ?? null,
      medicationPrice: additionalData?.medicationPrice ?? null,
      medicationShipping: additionalData?.medicationShipping ?? null,

      // Mental Health
      mentalHealthPlanCreated: additionalData?.mentalHealthPlanCreated ?? null,
      mentalHealthPlanConsentToPsychPartner: additionalData?.mentalHealthPlanConsentToPsychPartner ?? null,
      mentalHealthPlanSentToPsychPartner: additionalData?.mentalHealthPlanSentToPsychPartner ?? null,

      // Event
      eventName,
      eventType,
      eventSubType,
      eventStatus,
      eventDateTime,
      eventTriggeredBy,
      url: location.href
    };

    this.aiContext.info('sendActiveCampaignEvent', { eventName, activeCampaignDTO });

    this.sendActiveCampaignEvent(activeCampaignDTO).then((extId: string) => {
      this.aiContext.reportSuccessStatus('sendActiveCampaignEvent', !!extId, eventName, { activeCampaignDTO });
      // A valid externalId means the record was successfully saved in Active Campaign
      // We keep track of the externalId until the end of the user's "session", in order to update
      // the existing record with the latest "Status"
      if (extId) {
        console.log('AC event', eventName, 'successfully created');

        // If status is complete, then we should remove any stored externalId for this service type "session"
        if (eventStatus === 'Cancel' || eventStatus === 'Book') {
          try {
            switch (eventType) {
              case 'Appointment':
                this.clearStoredIdsForEventsOfType('appointment');
                break;
              case 'Quickscript':
                this.clearStoredIdsForEventsOfType('quickscript');
                break;
            }
          } catch (_err: any) {}
        } else {
          // Save externalId for this service type "session" to local storage
          this.setExternalId(eventType, eventName, extId);
        }
      }
    });
  }

  async getUpdatedMentalHealthTreatmentPlanStatus(patientId: string): Promise<any> {
    // Call API endpoint with patientId to retrieve patient's MHP Created and MHP Referral Sent status
    const mhtpStatus: PatientMHTPHistoryDTO = await this.patientService.getPatientMentalHealthHistoryStatus(patientId);

    let additionalData: any = {};

    if (mhtpStatus) {
      // Override additional data for Mental Health
      additionalData = {
        mentalHealthPlanCreated: null,
        // typeof mhtpStatus.hasBeenProvidedMHTP === 'boolean'
        //   ? (mhtpStatus.hasBeenProvidedMHTP ? ApprovalStatus.yes : ApprovalStatus.no)
        //   : null,
        mentalHealthPlanSentToPsychPartner:
          typeof mhtpStatus.hasHadMHTPSentToPsychPartner === 'boolean'
            ? mhtpStatus.hasHadMHTPSentToPsychPartner
              ? ApprovalStatus.yes
              : ApprovalStatus.no
            : null,
        mentalHealthPlanConsentToPsychPartner:
          additionalData.mentalHealthPlanConsentToPsychPartner ||
          (typeof mhtpStatus.hasRecentlyConsentedToPsychPartnerReferral === 'boolean'
            ? mhtpStatus.hasRecentlyConsentedToPsychPartnerReferral
              ? ApprovalStatus.yes
              : ApprovalStatus.no
            : null)
      };
    }

    return additionalData;
  }

  /**
   * @async
   * @function createAndSendWeightLossEvent
   * @description Create an Active Campaign Weight Loss event and post it to the API
   *
   * @param {string} eventName event name eg. 'weight_loss_qualified'
   * @param {object} additionalData Additional data to post. eg. goalWeight
   *
   * @returns {Promise<void>}
   */
  async createAndSendWeightLossEvent(eventName: string, additionalData: any = {}): Promise<void> {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('createAndSendWeightLossEvent', false, 'AC Disabled');
      return;
    }

    const eventStatus: string =
      Constants.ACTIVE_CAMPAIGN_EVENT_NAME_MAP[eventName] ?? Constants.ACTIVE_CAMPAIGN_EVENT_NAME_MAP.weight_loss_start;
    const eventType: string = 'WeightLoss';
    const patient: Patient = this.patientService.patientOther || this.patientService.patient || null;
    const policyId: string = this.patientService.getStoredBenefitCode() || null;
    const patientTimezoneOffsetHours: string =
      this.patientService.getPatientTimeZoneOffset(null, Boolean(this.patientService.patientOther)) || null;
    const timezoneOffset: number = patientTimezoneOffsetHours ? parseFloat(patientTimezoneOffsetHours) : null;
    const eventDateTime: string = this.functions.getUTCMomentString(patientTimezoneOffsetHours);
    const externalId: string = patient?.patientId ? 'WL-' + patient.patientId : null;

    let address: Address = patient?.address;

    if (patient?.addressId && !patient?.address?.postalCode) {
      address = await this.addressService.getAddressById(patient.addressId);
    }

    const activeCampaignWeightLossDTO: ActiveCampaignWeightLossDTO = {
      externalId,
      patientId: patient?.patientId ?? null,
      email: patient?.email ?? null,
      mobile: patient?.mobilePhone ?? null,
      firstName: patient?.firstName ?? null,
      lastName: patient?.lastName ?? null,
      city: address?.locality ?? null,
      state: address?.administrativeAreaLevel1 ?? null,
      postcode: address?.postalCode || patient?.postCode || null,
      timezoneOffset,
      policyId,
      eventName,
      eventType,
      eventStatus,
      eventDateTime,
      url: window.location.href,
      ...additionalData
    };

    // console.log('Posting AC WeightLoss Event', eventName, 'with data:', activeCampaignWeightLossDTO);
    this.sendActiveCampaignWeightLossEvent(activeCampaignWeightLossDTO).then((extId: string) => {
      // The externalId returned should match the externalId sent in the request. ie. WL-{patientId}
      if (externalId === extId) {
        console.log('[ACTIVE-CAMPAIGN-SERVICE] Weight Loss event successfully recorded.');
      } else if (!extId || extId === 'null') {
        console.log('[ACTIVE-CAMPAIGN-SERVICE] Weight Loss event did not record!');
      } else {
        console.log('[ACTIVE-CAMPAIGN-SERVICE] Weight Loss event recorded, but a new object was created!');
      }
    });
  }

  /**
   * @function createAndSendWeightLossCheckInEvent
   * @description Read the weight loss data from a patient's medical history and
   * post an ActiveCampaign "Check-In" Weight Loss event to the API
   *
   * @param {GeneralMedical} medicalHistoryData medical history form data
   * @param {Prescription} [prescription] weight loss medication prescription
   * @param {string} [eventName] event name eg. 'weight_loss_qualified'
   */
  createAndSendWeightLossCheckInEvent(
    medicalHistoryData: GeneralMedical,
    prescription?: Prescription,
    eventName?: string
  ): void {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('createAndSendWeightLossCheckInEvent', false, 'AC Disabled');
      return;
    }

    eventName =
      eventName ||
      (medicalHistoryData.approvalStatus == ApprovalStatus.yes
        ? Constants.ACTIVE_CAMPAIGN_EVENTS.WeightLossUpdated
        : Constants.ACTIVE_CAMPAIGN_EVENTS.WeightLossEligibilityCheck);

    // Weight Loss check-in data
    const medicalData: GeneralMedical = {
      originalWeight: medicalHistoryData?.originalWeight || medicalHistoryData?.currentWeight || null,
      originalWaist: medicalHistoryData?.originalWaist || medicalHistoryData?.currentWaist || null,
      currentHeight:
        medicalHistoryData?.currentHeight ||
        (medicalHistoryData?.height ? parseInt(medicalHistoryData.height.replace('cm', '').replace('.', '')) : null),
      currentWeight: medicalHistoryData?.currentWeight || null,
      currentWaist: medicalHistoryData?.currentWaist || null,
      goalWeight: medicalHistoryData?.goalWeight || null,
      goalWaist: medicalHistoryData?.goalWaist || null
    };

    const bmiCalculation: BMICalculation = this.functions.calculateBMI(
      medicalData.currentHeight,
      medicalData.currentWeight,
      true
    );

    let isQualified: string = null;
    if (medicalHistoryData.approvalStatus) {
      switch (medicalHistoryData.approvalStatus) {
        case ApprovalStatus.pending:
          isQualified = ApprovalStatus.pending;
          break;
        case ApprovalStatus.yes:
          isQualified = Constants.ACTIVE_CAMPAIGN_EVENT_NAME_MAP.weight_loss_qualified;
          break;
        case ApprovalStatus.no:
          isQualified = Constants.ACTIVE_CAMPAIGN_EVENT_NAME_MAP.weight_loss_not_qualified;
          break;
      }
    }

    let eventData: any = {
      ...medicalData,
      bmiLabel: bmiCalculation.bmiLabel,
      bmiValue: bmiCalculation.bmiValue,
      isQualified,
      goalWaistReached:
        medicalData.goalWaist + Constants.Weight_Loss_Configuration.waistGoalDeviation >= medicalData.currentWaist,
      goalWeightReached:
        medicalData.goalWeight + Constants.Weight_Loss_Configuration.weightGoalDeviation >= medicalData.currentWeight
    };

    // Medication name and id
    if (prescription?.medications?.length) {
      eventData.medicationId = prescription.medications[0].medicationId;
      eventData.medicationName = prescription.medications[0].shortDescription;
      eventData.medicationDosage = prescription.medications[0].name;
    }

    // Prescription expiry
    if (prescription) {
      eventData.prescriptionExpiryDate = prescription.prescriptionProvidedDateUTC
        ? moment(prescription.prescriptionProvidedDateUTC)
            .add(Constants.Weight_Loss_Configuration.prescriptionPeriodDays, 'days')
            .format()
        : null;
    } else {
      eventData.prescriptionExpiryDate = null;
    }

    this.createAndSendWeightLossEvent(eventName, eventData);
  }

  /**
   * @function createAndSendSmokingCessationEvent
   * @description Send a Smoking Cessation event containing order and medication data to Active Campaign
   *
   * @param {string} eventName name of the Active Campaign event
   * @param {Appointment} appointment related appointment
   * @param {any} [additionalOptions]
   */
  createAndSendSmokingCessationEvent(eventName: string, appointment?: Appointment, additionalOptions: any = {}): void {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('createAndSendSmokingCessationEvent', false, 'AC Disabled');
      return;
    }

    const orderId: string = additionalOptions.orderId;
    const appointmentId: string = additionalOptions.appointmentId;
    const medicationId: string = additionalOptions.medicationId;
    const medications: SmokingCessationMedModel[] = additionalOptions.medications;

    const scConfirmed: boolean = additionalOptions.scConfirmed;
    const scOrderUrl: string = additionalOptions.scOrderUrl; //eScriptURL;
    const isDelivered: boolean = additionalOptions.isDelivered;
    const prescriptionIsCompleted: boolean = additionalOptions.prescriptionIsCompleted;

    let medicationBrandName: string = additionalOptions.brandName || null;
    let medicationName: string = null;
    let medicationDosage: string = null;
    let medicationIndex: number = 0;

    if (medications?.length) {
      if (medicationId) {
        medicationIndex = medications.findIndex((med: SmokingCessationMedModel) => med.medId === medicationId);
      }

      if (medicationIndex >= 0) {
        try {
          medicationBrandName = medications[medicationIndex].brandName;
          medicationName = medications[medicationIndex].name;

          const concentration: string = medications[medicationIndex].attributes.find(
            (medAttribute: SmokingCessationMedAttributeModel) => medAttribute.code === 'Concentration'
          )?.value;
          const concentrationUnits: string = medications[medicationIndex].attributes.find(
            (medAttribute: SmokingCessationMedAttributeModel) => medAttribute.code === 'ConcentrationUnit'
          )?.value;

          medicationDosage = `${concentration} ${concentrationUnits}`;
        } catch (err: any) {
          console.warn('Failed to retrieve pod concentration details. Attributes missing');
        }
      }
    }

    const prescriptionCompleted: string =
      typeof prescriptionIsCompleted === 'boolean'
        ? prescriptionIsCompleted
          ? ApprovalStatus.yes
          : ApprovalStatus.no
        : null;

    const scEligibilityConfirmed: string =
      typeof scConfirmed === 'boolean' ? (scConfirmed ? ApprovalStatus.yes : ApprovalStatus.no) : null;

    this.createAndSendEvent(
      eventName,
      {
        // appointment
        appointmentId,
        // order
        orderId,
        scOrderUrl,
        isDelivered,
        scConfirmed: scEligibilityConfirmed,
        prescriptionIsCompleted: prescriptionCompleted,
        // medication
        medicationId,
        medicationCategory: Labels.smokingCessation,
        medicationBrandName,
        medicationName,
        medicationDosage
      },
      appointment
    );
  }

  /**
   * @function createAndSendQuickscriptEvent
   * @description Send a QuickScript event containing medication data to Active Campaign
   *
   * @param {string} eventName name of the Active Campaign event
   * @param {QuickScriptOrderItem} orderedMedicationItem Quickscript medication item 1
   * @param {string} [medicationBrandName] Brand Name of medication
   * @param {number} [medicationShipping=null] Quickscript medication shipping cost (if delivered)
   * @param {boolean} [isDelivered] Medication home delivery?
   * @param {string} [orderId]
   */
  createAndSendQuickscriptEvent(
    eventName: string,
    orderedMedicationItem: QuickScriptOrderItem,
    medicationBrandName?: string,
    medicationShipping: number = null,
    isDelivered?: boolean,
    orderId: string = null
  ): void {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('createAndSendQuickscriptEvent', false, 'AC Disabled');
      return;
    }

    this.createAndSendEvent(eventName, {
      orderId,
      isDelivered,

      // Medication 1
      medicationId: orderedMedicationItem.medicationId,
      medicationName: orderedMedicationItem.medicationFullName,
      medicationBrandName,
      medicationDosage: orderedMedicationItem.name,
      medicationCategory: orderedMedicationItem.condition,
      medicationQuantity: typeof orderedMedicationItem.quantity === 'number' ? orderedMedicationItem.quantity : null,
      medicationPrice: typeof orderedMedicationItem.price === 'number' ? orderedMedicationItem.price : null,
      medicationShipping
    });
  }

  /**
   * @deprecated
   * @function findOptionIdInSchema
   * @description Find the GUID of the provided dropdown field value in an Active Campaign schema
   *
   * @param {string} eventName
   * @param {string} optionValue
   *
   * @returns GUID representing the field value
   */
  findOptionIdInSchema(eventName: string, optionValue: string): string {
    try {
      const field: any = null; // so that we don't have to import the JSON

      if (field) {
        const option: any = field.options?.find((option: any) => option.value === optionValue);

        if (option) {
          return option.id;
        }
      }
    } catch (_err: any) {
      console.log('Unable to get option id for event "' + eventName + '" from value "' + optionValue + '"');
    }

    return null;
  }

  /**
   * @function sendActiveCampaignEvent
   * @description Send an event to Active Campaign, using Custom Object Schema 58950fcf-3820-4c76-b90f-e8da1d867796
   * If the event (record) is to be updated instead of created, the 'externalId' parameter must be supplied in the DTO.
   * Definitions for existing schemas can be found here: https://newsletter.doctorsondemand.com.au/app/settings/data-manager
   *
   * @param {ActiveCampaignEventDTO} activecampaignDTO
   *
   * @returns {Promise<string>} if successful, the Id or ExternalId of the record
   */
  sendActiveCampaignEvent(activecampaignDTO: ActiveCampaignEventDTO): Promise<string> {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('sendActiveCampaignEvent', false, 'AC Disabled');
      return Promise.resolve(null);
    }

    let encodedPayload: string;

    try {
      encodedPayload = btoa(JSON.stringify(activecampaignDTO));
    } catch (err: any) {
      const errorMessage: string = err?.message || 'Data encoding failed!';
      this.aiContext.error('ActiveCampaignEvent', { error: errorMessage });
      return Promise.reject('sendActiveCampaignEvent() :: ' + errorMessage);
    }

    if (!encodedPayload) {
      return Promise.reject('sendActiveCampaignEvent() :: No data supplied!');
    }

    return this.http
      .post(`${this.url}/acEvent`, { data: encodedPayload })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const externalId: string = String(response.response);
          return externalId;
        }
        return null;
      })
      .catch((err: any) => {
        console.log(
          'Error posting an Active Campaign event' +
            (activecampaignDTO.id ? ' (id: ' + activecampaignDTO.id + ')' : '') +
            '. Error: ',
          this.functions.getErrorMessage(err)
        );
        //throw err;
        return null;
      });
  }

  /**
   * @function sendActiveCampaignWeightLossEvent
   * @description Send a Weight Loss event to Active Campaign, using Custom Object Schema 26d5043f-12d6-4a92-b9c8-0e9bf27f1e36
   * Note: patientId is used as the externalId for these records.
   *
   * @param {ActiveCampaignWeightLossDTO} activeCampaignWeightLossDTO
   *
   * @returns {Promise<boolean>} true if the event was successfully recorded
   */
  sendActiveCampaignWeightLossEvent(activeCampaignWeightLossDTO: ActiveCampaignWeightLossDTO): Promise<string> {
    if (!this.isEnabled) {
      this.aiContext.reportSuccessStatus('sendActiveCampaignWeightLossEvent', false, 'AC Disabled');
      return Promise.resolve(null);
    }

    let encodedPayload: string;

    try {
      encodedPayload = btoa(JSON.stringify(activeCampaignWeightLossDTO));
    } catch (err: any) {
      const errorMessage: string = err?.message || 'Data encoding failed!';
      this.aiContext.error('ActiveCampaignEvent', { error: errorMessage });
      return Promise.reject('sendActiveCampaignWeightLossEvent() :: ' + errorMessage);
    }

    if (!encodedPayload) {
      return Promise.reject('sendActiveCampaignWeightLossEvent() :: No data supplied!');
    }

    return this.http
      .post(`${this.url}/acWeightLoss`, { data: encodedPayload })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const externalId: string = String(response.response);
          return externalId;
        }
        return null;
      })
      .catch((err: any) => {
        console.log(
          'Error posting an Active Campaign weight loss event' +
            (activeCampaignWeightLossDTO.id ? ' (id: ' + activeCampaignWeightLossDTO.id + ')' : '') +
            '. Error: ',
          this.functions.getErrorMessage(err)
        );
        return null;
      });
  }

  /**
   * @function createActiveCampaignSchema
   * @description Create a new Custom Object Schema
   *
   * @param {JSON} jsonSchema JSON object (Active Campaign Custom Object Schema)
   *
   * @returns {Promise<any>} Returns the created schema in its entirety (with extra fields)
   */
  /*
  createActiveCampaignSchema(jsonSchema: any): Promise<any> {
    // Remove id from the schema if there is one
    const acSchema: any = { ...jsonSchema };

    try {
      acSchema.schema.id = null;
    } catch (_err: any) {}

    return this.http
      .post(`${this.url}/createSchema`, acSchema)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response && response.success) {
          return response.response;
        }
        return null;
      })
      .catch((err: any) => {
        console.log('Error creating a new Active Campaign Custom Object Schema. Error: ', this.functions.getErrorMessage(err));
        return null;
      });
  }
  */

  /**
   * @function updateActiveCampaignSchema
   * @description Update an existing Custom Object Schema. Must provide schemaId as one of the parameters.
   *
   * @param {JSON} jsonSchema JSON object (Active Campaign Custom Object Schema)
   * @param {string} [schemaId] provide the schema GUID if not already in the JSON object
   *
   * @returns {Promise<any>} Returns the updated schema in its entirety
   */
  /*
  updateActiveCampaignSchema(jsonSchema: any, schemaId?: string): Promise<any> {
    // Add/overwrite id in schema if supplied
    const acSchema: any = { ...jsonSchema };
    if (schemaId) {
      try {
        acSchema.schema.id = schemaId;
      } catch (_err: any) {}
    }

    return this.http
      .post(`${this.url}/updateSchema`, acSchema)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response && response.success) {
          return response.response;
        }
        return null;
      })
      .catch((err: any) => {
        console.log('Error updating Active Campaign Custom Object Schema: ' + jsonSchema.schemaId +
          '. Error: ' + this.functions.getErrorMessage(err));
        return null;
      });
  }
  */
}
