import { Component, Input, OnInit } from '@angular/core';
import { Functions } from '@app/shared/functions';
import { BMICalculation } from '@app/shared/models/weightloss/BMICalculation';

@Component({
  selector: 'weight-loss-bmi-progress',
  templateUrl: './weight-loss-bmi-progress.component.html',
  styleUrls: ['./weight-loss-bmi-progress.component.scss']
})
export class WeightLossBmiProgressComponent implements OnInit {
  @Input('bmiCalculation') bmiCalculation: BMICalculation;

  constructor(
    private functions: Functions
  ) {}

  ngOnInit() {}

  getBMILabel(): string {
    if (this.bmiCalculation) {
      return this.functions.getBMILabel(this.bmiCalculation);
    }
    return null;
  }
}
