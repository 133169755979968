import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WordpressSearch } from '@app/shared/models/wordpressSearch';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Constants } from '@app/shared/constants';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { LiveChatService } from '@app/shared/services/live-chat.service';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { environment } from '@env/environment';
import { ComponentDisplayType } from '@src/app/shared/models/component-display-type';

@Component({
  selector: 'having-issues',
  templateUrl: './having-issue.component.html',
  styleUrls: ['./having-issue.component.scss']
})
export class HavingIssueComponent implements OnInit, OnDestroy {
  @Input() type: string = ComponentDisplayType.modal;

  private subscription = new Subscription();

  marketingURL: any = environment;
  faqsRedirectUrl: string = environment.faqs;
  searchConfig: any = Constants.Search_Configuration;
  searchQuery = new FormControl();
  isAgentOnline: boolean = false;
  isDisplayedInline: boolean = false;
  options: WordpressSearch[];
  filteredOptions: Observable<WordpressSearch[]>;
  enableLiveChat: boolean = false;
  enableMarketingSite: boolean = false;
  isDevEnvironment: boolean = environment.environmentName === 'development';

  isNative: boolean = Boolean(Capacitor.isNativePlatform());

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HavingIssueComponent>,
    private appointmentService: AppointmentService,
    private liveChatService: LiveChatService,
    private analytics: GoogleAnalyticsService,
    private whiteLabelService: WhitelabelService
  ) {}

  private _filterOption(value: string): WordpressSearch[] {
    const filterValue: string = (value || '').toLowerCase();

    return (this.options ?? [])
      .filter((option: WordpressSearch) => option.sub_category_title?.toLowerCase().includes(filterValue))
      .slice(0, Constants.Search_Configuration.needHelp_maxSearchResults);
  }

  ngOnInit(): void {
    this.isDisplayedInline = this.type === ComponentDisplayType.inline;
    if (!this.isDisplayedInline) {
      this.analytics.havingIssues();
    }

    // WHITE LABEL CONFIGURATION
    this.updateWhiteLabelConfiguration();
    this.initData();

    this.subscription.add(
      this.whiteLabelService.whitelabelConfigObs.subscribe(() => {
        this.updateWhiteLabelConfiguration();
        this.initData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateWhiteLabelConfiguration(): void {
    this.enableMarketingSite = this.whiteLabelService.marketingSiteEnabled();
    this.enableLiveChat = this.whiteLabelService.canProvideLiveChat();
  }

  initData(): void {
    // Live Chat
    if (this.enableLiveChat) {
      this.isAgentOnline = this.liveChatService.isAgentOnline;

      this.subscription.add(
        this.liveChatService.liveChatStatusChangeObs.subscribe(() => {
          if (this.isAgentOnline !== this.liveChatService.isAgentOnline) {
            this.isAgentOnline = this.liveChatService.isAgentOnline;
          }
        })
      );

      this.liveChatService.init(true);
    }

    // FAQ and UserGuide Search
    /* DOC-5969 - disable user guide search
    if (this.enableMarketingSite) {
      this.retrieveFAQSearchData();
    }
    */
  }

  /**
   * @deprecated
   * @function retrieveFAQSearchData
   * @description Provide searchable User Guide and FAQ items from the Marketing site (using Wordpress API)
   */
  retrieveFAQSearchData(): void {
    this.appointmentService.getFaqsAndGuides().then((res: WordpressSearch[]) => {
      this.options = res;

      this.filteredOptions = this.searchQuery.valueChanges.pipe(
        startWith(''),
        map((option) => {
          if (option && typeof option === 'object' && option.sub_category_title?.length) {
            return option.sub_category_title.length >= Constants.Search_Configuration.needHelp_minSearchChars
              ? this._filterOption(option.sub_category_title)
              : [];
          } else if (typeof option === 'string') {
            return option && option.length >= Constants.Search_Configuration.needHelp_minSearchChars
              ? this._filterOption(option)
              : [];
          }
        })
      );
    });
  }

  /**
   * @deprecated
   * @function displayFn
   * @description Determine how to display the selected search option object in the User Guide search autocomplete
   *
   * @param {string | WordpressSearching} [option]
   */
  displayFn(option?: string | WordpressSearch): string {
    return typeof option === 'string'
      ? option
      : option && typeof option === 'object'
        ? option.sub_category_title
        : undefined;
  }

  /**
   * @deprecated
   * @function openLink
   * @description Open link using Browser extension on mobile and regular window.open on desktop
   *
   * @param {string} url
   */
  openLink(url: string): void {
    if (url) {
      this.analytics.userGuideSearch();

      if (this.isNative) {
        Browser.open({ url });
      } else {
        window.open(url, '_blank');
      }
    }
  }

  /**
   * @function redirectToFaqs
   * @description Open Marketing FAQ page using Browser extension on mobile and regular window.open on desktop
   */
  redirectToFaqs(): void {
    if (this.faqsRedirectUrl) {
      if (this.isNative) {
        Browser.open({ url: this.faqsRedirectUrl });
      } else {
        window.open(this.faqsRedirectUrl, '_blank');
      }
    }
  }

  onClose(): void {
    // console.log('[HAVING-ISSUES] Close dialogRef :: ', this.dialogRef);
    if (this.dialogRef?.close) {
      try {
        this.dialogRef.close();
      } catch (err: any) {
        console.log('Could not close Having Issues modal. Error = ', err?.message);
      }
    }
  }
}
