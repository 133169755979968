import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Navigation, ParamMap, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AvailabilityDateRange, AvailabilityDateRangeStrings } from './models/availability-date-range';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModalWidthSpec } from './models/modalWidthSpec';
import { Constants, Labels } from './constants';
import { Patient } from './models/patient';
import { environment } from '@env/environment';
import { Appointment } from './models/appointment';
import { STEP_PATH } from './step-configuration';
import { BMICalculation } from './models/weightloss/BMICalculation';
import { Attachment } from './models/attachment';
import { Benefit } from './models/benefit';
import { AppointmentTimeOfDay } from './models/appointment-time-of-day';
import { APIResponseError } from './models/error-handling/api-response-error';
import moment from 'moment';

// Virtual scroller data types
export type Range = [number, number];
export type ItemHeight = number[];

@Injectable({
  providedIn: 'root'
})
export class Functions {
  _isSnackbarActive: boolean = false;

  constructor(
    private _snackbar: MatSnackBar,
    private location: Location,
    private router: Router
  ) {}

  checkEmails(email: string): boolean {
    const emailRegex: string = Constants.emailRegexString;
    const emails: string[] = email.split(',');

    let isValid: boolean = false;

    emails.forEach((email: string) => {
      email = email.trim();
      if (email.match(emailRegex)) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * @function checkMobile
   * @description Check if viewport width is below Destop breakpoint specification
   *
   * @param {number} [innerWidth] optional, custom width calculation
   * @returns {boolean} true if below Desktop breakpoint, otherwise false
   */
  checkMobile(innerWidth?: number): boolean {
    return (innerWidth || window.innerWidth) < Constants.SCREEN_SIZE.desktop;
  }

  /**
   * @function checkTablet
   * @description Check if viewport width is between Mobile and Destop breakpoints
   *
   * @param {number} [innerWidth] optional, custom width calculation
   * @returns {boolean} true if above Mobile breakpoint and below Desktop breakpoint, otherwise false
   */
  checkTablet(innerWidth?: number): boolean {
    const width: number = innerWidth || window.innerWidth;
    return width >= Constants.SCREEN_SIZE.tablet && width < Constants.SCREEN_SIZE.desktop;
  }

  /**
   * @function convertToMedicareFormat
   * @param {string} medicareNumber 0000-00000-0 formatted card number
   * @returns {string} 0000-00000-0 formatted string
   */
  convertToMedicareFormat(medicareNumber: string): string {
    let trimmed: string = (medicareNumber || '').replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 12) {
      trimmed = trimmed.substring(0, 12);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 4) !== '') {
      numbers.push(trimmed.substring(0, 4));
    }
    if (trimmed.substring(4, 9) !== '') {
      numbers.push(trimmed.substring(4, 9));
    }
    if (trimmed.substring(9, 10) !== '') {
      numbers.push(trimmed.substring(9, 10));
    }

    medicareNumber = numbers.join('-');

    return medicareNumber;
  }

  /**
   * @function convertToHealthcareFormat
   * @param {string} healthcareNumber 000-000-000-A formatted card number
   * @returns {string} 000-000-000-A formatted string
   */
  convertToHealthcareFormat(healthcareNumber: string): string {
    let trimmed: string = (healthcareNumber || '').replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 13) {
      trimmed = trimmed.substring(0, 13);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 3) !== '') {
      numbers.push(trimmed.substring(0, 3));
    }
    if (trimmed.substring(3, 6) !== '') {
      numbers.push(trimmed.substring(3, 6));
    }
    if (trimmed.substring(6, 9) !== '') {
      numbers.push(trimmed.substring(6, 9));
    }
    if (trimmed.substring(9, 10) !== '') {
      numbers.push(trimmed.substring(9, 10));
    }

    healthcareNumber = numbers.join('-');

    return healthcareNumber.toUpperCase();
  }

  /**
   * @function convertToSafetynetFormat
   * @param {string} safetynetNumber CN-000000000 or SN-000000000 formatted card number
   * @returns {string} AA-000000000 formatted string
   */
  convertToSafetynetFormat(safetynetNumber: string): string {
    let trimmed: string = (safetynetNumber || '').replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 12) {
      trimmed = trimmed.substring(0, 12);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 2) !== '') {
      numbers.push(trimmed.substring(0, 2));
    }
    if (trimmed.substring(2, 11) !== '') {
      numbers.push(trimmed.substring(2, 11));
    }

    safetynetNumber = numbers.join('-');

    return safetynetNumber.toUpperCase();
  }

  /**
   * @function convertToIHINumberFormat
   * @param {string} ihiNumber 0000-0000-0000-0000 formatted card number
   * @returns {string} 0000-0000-0000-0000 formatted string
   */
  convertToIHINumberFormat(ihiNumber: string): string {
    let trimmed: string = (ihiNumber || '').replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 19) {
      trimmed = trimmed.substring(0, 19);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 4) !== '') {
      numbers.push(trimmed.substring(0, 4));
    }
    if (trimmed.substring(4, 8) !== '') {
      numbers.push(trimmed.substring(4, 8));
    }
    if (trimmed.substring(8, 12) !== '') {
      numbers.push(trimmed.substring(8, 12));
    }
    if (trimmed.substring(12, 16) !== '') {
      numbers.push(trimmed.substring(12, 16));
    }

    ihiNumber = numbers.join('-');

    return ihiNumber;
  }

  validateSafetyNetInput(event: any, value: string = ''): boolean {
    // Ignore inputs in combination with Ctrl key
    if (event.ctrlKey) {
      return true;
    }

    // When user presses Backspace or Delete, and all that has been entered is 'CN-', clear the safetynet card value;
    if (
      (value.length < 4 && event.keyCode === 8) /* backspace */ ||
      (value.length < 3 && (event.keyCode === 46 /* delete */ || event.keyCode === 190)) /* keypad delete */
    ) {
      return false;
    }

    // SafetyNet card format: CN/SN-000000000
    // keyCodes: 67 = C, 99 = c, 83 = S, 115 = s
    const keyPressedCS: boolean = Boolean(
      event.keyCode === 67 || // C
        event.keyCode === 99 || // c
        event.keyCode === 83 || // S
        event.keyCode === 115 // s
    );
    const incompletePrefix: boolean = !value || value.length < 3;

    if (incompletePrefix) {
      if (keyPressedCS && !value.startsWith('C') && !value.startsWith('S')) {
        return true;
      }
      return false;
    } else {
      return this.validateNumber(event);
    }
  }

  isNameValid(name: string): boolean {
    if (!name?.length) {
      return true;
    }

    return name.length > 1 && !Constants.specialCharactersNameRegExp.test(name);
  }

  checkDummyPatient(patient: Patient): boolean {
    let isDummy: boolean = true;

    // if no patient is provided, isDummy is returned as true
    if (patient && patient.patientId) {
      isDummy = this.checkDummyPatientById(patient.patientId);
    }

    return isDummy;
  }

  checkDummyPatientById(patientId: string): boolean {
    let isDummy: boolean = true;
    const dummyPatientsId: string[] = ['0', '1', '2', '3', '4', Constants.BlankGUID];

    for (let index = 0; index < dummyPatientsId.length; index++) {
      const pId: string = dummyPatientsId[index];
      if (pId === patientId) {
        isDummy = true;
        break;
      } else {
        isDummy = false;
      }
    }

    return isDummy;
  }

  getStoredPatientId(): string {
    try {
      const patientId: string = sessionStorage.getItem(Constants.LocalStorage_Key.patientId);

      if (patientId) {
        return JSON.parse(patientId);
      }
    } catch (_err: any) {}

    return null;
  }

  handleError(error: any): Promise<any> {
    try {
      const hasTokenExpired: boolean = this.hasTokenExpired(error);
      if ((error && error.status === 401) || hasTokenExpired) {
        // Session expired. Fail silently (this will be handled up the chain, within the HTTP Interceptor)
        return Promise.resolve(null);
      }
    } catch (_err: any) {}

    return Promise.reject(error);
  }

  handleErrorAndRedirect(error: any): Promise<any> {
    try {
      if (this.isTokenInvalid(error) /* || this.hasTokenExpired(error)*/) {
        this.navigateToLogin();
        return null;
      }
    } catch (_err: any) {}

    return error;
  }

  handleKeyPress($event: any, focusTarget?: any): void {
    // Ignore inputs in combination with Ctrl key
    if (!$event.ctrlKey && this.isTabOrEnter($event)) {
      $event.preventDefault();

      if (focusTarget) {
        try {
          focusTarget.focus();
        } catch (err: any) {
          console.log('Cannot focus on target: ', focusTarget);
        }
      }
    }
  }

  hasTokenExpired(response: any): boolean {
    if (!response) {
      return false;
    }

    try {
      const code: string = this.getErrorCode(response);
      if (code) {
        return (
          [
            // Constants.API_ERROR_CODES.INVALID_TOKEN,
            Constants.API_ERROR_CODES.TOKEN_EXPIRED,
            Constants.API_ERROR_CODES.USER_NOT_AUTHORIZED_FOR_RESOURCE
          ].indexOf(code) !== -1
        );
      } else {
        return false;
      }
    } catch (_err: any) {
      return false;
    }
  }

  isTokenInvalid(response: any): boolean {
    if (!response) {
      return false;
    }
    return this.getErrorCode(response) === Constants.API_ERROR_CODES.INVALID_TOKEN;
  }

  /**
   * @function navigateToLogin
   * @description If we're not on the login or signup page, navigate to the login page and add
   * the current path to redirect back to
   *
   * @param {boolean} [noRedirect=false] whether to add the current path to redirect to after login
   */
  navigateToLogin(noRedirect: boolean = false, state?: RouterStateSnapshot): void {
    let url: string = state?.url || this.router.routerState.snapshot.url;
    // const url: string | UrlTree = this.router.parseUrl(window.location.pathname + (window.location.search?.length ? '?' + window.location.search : ''));
    const isLoginPage: boolean = url.startsWith(environment.loginUrl);

    if (!isLoginPage) {
      if (url.startsWith('/app/')) {
        url = url.substring(4);
      }
      const redirect: string = url.startsWith('/' + STEP_PATH.SIGN_UP) ? STEP_PATH.DASHBOARD : url;

      if (redirect && !noRedirect) {
        // this.router.navigateByUrl(environment.loginUrl + '?redirect=' + redirect);
        this.router.navigate([environment.loginUrl], {
          queryParams: { redirect }
        });
        // this.router.navigate([environment.loginUrl], {
        //   queryParams: { redirect },
        //   replaceUrl: true
        // });
      } else {
        this.router.navigate([environment.loginUrl]);
      }
    }
  }

  /**
   * @function deepCompare
   * @description Do a deep comparison of two objects and return true if they have the same properties and values
   *
   * @param {any} a Object A
   * @param {any} b Object B
   *
   * @returns {boolean} true if Object A and Object B are identical, otherwise false
   */
  deepCompare(a: any, b: any): boolean {
    if (a === b) {
      return true;
    }
    if (a instanceof Object && b instanceof Object) {
      // Check object type
      if (a.constructor !== b.constructor) {
        return false;
      }
      // Check each property in Object A exists in Object B
      for (const key in a) {
        if (a.hasOwnProperty(key)) {
          // If property exists, recursively call deepCompare on the values of that property in each object
          if (!b.hasOwnProperty(key) || !this.deepCompare(a[key], b[key])) {
            return false;
          }
        }
      }
      // Check each property in Object B exists in Object A
      for (const key in b) {
        if (b.hasOwnProperty(key) && !a.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  /**
   * @function deepCompare
   * @description Do a deep comparison of two objects and return true if they have the same properties and values.
   * Falsies will be treated as equal.
   *
   * @param {any} a Object A
   * @param {any} b Object B
   *
   * @returns {boolean} true if Object A and Object B are identical, otherwise false
   */
  deepCompareEqualizeFalsy(a: any, b: any): boolean {
    if (!a && !b) {
      return true;
    }

    if (a === b) {
      return true;
    }
    if (a instanceof Object && b instanceof Object) {
      // Check object type
      if (a.constructor !== b.constructor) {
        return false;
      }
      // Check each property in Object A exists in Object B
      for (const key in a) {
        if (a.hasOwnProperty(key)) {
          // If property exists, recursively call deepCompare on the values of that property in each object
          if (!b.hasOwnProperty(key) || !this.deepCompareEqualizeFalsy(a[key], b[key])) {
            return false;
          }
        }
      }
      // Check each property in Object B exists in Object A
      for (const key in b) {
        if (b.hasOwnProperty(key) && !a.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  /**
   * @function checkIfObjectIsEmpty
   * @description Check that an object has one or more keys
   *
   * @param {Object} obj
   *
   * @returns the original object (if not empty), otherwise null
   */
  checkIfObjectIsEmpty(obj: any): any {
    return obj && typeof obj === 'object' && Object.keys(obj).length ? obj : null;
  }

  /**
   * @function removeEmpty
   * @description Parse an object, removing any properties with a null or undefined value
   *
   * @param {any} obj Any Object
   *
   * @returns {any} Input Object with its null and undefined properties removed
   */
  removeEmpty(obj: any): any {
    return Object.entries(obj).reduce(
      (a, [k, v]) => (v === null || typeof v === 'undefined' ? a : ((a[k] = v), a)),
      {}
    ) as any;
  }

  /**
   * @function removeUndefined
   * @description Parse an object, removing any properties with an undefined value
   *
   * @param {any} obj Any Object
   *
   * @returns {any} Input Object with its undefined properties removed
   */
  removeUndefined(obj: any): any {
    return Object.entries(obj).reduce((a, [k, v]) => (typeof v === 'undefined' ? a : ((a[k] = v), a)), {}) as any;
  }

  /**
   * @function validateNumber
   * @description Validate a number as you type. Delete, backspace and tab characters are also allowed
   * If a typed character is not in the allowed list, 'false' is returned and that input is prevented
   *
   * @param {Event} event
   * @param {boolean} [allowPlus=false] Allow '+' as valid input
   *
   * @returns {boolean}
   */
  validateNumber(event: any, allowPlus: boolean = false): boolean {
    // Ignore inputs in combination with Ctrl key
    if (event.ctrlKey) {
      return true;
    }

    return (
      (event.keyCode >= 35 /*48*/ && event.keyCode <= 57) /* numbers, arrows, home/end/insert */ ||
      (event.keyCode >= 96 && event.keyCode <= 105) /* keypad numbers */ ||
      event.keyCode === 8 /* backspace */ ||
      event.keyCode === 46 /* delete */ ||
      event.keyCode === 190 /* keypad delete */ ||
      this.isTabOrEnter(event) ||
      (allowPlus && ((event.shiftKey && event.keyCode === 187) || event.keyCode === 107))
    ); /* plus, keypad plus */
  }

  updateAttachmentSubType(attachments: Attachment[]): Attachment[] {
    let allAttachments: Attachment[] = attachments || [];

    allAttachments.forEach((attachment: Attachment) => {
      attachment.dateCreated = new Date(attachment.dateCreated);

      if (attachment.attachmentSubType) {
        switch (attachment.attachmentSubType) {
          case 'MessageImagingRequest':
            attachment.attachmentSubType = 'Imaging Request';
            break;
          case 'MessagePathologyRequest':
            attachment.attachmentSubType = 'Pathology Request';
            break;
          case 'MessageImagingResult':
            attachment.attachmentSubType = 'Imaging Results';
            break;
          case 'MessagePathologyResult':
            attachment.attachmentSubType = 'Pathology Results';
            break;
          default:
            attachment.attachmentSubType = attachment.attachmentSubType.replace('Message', '');
            break;
        }
      }

      attachment.attachmentFileName = attachment.attachmentFileName.replace('MessageAttachment_Message', '');
    });

    return allAttachments;
  }

  formatMobilePhoneNumber(mobilePhoneNumber: string): string {
    let mobileNumber: string = (mobilePhoneNumber || '').replace(' ', '').replace('-', '');

    // Format numbers in the form: 444555777
    if (mobileNumber.length === 9 && mobileNumber.startsWith('4')) {
      mobileNumber = '+61'.concat(mobileNumber);
      // Format numbers in the form: +610444555777
    } else if (mobileNumber.startsWith('+610')) {
      mobileNumber = mobileNumber.replace('+610', '+61');
      // Format numbers in the form: 61444555777
    } else if (mobileNumber.startsWith('614')) {
      mobileNumber + '+'.concat(mobileNumber);
      // Format numbers in the form: 610444555777
    } else if (mobileNumber.startsWith('610')) {
      mobileNumber = '+61'.concat(mobileNumber.substring(3));
      // Format numbers in the form: 0444555777
    } else if (mobileNumber.startsWith('04')) {
      mobileNumber = '+614'.concat(mobileNumber.substring(2));
    }

    return mobileNumber;
  }

  isTabOrEnter(event: any): boolean {
    return Boolean(
      event && (event.keyCode === 13 || event.keyCode === 9 || event.key === 'Enter' || event.key === 'Tab')
    );
  }

  parseAppointmentReason(params: ParamMap): string {
    let appointmentReason: string = params?.get('reason')?.trim();

    if (appointmentReason?.length) {
      switch (appointmentReason) {
        case 'prescription':
          appointmentReason = Labels.prescriptionRequest;
          break;
        case 'referral':
          appointmentReason = Labels.specialistReferral;
          break;
        case 'medical certificate':
          appointmentReason = Labels.medicalCertificateRequest;
          break;
        case 'pathology':
          appointmentReason = Labels.pathologyAppointment;
          break;
        case 'quit smoking':
          appointmentReason = Labels.quitSmokingAppointment;
          break;
        case Labels.generalWeightLoss:
          appointmentReason = Labels.generalWeightLossAppointment;
          break;
        case Labels.medicatedWeightLoss:
          appointmentReason = Labels.medicatedWeightLossAppointment;
          break;
      }
    }

    return appointmentReason;
  }

  getTimeString(date: Date = new Date(), offset: string): string {
    const momentDate: moment.Moment = moment(date);

    if (momentDate.isValid()) {
      const timezoneOffset: string = offset ? offset : String(Constants.Default_TimeZone_Offset);
      const numericTimezoneOffset: number = parseFloat(timezoneOffset);

      return momentDate.utcOffset(numericTimezoneOffset).format(Constants.TimeFormat_24Hour);
    } else {
      return null;
    }
  }

  /**
   * @function isRecent
   * @description Check whether the timestamp for the last API execution
   * occurred within a set time period (eg. within the last 5 seconds)
   *
   * @param {Date} date
   *
   * @returns {boolean} true if timestamp is within the specified time period
   */
  isRecent(date: Date, seconds: number): boolean {
    if (date && date instanceof Date) {
      return (new Date().valueOf() - date.valueOf()) / Constants.MILLISECONDS_IN_SECOND <= seconds;
    }

    return false;
  }

  removeCountryFromTimeZone(label: string): string {
    if (label?.length) {
      // Shorten the timezone name by removing country
      return String(label).replace(', Australia', '').replace(', New Zealand', '');
    }

    return '';
  }

  /**
   * @function formatDateYearFirst
   * @description Format a string date that is either in MM-DD-YYYY or YYYY-MM-DD format
   * @param {string} date
   * @returns {string|null} formatted date (YYYY-MM-DD)
   */
  formatDateYearFirst(date: string): string {
    const yearFirst: boolean = date && Constants.YearFirstRegex.test(date);
    const dateOfBirth: string = yearFirst
      ? moment(date).format(Constants.YearFirstDate)
      : moment(date, Constants.YearLastUSDate).isValid()
        ? moment(date, Constants.YearLastUSDate).format(Constants.YearFirstDate)
        : null;

    return dateOfBirth;
  }

  /**
   * @function formatDateYearFirstAU
   * @description Format a string date that is either in DD-MM-YYYY or YYYY-MM-DD format
   * @param {string} date
   * @returns {string|null} formatted date (YYYY-MM-DD)
   */
  formatDateYearFirstAU(date: string): string {
    const yearFirst: boolean = date && Constants.YearFirstRegex.test(date);
    const dateOfBirth: string = yearFirst
      ? moment(date).format(Constants.YearFirstDate)
      : moment(date, Constants.YearLastAUDate).isValid()
        ? moment(date, Constants.YearLastAUDate).format(Constants.YearFirstDate)
        : null;

    return dateOfBirth;
  }

  /**
   * @function getDateFromString
   * @description Parse a U.S. formatted date (in the form YYYY-MM-DD or MM-DD-YYYY) and return a Date object
   * @param {string} date a date in the format YYYY-MM-DD or MM-DD-YYYY (U.S. standard)
   * @returns {Date|null}
   */
  getDateFromString(date: string): Date {
    const yearFirst: boolean = date && Constants.YearFirstRegex.test(date);
    return yearFirst
      ? moment(date).toDate()
      : moment(date, Constants.YearLastUSDate).isValid()
        ? moment(date, Constants.YearLastUSDate).toDate()
        : null;
  }

  /**
   * @function getDateFromStringAU
   * @description Parse an Australian formatted date (in the form YYYY-MM-DD or DD-MM-YYYY) and return a Date object
   * @param {string} date a date in the format YYYY-MM-DD or DD-MM-YYYY (Australian/British standard)
   * @returns {Date|null}
   */
  getDateFromStringAU(date: string): Date {
    const yearFirst: boolean = date && Constants.YearFirstRegex.test(date);
    return yearFirst
      ? moment(date, Constants.YearFirstDate).toDate()
      : moment(date, Constants.YearLastAUDate).isValid()
        ? moment(date, Constants.YearLastAUDate).toDate()
        : null;
  }

  /**
   * @function isLocaleDateValid
   * @description Check whether the input is a valid date object or use regular expressions to check
   * if a string date conforms to localised (Australian) date format.
   * @param {string|Date} date
   * @returns {boolean}
   */
  isLocaleDateValid(date: any): boolean {
    let finalDate: Date = null;

    if (date && date instanceof Date) {
      return true;
    }

    try {
      const dateString: string = String(date);
      if (
        dateString.length >= 8 &&
        (Constants.DateYearFirstRegex.test(dateString) || Constants.DateAURegex.test(dateString))
      ) {
        finalDate = this.getDateFromStringAU(this.formatDateYearFirstAU(dateString));
      }
    } catch (_err) {
      // fail silently
    }

    return !!finalDate;
  }

  getTodayStart(): Date {
    return moment().startOf('day').toDate();
    // return new Date(new Date().setHours(0, 0, 0, 0));
  }

  getEndOfDay(date: Date = new Date()): Date {
    return moment(date).endOf('day').toDate();
  }

  addToDate(
    date: Date = null,
    years: number = 0,
    months: number = 0,
    days: number = 0,
    hours: number = 0,
    minutes: number = 0
  ): Date {
    const startDate: Date = date || new Date();

    return moment(startDate)
      .add(years, 'years')
      .add(months, 'months')
      .add(days, 'days')
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .toDate();
  }

  getDate_UTC_Zero(date: Date = new Date()): moment.Moment {
    return moment(date).utcOffset(0);
  }

  /**
   * @function getUTCMoment
   * @description Return a Moment date based on provided timezone offset and optional time alterations
   *
   * @param {string|number} [timeZoneOffset=10] (Optional) Timezone offset string or number. eg. "-8" or -8.
   * Defaults to Brisbane timezone (GMT+10)
   * @param {boolean} [isDayStart=false] (Optional) Set time to start of day. ie. 00:00:00
   * @param {boolean} [isDayEnd=false] (Optional) Set time to end of day. ie. 23:59:59
   * @param {moment.Moment} [seedDate] (Optional) Set initial date to start with
   * @param {AppointmentTimeOfDay} [timeOfDay='All'] (Optional) Set the time of day ('Morning', 'Afternoon', 'Evening', 'All')
   *
   * @returns {moment.Moment} Moment object (Moment wrapped date)
   */
  getUTCMoment(
    timeZoneOffset: string | number = Constants.Default_TimeZone_Offset,
    isDayStart: boolean = false,
    isDayEnd: boolean = false,
    seedDate?: string | moment.Moment,
    timeOfDay: string = AppointmentTimeOfDay.All
  ): moment.Moment {
    // Make sure timezone offset is a String (ie. convert 10 to "10")
    const timeZoneString: string = String(timeZoneOffset);
    const tzOffset: number = parseFloat(timeZoneString);

    if (typeof seedDate === 'string' && seedDate.indexOf('%') > 0) {
      seedDate = this.formatAPIStringForMoment(seedDate);
    }

    // Get exact date string using the specified TimeZone offset (for the current DateTime)
    // If seedDate is 'undefined', today's date will be used
    const stringDate: string = moment(seedDate || undefined)
      .utcOffset(tzOffset)
      .format(Constants.UTC_Date_Format_Shortened);

    let momentDateUTC: moment.Moment = moment(stringDate);

    // If time of day param is supplied, set the appropriate hours/minutes/seconds based on the time of day
    if (timeOfDay && timeOfDay != AppointmentTimeOfDay.All) {
      if (isDayStart) {
        momentDateUTC = moment(stringDate)
          .utcOffset(tzOffset)
          .set('hours', this.getMinHour(timeOfDay))
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0);
      } else if (isDayEnd) {
        momentDateUTC = moment(stringDate)
          .utcOffset(tzOffset)
          .set('hours', this.getMaxHour(timeOfDay))
          .set('minutes', 59)
          .set('seconds', 59)
          .set('milliseconds', 0);
      }
    } else if (isDayStart) {
      if (typeof seedDate === 'string' && seedDate.length === 10) {
        // only availability days
        const stringDateWithoutTimezone: string = seedDate + 'T00:00:00' + this.getTimeZoneOffsetString(timeZoneString);
        momentDateUTC = moment(stringDateWithoutTimezone);
      } else {
        momentDateUTC = moment(stringDate).utcOffset(tzOffset).startOf('day');
      }
    } else if (isDayEnd) {
      if (typeof seedDate === 'string' && seedDate.length === 10) {
        // only availability days
        const stringDateWithoutTimezone: string = seedDate + 'T23:59:59' + this.getTimeZoneOffsetString(timeZoneString);
        momentDateUTC = moment(stringDateWithoutTimezone);
      } else {
        momentDateUTC = moment(stringDate).utcOffset(tzOffset).endOf('day');
      }
    }

    return momentDateUTC;
  }

  /**
   * @function getUTCMomentString
   * @description Return a string representation of a Moment date based on provided timezone offset
   * and optional time alterations
   *
   * @param {string|number} [timeZoneOffset=10] (Optional) Timezone offset string or number. eg. "-8" or -8.
   * Defaults to Brisbane timezone (GMT+10)
   * @param {boolean} [isDayStart=false] (Optional) Set time to start of day. ie. 00:00:00
   * @param {boolean} [isDayEnd=false] (Optional) Set time to end of day. ie. 23:59:59
   * @param {string|moment.Moment} [seedDate] (Optional) Set initial date to start with
   * @param {boolean} [returnZeroTimezone=false] (Optional) true if you want the returned timezone
   * to be +00:00 rather than the one specified with the timeZoneOffset parameter
   * @param {AppointmentTimeOfDay} [timeOfDay] (Optional) Set the time of day ('Morning', 'Afternoon', 'Evening', 'All')
   *
   * @returns {string} String representation of a UTC date in the format: YYYY-MM-DDTHH:mm:ssZ
   */
  getUTCMomentString(
    timeZoneOffset: string | number = Constants.Default_TimeZone_Offset,
    isDayStart: boolean = false,
    isDayEnd: boolean = false,
    seedDate?: string | moment.Moment,
    returnZeroTimezone: boolean = false,
    timeOfDay?: string
  ): string {
    return this.getUTCMoment(timeZoneOffset, isDayStart, isDayEnd, seedDate, timeOfDay)
      .utcOffset(returnZeroTimezone ? 0 : parseFloat(String(timeZoneOffset)))
      .format(Constants.UTC_Date_Format_Shortened);
  }

  getDateOnlyString(input: string | moment.Moment, timezoneOffset: string): string {
    const offset: number = timezoneOffset?.length ? parseFloat(timezoneOffset) : Constants.Default_TimeZone_Offset;

    return moment(input).utcOffset(offset).format(Constants.YearFirstDate);
  }

  getDateOnlyStringNoTimezoneOffset(dateString: string): string {
    return moment(dateString).format(Constants.YearFirstDate);
  }

  getDateOnlyMoment(input: string, timezoneOffset?: string): moment.Moment {
    return moment.utc(moment(input).utcOffset(timezoneOffset).format(Constants.YearFirstDate));
  }

  appendZeroTimezoneIfMissing(inputDate: string): string {
    if (inputDate && !(inputDate.endsWith('Z') || inputDate.endsWith('+00:00'))) {
      inputDate = inputDate.trim().substring(0, 19).replace(' ', 'T') + 'Z';
    }
    return inputDate;
  }

  /**
   * @function getAvailabilityDateParams
   * @description Return availability start and end dates as Moment dates
   *
   * @param {string} timezoneOffset timezone offset. eg. "-6", "0", "11"
   * @param {string} startDate any YYYY-MM-DD formatted date string to be used as a seed start date
   * @param {string} endDate any YYYY-MM-DD formatted date string to be used as a seed end date
   * @param {boolean} [forceZeroTimeStartDate=false] The output date always has it's time component
   * set to 00:00:00 unless that date is Today. Force setting time to zero even for today's date.
   *
   * @returns {AvailabilityDateRangeStrings} startDatePatientLocal and endDatePatientLocal as Moment dates
   */
  getAvailabilityDateParams(
    timezoneOffset: string,
    startDate?: string,
    endDate?: string,
    forceZeroTimeStartDate: boolean = false
  ): AvailabilityDateRange {
    const numericTimezoneOffset: number = timezoneOffset
      ? parseFloat(timezoneOffset)
      : Constants.Default_TimeZone_Offset;

    const now: moment.Moment = this.getUTCMoment(timezoneOffset);

    const startDayIsToday: boolean =
      now.clone().utcOffset(numericTimezoneOffset).format(Constants.YearFirstDate) === startDate.substring(0, 10);

    const startDatePatientLocal: moment.Moment = this.getUTCMoment(
      timezoneOffset,
      forceZeroTimeStartDate ? true : !startDayIsToday, // 00:00:00
      false, // 23:59:59
      startDayIsToday ? undefined : startDate // startDateSeed
    );

    const endDatePatientLocal: moment.Moment = this.getUTCMoment(
      timezoneOffset,
      false, // 00:00:00
      true, // 23:59:59
      endDate // endDateSeed
    );

    return { startDatePatientLocal, endDatePatientLocal, startDayIsToday };
  }

  /**
   * @function getAvailabilityDateParamsAsString
   * @description Return availability start and end dates as formatted and URI encoded strings
   *
   * @param {string} timezoneOffset timezone offset. eg. "-6", "0", "11"
   * @param {string} startDate any YYYY-MM-DD formatted date string to be used as a seed start date
   * @param {string} endDate any YYYY-MM-DD formatted date string to be used as a seed end date
   * @param {boolean} [forceZeroTimeStartDate=false] The output date always has it's time component
   * set to 00:00:00 unless that date is Today. Force setting time to zero even for today's date.
   *
   * @returns {AvailabilityDateRangeStrings} startDatePatientLocal and endDatePatientLocal as strings
   */
  getAvailabilityDateParamsAsString(
    timezoneOffset: string,
    startDate?: string,
    endDate?: string,
    forceZeroTimeStartDate: boolean = false
  ): AvailabilityDateRangeStrings {
    const params: AvailabilityDateRange = this.getAvailabilityDateParams(
      timezoneOffset,
      startDate,
      endDate,
      forceZeroTimeStartDate
    );

    return {
      startDatePatientLocal: this.getUTCMomentString(timezoneOffset, false, false, params.startDatePatientLocal),
      endDatePatientLocal: this.getUTCMomentString(timezoneOffset, false, false, params.endDatePatientLocal),
      startDayIsToday: params.startDayIsToday
    } as AvailabilityDateRangeStrings;
  }

  /**
   * @function formatMomentForAPI
   * @description Format a Moment object into a URI encoded string date
   *
   * @param {moment.Moment} date
   * @param {string} [timezoneOffset]
   * @param {boolean} [isUtc=false]
   *
   * @returns {string} URI encoded ISO date string
   */
  formatMomentForAPI(date: moment.Moment, timezoneOffset?: string, isUtc: boolean = false): string {
    const offset: number = timezoneOffset?.length ? parseFloat(timezoneOffset) : null;

    if (offset !== null) {
      return this.formatStringDateForAPI(date.clone().utcOffset(offset).format(Constants.UTC_Date_Format_Shortened));
    } else if (isUtc) {
      return this.formatStringDateForAPI(moment.utc(date).format(Constants.UTC_Date_Format_Shortened));
    } else {
      return this.formatStringDateForAPI(date.format(Constants.UTC_Date_Format_Shortened));
    }
  }

  /**
   * @function formatStringDateForAPI
   * @description Encode date string (convert + to %2B)
   *
   * @param {string} stringDate
   *
   * @returns {string} An ISO date string with URI encoding (for use with API)
   */
  formatStringDateForAPI(stringDate: string): string {
    return stringDate?.replace('+', '%2B');
  }

  /**
   * @function formatAPIStringForMoment
   * @description Decode date string (convert %2B to +)
   *
   * @param {string} stringDate
   *
   * @returns {string} An ISO date string without URI encoding
   */
  formatAPIStringForMoment(stringDate: string): string {
    return stringDate?.replace('%2B', '+');
  }

  calculateAppointmentCountdown(appointmentData: Appointment): Appointment {
    let data: Appointment = appointmentData;

    // time difference
    const timeDifference: number = data.startTime.getTime() - new Date().getTime();

    let secondsToDday: string = Math.floor(
      (timeDifference / Constants.MILLISECONDS_IN_SECOND) % Constants.SECONDS_IN_MINUTE
    ).toString();
    secondsToDday = secondsToDday.length == 1 ? '0' + secondsToDday : secondsToDday;

    let minutesToDday: string = Math.floor(
      (timeDifference / (Constants.MILLISECONDS_IN_SECOND * Constants.MINUTES_IN_HOUR)) % Constants.SECONDS_IN_MINUTE
    ).toString();
    minutesToDday = minutesToDday.length == 1 ? '0' + minutesToDday : minutesToDday;

    const hoursToDday: number = Math.floor(
      (timeDifference / (Constants.MILLISECONDS_IN_SECOND * Constants.MINUTES_IN_HOUR * Constants.SECONDS_IN_MINUTE)) %
        Constants.HOURS_IN_DAY
    );

    const daysToDday: number = Math.floor(
      timeDifference /
        (Constants.MILLISECONDS_IN_SECOND *
          Constants.MINUTES_IN_HOUR *
          Constants.SECONDS_IN_MINUTE *
          Constants.HOURS_IN_DAY)
    );

    // Seny - commenting this out, since removing 'days' does not improve visual accessibility
    // let countdownString: string = hoursToDday + ':' + minutesToDday + ':' + secondsToDday;
    // if (daysToDday > 0) {
    //   countdownString = daysToDday + ' day' + (daysToDday === 1 ? '' : 's') + ', ' + countdownString;
    // }
    // data.appointmentTimediffString = countdownString;

    const countdownString: string =
      daysToDday +
      ' day' +
      (daysToDday === 1 ? '' : 's') +
      ', ' +
      hoursToDday +
      ':' +
      minutesToDday +
      ':' +
      secondsToDday;
    data.appointmentTimediffString = countdownString;

    const timeDiffInMinutes: number = timeDifference / Constants.MILLISECONDS_IN_SECOND / Constants.SECONDS_IN_MINUTE;
    data.appointmentTimediff = timeDiffInMinutes;

    return data;
  }

  showToast(message: string, duration: number = 5): void {
    if (!this._isSnackbarActive) {
      this._snackbar
        .open(message, 'Close', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: duration * Constants.MILLISECONDS_IN_SECOND
        })
        .afterDismissed()
        .subscribe((info) => {
          this._isSnackbarActive = false;
        });
    }
  }

  getRandomNumberString(): string {
    return String(Math.random()).substring(2);
  }

  cacheBuster(input: string): string {
    let hash: string = '';

    if (input.indexOf('?') === -1) {
      hash = '?';
    } else {
      hash = '&';
    }

    return input + hash + 'dodxv=' + this.getRandomNumberString();
  }

  getTimeZoneOffsetString(offsetStringInitial: string): string {
    if (!offsetStringInitial) {
      return '';
    }

    const offset: number = parseFloat(offsetStringInitial);
    const integerOffset: number = parseInt(offsetStringInitial);

    let offsetText: string;

    // Is there a decimal point in the offset?
    if (offset > integerOffset) {
      offsetText = offset < 0 ? '-' : '+';
      offsetText += String(Math.abs(integerOffset)).padStart(2, '0');
      offsetText += offsetStringInitial.substring(offsetStringInitial.indexOf('.'));
    } else {
      // No decimal, just pad the integer value with a '0'
      if (offset < 0) {
        offsetText = '-'.concat(offsetStringInitial.substring(1).padStart(2, '0'));
      } else {
        offsetText = '+'.concat(offsetStringInitial.padStart(2, '0'));
      }
    }

    // Replace decimal values with minutes
    offsetText = offsetText.replace('.25', ':15').replace('.5', ':30').replace('.75', ':45');

    // Append minutes if missing
    if (offsetText.indexOf(':') === -1) {
      offsetText += ':00';
    }

    return offsetText;
  }

  capitalise(str: string): string {
    if (str && typeof str === 'string') {
      return str[0].toUpperCase() + str.slice(1);
    }

    return str;
  }

  getYear(dateString: any): number {
    let year: number = 0;

    try {
      year = parseInt(String(dateString).substring(0, 4));
    } catch (_err: any) {}

    return year;
  }

  returnToPreviousRoute(withMessage?: string): void {
    const currentRoute: Navigation = this.router.getCurrentNavigation();
    const lastUrl: string | UrlTree = currentRoute?.previousNavigation?.finalUrl
      ? currentRoute.previousNavigation.finalUrl
      : '/' + STEP_PATH.DASHBOARD;

    this.router.navigateByUrl(lastUrl, { replaceUrl: true }).then(() => {
      if (withMessage && withMessage.length) {
        this.showToast(withMessage);
      }
    });
  }

  handleAPIResponseError(error: any): APIResponseError {
    const isHttpError: boolean = error?.name === 'HttpErrorResponse';
    const isUnspecifiedError: boolean = error?.status === 0;
    const errorCode: string = this.getErrorCode(error);
    const errorSubCode: string = this.getErrorSubCode(error) || '';
    const errorMessage: string = this.getErrorMessage(error);

    return { isHttpError, isUnspecifiedError, errorCode, errorSubCode, errorMessage } as APIResponseError;
  }

  getErrorCode(errObject: any): string {
    let code: string = null;

    if (errObject && typeof errObject === 'object') {
      if (errObject?.error?.error?.code) {
        code = errObject.error.error.code;
      } else if (errObject?.error?.code) {
        code = errObject.error.code;
      } else if (errObject.code) {
        code = errObject.code;
      }
    }

    return code;
  }

  getErrorSubCode(errObject: any): string {
    let subCode: string = null;

    if (errObject && typeof errObject === 'object') {
      if (errObject.error && errObject.error.error && errObject.error.error.subCode) {
        subCode = errObject.error.error.subCode;
      } else if (errObject.error && errObject.error.subCode) {
        subCode = errObject.error.subCode;
      } else if (errObject.subCode) {
        subCode = errObject.subCode;
      }
    }

    return subCode;
  }

  getErrorMessage(errObject: any): string {
    let msg: string = Labels.serverError;

    if (errObject) {
      if (typeof errObject === 'string') {
        msg = errObject;
      } else if (typeof errObject === 'object') {
        if (errObject.error && errObject.error.modelState) {
          msg = Object.values(errObject.error.modelState).join(', ');
        } else if (errObject.error && errObject.error.error && errObject.error.error.codeDescription) {
          if (errObject.error.error.subCodeDescription) {
            msg = `${errObject.error.error.codeDescription} ${errObject.error.error.subCodeDescription}`;
          } else {
            msg = errObject.error.error.codeDescription;
          }
        } else if (errObject.error && errObject.error.codeDescription) {
          if (errObject.error.subCodeDescription) {
            msg = `${errObject.error.codeDescription} ${errObject.error.subCodeDescription}`;
          } else {
            msg = errObject.error.codeDescription;
          }
        } else if (errObject.codeDescription) {
          if (errObject.subCodeDescription) {
            msg = `${errObject.codeDescription} ${errObject.subCodeDescription}`;
          } else {
            msg = errObject.codeDescription;
          }
        } else if (errObject.error && errObject.error.error && errObject.error.error.message) {
          msg = errObject.error.error.message;
        } else if (errObject.error && errObject.error.message) {
          msg = errObject.error.message;
        } else if (errObject.message) {
          msg = errObject.message;
        }
      }
    }

    if (
      msg === Constants.API_ERROR_CODES.API_SERVER_500 ||
      msg.indexOf(Constants.API_ERROR_CODES.API_SERVER_500_ALT) === 0
    ) {
      return 'Invalid response from the server, please try again in a few minutes.';
    }

    return msg;
  }

  getAppointmentType(type?: string): string {
    switch (type) {
      case Constants.SERVICE_TYPE.MENTAL_HEALTH:
        return 'Mental Health';
      case Constants.SERVICE_TYPE.PSYCHOLOGY:
        return 'Psychologist';
      case Constants.SERVICE_TYPE.DIETITIAN:
        return 'Dietitian';
      case Constants.SERVICE_TYPE.WELLNESS:
        return 'Wellness Specialist';
      case Constants.SERVICE_TYPE.SLEEP_SPECIALIST:
        return 'Sleep Physician';
      default:
        return 'Doctor';
    }
  }

  /**
   * @function sanitiseMedicationName
   * @description Sanitise (encode) or decode a medication name for use in route paths
   *
   * @param {string} medName
   * @param {boolean} [reverse=false] if reverse is true, HTTP decode instead of HTTP encode the medName parameter
   *
   * @returns {string}
   */
  sanitiseMedicationName(medName: string, reverse: boolean = false): string {
    if (!medName?.length) {
      return medName;
    }

    if (reverse) {
      return medName.replace('%2F', '/');
    } else {
      return medName.replace('/', '%2F');
    }
  }

  getDistanceText(km?: number): string {
    if (typeof km === 'number') {
      return ' (' + Math.round(km * 10) / 10 + 'km)';
    }
    return '';
  }

  /**
   * @function getCurrencyString
   * @description Transform a numeric value into currency output
   *
   * @param {number} price
   * @param {CurrencyPipe} currencyPipe
   *
   * @returns {string} eg. $38.00
   */
  getCurrencyString(price: number, currencyPipe: any): string {
    return currencyPipe.transform(price ?? 0, 'USD', 'symbol', '1.2-2');
  }

  getModalConfig(customModalWidth?: ModalWidthSpec): MatDialogConfig {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true; // disable closing on Escape and body click
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'custom-modalbox';

    // Modal width overrides
    if (customModalWidth) {
      dialogConfig.width = this.checkTablet()
        ? customModalWidth.tablet || customModalWidth.mobile
        : this.checkMobile()
          ? customModalWidth.mobile
          : customModalWidth.desktop;
    }

    // Modal will be centered on the screen unless we manually set it to appear at the top of the page
    dialogConfig.position = { top: '0px' };

    if (this.checkMobile()) {
      dialogConfig.backdropClass = 'g-transparent-backdrop';
    }

    dialogConfig.data = {};

    return dialogConfig;
  }

  getMinHour(time: string): number {
    switch (time) {
      case 'Morning':
        return 0;
      case 'Afternoon':
        return 12;
      case 'Evening':
        return 18;
      default:
        return 0;
    }
  }

  getMaxHour(time: string): number {
    switch (time) {
      case 'Morning':
        return 11;
      case 'Afternoon':
        return 17;
      case 'Evening':
        return 23;
      default:
        return 23;
    }
  }

  roundToOneDecimal(numericValue: number): any {
    if (typeof numericValue !== 'number') {
      return numericValue;
    }

    return Math.round(numericValue * 10) / 10;
  }

  /**
   * @function getMapKeyByValue
   * @description Retrieve the key from a Map based on the value. Only accepts maps with string keys.
   *
   * @param {Map<string, string>} myMap
   * @param {string} targetValue
   *
   * @returns {string}
   */
  getMapKeyByValue(myMap: Map<string, string>, targetValue: string): string {
    for (let [key, value] of myMap.entries()) {
      if (value === targetValue) {
        return key;
      }
    }

    return null;
  }

  /**
   * @function cookieToRecordObject
   * @description Convert document.cookie key-value pairs into a Record object
   *
   * @param {string} cookie
   *
   * @returns {Record<string, string>}
   */
  cookieToRecordObject(cookie: string): Record<string, string> {
    let obj: any;

    if (typeof cookie === 'string' && cookie.length) {
      obj = cookie.split('; ').reduce<any>((result: any, value: string) => {
        try {
          const firstEqualsIndex: number = value.indexOf('=');
          if (firstEqualsIndex !== -1) {
            const key: string = value.substring(0, firstEqualsIndex);
            let val: string = value.substring(firstEqualsIndex + 1);

            // Make sure value does not end with ;
            if (val.endsWith(';')) {
              val = val.substring(0, val.length - 1);
            }

            result[key] = decodeURIComponent(val);
          }
          // const k: string[] = value.split('=', 2);
          // if (k && k.length === 2) {
          //   result[k[0]] = decodeURIComponent(k[1]);
          // }
        } catch (err: any) {}

        return result;
      }, {});
    }

    if (obj && Object.keys(obj).length) {
      return obj;
    }

    return null;
  }

  triggerResizeEvent(): void {
    try {
      if (window?.innerWidth < Constants.SCREEN_SIZE.desktop) {
        window.dispatchEvent(new Event('resize'));
        // console.log('Resize event dispatched!');
      }
    } catch (err: any) {
      console.log('Unable to fire a resize event! Error:', this.getErrorMessage(err));
    }
  }

  getFriendlyServiceType(serviceType: string = Constants.SERVICE_TYPE.DOCTOR): string {
    switch (serviceType) {
      case Constants.SERVICE_TYPE.MENTAL_HEALTH: // 'mentalhealth':
        return 'Mental Health';
      case Constants.SERVICE_TYPE.WEIGHT_LOSS: // 'weightloss':
        return 'Weight Loss';
      default:
        return serviceType;
    }
  }

  throttle(fn: Function, delay: number = 500): any {
    let lastCalled: number = 0;

    return () => {
      let now: number = new Date().getTime();
      if (now - lastCalled < delay) {
        return;
      }
      lastCalled = now;
      return fn();
    };
  }

  debounce(fn: Function, timeout: number = 300): any {
    let timer: any;

    return () => {
      if (!timer) {
        // fn.apply(this, args);
        fn();
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }

  /**
   * @function roundToOneDP
   * @description Round a number (or number in a string) to one decimal place. ie. 35.6
   *
   * @param {string|number} input
   *
   * @returns {number} the input rounded to one decimal place
   */
  roundToOneDP(input: string | number): number {
    return Math.round(parseFloat(String(input)) * 10) / 10;
  }

  /**
   * @function roundToXDecimalPlaces
   * @description Round a number (or number in a string) to one decimal place. ie. 35.6
   *
   * @param {number} value
   * @param {number} [decimalPlaces=2] number of digits to round to
   *
   * @returns {number} the input rounded to X decimal places
   */
  roundToXDecimalPlaces(value: number, decimalPlaces: number = 2): number {
    value *= Math.pow(10, decimalPlaces);
    value = Math.round(value);
    value /= Math.pow(10, decimalPlaces);

    return value;
  }

  /**
   * @function rgbToHex
   * @description Convert RGB values to Hex values
   *
   * @param {number} r Red
   * @param {number} g Green
   * @param {number} b Blue
   *
   * @returns {string} output in the form #AABBCC
   */
  rgbToHex(r: number, g: number, b: number): string {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  /**
   * @function hexToRgb
   * @description Convert Hex to RGB values
   *
   * @param {string} hex
   *
   * @returns {any} output in the form { r: 11, g: 22, b: 33 }
   */
  hexToRgb(hex: string): any {
    const result: RegExpExecArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : null;
  }

  /**
   * @function calculateBMI
   * @description Calculate the Body Mass Index based on height and weight inputs
   * BMI calculation: weight / (height * height)
   * underweight: Less than 18.5
   * healthy weight: 18.5 – 24.9
   * overweight: 25 – 29.9
   * obese: 30 and over
   *
   * @param {string|number} height height in centimetres. eg. '174.5cm' or 174.5
   * @param {string|number} weight weight in kilograms. eg. '89.2kg' or 89.2
   * @param {boolean} [roundToOneDecimal=false] round the bmiValue to one decimal place
   *
   * @returns {BMICalculation} eg. { bmiLabel: 'obese', bmiValue: 32.2 }
   */
  calculateBMI(height: string | number, weight: string | number, roundToOneDecimal: boolean = false): BMICalculation {
    let label: string = 'unknown';
    let bmi: number = 0;
    let cssClass: string = '';

    try {
      const heightCentimeters: number = parseFloat(String(height).replace('cm', ''));
      const heightMeters: number = heightCentimeters / 100;
      const weightKg: number = parseFloat(String(weight).replace('kg', ''));

      if (heightMeters && weightKg) {
        bmi = weightKg / (heightMeters * heightMeters);

        if (bmi < 18.5) {
          label = 'underweight';
          if (bmi > 16.5) {
            cssClass = 'warning';
          } else {
            cssClass = 'danger';
          }
        } else if (bmi >= 18.5 && bmi < 25) {
          label = 'healthy weight';
          cssClass = 'success';
        } else if (bmi >= 25 && bmi < 30) {
          label = 'overweight';
          cssClass = 'warning';
        } else if (bmi >= 30) {
          label = 'obese';
          cssClass = 'danger';
        }
      }
    } catch (err: any) {
      console.log('BMI calculation failed. Error: ', this.getErrorMessage(err));
    }

    return {
      bmiLabel: label,
      bmiValue: roundToOneDecimal ? this.roundToOneDP(bmi) : bmi,
      cssClass
    };
  }

  getBMILabel(bmiCalculation: BMICalculation): string {
    let label: string = 'unknown';

    if (typeof bmiCalculation?.bmiValue === 'number' && bmiCalculation?.bmiValue > 0 && bmiCalculation?.bmiLabel) {
      label = bmiCalculation.bmiLabel + ' (' + String(Math.round(bmiCalculation.bmiValue * 10) / 10) + ')';
    }

    return label;
  }

  sortISO8601DateArray(array: Array<any>, dateParam: string = 'date'): Array<any> {
    return array.sort((a: any, b: any) => (a[dateParam] < b[dateParam] ? -1 : a[dateParam] > b[dateParam] ? 1 : 0));
  }

  setLocation(url: string): void {
    this.location.go(url);
  }

  overrideLocation(url: string): void {
    this.location.replaceState(url);
  }

  getLocationHashParams(): Record<string, string> {
    const map: Record<string, string> = {};

    window.location.hash
      .substring(1)
      .split('&')
      .forEach((kvp) => {
        var split = kvp.split('=');

        map[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
      });

    return map;
  }

  parseJWT<TBody>(token: string): { header: Record<string, string>; body: TBody } | null {
    try {
      const parsed: any[] = token
        .split('.')
        .slice(0, 2) // Only take the first 2 elements, as the 3rd (and last) one is the signature which isn't useful (or JSON)
        .map((part: string) => JSON.parse(atob(part)));

      return {
        header: parsed[0],
        body: parsed[1] as TBody
      };
    } catch (err: any) {
      console.log('Unable to parse token. Error:', this.getErrorMessage(err));
    }

    return null;
  }

  parseEmailFromJWT(token: string): string {
    if (!token) {
      return undefined;
    }
    const parsedToken: any = this.parseJWT<any>(token);

    return parsedToken.body.unique_name;
  }

  /* START - Functions for virtual scroll strategy */
  intersects(a: Range, b: Range): boolean {
    return (a[0] <= b[0] && b[0] <= a[1]) || (a[0] <= b[1] && b[1] <= a[1]) || (b[0] < a[0] && a[1] < b[1]);
  }

  clamp(min: number, value: number, max: number): number {
    return Math.min(Math.max(min, value), max);
  }

  isEqual<T>(a: T, b: T): boolean {
    return a === b;
  }

  last<T>(value: T[]): T {
    return value[value.length - 1];
  }
  /* END - Functions for virtual scroll strategy */

  isNullOrEmpty(input: string): boolean {
    if (typeof input === 'undefined' || input === null || input === '') {
      return true;
    }

    return false;
  }

  isNullOrWhiteSpace(input: string): boolean {
    if (this.isNullOrEmpty(input)) {
      return true;
    }

    if (typeof input !== 'string') {
      return false;
      //throw new Error('Input is not a string');
    }

    return input.trim().length === 0;
  }

  getQueryParamMap(url: string): Record<string, string> {
    const map: Record<string, string> = {};

    if (!url || url?.length === 1) {
      return map;
    }

    const query = url.split('?')[1];

    if (!query) {
      return map;
    }

    try {
      query
        .split('&')
        .map((part) => part.split('='))
        .forEach((components) => {
          map[decodeURIComponent(components[0])] = decodeURIComponent(components[1]);
        });
    } catch (err: any) {
      console.error('Error parsing query params:', this.getErrorMessage(err));
    }

    return map;
  }

  isBenefitDoDMedicareB2C(benefit?: Benefit, benefitCode?: string): boolean {
    const policyNumber: string = benefit?.name?.length ? benefit.name : benefitCode || '';

    return Boolean(policyNumber.toUpperCase().indexOf('DODMEDICARE_') !== -1);
  }

  scrollToTop(): void {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } catch (_err: any) {}
  }
}
