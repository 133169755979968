import { AppointmentStatus } from './appointmentStatus';
import { Attachment } from './attachment';
import { Practitioner } from './practitioner';
import { Prescription } from './prescription';

export class Appointment {
  appointmentId?: string;
  appointmentNumber?: string;
  followupId?: string;
  serviceType?: string;
  isOnDemand?: boolean;
  appointmentStatus?: string;
  lastClosedStatusCode?: AppointmentStatus;
  patientHasJoinedAppointment?: boolean;
  appointmentDelay?: number;
  canBeCancelled?: boolean;
  canBeRescheduled?: boolean;
  isAfterHours?: boolean;
  agencyCode?: string;
  billingType?: string;
  businessHours?: boolean;
  bulkbillAfterHours?: boolean;
  appointmentReason?: string;
  shouldLimitByAgencyCode?: boolean;

  // patient
  patientId?: string;
  patientTimezoneOffsetId?: string;
  patientTimezoneOffsetHours?: string;
  patientTimezoneLabel?: string;
  followUpPatientId?: string;
  anonymousStart?: boolean;
  doesPatientHaveMedicare?: boolean;

  // practitioner
  practitionerId?: string;
  practitioners?: Array<Practitioner>;
  practitionerName?: string;
  practitionerPic?: string;
  isPractitionerMBSCapable?: boolean;

  // pricing
  price?: number; // discounted total price (from any Appointment/Session object)
  bookingFeePrice?: number; // discounted booking fee (Appointment object returned by /api/v1/patient/{patientId}/appointments)
  bookingFee?: number; // same as bookingFeePrice (Session object returned by api/v1/availabilities)
  servicePrice?: number; // same as originalPrice (Appointment object returned by /api/v1/patient/{patientId}/appointments)
  originalPrice?: number; // servicePrice before discounts are applied (Session object returned by api/v1/availabilities)
  originalBookingFee?: number; // bookingFee before discounts are applied (calculated from ServicePrice object)

  // policy
  policyId?: string;
  policyNumber?: string;
  b2BCustomerName?: string;
  hidePolicyNumber?: boolean;

  // session time
  startTimeUTC?: string;
  startTime?: Date;
  startLocal?: string;
  start?: string;
  end?: string;
  displayedTime?: string;
  duration?: number;
  appointmentTimediff?: number;
  appointmentTimediffString?: string;

  // attachments
  attachments?: Attachment[];
  hasScript?: boolean;
  hasCertificate?: boolean;
  hasReferral?: boolean;
  prescriptions?: Prescription;
  referrals?: Attachment[];
  results?: Attachment[];
  medicalCertificates?: Attachment[];
  invoices?: Attachment[];
}
