import { Injectable } from "@angular/core";
import { Constants, PREVENT_EXPIRED_TOKEN_LOGOUT } from '../constants';
import { AIContext, AppInsightsService } from "./appinsights.service";
import { HttpClient, HttpContext } from '@angular/common/http';
import { Functions } from "../functions";
import { IResponseAPI } from "../models/api-response";
import { SessionStorageService } from "ngx-webstorage";
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root',
})
export class PatientAttributionService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private readonly patientUrl: string = `${this.url}/patient`;

  private aiContext: AIContext;
  private _agencyId: string = null;
  private _b2bCustomerId: string = null;
  private _savedAgencyId: string = null;
  private _savedB2BCustomerId: string = null;
  private _savedPatientId: string = null;

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private storage: SessionStorageService,
    private aiService: AppInsightsService
  ) {
    this.aiContext = this.aiService.createContext('PatientAttributionService');
  }

  setAgencyId(agencyId: string, autosave: boolean = true): void {
    this._agencyId = agencyId || null;

    try {
      this.storage.store(Constants.LocalStorage_Key.patientAttributionAgencyId, this._agencyId);
    } catch (_err: any) {}

    if (autosave && this.shouldSave()) {
      this.saveAttribution();
    }
  }

  setB2BCustomerId(b2bCustomerId: string, autosave: boolean = true): void {
    this._b2bCustomerId = b2bCustomerId || null;

    try {
      this.storage.store(Constants.LocalStorage_Key.patientAttributionB2BCustomerId, this._b2bCustomerId);
    } catch (_err: any) {}

    if (autosave && this.shouldSave()) {
      this.saveAttribution();
    }
  }

  resetAgencyId(autosave: boolean = false): void {
    this._agencyId = null;
    this._savedAgencyId = null;

    try {
      this.storage.clear(Constants.LocalStorage_Key.patientAttributionAgencyId);
    } catch (_err: any) {}

    // API does not save attributions with null values
    // if (autosave && this.shouldSave()) {
    //   this.saveAttribution();
    // }
  }

  resetB2BCustomerId(autosave: boolean = false): void {
    this._b2bCustomerId = null;
    this._savedB2BCustomerId = null;

    try {
      this.storage.clear(Constants.LocalStorage_Key.patientAttributionB2BCustomerId);
    } catch (_err: any) {}

    // API does not save attributions with null values
    // if (autosave && this.shouldSave()) {
    //   this.saveAttribution();
    // }
  }

  resetAttributionData(autosave: boolean = false): void {
    this._savedPatientId = null;
    this.resetAgencyId(autosave);
    this.resetB2BCustomerId(autosave);

    // API does not save attributions with null values
    // if (autosave && this.shouldSave()) {
    //   this.saveAttribution();
    // }
  }

  private getPatientId(): string {
    return this.functions.getStoredPatientId();
  }

  private shouldSave(): boolean {
    return Boolean(this.getPatientId());
  }

  async saveAttribution(overridePatientId: string = null): Promise<boolean> {
    const patientId: string = overridePatientId ?? this.getPatientId();

    let agencyId: string = null;
    let b2bCustomerId: string = null;

    try {
      agencyId = this.storage.retrieve(Constants.LocalStorage_Key.patientAttributionAgencyId) ?? null;
    } catch (_err: any) {
      console.log('Failed to parse attribution agencyId from storage item');
    }

    try {
      b2bCustomerId = this.storage.retrieve(Constants.LocalStorage_Key.patientAttributionB2BCustomerId) ?? null;
    } catch (_err: any) {
      console.log('Failed to parse attribution b2bCustomerId from storage item');
    }

    // We don't want to re-save the same attribution values
    if (
      this._savedPatientId === patientId &&
      this._savedAgencyId === agencyId &&
      this._savedB2BCustomerId === b2bCustomerId
    ) {
      // console.log('[PATIENT_ATTRIBUTION] Already saved for this patient. Aborting.');
      return true;
    }

    const hasAgencyId: boolean = !!agencyId;
    const hasB2BCustomerId: boolean = !!b2bCustomerId;

    // If we don't have a patientId, or agency/b2bcustomer values, return here
    if (!patientId || (!hasAgencyId && !hasB2BCustomerId)) {
      this.aiContext.info('saveAttributionResult', {
        success: false,
        reason: 'MissingData',
        agencyId,
        b2bCustomerId,
        patientId
      });
      return false;
    }

    let succeeded: boolean = false;
    let error: string = null;

    try {
      const result: IResponseAPI = await this.updatePatientReferrerAttribution(patientId, agencyId, b2bCustomerId);

      this.aiContext.info('saveAttributionResult', {
        success: result?.success,
        didUpdate: Boolean(result?.response),
        agencyId,
        b2bCustomerId,
        patientId
      });

      succeeded = Boolean(result?.success);

      if (!succeeded) {
        error = this.functions.getErrorMessage(result?.error);
      }
    } catch (err: any) {
      this.aiContext.trackException(err, 'saveAttribution', { agencyId, b2bCustomerId, patientId });
      succeeded = false;
      error = this.functions.getErrorMessage(err);
    }

    // Remember saved values
    if (succeeded) {
      this.setAgencyId(agencyId, false);
      this.setB2BCustomerId(b2bCustomerId, false);

      this._savedAgencyId = agencyId;
      this._savedB2BCustomerId = b2bCustomerId;
      this._savedPatientId = patientId;
    } else {
      // this._savedPatientId = null;
      // this._savedAgencyId = null;
      // this._savedB2BCustomerId = null;
    }

    return succeeded;
  }

  // PUT https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/referrerAttribution
  private updatePatientReferrerAttribution(
    patientId: string,
    agencyId?: string,
    b2bCustomerId?: string
  ): Promise<IResponseAPI> {
    const params: any = {
      AgencyId: agencyId ?? null,
      B2BCustomerId: b2bCustomerId ?? null
    };

    return this.http
      .put(`${this.patientUrl}/${patientId}/referrerAttribution`, params, {
        context: new HttpContext().set(PREVENT_EXPIRED_TOKEN_LOGOUT, true)
      })
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        return this.functions.handleError(err);
      });
  }

  get agencyId(): string {
    return this._agencyId || null;
  }
  get b2bCustomerId(): string {
    return this._b2bCustomerId || null;
  }
}
