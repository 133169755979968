import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Subject, Subscription } from 'rxjs';
import { Constants } from '../constants';
import { IResponseAPI } from '../models/api-response';
import { Medication } from '../models/medication';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {
  AssociatedMedicationDetails,
  AssociatedMedications,
  MedicationBrand,
  MedicationCategories,
  MedicationDosage
} from '../models/associated-medications';
import { Pharmacy } from '../models/pharmacy';
import { Functions } from '../functions';
import { PromiseHelperService } from './promise-helper.service';
import { AIContext, AppInsightsService } from './appinsights.service';

@Injectable({
  providedIn: 'root'
})
export class MedicationService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private subscription = new Subscription();
  private aiContext: AIContext;

  private _medications: AssociatedMedications[] = [];
  private _qsMedications: Map<string, AssociatedMedications> = null;
  private _associatedMedications: Map<string, any> = new Map<string, any>();
  private _medicationList: Medication[] = [];

  private _pharmacies: Pharmacy[];

  private _medicationsChange: Subject<AssociatedMedications[]> = new Subject<AssociatedMedications[]>();
  public medicationsChangeObs = this._medicationsChange.asObservable();

  private _qsMedicationsChange: Subject<Map<string, AssociatedMedications>> = new Subject<
    Map<string, AssociatedMedications>
  >();
  public qsMedicationsChangeObs = this._qsMedicationsChange.asObservable();

  private _medicationsListChange: Subject<Medication[]> = new Subject<Medication[]>();
  public medicationsListChangeObs = this._medicationsListChange.asObservable();

  private _associatedMedicationsChange: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  public associatedMedicationsChangeObs = this._associatedMedicationsChange.asObservable();

  private _pharmaciesChange: Subject<Pharmacy[]> = new Subject<Pharmacy[]>();
  public pharmaciesChangeObs = this._pharmaciesChange.asObservable();

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    private promiseHelperService: PromiseHelperService,
    private functions: Functions,
    private aiService: AppInsightsService
  ) {
    this.aiContext = this.aiService.createContext('MedicationService');
    this._init();
  }

  private _init(): void {
    this.retrieveMedicationList();
    this.retrieveQuickScriptMedications();

    // Whenever medicationsList is updated, save it to local storage and set an expiry date
    this.subscription.add(
      this.medicationsListChangeObs.subscribe(() => {
        const today: Date = new Date();
        const expireListDate: number = today.setHours(
          today.getHours() + Constants.Medications_Configuration.expireMedicationListHours
        );
        try {
          this.sessionStorage.store(Constants.LocalStorage_Key.medicationsListExpiryDate, expireListDate);
          this.sessionStorage.store(Constants.LocalStorage_Key.medicationsList, this._medicationList);
        } catch (_err: any) {}
      })
    );

    // Whenever [selected] medications are updated, save to local storage
    this.subscription.add(
      this.medicationsChangeObs.subscribe(() => {
        try {
          this.sessionStorage.store(Constants.LocalStorage_Key.selectedMedications, this._medications);
        } catch (_err: any) {}
      })
    );

    // When we perform this check, we attempt to retrieve associatedMedications from storage
    if (!(this._associatedMedications instanceof Map && this._associatedMedications.size)) {
      this.retrieveAssociatedMedications();
    }

    // Whenever associatedMedications list is updated, save it to local storage
    this.subscription.add(
      this.associatedMedicationsChangeObs.subscribe(() => {
        const arrayMap: Array<[string, any]> =
          this._associatedMedications instanceof Map && this._associatedMedications.size
            ? Array.from(this._associatedMedications.entries())
            : null;

        try {
          this.sessionStorage.store(Constants.LocalStorage_Key.associatedMedications, arrayMap);
        } catch (_err: any) {}
      })
    );

    // QuickScript medications added by the patient
    this.subscription.add(
      this.qsMedicationsChangeObs.subscribe(() => {
        const arrayMap: Array<[string, AssociatedMedications]> =
          this._qsMedications instanceof Map && this._qsMedications.size
            ? Array.from(this._qsMedications.entries())
            : null;

        try {
          this.sessionStorage.store(Constants.LocalStorage_Key.selectedMedicationsMap, arrayMap);
        } catch (_err: any) {}
      })
    );

    // Save identified pharmacies to storage
    this.subscription.add(
      this.pharmaciesChangeObs.subscribe(() => {
        try {
          this.sessionStorage.store(Constants.LocalStorage_Key.pharmacies, this._pharmacies);
        } catch (_err: any) {}
      })
    );
  }

  /**
   * @function filterMedicationSearchResults
   * @description Search for a medication by name, applying a custom filter to ensure
   * that Generic medications were not being displayed in the result unless there were no other matches.
   *
   * @param {string} searchText
   * @param {Medication[]} medicationOptions array of medications
   *
   * @returns {Medication[]} Filtered array of medications that match the search criteria
   */
  filterMedicationSearchResults(searchText: string, medicationOptions: Medication[]): Medication[] {
    if (!(searchText && searchText.trim() && medicationOptions?.length)) {
      return [];
    }

    let result: Medication[] = medicationOptions.filter((option: Medication) => {
      let medName: string = option.name?.toLowerCase() ?? '';
      let medDescription: string = option.shortDescription?.toLowerCase() ?? '';

      // If certain characters do not appear in the search text, remove them from the medication name/description
      if (!searchText.includes('(')) {
        medDescription = medDescription.replace('(', ' ');
      }
      if (!searchText.includes(')')) {
        medDescription = medDescription.replace(')', ' ');
      }
      if (!searchText.includes('-')) {
        medName = medName.replace('-', ' ');
        medDescription = medDescription.replace('-', ' ');
      }
      if (!searchText.includes('/')) {
        medDescription = medDescription.replace('/', ' ');
      }
      medName = medName.replace('  ', ' ').trim();
      medDescription = medDescription.replace('  ', ' ').trim();

      // If medication name includes the user's search text, it's a match
      // Note: by not trimming the search text, we effectively exclude results that don't
      // contain a space at the end and proceed to match the longer medication description
      if (medName.includes(searchText)) {
        return true;
      }

      // If medication description includes the user's search text
      // Condition: either the search text must contain the word 'generic' or the
      // medication description must NOT to contain the word 'generic' to be a match
      if (medDescription && (Constants.GenericMed.test(searchText) || !Constants.GenericMed.test(medDescription))) {
        return medDescription.includes(searchText.trim());
      }

      // No match
      return false;
    });

    // If we didn't match any results, try searching the medication description again,
    // but this time without the 'generic' keyword filter
    if (!result.length) {
      result = medicationOptions.filter((option: Medication) => {
        let isMatch: boolean = false;
        let medName: string = option.name?.toLowerCase() ?? '';
        let medDescription: string = option.shortDescription?.toLowerCase() ?? '';
        let fullMedName: string = medName + ' ' + medDescription;

        // If certain characters do not appear in the search text, remove them from the medication description
        if (!searchText.includes('(')) {
          fullMedName = fullMedName.replace('(', ' ');
        }
        if (!searchText.includes(')')) {
          fullMedName = fullMedName.replace(')', ' ');
        }
        if (!searchText.includes('-')) {
          fullMedName = fullMedName.replace('-', ' ');
        }
        if (!searchText.includes('/')) {
          fullMedName = fullMedName.replace('/', ' ');
        }

        fullMedName = fullMedName.replace('  ', ' ').trim();
        if (fullMedName) {
          try {
            const searchWords: string[] = searchText.replace('  ', ' ').trim().split(' ');
            isMatch = searchWords.every((word: string) => fullMedName.includes(word));
          } catch (_err) {}
        }

        return isMatch;
      });
    }

    return result;
  }

  /**
   * @function retrieveQuickScriptMedications
   * @description Retrieve medication mapping from storage or memory to be used for
   * medication dosage selection within the QuickScript flow
   *
   * @returns {Map<string, AssociatedMedications>} Medication to AssociatedMedications map
   */
  retrieveQuickScriptMedications(): Map<string, AssociatedMedications> {
    if (!(this._qsMedications instanceof Map)) {
      try {
        const arrayMap = this.sessionStorage.retrieve(Constants.LocalStorage_Key.selectedMedicationsMap) as Array<
          [string, AssociatedMedications]
        >;

        if (Array.isArray(arrayMap) && arrayMap.length) {
          this._qsMedications = new Map<string, AssociatedMedications>(arrayMap);
        }
      } catch (err: any) {}
    }

    if (!(this._qsMedications instanceof Map)) {
      this._qsMedications = new Map<string, AssociatedMedications>();
    }

    return this._qsMedications;
  }

  /**
   * @function retrieveMedicationList
   * @description If prescription medication data has not expired, retrieve it from LocalStorage and
   * return it as an array of Medication objects.
   * This array of medications is used to quickly retrieve medication details for medication searches
   * without having to call the API
   *
   * @returns {Medication[]} Array of all available prescription medications
   */
  retrieveMedicationList(): Medication[] {
    if (!(Array.isArray(this._medicationList) && this._medicationList.length)) {
      try {
        let expiryDate: number;

        try {
          expiryDate = this.sessionStorage.retrieve(Constants.LocalStorage_Key.medicationsListExpiryDate);
        } catch (_err: any) {}

        if (!expiryDate || (typeof expiryDate === 'number' && new Date() < new Date(expiryDate))) {
          let medicationList: Medication[];

          try {
            medicationList = this.sessionStorage.retrieve(Constants.LocalStorage_Key.medicationsList) as Medication[];
          } catch (_err: any) {}

          if (medicationList) {
            this._medicationList = medicationList;
          }
        }
      } catch (err: any) {}
    }

    if (!Array.isArray(this._medicationList)) {
      this._medicationList = [];
    }

    return this._medicationList;
  }

  /**
   * @function retrieveAssociatedMedications
   * @description If associated medication data has not expired, retrieve it from LocalStorage and return it as a Map
   * This mapping of medicationIds to their associated medications is used to quickly retrieve associated medication
   * data without having to call the API each time
   *
   * @returns {Map<string, any>} Map of associated medications in the form [[medicationId]:[AssociatedMedications]]
   */
  retrieveAssociatedMedications(): Map<string, any> {
    try {
      let expiryDate: number = null;

      try {
        expiryDate = this.sessionStorage.retrieve(Constants.LocalStorage_Key.medicationsListExpiryDate);
      } catch (_err: any) {}

      // If no expiry date, or not yet expired, retrieve associated medications from local storage
      if (!expiryDate || (typeof expiryDate === 'number' && new Date() < new Date(expiryDate))) {
        let storedMedications: Array<[string, any]>;

        try {
          storedMedications = this.sessionStorage.retrieve(Constants.LocalStorage_Key.associatedMedications) as Array<
            [string, any]
          >;
        } catch (_err: any) {}

        if (Array.isArray(storedMedications) && storedMedications.length) {
          this._associatedMedications = new Map<string, any>(storedMedications);
        }
      }
    } catch (err: any) {}

    if (!(this._associatedMedications instanceof Map)) {
      this._associatedMedications = new Map<string, any>();
    }

    return this._associatedMedications;
  }

  /**
   * @function addQSMedication
   * @description Add a medication to the QuickScript flow. Medications added here may not be in the shopping
   * cart yet (this happens after completing Step 1 of the QuickScript flow).
   *
   * @param {string} medicationId
   * @param {AssociatedMedications} aMeds Medication products 'associated' with the provided medicationId
   *
   * @returns {Map<string, AssociatedMedications>} A Map of the QuickScript medications patient has added thus far
   */
  addQSMedication(medicationId: string, aMeds: AssociatedMedications): Map<string, AssociatedMedications> {
    if (
      medicationId &&
      aMeds &&
      this.qsMedications instanceof Map &&
      this._qsMedications.size < Constants.Medications_Configuration.maxMedicationsPerSession &&
      !this.findSelectedMedicationId(medicationId)
    ) {
      this._qsMedications.set(medicationId, aMeds);
      this._qsMedicationsChange.next(this._qsMedications);
    }

    return this._qsMedications;
  }

  /**
   * @function removeQSMedication
   * @description Remove a medication from the QuickScript medications Map.
   *
   * @param {string} medicationId
   *
   * @returns {Map<string, AssociatedMedications>} A Map of the remaining QuickScript medications
   */
  removeQSMedication(medicationId: string): Map<string, AssociatedMedications> {
    const medIdToRemove: string = this.findSelectedMedicationId(medicationId);
    if (medIdToRemove) {
      this._qsMedications.delete(medIdToRemove);
      this._qsMedicationsChange.next(this._qsMedications);
    } else {
      console.log('MedicationId ', medicationId, ' could not be found within the selected medications mapping.');
    }

    return this._qsMedications;
  }

  /**
   * @function findSelectedMedicationId
   * @description Determine the top level 'key' within the selected medications mapping to which the specified
   * medicationId belongs. Could either be from the top level Medication or an inner level MedicationDosage item.
   *
   * @param {string} medId Medication ID or name to search for. Could apply to the top level medication, one of
   * the medication dosage items, or a medication brand name
   *
   * @returns {string} Medication ID of the source medication (the key) in the selected medications mapping
   */
  findSelectedMedicationId(medId: string): string {
    const medicationId: string = (medId || '').trim();

    if (medicationId && this.qsMedications instanceof Map && this._qsMedications.size) {
      // Check if specified medicationId is a GUID or a medication name
      if (Constants.guidRegex.test(medicationId)) {
        if (this._qsMedications.has(medicationId)) {
          return medicationId;
        } else {
          // In the case where medicationId belongs to a medication dosage, find it and remove the parent mapping
          for (let meds of this.qsMedications.entries()) {
            const index: number = meds[1].medications.findIndex(
              (meds: MedicationDosage) => meds.medicationId === medicationId
            );
            if (index !== -1 && this._qsMedications.has(meds[0])) {
              return meds[0];
            }
          }
        }
      } else {
        for (let meds of this.qsMedications.entries()) {
          const index: number = meds[1].medicationBrands.findIndex(
            (medBrand: MedicationBrand) => medBrand.name.trim() === medicationId.trim()
          );
          if (index !== -1 && this._qsMedications.has(meds[0])) {
            return meds[0];
          }
        }
      }
    }

    return null;
  }

  /**
   * @function getQSMedicationByMedicationId
   * @description Search through the medication map to find medications associated with the provided
   * medicationId. Associated medications are the dosage options the user selects during QuickScript flow.
   *
   * @param {string} medicationId
   *
   * @returns {AssociatedMedications} medication options associated with the specified medicationId
   */
  getQSMedicationByMedicationId(medicationId: string): AssociatedMedications {
    const medId: string = this.findSelectedMedicationId(medicationId);
    if (medId) {
      return this._qsMedications.get(medId);
    }

    return null;
  }

  /**
   * @function getQSMedicationAtIndex
   * @description Get the associated medications from the medication map based on a map index.
   * Associated medications are the dosage options the user selects during QuickScript flow.
   *
   * @param {number} index Map utilises an index in the same way as an Array. Mapped elements
   * retain their position based on when they were added to the Map.
   *
   * @returns {AssociatedMedications} medication options associated with the medication at the specified index
   */
  getQSMedicationAtIndex(index: number): AssociatedMedications {
    try {
      const [...aMeds] = this.qsMedications.values();
      if (Array.isArray(aMeds) && aMeds.length > index) {
        return aMeds[index];
      }
    } catch (err: any) {
      console.log(
        'Failed to retrieve QuickScript medication at index ',
        index,
        '. Error: ',
        this.functions.getErrorMessage(err)
      );
    }

    return null;
  }

  /**
   * @function getQSMedicationIdAtIndex
   * @description Get the medicationId of the medication at a specified index (medications Map)
   *
   * @param {number} index Index of medication in the medications Map
   *
   * @returns {string} medicationId of the medication at the specified index
   */
  getQSMedicationIdAtIndex(index: number): string {
    try {
      const [...medIds] = this.qsMedications.keys();
      if (Array.isArray(medIds) && medIds.length > index) {
        return medIds[index];
      }
    } catch (err: any) {
      console.log(
        'Failed to retrieve QuickScript medication id at index ',
        index,
        '. Error: ',
        this.functions.getErrorMessage(err)
      );
    }

    return null;
  }

  /**
   * @async
   * @function getMedicationIdByName Retrieve medicationId which matches a medication by name
   *
   * @param {string} medName medication name (not including shortDescription or longDescription)
   *
   * @returns {Promise<string>} medicationId
   */
  async getMedicationIdByName(medName: string): Promise<string> {
    const medicationName: string = (medName || '').toLowerCase();
    if (medicationName) {
      const medications: Medication[] = await this.getMedications();
      if (medications?.length) {
        const medication: Medication = medications.find(
          (med: Medication) => med.name?.toLowerCase() === medicationName
        );
        if (medication && medication.medicationId) {
          return medication.medicationId;
        }
      }
    }
    return null;
  }

  /**
   * @async
   * @function getMedicationNameById Retrieve medication name which matches the specified medicationId
   *
   * @param {string} medId medicationId
   *
   * @returns {Promise<string>} medication name (not including shortDescription or longDescription)
   */
  async getMedicationNameById(medId: string): Promise<string> {
    const medications: Medication[] = await this.getMedications();
    if (Array.isArray(medications)) {
      const medication: Medication = medications.find((med: Medication) => med.medicationId === medId);
      if (medication?.name) {
        return medication.name;
      }
    }
    return null;
  }

  /**
   * @function getMedicationDetails
   * @description Get the formatted medication name, category and whether the patient is currently taking this medication
   *
   * @param {string} medicationId
   * @param {AssociatedMedications} [associatedMeds] if not specified, will try and retrieve existing associated medication data
   *
   * @returns {AssociatedMedicationDetails}
   */
  getAssociatedMedicationDetails(
    medicationId: string,
    brandId?: string,
    medName?: string,
    associatedMeds?: AssociatedMedications
  ): AssociatedMedicationDetails {
    const medication: AssociatedMedications = associatedMeds || this.getQSMedicationByMedicationId(medicationId);

    if (!medication?.medications?.length) {
      return null;
    }

    let medicationName: string = medName || medication.medications[0].name;
    let medicationBrandId: string = brandId || medication.medications[0].medicationBrandId;
    let medicationBrandName: string;

    for (let i = 0; i < medication.medicationBrands?.length; i++) {
      // if (medication.medicationBrands[i].name === medicationName) {
      if (medication.medicationBrands[i].medicationBrandId === medicationBrandId) {
        const description: string = medication.medicationBrands[i].shortDescription || '';

        medicationBrandName = medication.medicationBrands[i].name;
        medicationName = medicationBrandName + (description ? ' - ' + description : '');

        break;
      }
    }

    let takingMedication: string | boolean | null;
    try {
      takingMedication =
        sessionStorage.getItem(Constants.LocalStorage_Key.currentlyTakingMedication.concat('.', medicationId)) || null;

      if (takingMedication) {
        takingMedication = JSON.parse(takingMedication);
      }
    } catch (_err: any) {}

    const isTakingMedication: string | null =
      typeof takingMedication === 'boolean' ? (takingMedication ? 'Yes' : 'No') : null;
    const categories: MedicationCategories = medication.medicationCategories || {};
    const medicationCategory: string = categories[medication.medications[0].medicationCategoryId];

    return {
      medicationName,
      medicationBrandName,
      medicationCategory,
      isTakingMedication
    } as AssociatedMedicationDetails;
  }

  /**
   * @function getMedications
   * @description Retrieve all medications from the API (or from memory if already retrieved)
   *
   * @returns {Promise<Medication[]>} Array of medications
   */
  getMedications(): Promise<Medication[]> {
    if (Array.isArray(this.medicationList) && this._medicationList.length) {
      return Promise.resolve(this._medicationList);
    } else {
      return this.http
        .get(`${this.url}/medications`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            const result: any = response.response;
            if (result && Array.isArray(result.medications)) {
              this.medicationList = result.medications as Medication[];
            }
          }
          return this.medicationList;
        })
        .catch((err: any) => {
          this.functions.handleError(err);
          return null;
        });
    }
  }

  /**
   * @function getAssociatedMedications
   * @description Retrieve medications (medication dosages) associated with the specified medicationId
   *
   * @param {string} medicationId
   *
   * @returns {Promise<AssociatedMedications>} Associated medications object
   */
  getAssociatedMedications(medicationId: string): Promise<AssociatedMedications> {
    const associatedMedications: AssociatedMedications = this.associatedMedications.get(medicationId);

    if (associatedMedications) {
      this.aiContext.reportSuccessStatus(
        'GetAssociatedMedications',
        true,
        'Returning cached AssociatedMedication data',
        { medicationId }
      );
      return Promise.resolve(associatedMedications);
    } else {
      return this.http
        .get(`${this.url}/medications/${medicationId}/associated`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            let associatedMeds = response.response as AssociatedMedications;

            this.aiContext.reportSuccessStatus(
              'GetAssociatedMedications',
              Boolean(associatedMeds),
              'Returning AssociatedMedication data from API',
              { medicationId }
            );

            if (typeof associatedMeds === 'object') {
              associatedMeds.originalMedicationId = medicationId;
              this._associatedMedications.set(medicationId, associatedMeds);
              this._associatedMedicationsChange.next(null);

              return associatedMeds;
            }
          }
          return null;
        })
        .catch((error: any) => {
          this.aiContext.reportSuccessStatus(
            'GetAssociatedMedications',
            false,
            'Returning AssociatedMedication data from API',
            { medicationId, error }
          );
          return null;
        });
    }
  }

  /**
   * @async
   * @function getMedicationOptions
   * @description Retrieve and process medications for QuickScript orders
   *
   * @param {Function} callbackFn a callback function that is called on the result
   *
   * @returns {Promise<Medication[]>}
   */
  async getMedicationOptions(callbackFn?: Function): Promise<Medication[]> {
    let result: Medication[] = [];

    await this.getMedications()
      .then((response: Medication[]) => {
        if (Array.isArray(response) && response.length) {
          // UR-923 - Remove all medicines with matching `${name}-${shortDescription}` text
          // Keep the first unique medicine found
          result = response
            .reduce(
              (medications: Medication[], med: Medication) =>
                medications.find(
                  (medication: Medication) =>
                    medication.medicationId === med.medicationId ||
                    (medication.name || '')
                      .trim()
                      .concat('-', (medication.shortDescription || '').trim())
                      .toLowerCase() ===
                      (med.name || '')
                        .trim()
                        .concat('-', (med.shortDescription || '').trim())
                        .toLowerCase()
                )
                  ? [...medications]
                  : [...medications, med],
              []
            )
            .sort((med1: Medication, med2: Medication) =>
              /* med1.sortOrder && med2.sortOrder
            ? med2.sortOrder - med1.sortOrder
            : */
              ''.concat(med1.name || '', med1.shortDescription || '') >
              ''.concat(med2.name || '', med2.shortDescription || '')
                ? 1
                : -1
            );
        }

        if (callbackFn) {
          callbackFn(result);
        }
      })
      .catch((err: any) => {
        console.log('Failed to retrieve medications: ', err);
      });

    return result;
  }

  /**
   * @function getConditionsWeTreatPath
   * @description Returns the short name for a medical condition used in determining the path / URL
   * to the category's description page (under Conditions We Treat)
   *
   * @param {string} category Medication Category
   *
   * @returns {string | null} Partial path to the medical category description page.
   * eg. (in square brackets): https://www.doctorsondemand.com.au/conditions-we-treat/[hay-fever]/
   */
  getConditionsWeTreatPath(category: string): string | null {
    const categoryName: string = category.trim().toLowerCase();

    switch (categoryName) {
      case 'acne':
      case 'anaphylaxis':
      case 'asthma':
      case 'benign prostatic hyperplasia':
      case 'cholesterol':
      case 'contraception':
      case 'cystitis':
      case 'diabetes':
      case 'erectile dysfunction':
      case 'gout':
      case 'incontinence':
      case 'indigestion':
      case 'medicated weight loss':
      case 'menopause':
      case 'migraines':
      case 'osteoporosis':
        return categoryName.replace(' ', '-');

      case 'blood pressure':
      case 'blood pressure - hypertension': // original site label
        return 'high-blood-pressure';
      // return 'high-blood-pressure-hypertension'; // original site URI

      case 'cystitis - urinary tract infection': // original site label
        return 'cystitis';

      case 'diabetes - hyperglycemia': // original site label
        return 'diabetes';
      // return 'diabetes-and-hyperglycemia'; // original site URI

      case 'erectile dysfunction and impotence': // original site label
        return 'erectile-dysfunction';

      case 'hay fever':
      case 'hay fever & allergy': // original site label
      case 'hay fever and allergy': // hypothetical alternative
        return 'hayfever-and-allergies';

      case 'high cholesterol':
      case 'high cholesterol - hypercholesterolemia': // original site label
        return 'cholesterol';

      case 'digestion':
      case 'indigestion/heartburn - gord': // original site label
        return 'indigestion';

      case 'inflammation & pain': // original site label
      case 'inflammation and pain': // hypothetical alternative
        return 'pain';

      case 'male hair loss':
      case 'male pattern baldness':
      case 'male pattern baldness - androgenic alopecia': // original site label
        return 'male-hair-loss-alopecia';

      case 'menopause - hrt (hormone replacement therapy)': // original site label
        return 'menopause';

      case 'mental health':
        return 'mental-health-care-plan-assessment';

      case 'migraine':
        return 'migraines';

      case 'prostate':
      case 'prostate and bladder':
      case 'prostate - bph benign prostatic hyperplasia': // original site label
        return 'benign-prostatic-hyperplasia';
      // return 'benign-prostatic-hyperplasia-bph'; // original site label

      case 'smoking cessation':
        return 'smoking-cessation';

      case 'weight loss':
        return 'doctor-assisted-weight-management';

      default:
        return null;
    }
  }

  /**
   * @function getPharmacyById
   * @description Retrieve pharmacy by ID
   * GET /api/v1/pharmacy/{pharmacyId}
   *
   * @param {string} pharmacyId
   *
   * @returns {Promise<Pharmacy>} pharmacy object
   */
  getPharmacyById(pharmacyId: string): Promise<Pharmacy> {
    if (!pharmacyId) {
      return Promise.resolve(null);
    }

    let pharmacy: Pharmacy = this._pharmacies?.length
      ? this._pharmacies.find((pharm: Pharmacy) => pharm.pharmacyId === pharmacyId)
      : null;

    if (pharmacy && pharmacy.addressId) {
      return Promise.resolve(pharmacy);
    } else {
      return this.http
        .get(`${this.url}/pharmacy/${pharmacyId}`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            const pharmacy = response.response as Pharmacy;

            if (!this._pharmacies) {
              this._pharmacies = [];
            }

            const existingPharmacyIndex: number = this._pharmacies.findIndex(
              (pharm: Pharmacy) => pharm.pharmacyId === pharmacyId
            );

            if (existingPharmacyIndex === -1) {
              this._pharmacies.push(pharmacy);
            } else {
              this._pharmacies.splice(existingPharmacyIndex, 1, pharmacy);
            }

            this._pharmaciesChange.next(null);

            return pharmacy;
          }
          return null;
        })
        .catch((err: any) => {
          this.functions.handleError(err);
          return null;
        });
    }
  }

  /**
   * @function findPharmacy
   * @description Find all pharmacies near a specified address (radius of search is configured in the API).
   *
   * @param {string} originalAddress search radius around specified (full) address string
   *
   * @returns {Promise<Pharmacy[] | null>} List of pharmacies within a radius of the provided address
   */
  findPharmacy(originalAddress: string): Promise<Pharmacy[] | null> {
    const promiseStorageKey: string = 'FindPharmacyByAddress';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      originalAddress,
      Constants.API_Polling_Times.findPharmacyByAddress_SecondsBetweenRequests
    );

    // if (this._quickScriptPricing) {
    //   return Promise.resolve(this._quickScriptPricing);
    // } else {

    if (!returnExistingPromise) {
      const params = new HttpParams().append('address', originalAddress);

      const newPromise: Promise<Pharmacy[] | null> = this.http
        .get(`${this.url}/pharmacy/find`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.success && response.response) {
            const pharmacies = response.response as Pharmacy[];

            if (!this._pharmacies) {
              this._pharmacies = [];
            }

            pharmacies.forEach((pharmacy: Pharmacy) => {
              const verified: boolean = pharmacy.isVerified ?? true;
              // add all pharmacies with verified: true or verified: null/undefined
              if (verified) {
                let pharmacyIndex: number = this._pharmacies.findIndex(
                  (pharm: Pharmacy) => pharm.pharmacyId === pharmacy.pharmacyId
                );
                if (pharmacyIndex === -1) {
                  this._pharmacies.push(pharmacy);
                } else if (!this._pharmacies[pharmacyIndex].addressId) {
                  this._pharmacies.splice(pharmacyIndex, 1, pharmacy);
                }
              }
            });

            this._pharmaciesChange.next(null);

            return pharmacies;
          }
          return [];
        })
        .catch((err: any) => {
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<Pharmacy[]>(promiseStorageKey, newPromise, originalAddress);
    }

    return this.promiseHelperService.getPromiseByKey<Pharmacy[] | null>(promiseStorageKey);
  }

  resetService(): void {
    this._qsMedications = null;
    try {
      this.sessionStorage.clear(Constants.LocalStorage_Key.selectedMedicationsMap);
    } catch (_err: any) {}
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this._pharmacies = null;
    this._qsMedications = null;
    this._medicationList = null;
    this._associatedMedications = null;
  }

  get medications(): AssociatedMedications[] {
    if (!(Array.isArray(this._medications) && this._medications.length)) {
      try {
        this._medications = this.sessionStorage.retrieve(Constants.LocalStorage_Key.selectedMedications);
      } catch (_err: any) {}
    }
    if (!Array.isArray(this._medications)) {
      this._medications = [];
    }
    return this._medications ?? [];
  }
  set medications(medications: AssociatedMedications[]) {
    this._medications = medications ?? [];
    this._medicationsChange.next(null);
  }
  get qsMedications(): Map<string, AssociatedMedications> {
    if (!(this._qsMedications instanceof Map)) {
      this._qsMedications = new Map<string, AssociatedMedications>();
    }
    return this._qsMedications;
  }
  set qsMedications(selectedMedicationsMap: Map<string, AssociatedMedications>) {
    this._qsMedications = selectedMedicationsMap || null;
    this._qsMedicationsChange.next(this._qsMedications);
  }
  get associatedMedications(): Map<string, any> {
    if (!(this._associatedMedications instanceof Map)) {
      this.retrieveAssociatedMedications();
    }
    return this._associatedMedications;
  }
  get pharmacies(): Pharmacy[] {
    if (!(Array.isArray(this._pharmacies) && this._pharmacies.length)) {
      try {
        this._pharmacies = this.sessionStorage.retrieve(Constants.LocalStorage_Key.pharmacies);
      } catch (_err: any) {}
    }
    if (!Array.isArray(this._pharmacies)) {
      this._pharmacies = [];
    }
    return this._pharmacies;
  }
  get medicationList(): Medication[] {
    this.retrieveMedicationList();
    return this._medicationList ?? [];
  }
  set medicationList(medicationList: Medication[]) {
    this._medicationsListChange.next((this._medicationList = medicationList));
  }
}
