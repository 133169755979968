import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[ihiFormat]',
})
export class IHIFormatDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed: string = input.value.toUpperCase().replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 19) {
      trimmed = trimmed.substring(0, 19);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 4) !== '') {
      numbers.push(trimmed.substring(0, 4));
    }
    if (trimmed.substring(4, 8) !== '') {
      numbers.push(trimmed.substring(4, 8));
    }
    if (trimmed.substring(8, 12) !== '') {
      numbers.push(trimmed.substring(8, 12));
    }
    if (trimmed.substring(12, 16) !== '') {
      numbers.push(trimmed.substring(12, 16));
    }

    input.value = numbers.join('-');
  }
}

@NgModule({
  declarations: [IHIFormatDirective],
  exports: [IHIFormatDirective],
})
export class IHIFormatDirectiveModule {}
