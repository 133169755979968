import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppointmentHours } from '../models/appointment-hours';
import { QuickScriptServiceType } from '../models/quickscriptServiceType';
import { Medication } from '../models/medication';
import { ReceivePrescriptionType } from '../models/prescription-type';
import { AppointmentDTO } from '../models/appointmentDTO';
import { ServicePrice } from '../models/service-price';
import { Benefit } from '../models/benefit';
import { Product } from '../models/product';
import { QuickScript } from '../models/quickscript';
import { Constants } from '../constants';
import { QuickScriptOrderItem } from '../models/quick-script-order-item';
import { ProductPrice } from '../models/product-price';
import { CustomDimensionsDataLayer } from '../models/customDimensions';
import { ECommerceGA4, ECommerceItem } from '../models/ecommerce-ga4';
import { ProductServiceType } from '../models/product-service-type';
import { PricingService } from './pricing.service';
import { ReimbursementType } from '../models/reimbursement-type';
import { InboxInteraction } from '../models/inbox-interaction';
import { Capacitor } from '@capacitor/core';
import { HotjarService } from './hotjar.service';
import { VersionService } from './version.service';
import { Prescription } from '../models/prescription';
import { Appointment } from '../models/appointment';
import { BrowserReportService } from './browser-report.service';
import { Title } from '@angular/platform-browser';
import { AnalyticsProxyService } from './analytics-proxy.service';
import { AnalyticsPageViewDTO } from '../models/analyticsPageViewDTO';
import { AIContext, AppInsightsService } from './appinsights.service';
import { Functions } from '../functions';
import { WhitelabelAnalyticsProvider } from './whitelabel/whitelabel-analytics-provider';
import { WhitelabelService } from './whitelabel.service';
import { WhiteLabelConfigDTO } from '../models/whitelabel/WhiteLabelConfigDTO';
import { WhitelabelAnalyticsEventType, WhitelabelAnalyticsHandler } from './whitelabel/whitelabel-analytics-handler';
import { AnalyticsCustomEventDTO } from '../models/analyticsCustomEventDTO';
import { CustomerType } from '../models/customer-type';
import { environment } from '@env/environment';
// import { environment as prodEnvironment } from '@env/environment.prod';

export enum ServiceSource {
  quickscript = 'QuickScript',
  medication = 'Medication',
  shipping = 'Shipping',
  prescription = 'Prescription'
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private subscription = new Subscription();
  private _env: any = environment.analytics;
  private _gaAccountId: string = this._env.gtm.id;
  private _ga4MeasurementId: string = this._env.ga4.measurementId;
  private _isAnalyticsBlocked: boolean = false;
  private _previousPagePath: string = null;
  private _userId: string;
  private _isLoggedIn: boolean = false;
  private _customDimensions: CustomDimensionsDataLayer;
  private _browserReport: any;
  private _osAndBrowser: string = '';
  private isNative: boolean = Boolean(Capacitor.isNativePlatform());

  private aiContext: AIContext;

  // We do not need to check if the GTM.JS script has loaded as it has its own error event handler
  // private readonly googleSSTMTagManagerScript: string = this._env.gtm.taggingServerURL +
  //   '/gtm.js?id=' + this._env.gtm.id + '&l=' + this._env.gtm.dataLayerName;

  // GA blocking detection (we need to use the production SSTM server path
  // to avoid making a cross-origin request in test environments)
  // private readonly googleSSTMHealthTestUrl: string = prodEnvironment.analytics.gtm.taggingServerURL + '/healthz';

  private totalRecordedGACalls: number = 0;
  private totalErroredGACalls: number = 0;
  private blockDetectionMinimumSampleSize: number = 1;
  private blockDetectionMinimumErrorRate: number = 0;

  private whitelabelHandler: WhitelabelAnalyticsHandler | null = null;

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private pricingService: PricingService,
    private hotjarService: HotjarService,
    private versionService: VersionService,
    private browserReport: BrowserReportService,
    private titleService: Title,
    private analyticsProxyService: AnalyticsProxyService,
    private appInsights: AppInsightsService,
    private functions: Functions,
    private whitelabelService: WhitelabelService,
    private whitelabelAnalyticsProvider: WhitelabelAnalyticsProvider
  ) {
    this.aiContext = appInsights.createContext('GA');

    this._init();
  }

  private _init(): void {
    // Detect operating system and browser
    this._browserReport = this.browserReport.getBrowserReport();
    this._osAndBrowser = `${this._browserReport?.os?.name} ${this._browserReport?.os?.version} - ${this._browserReport?.browser?.name} ${this._browserReport?.browser?.version}`;

    // Remove instances of .0.0 at the end of major versions
    this._osAndBrowser = this._osAndBrowser.replace(/\b\.0(?!\.[1-9])\b/g, '');

    // Keep track of logged in state
    this._isLoggedIn = this.credentialsService.isAuthenticated();

    // IS LOGGED IN
    this.subscription.add(
      this.credentialsService.credentialsChangeObs.subscribe(() => {
        this._isLoggedIn = this.credentialsService.isAuthenticated();
      })
    );

    this.aiContext.info('Init');

    // When the Analytics configuration loads, check if we need to set analytics as 'blocked' (route requests via API)
    window.addEventListener('dod.gtm.configured', async () => {
      const useSSA: boolean = await this.shouldUseSSA();

      if (useSSA) {
        this.setClientAnalyticsIsBlocked();
      }
    });

    // If gtm.js script fails to load, set analytics as 'blocked' (route requests via API)
    window.addEventListener('dod.gtm.scriptload.fail', () => {
      this.setClientAnalyticsIsBlocked();
      this.aiContext.info('GoogleAnalyticsBlocked', { reason: 'GTMScriptTagLoadFailed' });
    });

    // Set App Insights handler for triggered Google Analytics events
    this.appInsights.onGACall((success: boolean) => this.handleGACall(success));

    // WHITELABEL SERVICE CONFIGURATION
    this.subscription.add(
      this.whitelabelService.whitelabelConfigObs.subscribe((config: WhiteLabelConfigDTO) => {
        this.handleWhitelabelChanged(config);
      })
    );
  }

  /**
   * @function handleWhitelabelChanged
   * @description Assign a handler for changes to the whitelabel agency
   *
   * @param {WhiteLabelConfigDTO} whitelabelConfig
   */
  private handleWhitelabelChanged(whitelabelConfig: WhiteLabelConfigDTO): void {
    this.whitelabelHandler = this.whitelabelAnalyticsProvider.GetInstance(whitelabelConfig?.code);

    this.aiContext.info('GotWhiteLabelHandler', {
      hasHandler: !!this.whitelabelHandler,
      agencyCode: whitelabelConfig?.code
    });
  }

  /**
   * @function handleGACall
   * @description Check each request sent to Google Analytics, if the error rate exceeds a preset amount
   * mark analytics as blocked (instead send requests via API/Measurement Protocol)
   *
   * @param {boolean} success
   */
  private handleGACall(success: boolean): void {
    this.totalRecordedGACalls++;
    if (!success) {
      this.totalErroredGACalls++;

      const errorRate: number = this.totalErroredGACalls / this.totalRecordedGACalls;

      this.aiContext.debug('ErrorRateUpdated', {
        errorRate,
        threshold: this.blockDetectionMinimumErrorRate,
        samples: this.totalRecordedGACalls,
        minSampleSize: this.blockDetectionMinimumSampleSize
      });

      if (
        this.totalRecordedGACalls >= this.blockDetectionMinimumSampleSize && // Ensure we have enough data to be sure
        errorRate >= this.blockDetectionMinimumErrorRate // Check if the error rate is too high
      ) {
        this.aiContext.info('GoogleAnalyticsBlocked', {
          reason: 'ErrorRateThreholdExceeded',
          errorRate: errorRate,
          totalCount: this.totalRecordedGACalls
        });
        this.setClientAnalyticsIsBlocked();
      }
    }
  }

  /**
   * @async
   * @function shouldUseSSA
   * @description Determine whether we should use SSTM (default, if not blocked), or fall back to
   * Server Side Analytics (using the Measurement Protocol)
   *
   * @returns {Promise<boolean>} true if we need to use Server Side Analytics
   */
  async shouldUseSSA(): Promise<boolean> {
    // If on iOS, and iOS should use SSA, default to true
    if (!window['dod_gtm_config'] || window['dod_gtm_config'].iOSUseSSA) {
      if (Capacitor.getPlatform() === 'ios') {
        this.aiContext.info('GoogleAnalyticsBlocked', { reason: 'BlockedOnPlatform', platform: 'iOS' });
        return true;
      }

      if (Capacitor.getPlatform() === 'web' && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
        this.aiContext.info('GoogleAnalyticsBlocked', { reason: 'BlockedOnPlatform', platform: 'iOS-Web' });
        return true;
      }
    }

    // If gtm.js script fails to load from the SSTM server, it will trigger an error handler that will set
    // analytics to 'blocked' state and start routing events via the DoD API. The additional health check
    // for the SSTM tagging server is unnecessary.

    // Ensure that we can connect to GA
    /*
    try {
      // Check that the SSTM health endpoint works (cannot be a cross-origin request!)
      const response: Response = await fetch(this.googleSSTMHealthTestUrl);

      if (!response.status || response.status >= 400) {
        this.aiContext.warn('GoogleAnalyticsBlocked', {
          reason: 'NonSuccessStatusCode',
          statusCode: response.status,
          statusText: response.statusText
        });

        return true;
      }
    } catch (error: any) {
      this.aiContext.trackException(error, 'GoogleAnalyticsBlocked', {
        reason: 'FetchThrewException',
        error: error
      });

      return true;
    }
    */

    return false;
  }

  setClientAnalyticsIsBlocked(): void {
    this._isAnalyticsBlocked = true;
    this.aiContext.debug('ClientAnalyticsBlockedState', { blocked: true });
  }

  setClientAnalyticsIsNotBlocked(): void {
    this._isAnalyticsBlocked = false;
    this.aiContext.debug('ClientAnalyticsBlockedState', { blocked: false });
  }

  trackUTMParameters(url: string): void {
    const params: Record<string, string> = this.functions.getQueryParamMap(url);

    let utmParams: Record<string, string> = {
      utm_source: params['utm_source'],
      utm_medium: params['utm_medium'],
      utm_campaign: params['utm_campaign'],
      utm_term: params['utm_term'],
      utm_content: params['utm_content'],
      utm_source_platform: params['utm_source_platform'],
      utm_creative_format: params['utm_creative_format'],
      utm_marketing_tactic: params['utm_marketing_tactic']
    };

    utmParams = this.functions.removeEmpty(utmParams);

    this.aiContext.info('trackUTMParameters', {
      url: url,
      params: utmParams
    });

    this.trackEvent('utm_params', null, null, null, utmParams);
  }

  /**
   * @function pageView
   * @description Track a PageView event
   *
   * @param {string} finalUrl URL of the final route at the end of any redirects
   * @param {string} [referrer] URL of the referrer
   */
  pageView(finalUrl: string, referrer?: string): void {
    this.aiContext.debug('pageView');

    try {
      // Remove the query from the URL (this is used for password reset tokens,
      // which we don't want to send to analytics)
      const pathWithoutQueryParams: string = (finalUrl || '').split('?')[0];
      const path: string = this.prependDeployFolderToPath(pathWithoutQueryParams);
      const url: string = this.prependProtocolAndHostToPath(path);

      // Always track AI events
      this.aiContext.trackPageView(url);

      // Whitelabel-specific events
      this.whitelabelHandler?.Trigger(WhitelabelAnalyticsEventType.PageView);

      if (!this._isAnalyticsBlocked) {
        try {
          // Let HotJar know that we have updated the route
          this.hotjarService.trackRouteChange(url);
        } catch (err: any) {
          this.aiContext.trackException(err, 'HotjarEventFailed');
        }

        this.trackEvent('pageview', null, 'pageview', this.sourceAndVersion, {
          'content-name': url,
          pwa_pagePath: path,
          pwa_referrer: referrer || this.referrerPageURL,
          ...(this.customDimensions || {})
        });
      } else {
        const pageViewDTO: AnalyticsPageViewDTO = {
          GA4MeasurementId: this._ga4MeasurementId,
          category: null,
          action: 'pageview',
          label: this.sourceAndVersion,
          pwa_pageURL: url,
          pwa_pagePath: path,
          pwa_referrer: referrer || this.referrerPageURL,
          hostname: location.hostname,
          pageTitle: this.pageTitle,
          onDemandAppointmentAvailable: this.customDimensions.onDemandAppointmentAvailable,
          onDemandAppointmentQueueSize: this.customDimensions.onDemandAppointmentQueueSize,
          nextAppointmentAvailability: this.customDimensions.nextAppointmentAvailability,
          UserId: this.customDimensions.userId,
          userType: this.customDimensions.userType,
          userAgeAndGender: this.customDimensions.userAgeAndGender,
          userLocation: this.customDimensions.userLocation,
          multiAccountRelationship: this.customDimensions.multiAccountRelationship,
          userSmoker: this.customDimensions.userSmoker,
          userDrinker: this.customDimensions.userDrinker,
          userHasHeartProblems: this.customDimensions.userHasHeartProblems,
          userHasAllergies: this.customDimensions.userHasAllergies,
          userTakesMedication: this.customDimensions.userTakesMedication,
          userBMI: this.customDimensions.userBMI,
          userBloodPressureRange: this.customDimensions.userBloodPressureRange,
          userMedicalCards: this.customDimensions.userMedicalCards,
          userRegisteredThisSession: this.customDimensions.userRegisteredThisSession,
          userCustomerType: this.customDimensions.userCustomerType,
          HotjarId: this.customDimensions.HotjarId,
          allowAdwords: this.customDimensions.allowAdwords
        };

        this.analyticsProxyService.sendPageView(pageViewDTO);

        if (!window['dodDataLayer']) {
          window['dodDataLayer'] = [];
        }
        window['dodDataLayer'].push(pageViewDTO);
      }
    } catch (err) {
      this.aiContext.trackException(err, 'PageviewEventFail');
    }
  }

  getHostName(linkUrl?: string): string {
    if (linkUrl) {
      try {
        return new URL(linkUrl).hostname;
      } catch (err: any) {
        return 'invalid url';
      }
    }
    return '';
  }

  /**
   * @function trackEvent
   * @description Push an event to the Google Analytics data layer. If GA is not blocked, will send the event via
   * Server Side Tag Manager (SSTM), otherwise route the request via the API, that will the Measurement Protocol
   * to record the event.
   *
   * GA4 properties always tracked with an event:
   *   language
   *   page_location
   *   page_referrer
   *   page_title
   *   screen_resolution
   *
   * Universal Analytics (LEGACY) mappings to GA4 configuration tag parameters:
   *   'action'   maps to GA4 'event_name'
   *   'category' maps to GA4 'event_category'
   *   'label'    maps to GA4 'event_label'
   *   'value'    maps to GA4 'value'
   *
   * @param {string} eventName Registered name of the tracking event. eg. 'PatientRegistration'
   * @param {string} [category] Registered category of the tracking event. eg. 'Patient Registered'
   * @param {string} [action] Event action type. eg. 'clickUrl'
   * @param {string} [label] Descriptive event label. eg. 'Referrer: {{Referrer}}'
   * @param {Object} [data] Any additional data to be tracked. Custom fields must be in quotes.
   * @param {boolean} [doNotPostToServer=false] Some events, like HTTP errors, should never be posted to the server
   */
  trackEvent(
    eventName: string,
    category?: string,
    action?: string,
    label?: string,
    data?: Object,
    doNotPostToServer: boolean = false,
    callback: (dataLayer: any) => void = null
  ): void {
    if (!environment.analytics.enabled) {
      return;
    }

    let dataLayer: any = {
      // Analytics Account ID
      id: this._gaAccountId,

      // Analytics User ID
      user_id: this._userId ?? undefined, // for Google Analytics 4 (GA4) (gtag.js)

      // UA
      event: eventName,
      action: action,
      category: category,
      label: label,
      'content-name': this.pageURL, // LEGACY - override UA {{Page URL}} variable
      pwa_pageURL: this.pageURL, // custom page url variable
      pwa_pagePath: this.routePath, // custom page path variable
      pwa_referrer: this.referrerPageURL, // custom referrer variable

      // Extras
      loginStatus: this._isLoggedIn,
      dodSource: this.sourceAndVersion
    };

    if (data) {
      dataLayer = { ...dataLayer, ...data };
    }

    this.aiContext.info('trackEvent', {
      eventName: eventName,
      dataLayer: dataLayer,
      isGaBlocked: this._isAnalyticsBlocked,
      allowSSA: !doNotPostToServer
    });

    if (!this._isAnalyticsBlocked || doNotPostToServer) {
      if (!window['dataLayer']) {
        window['dataLayer'] = [];
        this.aiContext.debug('dataLayerCreated', { type: 'GA' });
      }
      window['dataLayer'].push(dataLayer);
    } else {
      if (!window['dodDataLayer']) {
        window['dodDataLayer'] = [];
        this.aiContext.debug('dataLayerCreated', { type: 'DoD' });
      }

      window['dodDataLayer'].push(dataLayer);

      const analyticsCustomEventDTO: AnalyticsCustomEventDTO = {
        GA4MeasurementId: this._ga4MeasurementId,
        userId: this._userId ?? undefined,
        category,
        action,
        label,
        hostname: location.hostname,
        pwa_pageURL: this.pageURL,
        pwa_pagePath: this.routePath,
        pwa_referrer: this.referrerPageURL,
        pageTitle: this.pageTitle,
        eventName
      };

      this.analyticsProxyService.sendCustomGA4Event(analyticsCustomEventDTO);
    }
  }

  patientLoggedIn(loginMethod: string = 'email'): void {
    this.trackEvent('Login', 'Login', 'Logged In', loginMethod, { loginMethod });
  }

  startLiveChat(): void {
    this.trackEvent('livechatStart', 'Live Chat', 'Start', this.pageURL);
  }

  conferenceStart(appointmentId: string = null, startDateTime: string = null): void {
    this.trackEvent('ConferenceStart', 'Conference Start', appointmentId, startDateTime);
  }

  conferenceComplete(appointmentId: string = null, duration: string = 'unknown'): void {
    this.trackEvent('ConferenceComplete', 'Conference Complete', appointmentId, duration);
  }

  patientRegistered(patientId?: string): void {
    this.trackEvent('PatientRegistration', 'Patient Registered', null, patientId);
  }

  patientAdded(patientAddedLabel?: string): void {
    this.trackEvent('PatientAdded', 'Patient Added', null, patientAddedLabel);
  }

  appointmentComplete(appointment: Appointment): void {
    let label: string =
      (appointment.isOnDemand ? Constants.ANALYTICS_DEFAULTS.onDemand : Constants.ANALYTICS_DEFAULTS.scheduled) + ' - ';

    if (typeof appointment.isAfterHours === 'boolean') {
      label += appointment.isAfterHours ? AppointmentHours.AfterHours : AppointmentHours.BusinessHours;
    } else if (typeof appointment.businessHours === 'boolean') {
      label += appointment.businessHours ? AppointmentHours.BusinessHours : AppointmentHours.AfterHours;
    } else {
      label += 'unknown hours';
    }

    label += ' - ' + (appointment.policyId ? 'redeemed benefit' : 'no benefit');
    label += ' - ' + (this._customDimensions?.userCustomerType || Constants.ANALYTICS_DEFAULTS.userCustomerType);

    this.trackEvent('AppointmentComplete', 'Appointment Complete', appointment.appointmentId, label);
  }

  prescriptionComplete(
    appointmentOrOrderId: string,
    quickscript?: QuickScript,
    pricingTypes?: string,
    appointment?: Appointment,
    prescriptionType?: string
  ): void {
    let label: string = '';
    const source: string = appointment?.appointmentId ? 'appointment' : 'quickscript';

    if (quickscript) {
      if (quickscript.receivePrescriptionType == ReceivePrescriptionType.sendScriptToPharmacy) {
        label += ReceivePrescriptionType.sendScriptToPharmacy;
      } else if (quickscript.receivePrescriptionType == ReceivePrescriptionType.eScript) {
        label += ReceivePrescriptionType.eScript;
      } else {
        label += 'Home Delivery';
      }
    } else if (appointment && prescriptionType) {
      label +=
        prescriptionType == ReceivePrescriptionType.eScript
          ? ReceivePrescriptionType.eScript
          : ReceivePrescriptionType.sendScriptToPharmacy;
    }

    label += ' - ' + (pricingTypes || 'private');

    this.trackEvent('PrescriptionComplete', 'Prescription Complete', source + ' - ' + appointmentOrOrderId, label);
  }

  medicalCertificateComplete(appointmentId: string): void {
    this.trackEvent('MedicalCertificateComplete', 'Medical Certificate Complete', appointmentId);
  }

  referralComplete(appointmentId: string): void {
    this.trackEvent('ReferralComplete', 'Referral Complete', appointmentId);
  }

  pageNotFound(): void {
    // This event used to be 'PageNotFound', has been renamed just for PWA so it
    // doesn't conflict with the marketing site
    // this.trackEvent('PWA_PageNotFound', '404 Error', this.pageURL, this.referrerPageURL);

    // Due to a configuration change in GTM, we can use the PageNotFound event. Page paths will be
    // automatically detected.
    this.trackEvent('PageNotFound', '404 Error', this.pageURL, this.referrerPageURL);
  }

  pageScroll(scrollDepthThreshold: number, scrollDepthUnits: string): void {
    this.trackEvent('PageScroll', 'Page Scrolling', scrollDepthThreshold + ' ' + scrollDepthUnits, this.pageURL);
  }

  recordUnauthorisedAccess(url: string): void {
    const action: string = this._userId ? this._userId + ' | ' + url : url;

    this.trackEvent('UnauthorisedAccess', 'Unauthorised Access', action, null, null, true);
  }

  recordJSError(errorMessage?: any): void {
    const message: string = errorMessage ? String(errorMessage) : 'unspecified error';

    this.aiContext.trackException(new Error(message));
    this.trackEvent('JSError', 'JS Error', message, this.pageURL, null, true);
  }

  recordHTTPError(errorMessage?: any): void {
    const message: string = errorMessage ? String(errorMessage) : 'unspecified error';

    this.aiContext.trackException(new Error(message), 'HTTPError');
    this.trackEvent('HTTPError', 'HTTP Error', message, this.pageURL, null, true);
  }

  /**
   * @deprecated
   * @function recordHotJar
   * @description Record initialisation of HotJar.
   * Note: This function is no longer called when initialising the PWA (app.component),
   * instead we call its javascript equivalent on the first pageview, within the index.html file.
   * This was to ensure that the hotjar javascript had finished loading before the init event is recorded.
   *
   * @param {string} [hotjarId] HotJar account id
   */
  recordHotJar(hotjarId?: string): void {
    const action: string = this.hotjarService.hotjarRecordingStatus();
    this.trackEvent('HotJar', 'HotJar Init', action, hotjarId || String(environment.hotjarId || 0));
  }

  recordHotJarUserId(sessionId: string = 'unset', patientId: string = ''): void {
    // 'recording' or 'not recording' based on what is specified in the HotJar global settings parameter
    const action: string = sessionId !== 'unset' ? this.hotjarService.hotjarRecordingStatus() : 'not initialised';

    // Update the HotJar userId for this HotJar session (set to current patientId)
    this.hotjarService.setUserId(patientId, this.pageURL);

    this.trackEvent('HotJarUserId', 'HotJar Session', action, sessionId + ' - ' + patientId);
  }

  recordFacebookPixel(pixelId?: string): void {
    this.trackEvent('FacebookPixel', 'Facebook Pixel', 'Init', pixelId);
  }

  dropDownOption(dropDownId: string, dropDownSelectedOption: string): void {
    this.trackEvent('DropdownSelect', 'Dropdown Select', dropDownId, dropDownSelectedOption);
  }

  externalLinkClick(linkUrl: string): void {
    const hostName: string = this.getHostName(linkUrl);

    // One exception to external link tracking is the hostname: aveo.com.au
    if (!hostName.endsWith('aveo.com.au')) {
      this.trackEvent('ExternalLinkClick', 'External Link Click', hostName, this.pageURL);
    }
  }

  downloadFile(fileUrl: string = this.routePath, contentType: string): void {
    this.trackEvent('Download', 'Downloads', 'Download Attachment', fileUrl, { content_type: contentType });
  }

  socialLinkClick(socialMediaType: string): void {
    this.trackEvent('SocialLinkClick', 'Social Link Click', socialMediaType, this.pageURL);
  }

  subscribeNewsletter(): void {
    this.trackEvent('NewsletterSubscribe', 'Newsletter Subscription');
  }

  havingIssues(): void {
    this.trackEvent('HavingIssues', 'Having Issues');
  }

  appointmentCancellation(appointmentId: string): void {
    this.trackEvent('AppointmentCancellation', 'Appointment Cancellation', null, appointmentId);
  }

  /**
   * @function appointmentReschedule
   * @description Triggered when patient reschedules an existing appointment (appointment has an appointmentId)
   */
  appointmentReschedule(appointmentId: string): void {
    this.trackEvent('RescheduleAppointment', 'Appointment Rescheduled', null, appointmentId);
  }

  /**
   * @function changeAppointmentTime
   * @description Triggered when patient changes appointment time for an appointment not yet booked (no appointmentId)
   */
  changeAppointmentTime(): void {
    this.trackEvent('ChangeAppointmentTime', 'Change Appointment Time', 'Change Time');
  }

  changeAppointmentPractitioner(practitionerData?: string): void {
    this.trackEvent('ChangeAppointmentPractitioner', 'Change Practitioner', null, practitionerData);
  }

  appointmentFollowUpInit(followUpId: string): void {
    this.trackEvent('FollowUpAppointmentInit', 'Appointment Follow-Up', 'Init', followUpId);
  }

  appointmentFollowUpComplete(followUpId: string): void {
    this.trackEvent('FollowUpAppointmentComplete', 'Appointment Follow-Up', 'Complete', followUpId);
  }

  // resendPrescriptionToPharmacy(prescriptionType: ReceivePrescriptionType): void {
  //   const label: string = prescriptionType === ReceivePrescriptionType.sendScriptToPharmacy
  //     ? 'Regular Script'
  //     : prescriptionType;

  //   this.trackEvent('ResendToPharmacy', 'Resend Prescription', 'Resend to Pharmacy', label);
  // }

  resendPrescriptionToPharmacy(prescription: Prescription): void {
    let label: string = null;

    if (prescription) {
      label = prescription.appointmentId
        ? 'appointment - ' + prescription.appointmentId
        : 'quickscript - ' + prescription.orderId;
      label += ' - ' + (prescription.isElectronicPrescription ? ReceivePrescriptionType.eScript : 'Regular Script');

      if (prescription.pharmacyName) {
        label += ' - ' + ReceivePrescriptionType.sendScriptToPharmacy;
      } else if (prescription.isElectronicPrescription) {
        label += ' - ' + 'SMS';
      }
    }

    this.trackEvent('ResendToPharmacy', 'Resend Prescription', 'Resend to Pharmacy', label);
  }

  sendMedicalCertificateToEmployer(appointmentId: string): void {
    this.trackEvent('SendToEmployer', 'Send Medical Certificate', 'Send to Employer', appointmentId);
  }

  sendReferralToSpecialist(appointmentId: string): void {
    this.trackEvent('SendToSpecialist', 'Send Referral', 'Send to Specialist', appointmentId);
  }

  appointmentFilter(): void {
    this.trackEvent('AppointmentFilter', 'Appointment Filter');
  }

  applyBenefit(benefit: Benefit, benefitCode: string = 'empty'): void {
    const label: string = benefit?.policyId
      ? benefit.name + ' | ' + benefit.policyId
      : benefitCode + ' | INVALID BENEFIT';

    this.trackEvent('ApplyABenefit', 'Apply a Benefit', 'Benefit Applied', label);
  }

  redeemBenefit(benefitPolicyLabel: string): void {
    this.trackEvent('RedeemABenefit', 'Redeem a Benefit', 'Benefit Redeemed', benefitPolicyLabel);
  }

  medicationSearch(medication: Medication = null): void {
    const label: string = medication?.name
      ? medication.name.concat(medication.shortDescription ? ' - ' + medication.shortDescription : '')
      : null;

    this.trackEvent('MedicationSearch', 'Medication Search', 'Search', label);
  }

  /**
   * @deprecated
   * @function userGuideSearch
   * @description Triggered when the user searches User Guides and FAQs using the autocomplete search
   */
  userGuideSearch(): void {
    this.trackEvent('UserGuideSearch', 'User Guide Search');
  }

  /**
   * @function inboxInteraction
   *
   * @param {string} [type] view inbox, send message, reply to message, delete message
   */
  inboxInteraction(type?: string): void {
    this.trackEvent('Messages', 'Messages', type);
  }

  moveToStep(stepUri: string, label: string): void {
    this.trackEvent('ChangeStep', 'Step', null, stepUri.concat('|', label));
  }

  contactUs(): void {
    this.trackEvent('ContactUs', 'Contact Us');
  }

  switchPatient(patientLabel: string = null): void {
    this.trackEvent('SelectPatient', 'Select Patient', 'Switch Patients', patientLabel);
  }

  changePassword(): void {
    this.trackEvent('ChangePassword', 'Change Password');
  }

  updateCommunicationPreferences(sms = false, marketing = false, newsletter = false): void {
    const label: string = ''.concat(
      'sms:',
      sms ? 'yes' : 'no',
      '|marketing:',
      marketing ? 'yes' : 'no'
      // '|newsletter:', newsletter ? 'yes' : 'no'
    );

    this.trackEvent('ChangeCommunicationPreference', 'Change Communication Preference', null, label);
  }

  /**
   * @function testDevice
   *
   * @param {string} [type] video, microphone, internet speed
   */
  testDevice(type?: string): void {
    this.trackEvent('TestDevice', 'Test Device', type);
  }

  rateExperience(source: string, label: string): void {
    this.trackEvent('RateExperience', 'Rate Experience', source, label);
  }

  getStarted(): void {
    this.trackEvent('GetStarted', 'Get Started');
  }

  trackQuickScript(deliveryType: QuickScriptServiceType, price: ServicePrice): void {
    const totalCost: number = this.pricingService.calculateTotal(price);
    const formattedPrice: string = this.pricingService.formatPrice(totalCost);
    const delivery: string =
      'QuickScript ' + (deliveryType == QuickScriptServiceType.quickscriptdeliver ? 'delivered' : 'collect');
    const label: string = delivery.concat(' (', formattedPrice, ')');

    this.trackEvent(ServiceSource.quickscript.toLowerCase(), ServiceSource.prescription, 'Complete QuickScript', label);
  }

  trackPrescriptionType(prescriptionType: ReceivePrescriptionType): void {
    const label: string = 'Script Only '.concat(
      prescriptionType == ReceivePrescriptionType.sendScriptToPharmacy ? 'Snap and Send' : 'ETP2'
    );

    this.trackEvent(ServiceSource.prescription, ServiceSource.prescription, null, label);
  }

  /**
   * @function trackPrescriptionProduct
   *
   * @param {string} productName product name (ie. name - shortDescription)
   * @param {string} [medicalCondition] condition which this medication is designed to treat
   * @param {string|number} [price] only if delivered
   */
  trackPrescriptionProduct(productName: string, medicalCondition?: string, price?: string | number): void {
    const formattedPrice: string = this.pricingService.formatPrice(price);
    const label: string = ''.concat(
      '[',
      medicalCondition || 'unknown',
      '] ',
      productName,
      formattedPrice ? ' (' + formattedPrice + ')' : ''
    );

    this.trackEvent(ServiceSource.medication, ServiceSource.prescription, null, label);
  }

  /**
   * @function trackPrescriptionMedication
   * @description Same as trackPrescriptionProduct() but accepts product of type Medication
   *
   * @param {Medication} medication product of type Medication
   * @param {string} [medicalCondition] condition which this medication is designed to treat
   * @param {string|number} [price] only if delivered
   */
  trackPrescriptionMedication(medication: Medication, medicalCondition?: string, price?: string | number): void {
    const productLabel: string = String(medication.name).concat(
      medication.shortDescription ? ' - ' + medication.shortDescription : ''
    );

    this.trackPrescriptionProduct(productLabel, medicalCondition, typeof price === 'number' || price ? price : null);
  }

  /**
   * @function trackMedicationShipping
   *
   * @param {string} [price] only if delivered
   */
  trackMedicationShipping(price?: string): void {
    const formattedPrice: string = this.pricingService.formatPrice(price);
    const label: string = 'Send Medication To Patient'.concat(formattedPrice ? ' (' + formattedPrice + ')' : '');

    this.trackEvent(ServiceSource.shipping, ServiceSource.prescription, null, label);
  }

  /**
   * @function trackAppointmentBooked
   * @description Track an appointment booking with 'AppointmentBooked' event
   *
   * @param {AppointmentDTO} appointmentData
   * @param {Benefit} [benefit]
   */
  trackAppointmentBooked(
    appointmentData: AppointmentDTO,
    serviceAndProductsTotalRevenue: number,
    benefit?: Benefit
  ): void {
    this.trackAppointment(
      'AppointmentBooked',
      'Checkout',
      this.getAppointmentLabel(appointmentData, benefit),
      serviceAndProductsTotalRevenue
    );

    console.log('TrackAppointmentBooked', { handler: this.whitelabelHandler });
    this.whitelabelHandler?.Trigger(WhitelabelAnalyticsEventType.AppointmentBooked);
  }

  /**
   * @function trackAppointment
   * @description Track an appointment event
   *
   * @param {string} eventName
   * @param {string} action
   * @param {string} appointmentLabel
   * @param {string|number} [price]
   */
  trackAppointment(eventName: string, action: string, appointmentLabel: string, price?: string | number): void {
    let label: string = appointmentLabel;
    if (price || typeof price === 'number') {
      label += ' (' + this.pricingService.formatPrice(price) + ')';
    }

    this.trackEvent(eventName, 'Appointment Booked', action, label);
  }

  /**
   * @function getAppointmentLabel
   * @description Join multiple appointment parameters into a product label in the format:
   * [service type] consult - [customer type] - [reimbursement type] - [appointment time] - [appointment type]
   *
   * @param {AppointmentDTO} appointmentData
   * @param {Benefit} [benefit]
   *
   * @returns {string} product label eg. "doctor consult - b2b Medibank - private - BH - scheduled"
   */
  getAppointmentLabel(appointmentData: AppointmentDTO, benefit?: Benefit): string {
    const isBenefitDoDMedicare: boolean = this.functions.isBenefitDoDMedicareB2C(benefit);
    const serviceType: string = (appointmentData?.serviceType || Constants.SERVICE_TYPE.DOCTOR) + ' consult';
    const customerType: string = isBenefitDoDMedicare
      ? CustomerType.b2c + ' DoDMedicare'
      : benefit?.customerType || Constants.ANALYTICS_DEFAULTS.userCustomerType;
    const reimbursementType: string = isBenefitDoDMedicare
      ? ReimbursementType.bulk
      : benefit?.policyId
        ? ReimbursementType.b2b
        : ReimbursementType.private;
    const hoursType: string = appointmentData?.isAfterHours
      ? AppointmentHours.AfterHours
      : AppointmentHours.BusinessHours;
    const appointmentType: string = appointmentData?.isOnDemand
      ? Constants.ANALYTICS_DEFAULTS.onDemand
      : Constants.ANALYTICS_DEFAULTS.scheduled;

    const appointmentLabel: string = [serviceType, customerType, reimbursementType, hoursType, appointmentType].join(
      ' - '
    );

    return appointmentLabel;
  }

  /**
   * @function trackAppointmentPurchase
   * @description Track an appointment ecommerce event
   *
   * DOC-5095 - Universal Analytics is deprecated, route all events to GA4
   *
   * @param {string} appointmentId Used as the transactionId
   * @param {AppointmentDTO} appointmentData
   * @param {number} serviceAndProductsTotalRevenue
   * @param {Benefit} [benefit]
   */
  trackAppointmentPurchase(
    appointmentId: string,
    appointmentData: AppointmentDTO,
    serviceAndProductsTotalRevenue: number,
    benefit?: Benefit
  ): void {
    const sourceName: string = 'AppointmentFlow';
    const serviceType: string = (appointmentData.serviceType || Constants.SERVICE_TYPE.DOCTOR) + ' consult';
    const servicePriceObj: ServicePrice = appointmentData.servicePrice;
    const serviceDiscount: number = this.pricingService.getAppointmentBenefitDiscount(appointmentData, benefit);
    const total: number = serviceAndProductsTotalRevenue;
    const tax: number = this.pricingService.calculateTax(servicePriceObj);
    const delivery: number = 0;
    const productName: string = this.getAppointmentLabel(appointmentData, benefit);
    const policy: string = appointmentData.policyId || null;

    const products: Product[] = [
      {
        sku: serviceType,
        name: productName,
        category: 'consult',
        price: serviceAndProductsTotalRevenue,
        discount: serviceDiscount, // % service was discounted by
        quantity: 1,
        sourceId: 'appointment',
        sourceName: 'appointment'
      }
    ];

    this.trackECommercePurchaseGA4(appointmentId, products, total, tax, delivery, policy, sourceName);
  }

  /**
   * @function trackQuickScriptPurchase
   * @description Track an eCommerce purchase of a QuickScript order
   *
   * DOC-5095 - Universal Analytics is deprecated, route all events to GA4
   *
   * @param {QuickScript} qsData QuickScript purchase request DTO with additional medication data
   * @param {string} orderId orderNumber or orderId
   * @param {number} serviceRevenue The revenue generated for DoD for the base QS service
   * @param {number} serviceAndProductsTotalRevenue The total revenue to be specified for the GA transactionTotal.
   * Consists of the revenue to dod for the base QS service + the cost of the medications if delivery.
   */
  trackQuickScriptPurchase(
    qsData: QuickScript,
    orderId: string,
    serviceRevenue: number,
    serviceAndProductsTotalRevenue: number,
    benefit?: Benefit
  ): void {
    const sourceName: string = ServiceSource.quickscript;
    const sourceId: string = sourceName.toLowerCase();
    const category: string = ServiceSource.prescription.toLowerCase(); // default category

    const servicePrice: ServicePrice = qsData.servicePrice;
    const isDelivered: boolean = qsData.serviceType == QuickScriptServiceType.quickscriptdeliver;
    const policyId: string = qsData.policyId || null;

    let tax: number = this.pricingService.calculateTax(servicePrice);
    tax = this.pricingService.roundCurrency(tax);

    // === MEDICATION ===
    const itemsOrdered: Product[] =
      qsData.orderItems?.map((medication: QuickScriptOrderItem) => {
        const medicationName: string = medication.medicationFullName.concat(
          ' - ',
          medication.condition || 'unspecified',
          ' - ',
          medication.dosage || 'n/a',
          ' - ',
          medication.pricingType
        );

        return {
          sku: medication.medicationId,
          brand: medication.brandName,
          name: medicationName,
          category,
          quantity: medication.quantity ?? 1,
          price: isDelivered ? medication.price : 0, // special case for order items, track the actual amount paid rather than revenue generated
          discount: 0,
          sourceId: 'quickscript_medication',
          sourceName: 'QuickScript Medication'
        };
      }) ?? [];

    // === QUICKSCRIPT ===
    const serviceDiscount: number = this.pricingService.getQuickScriptBenefitDiscount(qsData, benefit);

    let pricingTypes: string[] = [];
    qsData?.orderItems?.forEach((qsOrderItem: QuickScriptOrderItem) => {
      if (qsOrderItem?.pricingType && pricingTypes.indexOf(qsOrderItem.pricingType) === -1) {
        pricingTypes.push(qsOrderItem.pricingType);
      }
    });

    const qsType: string =
      qsData.receivePrescriptionType == ReceivePrescriptionType.eScript
        ? ReceivePrescriptionType.eScript
        : isDelivered
          ? 'delivered'
          : 'collect';
    const reimbursementType: string = pricingTypes.length
      ? pricingTypes.join('/')
      : Constants.ANALYTICS_DEFAULTS.reimbursementType;
    const customerType: string = benefit?.customerType || Constants.ANALYTICS_DEFAULTS.userCustomerType;
    const qsEventName: string = `Prescription - ${customerType} - ${reimbursementType} - QuickScript ${qsType}`;

    // Place 'quickscript' first in the ecommerce item list
    itemsOrdered.unshift({
      sku: sourceId,
      name: qsEventName,
      category,
      quantity: 1,
      price: this.pricingService.roundCurrency(serviceRevenue),
      discount: serviceDiscount,
      sourceId,
      sourceName
    });

    // === SHIPPING ===
    let deliveryCost: number = 0;
    let deliveryDiscount: number = 0;

    servicePrice.productPrices?.forEach((product: ProductPrice) => {
      if (product?.serviceType && product.serviceType == ProductServiceType.sendToPatient) {
        deliveryCost = (product.productAmount || 0) + (product.productGst || 0) - (product.productDiscount || 0);
        deliveryDiscount = product.productDiscount;
      }
    });

    if (deliveryCost) {
      deliveryCost = this.pricingService.roundCurrency(deliveryCost);
    }
    if (deliveryDiscount) {
      deliveryDiscount = this.pricingService.roundCurrency(deliveryDiscount);
    }

    itemsOrdered.push({
      sku: 'shipping',
      name: 'Send Medication To Patient',
      category,
      quantity: 1,
      price: deliveryCost,
      discount: deliveryDiscount,
      sourceId,
      sourceName
    });

    // === PRESCRIPTION ===
    if (qsData.orderItems?.length) {
      const receivePrescriptionType: string =
        qsData.receivePrescriptionType === ReceivePrescriptionType.eScript
          ? ReceivePrescriptionType.eScript
          : ReceivePrescriptionType.sendScriptToPharmacy;

      itemsOrdered.push({
        sku: category,
        name: 'Prescription - ' + receivePrescriptionType,
        category,
        quantity: qsData.orderItems.length,
        price: 0,
        discount: 0,
        sourceId,
        sourceName
      });
    }

    this.trackECommercePurchaseGA4(
      orderId,
      itemsOrdered, // Product[]
      this.pricingService.roundCurrency(serviceAndProductsTotalRevenue),
      tax,
      deliveryCost,
      policyId,
      'QuickScriptFlow'
    );
  }

  /**
   * @function trackECommercePurchaseGA4
   * @description GA4 eCommerce tracker for a 'purchase' event
   *
   * @param {string} transactionId TransactionId (QuickScript orderId or appointmentId)
   * @param {Product[]} products list of products in the transaction
   * @param {number} total
   * @param {number} tax
   * @param {number} [deliveryCost=0]
   * @param {string} [policyId] policyId from applied Benefit
   * @param {string} [purchaseLocation] where was purchase made? - 'QuickScript' / 'Appointment' / other?
   */
  trackECommercePurchaseGA4(
    transactionId: string,
    products: Product[],
    total: number,
    tax: number,
    deliveryCost: number = 0,
    policyId?: string,
    purchaseLocation?: string
  ): void {
    let ecommerce: ECommerceGA4 = {
      transaction_id: transactionId, // transaction_id = GA4
      affiliation: 'DoctorsOnDemand',
      value: total,
      tax: tax,
      shipping: deliveryCost,
      currency: 'AUD',
      coupon: policyId || null,
      items: []
      // 'userId': this._userId,
    };

    if (Array.isArray(products) && products.length) {
      products.forEach((product: Product, index: number, _products: Product[]) => {
        let item = new ECommerceItem();

        item['item_id'] = product.sku;
        item['item_name'] = product.name;
        item['item_brand'] = product.brand || 'DoctorsOnDemand';
        item['item_category'] = product.category || null;
        item['index'] = index;
        item['coupon'] = policyId || null;
        item['price'] = product.price || 0;
        item['discount'] = product.discount || 0;
        item['quantity'] = product.quantity || 1;
        item['item_list_id'] = product.sourceId || null;
        item['item_list_name'] = product.sourceName || null;
        item['location_id'] = purchaseLocation || null;

        ecommerce.items.push(item);
      });
    }

    const dataLayer: any = {
      event: 'purchase',
      user_id: this._userId,
      // 'userId': this._userId,
      ecommerce: ecommerce,
      ...this.customDimensions
    };

    if (!this._isAnalyticsBlocked) {
      if (!window['dataLayer']) {
        window['dataLayer'] = [];
      }
      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push(dataLayer);
    } else {
      if (!window['dodDataLayer']) {
        window['dodDataLayer'] = [];
      }
      window['dodDataLayer'].push(dataLayer);

      // Forward this ecommerce transaction to the GA Measurement Protocol API
      this.analyticsProxyService.sendEcommerceTransaction({
        // identifiers
        GA4MeasurementId: this._ga4MeasurementId,

        // ecommerce transaction data
        transaction_id: transactionId,
        affiliation: 'DoctorsOnDemand',
        currency: 'AUD',
        value: total,
        tax,
        shipping: deliveryCost,
        coupon: policyId,
        items: ecommerce.items,

        // page data
        hostname: location.hostname,
        pwa_pageURL: this.pageURL,
        pwa_pagePath: this.routePath,
        pwa_referrer: this.referrerPageURL,
        pageTitle: this.pageTitle,

        // custom dimensions
        onDemandAppointmentAvailable: this.customDimensions.onDemandAppointmentAvailable,
        onDemandAppointmentQueueSize: this.customDimensions.onDemandAppointmentQueueSize,
        nextAppointmentAvailability: this.customDimensions.nextAppointmentAvailability,
        UserId: this.customDimensions.userId,
        userType: this.customDimensions.userType,
        userAgeAndGender: this.customDimensions.userAgeAndGender,
        userLocation: this.customDimensions.userLocation,
        multiAccountRelationship: this.customDimensions.multiAccountRelationship,
        userSmoker: this.customDimensions.userSmoker,
        userDrinker: this.customDimensions.userDrinker,
        userHasHeartProblems: this.customDimensions.userHasHeartProblems,
        userHasAllergies: this.customDimensions.userHasAllergies,
        userTakesMedication: this.customDimensions.userTakesMedication,
        userBMI: this.customDimensions.userBMI,
        userBloodPressureRange: this.customDimensions.userBloodPressureRange,
        userMedicalCards: this.customDimensions.userMedicalCards,
        userRegisteredThisSession: this.customDimensions.userRegisteredThisSession,
        userCustomerType: this.customDimensions.userCustomerType,
        HotjarId: this.customDimensions.HotjarId,
        allowAdwords: this.customDimensions.allowAdwords
      });
    }

    console.log('GA4 transaction: ', dataLayer);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  testEvents(): void {
    this.redeemBenefit('abc9876543');
    this.recordJSError('test js error 5');
    this.recordHTTPError('test http error 5');
    this.recordHotJar('Hotjar12345678');
    this.recordHotJarUserId('abcdefgh-ijklmn-opqrst-quvwxyz');
    this.recordUnauthorisedAccess('https://test5.com');
    this.pageNotFound();
    this.externalLinkClick('extTest8');
    this.downloadFile('https://urlToFile/6', 'pdf');
    this.socialLinkClick('twitter');
    this.havingIssues();
    this.appointmentCancellation('test123456');
    this.appointmentReschedule('appointment1234567');
    this.appointmentFilter();
    this.changeAppointmentTime();
    this.changeAppointmentPractitioner('practitioner12345');
    this.appointmentFollowUpInit('followUp1234567');
    this.appointmentFollowUpComplete('followUp1234567');
    this.medicalCertificateComplete('appointment1234567');
    this.referralComplete('appointment1234567');
    this.patientRegistered('patId98765');
    this.patientLoggedIn('OAuth');
    this.switchPatient('patId123456');

    let appointment = new Appointment();
    appointment.appointmentId = 'appointment12345';
    appointment.isAfterHours = true;
    appointment.isOnDemand = true;
    this.appointmentComplete(appointment);
    this.prescriptionComplete('appointment1234567', null, null, appointment, ReceivePrescriptionType.eScript);

    let prescription = new Prescription();
    prescription.appointmentId = 'appointment1234567';
    prescription.isElectronicPrescription = true;
    prescription.pharmacyName = null;
    this.resendPrescriptionToPharmacy(prescription);

    this.sendMedicalCertificateToEmployer('appointment1234567');
    this.sendReferralToSpecialist('appointment1234567');
    this.inboxInteraction(InboxInteraction.getMessages); // Messages event
    this.contactUs();
    this.changePassword();
    this.updateCommunicationPreferences(false, false, true);
    this.subscribeNewsletter();
    this.testDevice('webcam');
    this.rateExperience('quickscript', '5');
    this.getStarted();
    this.startLiveChat();
    this.conferenceStart('abcd1234', '05 Aug 5pm');
    this.conferenceComplete('abcd1234', '14mins');

    // Medication search
    let medication = new Medication();
    medication.name = 'testMedication5';
    medication.shortDescription = 'medDescription5';
    this.medicationSearch(medication);

    // Transaction tag (quickscript)
    this.trackECommercePurchaseGA4(
      'transactionId-Quickscript1',
      [
        {
          sku: 'sku12345',
          name: 'ProductName-Quickscript',
          category: 'category1',
          quantity: 1,
          price: 26.85
        }
      ],
      155.253,
      15.5,
      19.2,
      'abcdPolicy',
      'quickscript'
    );

    // Transaction tag (appointment)
    this.trackECommercePurchaseGA4(
      'transactionId-Appointment1',
      [
        {
          sku: 'sku1234567',
          name: 'ProductName-Appointment',
          category: 'category2',
          quantity: 1,
          price: 90
        }
      ],
      90.5,
      0.5,
      0,
      'somepolicy',
      'appointment'
    );

    this.pageView('https://test.doctorsondemand.com.au/app/dashboard5');
  }

  prependDeployFolderToPath(path: string): string {
    if (
      !path.startsWith('http') &&
      !path.startsWith(environment.deployFolder + '/') &&
      location.hostname !== 'localhost'
    ) {
      return environment.deployFolder.concat(path);
    }
    return path;
  }

  prependProtocolAndHostToPath(path: string): string {
    if (!path.startsWith('http')) {
      return 'https://' + location.host + path;
    }
    return path;
  }

  // CUSTOM DIMENSIONS
  get customDimensions(): CustomDimensionsDataLayer {
    if (!this._customDimensions) {
      this._customDimensions = new CustomDimensionsDataLayer();
    }
    return this._customDimensions;
  }
  set customDimensions(customDimensions: CustomDimensionsDataLayer) {
    if (customDimensions && Object.keys(customDimensions).length) {
      this._customDimensions = customDimensions;
    } else {
      this._customDimensions = new CustomDimensionsDataLayer();
    }
  }

  // USER ID (patientId)
  get userId(): string {
    return this._userId || null;
  }
  set userId(patientId: string) {
    this._userId = patientId || null;
  }

  // ROUTES

  // Referrer full URL
  get referrerPageURL(): string {
    return this.referrerPagePath
      ? this.prependProtocolAndHostToPath(this.prependDeployFolderToPath(this.referrerPagePath))
      : document.referrer;
  }

  // Referrer path only
  get referrerPagePath(): string {
    return this._previousPagePath || null;
  }
  set referrerPagePath(path: string) {
    this._previousPagePath = path;
  }

  // Router path only
  get routePath(): string {
    let url: string = this.router?.routerState?.snapshot?.url || '';

    if (!url) {
      url = location.pathname;
    }

    return this.prependDeployFolderToPath(url);
  }

  // Page full URL (using window.location)
  get pageURL(): string {
    return this.prependProtocolAndHostToPath(this.prependDeployFolderToPath(location.pathname));
  }

  // Page Title
  get pageTitle(): string {
    return this.titleService.getTitle();
  }

  // get referrer(): string {
  //   // return document.referrer ? (new URL(document.referrer)).pathname : this.routePath;
  //   // return document.referrer || document.location.href;
  //   return this.referrerPageRoute;
  // }

  get source(): string {
    return this.isNative ? Capacitor.getPlatform() + ' app' : 'pwa web';
  }
  get sourceAndVersion(): string {
    const version: string = this.isNative ? this.versionService.getCurrentVersionString() : environment.version;

    return (
      this.source +
      (version === 'dev' ? ' - ' : ' (v' + version + ') - ') +
      environment.environmentName +
      ' - ' +
      this._osAndBrowser
    );
  }

  // Labels for full URLs
  get referrerLabel(): string {
    return 'Referrer: '.concat(this.referrerPageURL);
  }
  get pageLabel(): string {
    return 'Page: '.concat(this.pageURL);
  }
}
