import { Injectable } from '@angular/core';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class QuickscriptFunctions {
  constructor() {}

  getAppointmentRequiredForMedicationCategoryMessage(medicationCategory: string): string {
    return `A <strong>${medicationCategory}</strong> appointment is required for the selected medication.`;
  }
}
