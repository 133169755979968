export class ECommerceItem {
  item_id: string;
  item_name: string;
  affiliation?: string = 'DoctorsOnDemand';
  coupon?: string;
  currency?: string = 'AUD';
  discount?: number = 0;
  index?: number = 0;
  item_brand?: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price: number;
  quantity: number;
}

export class ECommerceGA4 {
  transaction_id?: string;
  transactionId?: string;
  affiliation?: string = 'DoctorsOnDemand';
  value: number;
  tax: number;
  shipping?: number = 0;
  currency?: string = 'AUD';
  coupon?: string; // policyId
  userId?: string; // patientId
  items: Array<ECommerceItem>
}
