import { WeightLossLogEntry } from "./weightloss/weightLossLogEntry";

export class GeneralMedical {
  patientId?: string;

  // Medical History
  smoking?: boolean;
  smokingMore?: string;
  smokingMore2?: string;
  alcohol?: string;
  heartProblems?: boolean;
  heartProblemsMore?: string;
  heartProblemsMore2?: string;
  allergies?: boolean;
  allergiesMore?: string;
  otherMedication?: boolean;
  otherMedicationMore?: string;
  familyHistory?: boolean;
  familyHistoryMore?: string;
  majorSurgery?: boolean;
  majorSurgeryMore?: string;
  bloodPressure?: boolean;
  bloodPressureValue?: string;
  height?: string;              // used for BMI calculations and ActiveCampaign events
  weight?: string;              // used for BMI calculations and ActiveCampaign events

  // Weight Loss Check-In
  currentHeight?: number;       // copy numeric value of 'height'
  currentWeight?: number;       // copy numeric value of 'weight'
  currentWaist?: number;
  goalWeight?: number;
  goalWaist?: number;
  originalWeight?: number;      // the first recording of 'currentWeight'
  originalWaist?: number;       // the first recording of 'currentWaist'
  approvalStatus?: string;      // 'Yes' (qualified), 'No', (not qualified), 'Pending' (WL appointment booked)

  // Weight Loss Yes/No Medical Questions
  pregnantOrBreastFeeding?: boolean;
  wasPregnantInLastYear?: boolean;
  highBloodPressure?: boolean;
  highCholesterolOrTriglycerides?: boolean;
  type1Diabetes?: boolean;
  type2Diabetes?: boolean;
  obstructiveSleepApnoea?: boolean;
  anxiety?: boolean;
  depression?: boolean;
  postTraumaticStressDisorder?: boolean;
  substanceAbuseDisorder?: boolean;
  schizophrenia?: boolean;

  // Weight Loss Extra
  patientWeightLossProfileId?: string; // GUID
  patientWeightLossLogs?: Array<WeightLossLogEntry>;
  created?: string;  // weight loss profile creation date
  modified?: string; // weight loss profile last updated date
}
