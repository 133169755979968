import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@app/core/services/logger.service';
import { environment } from '@env/environment';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { AvailabilitySearch } from '../models/availabilitySearch';
import { Practitioner } from '../models/practitioner';
import { PromiseHelperService } from './promise-helper.service';

const log: Logger = new Logger('ApiService');

@Injectable({
  providedIn: 'root'
})
export class PractitionerService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private readonly practitionerUrl: string = `${this.url}/practitioner`;

  // Full list of practitioners (unfiltered), stored in session storage as
  // key/value pairs in the form practitionerId: { ...practitionerData }
  private _practitioners: any;
  // Selected practitioner (for an appointment)
  private _practitioner: Practitioner | null = null;
  // Filtered list of practitioners (for an appointment)
  private _practitionerList: Practitioner[] | null = [];

  private _practitionerChange: Subject<Practitioner> = new Subject<Practitioner>();
  public practitionerChangeObs = this._practitionerChange.asObservable();

  private _practitionerListChange: Subject<Practitioner[]> = new Subject<Practitioner[]>();
  public practitionerListChangeObs = this._practitionerListChange.asObservable();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private storage: SessionStorageService,
    private promiseHelperService: PromiseHelperService
  ) {}

  setPractitioner(practitioner?: Practitioner): void {
    this._practitionerChange.next((this._practitioner = practitioner || null));
  }

  setPractitionerById(practitionerId: string): Practitioner {
    if (!practitionerId) {
      this.setPractitioner(null);
    } else if (this.practitionerList?.length) {
      let practitioner: Practitioner = this.practitionerList.find(
        (prac: Practitioner) => prac.practitionerId === practitionerId
      );

      if (practitioner) {
        practitioner.checked = true;
        this.setPractitioner(practitioner);
        return practitioner;
      }
    }

    return null;
  }

  setPractitionerList(practitionerList?: Practitioner[]): void {
    this._practitionerListChange.next((this._practitionerList = practitionerList || []));
  }

  setStoredPractitioner(practitioner: Practitioner): void {
    if (!this._practitioners) {
      this._practitioners = {};
    }

    this._practitioners[practitioner.practitionerId] = practitioner;

    try {
      this.storage.store(Constants.LocalStorage_Key.practitioners, this._practitioners);
    } catch (_err: any) {}
  }

  savePractitionerList(
    practitioners: Practitioner[] | null,
    setPractitionerList: boolean = false,
    storePractitionerList: boolean = false
  ): void {
    if (setPractitionerList) {
      this.setPractitionerList(practitioners);
    }

    if (storePractitionerList) {
      try {
        this.storage.store(Constants.LocalStorage_Key.practitionerList, practitioners);
      } catch (_err: any) {}
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/availabilities/practitioners
  getAvailabilityPractitioners(
    availabilitySearch: AvailabilitySearch,
    storeResults: boolean = false,
    setPractitionerList: boolean = true
  ): Promise<Practitioner[] | null> {
    let params = new HttpParams();
    let search: AvailabilitySearch = { ...availabilitySearch };

    if (search?.startDatePatientLocal?.indexOf('+') > 0) {
      search.startDatePatientLocal = this.functions.formatStringDateForAPI(search.startDatePatientLocal);
    }
    if (search?.endDatePatientLocal?.indexOf('+') > 0) {
      search.endDatePatientLocal = this.functions.formatStringDateForAPI(search.endDatePatientLocal);
    }

    const promiseStorageKey: string = 'practitionerListRequest';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<AvailabilitySearch>(
      promiseStorageKey,
      search,
      Constants.API_Polling_Times.practitionerList_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      for (const key of Object.keys(search)) {
        if (typeof search[key] !== 'undefined') {
          params = params.append(key, search[key]);
        }
      }

      const newPromise: Promise<Practitioner[]> = this.http
        .get(`${this.url}/availabilities/practitioners`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (Array.isArray(response?.response?.availablePractitioners)) {
            let practitioners = response.response.availablePractitioners as Practitioner[];

            // Filter out blank practitioners
            practitioners = practitioners.filter(
              (practitioner: Practitioner) => practitioner.practitionerId !== Constants.BlankGUID
            );

            // Copy over the practitioner name to another variable
            practitioners.forEach((practitioner: Practitioner) => {
              if (practitioner.name?.length) {
                practitioner.practitionerName = practitioner.name.replace('  ', ' ').trim();
              }
              this.setStoredPractitioner(practitioner);
            });

            this.savePractitionerList(practitioners, setPractitionerList, storeResults);

            return practitioners;
          }

          this.savePractitionerList([], setPractitionerList, storeResults);

          return [];
        })
        .catch((err: any) => {
          const errorMessage: string = this.functions.getErrorMessage(err);
          console.warn('Unable to retrieve practitioner availabilities. Error = ', errorMessage);

          this.promiseHelperService.setErrorState(promiseStorageKey, err);

          this.savePractitionerList([], setPractitionerList, storeResults);

          this.functions.handleError(err);

          return [];
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<Practitioner[]>(promiseStorageKey, newPromise, search);
    }

    return this.promiseHelperService.getPromiseByKey<Practitioner[] | null>(promiseStorageKey);
  }

  getPractitionerById(practitionerId: string): Promise<Practitioner> {
    if (!practitionerId) {
      return Promise.reject(null);
    } else if (
      this.practitioners &&
      typeof this._practitioners === 'object' &&
      this._practitioners[practitionerId] &&
      // Practitioner profile is incomplete without the AHPRA registration number
      this._practitioners[practitionerId].ahpraRegistrationNo
    ) {
      // console.log('[PRACTITIONER-SERVICE] getPractitionerById() ahpraRegistrationNo=TRUE, id =', practitionerId);
      return Promise.resolve(this._practitioners[practitionerId]);
    } else {
      return this.http
        .get(`${this.practitionerUrl}/${practitionerId}`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response && response.success) {
            let practitioner = response.response as Practitioner;
            const practitionerName: string = (practitioner.name || practitioner.practitionerName)
              .replace('  ', ' ')
              .trim();

            practitioner.practitionerName = practitionerName;

            this.setStoredPractitioner(practitioner);

            return practitioner;
          }
          return null;
        })
        .catch((err) => {
          return this.functions.handleError(err);
        });
    }
  }

  get practitioner(): Practitioner | null {
    return this._practitioner;
  }

  get practitioners(): any {
    if (!this._practitioners) {
      try {
        this._practitioners = this.storage.retrieve(Constants.LocalStorage_Key.practitioners) || {};
      } catch (_err: any) {}
    }
    return this._practitioners;
  }

  get practitionerList(): Practitioner[] | null {
    return this._practitionerList;
  }

  get storedPractitionerList(): Practitioner[] {
    let practitionerList: Practitioner[] = null;

    try {
      practitionerList = this.storage.retrieve(Constants.LocalStorage_Key.practitionerList) as Practitioner[];
    } catch (_err: any) {}

    return practitionerList;
  }
}
