import { AbstractControl, ValidationErrors } from '@angular/forms';

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  const input: string = control.value ?? '';
  let isValid: boolean = false;

  if (!input) {
    isValid = true;
  } else {
    const isWhitespace: boolean = input.trim().length === 0;
    isValid = !isWhitespace;
  }

  if (!isValid) {
    return { 'whitespace': true };
  } else {
    return null;
  }
}
