<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 pl-0">
      <p class="subtitle">{{ dialogData.subtitle }}</p>
      <p class="title">{{ dialogData.title }}</p>
    </div>
    <div class="col-2">
      <button
        type="button"
        class="btn-block close"
        aria-label="Close"
        (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body microphone-test container-fluid text-secondary text-center">
    <p style="margin-bottom: 24px" class="col-12">
      Press the start button and speak into your microphone. If you see movement on the
      graph your microphone is working correctly.
    </p>
    <div class="col-8 mx-auto">
      <button
        mat-flat-button
        color="accent"
        class="btn-block text-wrap"
        (click)="start()"
        [disabled]="isStarted">
        Start
      </button>
    </div>
    <div id="waveform" class="wave" [class.rborder-black]="isStarted"></div>
  </div>
  <div class="modal-footer container-fluid bg-muted">
    <div class="col-12 m-0">
      <button
        mat-stroked-button
        color="accent"
        class="btn-block text-wrap"
        (click)="onClose()">
        Close
      </button>
    </div>
  </div>
</div>
