<div class="pdf-viewer-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 pl-0">
      <p class="subtitle">{{ dialogData.subtitle }}</p>
      <p class="title">{{ dialogData.title }}</p>
    </div>
    <div class="col-2 pr-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="container-fluid text-primary modal-body" [class.modal-body-multiple]="dialogData.isMultiple">
    <div *ngIf="dialogData.note" class="dialog-note noprint col-12 px-0">
      <div [innerHTML]="dialogData.note"></div>
    </div>

    <div *ngIf="isLoading" class="my-5">
      <loading-spinner></loading-spinner>
    </div>
    <section [class.d-none]="isLoading">
      <div
        *ngFor="let url of urlList; let i = index"
        id="dod-attachment-{{i+1}}"
        class="attachment-wrapper"
        [class.mb-3]="urlList.length > 1">
        <!-- PDF -->
        <pdf-viewer
          *ngIf="extList[i]"
          [pdfUrl]="url"
          style="max-width: 100%; height: 100%; border: 0">
        </pdf-viewer>
        <!-- Image -->
        <img
          *ngIf="!extList[i]"
          [src]="url"
          alt="attachment"
          style="max-width: 100%; height: 100%; border: 0" />
      </div>
    </section>
  </div>
  <div
    *ngIf="dialogData.isMultiple && (dialogData.attachments || dialogData.isDownloadDisabled)"
    class="modal-footer container-fluid bg-muted">
    <div class="row m-0 w-100">
      <div class="first-button" [ngClass]="isNative ? 'd-none' : 'col-6'">
        <print-button [isInModalFooter]="true"></print-button>
      </div>
      <div class="second-button" [ngClass]="isNative ? 'col-12' : 'col-6'">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="dialogData.isMultiple && !dialogData.attachments && !dialogData.isDownloadDisabled && dialogData.attachmentId"
    class="modal-footer container-fluid bg-muted">
    <div class="row m-0 w-100">
      <div class="first-button" [ngClass]="isNative ? 'col-6' : 'col-4'">
        <download-attachment-button
         [attachmentId]="dialogData.attachmentId"
         [attachmentFileName]="dialogData.attachmentFileName"
         [isInModalFooter]="true">
        </download-attachment-button>
      </div>
      <div class="first-button" [ngClass]="isNative ? 'd-none' : 'col-4'">
        <print-button [isInModalFooter]="true"></print-button>
      </div>
      <div class="second-button" [ngClass]="isNative ? 'col-6' : 'col-4'">
        <button
          mat-flat-button
          color="accent"
          class="btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="!dialogData.isMultiple || (!dialogData.attachments && !dialogData.attachmentId)"
    class="modal-footer bg-muted flex-nowrap">
    <button
      mat-flat-button
      color="accent"
      class="btn-block m-0"
      (click)="onClose()">
      Close
    </button>
  </div>
</div>
