export class PatientCustomDimensions {
  // PROFILE
  // [0-100 - Male|Female]
  userAgeAndGender?: string;
  // [postCode]
  userLocation?: string;
  // [single account | [multi account - Daughter | Father | Son | Spouse | etc..]]
  multiAccountRelationship?: string;

  // MEDICAL HISTORY
  // [smoker | non smoker]
  userSmoker?: string;
  // [non-drinker | drinker | heavy drinker]
  userDrinker?: string;
  // [heart problems | no heart problems]
  userHasHeartProblems?: string;
  // [allergies | no allergies]
  userHasAllergies?: string;
  // [takes medication | does not take medication]
  userTakesMedication?: string;
  // [obese | overweight | healthy weight | under weight]
  userBMI?: string;
  // [low | normal | elevated | hypertension]
  userBloodPressureRange?: string;
  // [Medicare card, Concession card, Healthcare Card, IHI]
  userMedicalCards?: string;
}

export class CustomDimensions {
  // [on demand available | on demand not available] - [medicare available | medicare not available]
  onDemandAppointmentAvailable?: string; // onDemand doctor available (and patient has Medicare Card)
  // [0 - 100]
  onDemandAppointmentQueueSize?: number; // onDemand (min) queue size
  // [0 - 100]
  nextAppointmentAvailability?: number; // minutes before next available appointment (either onDemand or Scheduled)
  // [patientId]
  userId?: string; // current patient id
  // [logged in / not logged in]
  userType: string; // is patient logged in
  // [new patient / existing patient]
  userRegisteredThisSession?: string;
  // [b2c | b2b | rio-tinto | b2b complab | b2b city fertility]
  userCustomerType?: string;
  // [HotJar identifier]
  HotjarId?: string;
  // less than 4 people in queue and available appointment in less than 90 mins = true ELSE false
  allowAdwords: boolean = false; // AdWords not configured yet

  // PatientId: [patient specific custom dimensions]
  patient?: {
    [key: string]: PatientCustomDimensions
  }
}

// Combined general and patient CustomDimensions (max 20 params)
export class CustomDimensionsDataLayer {
  onDemandAppointmentAvailable?: string;
  onDemandAppointmentQueueSize?: number;
  nextAppointmentAvailability?: number;

  HotjarId?: string;
  allowAdwords: boolean = false;

  userId?: string;
  userType: string;
  userRegisteredThisSession?: string;
  userCustomerType?: string;
  userAgeAndGender?: string;
  userLocation?: string;
  multiAccountRelationship?: string;
  userSmoker?: string;
  userDrinker?: string;
  userHasHeartProblems?: string;
  userHasAllergies?: string;
  userTakesMedication?: string;
  userBMI?: string;
  userBloodPressureRange?: string;
  userMedicalCards?: string;
}
