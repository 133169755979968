import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { STEP_PATH } from '../step-configuration';
import { AppointmentService } from './appointment.service';

@Injectable({
  providedIn: 'root'
})
export class LiveChatService implements OnDestroy {
  // private subscription = new Subscription();
  isAgentOnline: boolean = false;
  showGetStartedButton: boolean = false;
  showLiveChatButton: boolean = false;
  showGetStarted: boolean = false;
  showLiveChat: boolean = true;
  serviceInitialised: boolean = false;
  isLoading: boolean = false;
  liveChatWidgetOpen: boolean = false;

  private _liveChatStatusChange: Subject<any> = new Subject<any>();
  public liveChatStatusChangeObs = this._liveChatStatusChange.asObservable();

  private _liveChatVisibilityChange: Subject<boolean> = new Subject<boolean>();
  public liveChatVisibilityChangeObs = this._liveChatVisibilityChange.asObservable();

  constructor(
    private router: Router,
    private appointmentService: AppointmentService
  ) {}

  /**
   * @function init
   * @description Initialise the service. To be called by components that implement this service.
   *
   * @param {boolean} [forceAPICall] regardless of other parameters, call the API for updates to the live chat status
   */
  init(forceAPICall: boolean = false): void {
    this.showGetStartedButton = !!this.showGetStarted;
    this.showLiveChatButton = !!this.showLiveChat;

    if (!this.serviceInitialised) {
      if (forceAPICall || this.shouldCheckLiveChatStatus()) {
        this.getLiveChatStatus();
      }

      // Listen for navigation events and trigger live chat status check
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.showGetStartedButton = !!this.showGetStarted;
          this.showLiveChatButton = !!this.showLiveChat;

          if (forceAPICall || this.shouldCheckLiveChatStatus()) {
            this.getLiveChatStatus();
          }
        }
      });

      this.serviceInitialised = true;
    }
  }

  checkRouteIsValid(): boolean {
    const currentRoute: string = this.router.routerState.snapshot.url;
    if (currentRoute.indexOf(STEP_PATH.DASHBOARD + '/contact-us') !== -1) {
      return false;
    }

    return true;
  }

  shouldCheckLiveChatStatus(): boolean {
    const showLiveChatButton: boolean = !!this.showLiveChatButton;
    const showGetStartedButton: boolean = !!this.showGetStartedButton;

    if (!this.checkRouteIsValid()) {
      this.showLiveChatButton = false;
      this.showGetStartedButton = true;

      if (showLiveChatButton !== this.showLiveChatButton || showGetStartedButton !== this.showGetStartedButton) {
        this._liveChatStatusChange.next(null);
      }

      return false;
    }

    return true;
  }

  getLiveChatStatus(): void {
    if (!this.isLoading) {
      this.isLoading = true;

      this.appointmentService
        .getAgentOnlineStatus()
        .then((response: boolean) => {
          this.isAgentOnline = response;
        }).catch((_err) => {
          this.isAgentOnline = false;
        }).finally(() => {
          this.isLoading = false;
          this._liveChatStatusChange.next(null);
        });
    }
  }

  openLiveChat(): void {
    this._liveChatStatusChange.next(null);
    this._liveChatVisibilityChange.next(this.liveChatWidgetOpen = true);

    // We now track the LiveChat event upon user interaction with the chat widget
    // this.analytics.startLiveChat();
  }

  closeLiveChat(): void {
    this._liveChatVisibilityChange.next(this.liveChatWidgetOpen = false);
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}
