import { Injectable, OnDestroy } from '@angular/core';
import { AIContext, AppInsightsService } from './appinsights.service';

@Injectable({
  providedIn: 'root'
})
export class ModalKeeperService implements OnDestroy {
  aiContext: AIContext;
  private _openModals: Record<string, string> = {};
  private _relatedModals: Record<string, string[]> = {};

  constructor(private aiService: AppInsightsService) {
    this.aiContext = this.aiService.createContext('ModalKeeperService');
  }

  ngOnDestroy(): void {
    this.resetService();
  }

  resetService(): void {
    this._openModals = {};
    this._relatedModals = {};
  }

  /**
   * @function registerModalOpened
   * @description Add a record for an open modal popup using the title as a key and the message
   * as the detail, which is compared to other open modals to determine if they are the same.
   *
   * @param {string} title used to generate the modal's storage key
   * @param {string} [message] modal text (some modals won't have any text)
   * @param {string} [source] name of service or component (for logging only)
   * @param {string[]} [relatedModals] titles of other modals which can be opened by action buttons
   * found in the modal being registered
   */
  registerModalOpened(title: string, message?: string, source?: string, relatedModals?: string[]): void {
    const key: string = this.getKey(title);

    if (key) {
      this.aiContext.debugLog(key, { dialogOpened: title, source });
      this._openModals[key] = message?.length ? this.getStandardisedMessage(message) : null;

      if (relatedModals?.length) {
        this._relatedModals[key] = relatedModals.map((modalTitle: string) => this.getKey(modalTitle));
      }
    }
  }

  /**
   * @function registerModalClosed
   * @description Remove the record for an opened modal based on its title as the key and the message as
   * the detail that is compared. If title and message match an existing modal, the modal's 'opened' record is removed
   *
   * @param {string} title used to generate the modal's storage key
   * @param {string} [message] modal text (some modals won't have any text)
   * @param {string} [source] name of service or component (for logging only)
   * @param {boolean} [removeRelated=false] for the current modal, remove the array of related modals
   */
  registerModalClosed(title: string, message?: string, source?: string, removeRelated: boolean = false): void {
    const key: string = this.getKey(title);

    if (key) {
      this.aiContext.debugLog(key, { dialogClosed: title, source });

      if (removeRelated) {
        this.removeRelatedModals(key);
      }

      // if (
      //   typeof this._openModals[key] !== 'undefined' &&
      //   (!message || (message && this._openModals[key] === this.getStandardisedMessage(message)))
      // ) {

      if (typeof this._openModals[key] !== 'undefined') {
        delete this._openModals[key];

        const relatedModals: any = { ...this._relatedModals };
        for (let modalTitle in relatedModals) {
          if (this._relatedModals[modalTitle]?.length) {
            const matchingModalIndex: number = this._relatedModals[modalTitle].indexOf(key);
            if (matchingModalIndex !== -1) {
              delete this._relatedModals[modalTitle];
            }
          }
        }
      }
    }
  }

  /**
   * @function isModalOpen
   * @description Check if a modal with the specified title and message is currently open.
   *
   * @param {string} title eg. Practitioners Unavailable
   * @param {string} [message] eg. Apologies, there are no longer any practitioners available...
   *
   * @returns {boolean}
   */
  isModalOpen(title: string, message?: string): boolean {
    const key: string = this.getKey(title);

    if (!key) {
      return false;
    }

    const isModalOpen: boolean =
      typeof this._openModals[key] !== 'undefined' &&
      (!message || this._openModals[key] === this.getStandardisedMessage(message));

    this.aiContext.debugLog(key, { isOpen: isModalOpen, dialogOpenCheck: title });

    return isModalOpen;
  }

  /**
   * @function isModalOpenAndRelatedModalsClosed
   * @description Check if a modal with the specified title and message is currently open or if any of
   * the modals related to this modal are currently open.
   *
   * @param {string} title
   * @param {string} [message]
   *
   * @returns {boolean}
   */
  isModalOpenAndRelatedModalsClosed(title: string, message?: string): boolean {
    const isModalOpen: boolean = this.isModalOpen(title, message);

    if (isModalOpen) {
      return true;
    }

    const modalKey: string = this.getKey(title);
    const numRelatedModals: number = Object.keys(this._relatedModals)?.length ?? 0;

    let areRelatedModalsClosed: boolean = true;
    if (numRelatedModals && this._relatedModals[modalKey]) {
      this._relatedModals[modalKey].forEach((relatedModalTitle: string) => {
        if (this.isModalOpen(relatedModalTitle, null)) {
          areRelatedModalsClosed = false;
        }
      });
    }

    return !areRelatedModalsClosed;
  }

  /**
   * @function removeRelatedModals
   * @description Remove the array of related modals for the specified modal key
   *
   * @param {string} title
   */
  removeRelatedModals(title: string): void {
    const modalKey: string = this.getKey(title);
    if (modalKey && this._relatedModals[modalKey]) {
      delete this._relatedModals[modalKey];
      this.aiContext.debugLog(modalKey, { dialogClosed: title, relatedModals: this._relatedModals[modalKey] });
    }
  }

  /**
   * @function getKey
   * @description Generate standardised Key from a title
   *
   * @param {string} title eg. View Benefit
   *
   * @returns {string} eg. view-benefit
   */
  getKey(title: string): string {
    return title?.length ? title.replaceAll(/[\s-]/g, '').toLowerCase() : null;
  }

  /**
   * @function getStandardisedMessage
   * @description Standardise html tags found in error messages
   *
   * @param {string} message eg. There was an error.<br/>Please check your inputs
   *
   * @returns {string} eg. There was an error.<br>Please check your inputs
   */
  getStandardisedMessage(message: string): string {
    const formattedMessage: string = message?.length
      ? message
          .replaceAll(/(<br\/>)/g, '<br>')
          .replaceAll(/(\s\s)/g, ' ')
          .replaceAll(/(&#10;)|(\n)/g, '')
      : null;

    return formattedMessage;
  }
}
