import { Inject, Injectable, Optional } from '@angular/core';
import { PSM_CONFIG, PasswordValidationResponse } from './password-strength-meter.types';
import {
  OptionsType,
  ZxcvbnResult,
  zxcvbn,
  zxcvbnOptions,
  zxcvbnAsync,
  debounce,
  Matcher,
  Options
} from '@zxcvbn-ts/core';
import { matcherPwnedFactory } from '@zxcvbn-ts/matcher-pwned';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import { Constants } from '../../constants';
import { Observable, Subject } from 'rxjs';

export abstract class IPasswordStrengthMeterService {
  abstract passwordStrengthScore: number;
  abstract latestResult: ZxcvbnResult;
  abstract _resultUpdated: Subject<any>;
  abstract resultUpdatedObs: Observable<any>;

  abstract DEFAULT_DEBOUNCE_MS: number;
  abstract MIN_LENGTH: number;
  abstract MAX_LENGTH: number;
  abstract RECOMMENDED_LENGTH: number;
  abstract MIN_NUMERIC_CHARS: number;
  abstract RECOMMENDED_NUMERIC_CHARS: number;
  abstract MIN_ALPHA_CHARS: number;
  abstract RECOMMENDED_ALPHA_CHARS: number;
  abstract MIN_UPPERCASE_ALPHA_CHARS: number;
  abstract MIN_SPECIAL_CHARS: number;
  abstract RECOMMENDED_SPECIAL_CHARS: number;
  abstract SPACES_NOT_ALLOWED: boolean;

  abstract score(password: string, userInputs?: (string|number)[]): number;
  abstract scoreAsync(password: string, userInputs?: (string|number)[]): Promise<number>;
  abstract scoreWithFeedback(password: string, userInputs?: (string|number)[]): ZxcvbnResult;
  abstract scoreWithFeedbackAsync(password: string, userInputs?: (string|number)[]): Promise<ZxcvbnResult>;
  abstract debouncedZxcvbn(includeFeedback?: boolean, debounceMs?: number): any;
  abstract validatePassword(password: string): PasswordValidationResponse;
}

export const DEFAULT_CONFIG: OptionsType = {
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  translations: zxcvbnEnPackage.translations,
  useLevenshteinDistance: true,
  maxLength: Constants.Password_Strength_Configuration.MAX_LENGTH
};

@Injectable({
  providedIn: 'root'
})
export class PasswordStrengthMeterService extends IPasswordStrengthMeterService {
  _resultUpdated: Subject<ZxcvbnResult> = new Subject<ZxcvbnResult>();
  resultUpdatedObs: Observable<ZxcvbnResult> = this._resultUpdated.asObservable();

  passwordStrengthScore: number;
  latestResult: ZxcvbnResult;

  // Debounce milliseconds
  DEFAULT_DEBOUNCE_MS: number = Constants.Password_Strength_Configuration.DEFAULT_DEBOUNCE_MS;

  // Password strength configuration
  MIN_LENGTH: number = Constants.Password_Strength_Configuration.MIN_LENGTH;
  MAX_LENGTH: number = Constants.Password_Strength_Configuration.MAX_LENGTH;
  RECOMMENDED_LENGTH: number = Constants.Password_Strength_Configuration.RECOMMENDED_LENGTH;

  MIN_NUMERIC_CHARS: number = Constants.Password_Strength_Configuration.MIN_NUMERIC_CHARS;
  RECOMMENDED_NUMERIC_CHARS: number = Constants.Password_Strength_Configuration.RECOMMENDED_NUMERIC_CHARS;

  MIN_ALPHA_CHARS: number = Constants.Password_Strength_Configuration.MIN_ALPHA_CHARS;
  RECOMMENDED_ALPHA_CHARS: number = Constants.Password_Strength_Configuration.RECOMMENDED_ALPHA_CHARS;

  MIN_UPPERCASE_ALPHA_CHARS: number = Constants.Password_Strength_Configuration.MIN_UPPERCASE_ALPHA_CHARS;

  MIN_SPECIAL_CHARS: number = Constants.Password_Strength_Configuration.MIN_SPECIAL_CHARS;
  RECOMMENDED_SPECIAL_CHARS: number = Constants.Password_Strength_Configuration.RECOMMENDED_SPECIAL_CHARS;

  SPACES_NOT_ALLOWED: boolean = Constants.Password_Strength_Configuration.SPACES_NOT_ALLOWED;

  constructor(
    @Optional()
    @Inject(PSM_CONFIG)
    options: OptionsType
  ) {
    super();

    const matcherPwned: Matcher = matcherPwnedFactory(fetch, DEFAULT_CONFIG as Options);
    zxcvbnOptions.addMatcher('pwned', matcherPwned);

    if (options) {
      zxcvbnOptions.setOptions(options);
    } else {
      zxcvbnOptions.setOptions(DEFAULT_CONFIG);
    }
  }

  /**
   * @function setOptions
   * @description Set zxcvbn options
   *
   * @param {OptionsType} [options]
   */
  setOptions(options?: OptionsType): void {
    zxcvbnOptions.setOptions(options || DEFAULT_CONFIG);
  }

  /**
   * @function score
   * @description this will return the password strength score in number
   *  0 - too guessable
   *  1 - very guessable
   *  2 - somewhat guessable
   *  3 - safely unguessable
   *  4 - very unguessable
   *
   * @param {string} password - Password
   * @param {(string|number)[]} [userInputs] array of user inputs that cannot be used as part of the password
   */
  score(password: string, userInputs?: (string|number)[]): number {
    const result: ZxcvbnResult = zxcvbn(password, userInputs);

    this.passwordStrengthScore = result?.score || 0;
    this._resultUpdated.next(this.latestResult = result);

    console.log('Cracked in', result?.crackTimesSeconds, 'seconds');

    return this.passwordStrengthScore;
  }

  /**
   * @async
   * @function scoreAsync
   * @description Asyncronous version of the password scoring function.
   * this will return the password strength score in number
   *  0 - too guessable
   *  1 - very guessable
   *  2 - somewhat guessable
   *  3 - safely unguessable
   *  4 - very unguessable
   *
   * @param {string} password - Password
   * @param {(string|number)[]} [userInputs] array of user inputs that cannot be used as part of the password
   */
  async scoreAsync(password: string, userInputs?: (string|number)[]): Promise<number> {
    const result: ZxcvbnResult = await zxcvbnAsync(password, userInputs);

    this.passwordStrengthScore = result?.score || 0;
    this._resultUpdated.next(this.latestResult = result);

    // console.log('Cracked in', result?.crackTimesSeconds, 'seconds');

    return this.passwordStrengthScore;
  }

  /**
   * @function scoreWithFeedback
   * @description this will return the password strength score with feedback messages
   * return type { score: number; feedback: { suggestions: string[]; warning: string } }
   *
   * @param {string} password Password to score
   * @param {(string|number)[]} [userInputs] array of user inputs that cannot be used as part of the password
   */
  scoreWithFeedback(password: string, userInputs?: (string|number)[]): ZxcvbnResult {
    const result: ZxcvbnResult = zxcvbn(password, userInputs);

    this.passwordStrengthScore = result?.score || 0;
    this._resultUpdated.next(this.latestResult = result);

    return { score: result.score, feedback: result.feedback } as ZxcvbnResult;
  }

  /**
   * @function scoreWithFeedbackAsync
   * @description this will return the password strength score with feedback messages
   * return type { score: number; feedback: { suggestions: string[]; warning: string } }
   *
   * @param {string} password Password to score
   * @param {(string|number)[]} [userInputs] array of user inputs that cannot be used as part of the password
   */
  async scoreWithFeedbackAsync(password: string, userInputs?: (string|number)[]): Promise<ZxcvbnResult> {
    const result: ZxcvbnResult = await zxcvbnAsync(password, userInputs);

    this.passwordStrengthScore = result?.score || 0;
    this._resultUpdated.next(this.latestResult = result);

    return { score: result.score, feedback: result.feedback } as ZxcvbnResult;
  }

  /**
   * @function debouncedZxcvbn
   * @description Debounce password scoring functions
   *
   * @param {boolean} [includeFeedback] include feedback in the response
   *
   * @returns the debounced function
   */
  debouncedZxcvbn(includeFeedback?: boolean, debounceMs?: number): any {
    const debouncedFn: any = includeFeedback ? this.scoreAsync : this.scoreWithFeedbackAsync;
    debounce(debouncedFn, debounceMs ?? this.DEFAULT_DEBOUNCE_MS, false);

    return debouncedFn;
  }

  /**
   * @function validatePassword
   * @description Validate a password using a custom configuration
   *
   * @param {string} password password to validate
   *
   * @returns {PasswordValidationResponse}
   */
  validatePassword(password: string): PasswordValidationResponse {
    if (!password?.length) {
      return { isValid: true, errorCodes: [] } as PasswordValidationResponse;
    }

    let isValid: boolean = true;
    let errorCodes: string[] = [];
    const charArray: string[] = password.split('');

    if (password.length < this.MIN_LENGTH) {
      isValid = false;
      errorCodes.push('MIN_LENGTH');
    }
    if (password.length > this.MAX_LENGTH) {
      isValid = false;
      errorCodes.push('MAX_LENGTH');
    }

    // Alpha characters
    let occurs: number = this.occurs(charArray, /^[A-Za-z]$/);
    if (occurs < this.MIN_ALPHA_CHARS) {
      // console.log('Failed on MIN_ALPHA_CHARS (' + this.MIN_ALPHA_CHARS + '),', occurs, 'found');
      isValid = false;
      errorCodes.push('MIN_ALPHA_CHARS');
    } else {
      // console.log('PASSED MIN_ALPHA_CHARS (' + this.MIN_ALPHA_CHARS + '),', occurs, 'found');
    }

    // Uppercase Alpha characters
    occurs = this.occurs(charArray, /^[A-Z]$/);
    if (occurs < this.MIN_UPPERCASE_ALPHA_CHARS) {
      // console.log('Failed MIN_UPPERCASE_ALPHA_CHARS (' + this.MIN_UPPERCASE_ALPHA_CHARS + '),', occurs, 'found');
      isValid = false;
      errorCodes.push('MIN_UPPERCASE_ALPHA_CHARS');
    } else {
      // console.log('PASSED MIN_UPPERCASE_ALPHA_CHARS (' + this.MIN_UPPERCASE_ALPHA_CHARS + '),', occurs, 'found');
    }

    // Digits
    occurs = this.occurs(charArray, /^[\d]$/);
    if (occurs < this.MIN_NUMERIC_CHARS) {
      // console.log('Failed MIN_NUMERIC_CHARS (' + this.MIN_NUMERIC_CHARS + '),', occurs, 'found');
      isValid = false;
      errorCodes.push('MIN_NUMERIC_CHARS');
    } else {
      // console.log('PASSED MIN_NUMERIC_CHARS (' + this.MIN_NUMERIC_CHARS + '),', occurs, 'found');
    }

    // Special characters
    occurs = this.occurs(charArray, Constants.specialCharactersRegExp);
    if (occurs < this.MIN_SPECIAL_CHARS) {
      // console.log('Failed MIN_SPECIAL_CHARS (' + this.MIN_SPECIAL_CHARS + '),', occurs, 'found');
      isValid = false;
      errorCodes.push('MIN_SPECIAL_CHARS');
    } else {
      // console.log('PASSED MIN_SPECIAL_CHARS (' + this.MIN_SPECIAL_CHARS + '),', occurs, 'found');
    }

    // Validate spaces
    if (this.SPACES_NOT_ALLOWED && this.occurs(charArray, /^[\s]$/)) {
      // console.log('Failed SPACES_NOT_ALLOWED (' + this.SPACES_NOT_ALLOWED + '),', 'at least one space exists');
      isValid = false;
      errorCodes.push('SPACES_NOT_ALLOWED');
    }
    if (charArray[0] === ' ') {
      // console.log('Failed SPACE_FIRST - space at start');
      isValid = false;
      errorCodes.push('SPACE_FIRST');
    } else if (charArray[charArray.length - 1] === ' ') {
      // console.log('Failed SPACE_LAST - space at end');
      isValid = false;
      errorCodes.push('SPACE_LAST');
    }

    // if (errorCodes?.length) {
    //   console.log('[PasswordStrengthValidator] ErrorCodes:', errorCodes);
    // }

    // We have passed all the validations and do not need to run the full regular expression
    return { isValid, errorCodes } as PasswordValidationResponse;
  }

  occurs(charArray: string[], regex: RegExp | any): number {
    let occurs: number = 0;

    for (let i = 0; i < charArray.length; i++) {
      if (regex.test(charArray[i])) {
        occurs++;
      }
    }

    return occurs;
  }
}
