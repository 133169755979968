<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10">
      <div class="modal-header-title">Please Select {{ getPractitionerChangeFriendlyLabel() | titlecase }}</div>
    </div>
    <div class="col-2">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="scrollable-container">
    <div class="modal-body container-fluid text-primary">
      <div class="appointment-time">
        <div class="row">
          <div class="col-sm-12 my-auto">
            <span class="img-icon d-xl-none">
              <img src="assets/images/time-appointment-icon.svg" class="img-fluid" alt="appointment time" />
            </span>
            <span class="header-text">Appointment Time</span>
          </div>
          <div class="col-12">
            <div class="detail-appointment">
              <div *ngIf="!appointment?.isOnDemand" class="row detail-appointment-row">
                <div class="col-12 col-xl-4 my-auto date-container">
                  <div
                    class="date-box date-shorter"
                    [title]="(appointmentDayDate || '') + ' ' + (appointmentDayMonth || '')">
                    <span class="date-box-date">
                      {{ appointmentDayDate || '' }}
                    </span>
                    <span class="date-box-month">
                      {{ appointmentDayMonth || '' }}
                    </span>
                  </div>
                  <div
                    class="date-box date-longer"
                    [title]="
                      (appointmentDayWeekday || '') +
                      ' ' +
                      (appointmentDayTime || '') +
                      ' - ' +
                      appointmentTimeZoneLabel
                    ">
                    <span class="date-box-day">
                      {{ appointmentDayWeekday || '' }}
                    </span>
                    <span class="date-box-time">
                      {{ appointmentDayTime || '' }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-xl-auto custom-col custom-divider"
                  [title]="'Practitioner Type: ' + (appointment?.serviceType || 'doctor')">
                  <span class="header">Practitioner Type</span>
                  <span class="content">{{ appointment?.serviceType || 'doctor' | titlecase }}</span>
                </div>
                <div
                  class="col-sm-12 col-xl-auto custom-col"
                  [title]="'Appointment Price: ' + (appointment?.price | currency)">
                  <span class="header">Fee</span>
                  <span class="content">{{ appointment?.price | currency }}</span>
                </div>
              </div>
              <div *ngIf="appointment?.isOnDemand" class="row detail-appointment-row on-demand">
                <div class="col-12 col-xl-2 my-auto date-container">
                  <div
                    class="date-box date-shorter"
                    [title]="
                      (appointmentDayDate || '') + ' ' + (appointmentDayMonth || '') + ' - ' + appointmentTimeZoneLabel
                    ">
                    <span class="date-box-date">
                      {{ appointmentDayDate || '' }}
                    </span>
                    <span class="date-box-month">
                      {{ appointmentDayMonth || '' }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-xl-4 custom-col custom-divider"
                  [title]="'Practitioner Type: ' + (appointment?.serviceType || 'doctor')">
                  <span class="header">Practitioner Type</span>
                  <span class="content">{{ appointment?.serviceType || 'doctor' | titlecase }}</span>
                </div>
                <div
                  class="col-sm-12 col-xl-3 custom-col"
                  [title]="'Appointment Price: ' + (appointment?.price | currency)">
                  <span class="header">Fee</span>
                  <span class="content">{{ appointment?.price | currency }}</span>
                </div>
                <div
                  class="col-sm-12 col-xl-3 custom-col"
                  [title]="onDemandQueueSize !== null ? 'Queue Size: ' + onDemandQueueSize : ''">
                  <ng-container *ngIf="onDemandQueueSize !== null">
                    <span class="header">Queue Size</span>
                    <span class="content">{{ onDemandQueueSize }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content">
      <div *ngIf="isFilteredData" class="row clear-filters">
        Note: your appointment filters are still applied.&nbsp;<a (click)="resetFilters($event)">Clear Filters</a>.
      </div>
      <div *ngIf="this.appointment?.isOnDemand" class="row doctor list">
        <div class="col-12 col-xl-8 custom-col separator">
          <span class="doctor-image"></span>
          <div class="doctor-detail">
            <span class="header"> First available {{ getPractitionerChangeFriendlyLabel() | titlecase }} </span>
          </div>
        </div>
        <div class="col-sm-12 col-xl-4 custom-col">
          <button
            *ngIf="selectedPractitioner !== null"
            mat-stroked-button
            color="accent"
            class="select-button"
            (click)="selectFirstAvailable()">
            Select
          </button>
          <button
            *ngIf="selectedPractitioner === null"
            mat-flat-button
            color="accent"
            class="select-button"
            (click)="selectFirstAvailable()">
            Selected
          </button>
        </div>
      </div>
      <ng-container *ngIf="practitionerList?.length">
        <div *ngFor="let practitioner of practitionerList; let i = index" class="row doctor list" [attr.data-index]="i">
          <div class="col-12 col-xl-8 custom-col separator">
            <span class="doctor-image">
              <img
                *ngIf="practitioner.profileImageUrl"
                [src]="practitioner.profileImageUrl"
                loading="lazy"
                decoding="async"
                class="img-fluid"
                alt="practitioner photo" />
            </span>
            <div class="doctor-detail">
              <span class="header">
                {{ practitioner.practitionerName }}
                <on-demand-queue-size [onDemandQueueSize]="practitioner.onDemandQueueSize"> </on-demand-queue-size>
              </span>
              <span class="sub-header text-info" (click)="onShowProfile(practitioner.practitionerId)"> more info </span>
            </div>
          </div>
          <div class="col-sm-12 col-xl-4 custom-col">
            <button
              *ngIf="practitioner.practitionerId !== selectedPractitioner?.practitionerId"
              mat-stroked-button
              color="accent"
              class="select-button"
              (click)="selectPractitioner(i)">
              Select Practitioner
            </button>
            <button
              *ngIf="practitioner.practitionerId === selectedPractitioner?.practitionerId"
              mat-flat-button
              color="accent"
              class="select-button"
              (click)="selectPractitioner(i)">
              Selected
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row modal-footer">
    <div class="col-sm-12 m-0">
      <button mat-flat-button color="accent" class="select-button" (click)="onClose()">Close</button>
    </div>
  </div>
</div>
