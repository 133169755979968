import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HealthcareComponent } from '@app/pages/home/components/profile/healthcare/healthcare.component';
import { Functions } from '@app/shared/functions';
import { InputValidationFunctions } from '../../input-validation-functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from '../../constants';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'patient-set-mobile-phone-number-modal',
  templateUrl: './patient-set-mobile-phone-number-modal.component.html',
  styleUrls: ['./patient-set-mobile-phone-number-modal.component.scss'],
  providers: [HealthcareComponent]
})
export class PatientAddMobilePhoneNumberModalComponent implements OnInit, AfterViewInit {
  isSaving: boolean = false;

  patientId: string;
  existingMobilePhoneNumber: string;

  isMobile: boolean;

  @Output() mobilePhoneSet: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('mobilePhoneField') mobilePhoneField: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<PatientAddMobilePhoneNumberModalComponent>,
    private functions: Functions,
    public inputValidationFunctions: InputValidationFunctions,
    private formBuilder: FormBuilder,
    private patientService: PatientService
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.patientId = this.data?.patientId;
    this.existingMobilePhoneNumber = this.data?.mobilePhoneNumber;

    this.onResize();

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.createForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  personalDataFormGroup: FormGroup;

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  scrollToTop(): void {
    setTimeout(() => {
      try {
        document.getElementById('health-card-modal-wrapper').scrollTo(0, 0);
      } catch (err: any) {}
    }, 150);
  }

  createForm(): void {
    this.personalDataFormGroup = this.formBuilder.group({
      phone: [
        this.existingMobilePhoneNumber || '',
        [Validators.required, Validators.pattern(Constants.mobilePhoneRegex)]
      ]
    });
  }

  async saveAll(): Promise<void> {
    if (this.phone.invalid) {
      this.functions.showToast('Mobile phone number is invalid. Please check the value and try again.');
      return;
    }

    this.isSaving = true;

    var response = await this.patientService.updatePatientMobilePhoneNumber(this.patientId, this.phone.value);

    console.log('response', response);

    this.onClose(true);
  }

  onClose(mobileSet: boolean = false): void {
    this.dialogRef.close(mobileSet ? this.phone.value : null);
  }

  validateMobileNumber(event?: any): boolean {
    const isValid = Boolean(event && this.inputValidationFunctions.validateNumberPreventDefault(event, true));
    if (isValid) {
      if (this.inputValidationFunctions.validateMobileNumber(event)) {
        this.phone.setErrors(null);
        return true;
      } else {
        this.phone.setErrors({ pattern: true });
      }
    }

    return isValid;
  }

  get phone() {
    return this.personalDataFormGroup.get('phone');
  }
}
