import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Constants } from '../constants';
import { TimeZone } from '../models/time-zone';
import { HttpClient } from '@angular/common/http';
import { Functions } from '../functions';
import { Appointment } from '../models/appointment';
import { environment } from '@env/environment';
import { IResponseAPI } from '../models/api-response';
import StaticTimeZones from '@app/shared/static-resources/timezonesAustralia.json';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private _timeZoneOptions: TimeZone[] = [];

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private storage: LocalStorageService
  ) {
    // Load the static timezones into memory
    // this.timeZoneOptions = this.formatTimeZoneOptions(StaticTimeZones as TimeZone[]);
  }

  get timeZoneOptions() {
    if (!this._timeZoneOptions?.length) {
      let timezoneExpiry: number;
      try {
        timezoneExpiry = this.storage.retrieve(Constants.LocalStorage_Key.timezoneExpiry) as number;
      } catch (_err: any) {}

      try {
        if (timezoneExpiry && moment().isBefore(moment(timezoneExpiry))) {
          this._timeZoneOptions = this.storage.retrieve(Constants.LocalStorage_Key.timezones);
        }
      } catch (err: any) {
        console.log('Invalid TimeZone expiry date');
      }
    }

    return this._timeZoneOptions || [];
  }

  set timeZoneOptions(options: TimeZone[]) {
    if (Array.isArray(options) && options.length) {
      this._timeZoneOptions = options;

      try {
        this.storage.store(Constants.LocalStorage_Key.timezoneExpiry, moment().endOf('day').valueOf());
        this.storage.store(Constants.LocalStorage_Key.timezones, this._timeZoneOptions);
      } catch (_err: any) {}
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/timezones
  getTimezoneData(): Promise<TimeZone[]> {
    // If we have already retrieved the TimeZone options, return them instead of making another HTTP request
    if (this.timeZoneOptions?.length) {
      return Promise.resolve(this.timeZoneOptions);

      // Call the API to retrieve list of TimeZones
    } else {
      return this.http
        .get(`${this.url}/timezones`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            this.timeZoneOptions = this.formatTimeZoneOptions(response.response as TimeZone[]);
          }
          return this.timeZoneOptions;
        })
        .catch((_err: any) => {
          console.warn('Failed to retrieve Timezones from the API. Falling back to static list.');

          // Fall back to static timezone list and return it
          this._timeZoneOptions = this.formatTimeZoneOptions(StaticTimeZones as TimeZone[]);
          return this._timeZoneOptions;
        });
    }
  }

  formatTimeZoneOptions(options: TimeZone[]): TimeZone[] {
    // Format TimeZone text
    options.forEach((tz: TimeZone) => {
      if (!tz.offsetText && tz.offset) {
        tz.offsetText = this.functions.getTimeZoneOffsetString(tz.offset);
      }
    });

    options.sort((tz1: TimeZone, tz2: TimeZone) => {
      // Sort timezones by label only (capital city names)
      return tz1.timezoneLabel > tz2.timezoneLabel ? 1 : -1;
    });

    return options;
  }

  /**
   * @function getIanaTimeZone
   * @description Get the javascript (IANA) TimeZone name.
   * Note: iOS < v13 does not support Intl, so this function will return null instead.
   *
   * @returns {string} IANA TimeZone name. eg. Australia/Sydney
   */
  getIanaTimeZone(): string {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (_err: any) {
      return null;
    }
  }

  getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(patientTimeZoneId: string, utcOffsetHours: string): string {
    const patientTimezone: TimeZone = this.getTimeZoneFromStandardTimeZoneId(patientTimeZoneId);

    if (patientTimezone) {
      // 23/10/2023: For the first 24 hours of the release, the timezoneLabelNoOffset field may not be available for users that
      // already have the timezones cached in local storage, so just use the timezoneLabel in that case.
      if (patientTimezone.timezoneLabelNoOffset && utcOffsetHours) {
        return this.getTimezoneLabelFromNoOffsetLabelAndCalculatedUtcOffset(
          patientTimezone.timezoneLabelNoOffset,
          utcOffsetHours
        );
      } else if (patientTimezone.timezoneLabel) {
        return patientTimezone.timezoneLabel;
      }
    }

    return null;
  }

  getTimezoneLabelFromNoOffsetLabelAndCalculatedUtcOffset(
    timezoneLabelNoOffset: string,
    utcOffsetHours: string
  ): string {
    const utcSign: string = Number(utcOffsetHours) > 0 ? '+' : '-';
    return `${timezoneLabelNoOffset} (UTC${utcSign}${this.convertDecimalHoursToHHMM(utcOffsetHours)})`;
  }

  /**
   * @function getTimezoneUtcOffsetForGivenDateAndTimezoneId
   * @description Get the string representation of the timezone offset for the given timezone
   *
   * @param {string|moment.Moment} [utcDateOfInterest] date to parse. defaults to moment.utc() if not specified
   * @param {patientTimeZoneId} patientTimeZoneId eg. "Tasmania Standard Time"
   *
   * @returns {string} eg. "9.5", "11.0", "8.75"
   */
  getTimezoneUtcOffsetForGivenDateAndTimezoneId(
    utcDateOfInterest: string | moment.Moment = undefined,
    patientTimeZoneId: string
  ): string {
    const timeZone: TimeZone = this.getTimeZoneFromStandardTimeZoneId(patientTimeZoneId);
    const patientIANATimezoneId: string = timeZone?.ianaTimeZones?.length ? timeZone.ianaTimeZones[0] : null;

    if (patientIANATimezoneId) {
      // If no date of interest has been provided, assume we want to know the utcOffset for the current time
      if (!utcDateOfInterest) {
        utcDateOfInterest = moment.utc();
      }

      const momentDate: moment.Moment = moment.tz(utcDateOfInterest, patientIANATimezoneId);

      return (momentDate.utcOffset() / 60).toString();
    }

    return null;
  }

  getLocalTimeZoneOffsetString(inputDate?: string | Date | moment.Moment): string {
    return (moment.parseZone(inputDate).utcOffset() / 60).toString();
  }

  getTimeZoneOffsetStringFromAppointment(appointment: Appointment): string {
    if (appointment?.patientTimezoneOffsetHours) {
      return appointment.patientTimezoneOffsetHours;
    } else if (appointment?.patientTimezoneOffsetId) {
      return this.getTimezoneUtcOffsetForGivenDateAndTimezoneId(
        appointment.startTimeUTC || appointment.start,
        appointment.patientTimezoneOffsetId
      );
    }

    return null;
  }

  getTimeZoneLabelFromAppointment(appointment: Appointment): string {
    if (appointment?.patientTimezoneLabel) {
      return appointment.patientTimezoneLabel;
    } else if (appointment?.patientTimezoneOffsetId) {
      return this.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
        appointment.patientTimezoneOffsetId,
        this.getTimeZoneOffsetStringFromAppointment(appointment)
      );
    } else {
      const ianaTimeZone: string = this.getIanaTimeZone();

      if (ianaTimeZone) {
        const timeZone: TimeZone = this.getTimeZoneFromIanaTimeZoneName(ianaTimeZone);

        if (timeZone?.timezoneLabelNoOffset) {
          return this.getTimezoneLabelFromNoOffsetLabelAndCalculatedUtcOffset(
            timeZone.timezoneLabelNoOffset,
            timeZone.offset
          );
        }
      }
    }

    return null;
  }

  /**
   * @function getFormattedSessionDisplayTime
   * @description Gets the formatted time for display purposes.
   * Expects inputDate to be passed in UTC time (+00:00) and will return the formatted time
   * in the local time for the specified timezoneOffset
   * e.g: "2023-10-19T20:00:00+11:00" would return "08:00 PM"
   *
   * @param {string} inputDate date in the format "2023-10-19T20:00:00", assumed to be in UTC
   * @param {string} [timeZoneOffset] string representation of the numeric timezone offset (eg. "9.5")
   *
   * @returns {string} The start time formatted like "08:00 PM"
   */
  getFormattedSessionDisplayTime(inputDate: string, timeZoneOffset?: string): string {
    const numericTimezoneOffset: number = timeZoneOffset
      ? parseFloat(timeZoneOffset)
      : Constants.Default_TimeZone_Offset;

    return moment(inputDate).utcOffset(numericTimezoneOffset).format(Constants.TimeFormat_Meridiem);
  }

  /**
   * @function getFormattedSessionDisplayTimeLocal
   * @description Gets the formatted time for display purposes.
   * Expects inputDate to be passed in local time (eg. +11:00) and will return the formatted local time
   * e.g: "2023-10-19T19:00:00+11:00" would return "07:00 PM"
   *
   * @param {string} inputDate date in the format "2023-10-19T19:00:00+11:00"
   *
   * @returns {string} The start time formatted like "07:00 PM"
   */
  getFormattedSessionDisplayTimeLocal(inputDate: string): string {
    return moment.parseZone(inputDate).format(Constants.TimeFormat_Meridiem);
  }

  getTimeZoneFromIanaTimeZoneName(ianaTimeZoneName: string): TimeZone {
    if (!this._timeZoneOptions?.length) {
      return null;
    }
    return this._timeZoneOptions.find((tz: TimeZone) => tz.ianaTimeZones.indexOf(ianaTimeZoneName) !== -1);
  }

  getTimeZoneFromStandardTimeZoneId(standardTimeZoneId: string): TimeZone {
    if (!this._timeZoneOptions?.length) {
      return null;
    }
    return this._timeZoneOptions.find((tz: TimeZone) => tz.timeZone === standardTimeZoneId);
  }

  convertDecimalHoursToHHMM(decimalHours: string): string {
    const hours: number = Math.floor(parseFloat(decimalHours));
    const minutes: number = (parseFloat(decimalHours) - hours) * 60;

    return `${hours}:${String(minutes).padStart(2, '0')}`;
  }

  convertUTCDateToLocalDate(utcDate: string, offsetHours: number): Date {
    const utcDateTime: Date = new Date(utcDate);

    let newDate: Date = new Date(
      utcDateTime.getUTCFullYear(),
      utcDateTime.getUTCMonth(),
      utcDateTime.getUTCDate(),
      utcDateTime.getUTCHours(),
      utcDateTime.getUTCMinutes(),
      utcDateTime.getUTCSeconds()
    );

    newDate.setHours(newDate.getHours() + offsetHours);

    return newDate;
  }
}
