import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class DeepLinkRouterService {
  constructor (private router: Router) {}

  handleLinkOpened(openedUrl: string): Promise<boolean> {
    try {
      const url: URL = new URL(openedUrl);

      if (!url.pathname?.startsWith('/app/') || url.pathname?.length < 5) {
        return Promise.resolve(false);
      }
      const targetRoute: string[] = [
        url.pathname.slice(4)
      ];

      const queryParams: Params = {};

      if (url.search?.length > 1) {
        url.search?.substring(1)?.split('&')?.forEach(item => {
          const parts = item.split('=');

          queryParams[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        });
      }

      console.log('[DeepLinkRouter]', 'Attempting to navigate to', targetRoute, queryParams);

      return this.router.navigate(targetRoute, { queryParams: queryParams }).then(() => {
        // The route was handled
        console.log('[DeepLinkRouter]', 'Successfully navigated to deep link', targetRoute);
        return true;
      }).catch((e) => {
        // The route was not handled
        console.warn('[DeepLinkRouter]', 'Failed to navigate to deep link', targetRoute, e);
        return false;
      });
    } catch (err: any) {
      console.warn('[DeepLinkRouter]', 'Failed to handle link', err);
      return Promise.reject(err);
    }
  }
}
