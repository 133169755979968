import { AbstractControl, ValidationErrors } from '@angular/forms';

export function healthcareValidator(control: AbstractControl): ValidationErrors | null {
  const input: string = control.value ?? '';
  let isValid: boolean = false;

  if (!input) {
    isValid = true;

  // Full card number length is 13, but validators get triggered before the formatting functions, so we have 1 less char
  } else if (input.length < 12) {
    // isValid = false;
  } else {
    const cardNumber: string = input.replace(/\s+/g, '').replace(/-/g, '').substring(0, 10);
    const numbers: string = cardNumber.substring(0, cardNumber.length - 1);
    const letter: string = cardNumber.substring(cardNumber.length - 1, cardNumber.length);
    try {
      if (parseInt(numbers).toString() === numbers && isNaN(parseInt(letter))) {
        isValid = true;
      }
    } catch (err: any) {}
  }

  if (!isValid) {
    return { invalid: true };
  } else {
    return null;
  }
}
