import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[healthcareFormat]',
})
export class HealthcareDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed: string = input.value.toUpperCase().replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 13) {
      trimmed = trimmed.substring(0, 13);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 3) !== '') {
      numbers.push(trimmed.substring(0, 3));
    }
    if (trimmed.substring(3, 6) !== '') {
      numbers.push(trimmed.substring(3, 6));
    }
    if (trimmed.substring(6, 9) !== '') {
      numbers.push(trimmed.substring(6, 9));
    }
    if (trimmed.substring(9, 10) !== '') {
      numbers.push(trimmed.substring(9, 10));
    }

    input.value = numbers.join('-');
  }
}

@NgModule({
  declarations: [HealthcareDirective],
  exports: [HealthcareDirective],
})
export class HealthcareDirectiveModule {}
