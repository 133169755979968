import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Functions } from '@src/app/shared/functions';
import { B2BCustomerPortalAvailableRole, B2BCustomerPortalInfo } from '@src/app/shared/models/b2bCustomerPortalInfo';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class B2BBookingGuard implements CanActivate {
  constructor(
    private router: Router,
    private b2bCustomerService: B2BCustomerService,
    private functions: Functions
  ) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const b2bCustomerPortalInfo: B2BCustomerPortalInfo = await firstValueFrom(
        this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first())
      );

      if (!b2bCustomerPortalInfo) {
        this.functions.showToast('Invalid business customer information detected.');
        this.router.navigate(['/']);
        return false;
      } else if (
        !b2bCustomerPortalInfo.roles.some(
          (x: B2BCustomerPortalAvailableRole) => x === B2BCustomerPortalAvailableRole.CustomerBooking
        )
      ) {
        this.functions.showToast(
          'This operation is currently not available for ' + b2bCustomerPortalInfo.b2bCustomerName || 'this business'
        );
        await this.router.navigate(['/bp/' + this.b2bCustomerService.b2bCode]);
        return false;
      }

      return true;
    } catch (error) {
      this.functions.showToast("You don't have access to this page.");
      await this.router.navigate(['/']);
      return false;
    }
  }
}
