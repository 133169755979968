import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  SecurityContext
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '@app/shared/constants';
import { AppointmentType } from '@app/shared/models/appointment-type';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { AvailabilityService } from '@app/shared/services/availability.service';
import { AnalyticsCustomDimensionsService } from '@app/shared/services/custom-dimensions.service';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { ONDEMAND_STEP_PATH_MAPPING } from '@app/shared/step-configuration';
import { Subscription } from 'rxjs';

@Component({
  selector: 'start-appointment-modal',
  templateUrl: './start-appointment-modal.component.html',
  styleUrls: ['./start-appointment-modal.component.scss']
})
export class StartAppointmentModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  serviceTypes: any = Constants.SERVICE_TYPE;
  serviceType: string = this.serviceTypes.DOCTOR;
  appointmentType = AppointmentType;
  onDemandQueueSize: number = 0;
  queueSize: string;
  isOnline: boolean = false;
  enableAppointments: boolean = false;
  enableOnDemand: boolean = false;
  appointmentStepType: string = 'APPOINTMENT_TYPE_DOCTOR';
  appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;
  speakToDoctor: string = 'Speak to a Doctor Now';
  scheduledAppointment: string = 'Schedule Appointment';
  pageTitle: string = 'Book an Appointment';
  pageText: string = 'Need an appointment with a doctor?';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<StartAppointmentModalComponent>,
    private sanitizer: DomSanitizer,
    private appointmentService: AppointmentService,
    private availabilityService: AvailabilityService,
    private whiteLabelService: WhitelabelService,
    private customDimensionsService: AnalyticsCustomDimensionsService
  ) {}

  ngOnInit(): void {
    // WHITELABEL CONFIGURATION
    this.updateWhiteLabelConfiguration();
    this.subscription.add(
      this.whiteLabelService.whitelabelConfigObs.subscribe(() => {
        this.updateWhiteLabelConfiguration();
      })
    );

    if (this.data?.serviceType) {
      this.serviceType = this.data.serviceType;
    }
    if (this.data?.title) {
      this.pageTitle = this.data.title;
    }
    if (this.data?.text) {
      this.pageText = this.sanitizer.sanitize(SecurityContext.HTML, this.data.text);
    }

    // ON-DEMAND QUEUE SIZE
    this.updateQueueSizeDisplay(this.customDimensionsService?.allCustomDimensions?.onDemandAppointmentQueueSize);
  }

  ngAfterViewInit(): void {
    // ON-DEMAND AVAILABILITY
    this.subscription.add(
      this.availabilityService.isPractitionerOnlineChangeObs.subscribe(() => {
        this.getOnlinePractitioner();
      })
    );

    // ON-DEMAND QUEUE SIZE
    this.subscription.add(
      this.customDimensionsService.onDemandQueueSizeChangeObs.subscribe((queueSize: number) => {
        this.updateQueueSizeDisplay(queueSize);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClose(serviceType: string, isOnDemand: boolean = false): void {
    this.dialogRef.close({ serviceType, isOnDemand });
  }

  updateWhiteLabelConfiguration(): void {
    this.enableAppointments = this.whiteLabelService.canProvideAppointments();
    this.appointmentServiceType = this.serviceType || this.whiteLabelService.getPrimaryServiceType();
    this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
    this.enableOnDemand =
      this.whiteLabelService.onDemandEnabledForServiceType(this.appointmentServiceType) &&
      ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType];

    this.getOnlinePractitioner();
  }

  getOnlinePractitioner(): void {
    this.isOnline = this.enableOnDemand && this.availabilityService.isOnline(this.appointmentServiceType);
  }

  updateQueueSizeDisplay(queueSize: number = 0): void {
    this.onDemandQueueSize = queueSize;
    this.queueSize =
      typeof this.onDemandQueueSize === 'number'
        ? ' (' + this.onDemandQueueSize + ' patient' + (this.onDemandQueueSize === 1 ? '' : 's') + ' in queue)'
        : '';
  }
}
