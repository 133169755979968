import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { B2B_STEP_PATH } from '@src/app/pages/business-portal/business-portal-routing.module';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { B2BCustomerPortalInfo } from '../models/b2bCustomerPortalInfo';
import { AIContext, AppInsightsService } from './appinsights.service';
import {
  B2BPortalClaimViewModel,
  B2BCustomerPortalBookingPatient,
  B2BCustomerBookingStatus,
  B2BPortalLoginUserVerificationModel,
  B2BPortalVerifyPolicyRequest,
  B2BPortalGeneratePolicyRequest,
  B2BPortalValidatePatientEmailRequest,
  B2BPortalCreateNewPatientRequest,
  MFAEnrolmentMedium,
  B2BPortalLoginPatientVerificationModel,
  B2BPortalLoginUserSendCodeModel,
  B2BPortalUserLoginAccessTokens,
  JwtAccessToken
} from '../models/b2b-customer-portal/models';
import { IdName } from '../models/common/models';
import { Credentials } from '../models/credentials';

export enum B2BBookingPatientInputType {
  email,
  policy
}

@Injectable({
  providedIn: 'root'
})
export class B2BCustomerService {
  public static b2bCustomerCodeDoDMedicare = 'DoDMedicare';

  private logPrefix: string = '[B2BCustomerService]';
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private _b2bCode: string;
  private _aiContext: AIContext;

  private _storedB2BCode: string;

  private _verifiedBusinessUser: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public verifiedBusinessUser: Observable<string> = this._verifiedBusinessUser.asObservable();

  private _verifiedPolicyClaim: BehaviorSubject<B2BPortalClaimViewModel> = new BehaviorSubject<B2BPortalClaimViewModel>(
    null
  );
  public verifiedPolicyClaim: Observable<B2BPortalClaimViewModel> = this._verifiedPolicyClaim.asObservable();

  private _patientManuallyTypedEmail: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public patientManuallyTypedEmail: Observable<string> = this._patientManuallyTypedEmail.asObservable();

  private _patientBookingSource: BehaviorSubject<B2BBookingPatientInputType> =
    new BehaviorSubject<B2BBookingPatientInputType>(null);
  public patientBookingSource: Observable<B2BBookingPatientInputType> = this._patientBookingSource.asObservable();

  private _b2bCustomerPortalInfo: BehaviorSubject<B2BCustomerPortalInfo> = new BehaviorSubject<B2BCustomerPortalInfo>(
    null
  );
  public b2bCustomerPortalInfo: Observable<B2BCustomerPortalInfo> = this._b2bCustomerPortalInfo.asObservable();

  private _patient: BehaviorSubject<B2BCustomerPortalBookingPatient> =
    new BehaviorSubject<B2BCustomerPortalBookingPatient>(null);
  public patient: Observable<B2BCustomerPortalBookingPatient> = this._patient.asObservable();

  private _serviceType: string = null;
  private _currentPatientBookingStatus: B2BCustomerBookingStatus = null;

  private _tempPatientCredentials: Credentials;
  private _resourceAccessToken: string;

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private storageSession: SessionStorageService,
    private aiService: AppInsightsService,
    private router: Router
  ) {
    this._aiContext = this.aiService.createContext('B2BCustomerService');
  }

  clearAllBookingInformation(clearBusinessUser: boolean = true, clearServiceType: boolean = true) {
    if (clearBusinessUser) {
      this._verifiedBusinessUser.next(null);
    }

    if (clearServiceType) {
      this._serviceType = null;
    }

    this._verifiedPolicyClaim.next(null);
    this._patientManuallyTypedEmail.next(null);
    this._patient.next(null);
    this._patientBookingSource.next(null);
    this._currentPatientBookingStatus = null;
    this._resourceAccessToken = null;
    this._tempPatientCredentials = null;
  }

  get b2bCode(): string {
    return this._b2bCode;
  }

  retrieveB2BCode(): string {
    return this._storedB2BCode || this.b2bCode;
  }

  removeBenefitFromStorage(): void {
    this.storageSession.clear(Constants.LocalStorage_Key.benefit);
    this.storageSession.clear(Constants.LocalStorage_Key.benefit_object);
  }

  async initialiseService(): Promise<void> {
    try {
      this._storedB2BCode = this.storageSession.retrieve(Constants.LocalStorage_Key.sessionB2BCode);
    } catch (err: any) {
      this._aiContext.trackException(err);
    }

    if (this._storedB2BCode && typeof this._storedB2BCode === 'string') {
      this._aiContext.trackEvent('InitWithStoredB2BCode', { code: this.b2bCode });
    }

    this._aiContext.debug('B2BCustomerServiceInitialised', { b2bCode: this._b2bCode });
  }

  setSessionB2BCustomer(b2bCode: string): boolean {
    if (!b2bCode || typeof b2bCode !== 'string') {
      return false;
    }

    this._b2bCode = b2bCode.trim().toLowerCase();
    this._storedB2BCode = this._b2bCode;

    try {
      this.storageSession.store(Constants.LocalStorage_Key.sessionB2BCode, this._b2bCode);
    } catch (_err: any) {
      console.error(this.logPrefix, 'Failed to initialise with stored B2B code', _err);
    }

    return true;
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/getb2bcustomerinfofromcode/{b2bCode}
  getB2BCustomerPortalInfoFromCode(b2bCode: string): Promise<B2BCustomerPortalInfo> {
    return this.http
      .get(`${this.url}/businessportal/getb2bcustomerinfofromcode/${b2bCode}`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          let b2bCustomerPortalInfo = new B2BCustomerPortalInfo(response.response);
          this._b2bCustomerPortalInfo.next(b2bCustomerPortalInfo);
          return b2bCustomerPortalInfo;
        } else {
          return null;
        }
      })
      .catch((err: any) => {
        console.log(
          'Failed to get business portal details for "' + b2bCode + '". Error:',
          this.functions.getErrorMessage(err)
        );
        return null;
      });
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/verifyemaildomainandsendcode
  async validateEmailDomainAndSendCode(emailAddress: string): Promise<string> {
    const body = new B2BPortalLoginUserSendCodeModel({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      emailAddress: emailAddress
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/verifyemaildomainandsendcode`, body)
      );

      if (response?.success && typeof response.response === 'string') {
        return response.response;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to validate email domain for "${this.b2bCode}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/gettitles
  async getTitles(): Promise<string[]> {
    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.get<IResponseAPI>(`${this.url}/businessportal/gettitles`)
      );

      if (response?.success && typeof response.response) {
        return response.response as string[];
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(`Failed to get titles. Error:`, this.functions.getErrorMessage(err), err);
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/verifyb2bpasswordandemailcode
  async verifyB2BPasswordAndEmailCode(
    emailAddress: string,
    password: string,
    enrolmentId: string,
    code: string
  ): Promise<B2BPortalUserLoginAccessTokens> {
    const body = new B2BPortalLoginUserVerificationModel({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      emailAddress: emailAddress,
      password: password,
      mfaEnrolmentId: enrolmentId,
      b2bCustomerCode: code
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/verifyb2bpasswordandemailcode`, body)
      );

      if (response?.success && typeof response.response !== null) {
        return response.response as B2BPortalUserLoginAccessTokens;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to verify B2B password or email code for "${this.b2bCode}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/verifypolicy
  async verifyPolicy(policyNumber: string): Promise<B2BPortalClaimViewModel> {
    const body = new B2BPortalVerifyPolicyRequest({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      policyNumber: policyNumber,
      serviceTypeText: this._serviceType
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/verifypolicy`, body)
      );

      if (response?.success && typeof response.response !== null) {
        return response.response as B2BPortalClaimViewModel;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      this.functions.showToast(this.functions.getErrorMessage(err), 10);
      console.error(`Failed to validate policy: "${policyNumber}". Error:`, this.functions.getErrorMessage(err), err);
      return null;
    }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/getpoliciesforpatient
  async getPoliciesForPatient(patientId: string): Promise<IdName[]> {
    const params = new HttpParams()
      .set('b2bCustomerId', this._b2bCustomerPortalInfo.value.b2bCustomerId)
      .set('patientId', patientId);

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.get<IResponseAPI>(`${this.url}/businessportal/getpoliciesforpatient`, { params })
      );

      if (response?.success && typeof response.response != null) {
        return response.response as IdName[];
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      this.functions.showToast(
        `Failed to get available policy list for patient "${this._patient.value.name}". Error: ${this.functions.getErrorMessage(err)}`
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/generatenewpolicy
  async generateNewPolicy(): Promise<B2BPortalClaimViewModel> {
    const body: B2BPortalGeneratePolicyRequest = new B2BPortalGeneratePolicyRequest({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      serviceType: this._serviceType
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/generatenewpolicy`, body)
      );

      if (response?.success && typeof response.response !== null) {
        return response.response as B2BPortalClaimViewModel;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      this.functions.showToast(this.functions.getErrorMessage(err), 10);
      console.error(`Failed to validate data for new patient. Error:`, this.functions.getErrorMessage(err), err);
      return null;
    }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/validatepatientemail
  async validatePatientEmail(emailAddress: string): Promise<boolean> {
    const body = new B2BPortalValidatePatientEmailRequest({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      emailAddress: emailAddress
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/validatepatientemail`, body)
      );

      if (response?.success && typeof response.response === 'boolean') {
        return response.response as boolean;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to validate patient email: "${emailAddress}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/addpatient
  async addPatient(
    email: string,
    title: string,
    firstName: string,
    lastName: string,
    gender: string,
    phone: string,
    timezone: string
  ): Promise<string> {
    const body: B2BPortalCreateNewPatientRequest = new B2BPortalCreateNewPatientRequest({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      emailAddress: email,
      title: title,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      phone: phone,
      timezone: timezone
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/addpatient`, body)
      );

      if (response?.success && typeof response.response === 'string') {
        // expect Guid response
        return response.response as string;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(`Failed to validate data for new patient. Error:`, this.functions.getErrorMessage(err), err);
      return null;
    }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/getpatientsforemail
  async getPatientsForEmailOrPolicy(): Promise<B2BCustomerPortalBookingPatient[]> {
    const params = new HttpParams()
      .set('b2bCustomerId', this._b2bCustomerPortalInfo.value.b2bCustomerId)
      .set('policyId', this._verifiedPolicyClaim.value?.id || '')
      .set('emailAddress', this._patientManuallyTypedEmail.value || '');

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.get<IResponseAPI>(`${this.url}/businessportal/getpatientsforemail`, { params })
      );

      if (response?.success && typeof response.response !== null) {
        return response.response as B2BCustomerPortalBookingPatient[];
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to get patients for email address ${this._patientManuallyTypedEmail.value || 'N/A'}. Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/sendpatientemailcode
  async sendPatientEmailCode(patient: B2BCustomerPortalBookingPatient, mode: MFAEnrolmentMedium): Promise<string> {
    const params = new HttpParams()
      .set('b2bCustomerId', this._b2bCustomerPortalInfo.value.b2bCustomerId)
      .set('patientId', patient.id)
      .set('mode', mode != null ? MFAEnrolmentMedium[mode] : 'email');

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.get<IResponseAPI>(`${this.url}/businessportal/sendpatientemailcode`, { params })
      );

      if (response?.success && typeof response.response === 'string') {
        return response.response;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      this.functions.showToast(
        `Failed to send code for patient "${patient.name}". Error: ${this.functions.getErrorMessage(err)}`
      );
      console.error(
        `Failed to send code for patient "${patient.name}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/businessportal/verifypatientemailcode
  async verifyPatientEmailCode(
    patientId: string,
    emailAddress: string,
    enrolmentId: string,
    code: string
  ): Promise<JwtAccessToken> {
    const body = new B2BPortalLoginPatientVerificationModel({
      b2bCustomerId: this._b2bCustomerPortalInfo.value.b2bCustomerId,
      patientId: patientId,
      emailAddress: emailAddress,
      mfaEnrolmentId: enrolmentId,
      b2bCustomerCode: code
    });

    try {
      const response: IResponseAPI = await lastValueFrom(
        this.http.post<IResponseAPI>(`${this.url}/businessportal/verifypatientemailcode`, body)
      );

      if (response?.success && typeof response.response !== null) {
        return response.response as JwtAccessToken;
      } else {
        console.warn('Unexpected response format:', response);
        return null;
      }
    } catch (err) {
      console.error(
        `Failed to verify B2B password or email code for "${this.b2bCode}". Error:`,
        this.functions.getErrorMessage(err),
        err
      );
      return null;
    }
  }

  setVerifiedBusinessUser(emailAddress: string) {
    this._verifiedBusinessUser.next(emailAddress);
  }

  setVerifiedPolicy(claim: B2BPortalClaimViewModel) {
    this._verifiedPolicyClaim.next(claim);
  }

  setServiceType(serviceType: string) {
    this._serviceType = serviceType;
  }

  setResourceAccessToken(resourceAccessToken: string) {
    this._resourceAccessToken = resourceAccessToken;
  }

  get resourceAccessToken() {
    return this._resourceAccessToken;
  }

  setCurrentPatientBookingStatus(status: B2BCustomerBookingStatus) {
    this._currentPatientBookingStatus = status;
  }

  moveBackPatientBookingStatus() {
    const status: B2BCustomerBookingStatus = this._currentPatientBookingStatus;
    let navLocation: string = null;

    switch (status) {
      case B2BCustomerBookingStatus.PatientEnterEmail:
        navLocation = B2B_STEP_PATH.POLICY_NUMBER;
        break;
      case B2BCustomerBookingStatus.PatientSelect:
        navLocation =
          this.getPatientBookingSource === B2BBookingPatientInputType.policy
            ? B2B_STEP_PATH.POLICY_NUMBER
            : B2B_STEP_PATH.PATIENT_EMAIL;
        break;
      case B2BCustomerBookingStatus.PatientSendCode:
      case B2BCustomerBookingStatus.PatientVerifyCode:
        navLocation = B2B_STEP_PATH.PATIENT_SELECT;
        break;
      case B2BCustomerBookingStatus.GenerateNewPolicy:
        navLocation = B2B_STEP_PATH.PATIENT_CODE;
        break;
      case B2BCustomerBookingStatus.PatientAdd:
        navLocation =
          this.getPatientBookingSource === B2BBookingPatientInputType.policy
            ? B2B_STEP_PATH.PATIENT_SELECT
            : B2B_STEP_PATH.PATIENT_EMAIL;
        break;
      default:
        navLocation = null;
    }

    if (navLocation) {
      const baseUrl: string = `/bp/${this.b2bCode}/${B2B_STEP_PATH.BOOK_APPOINTMENT}/`;
      this.router.navigate([`${baseUrl}${navLocation}`]);
    }
  }

  setPatientManuallyTypedEmail(email: string) {
    this._patientManuallyTypedEmail.next(email);
  }

  // setPatientEmailManuallyEntered(val: boolean) {
  //   this._patientEmailManuallyEntered.next(val);
  // }

  setPatientSource(val: B2BBookingPatientInputType) {
    this._patientBookingSource.next(val);
  }

  setPatient(patient: B2BCustomerPortalBookingPatient) {
    this._patient.next(patient);
  }

  setTempPatientCredentials(credentials: Credentials) {
    this._tempPatientCredentials = credentials;
  }

  public getTempPatientCredentials(): Credentials {
    return this._tempPatientCredentials;
  }

  public get getVerifiedBusinessUser(): string {
    return this._verifiedBusinessUser.value;
  }

  public get b2bCustomerPortalInfoLoaded(): boolean {
    return !!this._b2bCustomerPortalInfo.value;
  }

  public get serviceType(): string {
    return this._serviceType;
  }

  public get currentPatientBookingStatus(): B2BCustomerBookingStatus {
    return this._currentPatientBookingStatus;
  }

  public get getVerifiedPolicyClaim(): B2BPortalClaimViewModel {
    return this._verifiedPolicyClaim.value;
  }

  public get getPatientEmail(): string {
    return this._patientManuallyTypedEmail.value;
  }

  public get getPatient(): B2BCustomerPortalBookingPatient {
    return this._patient.value;
  }

  public get getPatientBookingSource(): B2BBookingPatientInputType {
    return this._patientBookingSource.value;
  }

  public get getBookingStatusText(): string {
    switch (this._currentPatientBookingStatus) {
      case B2BCustomerBookingStatus.UserSendCode:
      case B2BCustomerBookingStatus.UserVerifyCodeAndPassword:
        return 'Login';
      case B2BCustomerBookingStatus.PolicyNumber:
        return 'Enter Policy Number';
      case B2BCustomerBookingStatus.PatientEnterEmail:
        return "Enter the Patient's Email Address";
      case B2BCustomerBookingStatus.PatientAdd:
        return 'Add Patient Details';
      case B2BCustomerBookingStatus.PatientSelect:
        return 'Select the Patient';
      case B2BCustomerBookingStatus.PatientSendCode:
      case B2BCustomerBookingStatus.PatientVerifyCode:
        return 'Patient Verification';
      case B2BCustomerBookingStatus.GenerateNewPolicy:
        return 'Patient Verification';
      default:
        return '';
    }
  }
}
