import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from '@src/app/shared/constants';
import { ErrorModalData } from '@src/app/shared/models/error-data';

@Component({
  selector: 'quick-script-cart',
  templateUrl: './quick-script-cart.component.html',
  styleUrls: ['./quick-script-cart.component.scss']
})
export class QuickScriptCartComponent implements OnInit {
  titles: any = Constants.MODAL_TITLES;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: ErrorModalData,
    public dialogRef: MatDialogRef<QuickScriptCartComponent>,
    private router: Router
  ) {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {}

  redirectTo(url?: string | string[]): void {
    if (Array.isArray(url) && url.length) {
      this.router.navigate(url);
    } else if (typeof url === 'string') {
      this.router.navigateByUrl(url);
    }
    this.onClose();
  }

  onClose(value?: boolean): void {
    this.dialogRef.close(value);
  }
}
