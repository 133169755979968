<div class="psm">
  <ng-container *ngIf="enableStrengthLabel">
    <span class="pass-strength"> Password strength: </span>
    <span class="{{ passwordScoreStringClass }}">{{ passwordScoreString }}</span>
  </ng-container>
  <div
    class="psm__progress-bar"
    role="progressbar"
    [passwordStrength]="passwordStrength"
    [numberOfProgressBarItems]="numberOfProgressBarItems"
    [colors]="colors">
    <div class="psm__progress-bar-items"></div>
    <div class="psm__progress-bar-overlay"></div>
  </div>
  <ng-container *ngIf="enableFeedback && feedback">
    <small *ngIf="feedback.warning" class="psm__feedback">
      {{ feedback.warning }}
    </small>
    <small *ngIf="feedback.suggestions?.length" class="psm__suggestion">
      <ng-container *ngFor="let suggestion of feedback.suggestions; last as isLast">
        {{ suggestion }}{{ isLast ? '' : ' ' }}
      </ng-container>
    </small>
  </ng-container>
</div>
