import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from '@app/shared/functions';
import { AgencyService } from '@app/shared/services/agency.service';
import { AIContext, AppInsightsService } from '@app/shared/services/appinsights.service';
import { B2BCustomerPortalInfo } from '@src/app/shared/models/b2bCustomerPortalInfo';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { SessionStorageService } from 'ngx-webstorage';
import { B2B_STEP_PATH } from '../../business-portal-routing.module';
import { B2BCustomerBookingStatus } from '@src/app/shared/models/b2b-customer-portal/models';
import { PatientService } from '@src/app/shared/services/patient.service';

@Component({
  selector: 'business-portal',
  templateUrl: './business-portal-container.component.html',
  styleUrls: ['./business-portal-container.component.scss']
})
export class BusinessPortalContainerComponent implements OnInit {
  aiContext: AIContext;

  isMobile: boolean;

  B2BCustomerBookingStatus = B2BCustomerBookingStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private functions: Functions,
    private aiService: AppInsightsService,
    public b2bCustomerService: B2BCustomerService,
    private agencyService: AgencyService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private patientService: PatientService
  ) {
    this.aiContext = this.aiService.createContext('B2BContainer');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  ngOnInit(): void {
    this.initialiseB2BCustomer();
  }

  moveBackPatientBookingStatus() {
    this.b2bCustomerService.moveBackPatientBookingStatus();
  }
  /**
   * @async
   * @function initialiseB2BCustomer
   * @description Initialise the B2BCustomer service with the specified b2bCode
   *
   * @returns {Promise<void>}
   */
  async initialiseB2BCustomer(): Promise<void> {
    const b2bCode: string = this.activatedRoute.snapshot.paramMap.get('b2bcode');

    this.aiContext.debug('InitWithB2BCode', { b2bCode: b2bCode });

    const setB2BSuccess: boolean = await this.b2bCustomerService.setSessionB2BCustomer(b2bCode);

    if (!setB2BSuccess) {
      // Session agency was not set
      this.functions.showToast('The link you followed is invalid', 5000);
      this.aiContext.warn('SetB2B', { result: false, b2bCode: b2bCode });
    } else {
      const result: B2BCustomerPortalInfo = await this.b2bCustomerService.getB2BCustomerPortalInfoFromCode(b2bCode);
      if (!result) {
        this.functions.showToast(
          'This business does not have access to the Business Portal. Please contact an administrator.'
        );
        this.router.navigate(['/']); // go to root
      }
    }

    this.aiContext.debug('SetB2B', { result: true, b2bCode: b2bCode });

    // Remove any existing benefits before handling B2B policy - I think we still want to do this
    this.agencyService.removeBenefitFromStorage();
    this.sessionStorageService.clear('b2bbookingmode');
  }

  startOver() {
    this.patientService.resetBenefit();
    this.patientService.removePatientData();
    this.patientService.resetPatient();
    this.b2bCustomerService.clearAllBookingInformation(false, false);
    this.router.navigate([
      'bp',
      this.b2bCustomerService.b2bCode,
      B2B_STEP_PATH.BOOK_APPOINTMENT,
      B2B_STEP_PATH.POLICY_NUMBER
    ]);
  }
}
