import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[medicareFormat]',
})
export class MedicareDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed: string = input.value.replace(/\s+/g, '');
    let numbers: string[] = [];

    if (trimmed.length > 12) {
      trimmed = trimmed.substring(0, 12);
    }

    trimmed = trimmed.replace(/-/g, '');

    if (trimmed.substring(0, 4) !== '') {
      numbers.push(trimmed.substring(0, 4));
    }
    if (trimmed.substring(4, 9) !== '') {
      numbers.push(trimmed.substring(4, 9));
    }
    if (trimmed.substring(9, 10) !== '') {
      numbers.push(trimmed.substring(9, 10));
    }

    input.value = numbers.join('-');
  }
}

@NgModule({
  declarations: [MedicareDirective],
  exports: [MedicareDirective],
})
export class MedicareDirectiveModule {}
