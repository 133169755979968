import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PractitionerTypeComponent } from '@app/pages/home/components/appointments/practitioner-type/practitioner-type.component';
import { DefaultModalWidths, ModalWidthSpec } from '../models/modalWidthSpec';
import { MedicationModalComponent } from '@app/pages/appointment/components/medication-modal/medication-modal.component';
import { ToggleButtonModalComponent } from '@app/pages/appointment/components/toggle-button-modal/toggle-button-modal.component';
import { HavingIssueComponent } from '@app/pages/home/components/appointments/having-issue/having-issue.component';
import { StartAppointmentModalComponent } from '@app/pages/home/components/appointments/start-appointment-modal/start-appointment-modal.component';
import { MedicalCertificateEmailComponent } from '@app/pages/home/components/medical-certificates/medical-certificate-email/medical-certificate-email.component';
import { ResendPrescriptionComponent } from '@app/pages/home/components/prescriptions/resend-prescription/resend-prescription.component';
import { ReferralEmailComponent } from '@app/pages/home/components/referrals/referral-email/referral-email.component';
import { QuickScriptCartComponent } from '@app/pages/quick-script/components/quick-script-cart/quick-script-cart.component';
import { HealthCardInfoComponent } from '@app/pages/quick-script/components/quick-script-medication/health-card-info/health-card-info.component';
import { ChangeAddressPharmacyComponent } from '@app/pages/quick-script/components/quick-script-payment/change-address-pharmacy/change-address-pharmacy.component';
import { VideoModalComponent } from '@app/shared/components/video-modal/video-modal.component';
import { PolicyEligibilityModalComponent } from '@src/app/pages/appointment/components/policy-eligibility-modal/policy-eligibility-modal.component';
import { WeightLossCheckInModalComponent } from '../../pages/weight-loss/components/weight-loss-check-in-modal/weight-loss-check-in-modal.component';
import { AppointmentSelectPractitionerComponent } from '../components/appointment-select-practitioner/appointment-select-practitioner.component';
import { BenefitComponent } from '../components/benefit/benefit.component';
import { CancelAppointmentComponent } from '../components/cancel-appointment/cancel-appointment.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { HealthcareCardsModalComponent } from '../components/healthcare-cards-modal/healthcare-cards-modal.component';
import { LoadingModalComponent } from '../components/loading-modal/loading-modal.component';
import { PdfViewerTemplateComponent } from '../components/pdf-viewer-template/pdf-viewer-template.component';
import { PensionerConcessionCardComponent } from '../components/pensioner-concession-card/pensioner-concession-card.component';
import { PractitionerProfileComponent } from '../components/practitioner-profile/practitioner-profile.component';
import { RescheduleAppointmentComponent } from '../components/reschedule-appointment/reschedule-appointment.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { SwitchPatientComponent } from '../components/switch-patient/switch-patient.component';
import { TestInternetComponent } from '../components/test-internet/test-internet.component';
import { TestMicrophoneComponent } from '../components/test-microphone/test-microphone.component';
import { TestWebcamComponent } from '../components/test-webcam/test-webcam.component';
import { Functions } from '../functions';
import { GetPolicyEligibilityDTO } from '../models/GetPolicyEligibilityDTO';
import { Appointment } from '../models/appointment';
import { Attachment } from '../models/attachment';
import { Medication } from '../models/medication';
import { Prescription } from '../models/prescription';
import { RepeatPrescriptionComponent } from '@src/app/pages/home/components/prescriptions/repeat-prescription/repeat-prescription.component';
import { B2BCustomerService } from './b2b-customer.service';
import { AIContext, AppInsightsService } from './appinsights.service';
import { Patient } from '../models/patient';
import { ModalKeeperService } from './modal-keeper-service';
import { Constants } from '../constants';
import { EmailVerificationSignupModalComponent } from '../components/email-verification-signup-modal/email-verification-signup-modal.component';
import { PatientAddMobilePhoneNumberModalComponent } from '../components/patient-set-mobile-phone-number-modal/patient-set-mobile-phone-number-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService implements OnDestroy {
  aiContext: AIContext;
  contextKey: string = 'ModalService';

  constructor(
    private functions: Functions,
    private dialog: MatDialog,
    private b2bCustomerService: B2BCustomerService,
    private aiService: AppInsightsService,
    private modalKeeperService: ModalKeeperService
  ) {
    this.aiContext = this.aiService.createContext(this.contextKey);
  }

  ngOnDestroy(): void {
    this.modalKeeperService?.resetService();
  }

  /**
   * @function triggerResizeEvent
   * @description Trigger a window 'resize' event on mobile screens (forces re-positioning of sticky elements
   * like the mobile header)
   */
  triggerResizeEvent(): void {
    this.functions.triggerResizeEvent();
  }

  /**
   * @function closeAllDialogs
   * @description Close all resitered popups
   */
  closeAllDialogs(): void {
    if (this.dialog) {
      try {
        this.dialog.closeAll();
      } catch (_err: any) {}
    }
  }

  selectPractitionerType(appointmentType: any, resultCallbackFn?: Function): void {
    const appointmentTypeString: string = String(appointmentType);

    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '70vw',
      desktop: '50vw'
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.data = {
      type: appointmentTypeString
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PractitionerTypeComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  genericStartAppointment(title: string, text: string, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.maxWidth = 573;
    dialogConfig.panelClass = 'custom-modalbox-size-small';

    dialogConfig.data = {
      title,
      text
    };

    this.dialog
      .open(StartAppointmentModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: string) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  needAppointment(resultCallbackFn?: Function): void {
    this.genericStartAppointment(
      'Need an Appointment?',
      '<p>Book an online doctor appointment now.</p>',
      resultCallbackFn
    );
  }

  needPrescription(resultCallbackFn?: Function): void {
    this.genericStartAppointment(
      'Need a Prescription?',
      `<p>Do you need a new or repeat prescription?</p>
        <p>Book an online doctor appointment for a new or repeat prescription.</p>`,
      resultCallbackFn
    );
  }

  newPrescription(resultCallbackFn?: Function): void {
    this.genericStartAppointment(
      'New Prescription',
      `<p>Do you need a new prescription for a medication you are not currently taking?</p>
        <p>Book an online doctor appointment for a new prescription.</p>`,
      resultCallbackFn
    );
  }

  // repeatPrescription(dialogData: any = {}, resultCallbackFn?: Function): void {
  //   this.genericStartAppointment(
  //     'Repeat Prescription',
  //     `<p>Do you need a repeat prescription?</p>
  //       <p>Book an online doctor appointment for a repeat prescription.</p>`,
  //     resultCallbackFn
  //   );
  // }

  repeatPrescription(dialogData: any = {}, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Repeat Prescription',
      isAdditionalMedication: false,
      ...dialogData
    };
    dialogConfig.panelClass = dialogData.noMedSearch ? 'custom-modalbox-size-small' : 'custom-modalbox-stretched';
    dialogConfig.id = 'repeat-prescription-modal'.concat(dialogData.noMedSearch ? '2' : '');
    dialogConfig.minHeight = 343;
    dialogConfig.maxWidth = dialogData.noMedSearch ? 573 : 815;

    // dialogConfig.maxWidth = 573; // Figma
    // dialogConfig.maxWidth = 815; // reality

    this.dialog
      .open(RepeatPrescriptionComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
      });
  }

  viewPrescription(prescription: Prescription, resultCallbackFn?: Function): void {
    // Force the Redeem Benefits modal to use strict sizing
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '65vw',
      desktop: DefaultModalWidths.desktop
    };

    let attachments: string[] = [];
    if (prescription && Array.isArray(prescription.scriptAttachments)) {
      attachments = prescription.scriptAttachments.map((attachment: Attachment) => attachment.attachmentId);
    }

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.data = {
      attachments,
      title: 'View Script',
      subtitle: 'Prescription',
      isMultiple: false
    };
    dialogConfig.panelClass = 'custom-modalbox-stretched';
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PdfViewerTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  resendPrescription(prescription: Prescription, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      id: prescription.appointmentId ? prescription.appointmentId : prescription.orderId,
      type: prescription.appointmentId ? 'appointment' : 'order',
      prescription
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(ResendPrescriptionComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewMedicalCertificate(attachmentId: string, resultCallbackFn?: Function, attachmentFileName?: string): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      attachmentId,
      attachmentFileName: attachmentFileName ?? 'MedicalCertificate',
      title: 'View Medical Certificate',
      subtitle: 'Medical Certificate',
      isMultiple: true
    };
    dialogConfig.panelClass = 'custom-modalbox-stretched';
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PdfViewerTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  sendMedicalCertificate(attachmentId: string, appointmentId: string, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      attachmentId,
      appointmentId
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(MedicalCertificateEmailComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewReferral(attachmentId: string, resultCallbackFn?: Function, attachmentFileName?: string): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      attachmentId,
      attachmentFileName: attachmentFileName ?? 'Referral',
      title: 'View Referral',
      subtitle: 'Referral',
      note: `<p>You can view a copy of your referral or medical test request here. Referrals and test
        requests can also be downloaded and printed or sent direct to your specialist or laboratory.</p>`,
      isMultiple: true
    };
    dialogConfig.panelClass = 'custom-modalbox-stretched';
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PdfViewerTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  sendReferral(attachmentId: string, appointmentId: string, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = { attachmentId, appointmentId };
    dialogConfig.maxWidth = 573;
    dialogConfig.minHeight = 444;

    this.dialog
      .open(ReferralEmailComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewResults(attachmentId: string, resultCallbackFn?: Function, attachmentFileName?: string): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      attachmentId,
      attachmentFileName: attachmentFileName ?? 'Result',
      title: 'View Results',
      subtitle: 'Pathology / Imaging Results',
      isMultiple: true
    };
    dialogConfig.panelClass = 'custom-modalbox-stretched';
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PdfViewerTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewBenefit(
    appointment?: Appointment,
    benefitCode?: string,
    serviceType?: string,
    resultCallbackFn?: Function,
    doNotApplyBenefit: boolean = false
  ): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      benefitCode,
      doNotApplyBenefit,
      serviceType,
      title: Constants.MODAL_TITLES.redeemABenefit
    };

    const subTitle: string = 'viewBenefit';
    if (this.modalKeeperService.isModalOpen(dialogConfig.data.title, subTitle)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(dialogConfig.data.title, subTitle, this.contextKey);

    if (appointment) {
      dialogConfig.data.appointment = appointment;
    }

    dialogConfig.panelClass = 'custom-modalbox-size-regular';
    dialogConfig.maxWidth = 573; // Max width as per Figma designs

    this.dialog
      .open(BenefitComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(dialogConfig.data.title, subTitle, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewInvoice(attachmentId: string, resultCallbackFn?: Function, attachmentFileName?: string): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      attachmentId,
      attachmentFileName: attachmentFileName ?? 'Invoice',
      title: 'View Invoice',
      subtitle: 'Details of your invoice',
      isMultiple: true
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PdfViewerTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewPractitionerProfile(practitionerId: string, dialogData: any = {}, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      practitionerId,
      closeButtonOnly: false,
      ...dialogData
    };
    dialogConfig.panelClass = 'custom-modalbox-size-regular';
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PractitionerProfileComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  viewGenericAttachment(attachmentId: string): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      attachmentId,
      title: 'View Attachment',
      isMultiple: false
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(PdfViewerTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.triggerResizeEvent();
      });
  }

  cancelAppointment(appointmentId: string, resultCallbackFn?: Function): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '65vw',
      desktop: '46vw'
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.data = { appointmentId };
    dialogConfig.panelClass = 'custom-modalbox-stretched';
    dialogConfig.maxWidth = 664;

    if (this.modalKeeperService.isModalOpen(Constants.MODAL_TITLES.cancelAppointment)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(Constants.MODAL_TITLES.cancelAppointment, null, this.contextKey);

    this.dialog
      .open(CancelAppointmentComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(Constants.MODAL_TITLES.cancelAppointment, null, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  rescheduleAppointment(appointment: Appointment, resultCallbackFn?: Function): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '80vw',
      desktop: '54vw'
    };

    const isAppointmentBooked: boolean = Boolean(appointment?.appointmentId);

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.data = {
      appointment,
      title: isAppointmentBooked
        ? Constants.MODAL_TITLES.rescheduleAppointment
        : Constants.MODAL_TITLES.changeAppointmentTime,
      confirmationTitle: isAppointmentBooked ? 'Rescheduled Appointment Confirmation' : 'Confirm New Appointment Time',
      isReschedule: isAppointmentBooked
    };
    dialogConfig.panelClass = 'custom-modalbox-size-medium';
    dialogConfig.maxWidth = 684;
    // this.functions.checkMobile() ? 611 : 683; //664;

    if (this.modalKeeperService.isModalOpen(dialogConfig.data.title, dialogConfig.data.confirmationTitle)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(
      dialogConfig.data.title,
      dialogConfig.data.confirmationTitle,
      this.contextKey
    );

    this.dialog
      .open(RescheduleAppointmentComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(
          dialogConfig.data.title,
          dialogConfig.data.confirmationTitle,
          this.contextKey
        );

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  changePractitioner(appointment: Appointment): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '662px',
      desktop: '662px'
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    if (!this.functions.checkMobile()) {
      dialogConfig.panelClass = 'custom-modalbox-stretched';
    }

    if (this.modalKeeperService.isModalOpen('change-practitioner')) {
      return;
    }

    this.modalKeeperService.registerModalOpened('change-practitioner', null, this.contextKey);

    this.dialog
      .open(AppointmentSelectPractitionerComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.modalKeeperService.registerModalClosed('change-practitioner', null, this.contextKey);
        this.functions.triggerResizeEvent();
      });
  }

  userPolicyEligibility(model: GetPolicyEligibilityDTO, patient: Patient, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      ...model
    };
    dialogConfig.maxWidth = 573; // Max width as per Figma designs

    if (this.modalKeeperService.isModalOpen('policy-eligibility', model.instructionsHtml)) {
      return;
    }

    this.modalKeeperService.registerModalOpened('policy-eligibility', model.instructionsHtml, this.contextKey);

    this.aiContext.info('DisplayUserPolicyEligibility', {
      source: 'API',
      message: 'Displaying policy eligibility modal',
      patientId: patient?.patientId
    });

    this.dialog
      .open(PolicyEligibilityModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed('policy-eligibility', model.instructionsHtml, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  weightLossCheckIn(dialogData: any = {}, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      ...dialogData
    };
    dialogConfig.maxWidth = 573; // Max width as per Figma designs

    this.dialog
      .open(WeightLossCheckInModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  switchPatient(dialogData: any = {}, resultCallbackFn?: Function): void {
    // do not open patient select as the patient has already been set through the Business Portal workflow
    if (this.b2bCustomerService.b2bCustomerPortalInfoLoaded && !!this.b2bCustomerService.getPatient) {
      return;
    }

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      ...dialogData
    };
    dialogConfig.maxWidth = 573; // Max width as per Figma designs

    if (this.modalKeeperService.isModalOpen('switch-patient')) {
      return;
    }

    this.modalKeeperService.registerModalOpened('switch-patient', null, this.contextKey);

    this.dialog
      .open(SwitchPatientComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed('switch-patient', null, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  healthCareInfo(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.panelClass = 'custom-modalbox-stretched';
    dialogConfig.maxWidth = 664;

    this.dialog
      .open(HealthCardInfoComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  pensionerConcessionModal(resultCallbackFn?: Function): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '65vw',
      desktop: '48vw'
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'custom-modalbox';

    this.dialog
      .open(PensionerConcessionCardComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  changePharmacyAddress(appointmentType: string, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Change ' + appointmentType,
      type: appointmentType
    };
    dialogConfig.maxWidth = 573;
    dialogConfig.panelClass = 'custom-modalbox-stretched';

    if (this.modalKeeperService.isModalOpen(dialogConfig.data.title)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(dialogConfig.data.title, null, this.contextKey);

    this.dialog
      .open(ChangeAddressPharmacyComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(dialogConfig.data.title, null, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  healthCareCards(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.maxWidth = 815;
    dialogConfig.panelClass = 'custom-modalbox-stretched';

    this.dialog
      .open(HealthcareCardsModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  addPatientmobilePhone(patientId: string, mobilePhoneNumber: string, resultCallbackFn?: Function): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '662px',
      desktop: '662px'
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.data = {
      patientId: patientId,
      mobilePhoneNumber: mobilePhoneNumber
    };

    if (!this.functions.checkMobile()) {
      dialogConfig.panelClass = 'custom-modalbox-stretched';
    }

    if (this.modalKeeperService.isModalOpen('add-patient-mobile-phone')) {
      return;
    }

    this.modalKeeperService.registerModalOpened('add-patient-mobile-phone', null, this.contextKey);

    this.dialog
      .open(PatientAddMobilePhoneNumberModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((mobilePhoneNumber: string) => {
        if (resultCallbackFn) {
          resultCallbackFn(mobilePhoneNumber);
        }
        this.modalKeeperService.registerModalClosed('add-patient-mobile-phone', null, this.contextKey);
        this.functions.triggerResizeEvent();
      });
  }

  quickScriptCart(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: Constants.MODAL_TITLES.modalCart,
      hideHeaderCloseButton: true
    };
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'custom-modalbox-stretched';

    if (this.modalKeeperService.isModalOpen(dialogConfig.data.title)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(dialogConfig.data.title, null, this.contextKey);

    this.dialog
      .open(QuickScriptCartComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(dialogConfig.data.title, null, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  forgotPassword(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.maxWidth = 573;

    this.dialog
      .open(ForgotPasswordComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  resetPassword(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.maxWidth = 573;

    this.dialog
      .open(ResetPasswordComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  verifyEmailSignup(emailAddress: string, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.maxWidth = 573;

    dialogConfig.data = {
      emailAddress: emailAddress,
      signupMode: true
    };

    this.dialog
      .open(EmailVerificationSignupModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((verified: boolean) => {
        if (resultCallbackFn) {
          resultCallbackFn(verified);
        }
        this.triggerResizeEvent();
      });
  }

  testVideoCamera(resultCallbackFn?: Function): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: '75vw',
      desktop: '52vw'
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    if (this.functions.checkMobile()) {
      dialogConfig.panelClass = 'custom-modalbox-stretched';
    }

    dialogConfig.data = {
      title: 'Camera Test',
      subtitle: 'Test the video camera on your device'
    };
    dialogConfig.maxWidth = 602;

    this.dialog
      .open(TestWebcamComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  testMicrophone(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Microphone Test',
      subtitle: 'Test the microphone on your device'
    };
    if (this.functions.checkMobile()) {
      dialogConfig.panelClass = 'custom-modalbox-stretched';
    }
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(TestMicrophoneComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  testInternetSpeed(resultCallbackFn?: Function): void {
    const customWidths: ModalWidthSpec = {
      mobile: DefaultModalWidths.mobile,
      tablet: DefaultModalWidths.tablet,
      desktop: DefaultModalWidths.desktop
    };

    let dialogConfig: MatDialogConfig = this.functions.getModalConfig(customWidths);

    dialogConfig.data = {
      title: 'Internet Speed Test',
      subtitle: 'Test the speed of your internet connection'
    };
    dialogConfig.maxWidth = 573;

    if (this.functions.checkMobile()) {
      dialogConfig.panelClass = 'custom-modalbox-stretched';
    }

    this.dialog
      .open(TestInternetComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  havingIssues(resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.maxWidth = 664;

    this.dialog
      .open(HavingIssueComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  showMedicationMessage(medication: Medication, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = { medication };
    dialogConfig.panelClass = 'custom-modalbox-size-regular';
    dialogConfig.maxWidth = 573; // Max width as per Figma designs

    this.dialog
      .open(MedicationModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  confirmationDialog(
    title?: string,
    message?: string,
    resultCallbackFn?: Function,
    hasCancel?: boolean,
    hasConfirm?: boolean,
    confirmButtonText?: string
  ): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      dialogTitle: title ?? null,
      confirmationMessage: message ?? null,
      hasCancel: hasCancel ?? null,
      hasConfirm: hasConfirm ?? null,
      confirmButtonText: confirmButtonText ?? 'Confirm'
    };
    dialogConfig.panelClass = 'custom-modalbox-size-tiny';
    dialogConfig.minWidth = 320;
    dialogConfig.maxWidth = 320;

    const cdTitle: string = dialogConfig.data.dialogTitle || Constants.MODAL_TITLES.confirmationDialog;
    if (this.modalKeeperService.isModalOpen(cdTitle, message)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(cdTitle, message, this.contextKey);

    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(cdTitle, message, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  compatibleDeviceList(message?: string, buttonToggle: boolean = null, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      dialogTitle: 'Compatible Device List',
      footerHeading: 'Is your device compatible?',
      text: message || '',
      buttonToggle
    };
    dialogConfig.panelClass = 'custom-modalbox-size-medium';
    dialogConfig.maxWidth = 573;

    if (this.modalKeeperService.isModalOpen(dialogConfig.data.dialogTitle)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(dialogConfig.data.dialogTitle, null, this.contextKey);
    this.dialog
      .open(ToggleButtonModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(dialogConfig.data.dialogTitle, null, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  playVideo(title: string, subtitle: string, videoSrc: string, resultCallbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title,
      subtitle,
      videoSrc
    };
    dialogConfig.panelClass = 'custom-modalbox-size-medium';
    dialogConfig.maxWidth = 573;

    if (this.modalKeeperService.isModalOpen(title, subtitle)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(title, subtitle, this.contextKey);
    this.dialog
      .open(VideoModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(title, subtitle, this.contextKey);

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.triggerResizeEvent();
      });
  }

  /**
   * @function showLoader
   * @description Show a full-screen loading animation with semi-transparent background
   *
   * @returns {() => void} callback function to hide/close the loading modal
   */
  showLoader(): () => void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.panelClass = 'custom-modalbox-fullscreen';

    dialogConfig.data = {
      isMultiple: true
    };

    const modal: MatDialogRef<LoadingModalComponent, any> = this.dialog.open(LoadingModalComponent, dialogConfig);

    return () => {
      modal.close();
    };
  }
}
