import { Component, HostListener, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  dialogTitle: string = 'Confirm';
  confirmationMessage: string = 'Are you sure you want to proceed?';
  hasConfirm: boolean = true;
  hasCancel: boolean = true;
  confirmButtonText: string = 'Confirm';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    if (data?.dialogTitle) {
      this.dialogTitle = data.dialogTitle;
    }
    if (data?.confirmationMessage) {
      this.confirmationMessage = data.confirmationMessage;
    }
    if (typeof data?.hasConfirm === 'boolean') {
      this.hasConfirm = data.hasConfirm;
    }
    if (typeof data?.hasCancel === 'boolean') {
      this.hasCancel = data.hasCancel;
    }
    if (this.data?.confirmButtonText) {
      this.confirmButtonText = this.data.confirmButtonText;
    }
  }

  // Close dialog on Escape (CANCEL)
  @HostListener('document:keyup.escape', ['$event']) onEscapeHandler(_event: KeyboardEvent) {
    if (!this.hasCancel) {
      return;
    }

    if (_event) {
      _event.preventDefault();
    }
    this.dialogRef.close(false);
  }

  // Close dialog on Enter (CONFIRM)
  @HostListener('document:keyup.enter', ['$event']) onEnterHandler(_event: KeyboardEvent) {
    if (!this.hasConfirm) {
      return;
    }

    if (_event) {
      _event.preventDefault();
    }
    this.dialogRef.close(true);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.dialogRef = null;
  }
}
