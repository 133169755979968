import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { SessionStorageService } from "ngx-webstorage";
import { Subject } from "rxjs";
import { Constants } from "../constants";
import { Functions } from "../functions";
import { IResponseAPI } from "../models/api-response";
import { PolicyToken } from "../models/policy-token";
import { WhiteLabelConfigDTO } from "../models/whitelabel/WhiteLabelConfigDTO";
import { AIContext, AppInsightsService } from "./appinsights.service";
import { WhitelabelService } from "./whitelabel.service";
import { PatientAttributionService } from "./patient-attribution.service";
import { PromiseHelperService } from "./promise-helper.service";
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { STEP_PATH } from '../step-configuration';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  private logPrefix: string = '[AgencyService]';
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private _agencyCode: string;
  private _aiContext: AIContext;
  private _serviceType: string;

  private _storedAgencyCode: string;
  private _agencyBenefitRemoved: boolean;
  private _agencyPolicies: any = {};

  private isNative: boolean = Capacitor.isNativePlatform();

  private _agencyBenefitRemovedChange: Subject<boolean> = new Subject<boolean>();
  public agencyBenefitRemovedObs = this._agencyBenefitRemovedChange.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private functions: Functions,
    private storageSession: SessionStorageService,
    private whiteLabelService: WhitelabelService,
    private patientAttributionService: PatientAttributionService,
    private aiService: AppInsightsService,
    private promiseHelperService: PromiseHelperService
  ) {
    this._aiContext = this.aiService.createContext('AgencyService');
  }

  get agencyCode(): string {
    return this._agencyCode ?? this.getDefaultAgencyCode();
  }

  get serviceType(): string {
    return this._serviceType ?? Constants.SERVICE_TYPE.DOCTOR;
  }

  retrieveAgencyCode(): string {
    return this._storedAgencyCode || this.agencyCode;
  }

  getDefaultAgencyCode(): string {
    return environment.agencyCode;
  }

  resetSessionAgencyToDefault(clearBenefit: boolean = false): void {
    this._agencyCode = this.getDefaultAgencyCode();
    this._storedAgencyCode = this._agencyCode;

    try {
      this.storageSession.clear(Constants.LocalStorage_Key.sessionAgencyCode);
      this.storageSession.clear(Constants.LocalStorage_Key.agencyServiceConfiguration);

      const storedBenefit: string = this.storageSession.retrieve(Constants.LocalStorage_Key.benefit);

      if (clearBenefit || storedBenefit?.indexOf('BLUA') !== -1 || storedBenefit?.indexOf('SUPERPHARMACY') !== -1) {
        this.removeBenefitFromStorage();
        this._agencyBenefitRemovedChange.next((this._agencyBenefitRemoved = true));
      } else {
        this._agencyBenefitRemovedChange.next((this._agencyBenefitRemoved = false));
      }
    } catch (_err: any) {}

    this.patientAttributionService.resetAgencyId(false);
  }

  removeBenefitFromStorage(): void {
    this.storageSession.clear(Constants.LocalStorage_Key.benefit);
    this.storageSession.clear(Constants.LocalStorage_Key.benefit_object);
  }

  async initialiseService(serviceType?: string): Promise<void> {
    try {
      this._storedAgencyCode = this.storageSession.retrieve(Constants.LocalStorage_Key.sessionAgencyCode);
    } catch (err: any) {
      this._aiContext.trackException(err, 'InitialiseAgencyService', { serviceType });
    }

    if (
      this._storedAgencyCode &&
      typeof this._storedAgencyCode === 'string' &&
      this._storedAgencyCode !== Constants.SERVICE_TYPE.WEIGHT_LOSS // Legacy, WeightLoss is no longer an Agency, can be removed.
    ) {
      await this.setSessionAgency(this._storedAgencyCode, true, serviceType)
        .then(() => {
          this._aiContext.trackEvent('InitWithStoredAgencyCode', { code: this.agencyCode });
        })
        .catch((err: any) => {
          this._aiContext.trackException(err);
          console.error(this.logPrefix, 'Failed to initialise with stored agency code', err);
        });
    } else {
      this.resetSessionAgencyToDefault(false); // reset agency to DoD but do not remove benefit
      this.whiteLabelService.setWhiteLabelConfig(null); // set default DoD config
    }

    this._aiContext.debug('AgencyInitialised', { agencyCode: this._agencyCode });
  }

  /**
   * @function setSessionAgency
   * @description Set the current session's agency and initialise whitelabel configuration
   *
   * @param {string} agencyCode
   * @param {boolean} shouldSkipHttpRefererValidation
   * @param {string} [serviceType]
   * @returns
   */
  async setSessionAgency(
    agencyCode: string,
    shouldSkipHttpRefererValidation: boolean,
    serviceType?: string
  ): Promise<boolean> {
    if (!agencyCode || typeof agencyCode !== 'string') {
      return false;
    }

    this._agencyCode = agencyCode.trim().toLowerCase();
    this._storedAgencyCode = this._agencyCode;
    this._serviceType = serviceType?.trim().toLowerCase() ?? Constants.SERVICE_TYPE.DOCTOR;

    try {
      this.storageSession.store(Constants.LocalStorage_Key.sessionAgencyCode, this._agencyCode);
      this.storageSession.store(Constants.LocalStorage_Key.sessionServiceType, this._serviceType);
    } catch (err: any) {
      this._aiContext.trackException(err, 'SetSessionAgency_StoreAgencyCodeAndServiceType');
    }

    // SET WHITELABEL CONFIGURATION FOR CURRENT SESSION (ENTRY POINT)
    const success: boolean = await this.retrieveAndSetWhiteLabelConfiguration(
      shouldSkipHttpRefererValidation,
      serviceType
    );

    if (success) {
      const whiteLabelConfig: WhiteLabelConfigDTO = this.whiteLabelService.getWhiteLabelConfig();

      if (whiteLabelConfig?.agencyId) {
        this._aiContext.reportSuccessStatus('SetSessionAgency', true, 'WhiteLabelConfigurationInitialised', {
          agencyName: whiteLabelConfig.name,
          agencyCode: whiteLabelConfig.code,
          agencyId: whiteLabelConfig.agencyId,
          serviceType: this._serviceType
        });

        // Set the patient agency attribution
        this.patientAttributionService.setAgencyId(whiteLabelConfig.agencyId, true);
      }
    }

    return success;
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/agency/validate/{agencyCode}
  validateAgencyCode(agencyCode: string): Promise<boolean> {
    return this.http
      .get(`${this.url}/agency/${agencyCode}/validate`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && typeof response.response === 'boolean') {
          return response.response;
        }
        return false;
      })
      .catch((err: any) => {
        console.log('Failed to validate agency code "' + agencyCode + '". Error:', this.functions.getErrorMessage(err));
        return false;
      });
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/agency/generateToken
  generatePolicyForB2BClient(agencyCode: string, serviceType: string): Promise<PolicyToken> {
    if (!agencyCode?.length) {
      return Promise.resolve(null);
    }

    return this.http
      .post(`${this.url}/agency/${agencyCode}/generatePolicy?serviceType=${serviceType}`, {})
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const policy = response.response as PolicyToken;
          return policy;
        }
        return null;
      })
      .catch((err: any) => {
        console.log(
          'Failed to generate token for agency code "' + agencyCode + '". Error:',
          this.functions.getErrorMessage(err)
        );
        return null;
      });
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/agency/{code}/generateMedicarePolicy
  generateMedicarePolicyForB2BAgency(agencyCode: string): Promise<PolicyToken | null> {
    if (!agencyCode?.length) {
      return Promise.resolve(null);
    }

    const promiseStorageKey: string = 'agencyPolicies';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      agencyCode
    );

    if (!returnExistingPromise) {
      const newPromise: Promise<PolicyToken | null> = this.http
        .post(`${this.url}/agency/${agencyCode}/generateMedicarePolicy`, {})
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.response) {
            return response.response as PolicyToken;
          }

          return null;
        })
        .catch((err: any) => {
          console.log(
            'Failed to generate medicare policy for agency code "' + agencyCode + '". Error:',
            this.functions.getErrorMessage(err)
          );

          this.promiseHelperService.setErrorState(promiseStorageKey, err);

          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<PolicyToken>(promiseStorageKey, newPromise, agencyCode);
    }

    return this.promiseHelperService.getPromiseByKey<PolicyToken | null>(promiseStorageKey);
  }

  /**
   * @private
   * @function retrieveAndSetWhiteLabelConfiguration
   * @description Retrieve whitelabel service configuration from the API
   *
   * @param {boolean} shouldSkipHttpRefererValidation check Referrer to make sure it matches the agency's known domain
   * @param {string} [serviceType] set the primary appointment service type or default to 'doctor'
   *
   * @returns {Promise<boolean>}
   */
  private async retrieveAndSetWhiteLabelConfiguration(
    shouldSkipHttpRefererValidation: boolean,
    serviceType?: string
  ): Promise<boolean> {
    if (this._agencyCode === this.getDefaultAgencyCode()) {
      this.whiteLabelService.setWhiteLabelConfig(null);
      return true;
    }

    // Retrieve Whitelabel Configuration from the API
    const config: WhiteLabelConfigDTO = await this.http
      .get(`${this.url}/agency/${this._agencyCode}/whitelabel`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          let serviceConfiguration = response.response as WhiteLabelConfigDTO;
          if (!serviceConfiguration?.code) {
            serviceConfiguration.code = this._agencyCode;
          }
          if (!serviceConfiguration?.primaryAppointmentServiceType) {
            serviceConfiguration.primaryAppointmentServiceType = serviceType || Constants.SERVICE_TYPE.DOCTOR;
          }
          return serviceConfiguration;
        }
        return null;
      })
      .catch((err: any) => {
        console.warn(
          'Unable to retrieve whitelabel configuration for agency "' +
            this._agencyCode +
            '". Error: ' +
            this.functions.getErrorMessage(err)
        );

        this.resetSessionAgencyToDefault(true);
        this.whiteLabelService.setWhiteLabelConfig(null);

        return null;
      });

    // Validate http referer if required
    if (!shouldSkipHttpRefererValidation && !this.isHttpReferrerValidForAgency(document.referrer, config)) {
      this.functions.showToast('The link you followed is invalid');
      this._aiContext.debugLog('AgencyHttpReferrerValid', {
        result: false,
        referrer: document.referrer,
        agencyCode: this._agencyCode,
        config
      });
      return false;
    }

    this._aiContext.debugLog('WhiteLabelConfigurationRetrievedFromAPI', { config });

    this.whiteLabelService.setWhiteLabelConfig(config);

    return Boolean(config);
  }

  /**
   * @function isHttpReferrerValidForAgency
   * @description Check the list of configured urls to match the Referrer of the most recent request
   *
   * @param {string} httpReferrer Referrer url
   * @param {WhiteLabelConfigDTO} whiteLabelConfig
   *
   * @returns {boolean} true if the Referrer is valid
   */
  isHttpReferrerValidForAgency(httpReferrer: string, whiteLabelConfig: WhiteLabelConfigDTO): boolean {
    // If validHttpReferrers have not been defined for the agency then allow any referrer
    if (!whiteLabelConfig?.urls?.validHttpReferrers || !whiteLabelConfig?.urls?.validHttpReferrers?.length) {
      return true;
    }

    // If referrers have been defined for the agency and the httpReferrer for the given request is valid, then allow
    if (whiteLabelConfig?.urls?.validHttpReferrers?.some((ref: string) => httpReferrer.includes(ref))) {
      return true;
    }

    return false;
  }

  /**
   * @function navigateToDashboardOrLandingPage
   * @description Navigate to the whitelabel landing page, if specified. Redirect authenticated users to
   * the dashboard if flow escape is not explicitly prevented in the whitelabel service configuration.
   *
   * @param {boolean} isAuthenticated
   * @param {boolean} [preventFlowEscape]
   * @param {any} callbackFn function to be executed after navigating to a route within the PWA
   *
   * @returns {boolean} true if we remain in the PWA, false if redirecting to an external page
   */
  navigateToDashboardOrLandingPage(
    isAuthenticated: boolean = false,
    preventFlowEscape: boolean = false,
    callbackFn?: any
  ): boolean {
    const whiteLabelConfig: WhiteLabelConfigDTO = this.whiteLabelService.getWhiteLabelConfig();
    const enableMarketingSite: boolean = this.whiteLabelService.marketingSiteEnabled();
    // const preventFlowEscape: boolean = this.whiteLabelService.isPreventFlowEscape();
    const landingPage: string = whiteLabelConfig.urls?.landingPage;

    // If flow escape is not prevented and patient is authenticated, or agency is DoD, or this
    // is the native mobile app, then redirect patient to the dashboard
    if (this.isNative || (isAuthenticated && !preventFlowEscape) || whiteLabelConfig.isDefault) {
      this.navigateToDashboardWithCallback(callbackFn);
      return true;
    }

    /*
    // If landing page is specified in the ScopedConfigurableValues for this agency-service-definition
    // then determine where to redirect the user based on whether the path is absolute or relative. eg.

    1. /app/dashboard/manage-appointments  -- relative URL, PWA app route
    2. /WestFund -- relative URL, DoD Marketing site agency page
    3. https://www.doctorsondemand.com.au/WestFund -- absolute URL to DoD Marketing site agency page
    4. https://www.westfund.com.au/ -- absolute URL to agency site

    */

    if (landingPage) {
      if (landingPage.indexOf('/app/') === 0) {
        this.router.navigate([landingPage.substring(4)]).then(() => {
          if (callbackFn) {
            callbackFn();
          }
        });
        return true;
      } else if (landingPage.indexOf('/') === 0) {
        // In case user presses the 'Back' button in their browser, do not clear the agency
        // this.resetSessionAgencyToDefault(false);
        window.location.href = environment.marketingSite + landingPage.substring(1);
      } else {
        // In case user presses the 'Back' button in their browser, do not clear the agency
        // this.resetSessionAgencyToDefault(false);
        window.location.href = landingPage;
      }
    } else if (preventFlowEscape && enableMarketingSite) {
      window.location.href = environment.marketingSite;
    } else {
      // If patient is not authenticated, they will be redirected to the login page instead
      this.navigateToDashboardWithCallback(callbackFn);
      return true;
    }

    return false;
  }

  navigateToDashboardWithCallback(callbackFn?: any): void {
    this.router.navigate(['/' + STEP_PATH.DASHBOARD]).then(() => {
      if (callbackFn) {
        callbackFn();
      }
    });
  }
}
