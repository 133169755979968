import { effect, EffectRef, Injectable, Signal } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { NullOrUndefined } from '../types';
import { filter, map, Observable, Subject } from 'rxjs';

type KeyValueMapping = {
  'Appointment:InitialPharmacyId': string;
};

type Keys = keyof KeyValueMapping;

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly cache: Partial<KeyValueMapping> = {};

  private readonly onChange$ = new Subject<Partial<KeyValueMapping>>();

  constructor(private readonly sessionStore: SessionStorageService) {}

  retrieve<K extends Keys>(key: K): KeyValueMapping[K] | NullOrUndefined {
    let value = this.cache[key];
    if (!value) {
      value = this.sessionStore.retrieve(key);
    }

    return value;
  }

  store<K extends Keys>(key: K, value: KeyValueMapping[K]) {
    this.cache[key] = value;
    this.sessionStore.store(key, value);
    this.onChange$.next({
      [key]: value
    });
  }

  onChange<K extends Keys>(key: K): Observable<KeyValueMapping[K]> {
    return this.onChange$.asObservable().pipe(
      filter((x) => Object.keys(x)[0] === key),
      map((x) => x[key])
    );
  }

  storeOnSignalChange<K extends Keys>(key: Keys, signal: Signal<KeyValueMapping[K]>): EffectRef {
    return effect(() => {
      const value = signal();
      if (value) {
        this.store(key, value);
      } else {
        this.delete(key);
      }
    });
  }

  delete(key: Keys) {
    if (!key) {
      return;
    }
    delete this.cache[key];
    this.sessionStore.clear(key);
    this.onChange$.next({ [key]: undefined });
  }
}
