import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { SessionStorageService } from 'ngx-webstorage';
import { CredentialsService } from '@app/core/services/credentials.service';
import { GeneralMedical } from '../models/general-medical';
import { WeightLossLogEntry } from '../models/weightloss/weightLossLogEntry';
import { environment } from '@env/environment';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GeneralMedicalService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly medicalHistoryUrl: string = `${environment.apiBaseUrl}${this.endpointPrefix}/patient`;

  private _medicalHistory: GeneralMedical = null;
  private _weightLossHistory: GeneralMedical = null;

  // When tracking Medical History change, we provide the patientId for whom the history has changed
  private _medicalHistoryChange: Subject<string> = new Subject<string>();
  public medicalHistoryChangeObs = this._medicalHistoryChange.asObservable();

  private _weightLossDataChangeObs: Subject<GeneralMedical> = new Subject<GeneralMedical>();
  public weightLossDataChangeObs = this._weightLossDataChangeObs.asObservable();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private storage: SessionStorageService,
    private credentialsService: CredentialsService
  ) {}

  ngOnDestroy(): void {
    this._medicalHistory = null;
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/generalMedical
  getMedicalHistory(patientId: string): Promise<GeneralMedical> {
    this.retrieveMedicalHistory(patientId);

    // Check session
    if (this.medicalHistory && this.medicalHistory[patientId]) {
      // console.log('[GENERAL-MEDICAL-SERVICE] getMedicalHistory() from memory');
      return Promise.resolve(this.medicalHistory[patientId]);
      // Retrieve from API
    } else if (this.credentialsService.isAuthenticated()) {
      // console.log('[GENERAL-MEDICAL-SERVICE] getMedicalHistory() from API');
      return this.http
        .get(`${this.medicalHistoryUrl}/${patientId}/generalMedical`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            const medicalHistory = response.response as GeneralMedical;
            const combinedMedicalProfile: GeneralMedical = {
              ...(medicalHistory || {}),
              ...(this.weightLossHistory[patientId] || {})
            };

            this.storeMedicalHistory(patientId, combinedMedicalProfile);

            return combinedMedicalProfile;
          } else {
            console.log(
              '[GENERAL-MEDICAL-SERVICE] getMedicalHistory() FAILED. Error:',
              this.functions.getErrorMessage(response)
            );
          }

          return null;
        })
        .catch((err: any) => {
          console.log(
            '[GENERAL-MEDICAL-SERVICE] getMedicalHistory() FAILED. Error:',
            this.functions.getErrorMessage(err)
          );
          this.functions.handleError(err);
          return null;
        });
    } else {
      return Promise.resolve(null);
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/weightloss
  getWeightLossHistory(patientId: string, forceAPICall: boolean = false): Promise<GeneralMedical> {
    if (!Object.keys(this.weightLossHistory).length || !this._weightLossHistory[patientId]) {
      const storedMedicalHistory: GeneralMedical = this.retrieveMedicalHistory(patientId);

      if (typeof storedMedicalHistory?.goalWeight === 'number') {
        this._weightLossHistory[patientId] = storedMedicalHistory;
      }
    }

    // Check session
    if (!forceAPICall && this.weightLossHistory[patientId]) {
      return Promise.resolve(this.weightLossHistory[patientId]);
      // Retrieve from API
    } else if (this.credentialsService.isAuthenticated()) {
      return this.http
        .get(`${this.medicalHistoryUrl}/${patientId}/weightloss`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            let combinedMedicalProfile: GeneralMedical = this.medicalHistory[patientId] || {};
            const weightLossHistory = response.response as GeneralMedical;

            if (weightLossHistory) {
              combinedMedicalProfile = {
                ...combinedMedicalProfile,
                ...weightLossHistory
              };

              // Save full medical history to storage
              this.storeMedicalHistory(patientId, combinedMedicalProfile);

              // Trigger Weight Loss change observer
              this._weightLossDataChangeObs.next((this.weightLossHistory[patientId] = combinedMedicalProfile));
            }

            return combinedMedicalProfile;
          } else {
            console.log(
              '[GENERAL-MEDICAL-SERVICE] getWeightLossHistory() FAILED. Error:',
              this.functions.getErrorMessage(response)
            );
          }

          return null;
        })
        .catch((err: any) => {
          console.log(
            '[GENERAL-MEDICAL-SERVICE] getWeightLossHistory() FAILED. Error:',
            this.functions.getErrorMessage(err)
          );
          this.functions.handleError(err);

          return null;
        });
    } else {
      return Promise.resolve(null);
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/generalMedical
  saveMedicalHistory(patientId: string, genMedical: GeneralMedical): Promise<IResponseAPI | null> {
    const medicalHistory: GeneralMedical = this.mapMedicalHistoryFromGeneralMedical(genMedical);

    return this.http
      .put(`${this.medicalHistoryUrl}/${patientId}/generalMedical`, medicalHistory)
      .toPromise()
      .then(async (response: IResponseAPI) => {
        if (response?.success && response.response) {
          // Check if general medical data contains weight loss parameters
          if (typeof genMedical.currentWaist === 'number' && typeof genMedical.goalWeight === 'number') {
            return await this.saveWeightLoss(patientId, genMedical);
          } else {
            this.storeMedicalHistory(patientId, genMedical);
          }
        } else {
          console.log(
            '[GENERAL-MEDICAL-SERVICE] saveMedicalHistory() FAILED. Error:',
            this.functions.getErrorMessage(response)
          );
        }

        return response;
      })
      .catch((err: any) => {
        console.log(
          '[GENERAL-MEDICAL-SERVICE] saveMedicalHistory() FAILED. Error:',
          this.functions.getErrorMessage(err)
        );
        // return this.functions.handleErrorAndRedirect(err);
        throw err;
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/weightloss
  saveWeightLoss(patientId: string, genMedical: GeneralMedical): Promise<any> {
    const weightLossQuestions: GeneralMedical = this.mapWeightLossFromGeneralMedical(genMedical);

    return this.http
      .post(`${this.medicalHistoryUrl}/${patientId}/weightloss`, weightLossQuestions)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          const nowUtc: string = moment.utc().format();

          // Manually add log entry so that we don't have to retrieve latest weight loss data from the API
          let weightLossLogs: WeightLossLogEntry[] = genMedical.patientWeightLossLogs || [];

          weightLossLogs.push({
            asOfDate: nowUtc,
            currentWeight: genMedical.currentWeight,
            currentWaist: genMedical.currentWaist,
            goalWeight: genMedical.goalWeight,
            goalWaist: genMedical.goalWaist
          });
          genMedical.patientWeightLossLogs = weightLossLogs;

          genMedical.currentHeight =
            genMedical.currentHeight || (genMedical.height ? parseInt(genMedical.height.replace('cm', '')) : null);
          genMedical.originalWeight = genMedical.originalWeight || genMedical.currentWeight;
          genMedical.originalWaist = genMedical.originalWaist || genMedical.currentWaist;

          // Update last modified date (generate an ISO 8601 date string)
          genMedical.modified = nowUtc;

          const combinedMedicalProfile: GeneralMedical = {
            ...(this.medicalHistory[patientId] || {}),
            ...genMedical
          };

          // Save full medical history to storage
          this.storeMedicalHistory(patientId, combinedMedicalProfile);

          this._weightLossDataChangeObs.next((this.weightLossHistory[patientId] = combinedMedicalProfile));

          return response;
        } else {
          console.log(
            '[GENERAL-MEDICAL-SERVICE] saveWeightLoss() FAILED. Error:',
            this.functions.getErrorMessage(response)
          );
        }

        return null;
      })
      .catch((err: any) => {
        console.log('[GENERAL-MEDICAL-SERVICE] saveWeightLoss() FAILED. Error:', this.functions.getErrorMessage(err));
        return this.functions.handleErrorAndRedirect(err);
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/weightloss/goals
  saveWeightLossGoals(patientId: string, goalWeight: number, goalWaist: number): Promise<IResponseAPI | null> {
    const goalData: GeneralMedical = { patientId, goalWeight, goalWaist };

    return this.http
      .post(`${this.medicalHistoryUrl}/${patientId}/weightloss/goals`, goalData)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const combinedMedicalProfile: GeneralMedical = {
            ...(this.medicalHistory[patientId] || {}),
            ...goalData
          };

          // Save full medical history to storage
          this.storeMedicalHistory(patientId, combinedMedicalProfile);

          this._weightLossDataChangeObs.next((this.weightLossHistory[patientId] = combinedMedicalProfile));

          return response;
        } else {
          console.log(
            '[GENERAL-MEDICAL-SERVICE] saveWeightLossGoals() FAILED. Error:',
            this.functions.getErrorMessage(response)
          );
        }

        return null;
      })
      .catch((err: any) => {
        console.log(
          '[GENERAL-MEDICAL-SERVICE] saveWeightLossGoals() FAILED. Error:',
          this.functions.getErrorMessage(err)
        );
        return this.functions.handleErrorAndRedirect(err);
      });
  }

  storeMedicalHistoryFilled(patientId: string): void {
    try {
      this.storage.store(Constants.LocalStorage_Key.medicalHistoryFilled.concat('.', patientId), true);
    } catch (_err: any) {}
  }

  clearMedicalHistoryFilled(patientId: string): void {
    try {
      this.storage.clear(Constants.LocalStorage_Key.medicalHistoryFilled.concat('.', patientId));
    } catch (_err: any) {}
  }

  isMedicalHistoryFilled(patientId: string): boolean {
    let isFilled: boolean = false;

    try {
      isFilled = this.storage.retrieve(Constants.LocalStorage_Key.medicalHistoryFilled.concat('.', patientId));
    } catch (_err: any) {}

    return !!isFilled;
  }

  storeMedicalHistory(patientId: string, medicalHist: GeneralMedical): void {
    if (medicalHist && patientId) {
      this.medicalHistory[patientId] = medicalHist;

      try {
        this.storage.store(Constants.LocalStorage_Key.medicalHistory.concat('.', patientId), medicalHist);
      } catch (_err: any) {}

      this._medicalHistoryChange.next(patientId);
    }
  }

  retrieveMedicalHistory(patientId: string): GeneralMedical {
    if (patientId) {
      if (!Object.keys(this.medicalHistory).length || !this.weightLossHistory[patientId]) {
        const storedMedicalHistory: GeneralMedical = this.getMedicalHistoryFromStorage(patientId);
        if (typeof storedMedicalHistory?.alcohol === 'string') {
          this._medicalHistory[patientId] = storedMedicalHistory;
        }
      }

      return this.medicalHistory[patientId] || null;
    }

    return null;
  }

  getMedicalHistoryFromStorage(patientId: string): GeneralMedical {
    try {
      return this.storage.retrieve(Constants.LocalStorage_Key.medicalHistory.concat('.', patientId)) || null;
    } catch (_err: any) {}

    return null;
  }

  mapMedicalHistoryFromGeneralMedical(generalMedical: GeneralMedical): GeneralMedical {
    if (!generalMedical) {
      return new GeneralMedical();
    }

    const weight: string =
      typeof generalMedical.weight === 'string' && generalMedical.weight.indexOf('kg') !== -1
        ? generalMedical.weight
        : typeof generalMedical.currentWeight === 'number' && generalMedical.currentWeight > 0
          ? String(generalMedical.currentWeight) + 'kg'
          : null;

    return {
      patientId: generalMedical.patientId,
      smoking: generalMedical.smoking,
      smokingMore: generalMedical.smokingMore,
      smokingMore2: generalMedical.smokingMore2,
      alcohol: generalMedical.alcohol,
      heartProblems: generalMedical.heartProblems,
      heartProblemsMore: generalMedical.heartProblemsMore,
      heartProblemsMore2: generalMedical.heartProblemsMore2,
      allergies: generalMedical.allergies,
      allergiesMore: generalMedical.allergiesMore,
      otherMedication: generalMedical.otherMedication,
      otherMedicationMore: generalMedical.otherMedicationMore,
      height: generalMedical.height,
      weight,
      bloodPressure: generalMedical.bloodPressure,
      bloodPressureValue: generalMedical.bloodPressureValue
    } as GeneralMedical;
  }

  mapWeightLossFromGeneralMedical(generalMedical: GeneralMedical): GeneralMedical {
    if (!generalMedical) {
      return new GeneralMedical();
    }

    const currentWeight: number =
      typeof generalMedical.currentWeight === 'number' && generalMedical.currentWeight > 0
        ? generalMedical.currentWeight
        : generalMedical.weight
          ? parseFloat(String(generalMedical.weight).replace('kg', '')) // weight is a decimal
          : null;

    const currentHeight: number =
      typeof generalMedical.height === 'string'
        ? parseInt(generalMedical.height.replace('cm', '')) // height is an integer
        : typeof generalMedical.currentHeight === 'number'
          ? generalMedical.currentHeight
          : null;

    return {
      // Record identifiers
      patientId: generalMedical.patientId,
      patientWeightLossProfileId: generalMedical.patientWeightLossProfileId || null,

      // Weight Loss change logs (don't send via API)
      // patientWeightLossLogs: generalMedical.patientWeightLossLogs || [],

      // Current weight change parameters
      currentWaist: generalMedical.currentWaist,
      goalWaist: generalMedical.goalWaist,
      currentWeight,
      goalWeight: generalMedical.goalWeight,
      currentHeight,

      // Medical history questions specific to weight loss
      pregnantOrBreastFeeding: generalMedical.pregnantOrBreastFeeding,
      wasPregnantInLastYear: generalMedical.wasPregnantInLastYear,
      highBloodPressure: generalMedical.highBloodPressure,
      highCholesterolOrTriglycerides: generalMedical.highCholesterolOrTriglycerides,
      type1Diabetes: generalMedical.type1Diabetes,
      type2Diabetes: generalMedical.type2Diabetes,
      obstructiveSleepApnoea: generalMedical.obstructiveSleepApnoea,
      anxiety: generalMedical.anxiety,
      depression: generalMedical.depression,
      postTraumaticStressDisorder: generalMedical.postTraumaticStressDisorder,
      substanceAbuseDisorder: generalMedical.substanceAbuseDisorder,
      schizophrenia: generalMedical.schizophrenia,

      // Is patient qualified for the weight loss program?
      // approvalStatus: generalMedical.approvalStatus ?? false,

      // Last modified (ISO 8601 date string)
      modified: generalMedical.modified
    } as GeneralMedical;
  }

  get medicalHistory(): GeneralMedical {
    if (!this._medicalHistory) {
      this._medicalHistory = {};
    }

    return this._medicalHistory;
  }

  get weightLossHistory(): GeneralMedical {
    if (!this._weightLossHistory) {
      this._weightLossHistory = {};
    }

    return this._weightLossHistory;
  }
}
