<div class="confirmation-dialog-wrapper">
  <button type="button" name="hidden_focus_element" class="d-none"></button>

  <h1 class="confirm-title header-title" md-dialog-title>{{ dialogTitle }}</h1>

  <div class="confirm-content" md-dialog-content [innerHtml]="confirmationMessage"></div>

  <div class="confirm-action-buttons" md-dialog-actions [ngClass]="!hasCancel && hasConfirm ? 'confirm-only' : ''">
    <button
      *ngIf="hasConfirm"
      mat-flat-button
      color="accent"
      (keydown.enter)="dialogRef.close(true)"
      (click)="dialogRef.close(true)"
    >
      {{ confirmButtonText }}
    </button>
    <button
      *ngIf="hasCancel"
      mat-stroked-button
      (keydown.escape)="dialogRef.close(false)"
      (click)="dialogRef.close(false)"
    >
      Cancel
    </button>
  </div>
</div>
