import { AbstractControl, ValidationErrors } from '@angular/forms';

export function safetyNetValidator(control: AbstractControl): ValidationErrors | null {
  const input: string = (control.value ?? '').toUpperCase();
  let isValid: boolean = false;

  if (!input) {
    isValid = true;
  } else if (input.length < 12) {
    // isValid = false;
  } else {
    // CN/SN-000000000
    const cardNumber: string = input.replace(/\s+/g, '').replace(/[CSN-]/g, '').substring(0, 12);
    try {
      if (parseInt(cardNumber).toString() === cardNumber) {
        isValid = true;
      }
    } catch (err: any) {}

    isValid = isValid && (input.startsWith('CN-') || input.startsWith('SN-'))
  }

  if (!isValid) {
    return { invalid: true };
  } else {
    return null;
  }
}
