import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'on-demand-queue-size',
  templateUrl: './on-demand-queue-size.component.html',
  styleUrls: ['./on-demand-queue-size.component.scss']
})
export class OnDemandQueueSizeComponent implements OnInit {
  @Input('onDemandQueueSize') onDemandQueueSize: number = null;

  constructor() {}

  ngOnInit() {}
}
