import { Injectable, OnDestroy } from '@angular/core';
import { AIContext, AppInsightsService } from './appinsights.service';
import { environment } from '@src/environments/environment';
import { TransactionData, WorkerRegistration } from '../models/worker/worker-registration';
import { TransactionPromise } from '../models/worker/transaction-promise';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class WorkerAdapterService implements OnDestroy {
  private workerRegistrations: Record<string, WorkerRegistration> = {};

  private aiCtx: AIContext;

  constructor(aiService: AppInsightsService) {
    this.aiCtx = aiService.createContext('WorkerAdapterService');
  }

  ngOnDestroy(): void {
    if (Object.keys(this.workerRegistrations).length) {
      Object.values(this.workerRegistrations).forEach((registration: WorkerRegistration) => {
        registration.terminate();
      });
    }
  }

  /**
   * @function registerWorker
   * @description Register a worker with the given path
   *
   * @param {string} path the path to the worker script
   *
   * @returns {WorkerRegistration}
   */
  public registerWorker(path: string): WorkerRegistration {
    const worker: Worker = new Worker(path);

    const regId: string = this.getNewId('worker');

    this.aiCtx.debug('registerWorker', { regId, path });

    const reg: WorkerRegistration = {
      id: regId,
      worker: worker,
      txnAwaits: {},

      sendMessage: (type: string, data: any) => {
        this.aiCtx.debugLog('sendMessage', { regId, type });

        worker.postMessage({
          Type: type,
          Data: data
        } as TransactionData);
      },

      sendRequest: <TResult>(type: string, data: any) => {
        const txnId: string = this.getNewId(type);

        this.aiCtx.debugLog('sendRequest', { regId, txnId, type });

        return new Promise<TResult>((resolve, reject) => {
          reg.txnAwaits[txnId] = {
            resolve,
            reject,
            type,
            startTime: performance.now()
          };

          worker.postMessage({
            TxnId: txnId,
            Type: type,
            Data: data
          } as TransactionData);
        });
      },

      terminate: () => {
        this.aiCtx.trackEvent('terminate', { regId });

        // Terminate the worker
        worker.terminate();

        // Clean up any pending transaction promises that are still waiting
        for (const txnId in reg.txnAwaits) {
          const promise: TransactionPromise = reg.txnAwaits[txnId];

          if (!promise) {
            continue;
          }

          promise.reject('Worker terminated');

          delete reg.txnAwaits[txnId];
        }

        delete this.workerRegistrations[reg.id];
      }
    };

    // Setup the transaction listener
    this.setupTxnListener(reg);

    // Configure the worker
    reg.sendMessage('DoD.PWA.Configure', {
      API3URL: environment.apiBaseUrl,
      TimezoneOffset: Constants.Default_TimeZone_Offset
    });

    // Store the registration
    this.workerRegistrations[regId] = reg;

    return reg;
  }

  /**
   * @private
   * @function setupTxnListener
   * @description Set up the transaction listener (listen for messages coming from the worker)
   *
   * @param {WorkerRegistration} registration
   */
  private setupTxnListener(registration: WorkerRegistration): void {
    registration.worker.addEventListener('message', (e: MessageEvent<TransactionData>) => {
      if (e?.data?.Type === 'DoD.Messaging.TransactionFailed') {
        // Transaction Failed
        const promise: TransactionPromise = registration.txnAwaits[e.data.TxnId];

        this.aiCtx.warn('transactionFailed', {
          regId: registration.id,
          txnId: e.data.TxnId,
          type: promise.type,
          duration: Math.round(performance.now() - promise.startTime),
          message: e.data.Data.message,
          stack: e.data.Data.stack
        });

        promise.reject(new Error('Transaction failed'));
        delete registration.txnAwaits[e.data.TxnId];
      } else if (e?.data?.TxnId && registration.txnAwaits[e.data.TxnId] !== undefined) {
        // Transaction Succeeded
        const promise: TransactionPromise = registration.txnAwaits[e.data.TxnId];

        this.aiCtx.info('transactionComplete', {
          regId: registration.id,
          txnId: e.data.TxnId,
          type: promise.type,
          duration: Math.round(performance.now() - promise.startTime)
        });

        promise.resolve(e.data.Data);
        delete registration.txnAwaits[e.data.TxnId];
      } else {
        // Non-transactional message
        this.aiCtx.debugLog('nonTransactionEvent', {
          regId: registration.id,
          type: (e.data || {}).Type
        });
      }
    });
  }

  /**
   * @private
   * @function getNewId
   *
   * @param {string} namespace id prefix
   *
   * @returns {string} unique id string
   */
  private getNewId(namespace: string): string {
    return `${namespace}_${new Date().getTime()}_${Math.floor(Math.random() * 100000)}`;
  }
}
