import { HostListener, Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Functions } from '@app/shared/functions';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  dialogData: any;
  isMobile: boolean;
  isLoading: boolean = false;
  isNative: boolean = Boolean(Capacitor.isNativePlatform());

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoModalComponent>,
    private functions: Functions
  ) {
    this.dialogData = this.data || {};

    this.onResize();

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.playVideo().finally(() => {
      this.isLoading = false;
    })
  }

  async playVideo(): Promise<boolean> {
    return false;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
