import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Constants, ApprovalStatus } from '../constants';
import { GeneralMedical } from '../models/general-medical';
import { GeneralMedicalService } from './general-medical.service';
import { Prescription } from '../models/prescription';
import { BMICalculation } from '../models/weightloss/BMICalculation';
import { Functions } from '../functions';
import { WeightLossLogEntry } from '../models/weightloss/weightLossLogEntry';
import { GoalProgressDetails } from '../models/weightloss/goalProgressDetails';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WeightLossService implements OnDestroy {
  private subscription = new Subscription();
  private _weightLossChange: Subscription;
  private _weightLossData: GeneralMedical;
  private _checkInOverdue: boolean;
  private _bmiCalculation: BMICalculation;
  private _serviceInitialisedForPatient: any = {};

  private _weightLossServiceDataChangeObs: Subject<GeneralMedical> = new Subject<GeneralMedical>();
  public weightLossServiceDataChangeObs = this._weightLossServiceDataChangeObs.asObservable();

  patientId: string = null;

  constructor(
    private functions: Functions,
    private medicalHistoryService: GeneralMedicalService
  ) {}

  async init(patientId: string): Promise<boolean> {
    if (!this._weightLossChange) {
      this.subscription.add(
        this._weightLossChange = this.medicalHistoryService.weightLossDataChangeObs.subscribe((weightLossData: GeneralMedical) => {
          this._weightLossData = weightLossData;

          this.checkOverdue();
          this.updateBMICalculation();

          this._weightLossServiceDataChangeObs.next(this._weightLossData);
        })
      );
    }

    if (
      patientId &&
      !this._serviceInitialisedForPatient[patientId] &&
      (this.patientId !== patientId || !this._weightLossData)
    ) {
      this.patientId = patientId;
      this._serviceInitialisedForPatient[patientId] = true;

      this._weightLossData = await this.medicalHistoryService.getWeightLossHistory(patientId, true);

      this.checkOverdue();
      this.updateBMICalculation();
    }

    return this.isProfileLoadedForPatient(this.patientId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this._weightLossChange = null;
  }

  isProfileLoadedForPatient(patientId: string): boolean {
    if (!patientId) {
      return false;
    }
    return Boolean(this._serviceInitialisedForPatient[patientId]);
  }

  updateBMICalculation(weightLossData?: GeneralMedical): BMICalculation {
    const data: GeneralMedical = weightLossData || this._weightLossData;

    if (
      data &&
      (data.currentHeight || data.height) &&
      (data.currentWeight || data.weight)
    ) {
      this._bmiCalculation = this.functions.calculateBMI(
        data.currentHeight || data.height,
        data.currentWeight || data.weight,
        true
      );
    }

    return this._bmiCalculation;
  }

  checkOverdue(weightLossData?: GeneralMedical): boolean {
    const data: GeneralMedical = weightLossData || this._weightLossData;

    if (data?.modified) {
      this._checkInOverdue = moment(data.modified)
        .diff(moment(), 'days') > Constants.Weight_Loss_Configuration.checkInOverdueDays;
    }

    return this._checkInOverdue;
  }

  checkReorder(prescriptions: Prescription[]): Prescription {
    return prescriptions?.find((prescription: Prescription) =>
      // prescription.appointmentId &&
      prescription.isResendable &&
      moment(prescription.prescriptionProvidedDateUTC || prescription.prescriptionDate)
        .diff(moment(), 'days') >= Constants.Weight_Loss_Configuration.reorderPeriodDays
    );
  }

  updateApprovalStatus(approvalStatus: string): void {
    if (
      this._weightLossData &&
      this._weightLossData.approvalStatus !== approvalStatus
    ) {
      this._weightLossData.approvalStatus = approvalStatus;
      this._weightLossServiceDataChangeObs.next(this._weightLossData);
    }
  }

  resetService(): void {
    this._weightLossData = null;
    this._bmiCalculation = null;
    this._checkInOverdue = false;
    this._serviceInitialisedForPatient = {};
  }

  sortAndFilterWeightLossLogs(logs: WeightLossLogEntry[], timezoneOffset?: string): WeightLossLogEntry[] {
    let numericTimeZoneOffset: number = timezoneOffset ? parseFloat(timezoneOffset) : Constants.Default_TimeZone_Offset;
    let filteredLogs: WeightLossLogEntry[] = [];

    if (logs?.length) {
      let sortedLogs: WeightLossLogEntry[] = this.functions.sortISO8601DateArray(logs, 'asOfDate');
      for (let i = 0; i < sortedLogs.length; i++) {
        if (
          i == sortedLogs.length - 1 ||
          !moment(sortedLogs[i].asOfDate).utcOffset(numericTimeZoneOffset)
            .isSame(moment(sortedLogs[i + 1].asOfDate).utcOffset(numericTimeZoneOffset), 'days')
        ) {
          filteredLogs.push(sortedLogs[i]);
        }
      }
    }

    return filteredLogs;
  }

  getGoalProgressDetails(
    weightLossData?: GeneralMedical,
    timezoneOffset?: string
  ): GoalProgressDetails {
    const data: GeneralMedical = weightLossData || this._weightLossData;
    const logs: WeightLossLogEntry[] = data?.patientWeightLossLogs?.length
      ? this.sortAndFilterWeightLossLogs(data.patientWeightLossLogs, timezoneOffset)
      : [];
    const checkInIsOverdue: boolean = this.checkOverdue(data);
    const bmiCalculation: BMICalculation = this.updateBMICalculation(data);

    let weightGoalReached: boolean = false;
    let weightGoalLabel: string = null;
    let waistGoalReached: boolean = false;
    let waistGoalLabel: string = null;
    let waistCssClass: string = null;

    if (data?.currentWeight && data?.goalWeight) {
      weightGoalReached = data.goalWeight + Constants.Weight_Loss_Configuration.weightGoalDeviation >= data.currentWeight;
      weightGoalLabel = weightGoalReached
        ? 'Goal reached!'
        : String(Math.floor(data.currentWeight - data.goalWeight)).concat('kg until you reach your goal!');
    }

    if (data?.currentWaist && data?.goalWaist) {
      waistGoalReached = data.goalWaist + Constants.Weight_Loss_Configuration.waistGoalDeviation >= data.currentWaist;
      waistGoalLabel = waistGoalReached
        ? 'Goal reached!'
        : String(Math.floor(data.currentWaist - data.goalWaist)).concat('cm until you reach your goal!');
      waistCssClass = waistGoalReached
        ? 'success'
        : data.currentWaist - data.goalWaist < 5
          ? 'warning'
          : 'danger';
    }

    return {
      weightLossData: data,
      weightLossLogs: logs,
      bmiCalculation,
      checkInIsOverdue,
      weightGoalReached,
      weightGoalLabel,
      waistGoalReached,
      waistGoalLabel,
      waistCssClass
    } as GoalProgressDetails;
  }

  get isQualifiedForWeightLossProgram(): boolean {
    return this._weightLossData?.approvalStatus == ApprovalStatus.yes;
  }
  get bmiCalculation(): BMICalculation {
    return this._bmiCalculation || null;
  }
  get weightLossData(): GeneralMedical {
    return this._weightLossData || null;
  }
  get isCheckInOverdue(): boolean {
    return this._checkInOverdue ?? false;
  }
  // get currentPrescription(): Prescription {
  //   return this._currentPrescription || null;
  // }
  // set currentPrescription(prescription: Prescription) {
  //   this._currentPrescription = prescription || null;
  // }
}
