<ng-container *ngIf="autoCompleteForm; else manualAddressInputTemplate">
  <address-autocomplete
    [tempAddress]="joinedAddress"
    [isPharmacyAddress]="isPharmacyAddress"
    [isChangePharmacy]="isChangePharmacy"
    addressType="address"
    (switchForm)="switchForm()"
    (passData)="setData($event)">
  </address-autocomplete>
  <!-- addressType="geocode" -->
</ng-container>
<ng-template #manualAddressInputTemplate>
  <form
    id="address-form"
    [formGroup]="addressForm"
    [class.manual-input]="!autoCompleteForm">
    <div>
      <div class="row">
        <!--div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>Unit Number</mat-label>
            <input
              formControlName="unitNumber"
              autocomplete="section-manualaddress shipping address-line1"
              maxlength="20"
              matInput
              placeholder="Unit Number" />
            <mat-error *ngIf="unitNumber.invalid && unitNumber.errors && (unitNumber.dirty || unitNumber.touched)">
              <div *ngIf="unitNumber.errors.pattern">Unit number is invalid</div>
            </mat-error>
          </mat-form-field>
        </div-->
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>Unit/Street Number</mat-label>
            <input
              formControlName="streetNumber"
              autocomplete="section-manualaddress shipping address-line1"
              maxlength="50"
              matInput
              placeholder="Unit/Street Number"
              required />
            <mat-error *ngIf="streetNumber.invalid && streetNumber.errors && (streetNumber.dirty || streetNumber.touched)">
              <div *ngIf="streetNumber.hasError('required') || streetNumber.hasError('whitespace')">
                Unit/Street Number is required
              </div>
              <div *ngIf="streetNumber.hasError('pattern')">Unit/Street Number is invalid</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>Street Name</mat-label>
            <input
              formControlName="route"
              autocomplete="section-manualaddress shipping address-line2"
              maxlength="250"
              matInput
              placeholder="Street Name"
              required />
            <mat-error *ngIf="route.invalid && route.errors && (route.dirty || route.touched)">
              <div *ngIf="route.hasError('required') || route.hasError('whitespace')">Street Name is required</div>
              <div *ngIf="route.hasError('pattern')">Street Name is invalid</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>Suburb</mat-label>
            <input
              formControlName="locality"
              autocomplete="shipping address-level3"
              maxlength="60"
              matInput
              placeholder="Suburb"
              required />
            <mat-error *ngIf="locality.invalid && locality.errors && (locality.dirty || locality.touched)">
              <div *ngIf="locality.hasError('required') || locality.hasError('whitespace')">Suburb is required</div>
              <div *ngIf="locality.hasError('pattern')">Suburb is invalid</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>State</mat-label>
            <input
              formControlName="administrativeAreaLevel1"
              autocomplete="shipping address-level1"
              maxlength="20"
              matInput
              placeholder="State"
              required />
            <mat-error
              *ngIf="administrativeAreaLevel1.invalid &&
                administrativeAreaLevel1.errors &&
                (administrativeAreaLevel1.dirty || administrativeAreaLevel1.touched)">
              <div *ngIf="administrativeAreaLevel1.hasError('required') || administrativeAreaLevel1.hasError('whitespace')">
                State is required
              </div>
              <div *ngIf="administrativeAreaLevel1.hasError('pattern')">State is invalid</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>City</mat-label>
            <input
              formControlName="administrativeAreaLevel2"
              autocomplete="shipping address-level2"
              maxlength="60"
              matInput
              placeholder="City"
              required />
            <mat-error
              *ngIf="administrativeAreaLevel2.invalid &&
                administrativeAreaLevel2.errors &&
                (administrativeAreaLevel2.dirty || administrativeAreaLevel2.touched)">
              <div *ngIf="administrativeAreaLevel2.hasError('required') || administrativeAreaLevel2.hasError('whitespace')">
                City is required
              </div>
              <div *ngIf="administrativeAreaLevel2.hasError('pattern')">City is invalid</div>
            </mat-error>
          </mat-form-field>
        </div>
        <!--div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>Country</mat-label>
            <input
              formControlName="country"
              autocomplete="shipping country-name"
              maxlength="25"
              matInput
              placeholder="Country"
              value="Australia"
              readonly />
            <mat-error *ngIf="country.invalid && country.errors && (country.dirty || country.touched)">
              <div *ngIf="country.errors.required">Country is required</div>
            </mat-error>
          </mat-form-field>
        </div-->
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="postcode-address-field default-input address-input">
            <mat-label>Postcode</mat-label>
            <input
              formControlName="postalCode"
              autocomplete="postal-code"
              inputmode="numeric"
              maxlength="4"
              matInput
              placeholder="Postcode"
              required
              (keydown)="validateNumber($event)" />
            <mat-error *ngIf="postalCode.invalid && postalCode.errors && (postalCode.dirty || postalCode.touched)">
              <div *ngIf="postalCode.hasError('required')">Postcode is required</div>
              <div *ngIf="postalCode.hasError('pattern')">Postcode is invalid</div>
            </mat-error>
          </mat-form-field>
          <div class="mat-small caption text-right">
            Switch back to address
            <span
              [ngClass]="postalCode.invalid && (postalCode.dirty || postalCode.touched)
                ? 'd-inline-block d-xl-inline'
                : 'd-sm-inline'">
              <a role="button" id="manualFormSwitchButton" (click)="switchForm()">search automatically</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
