<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-2 px-0">
      <img
        *ngIf="practitioner?.profileImageUrl"
        src="{{ practitioner.profileImageUrl }}"
        loading="lazy"
        decoding="async"
        class="img-fluid practitioner-photo"
        alt="practitioner photo" />
    </div>
    <div class="col-9 profile-section">
      <span class="name">{{ practitioner?.practitionerName || '' }}</span>
      <span class="education">{{ practitioner?.qualifications || '' }}</span>
      <span class="unique-code" (click)="redirectTo(practitioner?.practitionerId)">
        AHPRA: {{ practitioner?.ahpraRegistrationNo || '' }}
      </span>
    </div>
    <div class="col-1 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else loaded">
    <div class="mt-5" style="min-height: 112px;">
      <loading-spinner></loading-spinner>
    </div>
  </ng-container>
  <ng-template #loaded>
    <div
      [className]="closeButtonOnly
        ? 'modal-body container-fluid text-secondary'
        : 'modal-body-multiple container-fluid text-secondary'">
      <div class="row m-0 language-row">
        <div class="col-12">
          <div class="spoken-languages-text">Spoken Languages</div>
        </div>
        <div *ngFor="let language of languageCodes" class="d-flex col-auto language-item-wrapper">
          <span class="language-flag">
            <span [ngClass]="'flag-icon ' + language.languageCode + ' flag-icon-squared'"></span>
          </span>
          <span class="language-name">{{ language ? language.languageName : '' }}</span>
        </div>
      </div>
      <div *ngIf="practitioner && practitioner.bio" class="row custom-margin">
        <div class="col-12">
          <div class="bio-text">Biography</div>
        </div>
        <div class="col-12">
          <p class="bio-desc">{{ practitioner.bio || '' }}</p>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="modal-footer container-fluid bg-muted" [class.single-button-height]="closeButtonOnly">
    <div *ngIf="!closeButtonOnly && isMobile" class="row m-0">
      <div class="first-button col-sm-12 col-xl-6">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onSelect()">
          Select Doctor
        </button>
      </div>
      <div class="second-button col-sm-12 col-xl-6">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
    <div *ngIf="!closeButtonOnly && !isMobile" class="row m-0">
      <div class="first-button col-sm-12 col-xl-6">
        <button
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose()">
          Close
        </button>
      </div>
      <div class="second-button col-sm-12 col-xl-6">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onSelect()">
          Select Doctor
        </button>
      </div>
    </div>
    <div *ngIf="closeButtonOnly" class="row m-0">
      <div class="col-sm-12 p-0">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
