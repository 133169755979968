import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProcessRequestMessage } from '../models/process-request-message';
import { Logger } from '@app/core/services/logger.service';
import { Constants } from '../constants';

const log = new Logger('ApiService');

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  messages$: BehaviorSubject<ProcessRequestMessage[]> = new BehaviorSubject<ProcessRequestMessage[]>([]);
  get messages(): ProcessRequestMessage[] {
    return this.messages$.getValue();
  }

  constructor(private http: HttpClient) {}

  public get<T>(url: string): Observable<T> {
    if (!url) {
      return;
    }
    return this.http.get<T>(url, this.getRequestHeaders()).pipe(
      map((resp: T) => {
        this.hanldeFailedRequest(resp);
        if (!resp[Constants.API_Respose.Success]) {
          throwError(() => new Error('request failed'));
        }
        return resp[Constants.API_Respose.Response] as T;
      }),
      catchError((error: any) => {
        log.info('error', error);
        return this.handleError(error);
      }),
    );
  }

  public post<T>(url: string, postBody: any, retainMessages?: boolean): Observable<T> {
    if (!url) {
      return;
    }
    if (!retainMessages) {
      this.resetMessages();
    }
    return this.http.post<T>(url, postBody, this.getRequestHeaders()).pipe(
      map((resp: T) => {
        return resp;
      }),
      catchError((error: any) => {
        log.info('error', error);
        return this.handleError(error);
      }),
    );
  }

  public put<T>(url: string, postBody: any, retainMessages?: boolean): Observable<T> {
    if (!url) {
      return;
    }
    if (!retainMessages) {
      this.resetMessages();
    }
    return this.http.put<T>(url, postBody, this.getRequestHeaders()).pipe(
      map((resp: T) => {
        this.hanldeFailedRequest(resp);
        return resp[Constants.API_Respose.Response] as T;
      }),
      catchError((error: any) => {
        log.info('error', error);
        return this.handleError(error);
      }),
    );
  }

  resetMessages(): void {
    this.messages$.next([]);
  }

  protected getRequestHeaders(): Object {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept':       'application/json, text/plain, */*',
    });

    return { headers };
  }

  protected handleError(error: any): Observable<any> {
    // Check for authentication error
    if (error?.status === 401) {
      console.warn('[apiService] Authentication error!');
      return of(error);
      // Do not trigger route navigation from API Service
      // this.functions.navigateToLogin();
    } else {
      console.warn('[apiService] handleError() error = ', error);
      return throwError(() => error);
    }
  }

  private hanldeFailedRequest(response: any): void {
    if (response && !response.success && response.error) {
      const errorMessage = {
        code: response.error.code,
        severity: 'error',
        message: response.error.message,
      } as ProcessRequestMessage;
      this.messages$.next([...this.messages, ...[errorMessage]]);
    }
  }
}
