import { Injectable } from '@angular/core';
import { AIContext, AppInsightsService } from './appinsights.service';
import { environment } from '@src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { viewport } from '@popperjs/core';
import { PatientService } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private aiContext: AIContext;

  constructor(
    private aiService: AppInsightsService,
    private patientService: PatientService
  ) {
    this.aiContext = aiService.createContext('DebugService');

    if (!environment.production) {
      window['dod_getDebugInfo'] = () => this.saveDebugInfo();
    } else {
      this.aiContext.debugLog('Not in development mode, skipping debug info');
    }
  }

  private getDebugInfo(): any {
    return {
      appInsightsSessionId: this.aiService.appInsights.context.getSessionId(),
      isProduction: environment.production,
      apiBaseUrl: environment.apiBaseUrl,
      isNative: Capacitor.isNativePlatform(),
      userAgent: navigator.userAgent,
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      screenWidth: screen.width,
      screenHeight: screen.height,
      currentPatientId: this.patientService.patient?.patientId,
      currentPolicyId: this.patientService.benefit?.policyId,
      policyMetadataToken: this.patientService.benefit?.policyMetadataToken,
      url: window.location.href,
      referrer: document.referrer
    };
  }

  private saveDebugInfo() {
    const debugInfo = this.getDebugInfo();
    const debugInfoString = JSON.stringify(debugInfo, null, 2);
    const blob = new Blob([debugInfoString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'debug-info.json';
    a.click();
    URL.revokeObjectURL(url);
  }

  printDebugInfo() {
    console.log('[DebugService] Debug Info:');
    console.table(this.getDebugInfo());
  }
}
