<div class="container-fluid">
  <form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="row content-section mt-4">
      <div class="col-12">
        <mat-card appearance="outlined" class="card-wrapper">
          <mat-card-content class="content">
            <div class="form-content">
              <div class="row">
                <div class="col-md-6 content-box">
                  <div class="disabled-input-wrapper">
                    <mat-form-field
                      appearance="outline"
                      class="default-input w-100"
                      [class.disabled]="b2bCustomerService.getPatientBookingSource === B2BBookingPatientInputType.email"
                    >
                      <mat-label>Email</mat-label>
                      <input
                        formControlName="email"
                        autocomplete="email"
                        matInput
                        maxlength="255"
                        placeholder="Enter the patient's email address"
                        required
                      />
                      <mat-error *ngIf="email.invalid">
                        <span *ngIf="email.hasError('required')">Email is required.&nbsp;</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-dropdown">
                    <mat-label>Title</mat-label>
                    <mat-select formControlName="title" *ngIf="titles">
                      <mat-option class="option-wrapper border-bottom" [value]="null"> Select </mat-option>
                      <mat-option *ngFor="let title of titles" class="option-wrapper border-bottom" [value]="title">
                        {{ title || '' }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-input w-100">
                    <mat-label>First Name</mat-label>
                    <input
                      formControlName="firstName"
                      autocomplete="firstName"
                      matInput
                      maxlength="255"
                      placeholder="Enter the patient's first name"
                      required
                    />
                    <mat-error *ngIf="firstName.invalid">
                      <span *ngIf="firstName.hasError('required')">First Name is required.&nbsp;</span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-input w-100">
                    <mat-label>Last Name</mat-label>
                    <input
                      formControlName="lastName"
                      autocomplete="lastName"
                      matInput
                      maxlength="255"
                      placeholder="Enter the patient's last name"
                      required
                    />
                    <mat-error *ngIf="lastName.invalid">
                      <span *ngIf="lastName.hasError('required')">Last Name is required.&nbsp;</span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-dropdown">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender" required>
                      <mat-option value="">Select</mat-option>
                      <mat-option value="M">Male</mat-option>
                      <mat-option value="F">Female</mat-option>
                    </mat-select>
                    <mat-error *ngIf="gender.invalid"> Gender is required. </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-input prefixed-input">
                    <mat-label>Mobile Phone</mat-label>
                    <input
                      formControlName="phone"
                      autocomplete="mobile"
                      placeholder="Mobile phone"
                      maxlength="20"
                      type="tel"
                      matInput
                      required
                    />
                    <mat-error *ngIf="(phone.invalid && phone.errors) || !validPhone">
                      <span *ngIf="phone.hasError('required')"> Phone number is required. </span>
                      <span *ngIf="phone.hasError('pattern')"> Phone number is invalid. </span>
                      <span *ngIf="!phone.hasError('pattern') && !validPhone"> Phone number is invalid. </span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-dropdown">
                    <mat-label>Time Zone</mat-label>
                    <mat-select formControlName="timezone" panelClass="standard-dropdown-items-panel ml-1" required>
                      <mat-option *ngFor="let item of timezoneOptions" [value]="item.timeZone">
                        {{ item.timezoneLabel }}
                      </mat-option>
                      <mat-error *ngIf="timezone.invalid && timezone.errors">
                        <span *ngIf="timezone.hasError('required')"> Time Zone is required. </span>
                      </mat-error>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <p class="error-message mb-3" *ngIf="invalidData">
                  The patient's data is not yet correct. Please update and try again.
                </p>
              </div>
              <div class="row">
                <div class="col-12 col-sm-3 col-md-2 button-wrapper">
                  <button
                    (click)="createPatient()"
                    [disabled]="isSubmitting"
                    class="save-btn"
                    mat-flat-button
                    color="accent"
                  >
                    <span *ngIf="!isSubmitting">Create</span>
                    <span *ngIf="isSubmitting">Validating...</span>
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>
