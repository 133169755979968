<div class="healthcare-cards-form-wrapper" [class.inline-minimal]="medicareAndIHIOnly">
  <div
    [class.d-none]="inlineFull || medicareAndIHIOnly || (!inlineFull && !medicareAndIHIOnly && !displayDOBField)"
    class="date-of-birth-wrapper health-card-wrapper">
    <div class="card">
      <form [formGroup]="dateOfBirthForm" class="row">
        <div class="col-sm-12 col-xl-6">
          <mat-form-field appearance="outline" class="default-input date-input">
            <mat-label>Date of Birth</mat-label>
            <input
              formControlName="dateOfBirth"
              autocomplete="off"
              placeholder="DD/MM/YYYY"
              dateOfBirthMask
              [matDatepicker]="picker"
              [min]="dobStartYear"
              [max]="today"
              matInput
              required
              (click)="picker.open()"
              (dateInput)="picker.close()"
              (keydown)="
                inputValidationFunctions.validateDeleteSlashDDMMYYYY($event);
                inputValidationFunctions.validateNumberPreventDefault($event)
              " />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker touchUi startView="multi-year" [opened]="false" [dateClass]="dateStyleClass">
            </mat-datepicker>
            <mat-error *ngIf="dateOfBirth.invalid && dateOfBirth.errors && (dateOfBirth.dirty || dateOfBirth.touched)">
              <span *ngIf="dateOfBirth.errors.required">Date of Birth is required.</span>
              <span *ngIf="dateOfBirth.errors.dateFormat">Date of Birth is invalid.</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="d-none d-xl-flex col-xl-6 button-wrapper save-button-wrapper">
          <button
            mat-flat-button
            color="accent"
            class="save-btn"
            [disabled]="!dateOfBirthForm.valid"
            (click)="onSaveDOB()">
            {{ dateOfBirthFormLoading ? 'Saving...' : 'Save Date of Birth' }}
          </button>
        </div>
      </form>
      <div *ngIf="!inlineFull && !medicareAndIHIOnly" class="d-xl-none row footer-section">
        <div class="col-12 button-wrapper">
          <button
            mat-flat-button
            color="accent"
            class="save-btn"
            [disabled]="!dateOfBirthForm.valid"
            (click)="onSaveDOB()">
            {{ dateOfBirthFormLoading ? 'Saving...' : 'Save Date of Birth' }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!hideMedicareFields" class="medicare-card-wrapper health-card-wrapper">
    <div class="card">
      <div class="row header-section header" [class.with-toggle]="medicareAndIHIOnly || inlineFull">
        <div [ngClass]="medicareAndIHIOnly || inlineFull ? 'col-8 col-xl-6' : 'col-12'">
          <span class="mat-headline-3">
            <span *ngIf="medicareAndIHIOnly">Do you have a Medicare Card?</span>
            <span *ngIf="!medicareAndIHIOnly">Medicare Card</span>
          </span>
          <span class="desc">
            If you have a Medicare card you can opt to receive an eScript and may be eligible for
            <span title="Pharmaceutical Benefits Scheme">PBS</span> medications.
          </span>
        </div>
        <div *ngIf="isMobile && (medicareAndIHIOnly || inlineFull)" class="col-4 d-xl-none text-right my-auto">
          <label class="handmade-switch">
            <input
              name="medicareCardToggle"
              type="checkbox"
              [ngModel]="medicareCardToggle"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateMedicareCardToggle()" />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          *ngIf="!isMobile && (medicareAndIHIOnly || inlineFull)"
          class="col-4 col-xl-6 d-none d-xl-block my-auto button-wrapper">
          <mat-button-toggle-group
            [ngModel]="medicareCardToggle"
            [ngModelOptions]="{ standalone: true }"
            (change)="updateMedicareCardToggle()"
            class="toggle-group">
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <hr class="m-0" />
      <form [formGroup]="medicareCardForm">
        <div class="row content-section" [class.d-none]="(medicareAndIHIOnly || inlineFull) && !medicareCardToggle">
          <div class="col-sm-12 col-xl-6">
            <mat-form-field appearance="outline" class="default-input">
              <mat-label>First Name</mat-label>
              <input
                #firstNameField
                formControlName="firstName"
                placeholder="First name"
                autocomplete="given-name"
                autofocus
                type="text"
                maxlength="50"
                matInput
                required />
              <mat-error *ngIf="firstName.invalid && (firstName.touched || firstName.dirty)">
                First Name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-xl-6">
            <mat-form-field appearance="outline" class="default-input">
              <mat-label>Last Name</mat-label>
              <input
                formControlName="lastName"
                placeholder="Last name"
                autocomplete="family-name"
                type="text"
                maxlength="50"
                matInput
                required />
              <mat-error *ngIf="lastName.invalid && (lastName.touched || lastName.dirty)">
                Last Name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-xl-6">
            <mat-form-field appearance="outline" class="default-input">
              <mat-label> Medicare Card Number </mat-label>
              <input
                #medicareCardNumberField
                formControlName="medicareCardNumber"
                placeholder="0000-00000-0"
                type="text"
                inputmode="numeric"
                maxlength="12"
                medicareFormat
                matInput
                required
                (keydown)="validateNumber($event) ? handleKeyPress($event, medicareIRNField) : null" />
              <mat-error
                *ngIf="
                  medicareCardNumber.invalid &&
                  medicareCardNumber.errors &&
                  (medicareCardNumber.touched || medicareCardNumber.dirty)
                ">
                <span *ngIf="medicareCardNumber.hasError('required')"> Medicare Card Number is required </span>
                <span *ngIf="medicareCardNumber.hasError('invalid')"> Medicare Card Number is invalid </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-xl-6">
            <mat-form-field appearance="outline" class="default-input">
              <mat-label>
                {{ isNarrowMobile || isModal ? 'Individual Reference #' : 'Individual Reference Number' }}
              </mat-label>
              <input
                #medicareIRNField
                formControlName="medicareIRN"
                type="text"
                inputmode="numeric"
                maxlength="1"
                matInput
                required
                [placeholder]="isNarrowMobile || isModal ? 'Individual Reference #' : 'Individual Reference Number'"
                (keydown)="validateNumber($event) ? handleKeyPress($event, medicareExpiryMonthField) : null" />
              <mat-error
                *ngIf="medicareIRN.invalid && medicareIRN.errors && (medicareIRN.touched || medicareIRN.dirty)">
                <span *ngIf="medicareIRN.invalid && medicareIRN.hasError('pattern')">
                  Individual Reference Number is invalid
                </span>
              </mat-error>
              <span *ngIf="medicareIRN.valid || medicareIRN.hasError('required')" class="hint-bottom">
                What is my
                <a class="text-accent" (click)="openIRNModal()">
                  <span *ngIf="!(isNarrowMobile || isModal)">Individual Reference Number</span>
                  <span *ngIf="isNarrowMobile || isModal">IRN</span></a
                >?
              </span>
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-xl-6 mb-4">
            <mat-form-field appearance="outline" class="default-dropdown">
              <mat-label>Expiry Month</mat-label>
              <mat-select
                #medicareExpiryMonthField
                formControlName="medicareExpiryMonth"
                name="medicareExpiryMonth"
                required>
                <mat-option *ngFor="let expiryMonth of months" [value]="expiryMonth.code">
                  {{ expiryMonth.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  medicareExpiryMonth.invalid &&
                  medicareExpiryMonth.errors &&
                  (medicareExpiryMonth.touched || medicareExpiryMonth.dirty)
                ">
                <span *ngIf="medicareExpiryMonth.hasError('required')"> Expiry Month is required </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-xl-6 mb-4">
            <mat-form-field appearance="outline" class="default-dropdown">
              <mat-label>Expiry Year</mat-label>
              <mat-select formControlName="medicareExpiryYear" name="medicareExpiryYear" required>
                <mat-option *ngFor="let expiryYear of years" [value]="expiryYear">
                  {{ expiryYear }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  medicareExpiryYear.invalid &&
                  medicareExpiryYear.errors &&
                  (medicareExpiryYear.touched || medicareExpiryYear.dirty)
                ">
                <span *ngIf="medicareExpiryYear.hasError('required')"> Expiry Year is required </span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div
        *ngIf="(!medicareAndIHIOnly && !inlineFull) || (inlineFull && medicareCardToggle)"
        class="row footer-section">
        <div class="col-12 col-xl-6 offset-xl-6 button-wrapper">
          <button
            mat-flat-button
            color="accent"
            class="save-btn"
            [disabled]="
              !dob ||
              medicareCardForm.pristine ||
              !medicareCardForm.valid ||
              medicareFormLoading ||
              !medicareIRN.value ||
              !medicareExpiryMonth.value ||
              !medicareExpiryYear.value ||
              saveAllLoading
            "
            (click)="onSaveMedicare()">
            {{ medicareFormLoading ? 'Saving...' : 'Save Medicare Card Details' }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!medicareAndIHIOnly" class="healthcare-card-wrapper health-card-wrapper">
    <div class="card">
      <div class="row header-section header with-toggle">
        <div class="col-8 col-xl-6">
          <span class="mat-headline-3">Healthcare Card</span>
          <span class="desc">
            You may be eligible for cheaper pricing on your medication if you have an eligible Healthcare Card.
          </span>
        </div>
        <div *ngIf="isMobile" class="col-4 d-xl-none text-right my-auto">
          <label class="handmade-switch">
            <input
              name="healthcareCardToggle"
              type="checkbox"
              [ngModel]="healthcareCardToggle"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateHealthcareCardToggle()" />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="!isMobile" class="col-4 col-xl-6 d-none d-xl-block my-auto button-wrapper">
          <mat-button-toggle-group
            [ngModel]="healthcareCardToggle"
            [ngModelOptions]="{ standalone: true }"
            (change)="updateHealthcareCardToggle()"
            class="toggle-group">
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <ng-container *ngIf="healthcareCardToggle">
        <hr class="m-0" />

        <form [formGroup]="healthcareCardForm">
          <div>
            <div class="row content-section">
              <div class="col-12 col-xl-6">
                <mat-form-field appearance="outline" class="default-input">
                  <mat-label>Healthcare Card Number</mat-label>
                  <input
                    #healthcareCardNumberField
                    formControlName="healthcareCardNumber"
                    placeholder="000-000-000-A"
                    maxlength="13"
                    healthcareFormat
                    matInput
                    (keydown)="handleKeyPress($event, healthcareExpiryMonthField)" />
                  <mat-error
                    *ngIf="healthcareCardNumber.invalid && healthcareCardNumber.errors && healthcareCardNumber.dirty">
                    <span *ngIf="healthcareCardNumber.hasError('required')"> Healthcare Card Number is required </span>
                    <span *ngIf="healthcareCardNumber.hasError('invalid')"> Healthcare Card Number is invalid </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-xl-3">
                <mat-form-field appearance="outline" class="default-dropdown">
                  <mat-label>Expiry Month</mat-label>
                  <mat-select
                    #healthcareExpiryMonthField
                    formControlName="healthcareExpiryMonth"
                    name="healthcareExpiryMonth">
                    <mat-option *ngFor="let expiryMonth of months" [value]="expiryMonth.code">
                      {{ expiryMonth.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-xl-3">
                <mat-form-field appearance="outline" class="default-dropdown">
                  <mat-label>Expiry Year</mat-label>
                  <mat-select formControlName="healthcareExpiryYear" name="healthcareExpiryYear">
                    <mat-option *ngFor="let expiryYear of years" [value]="expiryYear">
                      {{ expiryYear }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="healthcareCardToggle" class="row footer-section">
              <div class="col-12 col-xl-6 offset-xl-6 button-wrapper">
                <button
                  mat-flat-button
                  color="accent"
                  class="save-btn"
                  [disabled]="
                    !dob ||
                    healthcareCardForm.pristine ||
                    !healthcareCardForm.valid ||
                    healthcareCardLoading ||
                    !healthcareCardNumber.value ||
                    !healthcareExpiryMonth.value ||
                    !healthcareExpiryYear.value ||
                    saveAllLoading
                  "
                  (click)="onSaveHealthcare()">
                  {{ healthcareCardLoading ? 'Saving...' : 'Save Healthcare Card Details' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!medicareAndIHIOnly" class="concession-card-wrapper health-card-wrapper">
    <div class="card">
      <div class="row header-section header with-toggle">
        <div class="col-8 col-xl-6">
          <span class="mat-headline-3">Concession Card</span>
          <span class="desc">
            You may be eligible for cheaper pricing on your medication if you have an eligible Concession Card.
          </span>
        </div>
        <div *ngIf="isMobile" class="col-4 d-xl-none text-right my-auto">
          <label class="handmade-switch">
            <input
              name="concessionCardToggle"
              type="checkbox"
              [ngModel]="concessionCardToggle"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateConcessionCardToggle()" />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="!isMobile" class="col-4 col-xl-6 d-none d-xl-block my-auto button-wrapper">
          <mat-button-toggle-group
            name="concessionCardToggle"
            class="toggle-group"
            [ngModel]="concessionCardToggle"
            [ngModelOptions]="{ standalone: true }"
            (change)="updateConcessionCardToggle()">
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <ng-container *ngIf="concessionCardToggle">
        <hr class="m-0" />

        <form [formGroup]="concessionCardForm">
          <div>
            <div class="row content-section">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <mat-form-field appearance="outline" class="default-input">
                  <mat-label>Concession Card Number</mat-label>
                  <input
                    #concessionCardNumberField
                    formControlName="concessionCardNumber"
                    placeholder="000-000-000-A"
                    maxlength="13"
                    healthcareFormat
                    matInput
                    (keydown)="handleKeyPress($event, concessionExpiryMonthField)" />
                  <mat-error
                    *ngIf="concessionCardNumber.invalid && concessionCardNumber.errors && concessionCardNumber.dirty">
                    <span *ngIf="concessionCardNumber.hasError('required')"> Concession Card Number is required </span>
                    <span *ngIf="concessionCardNumber.hasError('invalid')"> Concession Card Number is invalid </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3">
                <mat-form-field appearance="outline" class="default-dropdown">
                  <mat-label>Expiry Month</mat-label>
                  <mat-select
                    #concessionExpiryMonthField
                    formControlName="concessionExpiryMonth"
                    name="concessionExpiryMonth">
                    <mat-option *ngFor="let expiryMonth of months" [value]="expiryMonth.code">
                      {{ expiryMonth.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3">
                <mat-form-field appearance="outline" class="default-dropdown">
                  <mat-label>Expiry Year</mat-label>
                  <mat-select formControlName="concessionExpiryYear" name="concessionExpiryYear">
                    <mat-option *ngFor="let expiryYear of years" [value]="expiryYear">
                      {{ expiryYear }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row footer-section">
              <div class="col-12 col-md-12 col-lg-6 offset-lg-6 button-wrapper">
                <button
                  mat-flat-button
                  color="accent"
                  class="save-btn"
                  [disabled]="
                    !dob ||
                    concessionCardForm.pristine ||
                    !concessionCardForm.valid ||
                    concessionCardLoading ||
                    !concessionCardNumber.value ||
                    !concessionExpiryMonth.value ||
                    !concessionExpiryYear.value ||
                    saveAllLoading
                  "
                  (click)="onSaveConcession()">
                  {{ concessionCardLoading ? 'Saving...' : 'Save Concession Card Details' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>

  <!-- DOC-4418 - SafetyNet is disabled -->
  <div *ngIf="false && !medicareAndIHIOnly" class="safetynet-card-wrapper health-card-wrapper">
    <div class="card">
      <div class="row header-section header with-toggle">
        <div class="col-8 col-xl-6">
          <span class="mat-headline-3">Safety Net Card</span>
          <span class="desc">
            Safety Net is a scheme designed to protect you and your family from the high total cost of large numbers of
            <span title="Pharmaceutical Benefits Scheme">PBS</span> medicines.
          </span>
        </div>
        <div *ngIf="isMobile" class="col-4 d-xl-none text-right my-auto">
          <label class="handmade-switch">
            <input
              name="safetyNetToggle"
              type="checkbox"
              [ngModel]="safetyNetToggle"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateSafetyNetToggle()" />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="!isMobile" class="col-4 col-xl-6 d-none d-xl-block my-auto button-wrapper">
          <mat-button-toggle-group
            name="safetyNetToggle"
            class="toggle-group"
            [ngModel]="safetyNetToggle"
            [ngModelOptions]="{ standalone: true }">
            <mat-button-toggle [value]="true" (change)="updateSafetyNetToggle(true)">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false" (change)="updateSafetyNetToggle(false)">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <ng-container *ngIf="safetyNetToggle">
        <hr class="m-0" />

        <form [formGroup]="safetyNetForm">
          <div>
            <div class="row content-section">
              <div class="col-xs-12 col-sm-12 col-md-8 col-xl-6">
                <mat-form-field appearance="outline" class="default-input">
                  <mat-label>Safety Net Card Number</mat-label>
                  <input
                    #safetyNetCardNumberField
                    formControlName="safetyNetCardNumber"
                    placeholder="CN/SN-000000000"
                    type="text"
                    maxlength="12"
                    safetynetFormat
                    matInput
                    (keydown)="validateSafetyNetInput($event)" />
                  <mat-error
                    *ngIf="safetyNetCardNumber.invalid && safetyNetCardNumber.errors && safetyNetCardNumber.dirty">
                    <span *ngIf="safetyNetCardNumber.hasError('required')"> Safety Net Card number is required </span>
                    <span *ngIf="safetyNetCardNumber.hasError('invalid')"> Safety Net Card number is invalid </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <!--
              <div class="col-xs-12 col-sm-12 col-md-3">
                <mat-form-field appearance="outline" class="default-dropdown">
                  <mat-label>Expiry Month</mat-label>
                  <mat-select
                    #safetyNetExpiryMonthField
                    formControlName="safetyNetExpiryMonth"
                    name="safetyNetExpiryMonth">
                    <mat-option *ngFor="let expiryMonth of months" [value]="expiryMonth.code">
                      {{ expiryMonth.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3">
                <mat-form-field appearance="outline" class="default-dropdown">
                  <mat-label>Expiry Year</mat-label>
                  <mat-select
                    formControlName="safetyNetExpiryYear"
                    name="safetyNetExpiryYear">
                    <mat-option *ngFor="let expiryYear of years" [value]="expiryYear">
                      {{ expiryYear }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              -->
            </div>

            <div class="row footer-section">
              <div class="col-12 col-xl-6 offset-xl-6 button-wrapper">
                <button
                  mat-flat-button
                  color="accent"
                  class="save-btn"
                  [disabled]="
                    !dob ||
                    safetyNetForm.pristine ||
                    !safetyNetForm.valid ||
                    safetyNetLoading ||
                    !safetyNetCardNumber.value ||
                    saveAllLoading
                  "
                  (click)="onSaveSafetyNetCard()">
                  {{ safetyNetLoading ? 'Saving...' : 'Save SafetyNet Card Number' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="
      ihiNumberToggle ||
      !medicareAndIHIOnly ||
      (medicareAndIHIOnly && (!hasFullMedicareDetails() || !medicareCardToggle))
    "
    class="ihi-card-wrapper health-card-wrapper"
    [class.extra-padding]="medicareAndIHIOnly">
    <div class="card">
      <div class="row header-section header with-toggle">
        <div class="col-8 col-xl-6">
          <span class="mat-headline-3">
            <span *ngIf="medicareAndIHIOnly">Do you have an Individual Healthcare Identifier?</span>
            <span *ngIf="!medicareAndIHIOnly">Individual Healthcare Identifier</span>
          </span>
          <span class="desc">
            If you have an IHI number you can opt to receive an eScript.
            <span> <a class="text-accent" (click)="openIHIModal()">How do I get an IHI</a>? </span>
          </span>
        </div>
        <div *ngIf="isMobile" class="col-4 d-xl-none text-right my-auto">
          <label class="handmade-switch">
            <input
              name="ihiNumberToggle"
              type="checkbox"
              [ngModel]="ihiNumberToggle"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateIHIToggle()" />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="!isMobile" class="col-4 col-xl-6 d-none d-xl-block my-auto button-wrapper">
          <mat-button-toggle-group
            name="ihiNumberToggle"
            class="toggle-group"
            [ngModel]="ihiNumberToggle"
            [ngModelOptions]="{ standalone: true }">
            <mat-button-toggle [value]="true" (change)="updateIHIToggle(true)">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false" (change)="updateIHIToggle(false)">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <ng-container *ngIf="ihiNumberToggle">
        <hr class="m-0" />

        <form [formGroup]="ihiNumberForm">
          <div>
            <div class="row content-section">
              <div class="col-xs-12 col-sm-12 col-md-8 col-xl-6">
                <mat-form-field appearance="outline" class="default-input">
                  <mat-label>Individual Healthcare Identifier</mat-label>
                  <input
                    #ihiNumberField
                    formControlName="ihiNumber"
                    placeholder="0000-0000-0000-0000"
                    type="text"
                    inputmode="numeric"
                    maxlength="19"
                    ihiFormat
                    matInput
                    (keydown)="validateNumber($event)" />
                  <mat-error *ngIf="ihiNumber.invalid && ihiNumber.errors && ihiNumber.dirty">
                    <span *ngIf="ihiNumber.hasError('invalid')"> IHI Number is invalid </span>
                    <span *ngIf="medicareAndIHIOnly && ihiNumber.hasError('required')"> This field is required </span>
                  </mat-error>
                  <span
                    *ngIf="!medicareAndIHIOnly && (!ihiNumber.invalid || ihiNumber.hasError('required'))"
                    class="hint-bottom">
                    What is <span *ngIf="!isModal" class="d-none d-md-inline">my </span
                    ><a class="text-accent" (click)="openIHIModal()">Individual Healthcare Identifier</a>?
                  </span>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="!medicareAndIHIOnly" class="row footer-section">
              <div class="col-12 col-xl-6 offset-xl-6 button-wrapper">
                <button
                  mat-flat-button
                  color="accent"
                  class="save-btn"
                  [disabled]="
                    !dob ||
                    ihiNumberForm.pristine ||
                    !ihiNumberForm.valid ||
                    ihiNumberLoading ||
                    !ihiNumber.value ||
                    saveAllLoading
                  "
                  (click)="onSaveIHINumber()">
                  {{ ihiNumberLoading ? 'Saving...' : 'Save IHI Number' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!medicareAndIHIOnly && !isModal && !inlineFull" class="row footer-section">
    <div class="col-12 col-xl-6 offset-xl-6 button-wrapper">
      <button
        mat-flat-button
        color="accent"
        class="save-btn"
        [class.spinner]="saveAllLoading"
        [disabled]="saveAllLoading"
        (click)="medicareAndIHIOnly ? saveMinimalWithMessage() : saveAll()">
        {{ saveAllLoading ? 'Saving...' : 'Save All Healthcare Identifiers' }}
      </button>
    </div>
  </div>
</div>
