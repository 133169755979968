import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  @Input('alertLevel') alertLevel: string = 'default';

  constructor() {}
}
