/// <reference types="@types/google.maps" />
import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  Input,
  NgZone,
  SimpleChanges,
  OnChanges,
  ElementRef
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Address } from '@app/shared/models/address';

@Component({
  selector: 'address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressAutocompleteComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() addressType: string = 'address';
  @Input() tempAddress: string;
  @Input() isPharmacyAddress: boolean;
  @Input() isChangePharmacy: boolean;

  @Output() switchForm: EventEmitter<any> = new EventEmitter();
  @Output() passData: EventEmitter<any> = new EventEmitter();

  @ViewChild('addresstext') addresstext: ElementRef;

  isNarrowMobile: boolean = false;
  addressTextTracker: string;
  addressModified: boolean = false;
  autoCompleteField: any;
  formattedAddress: string;
  autoAddressForm: FormGroup;
  autoAddress: any = {
    autoAddressLine: '',
  };

  constructor(public zone: NgZone) {
    this.createForm();
  }

  ngOnInit(): void {
    this.isNarrowMobile = window.innerWidth < 390;

    this.autoAddressLine.valueChanges.subscribe((addressAutocomplete: string) => {
      this.addressModified = (this.addressTextTracker !== addressAutocomplete) &&
        (this.addressTextTracker !== (addressAutocomplete + ', Australia'));
      this.addressTextTracker = addressAutocomplete;
    });
  }

  ngAfterViewInit(): void {
    this.updateAddress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'tempAddress':
            const current: string = changes.tempAddress.currentValue;
            if (current && current !== changes.tempAddress.previousValue) {
              this.autoAddressLine.setValue(current, { emitEvent: false });
              this.updateAddress(current);
            }
            break;
        }
      }
    }
  }

  private updateAddress(currentAddress?: string): void {
    if (currentAddress || (this.tempAddress && typeof this.tempAddress === 'string')) {
      this.changeAddress(currentAddress || this.tempAddress);
    }
    if (google?.maps?.places && google.maps.event) {
      this.createGoogleMapsAutocomplete();
    }
  }

  createForm(): void {
    this.autoAddressForm = new FormGroup({
      autoAddressLine: new FormControl(
        this.autoAddress.autoAddressLine,
        this.isPharmacyAddress
          ? [Validators.required]
          : null
      ),
    });
  }

  createAndValidateAddressObject(place: google.maps.places.PlaceResult): void {
    let unitNumber: string = this.getUnitNumber(place) || '';

    if (unitNumber) {
      if (/^([1-9][0-9]*)/.test(unitNumber.trim())) {
        // unitNo = 'Unit '.concat(unitNo, '  '); // Australia Post likes 2 spaces between unit and street number
        unitNumber += '/'; // Simplified form. eg. 3/25 Street Name
      } else {
        // If user entered something like "Shop 2", append two spaces
        unitNumber += '  ';
      }
    }

    const address: Address = {
      originalAddress: place.formatted_address,
      // unitNumber: this.getUnitNumber(place),
      streetNumber: unitNumber + this.getStreetNumber(place),
      route: this.getRoute(place),
      locality: this.getLocality(place),
      administrativeAreaLevel1: this.getAdministrativeAreaLevel1(place),
      administrativeAreaLevel2: this.getAdministrativeAreaLevel2(place),
      country: this.getCountry(place),
      postalCode: this.getPostCode(place),
      lat: this.getLatitude(place),
      lng: this.getLongitude(place),
    };

    let isError: boolean = false;
    let errorMessage: string = null;

    const isPinpointAddress: boolean = Boolean(address.lat && address.lng);

    if (!this.isPharmacyAddress) {
      Object.keys(address).some((key) => {
        if (
          ['originalAddress', 'unitNumber', 'lat', 'lng'].indexOf(key) === -1 &&
          address[key] === undefined &&
          !isError
        ) {
          isError = true;
          if (key === 'streetNumber') {
            errorMessage = 'Street Number is required';
          } else if (key === 'administrativeAreaLevel1') {
            errorMessage = 'State is required'
          } else if (key === 'administrativeAreaLevel2') {
            // There are instances, such as Canberra, where 'city' is not specified.
            // If this is a valid address, instead of showing an error message, set this
            // field to an empty string
            if (isPinpointAddress) {
              address.administrativeAreaLevel2 = address.locality;
              isError = false;
            } else {
              errorMessage = 'City is required';
            }
          } else {
            errorMessage = `${key.replace(/(?!^)([A-Z]|\d+)/g, ' $1')} is required`;
          }
        }
      });

      if (!isError && !(address.lat || address.lng)) {
        isError = true;
        errorMessage = 'Invalid location';
      }

      if (isError) {
        this.autoAddressLine.setErrors({ invalid: true, message: errorMessage });
      } else {
        this.autoAddressLine.setErrors(null);
      }
    } else {
      // If we have an incomplete address for pharmacy search, fill in the gaps
      // address.unitNumber = address.unitNumber ?? '';
      address.streetNumber = address.streetNumber ?? '1';
      address.administrativeAreaLevel2 = address.administrativeAreaLevel2 ?? address.locality;

      this.addressModified = !isPinpointAddress;
    }

    this.passData.emit({ address, isError });
  }

  switch(): void {
    this.switchForm.emit();
  }

  capitalise(str: string): string {
    if (str && typeof str === 'string') {
      return str[0].toUpperCase() + str.slice(1);
    }

    return str;
  }

  changeAddress(value: string): void {
    const errors: ValidationErrors = this.autoAddressLine.errors && Object.keys(this.autoAddressLine.errors).length
      ? this.autoAddressLine.errors
      : null;

    if (!errors) {
      if (typeof value === 'string' && value.length) {
        const addressWithoutCountry: string = value.endsWith(', Australia')
          ? value.substring(0, value.length - 11)
          : value;

        // if (value !== addressWithoutCountry || addressWithoutCountry !== this.autoAddressLine.value) {
        this.autoAddressLine.patchValue(addressWithoutCountry, { emitEvent: false });
        this.addressTextTracker = addressWithoutCountry;
        // }
      }
    }
  }

  /**
   * @function googleAutocompleteService
   * @description Use Google Maps AutocompleteService to get addresses matching text the user has entered
   *
   * @param {string} addressInput addrss text
   */
  googleAutocompleteService(addressInput: string): void {
    if (!addressInput) {
      return;
    }

    try {
      const service: google.maps.places.AutocompleteService = new google.maps.places.AutocompleteService();

      service.getPlacePredictions({
        input: addressInput,
        componentRestrictions: { country: 'AU' }
      }, (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          this.autoAddressLine.setErrors({ invalid: true, message: 'No matching address' });
          this.passData.emit({ address: null, isError: true });
          return;
        }
        this.autoAddressLine.setErrors(null);
        if (Array.isArray(predictions) && predictions.length) {
          for (let i = 0; i < predictions.length; i++) {
            if (
              predictions[i].description &&
              predictions[i].types?.indexOf('geocode') !== -1 &&
              (predictions[i].types?.indexOf('street_address') !== -1 ||
                predictions[i].types?.indexOf('premise') !== -1 ||
                predictions[i].types?.indexOf('subpremise') !== -1)
            ) {
              this.autoAddressLine.setValue(predictions[i].description);
              this.passData.emit({ address: { originalAddress: predictions[i].description }, isError: false });
              break;
            }
          }
        }
      });
    } catch (err: any) {
      console.log('google.maps.places.AutocompleteService failed to execute getPlacePredictions(). Error = ', err);
    }
  }

  /**
   * @function createGoogleMapsAutocomplete
   * @description Initialise Google Maps Autocomplete for the address input field and listen for matching
   * address selection events
   */
  private createGoogleMapsAutocomplete(): void {
    if (this.addresstext?.nativeElement && !this.autoCompleteField) {
      try {
        this.autoCompleteField = new google.maps.places.Autocomplete(
          this.addresstext.nativeElement,
          {
            componentRestrictions: { country: 'AU' },
            fields: ['address_components', 'formatted_address', 'geometry', 'types'],
            types: [this.addressType], // 'address' (default) / 'establishment' / 'geocode'
          }
        );

        google.maps.event.addListener(this.autoCompleteField, 'place_changed', () => {
          this.zone.run(() => {
            const place: google.maps.places.PlaceResult = this.autoCompleteField.getPlace();

            // Residential house is of type 'street_address'. An office can be a 'premise' or 'subpremise'.
            // If place is of type 'route' then we've found a building, but the apartment number is missing
            if (
              place?.address_components?.length &&
              place.formatted_address &&
              (place.types.indexOf('street_address') !== -1 ||
                place.types.indexOf('premise') !== -1 ||
                place.types.indexOf('subpremise') !== -1)
            ) {
              this.getFormattedAddress(place);
              this.changeAddress(place.formatted_address);

              this.addressModified = false;

              this.createAndValidateAddressObject(place);
            } else {
              if (!this.isPharmacyAddress) {
                this.autoAddressLine.setErrors({
                  invalid: true,
                  message: 'No postal drop-off point ' + (this.isNarrowMobile ? 'found' : 'at this location')
                });
                this.passData.emit({ address: null, isError: true });
              } else if (place?.address_components?.length) {
                this.createAndValidateAddressObject(place);
              }
            }
          });
        });
      } catch (err: any) {
        console.log('Could not instantiate Google Maps!');
      }
    }
  }

  /**
   * @function onExitAddressField
   * @description When user exits from the address field (blur event triggered) select top address
   * match if no match has been selected by the user.
   *
   * @param {Event} event
   */
  onExitAddressField(event: any): void {
    if (this.autoAddressLine.value === null || this.autoAddressLine.value === '') {
      this.autoAddressLine.setErrors(null);
      this.passData.emit({ address: null, isError: false });
    } else if (typeof this.autoAddressLine.value === 'string' && this.autoAddressLine.value) {
      // Wait a short time for selected address place to be processed before checking its validity
      setTimeout(() => {
        const selectedPlace: google.maps.places.PlaceResult = this.autoCompleteField.getPlace();

        if (typeof selectedPlace === 'undefined' || this.addressModified) {
          if (!this.autoAddressLine.hasError('invalid')) {
            this.autoAddressLine.setErrors({ invalid: true, message: 'No matching address selected' });
          }
          this.passData.emit({ address: null, isError: true });
        }
      }, 450);

      /*
      let hasPlaceSelection: boolean = false;

      const selectedItem: Element = document.querySelector('.pac-container .pac-item-selected');

      if (!selectedItem) {
        const items: NodeListOf<Element> = document.querySelectorAll('.pac-container .pac-item');

        if (items?.length) {
          for (let x = 0; x < items.length; x++) {
            if (items[x].matches(':hover')) {
              hasPlaceSelection = true;
              break;
            }
          }
        }
      } else {
        hasPlaceSelection = true;
      }

      if (!hasPlaceSelection) {
        this.autoAddressLine.setErrors(null);

        // NOTE: prediction results will only yield us text values - we need an Address object
        this.googleAutocompleteService(this.autoAddressLine.value);
      }
      */
    }
  }

  private getFormattedAddress(place: google.maps.places.PlaceResult): void {
    this.autoAddress = place.formatted_address;
    this.formattedAddress = place.formatted_address;
  }

  private getAddressComponent(place: any, componentTemplate: any): any {
    let result: any;

    if (place?.address_components?.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        try {
          const addressType: string = place.address_components[i].types[0];
          if (componentTemplate[addressType]) {
            result = place.address_components[i][componentTemplate[addressType]];
            return result;
          }
        } catch (err: any) {}
      }
    }

    return;
  }

  private getLatitude(place: any): number {
    return place?.geometry?.location?.lat();
  }

  private getLongitude(place: any): number {
    return place?.geometry?.location?.lng();
  }

  // Suburb
  private getLocality(place: any): any {
    return this.getAddressComponent(place, { locality: 'long_name' });
  }

  private getCountry(place: any): any {
    return this.getAddressComponent(place, { country: 'long_name' });;
  }

  private getPostCode(place: any): any {
    return this.getAddressComponent(place, { postal_code: 'long_name' });
  }

  private getStreetNumber(place: any): any {
    return this.getAddressComponent(place, { street_number: 'short_name' });
  }

  private getUnitNumber(place: any): any {
    return this.getAddressComponent(place, { subpremise: 'long_name' });
  }

  // Street Name
  private getRoute(place: any): any {
    return this.getAddressComponent(place, { route: 'short_name' }); //long_name
  }

  // State
  private getAdministrativeAreaLevel1(place: any): any {
    // should use abbreviated state name (short_name)
    return this.getAddressComponent(place, { administrative_area_level_1: 'short_name' });
  }

  // City
  private getAdministrativeAreaLevel2(place: any): any {
    return this.getAddressComponent(place, { administrative_area_level_2: 'long_name' });
  }

  // private getCountryShort(place: any) {
  //   const COMPONENT_TEMPLATE = { country: 'short_name' },
  //     countryShort = this.getAddressComponent(place, COMPONENT_TEMPLATE);
  //   return countryShort;
  // }

  // private getPhone(place: any) {
  //   const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' },
  //     phone = this.getAddressComponent(place, COMPONENT_TEMPLATE);
  //   return phone;
  // }

  get autoAddressLine() {
    return this.autoAddressForm.get('autoAddressLine');
  }
}
