import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Constants } from '@app/shared/constants';

@Component({
  selector: 'medication-modal',
  templateUrl: './medication-modal.component.html',
  styleUrls: ['./medication-modal.component.scss'],
})
export class MedicationModalComponent implements OnInit {
  medicationName: string = 'This medication';

  controlledDrugWarning: string = Constants.MedicationWarnings.controlledDrugs;
  controlledDrugsProceed: string = Constants.MedicationWarnings.controlledDrugsProceed;

  constructor(
    public dialogRef: MatDialogRef<MedicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    if (this.data && this.data.medication && this.data.medication.name) {
      this.medicationName = this.data.medication.name;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
