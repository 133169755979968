export class Address {
  addressId?: string;
  originalAddress?: string;
  unitNumber?: string;
  streetNumber?: string;
  route?: string;
  administrativeAreaLevel1?: string;
  administrativeAreaLevel2?: string;
  country?: string;
  postalCode?: string;
  locality?: string;
  lat?: number;
  lng?: number;
}
