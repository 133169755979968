import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { SessionStorage } from 'ngx-webstorage';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { Address } from '../models/address';
import { IResponseAPI } from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  constructor(
    private http: HttpClient,
    private functions: Functions
  ) {}

  @SessionStorage()
  private _addresses: any;

  get addresses(): any {
    if (!this._addresses) {
      this._addresses = {};
    }
    return this._addresses;
  }

  addressIdExists(address: Address): string {
    let addressId = '';

    if (address && this.addresses) {
      for (const key of Object.keys(this.addresses)) {
        // Check all necessary values for an address match
        if (
          this.addresses[key].administrativeAreaLevel1 &&
          this.addresses[key].locality &&
          this.addresses[key].postalCode &&
          this.addresses[key].route &&
          this.addresses[key].streetNumber &&
          this.addresses[key].administrativeAreaLevel1 === address.administrativeAreaLevel1 &&
          this.addresses[key].locality === address.locality &&
          this.addresses[key].postalCode === address.postalCode &&
          this.addresses[key].route === address.route &&
          this.addresses[key].streetNumber === address.streetNumber
        ) {
          addressId = key;
          break;
        }
      }
    }

    return addressId;
  }

  // GET /api/v1/address
  getAddressById(addressId: string): Promise<Address> {
    if (this.addresses && typeof this.addresses === 'object' && this.addresses[addressId]) {
      return Promise.resolve(this.addresses[addressId]);
    } else {
      const params = new HttpParams().append('addressId', addressId);

      return this.http
        .get(`${this.url}/address`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response && response.success) {
            let address = response.response as Address;

            address.addressId = addressId;
            this._addresses[addressId] = address;

            return address;
          }
          return null;
        })
        .catch((err: any) => {
          this.functions.handleError(err);
          return null;
        });
    }
  }

  // POST /api/v1/address
  createAddress(address: Address, throwError: boolean = false): Promise<string> {
    const addressId: string = this.addressIdExists(address);

    if (addressId) {
      return Promise.resolve(addressId);
    } else {
      return this.http
        .post(`${this.url}/address`, address)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response && response.success) {
            const createdAddress = response.response as any;
            if (createdAddress.addressId && this.addresses) {
              let newAddress: Address = address;

              newAddress.addressId = createdAddress.addressId;
              this._addresses[createdAddress.addressId] = newAddress;

              return createdAddress.addressId;
            }
          }
          return null;
        })
        .catch((err: any) => {
          this.functions.handleError(err);

          if (throwError) {
            throw err;
          } else {
            return null;
          }
        });
    }
  }
}
