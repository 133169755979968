<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">{{ modalType | titlecase }}</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <form [formGroup]="quickscriptForm">
    <div class="modal-body container-fluid text-secondary">
      <div class="row m-0">
        <!-- isHealthCardsValid && isMobilePhoneValid &&  -->
        <div
          *ngIf="deliveryMethodSelected == deliveryMethod.quickscriptpharm"
          class="col-12 mb-4">
          <div class="gray-box row bigger-padding">
            <div class="col-12 my-auto question-container">
              <span class="gray-box-question">
                Please choose how you would like to receive your prescription.<br/>
                <span class="smaller-text">
                  Note: You must have a valid Medicare Card or IHI Number and a valid Phone Number
                  to receive eScripts. Click <a (click)="openHealthcareModal()">here</a> to update healthcare
                  card details.
                </span>
              </span>
            </div>
            <div class="col-12 button-wrapper">
              <mat-button-toggle-group formControlName="receivePrescription" class="toggle-group mobile-vertical">
                <mat-button-toggle
                  value="EScript"
                  [disabled]="!isMobilePhoneValid || !isHealthCardsValid"
                  [title]="isMobilePhoneValid && isHealthCardsValid
                    ? 'Receive your prescription via SMS'
                    : 'You must have a valid Medicare Card or IHI Number and a valid Phone Number to received eScripts'">
                  Receive an eScript
                </mat-button-toggle>
                <mat-button-toggle
                  value="SendScriptToPharmacy"
                  title="Send prescription to the pharmacy of your choice">
                  Send script to Pharmacy
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div *ngIf="etp2Selected" class="gray-box row normal-padding">
            <div class="col-12">
              <info-icon></info-icon>
              <span class="normal-desc">We will send a token code to your mobile number</span>
            </div>
          </div>
        </div>
        <div
          *ngIf="sendPharmacySelected || deliveryMethodSelected == deliveryMethod.quickscriptdeliver"
          class="col-12 mb-2">
          <address-input
            [isChangePharmacy]="true"
            [inputAddress]="address.value"
            (addressUpdate)="addressUpdate($event)">
          </address-input>
        </div>
        <div *ngIf="deliveryMethodSelected == deliveryMethod.quickscriptpharm && !etp2Selected" class="col-12">
          <div *ngIf="isLoading" class="my-5">
            <loading-spinner loadingText="Finding Pharmacies ..."></loading-spinner>
          </div>
          <div [class.d-none]="isLoading">
            <mat-form-field appearance="outline">
              <mat-label>Select Pharmacy</mat-label>
              <mat-select formControlName="pharmacy" [compareWith]="compareFn">
                <mat-option *ngFor="let pharmacy of pharmacies" [value]="pharmacy">
                  {{ pharmacy.name }}{{ getDistanceText(pharmacy.distance) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="contact-us text-right">
              Can't find your pharmacy? <a
                mat-list-item
                role="button"
                [routerLink]="['/dashboard', 'contact-us']">
                Please contact us
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="deliveryMethodSelected == deliveryMethod.quickscriptdeliver" class="col-12">
          <mat-form-field appearance="outline" class="default-input">
            <mat-label>Delivery Instructions</mat-label>
            <input
              formControlName="deliveryInstruction"
              maxlength="255"
              matInput
              type="text" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer container-fluid bg-muted">
    <div class="row m-0">
      <div class="col-12">
        <button
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          [disabled]="isLoading || quickscriptForm.invalid || !address.value"
          (click)="onSubmit()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
