<div class="modal-wrapper">
  <form [formGroup]="resetPasswordForm" (submit)="$event.preventDefault()">
    <div class="modal-header container-fluid align-items-center">
      <div class="col-10 px-0">
        <h4 class="text-primary title mb-0">Change Password</h4>
      </div>
      <div class="col-2 px-0">
        <button type="button" class="close" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div *ngIf="isConfirm" class="modal-body container-fluid text-primary">
      <p class="col-12 px-0 m-0">
        Your password has been changed.
      </p>
    </div>
    <div *ngIf="!isConfirm" class="modal-body container-fluid text-primary">
      <p class="col-12 px-0">
        Please enter your new password below:
      </p>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="form-container">
            <div class="form-group m-0">
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="default-input with-long-error-text">
                    <mat-label>New password</mat-label>
                    <input
                      formControlName="newPassword"
                      autocomplete="new-password"
                      [type]="passHide ? 'password' : 'text'"
                      maxlength="32"
                      matInput
                      required
                      placeholder="New password"
                      (keydown.enter)="$event.preventDefault()">
                    <button
                      mat-icon-button
                      matSuffix
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="passHide"
                      (click)="toggleMyPasswordFieldType($event, false)">
                      <mat-icon>{{ passHide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error *ngIf="newPassword.invalid &&
                      newPassword.errors &&
                      (newPassword.dirty || newPassword.touched)">
                      <span *ngIf="newPassword.hasError('required')">
                        New password is required
                      </span>
                      <span *ngIf="newPassword.hasError('sameAsOld')">
                        <span class="d-none d-sm-inline">
                          Your new password must be different to your old password
                        </span>
                        <span class="d-inline d-sm-none">
                          New password must be different to old one
                        </span>
                      </span>
                      <span *ngIf="newPassword.hasError('pattern')">
                        {{ passwordPatternErrorMessage }}
                      </span>
                      <span *ngIf="newPassword.hasError('tooWeak')">
                        New password is too weak
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field appearance="outline" class="default-input">
                    <mat-label>Confirm new password</mat-label>
                    <input
                      formControlName="newPasswordConfirm"
                      autocomplete="new-password"
                      [type]="confirmPassHide ? 'password' : 'text'"
                      maxlength="32"
                      matInput
                      required
                      placeholder="Confirm new password"
                      (keydown.enter)="onSubmit($event)">
                    <button
                      mat-icon-button
                      matSuffix
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="confirmPassHide"
                      (click)="toggleMyPasswordFieldType($event, true)">
                      <mat-icon>{{ confirmPassHide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error
                      *ngIf="newPasswordConfirm.invalid &&
                        newPasswordConfirm.errors &&
                        (newPasswordConfirm.dirty || newPasswordConfirm.touched)">
                      <span *ngIf="newPasswordConfirm.hasError('required')">
                        Confirm password is required
                      </span>
                      <span *ngIf="newPasswordConfirm.hasError('notEquivalent')">
                        Password doesn't match
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="d-none">
                  <password-strength-meter
                    [userInputs]="userInputs"
                    [password]="newPassword.value"
                    [enableFeedback]="false"
                    [enableDebounce]="true"
                    [enableStrengthLabel]="false"
                    [minPasswordLength]="6"
                    (resultUpdated)="passwordValidationResultUpdated($event)">
                  </password-strength-meter>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="modal-footer container-fluid bg-muted flex-nowrap">
      <button
        *ngIf="!isConfirm"
        type="button"
        mat-flat-button
        color="accent"
        class="btn-block"
        [class.spinner]="isLoading"
        [disabled]="!resetPasswordForm.valid || isLoading"
        (click)="onSubmit()">
        Change Password
      </button>
      <button
        *ngIf="isConfirm"
        mat-flat-button
        type="button"
        color="accent"
        class="btn-block"
        (click)="onClose()">
        Close
      </button>
    </div>
  </form>
</div>
