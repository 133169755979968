export abstract class BaseB2BPortalLoginVerificationModel {
  emailAddress: string;
  mfaEnrolmentId: string;
  b2bCustomerCode: string;
  b2bCustomerId: string;

  constructor(init?: Partial<BaseB2BPortalLoginVerificationModel>) {
    Object.assign(this, init);
  }
}

export enum MFAEnrolmentMedium {
  sms,
  email
}

export class B2BPortalLoginUserVerificationModel extends BaseB2BPortalLoginVerificationModel {
  password: string;

  constructor(init?: Partial<B2BPortalLoginUserVerificationModel>) {
    super(init);
    Object.assign(this, init);
  }
}

export class B2BPortalLoginUserSendCodeModel {
  b2bCustomerId: string;
  emailAddress: string;

  constructor(init?: Partial<B2BPortalLoginUserSendCodeModel>) {
    Object.assign(this, init);
  }
}

export class B2BPortalLoginPatientVerificationModel extends BaseB2BPortalLoginVerificationModel {
  patientId: string;

  constructor(init?: Partial<B2BPortalLoginPatientVerificationModel>) {
    super(init);
    Object.assign(this, init);
  }
}

export class B2BPortalVerifyPolicyRequest {
  b2bCustomerId: string;
  policyNumber: string;
  serviceTypeText: string;

  constructor(init?: Partial<B2BPortalVerifyPolicyRequest>) {
    Object.assign(this, init);
  }
}

export class B2BPortalGeneratePolicyRequest {
  b2bCustomerId: string;
  serviceType: string;

  constructor(init?: Partial<B2BPortalGeneratePolicyRequest>) {
    Object.assign(this, init);
  }
}

export class B2BCustomerPortalBookingPatient {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  title: string;
  gender: string;
  phone: string;
  timezone: string;

  constructor(init?: Partial<B2BCustomerPortalBookingPatient>) {
    Object.assign(this, init);
  }
}

export class B2BPortalCreateNewPatientRequest {
  b2bCustomerId: string;
  emailAddress: string;
  title: string;
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  timezone: string;

  constructor(init?: Partial<B2BPortalCreateNewPatientRequest>) {
    Object.assign(this, init);
  }
}

export class B2BPortalValidatePatientEmailRequest {
  b2bCustomerId: string;
  emailAddress: string;

  constructor(init?: Partial<B2BPortalValidatePatientEmailRequest>) {
    Object.assign(this, init);
  }
}

export class B2BPortalClaimViewModel {
  id: string;
  number: string;
  name: string;
  effective: string;
  expires: string;
  user: B2BUserViewModel;

  constructor(init?: Partial<B2BPortalClaimViewModel>) {
    Object.assign(this, init);
  }
}

export class B2BUserViewModel {
  email: string;

  constructor(init?: Partial<B2BUserViewModel>) {
    Object.assign(this, init);
  }
}

export class JwtAccessToken {
  accessToken: string;
  refreshToken: string;
  expiresInSeconds: number;
}

export class B2BPortalUserLoginAccessTokens {
  jwtAccessToken: JwtAccessToken;
  resourceAccessToken: string;

  constructor(init?: Partial<B2BPortalUserLoginAccessTokens>) {
    Object.assign(this, init);
  }
}

export enum B2BCustomerBookingStatus {
  UserSendCode = 1,
  UserVerifyCodeAndPassword = 2,
  PolicyNumber = 3,
  PatientEnterEmail = 4,
  PatientAdd = 5,
  PatientSelect = 6,
  PatientSendCode = 7,
  PatientVerifyCode = 8,
  GenerateNewPolicy = 9,
  MakeBooking = 10,
  Complete = 11
}
