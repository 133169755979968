import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { Routes, RouterModule, Router, Route } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { environment } from '@env/environment';
// import { TranslateModule } from '@ngx-translate/core';
// import { AuthenticationGuard } from './core/services/authentication.guard';
import { STEP_PATH } from './shared/step-configuration';

const appRoutes: Routes = [
  {
    path: STEP_PATH.FALLBACK,
    loadChildren: () => import('./pages/fallback/fallback.module').then((m) => m.FallbackModule),
    data: { name: STEP_PATH.FALLBACK, roles: ['all'] }
  },
  {
    path: '',
    redirectTo: STEP_PATH.DASHBOARD,
    pathMatch: 'full',
    data: { name: STEP_PATH.DASHBOARD, roles: ['dod'] }
  },
  {
    path: STEP_PATH.DASHBOARD,
    // canActivate: !isNative ? [AuthenticationGuard] : undefined,
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
    data: { name: STEP_PATH.DASHBOARD, roles: ['dod'] }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
    data: { name: 'login', roles: ['all'] }
  },
  {
    path: STEP_PATH.SIGN_UP,
    loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupModule),
    data: { name: STEP_PATH.SIGN_UP, roles: ['all'] }
  },
  {
    path: 'bp/' + STEP_PATH.APPOINTMENT,
    loadChildren: () => import('./pages/appointment/appointment.module').then((m) => m.AppointmentModule),
    data: { name: STEP_PATH.APPOINTMENT, roles: ['all'] }
  },
  {
    path: STEP_PATH.APPOINTMENT,
    loadChildren: () => import('./pages/appointment/appointment.module').then((m) => m.AppointmentModule),
    data: { name: STEP_PATH.APPOINTMENT, roles: ['all'] }
  },
  {
    path: 'appointment-mobile',
    loadChildren: () =>
      import('./pages/appointment-mobile/appointment-mobile.module').then((m) => m.AppointmentMobileModule),
    data: { name: 'appointmentmobile', roles: ['all'] }
  },
  {
    path: STEP_PATH.WAITING_ROOM,
    // canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/waitingroom/waitingroom.module').then((m) => m.WaitingroomModule),
    data: { name: STEP_PATH.WAITING_ROOM, roles: ['dod'] }
  },

  // QuickScript and Cart of unfinished orders
  {
    path: STEP_PATH.QUICK_SCRIPT,
    loadChildren: () => import('./pages/quick-script/quick-script.module').then((m) => m.QuickScriptModule),
    data: { name: STEP_PATH.QUICK_SCRIPT, roles: ['all'] }
  },
  // Shopping cart disabled
  // {
  //   path: 'shopping-cart',
  //   loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then((m) => m.ShoppingCartModule),
  //   data: { name: 'shoppingcart', roles: ['all'] }
  // },

  // {
  //   path: 'reset-password',
  //   loadChildren: () => import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  //   data: { name: 'resetpassword', roles: ['all'] }
  // },

  // WhiteLabel landing page
  {
    path: STEP_PATH.SMOKING_CESSATION,
    loadChildren: () =>
      import('./pages/verticals/smokingcessation/smoking-cessation.module').then((m) => m.SmokingCessationModule),
    data: { name: STEP_PATH.SMOKING_CESSATION, roles: ['all'] }
  },
  {
    path: 'wl',
    loadChildren: () => import('./pages/whitelabels/whitelabels.module').then((m) => m.WhitelabelsModule),
    data: { name: 'whitelabels', roles: ['all'] }
  },
  {
    path: 'bp',
    loadChildren: () => import('./pages/business-portal/business-portal.module').then((m) => m.BusinessPortalModule),
    data: { name: 'businessportal', roles: ['all'] }
  },
  {
    path: 'in-pharmacy/smokingcessation',
    redirectTo: 'appointment/smokingcessation/choose-an-appointment-time',
    data: { name: '', roles: ['all'] }
  },
  // Custom Page Not Found component
  {
    path: 'notfound',
    component: NotFoundComponent,
    data: { name: 'not-found', roles: ['all'], title: 'Page Not Found' }
  },

  // Fallback when no prior route is matched
  {
    path: '**',
    // component: NotFoundComponent,
    redirectTo: 'notfound',
    data: { name: 'not-found', roles: ['all'], title: 'Page Not Found' }
  }
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {}

@NgModule({
  imports: [
    RouterModule.forRoot([], { scrollPositionRestoration: 'enabled' })
    // TranslateModule
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: routeConfigFactory,
      deps: [Injector],
      multi: true
    }
  ]
})
export class AppRoutingModule {}

export function routeConfigFactory(injector: Injector) {
  return () => {
    return new Promise<void>((resolve: any) => {
      const filteredRoutes: Route[] = appRoutes.filter((item: Route) => {
        if (!item.data.roles || item.data.roles.length === 0) {
          return true;
        }

        const agencyCode: string = environment.agencyCode;

        return (
          item.data.roles.filter((role: string) => {
            return role === agencyCode || role === 'all';
          }).length > 0
        );
      });

      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);

      resolve();
    });
  };
}
