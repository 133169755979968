export class Medication {
  medicationId: string;
  medicationBrandId?: string;
  name: string;
  categoryName?: string;
  shortDescription?: string;
  longDescription?: string;
  sortOrder?: number;
  blackListed?: boolean;
  allowed?: boolean;
  redirectService?: string;
}
