<div class="container-fluid">
  <ng-container *ngIf="b2bCustomerService.currentPatientBookingStatus == B2BCustomerBookingStatus.UserSendCode">
    <div class="message-container mt-4">
      <div class="gray-box">
        <p>
          In the interest of security, Doctors on Demand requires multi-factor authentication to log into this service.
        </p>
        <p class="mb-0">Please enter your work email below to receive a verification code.</p>
      </div>
    </div>

    <div class="message-container">
      <div class="warning-section">
        <form [formGroup]="formGroupSend">
          <div class="row content-section mt-4">
            <div class="col-12">
              <mat-card appearance="outlined" class="card-wrapper">
                <mat-card-content class="content">
                  <div class="form-content">
                    <div class="row content-section">
                      <div class="col-md-6 content-box">
                        <div class="form-group">
                          <mat-form-field appearance="outline" class="w-100" cdkFocusInitial>
                            <mat-label>Email</mat-label>
                            <input
                              formControlName="email"
                              autocomplete="email"
                              matInput
                              maxlength="255"
                              placeholder="Enter the email address"
                              required
                            />
                            <mat-error *ngIf="email.invalid">
                              <span *ngIf="email.hasError('required')">Email is required</span>
                              <span *ngIf="email.hasError('pattern')">Email is not in the correct format</span>
                            </mat-error>
                          </mat-form-field>
                          <div *ngIf="invalidDomainError">
                            <p class="error-message mb-3">
                              Email is not valid for this business. Please contact an administrator.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row footer-section">
                    <div class="col-12 col-sm-3 col-md-2 button-wrapper">
                      <button
                        (click)="sendCode()"
                        [disabled]="isSubmitting"
                        class="save-btn"
                        mat-flat-button
                        color="accent"
                      >
                        <span *ngIf="!isSubmitting">Send Code</span>
                        <span *ngIf="isSubmitting">Sending...</span>
                      </button>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

  <form
    [formGroup]="formGroupVerify"
    *ngIf="b2bCustomerService.currentPatientBookingStatus == B2BCustomerBookingStatus.UserVerifyCodeAndPassword"
  >
    <div class="row content-section mt-4">
      <div class="col-12">
        <mat-card appearance="outlined" class="card-wrapper">
          <mat-card-content class="content">
            <div class="form-content">
              <div class="row content-section">
                <div class="col-md-6 content-box">
                  <div class="form-group">
                    <mat-form-field appearance="outline" class="default-input w-100">
                      <mat-label>Verification Code</mat-label>
                      <input
                        formControlName="mfacode"
                        autocomplete="off"
                        matInput
                        maxlength="255"
                        placeholder="Authentication Code"
                        required
                      />
                      <mat-error *ngIf="mfacode.invalid">
                        <span *ngIf="mfacode.hasError('required')">Authentication Code is required.&nbsp;</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row content-section">
                <div class="col-md-6 content-box">
                  <div class="form-group">
                    <mat-form-field appearance="outline" class="default-input password-field w-100">
                      <mat-label>Password</mat-label>
                      <input
                        formControlName="password"
                        type="password"
                        autocomplete="new-password"
                        matInput
                        maxlength="255"
                        placeholder="Password"
                        required
                      />
                      <mat-error *ngIf="password.invalid">
                        <span *ngIf="password.hasError('required')">Password is required.&nbsp;</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div>
                <p class="error-message mb-3" *ngIf="invalidPasswordOrCode">
                  The business password or your verification code are incorrect or expired. Please start over or contact
                  an administrator.
                </p>
              </div>
              <div class="row footer-section">
                <div class="col-12 col-sm-3 col-md-2 button-wrapper">
                  <button
                    (click)="verifyPasswordAndCode()"
                    [disabled]="isSubmitting"
                    class="save-btn"
                    mat-flat-button
                    color="accent"
                  >
                    <span *ngIf="!isSubmitting">Verify</span>
                    <span *ngIf="isSubmitting">Verifying...</span>
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>
