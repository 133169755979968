<div class="modal-wrapper">
  <div *ngIf="!isDisplayedInline" class="modal-header container-fluid align-items-center">
    <div class="col-10">
      <div class="title">Having Issues?</div>
    </div>
    <div class="col-2">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div
    [ngClass]="isDisplayedInline ? '' : 'modal-body container-fluid'"
    [class.is-offline]="!isAgentOnline"
    class="text-primary">

    <div *ngIf="enableMarketingSite && !isNative" class="row search-box">
      <div class="col-12">
        <p class="label-text">
          Need help? Go to our <a (click)="redirectToFaqs()">FAQs</a>.
        </p>
      </div>
    </div>

    <!-- DOC-5969 - remove User Guide search
    <div *ngIf="enableMarketingSite && !isNative" class="row search-box">
      <div class="col-12">
        <p class="label-text">Having issues connecting with your Practitioner? Or need other technical support?</p>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <input
            [formControl]="searchQuery"
            autocomplete="off"
            matInput
            placeholder="Search our Guides or FAQ"
            [matAutocomplete]="auto" />
          <button
            class="search-button"
            matSuffix
            mat-icon-button
            (click)="redirectToFaqs()">
            <mat-icon>search</mat-icon>
          </button>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="guide-auto-complete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              <div class="clickable-option" (click)="openLink(option?.sub_category_link)">
                <span class="autocomplete-name">{{ option.sub_category_title }} </span>
                <span class="autocomplete-group">{{ option.category_title }}</span>
              </div>
            </mat-option>
            <mat-option
              *ngIf="searchQuery.value?.length >= searchConfig.needHelp_minSearchChars"
              class="autocomplete-searchall">
              <p class="autocomplete-searchall-text">Not what you're after?</p>
              <a
                mat-flat-button
                color="accent"
                class="btn-block"
                [href]="marketingURL.faqs"
                target="_blank">
                Search All
              </a>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="no-msg"></div>
      </div>
    </div>
    -->

    <div *ngIf="enableLiveChat && !(isDisplayedInline && !isAgentOnline)" [class.row]="isDisplayedInline">
      <live-chat-contact-widget
        [isDisplayedInline]="isDisplayedInline"
        (closePopup)="onClose()">
      </live-chat-contact-widget>
    </div>
  </div>
</div>
