import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GetPolicyEligibilityDTO } from '@src/app/shared/models/GetPolicyEligibilityDTO';

@Component({
  selector: 'policy-eligibility-modal',
  templateUrl: './policy-eligibility-modal.component.html',
  styleUrls: ['./policy-eligibility-modal.component.scss']
})
export class PolicyEligibilityModalComponent {
  model: GetPolicyEligibilityDTO;
  trustedHtmlHeader: SafeHtml;
  trustedHtmlBody: SafeHtml;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: GetPolicyEligibilityDTO,
    public dialogRef: MatDialogRef<PolicyEligibilityModalComponent>,
    private sanitizer: DomSanitizer
  ) {
    this.model = data;
    this.trustedHtmlHeader = this.sanitizer.bypassSecurityTrustHtml(this.model.headerHtml);

    // inject URL if required
    const urlInjectedBody: string = this.model.instructionsHtml
      .replace('{{ URL }}', '{{URL}}')
      .replace('{{URL}}', this.model.url);

    this.trustedHtmlBody = this.sanitizer.bypassSecurityTrustHtml(urlInjectedBody);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
