import { Component, Inject, OnInit, Optional, SecurityContext } from '@angular/core';
import { ONDEMAND_STEP_PATH_MAPPING, SERVICE_STEP_PATH_MAPPING, STEP_PATH } from '@app/shared/step-configuration';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Constants } from '@app/shared/constants';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { AvailabilityService } from '@app/shared/services/availability.service';
import { StepService } from '@app/shared/services/step.service';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { ErrorModalData } from '../../models/error-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'error-message-modal',
  templateUrl: './error-message-modal.component.html',
  styleUrls: ['./error-message-modal.component.scss']
})
export class ErrorMessageModalComponent implements OnInit {
  private subscription = new Subscription();

  dialogData: ErrorModalData;
  enableAppointments: boolean = false;
  enableOnDemand: boolean = false;
  private appointmentStepType: string = 'APPOINTMENT_TYPE_DOCTOR';
  private appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: ErrorModalData,
    public dialogRef: MatDialogRef<ErrorMessageModalComponent>,
    private router: Router,
    private appointmentService: AppointmentService,
    private stepService: StepService,
    private availabilityService: AvailabilityService,
    private whiteLabelService: WhitelabelService,
    private htmlSanitiser: DomSanitizer
  ) {
    this.dialogData = this.data;
    this.dialogData.message = this.htmlSanitiser.sanitize(SecurityContext.HTML, this.dialogData.message || '');

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    // WHITELABEL CONFIGURATION
    this.updateWhiteLabelConfiguration();
    this.subscription.add(
      this.whiteLabelService.whitelabelConfigObs.subscribe(() => {
        this.updateWhiteLabelConfiguration();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateWhiteLabelConfiguration(): void {
    this.enableAppointments = this.whiteLabelService.canProvideAppointments();
    this.appointmentServiceType =
      this.dialogData.serviceType ||
      this.appointmentService.appointment?.serviceType ||
      this.whiteLabelService.getPrimaryServiceType();
    this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
    this.enableOnDemand =
      this.enableAppointments &&
      this.whiteLabelService.onDemandEnabledForServiceType(this.appointmentServiceType) &&
      ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType];
  }

  onClose(value?: string): void {
    try {
      this.dialogRef.close(value);
    } catch (err: any) {
      console.log('Unable to close error message modal. Error = ', err?.message);
    }
  }

  private resetAppointment(): void {
    this.stepService.resetService();
    this.appointmentService.resetService();
  }

  scheduleAppointment(): void {
    this.resetAppointment();
    this.stepService.setList(this.appointmentServiceType);

    this.router.navigate(SERVICE_STEP_PATH_MAPPING[this.appointmentServiceType]);

    this.onClose();
  }

  contactUs(): void {
    this.onClose();
    this.router.navigate([STEP_PATH.DASHBOARD, 'contact-us']);
  }

  private onDemandAppointment(): void {
    this.resetAppointment();

    this.router.navigate([STEP_PATH.APPOINTMENT, STEP_PATH[ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType]]]);

    this.onClose();
  }

  speakToDoctor(): void {
    if (this.enableOnDemand && this.availabilityService.isOnline(this.appointmentServiceType)) {
      this.onDemandAppointment();
    } else {
      this.scheduleAppointment();
    }
  }

  rescheduleAppointment(): void {
    this.onClose('reschedule');
  }

  selectPractitioner(): void {
    this.onClose('selectPractitioner');
  }

  cancelAppointment(): void {
    this.onClose('cancelAppointment');
  }

  goToWaitingRoom(): void {
    this.onClose('waitingRoom');
  }

  switchPatient(): void {
    this.onClose('switchPatient');
  }

  updateHealthcareIdentifiers(): void {
    this.onClose('updateHealthcareIdentifiers');
  }

  removeBenefit(): void {
    this.onClose('removeBenefit');
  }

  updatePatientProfile(): void {
    this.router.navigate([STEP_PATH.DASHBOARD, STEP_PATH.YOUR_ACCOUNT, 'personal-details']);
    this.onClose();
  }

  goToDashboard(): void {
    this.router.navigate([STEP_PATH.DASHBOARD]);
    this.onClose();
  }
}
