import { AbstractControl, ValidationErrors } from '@angular/forms';

export function ihiValidator(control: AbstractControl): ValidationErrors | null {
  const input: string = control.value ?? '';
  let isValid: boolean = false;

  if (!input) {
    isValid = true;

  // Full card number length is 16, but validators get triggered before the formatting functions, so we have 1 less char
  } else if (input.length < 19) {
    // isValid = false;
  } else {
    const cardNumber: string = input.replace(/\s+/g, '').replace(/-/g, '').substring(0, 16);
    try {
      if (parseInt(cardNumber).toString() === cardNumber) {
        isValid = true;
      }
    } catch (err: any) {}
  }

  if (!isValid) {
    return { invalid: true };
  } else {
    return null;
  }
}
