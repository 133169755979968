import { Component, OnInit } from '@angular/core';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { B2B_STEP_PATH } from '../../business-portal-routing.module';
import { PatientService } from '@src/app/shared/services/patient.service';

@Component({
  selector: 'business-portal-landing-page',
  templateUrl: './business-portal-landing-page.component.html',
  styleUrls: ['./business-portal-landing-page.component.scss']
})
export class BusinessPortalLandingPageComponent implements OnInit {
  B2B_STEP_PATH = B2B_STEP_PATH;

  constructor(
    public b2bCustomerService: B2BCustomerService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.patientService.resetBenefit();
    this.patientService.removePatientData();
    this.patientService.resetPatient();
    this.b2bCustomerService.clearAllBookingInformation();
  }

  getDisplayName(serviceType: string) {
    switch (serviceType) {
      case 'doctor':
        return 'Doctor';
      case 'psychologist':
        return 'Psychologist';
      case 'dietitian':
        return 'Dietitian';
      case 'wellnessspecialist':
        return 'Wellness Specialist';
      case 'bulkbillgp':
        return 'Bulk Bill GP';
      case 'sleepgp':
        return 'Sleep GP';
      case 'sleepphysicianinitial':
        return 'Sleep Physician (Initial)';
      case 'sleepphysicianfollowup':
        return 'Sleep Physician (Follow Up)';
      case 'weightloss':
        return 'Weight Loss';
      case 'mentalhealth':
        return 'Mental Health';
      default:
        return serviceType;
    }
  }
}
