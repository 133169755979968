<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary m-0">Please choose practitioner type</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose(true)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="!showPractitionerOptions; else loaded">
    <div class="mt-5" style="min-height: 112px;">
      <loading-spinner></loading-spinner>
    </div>
  </ng-container>
  <ng-template #loaded>
    <div
      *ngIf="enableAppointments && appointmentType == appointmentTypes.onDemand"
      class="modal-body container-fluid bg-muted p-0">
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Doctor</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <ng-container *ngIf="isDoctorOnline; else elseTemplateDoctor">
            <button
              mat-flat-button
              color="accent"
              class="btn-block"
              (click)="speakTo()">
              Speak to a Doctor Now
            </button>
          </ng-container>
          <ng-template #elseTemplateDoctor>
            <span class="hint">No doctors are online right now</span>
            <button
              mat-stroked-button
              color="accent"
              class="btn-block"
              (click)="scheduleAppointment()">
              Schedule Appointment
            </button>
          </ng-template>
        </div>
      </div>
      <!--
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Psychologist</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <ng-container *ngIf="psychologistIsOnline; else elseTemplatePsychologist">
            <button
              mat-flat-button
              color="accent"
              class="btn-block"
              (click)="speakTo('psychologist')">
              Speak to a Psychologist Now
            </button>
          </ng-container>
          <ng-template #elseTemplatePsychologist>
            <span class="hint">
              No psychologists are online right now
            </span>
            <button
              mat-stroked-button
              color="accent"
              class="btn-block"
              (click)="scheduleAppointment('psychologist')">
              Schedule Appointment
            </button>
          </ng-template>
        </div>
      </div>
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Dietitian</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <ng-container *ngIf="dietitianIsOnline; else elseTemplateDietitian">
            <button
              mat-flat-button
              color="accent"
              class="btn-block"
              (click)="speakTo('dietitian')">
              Speak to a Dietitian Now
            </button>
          </ng-container>
          <ng-template #elseTemplateDietitian>
            <span class="hint">No dietitians are online right now</span>
            <button
              mat-stroked-button
              color="accent"
              class="btn-block"
              (click)="scheduleAppointment('dietitian')">
              Schedule Appointment
            </button>
          </ng-template>
        </div>
      </div>
      -->
      <!-- Currently no Wellness specialists -->
      <!--
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Wellness Specialist</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <ng-container *ngIf="wellnessIsOnline; else elseTemplateWellnessSpecialistOnline">
            <button
              mat-flat-button
              color="accent"
              class="btn-block"
              (click)="speakTo('wellnessSpecialist')">
              Speak to a Wellness Specialist Now
            </button>
          </ng-container>
          <ng-template #elseTemplateWellnessSpecialistOnline>
            <span class="hint">
              No Wellness Specialists are online right now.
              Please schedule an appointment for a future time.
            </span>
            <button
              mat-stroked-button
              color="accent"
              class="btn-block"
              (click)="scheduleAppointment('wellnessSpecialist')">
              Schedule Appointment
            </button>
          </ng-template>
        </div>
      </div>
      -->
    </div>
    <div
      *ngIf="enableAppointments && appointmentType == appointmentTypes.scheduled"
      class="modal-body container-fluid bg-muted p-0">
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Doctor</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <button
            mat-flat-button
            color="accent"
            class="btn-block"
            (click)="scheduleAppointment()">
            Schedule Doctor Appointment
          </button>
        </div>
      </div>
      <!-- Currently no Psychologits, Dietitians, or Wellness specialists -->
      <!--
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Psychologist</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <button
            mat-flat-button
            color="accent"
            class="btn-block"
            (click)="scheduleAppointment('psychologist')">
            Schedule Psychologist Appointment
          </button>
        </div>
      </div>
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Dietitian</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <button
            mat-flat-button
            color="accent"
            class="btn-block"
            (click)="scheduleAppointment('dietitian')">
            Schedule Dietitian Appointment
          </button>
        </div>
      </div>
      -->
      <!--
      <div class="list-item col-12 row mx-0 border-bottom border-white">
        <div class="practitioner-text col-xs-12 col-xl-6 d-flex flex-column my-auto">
          <span class="text-large text-primary">Wellness Doctor</span>
        </div>
        <div class="practitioner-button col-xs-12 col-xl-6 d-flex flex-column align-self-center">
          <button
            mat-flat-button
            color="accent"
            class="btn-block"
            (click)="scheduleAppointment('wellnessSpecialist')">
            Schedule Wellness Doctor Appointment
          </button>
        </div>
      </div>
      -->
    </div>
  </ng-template>
</div>
