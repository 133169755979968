import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { PublicHolidaySpecification } from '../models/publicHolidaySpecification';
import { IResponseAPI } from '../models/api-response';
import { PatientService } from './patient.service';
import { Subject, Subscription } from 'rxjs';
import { PublicHolidayList } from '../models/publicHolidayList';
import { LocalStorageService } from 'ngx-webstorage';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PublicHolidayService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private subscription = new Subscription();

  // Polling subscriptions
  public holidayPollingInterval: Subscription;

  // Observers
  private _holidayStatusUpdated: Subject<boolean> = new Subject<boolean>();
  public holidayStatusUpdatedObs = this._holidayStatusUpdated.asObservable();

  // Holiday status for Today!
  private _isWeekendOrPublicHoliday: boolean = false;
  private _publicHolidayList: string[] = [];

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private patientService: PatientService,
    private storage: LocalStorageService
  ) {}

  get isWeekendOrPublicHoliday(): boolean {
    return this._isWeekendOrPublicHoliday;
  }

  set isWeekendOrPublicHoliday(isHoliday: boolean) {
    if (this.isWeekendOrPublicHoliday !== isHoliday) {
      this._holidayStatusUpdated.next((this._isWeekendOrPublicHoliday = isHoliday));
    }
  }

  get publicHolidayList(): string[] {
    if (!this._publicHolidayList?.length) {
      this._publicHolidayList = this.storage.retrieve(Constants.LocalStorage_Key.publicHolidays);
    }
    if (!Array.isArray(this._publicHolidayList)) {
      this._publicHolidayList = [];
    }

    return this._publicHolidayList;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * @function initPublicHolidayStatusForToday
   * @description Set Sunday or Public Holiday status for today and save it in the service for easy access
   */
  initPublicHolidayStatusForToday(): void {
    if (this.isSaturday() || this.isSunday()) {
      this.isWeekendOrPublicHoliday = true;
    } else {
      const today: string = this.getLocalDateString();
      let isPublicHoliday: boolean = false;

      if (today && this.publicHolidayList?.length) {
        for (let i = 0; i < this._publicHolidayList.length; i++) {
          if (this._publicHolidayList[i].substring(0, 10) === today) {
            isPublicHoliday = true;
            break;
          }
        }
      }

      this.isWeekendOrPublicHoliday = isPublicHoliday;
    }
  }

  /**
   * @async
   * @function retrievePublicHolidays
   * @description Retrieve public holidays list from the API and store in the service and local storage.
   *
   * @returns {Promise<string[]>} List of public holidays in the format 'YYYY-MM-DDT00:00:00'
   */
  async retrievePublicHolidays(): Promise<string[]> {
    const currentYear: number = moment().utc().get('year');
    const storedHolidayList: string[] = this.publicHolidayList; // this.storage.retrieve(Constants.LocalStorage_Key.publicHolidays);

    if (storedHolidayList?.length) {
      this._publicHolidayList = storedHolidayList;
    } else {
      const publicHolidayListThisYear: PublicHolidayList = await this.getPublicHolidaysForYear(currentYear);
      this._publicHolidayList = publicHolidayListThisYear?.publicHolidays || [];
    }

    const yearAfterTwoWeeks: number = moment().utc().add(Constants.AppointmentTimeTable_Configuration.maxDaysToDisplay, 'days').get('year');

    if (
      // Is next year after 14 days?
      yearAfterTwoWeeks > currentYear &&
      (
        // Is public holiday list empty or is last element of public holiday list the current year?
        !this.publicHolidayList?.length ||
        this.publicHolidayList[this.publicHolidayList.length - 1].substring(0, 4) === String(currentYear)
      )
    ) {
      // Retrieve the next year of public holiday data
      const publicHolidayListNextYear: PublicHolidayList = await this.getPublicHolidaysForYear(yearAfterTwoWeeks);
      if (publicHolidayListNextYear?.publicHolidays?.length) {
        this._publicHolidayList = [...(this._publicHolidayList || []), ...publicHolidayListNextYear.publicHolidays];
      }
    }

    this.storage.store(Constants.LocalStorage_Key.publicHolidays, this.publicHolidayList);

    return this.publicHolidayList;
  }

  /**
   * @function isSaturday
   * @description Check whether the specified date is a Saturday. If no date is specified, use today's date.
   *
   * @param {string} [dateString] seed date (defaults to 'now')
   * @param {string} [timezoneOffset] patient's timezone offset string (defaults to offset found in profile)
   *
   * @returns {boolean} true if the date is a Saturday
   */
  isSaturday(dateString?: string, timezoneOffset?: string): boolean {
    let isSaturday: boolean = false;

    try {
      const patientTimeZoneOffset: string = timezoneOffset || this.patientService.getPatientTimeZoneOffset();
      const numericOffset: number = parseFloat(patientTimeZoneOffset);

      isSaturday = moment(dateString).utcOffset(numericOffset, true).isoWeekday() === 6;
    } catch (_err: any) {}

    return isSaturday;
  }

  /**
   * @function isSunday
   * @description Check whether the specified date is a Sunday. If no date is specified, use today's date.
   *
   * @param {string} [dateString] seed date (defaults to 'now')
   * @param {string} [timezoneOffset] patient's timezone offset string (defaults to offset found in profile)
   *
   * @returns {boolean} true if the date is a Sunday
   */
  isSunday(dateString?: string, timezoneOffset?: string): boolean {
    let isSunday: boolean = false;

    try {
      const patientTimeZoneOffset: string = timezoneOffset || this.patientService.getPatientTimeZoneOffset();
      const numericOffset: number = parseFloat(patientTimeZoneOffset);

      isSunday = moment(dateString).utcOffset(numericOffset, true).isoWeekday() === 7;
    } catch (_err: any) {}

    return isSunday;
  }

  /**
   * @function isDateAPublicHoliday
   * @description Check whether the specified date exists in the stored public holiday list.
   *
   * @param {string} [dateString] seed date (defaults to 'now')
   * @param {string} [timezoneOffset] patient's timezone offset string (defaults to offset found in profile)
   *
   * @returns {boolean} true if the date is a public holiday
   */
  isDateAPublicHoliday(dateString?: string, timezoneOffset?: string): boolean {
    const day: string = this.getLocalDateString(dateString, timezoneOffset);
    let isPublicHoliday: boolean = false;

    if (this.publicHolidayList?.length) {
      for (let i = 0; i < this._publicHolidayList.length; i++) {
        if (this._publicHolidayList[i].substring(0, 10) === day) {
          isPublicHoliday = true;
          break;
        }
      }
    }

    return isPublicHoliday;
  }

  /**
   * @deprecated
   * @async
   * @function isDateSundayOrPublicHoliday
   * @description Determine whether today is a Sunday or a Public Holiday (query the API with today's date)
   */
  async isDateSundayOrPublicHoliday(): Promise<void> {
    if (this.isSaturday() || this.isSunday()) {
      this.isWeekendOrPublicHoliday = true;
    } else {
      await this.isPublicHoliday();
    }
  }

  /**
   * @deprecated
   * @function appendPublicHolidays
   * @description Append a list of public holiday dates to the existing/stored public holiday date list
   *
   * @param {string[]} [publicHolidays=[]] list of dates in the format 'YYYY-MM-DDT00:00:00'
   */
  appendPublicHolidays(publicHolidays: string[] = []): void {
    if (!this.publicHolidayList.some((stringDate: string) => publicHolidays.indexOf(stringDate) !== -1)) {
      this._publicHolidayList = [...this.publicHolidayList, ...publicHolidays];
    }
  }

  /**
   * @function getLocalDate
   * @description Apply the patient's timezone offset and get today's date as a Moment object
   *
   * @param {string} [dateString] seed date (defaults to 'now')
   * @param {string} [timezoneOffset] patient's timezone offset string (defaults to offset found in profile)
   *
   * @returns {moment.Moment} Moment object for today with applied timezone offset
   */
  getLocalDate(dateString?: string, timezoneOffset?: string): moment.Moment {
    const patientTimeZoneOffset: string = timezoneOffset || this.patientService.getPatientTimeZoneOffset();
    const numericOffset: number = parseFloat(patientTimeZoneOffset);

    return this.functions.getUTCMoment(patientTimeZoneOffset, false, false, dateString).utcOffset(numericOffset);
  }

  /**
   * @function getLocalDateString
   * @description Apply the patient's timezone offset and get today's date as a string in the format YYYY-MM-DD
   *
   * @param {string} [dateString] seed date (defaults to 'now')
   * @param {string} [timezoneOffset] patient's timezone offset string (defaults to offset found in profile)
   *
   * @returns {string} String date in the format 'YYYY-MM-DD' (after timezone offset has been applied)
   */
  getLocalDateString(dateString?: string, timezoneOffset?: string): string {
    const patientTimeZoneOffset: string = timezoneOffset || this.patientService.getPatientTimeZoneOffset();
    const stringDate: string = this.functions.getUTCMomentString(patientTimeZoneOffset, false, false, dateString);

    if (stringDate?.length >= 10) {
      return stringDate.substring(0, 10);
    }

    return null;
  }

  // startPollingInterval(): void {
  //   if (!this.holidayPollingInterval) {
  //     this.subscription.add(
  //       this.holidayPollingInterval = interval(
  //         Constants.API_Polling_Times.pollAppointmentPricing_seconds * Constants.MILLISECONDS_IN_SECOND
  //       ).subscribe(() => {
  //         this.isDateSundayOrPublicHoliday();
  //       })
  //     );
  //   }
  // }

  /**
   * @async
   * @function getPublicHolidaysForYear
   * @description Retrieve all public holidays for a specified year.
   * GET /api/v1/publicHolidays/isPublicHoliday/2023
   *
   * @param {number} year numeric year
   *
   * @returns {Promise<PublicHolidayList>}
   */
  getPublicHolidaysForYear(year: number): Promise<PublicHolidayList> {
    const params = new HttpParams();

    return this.http
      .get(`${this.url}/publicHolidays/getPublicHolidays/${year}`, { params })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const publicHolidayList = response.response as PublicHolidayList;
          return publicHolidayList;
        }
        return null;
      })
      .catch((err: any) => {
        console.warn('Unable to retrieve public holidays for year', year + '. Error:', this.functions.getErrorMessage(err));
        // this.functions.handleError(err);
        return null;
      });
  }

  /**
   * @deprecated
   * @async
   * @function getPublicHolidaySpecification
   * @description Retrieve public holiday specification for a given date from the API.
   * GET /api/v1/publicHolidays/isPublicHoliday/2023-01-01
   *
   * @param {string} [dateString] defaults to today's date
   * @param {string} [timezoneOffset] timezone offset, defaults to the patient's timezone offset
   *
   * @returns {Promise<PublicHolidaySpecification>}
   */
  getPublicHolidaySpecification(dateString?: string, timezoneOffset?: string): Promise<PublicHolidaySpecification> {
    const params = new HttpParams();
    const dateFmt: string = this.getLocalDateString(dateString, timezoneOffset);
    // date.toLocaleDateString('sv-SE'); // sv-SE: 2021-09-03 17:56:58

    return this.http
      .get(`${this.url}/publicHolidays/isPublicHoliday/${dateFmt}`, { params })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const phSpec = response.response as PublicHolidaySpecification;
          return phSpec;
        }
        return null;
      })
      .catch((err: any) => {
        console.warn('Unable to retrieve public holiday specification. Error:', this.functions.getErrorMessage(err));
        // this.functions.handleError(err);
        return null;
      });
  }

  /**
   * @deprecated
   * @async
   * @function isPublicHoliday
   * @description Retrieve public holiday specification for a given date from the API and update service with the latest data
   *
   * @param {string} [dateString] defaults to today's date
   * @param {string} [timezoneOffset] timezone offset, defaults to the patient's timezone offset
   *
   * @returns {Promise<boolean>} true on success
   */
  isPublicHoliday(dateString?: string, timezoneOffset?: string): Promise<boolean> {
    return this.getPublicHolidaySpecification(dateString, timezoneOffset)
      .then((phSpec: PublicHolidaySpecification) => {
        const isPublicHoliday = Boolean(phSpec?.isPublicHoliday);

        // Update holiday status if the date is Today
        if (!dateString && this.isWeekendOrPublicHoliday !== isPublicHoliday) {
          this.isWeekendOrPublicHoliday = isPublicHoliday;
        }

        return isPublicHoliday;
      })
      .catch((err: any) => {
        console.log('Unable to retrieve public holiday information. Error: ', this.functions.handleError(err));
        return false;
      });
  }
}
