import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { IResponseAPI } from '@app/shared/models/api-response';
import { SendEmailDTO } from '@app/shared/models/sendEmailDTO';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { PatientService } from '@app/shared/services/patient.service';

@Component({
  selector: 'referral-email',
  templateUrl: './referral-email.component.html',
  styleUrls: ['./referral-email.component.scss'],
})
export class ReferralEmailComponent implements OnInit {
  emailForm: FormGroup;
  isConfirm: boolean = false;
  isLoading: boolean = false;
  isMobile: boolean;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReferralEmailComponent>,
    private formBuilder: FormBuilder,
    private functions: Functions,
    private patientService: PatientService,
    private analytics: GoogleAnalyticsService
  ) {
    this.onResize();

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(Constants.emailRegexString)]],
    });
  }

  async onConfirm(): Promise<void> {
    const toEmail: string = this.email.value;
    const attachmentId: string = this.data?.attachmentId;
    const appointmentId: string = this.data?.appointmentId;

    if (toEmail && attachmentId) {
      const payload: SendEmailDTO = {
        attachmentId,
        appointmentId,
        toEmail
      };

      try {
        this.isLoading = true;
        const response: IResponseAPI = await this.patientService
          .sendReferralEmail(payload)
          .catch((err: any) => {
            console.log('Unable to email referral. Error:', this.functions.getErrorMessage(err));
            return null;
          })
          .finally(() => {
            this.isLoading = false;
          });

        if (response?.success) {
          this.analytics.sendReferralToSpecialist(appointmentId);
          this.isConfirm = true;
        } else {
          this.functions.showToast('Failed to send email. Please contact support.');
        }
      } catch (err: any) {
        console.log(this.functions.getErrorMessage(err));
        this.isLoading = false;
      }
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  get email() {
    return this.emailForm.get('email');
  }
}
