import { Labels } from './constants';
import { ServiceType, StepPathValue } from './types';

// Temporarily separated into another file to avoid conflicts.
// This can be applied in the future.

export const stepConfigurationKeys = {
  CHOOSE_APPOINTMENT_TIME: 'CHOOSE_APPOINTMENT_TIME',
  CHAT_WITH_YOUR_PRACTITIONER: 'CHAT_WITH_YOUR_PRACTITIONER',
  QUESTIONNAIRE: 'QUESTIONNAIRE'
} as const;

export const commonStepConfigurations = {
  [stepConfigurationKeys.CHOOSE_APPOINTMENT_TIME]: {
    path: 'appointment-time-table',
    virtualPath: 'choose-an-appointment-time',
    label: Labels.chooseTime
  },
  CONFIRM_MEDICAL_HISTORY: {
    path: 'signup-or-confirm-med-history',
    virtualPath: 'confirm-medical-history',
    label: Labels.confirmMedicalHistory
  },
  SIGNUP_OR_LOGIN: {
    path: 'signup-or-confirm-med-history',
    virtualPath: 'signup-or-login',
    label: Labels.registerLogin
  },
  ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
  PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
  MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
  HEALTHCARE_CARD_DETAILS: {
    path: 'healthcare-cards',
    virtualPath: 'healthcare-details',
    label: Labels.healthcareDetails
  },
  REDEEM_BENEFIT_AND_CONSENT: {
    path: 'benefit-and-consent',
    virtualPath: 'confirm-your-appointment-details',
    label: Labels.consentQuestions
  },
  PATIENT_VERIFICATION: {
    path: 'patient-verification',
    virtualPath: 'patient-verification',
    label: Labels.patientVerification
  },
  PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
  [stepConfigurationKeys.CHAT_WITH_YOUR_PRACTITIONER]: {
    path: 'appointment-booked',
    virtualPath: 'appointment-booked',
    label: Labels.chatWithPractitioner
  }
} as const;

export type ServiceStepPathMapping = Record<
  | Exclude<
      ServiceType,
      | 'psychologist'
      | 'dietitian'
      | 'wellness-specialist'
      | 'sleep-specialist'
      | 'sleep-gp'
      | 'quickscriptpharm'
      | 'quickscriptdeliver'
    >
  | Extract<StepPathValue, 'speak-to-doctor'>
  | 'speak-to-fertility-consultant',
  string[]
>;
