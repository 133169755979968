<div class="sticky-top">
  <shared-header [parameter-sidenav]="false" [showGetStarted]="false"> </shared-header>
</div>

<mat-drawer-container>
  <mat-drawer-content id="main-scrolling-container">
    <main>
      <b2b-header *ngIf="b2bCustomerService.b2bCustomerPortalInfo | async"></b2b-header>
      <div class="bg-light px-3 py-3 b2b-subheader" *ngIf="b2bCustomerService.currentPatientBookingStatus != null">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="mb-0">{{ b2bCustomerService.getBookingStatusText }}</h2>
          </div>

          <div class="col-auto">
            <div
              class="button-wrapper d-inline-block mr-2"
              *ngIf="b2bCustomerService.currentPatientBookingStatus > B2BCustomerBookingStatus.PolicyNumber"
            >
              <button (click)="startOver()" mat-stroked-button color="accent">Start Over</button>
            </div>
            <div
              class="button-wrapper d-inline-block"
              *ngIf="b2bCustomerService.currentPatientBookingStatus > B2BCustomerBookingStatus.PolicyNumber"
            >
              <button (click)="moveBackPatientBookingStatus()" mat-stroked-button color="accent">
                <i class="fas fa-chevron-left mr-2"></i>Back
              </button>
            </div>
          </div>
        </div>
      </div>

      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>

<shared-footer class="mt-auto" [class.hide-footer]="isMobile"> </shared-footer>
