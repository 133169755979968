<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 pl-0">
      <p class="subtitle">{{ dialogData.subtitle }}</p>
      <p class="title">{{ dialogData.title }}</p>
    </div>
    <div class="col-2 pr-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="container-fluid text-primary modal-body">
    <div *ngIf="isLoading" class="my-5">
      <loading-spinner></loading-spinner>
    </div>
    <section [class.d-none]="isLoading">
      <video
        width="100%"
        height="auto"
        [src]="dialogData.videoSrc"
        autoplay>
      </video>
    </section>
    <section>
      <p *ngIf="dialogData.notes">{{ dialogData.notes }}</p>
    </section>
  </div>
  <div class="modal-footer bg-muted flex-nowrap single-button-height">
    <button mat-flat-button color="accent" class="btn-block m-0" (click)="onClose()">
      Close
    </button>
  </div>
</div>
