import { Injectable } from "@angular/core";
import { Functions } from '@app/shared/functions';
import { Constants } from "./constants";

@Injectable({
  providedIn: 'root',
})
export class InputValidationFunctions {
  constructor(
    private functions: Functions
  ) {}

  validateDeleteSlashMMYY(event: any): boolean {
    return this.validateDeleteSlash(event, new Set([3]));
  }

  validateDeleteSlashDDMMYYYY(event: any): boolean {
    return this.validateDeleteSlash(event, new Set([3, 6]));
  }

  validateDeleteSlash(event: any, slashIndexes: Set<number> ): boolean {
    // Allow backspaces and delete (including keypad delete)
    if (event?.keyCode === 8 || event?.keyCode === 46 || event?.keyCode === 190) {
      if (slashIndexes.has(event.currentTarget.value.length)) {
        // Using while loop as regex replace was not working for some reason
        while (event.currentTarget.value.includes('/')) {
          event.currentTarget.value = event.currentTarget.value.replace('/', '');
        }
      }
      return true;
    }

    return true;
  }

  validateNumberPreventDefault(event: any, allowPlus: boolean = false): boolean {
    const isValid: boolean = this.functions.validateNumber(event, allowPlus);

    if (!isValid) {
      event.preventDefault();
    }

    return isValid;
  }

  validateMobileNumber(event: any): boolean {
    const target: any = event?.target || event?.currentTarget;

    if (target) {
      const mobile: string = (target.value ?? '').trim();
      return Constants.mobilePhoneRegex.test(mobile);
    }

    return false;
  }
}
