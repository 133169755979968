<div class="modal-wrapper">
  <form [formGroup]="emailForm" (submit)="onSubmit()">
    <div class="modal-header container-fluid align-items-center">
      <div class="col-10 px-0">
        <div class="text-primary subtitle">Medical Certificate</div>
        <div *ngIf="!isConfirm" class="text-primary title">Send to Employer</div>
        <div *ngIf="isConfirm" class="text-primary title">Confirmation</div>
      </div>
      <div class="col-2 px-0">
        <button type="button" class="close" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div *ngIf="!isConfirm" class="modal-body container-fluid text-primary">
      <p class="col-12 text-justify px-0">
        We can send a copy of your medical certificate directly to your employer
        via email.
      </p>
      <mat-card appearance="outlined">
        <mat-card-subtitle class="text-secondary">
          Please enter your employer's email address:
        </mat-card-subtitle>
        <mat-card-content>
          <div class="form-container">
            <div class="form-group m-0">
              <mat-form-field appearance="outline" class="custom-field w-100">
                <input
                  formControlName="email"
                  id="email"
                  type="text"
                  matInput
                  maxlength="255"
                  autocomplete="email"
                  required />
                <mat-error *ngIf="email.invalid && email.errors && (email.touched || email.dirty)">
                  <span *ngIf="email.hasError('required')">Email address is required</span>
                  <span *ngIf="email.hasError('pattern')">Email format is not valid</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="isConfirm" class="modal-body-multiple container-fluid text-primary">
      <p class="col-12 px-0 m-0">
        A copy of your medical certificate has been sent to your employer.
        Please ask your employer to check their junk mail if they have not received
        it.<br/><br/><strong>Sent to: {{ email.value }}</strong>
      </p>
    </div>
    <div *ngIf="!isConfirm" class="modal-footer container-fluid bg-muted flex-nowrap">
      <div *ngIf="!isMobile" class="row m-0 w-100">
        <div class="first-button col-xl-6 col-xs-12">
          <button
            mat-stroked-button
            type="button"
            color="accent"
            class="btn-block"
            (click)="onClose()">
            Close
          </button>
        </div>
        <div class="second-button col-xl-6 col-xs-12">
          <button
            mat-flat-button
            type="submit"
            color="accent"
            class="btn-block"
            [disabled]="emailForm.invalid || isLoading || certificateSent">
            <span *ngIf="certificateSent">Medical Certificate successfully sent</span>
            <span *ngIf="!certificateSent">Send</span>
          </button>
        </div>
      </div>
      <div *ngIf="isMobile" class="row m-0 w-100">
        <div class="first-button col-xl-6 col-xs-12">
          <button
            mat-flat-button
            type="submit"
            color="accent"
            class="btn-block"
            [disabled]="emailForm.invalid || isLoading || certificateSent">
            <span *ngIf="certificateSent">Medical Certificate successfully sent</span>
            <span *ngIf="!certificateSent">Send</span>
          </button>
        </div>
        <div class="second-button col-xl-6 col-xs-12">
          <button
            mat-stroked-button
            type="button"
            color="accent"
            class="btn-block"
            (click)="onClose()">
            Close
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isConfirm" class="modal-footer container-fluid bg-muted flex-nowrap">
      <button
        mat-flat-button
        type="button"
        color="accent"
        class="btn-block"
        (click)="onClose()">
        Close
      </button>
    </div>
  </form>
</div>
