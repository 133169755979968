import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorMessageModalComponent } from '../components/error-message-modal/error-message-modal.component';
import { ErrorModalData } from '../models/error-data';
import { ModalKeeperService } from './modal-keeper-service';
import { Functions } from '../functions';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  constructor(
    private functions: Functions,
    private dialog: MatDialog,
    private modalKeeperService: ModalKeeperService
  ) {}

  showErrorMessage(
    message: string,
    dialogData: ErrorModalData = {},
    resultCallbackFn?: Function,
    relatedModals?: string[]
  ): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'An issue has occurred',
      message,
      isClose: true,
      ...dialogData
    };
    dialogConfig.maxWidth = 573;

    if (this.modalKeeperService.isModalOpenAndRelatedModalsClosed(dialogConfig.data.title, dialogConfig.data.message)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(
      dialogConfig.data.title,
      dialogConfig.data.message,
      'ErrorModalService',
      relatedModals
    );

    this.dialog
      .open(ErrorMessageModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(
          dialogConfig.data.title,
          dialogConfig.data.message,
          'ErrorModalService',
          !result
        );

        if (resultCallbackFn) {
          resultCallbackFn(result);
        }
        this.functions.triggerResizeEvent();
      });
  }
}
