import { Injectable } from '@angular/core';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeFunctions {
  constructor() {}

  getDisplayFriendlyServiceTypeName(serviceType: string): string {
    switch (serviceType) {
      case Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY:
      case Constants.SERVICE_TYPE.QUICKSCRIPT_DELIVERY:
        return 'QuickScript order';
      case Constants.SERVICE_TYPE.MENTAL_HEALTH:
        return 'mental health appointment';
      case Constants.SERVICE_TYPE.WEIGHT_LOSS:
        return 'weight loss appointment';
      case Constants.SERVICE_TYPE.DOCTOR:
      case Constants.SERVICE_TYPE.BULKBILL_GP:
        return 'doctor appointment';
      default:
        return 'appointment';
    }
  }

  getAppointmentRequiredForMedicationCategoryMessage(medicationCategory: string): string {
    return `A <strong>${medicationCategory}</strong> appointment is required for the selected medication.`;
  }
}
