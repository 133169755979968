<div class="container-fluid">
  <div class="row content-section mt-4">
    <div class="col-12">
      <mat-card appearance="outlined" class="card-wrapper mb-5">
        <mat-card-content class="content">
          <div class="form-content">
            <div class="row">
              <div class="col-12">
                <loading-spinner *ngIf="loading"></loading-spinner>
                <p *ngIf="!loading && patients && !patients?.length">
                  No patients available for this
                  {{
                    b2bCustomerService.getPatientBookingSource === B2BBookingPatientInputType.policy
                      ? 'membership / policy number'
                      : 'email address'
                  }}
                </p>
                <table class="table" *ngIf="!loading && patients?.length">
                  <thead>
                    <th class="px-3 py-2">Name</th>
                    <th class="px-3 py-2 text-center">Select</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let patient of patients">
                      <td class="px-3 py-2">{{ patient.name }}</td>
                      <td class="px-3 py-2 button-wrapper">
                        <button
                          (click)="selectPatient(patient)"
                          [disabled]="isSubmitting"
                          class="save-btn"
                          mat-flat-button
                          color="accent"
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" *ngIf="!loading">
              <div class="col-12 col-sm-3 col-md-2 button-wrapper">
                <button
                  (click)="patientMissing()"
                  [disabled]="isSubmitting"
                  class="save-btn mt-4"
                  mat-stroked-button
                  color="accent"
                >
                  {{ patients && !patients?.length ? 'Add New Patient' : 'Patient Not In List' }}
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
