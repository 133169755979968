export class B2BCustomerPortalInfo {
  b2bCustomerId: string;
  b2bCustomerName: string;
  logoUrl: string;
  roles: B2BCustomerPortalAvailableRole[];
  availableBookingServiceTypes: string[];

  constructor(data: any) {
    this.b2bCustomerId = data.b2BCustomerId;
    this.b2bCustomerName = data.b2BCustomerName;
    this.logoUrl = data.logoUrl;
    this.roles =
      data.roles?.map(
        (action: string) => B2BCustomerPortalAvailableRole[action as keyof typeof B2BCustomerPortalAvailableRole]
      ) || [];
    this.availableBookingServiceTypes = data.availableBookingServiceTypes;
  }
}

export enum B2BCustomerPortalAvailableRole {
  CustomerBooking = 'CustomerBooking'
}
