<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div  class="col-10 pl-0">
      <div class="text-primary my-2 subtitle">Prescription</div>
      <div *ngIf="!isResent" class="text-primary title">Resend to Pharmacy</div>
      <div *ngIf="isResent" class="text-primary title">Confirmation</div>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <ng-container *ngIf="isLoading; else loaded">
    <div class="m-5">
      <loading-spinner></loading-spinner>
    </div>
  </ng-container>
  <ng-template #loaded>
    <div *ngIf="!isUsed && !isResent" class="modal-body shorter container-fluid text-secondary">
      <p class="col-12">
        If your pharmacist has not received your prescription via fax or email,
        you can resend it here.
        If your medication has already been dispensed, this option is not available.
      </p>
      <div class="gray-box">
        <div class="col-12">
          <div class="title">Has your medication already been dispensed?</div>
        </div>
        <div class="d-flex col-12">
          <div class="col-6 pl-xl-0 custom-padding-right action-buttons">
            <button
              mat-flat-button
              color="accent"
              class="text-wrap btn-block condition"
              [disabled]="isLoading || prescriptionSent"
              (click)="resendScript()">
              No
            </button>
          </div>
          <div class="col-6 pr-xl-0 custom-padding-left action-buttons">
            <button
              mat-stroked-button
              color="accent"
              class="text-wrap btn-block condition"
              (click)="onYesClick()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isUsed && !isResent" class="modal-body default container-fluid text-secondary">
      <p class="col-12">
        As your medication has already been dispensed from this prescription we cannot
        resend it to your pharmacy.  Please check with your pharmacist.  Or would you like
        to renew your prescription instead?
      </p>
    </div>
    <div *ngIf="isResent" class="modal-body default container-fluid text-secondary">
      <p class="col-12">We have resent a copy of your prescription to the pharmacy.</p>
    </div>
  </ng-template>

  <div *ngIf="isUsed" class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="row">
      <div class="col-6 action-buttons">
        <button
          mat-stroked-button
          color="accent"
          class="text-wrap btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
      <div class="col-6 action-buttons">
        <button
          mat-flat-button
          color="accent"
          class="text-wrap btn-block"
          (click)="onClose('renew')">
          Renew Prescription
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isUsed && isResent" class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="row">
      <div class="col-12 action-buttons">
        <button
          mat-flat-button
          color="accent"
          class="text-wrap btn-block"
          (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
