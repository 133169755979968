import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { AnalyticsCustomEventDTO } from '../models/analyticsCustomEventDTO';
import { ECommerceRefundDTO } from '../models/eCommerceRefundDTO';
import { ECommerceTransactionDTO } from '../models/eCommerceTransactionDTO';
import { AnalyticsPageViewDTO } from '../models/analyticsPageViewDTO';
import { AIContext, AppInsightsService } from './appinsights.service';
import { AnalyticsIDsResponseDTO } from '../models/analyticsIDsResponseDTO';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { PromiseHelperService } from './promise-helper.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsProxyService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private aiContext: AIContext;

  private cachedAnalyticsIds: AnalyticsIDsResponseDTO = null;

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private aiService: AppInsightsService,
    private storageLocal: LocalStorageService,
    private storageSession: SessionStorageService,
    private promiseHelperService: PromiseHelperService
  ) {
    this.aiContext = this.aiService.createContext('AnalyticsProxyService');
  }

  // https://api3.doctorsondemand.com.au/api/v1/ssa/ecommerce
  async sendEcommerceTransaction(eCommerceDTO: ECommerceTransactionDTO): Promise<boolean> {
    const ids: AnalyticsIDsResponseDTO = await this.getSSAIDs();

    if (!eCommerceDTO.client_id) {
      eCommerceDTO.client_id = ids.clientID;
    }
    if (!eCommerceDTO.session_id) {
      eCommerceDTO.session_id = ids.sessionID;
    }

    this.aiContext.info('sendEcommerceTransaction', { event: eCommerceDTO });

    let encodedPayload: string;

    try {
      encodedPayload = btoa(JSON.stringify(eCommerceDTO));
    } catch (err: any) {
      this.aiContext.error('EncodeEcommerceTransaction', { error: err?.message || 'Data encoding failed!' });
      return Promise.resolve(false);
    }

    if (!encodedPayload) {
      return Promise.resolve(false);
    }

    return await this.http
      .post(`${this.url}/ssa/ecommerce`, { data: encodedPayload })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          return Boolean(response.response);
        }
        this.aiContext.error('SeverSideAnalyticsNonSuccess', { type: 'sendEcommerceTransaction', response: response });
        return false;
      })
      .catch((err: any) => {
        this.aiContext.trackException(err, 'SeverSideAnalyticsNonSuccess', { type: 'sendEcommerceTransaction' });
        return false;
      });
  }

  // https://api3.doctorsondemand.com.au/api/v1/ssa/event
  async sendCustomGA4Event(eventDTO: AnalyticsCustomEventDTO): Promise<boolean> {
    const ids: AnalyticsIDsResponseDTO = await this.getSSAIDs();

    if (!eventDTO.client_id) {
      eventDTO.client_id = ids.clientID;
    }
    if (!eventDTO.session_id) {
      eventDTO.session_id = ids.sessionID;
    }

    this.aiContext.info('sendCustomEvent', { event: eventDTO });

    let encodedPayload: string;

    try {
      encodedPayload = btoa(JSON.stringify(eventDTO));
    } catch (err: any) {
      this.aiContext.error('EncodeAnalyticsCustomEvent', { error: err?.message || 'Data encoding failed!' });
      return Promise.resolve(false);
    }

    if (!encodedPayload) {
      return Promise.resolve(false);
    }

    return await this.http
      .post(`${this.url}/ssa/event`, { data: encodedPayload })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          return Boolean(response.response);
        }
        this.aiContext.error('SeverSideAnalyticsNonSuccess', { type: 'sendCustomEvent', response: response });
        return false;
      })
      .catch((err: any) => {
        this.aiContext.trackException(err, 'SeverSideAnalyticsNonSuccess', { type: 'sendCustomEvent' });
        return false;
      });
  }

  // https://api3.doctorsondemand.com.au/api/v1/ssa/pageview
  async sendPageView(pageViewDTO: AnalyticsPageViewDTO): Promise<boolean> {
    const ids: AnalyticsIDsResponseDTO = await this.getSSAIDs();

    if (!pageViewDTO.client_id) {
      pageViewDTO.client_id = ids.clientID;
    }
    if (!pageViewDTO.session_id) {
      pageViewDTO.session_id = ids.sessionID;
    }

    this.aiContext.info('sendPageView', { event: pageViewDTO });

    let encodedPayload: string;

    try {
      encodedPayload = btoa(JSON.stringify(pageViewDTO));
    } catch (err: any) {
      this.aiContext.error('EncodeAnalyticsCustomEvent', { error: err?.message || 'Data encoding failed!' });
      return Promise.resolve(false);
    }

    if (!encodedPayload) {
      return Promise.resolve(false);
    }

    return this.http
      .post(`${this.url}/ssa/pageview`, { data: encodedPayload })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          return Boolean(response.response);
        }
        this.aiContext.error('SeverSideAnalyticsNonSuccess', { type: 'sendPageView', response: response });
        return false;
      })
      .catch((err: any) => {
        this.aiContext.trackException(err, 'SeverSideAnalyticsNonSuccess', { type: 'sendPageView' });
        return false;
      });
  }

  // https://api3.doctorsondemand.com.au/api/v1/ssa/refund
  async sendEcommerceRefund(refundDTO: ECommerceRefundDTO): Promise<boolean> {
    const ids: AnalyticsIDsResponseDTO = await this.getSSAIDs();

    if (!refundDTO.client_id) {
      refundDTO.client_id = ids.clientID;
    }
    if (!refundDTO.session_id) {
      refundDTO.session_id = ids.sessionID;
    }

    this.aiContext.info('sendEcommerceRefund', { event: refundDTO });

    let encodedPayload: string;

    try {
      encodedPayload = btoa(JSON.stringify(refundDTO));
    } catch (err: any) {
      this.aiContext.error('EncodeAnalyticsRefundEvent', { error: err?.message || 'Data encoding failed!' });
      return Promise.resolve(false);
    }

    if (!encodedPayload) {
      return Promise.resolve(false);
    }

    return this.http
      .post(`${this.url}/ssa/refund`, { data: encodedPayload })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          return Boolean(response.response);
        }
        this.aiContext.error('SeverSideAnalyticsNonSuccess', { type: 'sendEcommerceRefund', response: response });
        return false;
      })
      .catch((err: any) => {
        this.aiContext.trackException(err, 'SeverSideAnalyticsNonSuccess', { type: 'sendEcommerceRefund' });
        return false;
      });
  }

  /**
   * @async
   * @function getSSAIDs
   * @description Get Server Side Analytics generated IDs for the current Google Analytics session
   *
   * @returns {Promise<AnalyticsIDsResponseDTO>} client_id and session_id to use for the current Google Analytics session
   */
  async getSSAIDs(): Promise<AnalyticsIDsResponseDTO> {
    if (this.cachedAnalyticsIds !== null) {
      return this.cachedAnalyticsIds;
    }

    const cookieIDs: AnalyticsIDsResponseDTO = this.getSSAIDsFromGACookies();

    if (cookieIDs !== null) {
      this.cachedAnalyticsIds = cookieIDs;
      return cookieIDs;
    }

    let clientID: string = null;
    let sessionID: string = null;

    try {
      try {
        clientID = this.storageLocal.retrieve(Constants.LocalStorage_Key.ssaClientId);
        sessionID = this.storageSession.retrieve(Constants.LocalStorage_Key.ssaSessionId);
      } catch (err: any) {
        console.error('Error retrieving GA session ids from storage. Error:', this.functions.getErrorMessage(err));
      }

      if (!clientID || !sessionID) {
        this.aiContext.info('ObtainingIDs');

        let ids: AnalyticsIDsResponseDTO = await this.generateSSAIDs();

        if (!clientID && ids?.clientID) {
          clientID = ids.clientID;
          try {
            this.storageLocal.store(Constants.LocalStorage_Key.ssaClientId, clientID);
            console.log('(localStorage) GA client_id:', Constants.LocalStorage_Key.ssaClientId);
          } catch (err: any) {
            console.error('Could not save GA client_id to local storage. Error:', this.functions.getErrorMessage(err));
          }
        }

        if (!sessionID && ids?.sessionID) {
          sessionID = ids.sessionID;
          try {
            this.storageSession.store(Constants.LocalStorage_Key.ssaSessionId, sessionID);
            console.log('(sessionStorage) GA session_id:', Constants.LocalStorage_Key.ssaSessionId);
          } catch (err: any) {
            console.error('Could not save GA session_id to session storage. Error:', this.functions.getErrorMessage(err));
          }
        }
      }
    } catch (err: any) {
      console.warn('[SSA]', this.functions.getErrorMessage(err));
      this.aiContext.trackException(err);
    }

    this.cachedAnalyticsIds = {
      clientID,
      sessionID
    };

    return this.cachedAnalyticsIds;
  }

  /**
   * @function generateSSAIDs
   * @description Generate new session identifiers for Google Analytics
   * https://api3.doctorsondemand.com.au/api/v1/ssa/ids
   *
   * @returns {Promise<AnalyticsIDsResponseDTO|null>}
   */
  generateSSAIDs(): Promise<AnalyticsIDsResponseDTO|null> {
    const promiseStorageKey: string = 'googleAnalyticsSessionIds';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      null,
      Constants.API_Polling_Times.googleAnalyticsSessionIds_SecondsBetweenRequests,
    );

    if (!returnExistingPromise) {
      const newPromise: Promise<AnalyticsIDsResponseDTO|null> = this.http
        .get(`${this.url}/ssa/ids`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.response) {
            return response.response as AnalyticsIDsResponseDTO;
          }

          this.aiContext.error('FailedToObtainIDs', { reason: 'NonSuccessResponse' });

          return null;
        })
        .catch((ex: any) => {
          this.aiContext.trackException(ex, 'FailedToObtainIDs');
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

        this.promiseHelperService.storePromise<AnalyticsIDsResponseDTO|null>(promiseStorageKey, newPromise);
      }

      return this.promiseHelperService.getPromiseByKey<AnalyticsIDsResponseDTO|null>(promiseStorageKey);
  }

  /**
   * @function getSSAIDsFromGACookies
   * @description Retrieve the Google Analytics session identifiers (session_id and client_id) from a _ga cookie
   *
   * @returns {AnalyticsIDsResponseDTO}
   */
  getSSAIDsFromGACookies(): AnalyticsIDsResponseDTO {
    try {
      const cookies: Record<string, string> = this.functions.cookieToRecordObject(document.cookie);
      const gaCookieName: string = '_ga';
      const ga4CookieName: string = `_ga_${environment.analytics.ga4.measurementId.substring(2)}`;

      if (!cookies[gaCookieName] || !cookies[ga4CookieName]) {
        this.aiContext.info('ParseGACookies', {
          success: false,
          reason: 'MissingCookies',
          hasGA: !!cookies[gaCookieName],
          hasGA4: !!cookies[ga4CookieName],
          gaCookieName,
          ga4CookieName
        });
        return null;
      }

      const gaParts: string[] = cookies[gaCookieName].split('.');
      const ga4Parts: string[] = cookies[ga4CookieName].split('.');

      const ids: AnalyticsIDsResponseDTO = {
        clientID: `${gaParts[2]}.${gaParts[3]}`,
        sessionID: ga4Parts[2]
      };

      this.aiContext.info('ParseGACookies', {
        success: true,
        sessionID: ids.sessionID,
        clientID: ids.clientID
      });

      return ids;
    } catch (err: any) {
      this.aiContext.trackException(err, 'ParseGACookies', {
        success: false
      });
    }

    return null;
  }
}
