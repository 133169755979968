import { ContentChild, ElementRef, HostListener, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
})
export class TemplateCardComponent implements OnInit {
  @Input() withHeader: boolean;
  @Input() subtitleBottom: boolean;

  @ContentChild('content') content: ElementRef;

  innerWidth: any;
  isDesktop: boolean = false;

  constructor() {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;
    this.isDesktop = this.innerWidth >= 1200;
  }

  ngOnInit(): void {}
}
