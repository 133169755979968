export enum ActiveCampaignEventTriggeredBy {
  patient = 'Patient',
  practitioner = 'Practitioner',
  admin = 'Admin'
}

export class ActiveCampaignEventDTO {
  // session identifiers
  externalId?: string;
  id?: string;

  // patient
  patientId?: string;
  email?: string;
  mobile?: string;
  firstName?: string;
  lastName?: string;
  isLoggedIn: string;
  timezoneOffset?: number;
  hasMedicare?: string; // Yes/No

  // address
  city?: string;
  state?: string;
  postcode: string;

  // benefit
  policyId?: string;

  // appointment
  doctorName?: string;
  appointmentDateTimeUTC?: string; // ISO 8601 standard UTC. eg. 2022-12-23T13:33:33Z
  appointmentDateTimeLocal?: string; // ISO 8601 extended. eg. 2022-12-22T13:33:33+11:00
  cancellationReason?: string;
  followupId?: string;
  appointmentId?: string;

  // order (QS or NRT)
  orderId?: string;

  // service
  serviceType?: string;

  // medication
  isTakingMedication?: string; // Yes/No
  medicationId?: string;
  medicationCategory?: string;
  medication2Category?: string;
  medicationBrandName?: string; // NRT and QS
  medicationName?: string;
  medication2Name?: string;
  medicationDosage?: string;
  medication2Dosage?: string;
  medicationQuantity?: number;
  medication2Quantity?: number;
  medicationImageUrl?: string;
  medication2ImageUrl?: string;
  medicationPrice?: number;
  medication2Price?: number;
  medicationShipping?: number;

  // mental health
  mentalHealthPlanCreated?: string;
  mentalHealthPlanConsentToPsychPartner?: string;
  mentalHealthPlanSentToPsychPartner?: string;

  // smoking cessation
  prescriptionIsCompleted?: string; // Yes/No
  isHomeDelivery?: string; // Yes/No
  scConfirmed?: string; // Yes/No
  scOrderUrl?: string;
  supplyEndDate?: string;

  // event details
  eventName: string; // unique event name, eg. browse_quickscript
  eventDateTime: string; // ISO 8601 standard UTC. eg. 2022-12-23T13:33:33Z
  eventType: string; // eg. Quickscript, Appointment
  eventSubType: string; // eg. Quickscript HomeDelivery, Quickscript Pharmacy, Appointment Scheduled, Appointment On-Demand
  eventStatus: string; // eg. Browse, Start, Book, Complete
  eventTriggeredBy?: string; // eg. Patient, Admin
  url?: string; // route/url where the event was triggered
}
