import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Functions } from '../functions';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService implements ErrorHandler {
  constructor(
    private functions: Functions,
    private analytics: GoogleAnalyticsService
  ) {}

  /**
   * @function handleError
   * @description Provides a custom override for site-wide error handling
   *
   * @param {any} error Error object
   */
  handleError(error: any) {
    // DEBUG
    // console.warn('Unhandled JS error: ', error);

    if (error) {
      if (error instanceof HttpErrorResponse) {
        // This is now recorded within the HTTP Interceptor Service
        // this.analytics.recordHTTPError(this.functions.getErrorMessage(error));
      } else {
        this.analytics.recordJSError(this.functions.getErrorMessage(error));
      }
    } else {
      this.analytics.recordJSError('Unknown error occurred. No error object to parse.');
    }
  }
}
