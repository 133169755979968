import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { WhiteLabelConfigDTO } from '@app/shared/models/whitelabel/WhiteLabelConfigDTO';
import { ErrorMessageModalComponent } from '@src/app/shared/components/error-message-modal/error-message-modal.component';
import { Appointment } from '@src/app/shared/models/appointment';
import { ErrorModalData, ErrorTypes, ModalError, ModalType } from '@src/app/shared/models/error-data';
import { AIContext, AppInsightsService } from '@src/app/shared/services/appinsights.service';
import { ModalKeeperService } from '@src/app/shared/services/modal-keeper-service';
import { ModalsService } from '@src/app/shared/services/modals.service';
import { PatientService } from '@src/app/shared/services/patient.service';
import { WhitelabelService } from '@src/app/shared/services/whitelabel.service';
import { STEP_PATH } from '@src/app/shared/step-configuration';

@Injectable({
  providedIn: 'root'
})
export class AppointmentErrorsService {
  contextName: string = 'AppointmentErrorService';
  private aiContext: AIContext;

  constructor(
    private functions: Functions,
    private router: Router,
    private aiService: AppInsightsService,
    private dialog: MatDialog,
    private modalKeeperService: ModalKeeperService,
    private whitelabelService: WhitelabelService,
    private modalService: ModalsService
  ) {
    this.aiContext = this.aiService.createContext(this.contextName);
  }

  /**
   * @function getAppointmentError
   * @description Allows calls to the error handler from anywhere in an appointment flow.
   */
  getAppointmentError(
    appointment: Appointment,
    errorType?: ErrorTypes,
    error?: any,
    practitionerListSize: number = 0,
    whiteLabelConfiguration?: WhiteLabelConfigDTO
  ): ModalError {
    if (!appointment) {
      return null;
    }

    const { isHttpError, isUnspecifiedError, errorCode, errorSubCode, errorMessage } =
      this.functions.handleAPIResponseError(error);
    const isOnDemandPractitionerError: boolean = errorMessage?.indexOf('no on-demand practitioners available') !== -1;
    const response = new ModalError();

    response.success = !errorMessage;

    // default response
    response.modalData = {
      title: Constants.MODAL_TITLES.issueCreatingAppointment,
      isClose: false
    };

    switch (errorType) {
      case ErrorTypes.pricing:
        if (errorCode === Constants.API_ERROR_CODES.PRICING_CHANGED) {
          response.modalData.title = Constants.MODAL_TITLES.appointmentPricingChanged;
          response.modalData.message = errorMessage;
          response.modalData.isClose = true;
        } else if (errorCode === Constants.API_ERROR_CODES.PRACTITIONER_UNAVAILABLE) {
          if (!appointment?.appointmentId) {
            if (appointment.isOnDemand || isOnDemandPractitionerError) {
              response.modalData.title = Constants.MODAL_TITLES.onDemandPractitionerNotAvailable;
              response.modalData.message = Constants.ErrorMessages.ONDEMAND_PRACTITIONER_NOT_AVAILABLE;
            } else {
              response.modalData.title = Constants.MODAL_TITLES.sessionTimeUnavailable;
              response.modalData.message = `Selected practitioner is not available at the specified session time.<br/><br/>
                Please schedule your appointment for another time${
                  !!practitionerListSize ? ' or select a different practitioner' : ''
                }.`;
            }
            response.modalData.isRescheduleAppointment = true;
            response.modalData.isDoctorSelect = !!practitionerListSize || appointment.isOnDemand;
            response.modalData.hideHeaderCloseButton = true;
          } else {
            response.modalData.title = Constants.MODAL_TITLES.appointmentAlreadyPaidFor;
            response.modalData.message = `This appointment has already been paid for. Please proceed to the Dashboard
              to see your current appointment bookings.`;
            response.modalData.isClose = true;
            response.modalData.showDashboardButton = true;
          }
        } else if (errorSubCode === Constants.API_ERROR_CODES.ONDEMAND_QUEUE_EXPIRED) {
          if (appointment?.isOnDemand || isOnDemandPractitionerError) {
            response.modalData.title = Constants.MODAL_TITLES.onDemandPractitionerNotAvailable;
            response.modalData.message = `Apologies, this practitioner is no longer available for
              on-demand appointments.<br/><br/>Please select a different practitioner to proceed with your booking.`;
            response.modalData.isRescheduleAppointment = true;
            response.modalData.isDoctorSelect = true;
            // response.modalData.isClose = true;
          } else {
            response.modalData.title = Constants.MODAL_TITLES.sessionTimeUnavailable;
            response.modalData.message = `This practitioner is no longer available at the specified session
              time.<br/><br/>Please re-schedule your appointment for another time.`;
            response.modalData.isRescheduleAppointment = true;
            // response.modalData.isDoctorSelect = true; // show change doctor button?
            response.modalData.isClose = true;
          }
        } else if (
          errorCode === Constants.API_ERROR_CODES.ON_DEMAND_PRACTITIONER_UNAVAILABLE ||
          isOnDemandPractitionerError
        ) {
          response.modalData.title = Constants.MODAL_TITLES.onDemandPractitionerNotAvailable;
          response.modalData.message = `An on-demand practitioner is no longer available, please choose an alternate session time or
            select a different practitioner.`;
          response.modalData.isRescheduleAppointment = true;
          response.modalData.isDoctorSelect = !!appointment?.practitionerId;
          response.modalData.isClose = false;
          response.modalData.hideHeaderCloseButton = true;
        } else if (
          errorCode === Constants.API_ERROR_CODES.INVALID_DATA &&
          errorMessage.indexOf(Constants.API_ERROR_DESCRIPTORS.SESSION_EXPIRED) !== -1
        ) {
          response.modalData.title = Constants.MODAL_TITLES.sessionTimeHasPassed;
          response.modalData.message = 'The selected session time is in the past. Please choose another time slot.';
          response.modalData.isRescheduleAppointment = true;
          response.modalData.isClose = true;
        } else if (
          errorCode === Constants.API_ERROR_CODES.CANT_CREATE_APPOINTMENT_NO_PRACTITIONER_AVAILABLE ||
          errorMessage.indexOf(Constants.API_ERROR_DESCRIPTORS.NO_PRACTITIONERS_AVAILABLE) !== -1
        ) {
          response.modalData.title = Constants.MODAL_TITLES.practitionerNotAvailable;
          response.modalData.message = errorMessage;
          response.modalData.isRescheduleAppointment = true;
          response.modalData.isDoctorSelect = true;
          response.modalData.isClose = false;
          response.modalData.hideHeaderCloseButton = true;
        } else if (errorCode == Constants.API_ERROR_CODES.NO_REDEMPTIONS_REMAINING) {
          response.modalData.title = Constants.MODAL_TITLES.noRedemptionsRemaining;
          response.modalData.message = Constants.ErrorMessages.NO_BENEFIT_REDEMPTIONS_REMAINING;
          response.modalData.showRemoveBenefitButton = true;
          response.modalData.hideHeaderCloseButton = true;

          // This error occurs if we've already created this appointment and are trying to retrieve
          // appointment pricing after the fact. The error can be ignored and not shown to the user.
        } else if (isHttpError && isUnspecifiedError) {
          // name: "HttpErrorResponse"
          // ok: false
          // status: 0
          // statusText: "Unknown Error"
          response.modalData.title = Constants.MODAL_TITLES.issueWithAppointmentPricing;
          response.modalData.message = Constants.ErrorMessages.APPOINTMENT_PRICING_ERROR;
          response.modalData.showDashboardButton = true;
          response.modalData.isClose = true;

          console.log(
            'Attempting to retrieve appointment pricing after appointment has been created! appointmentId:',
            appointment?.appointmentId
          );
        } else {
          response.modalData.title = Constants.MODAL_TITLES.issueWithAppointmentPricing;
          response.modalData.message = errorMessage;
          response.modalData.isClose = true;
        }

        break;

      case ErrorTypes.booking:
        switch (errorCode) {
          case Constants.API_ERROR_CODES.PRACTITIONER_UNAVAILABLE:
            if (!appointment?.appointmentId) {
              if (appointment.isOnDemand || isOnDemandPractitionerError) {
                response.modalData.title = Constants.MODAL_TITLES.onDemandPractitionerNotAvailable;
                response.modalData.message = Constants.ErrorMessages.ONDEMAND_PRACTITIONER_NOT_AVAILABLE;
              } else {
                response.modalData.title = Constants.MODAL_TITLES.practitionersUnavailable;
                response.modalData.message = `Apologies, this practitioner is not available at the chosen session
                  time.<br/><br/>Please re-schedule your appointment for another
                  time${!!practitionerListSize ? ' or select a different practitioner' : ''}.`;
              }
              response.modalData.isRescheduleAppointment = true;
              response.modalData.isDoctorSelect = !!practitionerListSize || appointment.isOnDemand;
              response.modalData.hideHeaderCloseButton = true;
            }

            break;
          case Constants.API_ERROR_CODES.CANT_CREATE_APPOINTMENT_NO_PRACTITIONER_AVAILABLE:
            if (!appointment.appointmentId) {
              response.modalData.title = Constants.MODAL_TITLES.practitionersUnavailable;
              response.modalData.message = `Apologies, there are no longer any practitioners available at the
                chosen session time.<br/><br/>Please re-schedule your appointment for another time.`;
              response.modalData.isRescheduleAppointment = true;
            }

            break;
          case Constants.API_ERROR_CODES.PAYMENT_FAILED:
            response.modalData.title = Constants.MODAL_TITLES.issueWithPayment;
            response.modalData.message = errorMessage;
            response.modalData.isClose = true;

            break;

          case Constants.API_ERROR_CODES.MEDICARE_REQUIRED_FOR_POLICY:
            response.modalData.title = Constants.MODAL_TITLES.medicareCardRequired;
            response.modalData.message = `Medicare discount policy is applied but no valid medicare card details have been provided.
              <br/><br/>To proceed, please update your Medicare card details, or remove the applied benefit
              (you will be required to pay the full appointment price).`;
            response.modalData.showRemoveBenefitButton = true;
            response.modalData.updateHealthcareIdentifiers = true;
            response.modalData.hideHeaderCloseButton = true;

            break;

          case Constants.API_ERROR_CODES.POLICY_MAX_NUMBER_OF_USES_EXCEEDED:
          case Constants.API_ERROR_CODES.POLICY_METADATA_TOKEN_REQUIRED:
            if (whiteLabelConfiguration.urls?.policyTokenValidationFailed && !whiteLabelConfiguration.isDefault) {
              response.modalType = ModalType.confirmationDialog;
              response.modalData.title = Constants.MODAL_TITLES.policyValidationRequired;
              response.modalData.message = `You have already booked an appointment with your policy. Please check your dashboard for
                existing appointments.<br/><br/>If you need to create another appointment, please click
                the link below to revalidate your policy details.<br/><br/>
                <a href='${whiteLabelConfiguration?.urls?.policyTokenValidationFailed}'>Re-validate Policy</a>`;
              response.modalData.customCloseButtonText = 'Dashboard';
              response.customCallbackFn = (result: any) => {
                if (result) {
                  this.router.navigate([STEP_PATH.DASHBOARD]);
                }
              };
            } else {
              response.modalData.title = Constants.MODAL_TITLES.issueCreatingAppointment;
              response.modalData.message = Constants.ErrorMessages.APPOINTMENT_BOOKING_ERROR;
              response.modalData.showDashboardButton = true;
              response.modalData.isClose = true;
            }
            break;

          case Constants.API_ERROR_CODES.INVALID_POLICY_METADATA_TOKEN:
            if (whiteLabelConfiguration?.urls?.policyTokenValidationFailed && !whiteLabelConfiguration?.isDefault) {
              response.modalType = ModalType.confirmationDialog;
              response.modalData.title = Constants.MODAL_TITLES.policyValidationRequired;
              response.modalData.message = `The supplied policy is not valid for this request. Please re-validate
                your ${whiteLabelConfiguration.name} policy to continue.`;
              response.modalData.customCloseButtonText = 'Re-validate Policy';
              response.customCallbackFn = (result: any) => {
                if (result) {
                  window.location.href = whiteLabelConfiguration.urls.policyTokenValidationFailed;
                }
              };
            } else {
              response.modalData.title = Constants.MODAL_TITLES.issueCreatingAppointment;
              response.modalData.message = Constants.ErrorMessages.APPOINTMENT_BOOKING_ERROR;
              response.modalData.showDashboardButton = true;
              response.modalData.isClose = true;
            }
            break;

          case Constants.API_ERROR_CODES.POLICY_CONSTRAINTS_VIOLATED:
            response.modalType = ModalType.errorModal;
            response.modalData.title = 'Patient Not Eligible';
            response.modalData.message = `The chosen patient isn't eligible for an appointment covered by the current policy.<br/><br/>Please make sure you select the same patient as on the ${this.whitelabelService.getWhiteLabelConfig().name} website.`;
            response.modalData.isClose = false;
            response.modalData.showSwitchPatient = true;
            response.customCallbackFn = (response: any) => {
              if (response === 'switchPatient') {
                this.modalService.switchPatient(null, (result: any) => {
                  if (result && result === 'newPatient') {
                    // TODO make this path dynamic (based on the service type)
                    this.router.navigate([STEP_PATH.APPOINTMENT, STEP_PATH.APPOINTMENT_TYPE_DOCTOR, STEP_PATH.SIGN_UP]);
                  }
                });
              }
            };
            break;

          default:
            response.modalData.title = Constants.MODAL_TITLES.issueCreatingAppointment;
            response.modalData.isClose = true;

            if (isOnDemandPractitionerError) {
              response.modalData.title = Constants.MODAL_TITLES.onDemandPractitionerNotAvailable;
              response.modalData.message = Constants.ErrorMessages.ONDEMAND_PRACTITIONER_NOT_AVAILABLE;
              response.modalData.isRescheduleAppointment = true;
              response.modalData.isDoctorSelect = !!practitionerListSize || appointment.isOnDemand;
            } else if (isHttpError && isUnspecifiedError) {
              response.modalData.message = Constants.ErrorMessages.APPOINTMENT_BOOKING_ERROR;
              response.modalData.showDashboardButton = true;
            } else {
              response.modalData.message = errorMessage;
            }
            break;
        }

        break;
    }

    this.aiContext.reportSuccessStatus(
      this.contextName,
      response?.success,
      response?.modalData?.message || errorMessage,
      { error } // original error object
    );

    return response;
  }

  /**
   * @function showErrorMessage
   * @description Publically accessible error message dialog (with no callback)
   *
   * @param {string} message
   * @param {any} [dialogData={}]
   */
  showErrorMessage(message: string, dialogData: any = {}): void {
    let dialogConfig: MatDialogConfig<ErrorModalData> = this.functions.getModalConfig();
    const title: string = Constants.MODAL_TITLES.issueWithAppointment;

    dialogConfig.data = {
      title,
      message,
      isClose: !dialogData?.newAppointment,
      ...dialogData
    };
    dialogConfig.maxWidth = 573;

    if (this.modalKeeperService.isModalOpenAndRelatedModalsClosed(dialogConfig.data.title, dialogConfig.data.message)) {
      return;
    }

    this.modalKeeperService.registerModalOpened(dialogConfig.data.title, dialogConfig.data.message, this.contextName, [
      Constants.MODAL_TITLES.changePractitioner,
      Constants.MODAL_TITLES.rescheduleAppointment,
      Constants.MODAL_TITLES.changeAppointmentTime,
      Constants.MODAL_TITLES.cancelAppointment,
      Constants.MODAL_TITLES.redeemABenefit
    ]);

    this.dialog
      .open(ErrorMessageModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: any) => {
        this.modalKeeperService.registerModalClosed(
          dialogConfig.data.title,
          dialogConfig.data.message,
          this.contextName,
          !result
        );
        this.functions.triggerResizeEvent();
      });
  }

  appointmentInvalid(callback: () => void): void {
    this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
      if (callback) {
        callback();
      }
      this.showErrorMessage('Cannot proceed. Appointment details are not available.');
    });
  }

  appointmentCancelled(callback: () => void): void {
    this.navigateAndExecuteCallback(callback, () => {
      this.showErrorMessage(
        `We're very sorry, we're unable to proceed with your consult at this time due to unforeseen circumstances.
        Our goal is to deliver the highest standard of care to you at all times, and we apologise that we
        haven't delivered that on this occasion.<br/><br/>This appointment has been cancelled and a refund will
        automatically be processed within 2-3 business days. If further assistance is needed, our patient concierge
        team can be contacted via <a href="mailto:info@doctorsondemand.com.au">info@doctorsondemand.com.au</a>, or
        via the Contact Us link on your dashboard.<br/><br/>In the meantime, you can re-book the next available
        appointment via the button below.`,
        {
          title: 'Appointment Cancelled',
          newAppointment: true
        }
      );
    });
  }

  appointmentPatientNotAttended(callback: () => void): void {
    this.navigateAndExecuteCallback(callback, () => {
      this.showErrorMessage(
        `It appears that you were unable to attend your consult. The appointment has now been closed.
        <br/><br/>Please contact us if you have any questions or concerns, or book a new appointment.`,
        {
          title: 'Appointment Closed',
          isContactUs: true,
          newAppointment: true,
          isClose: false,
          hideHeaderCloseButton: false
        }
      );
    });
  }

  appointmentExpired(callback: () => void): void {
    this.navigateAndExecuteCallback(callback, () => {
      this.showErrorMessage('Appointment time has elapsed. The appointment has expired.');
    });
  }

  private navigateAndExecuteCallback(callback: () => void, showMessageCallback: () => void): void {
    this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
      if (callback) {
        callback();
      }
      showMessageCallback();
    });
  }
}
