import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[dateOfBirthMask]',
})
export class DateOfBirthMaskDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed: string = input.value?.replace(/(\s+)|(\/)/g, '') || '';
    let numbers: string[] = [];

    if (trimmed.length > 10) {
      trimmed = trimmed.substring(0, 10);
    }

    if (trimmed.substring(0, 2) !== '') {
      numbers.push(trimmed.substring(0, 2));
    }

    if (trimmed.length >= 2) {
      numbers.push('/');
    }

    if (trimmed.substring(2, 4) !== '') {
      numbers.push(trimmed.substring(2, 4));
    }

    if (trimmed.length >= 4) {
      numbers.push('/');
    }

    if (trimmed.substring(4, 8) !== '') {
      numbers.push(trimmed.substring(4, 8));
    }

    input.value = numbers.join('');
  }
}

@NgModule({
  declarations: [DateOfBirthMaskDirective],
  exports: [DateOfBirthMaskDirective],
})
export class DateOfBirthMaskDirectiveModule {}
