import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@src/app/shared/constants';
import { B2BBookingPatientInputType, B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { first } from 'rxjs';
import { B2B_STEP_PATH } from '../../../business-portal-routing.module';
import {
  B2BCustomerBookingStatus,
  B2BCustomerPortalBookingPatient
} from '@src/app/shared/models/b2b-customer-portal/models';
import { PatientService } from '@src/app/shared/services/patient.service';
import { TimeZone } from '@src/app/shared/models/time-zone';
import { TimezoneService } from '@src/app/shared/services/timezone.service';

@Component({
  selector: 'business-portal-patient-booking-patient-add',
  templateUrl: './business-portal-patient-booking-patient-add.component.html',
  styleUrls: ['./business-portal-patient-booking-patient-add.component.scss']
})
export class BusinessPortalPatientBookingPatientAddComponent implements OnInit {
  formGroup: FormGroup;

  isSubmitting: boolean;

  invalidData: boolean;

  titles: string[];
  validPhone: boolean = true;

  timezoneOptions: TimeZone[] = [];

  B2BBookingPatientInputType = B2BBookingPatientInputType;

  constructor(
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private timezoneService: TimezoneService
  ) {}

  async ngOnInit(): Promise<void> {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientAdd);

    const titles: string[] = await this.b2bCustomerService.getTitles();
    this.titles = titles;

    this.createForm();
    this.getTimezones();
  }

  createForm(): void {
    const patient = this.b2bCustomerService.getPatient;

    const bookingSource: B2BBookingPatientInputType = this.b2bCustomerService.getPatientBookingSource;

    this.formGroup = this.formBuilder.group({
      email: [
        {
          value: patient?.email || this.b2bCustomerService.getPatientEmail || '',
          disabled: bookingSource === B2BBookingPatientInputType.email
        },
        [Validators.required, Validators.pattern(Constants.emailB2BCustomerPortalRegexString)]
      ],
      title: [patient?.title || null, [Validators.required]],
      firstName: [patient?.firstName, [Validators.required]],
      lastName: [patient?.lastName, [Validators.required]],
      gender: [patient?.gender, [Validators.required]],
      phone: [patient?.phone, [Validators.required, Validators.pattern(Constants.mobilePhoneRegexString)]],
      timezone: [patient?.timezone, [Validators.required]]
    });
  }

  getTimezones(): void {
    this.timezoneService.getTimezoneData().then((timeZones: TimeZone[]) => {
      if (Array.isArray(timeZones)) {
        this.timezoneOptions = timeZones;
      }
    });
  }

  get email() {
    return this.formGroup.get('email');
  }

  get title() {
    return this.formGroup.get('title');
  }

  get firstName() {
    return this.formGroup.get('firstName');
  }

  get lastName() {
    return this.formGroup.get('lastName');
  }

  get gender() {
    return this.formGroup.get('gender');
  }
  get phone() {
    return this.formGroup.get('phone');
  }

  get timezone() {
    return this.formGroup.get('timezone');
  }

  createPatient() {
    const timeZone: string = this.timezone.value;

    if (!this.formGroup.valid || !this.email.value || !timeZone) {
      this.invalidData = true;
      return;
    } else {
      this.formGroup.setErrors(null);
      this.formGroup.markAsPristine();
      this.invalidData = false;
    }

    this.validPhone = this.patientService.isMobilePhoneValid(this.phone.value);

    if (!this.validPhone) {
      return;
    }

    this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first()).subscribe(async (info) => {
      if (info) {
        this.isSubmitting = true;
        const response = await this.b2bCustomerService.addPatient(
          this.email.value,
          this.title.value,
          this.firstName.value,
          this.lastName.value,
          this.gender.value,
          this.phone.value,
          timeZone
        );

        this.invalidData = !response;
        this.isSubmitting = false;

        if (response) {
          const patient: B2BCustomerPortalBookingPatient = new B2BCustomerPortalBookingPatient({
            id: response,
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            name: `${this.lastName.value}, ${this.firstName.value}`,
            email: this.email.value,
            title: this.title.value,
            timezone: timeZone
          });
          this.b2bCustomerService.setPatient(patient);
          this.b2bCustomerService.setPatientManuallyTypedEmail(this.email.value);
          this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientSendCode);
          this.router.navigate(['../', B2B_STEP_PATH.PATIENT_CODE], { relativeTo: this.activatedRoute });
        } else {
        }
      } else {
        console.error('ERROR in createPatient');
      }
    });
  }
}
