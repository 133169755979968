export enum AppointmentStatus {
  APPOINTMENT_SCHEDULED = 0,
  APPOINTMENT_FINALISED = 1,
  PATIENT_JOINED = 2,
  DOCTOR_JOINED = 3,
  PATIENT_LEFT = 4,
  DOCTOR_LEFT = 5,
  DOCTOR_DELAYED = 6,
  DOCTOR_EXTEND = 7,
  CANCELLED_BY_PATIENT_NOT_ENOUGH_NOTICE = 8,
  CANCELLED_BY_PATIENT_ENOUGH_NOTICE = 9,
  CANCELLED_BY_DOCTOR = 10,
  CANCELLED_BY_ADMIN = 11,
  PATIENT_SELECT_FOLLOWUP = 12,
  PATIENT_DECLINE_FOLLOWUP = 13,
  CLOSED_BY_DOCTOR_LEGACY = 14,
  CLOSED_WITH_SCRIPT = 15,
  CLOSED_WITHOUT_SCRIPT = 16,
  CLOSED_WITHOUT_SCRIPT_FURTHER_TESTS_REQUIRED = 17,
  CLOSED_WITHOUT_SCRIPT_NON_PRESCRIPTION_TREATMENT = 18,
  CLOSED_WITHOUT_SCRIPT_PATIENT_NOT_SUITABLE = 19,
  CLOSED_WITHOUT_SCRIPT_PATIENT_WANTS_TO_THINK = 20,
  CLOSED_WITHOUT_SCRIPT_GP_REFERRAL_REQUIRED = 21,
  REFUNDED_BY_ADMIN = 22,
  MISSED_BY_DOCTOR = 23,
  MISSED_BY_PATIENT = 24,
  WAITING_ON_REQUEST = 25
}
