<div id="health-card-modal-wrapper" class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">Email Verification</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-secondary pt-0 pb-4">
    <div class="row m-0">
      <div class="col-12">
        <email-verification-form
          [emailAddress]="emailAddress"
          [prevVerified]="emailVerified"
          [signupMode]="true"
          (verified)="emailVerifiedActions($event)">
        </email-verification-form>
      </div>
    </div>
  </div>
  <div class="modal-footer container-fluid d-flex bg-muted">
    <div class="row m-0 w-100">
      <div class="col-6"></div>
      <div class="col-6">
        <button mat-stroked-button color="accent" class="btn-block text-wrap" (click)="onClose()">Close</button>
      </div>
    </div>
  </div>
</div>
