import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { CustomDimensions, CustomDimensionsDataLayer, PatientCustomDimensions } from '../models/customDimensions';
import { interval, Subject, Subscription } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { GeneralMedical } from '../models/general-medical';
import { Patient } from '../models/patient';
import { GeneralMedicalService } from './general-medical.service';
import { PatientService } from './patient.service';
import { Address } from '../models/address';
import { AddressService } from './address.service';
import { Benefit } from '../models/benefit';
import { AvailabilityService } from './availability.service';
import { AppointmentService } from './appointment.service';
// import { AvailabilityGroupsForDay } from '../models/availabilityGroupsForDay';
import { PractitionerService } from './practitioner.service';
import { PractitionerGender } from '../models/practitionerGender';
import { Practitioner } from '../models/practitioner';
import { CredentialsService } from '@app/core/services/credentials.service';
import { AvailabilitySearch } from '../models/availabilitySearch';
import { AvailabilityDateRangeStrings } from '../models/availability-date-range';
import { AppointmentAvailabilityData } from '../models/appointment-availability-data';
import { AvailabilityGroups } from '../models/availabilityGroups';
import { HotjarService } from './hotjar.service';
import { HttpClient } from '@angular/common/http';
import { IResponseAPI } from '../models/api-response';
import { WhitelabelService } from './whitelabel.service';
import { BMICalculation } from '../models/weightloss/BMICalculation';
import { environment } from '@env/environment';
import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import { AvailabilityGroupsForDay } from '../models/availabilityGroupsForDay';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsCustomDimensionsService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private subscription = new Subscription();

  private _customDimensionsChange: Subject<CustomDimensions> = new Subject<CustomDimensions>();
  public customDimensionsChangeObs = this._customDimensionsChange.asObservable();

  private _onDemandQueueSizeChange: Subject<number> = new Subject<number>();
  public onDemandQueueSizeChangeObs = this._onDemandQueueSizeChange.asObservable();

  private _allCustomDimensions: CustomDimensions;
  private _medicalHistoryProcessed: any = {};
  private _patientProfileProcessed: any = {};
  private _patientBenefitProcessed: boolean = false;

  currentPatient: Patient;
  currentPatientId: string;
  currentPatientMedicalHistory: GeneralMedical;
  currentPatientCustomDimensions: PatientCustomDimensions;
  listeningForUpdates: boolean = false;
  currentPatientTimeZoneOffsetHours: string;
  currentPatientHasMedicare: boolean;
  availabilityDataRetrieved: boolean;
  patientIsLoggedIn: boolean;
  appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;

  isNative: boolean = Boolean(Capacitor.isNativePlatform());
  mobilePlatform: string = Capacitor.getPlatform();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private hotjarService: HotjarService,
    private patientService: PatientService,
    private addressService: AddressService,
    private medicalHistoryService: GeneralMedicalService,
    private storage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private availabilityService: AvailabilityService,
    private appointmentService: AppointmentService,
    private practitionerService: PractitionerService,
    private credentialsService: CredentialsService,
    private whiteLabelService: WhitelabelService
  ) {
    // We will initialise this service from the App Component
    // this.init();
  }

  get allCustomDimensions() {
    // Retrieve custom dimensions from storage, if none, create a new CustomDimensions object
    if (!this._allCustomDimensions) {
      this._allCustomDimensions = this.retrieveCustomDimensions() ?? new CustomDimensions();
    }

    return this._allCustomDimensions;
  }

  set allCustomDimensions(customDimensions: CustomDimensions) {
    this._allCustomDimensions = customDimensions;
  }

  get medHistoryProcessed(): boolean {
    return this.currentPatientId ? Boolean(this._medicalHistoryProcessed[this.currentPatientId]) : false;
  }

  get patientProfileProcessed() {
    return this.currentPatientId ? Boolean(this._patientProfileProcessed[this.currentPatientId]) : false;
  }

  isMedicalHistoryProcessedForPatient(patientId: string): boolean {
    return Boolean(
      patientId &&
        typeof this._medicalHistoryProcessed === 'object' &&
        this._medicalHistoryProcessed[patientId] &&
        this.allCustomDimensions.patient &&
        this.allCustomDimensions.patient[patientId] &&
        typeof this.allCustomDimensions.patient[patientId].userDrinker !== 'undefined'
    );
  }

  isProfileProcessedForPatient(patientId: string): boolean {
    return Boolean(
      patientId &&
        typeof this._patientProfileProcessed === 'object' &&
        this._patientProfileProcessed[patientId] &&
        this.allCustomDimensions.patient &&
        this.allCustomDimensions.patient[patientId] &&
        this.allCustomDimensions.patient[patientId].userAgeAndGender // will always be set
    );
  }

  isBenefitProcessedForPatient(): boolean {
    return Boolean(this._patientBenefitProcessed && this.allCustomDimensions.userCustomerType);
  }

  resetCustomDimensionsProcessedStatus(): void {
    this._medicalHistoryProcessed = {};
    this._patientProfileProcessed = {};
    this._patientBenefitProcessed = false;
  }

  init(): void {
    // if (this.whiteLabelService.canProvideAppointments()) {
    this.appointmentServiceType =
      this.appointmentService.appointment?.serviceType || this.whiteLabelService.getPrimaryServiceType();
    // }

    // Current Patient
    if (this.patientService.patient?.patientId) {
      this.currentPatient = this.patientService.patient;
      this.currentPatientId = this.currentPatient.patientId;
    }

    // Patient's custom dimensions
    if (this.allCustomDimensions.patient && Object.keys(this.allCustomDimensions.patient).length) {
      if (this.currentPatientId && this.allCustomDimensions.patient[this.currentPatientId]) {
        this.currentPatientCustomDimensions = this.allCustomDimensions.patient[this.currentPatientId];
      }
    } else {
      this.allCustomDimensions.patient = {};
    }

    // Initialise patient's custom dimensions if they don't exist yet
    if (!(this.currentPatientCustomDimensions && Object.keys(this.currentPatientCustomDimensions).length)) {
      this.currentPatientCustomDimensions = new PatientCustomDimensions();
    }

    // Update patient-specific custom dimensions
    if (this.currentPatient && this.currentPatientId) {
      this.updateCurrentPatient(this.currentPatient);
    } else {
      this.allCustomDimensions.userRegisteredThisSession = null;
    }

    // Calculate onDemand queue size - first check session availabilities in storage,
    // if none stored then base the calculation on practitioner list (if available)
    const storedSessionTimes: any = this.appointmentService.storedAvailabilityGroupsForDay;
    if (storedSessionTimes) {
      this.calculateNextAppointmentAvailability(storedSessionTimes);
    }

    const practitionerList: Practitioner[] = this.practitionerService.storedPractitionerList;
    this.calculateOnDemandQueueSize(practitionerList);

    // Remove saved customerType from storage
    this.resetCustomerType();

    // Determine customer type from applied benefit
    if (this.patientService.getStoredBenefitCode() && !this.patientService.benefit) {
      this.patientService.retrieveBenefit().then((benefit: Benefit) => {
        this.updateCustomerType(benefit);
      });
    } else {
      this.updateCustomerType(this.patientService.benefit);
    }

    // Update local variables
    this.updateCurrentPatientTimeZone();
    this.updatePatientHasMedicare();

    // HotJar
    this.allCustomDimensions.HotjarId = this.hotjarService.getSessionIdFromCookie();

    // Get stats for on-demand availability, queue size and minutes until next appointment
    this.retrieveLatestAvailabilityData().then((success: boolean) => {
      if (!success) {
        // Are there any onDemand doctors available?
        this.updateOnDemandAppointmentAvailable();
      }
      // Store custom dimensions even if AvailabilityData API call fails
      this.updateAndStoreCustomDimensions(this.currentPatientId, this.currentPatientCustomDimensions);
    });
  }

  subscribeToUpdates(): void {
    // Make sure we're not subscribing to the same events twice
    if (!this.listeningForUpdates) {
      // PATIENT
      this.subscription.add(
        this.patientService.patientChangeObs.subscribe((patient: Patient) => {
          // console.log('[CUSTOM-DIMENSIONS] Patient updated observer...');
          if (patient?.patientId) {
            this.currentPatient = patient;
            this.currentPatientId = patient.patientId;
            this._patientProfileProcessed[patient.patientId] = false;
            // console.log('[CUSTOM-DIMENSIONS] this._patientProfileProcessed[' + patient.patientId + '] set to FALSE');
            this.updateCurrentPatient(patient);
          } else if (!this.credentialsService.isAuthenticated()) {
            this.currentPatient = null;
            this.currentPatientId = null;
            this._patientProfileProcessed = {};
            this.updateCurrentPatient(null);
          }
        })
      );

      // PATIENT OTHER
      this.subscription.add(
        this.patientService.patientOtherChangeObs.subscribe((patient: Patient) => {
          if (patient?.patientId) {
            this._patientProfileProcessed[patient.patientId] = false;
            this.updateCurrentPatient(patient);
          }
        })
      );

      // CREDENTIALS
      this.subscription.add(
        this.credentialsService.credentialsChangeObs.subscribe(() => {
          // console.log('[CUSTOM-DIMENSIONS] Credentials updated observer...');

          // Check if we have loaded the patient's profile
          const patientId: string = this.patientService.patient?.patientId || this.patientService.getStoredPatientId();

          // if (patientId) {
          //   this._patientProfileProcessed[patientId] = false;
          // } else {
          //   this._patientProfileProcessed = {};
          // }

          // this.updateLoggedInStatus();
          this.updateAndStoreCustomDimensions(patientId);
        })
      );

      // BENEFIT
      this.subscription.add(
        this.patientService.benefitChangeObs.subscribe((benefit: Benefit) => {
          this._patientBenefitProcessed = false;

          // Remove saved customerType from storage
          this.resetCustomerType();

          // Update customerType based on applied benefit
          this.updateCustomerType(benefit);

          this._customDimensionsChange.next(this.allCustomDimensions);
        })
      );

      // AVAILABILITY
      this.subscription.add(
        this.availabilityService.isPractitionerOnlineChangeObs.subscribe(() => {
          this.updateOnDemandAppointmentAvailable();
        })
      );

      // SESSION TIMES
      // this.subscription.add(
      //   this.appointmentService.sessionTimesChangeObs.subscribe((availabilities: any) => {
      //     console.log('[CUSTOM-DIMENSIONS-SERVICE] sessionTimesChangeObs triggered');
      //     this.calculateNextAppointmentAvailability(availabilities);
      //   })
      // );

      // PRACTITIONERS (on-demand queue size)
      // this.subscription.add(
      //   this.practitionerService.practitionerListChangeObs.subscribe((practitioners: Practitioner[]) => {
      //     console.log('[CUSTOM-DIMENSIONS-SERVICE] practitionerListChangeObs triggered');
      //     this.calculateOnDemandQueueSize(practitioners);
      //   })
      // );

      // GENERAL MEDICAL (Medical History)
      this.subscription.add(
        this.medicalHistoryService.medicalHistoryChangeObs.subscribe((patientId: string) => {
          if (patientId) {
            this._medicalHistoryProcessed[patientId] = false;
          } else {
            this._medicalHistoryProcessed = {};
          }

          this.initCurrentPatientMedicalHistory(patientId).then(() => {
            this.updateAndStoreCustomDimensions(patientId, this.currentPatientCustomDimensions);
          });
        })
      );

      // ON-DEMAND QUEUE SIZE (interval)
      this.subscription.add(
        interval(Constants.API_Polling_Times.customDimensionQueueSize * Constants.MILLISECONDS_IN_SECOND).subscribe(
          () => {
            if (this.availabilityDataRetrieved === false) {
              this.retrieveAvailablePractitioners();
            }
          }
        )
      );

      // SESSION TIMES (interval)
      this.subscription.add(
        interval(Constants.API_Polling_Times.customDimensionSessionTimes * Constants.MILLISECONDS_IN_SECOND).subscribe(
          () => {
            if (this.availabilityDataRetrieved === false) {
              if (this.availabilityService.isDoctorOnline) {
                this.updateNextAppointmentAvailability(0);
                this.updateOnDemandAppointmentAvailable(true);
              } else {
                this.retrieveSessionTimes();
              }
            }
          }
        )
      );

      // SESSION TIMES UPDATER (interval)
      this.subscription.add(
        interval(Constants.SECONDS_IN_MINUTE * Constants.MILLISECONDS_IN_SECOND).subscribe(() => {
          // this.updateAppointmentSessionTimes();

          if (this.availabilityDataRetrieved !== false) {
            this.retrieveLatestAvailabilityData().then((success: boolean) => {
              if (success) {
                this.updateAndStoreCustomDimensions(this.currentPatientId);
              }
            });
          }
        })
      );

      this.listeningForUpdates = true;
    }
  }

  /**
   * @function retrieveAvailablePractitioners
   * @description Retrieve practitioner availabilities for today and tomorrow and then calculate
   * onDemand appointment queue size
   */
  retrieveAvailablePractitioners(): void {
    let availabilityParameter: AvailabilitySearch = this.appointmentService.createAvailabilityParameter(
      this.appointmentServiceType,
      true, // onDemand
      null // Don't apply a policy
    );

    const dateParams: AvailabilityDateRangeStrings = this.getDateParamsForTodayAndTomorrow();

    availabilityParameter.startDatePatientLocal = dateParams.startDatePatientLocal;
    availabilityParameter.endDatePatientLocal = dateParams.endDatePatientLocal;
    availabilityParameter.shouldGetOnDemandQueueSizes = true;

    const startDate: moment.Moment = this.functions.getUTCMoment(
      availabilityParameter.timezoneOffSetHours,
      false,
      false,
      availabilityParameter.startDatePatientLocal
    );
    const endDate: moment.Moment = this.functions.getUTCMoment(
      availabilityParameter.timezoneOffSetHours,
      false,
      false,
      availabilityParameter.endDatePatientLocal
    );
    const now: moment.Moment = this.functions.getUTCMoment(availabilityParameter.timezoneOffSetHours);

    // is endDate in the past?
    if (endDate.diff(now, 'minutes') < 0) {
      return this.calculateOnDemandQueueSize([]);

      // is startDate in the past?
    } else if (startDate.diff(now, 'minutes') < 0) {
      availabilityParameter.startDatePatientLocal = this.functions.getUTCMomentString(
        availabilityParameter.timezoneOffSetHours,
        false,
        false,
        now
      );
    }

    this.practitionerService
      .getAvailabilityPractitioners(this.functions.removeEmpty(availabilityParameter), true, false)
      .then((practitioners: Practitioner[]) => {
        this.calculateOnDemandQueueSize(practitioners);
      })
      .catch(() => {
        this.updateOnDemandQueueSize(null);
      });
  }

  /**
   * @function retrieveSessionTimes
   * @description Retrieve session times for today and tomorrow and then calculate
   * next appointment availability (in minutes to next appointment)
   */
  retrieveSessionTimes(): void {
    let availabilityParameter: AvailabilitySearch = this.appointmentService.createAvailabilityParameter(
      this.appointmentServiceType,
      false, // onDemand
      null // Do not apply a policyId
    );

    const dateParams: AvailabilityDateRangeStrings = this.getDateParamsForTodayAndTomorrow();

    availabilityParameter.startDatePatientLocal = dateParams.startDatePatientLocal;
    availabilityParameter.endDatePatientLocal = dateParams.endDatePatientLocal;

    ///////////
    // DOC-3053 - Check that the start/end dates are not in the past
    ///////////
    const startDate: moment.Moment = this.functions.getUTCMoment(
      availabilityParameter.timezoneOffSetHours,
      false,
      false,
      availabilityParameter.startDatePatientLocal
    );
    const endDate: moment.Moment = this.functions.getUTCMoment(
      availabilityParameter.timezoneOffSetHours,
      false,
      false,
      availabilityParameter.endDatePatientLocal
    );
    const now: moment.Moment = this.functions.getUTCMoment(availabilityParameter.timezoneOffSetHours);

    // is endDate in the past?
    if (endDate.diff(now, 'minutes') < 0) {
      return this.calculateNextAppointmentAvailability(null);

      // is startDate in the past?
    } else if (startDate.diff(now, 'minutes') < 0) {
      availabilityParameter.startDatePatientLocal = this.functions.getUTCMomentString(
        availabilityParameter.timezoneOffSetHours,
        false,
        false,
        now
      );
    }
    ///////////

    let availabilityGroupsForDay: any = {};

    // console.log('[CUSTOM-DIMENSIONS-SERVICE] Retrieving Session Times: ', dateParams.startDatePatientLocal,
    //   ' => ', dateParams.endDatePatientLocal);

    this.appointmentService
      .getAvailability(availabilityParameter, true)
      .then((availabilityGroups: AvailabilityGroups[]) => {
        if (Array.isArray(availabilityGroups) && availabilityGroups.length) {
          availabilityGroups.forEach((groups: AvailabilityGroups) => {
            if (groups.date) {
              const shortDate: string = groups.date.substring(0, 10);
              groups.date = shortDate;
              availabilityGroupsForDay[shortDate] = groups.availabilityGroupsForDay;
            }
          });

          this.calculateNextAppointmentAvailability(availabilityGroupsForDay);
        }
      });
  }

  getDateParamsForTodayAndTomorrow(): AvailabilityDateRangeStrings {
    const offsetHourString: string =
      this.currentPatientTimeZoneOffsetHours ?? this.patientService.getPatientTimeZoneOffset();
    const numericTimezoneOffset: number = offsetHourString
      ? parseFloat(offsetHourString)
      : Constants.Default_TimeZone_Offset;
    const today: moment.Moment = this.functions.getUTCMoment(offsetHourString);
    const tomorrow: moment.Moment = today.clone().add(1, 'day');

    return this.functions.getAvailabilityDateParamsAsString(
      offsetHourString,
      today.clone().utcOffset(numericTimezoneOffset).format(Constants.UTC_Date_Format_Shortened),
      tomorrow.clone().utcOffset(numericTimezoneOffset).format(Constants.UTC_Date_Format_Shortened)
    );
  }

  async initCurrentPatientMedicalHistory(patientId: string): Promise<void> {
    const pId: string = patientId;

    if (pId) {
      try {
        // console.log('[CUSTOM-DIMENSIONS-SERVICE] getMedicalHistory(', pId, ')');
        this.currentPatientMedicalHistory = await this.medicalHistoryService
          .getMedicalHistory(pId)
          .catch((err: any) => {
            console.warn('Unable to retrieve users medical history. Error = ', this.functions.getErrorMessage(err));
            return null;
          });

        if (this.currentPatientMedicalHistory) {
          const patientCustomDimensions: PatientCustomDimensions = this.calculateCustomDimensionsFromMedicalHistory(
            this.currentPatientMedicalHistory
          );

          if (patientCustomDimensions) {
            // this.updatePatientCustomDimensions(pId, patientCustomDimensions);
            this.currentPatientCustomDimensions = {
              ...this.currentPatientCustomDimensions,
              ...patientCustomDimensions
            };
          }
        } else if (this.currentPatientId === pId) {
          this.currentPatientMedicalHistory = new GeneralMedical();
        }
      } catch (err: any) {
        if (this.currentPatientId === pId) {
          this.currentPatientMedicalHistory = new GeneralMedical();
        }
      }

      // Track that we tried to retrieve the patient's medical history and process it
      this._medicalHistoryProcessed[pId] = true;
    }
  }

  updateCurrentPatient(anyPatient?: Patient): void {
    const patient: Patient = anyPatient;

    if (!this.allCustomDimensions.patient) {
      this.allCustomDimensions.patient = {};
    }

    // Re-calculate next available session time
    // this.calculateNextAppointmentAvailability(this.appointmentService.storedAvailabilityGroupsForDay);

    // if (this.practitionerService.practitionerList?.length) {
    //   this.calculateOnDemandQueueSize(this.practitionerService.practitionerList);
    // }

    this.updateCustomerType(this.patientService.benefit);

    if (patient?.patientId) {
      this.currentPatient = patient;
      this.currentPatientId = patient.patientId;
      this.allCustomDimensions.userRegisteredThisSession = patient.hasCompletedATransaction
        ? 'existing patient'
        : 'new patient';

      this.updateLoggedInStatus();

      if (!this.allCustomDimensions.patient[this.currentPatientId]) {
        this.allCustomDimensions.patient[this.currentPatientId] = new PatientCustomDimensions();
      }

      this.updateCurrentPatientTimeZone();
      this.updatePatientHasMedicare();

      this.initCurrentPatientMedicalHistory(this.currentPatientId).then(() => {
        this.calculateCustomDimensionsFromProfile(this.currentPatient).then(() => {
          this.updateAndStoreCustomDimensions(this.currentPatientId, this.currentPatientCustomDimensions);
        });
      });
    } else {
      this.currentPatient = null;
      this.currentPatientId = null;
      this.currentPatientCustomDimensions = null;
      this.allCustomDimensions.userRegisteredThisSession = null;

      this.updateAndStoreCustomDimensions(null, this.currentPatientCustomDimensions);
    }
  }

  updateLoggedInStatus(override?: boolean): void {
    this.patientIsLoggedIn = override ?? this.credentialsService.isAuthenticated();

    this.allCustomDimensions.userType = this.isNative ? 'app ' + this.mobilePlatform : 'web';
    this.allCustomDimensions.userType += ' - ' + (this.patientIsLoggedIn ? 'logged in' : 'not logged in');
  }

  updateCurrentPatientTimeZone(): void {
    const isPatientOther: boolean =
      this.currentPatientId && this.currentPatientId === this.patientService.patientOther?.patientId;

    this.currentPatientTimeZoneOffsetHours = this.patientService.getPatientTimeZoneOffset(null, isPatientOther);
  }

  retrieveCustomDimensions(override: boolean = false): CustomDimensions {
    // console.log('[CUSTOM-DIMENSIONS-SERVICE] retrieveCustomDimensions() from storage. override = ', override);

    let customDimensions: CustomDimensions = null;

    try {
      customDimensions = this.sessionStorage.retrieve(Constants.LocalStorage_Key.customDimensions) || null;
    } catch (_err: any) {}

    if (override) {
      this.allCustomDimensions = customDimensions;
    }

    return customDimensions;
  }

  storeCustomDimensions(): void {
    try {
      this.sessionStorage.store(Constants.LocalStorage_Key.customDimensions, this.allCustomDimensions);
    } catch (_err: any) {}
  }

  async calculateCustomDimensionsFromProfile(anyPatient?: Patient): Promise<PatientCustomDimensions> {
    let dimensions: PatientCustomDimensions = new PatientCustomDimensions();
    const patient: Patient = anyPatient || null;

    if (patient?.patientId) {
      try {
        let age: number = 0;
        const sex: string =
          patient.sex == PractitionerGender.Male
            ? 'Male'
            : patient.sex == PractitionerGender.Female
              ? 'Female'
              : 'unknown';

        // Age and Gender
        if (patient.dateOfBirth) {
          try {
            const stringDate: string = this.functions.formatDateYearFirst(patient.dateOfBirth);
            if (stringDate) {
              age = Math.abs(moment().diff(moment(stringDate), 'years'));
            }
          } catch (err: any) {}
        }
        dimensions.userAgeAndGender = (age ? String(age) : 'unknown') + ' - ' + sex;

        // console.log('[CUSTOM-DIMENSIONS] userAgeAndGender set');
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for age and gender');
        dimensions.userAgeAndGender = null;
      }

      // PostCode
      try {
        if (patient.postCode) {
          dimensions.userLocation = patient.postCode;
        } else if (patient.address?.postalCode) {
          dimensions.userLocation = patient.address.postalCode;
        } else if (patient.addressId) {
          const address: Address = await this.addressService.getAddressById(patient.addressId);
          if (address.postalCode) {
            dimensions.userLocation = address.postalCode;
          } else {
            dimensions.userLocation = null;
          }
        } else {
          let manualAddressMatcher: string = null;

          try {
            const postCodeMatches: RegExpMatchArray = patient.originalAddress?.match(Constants.postCodeRegex);
            if (postCodeMatches && postCodeMatches.length) {
              // get last match
              manualAddressMatcher = postCodeMatches[postCodeMatches.length - 1];
            }
          } catch (err: any) {}

          dimensions.userLocation = manualAddressMatcher;
        }

        // console.log('[CUSTOM-DIMENSIONS] userLocation set');
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for location');
        dimensions.userLocation = null;
      }

      // Medical Cards
      try {
        let medicalCards: string[] = [];
        if (patient.medicareCard?.cardNumber && patient.medicareCard?.medicareIRN && patient.medicareCard?.cardExpiry) {
          medicalCards.push('Medicare card');
        }
        if (patient.healthCareCard?.cardNumber && patient.healthCareCard?.cardExpiry) {
          medicalCards.push('Healthcare card');
        }
        if (patient.pensionCard?.cardNumber && patient.pensionCard?.cardExpiry) {
          medicalCards.push('Concession card');
        }
        if (patient.safetyNetCardNumber) {
          medicalCards.push('SafetyNet card');
        }
        if (patient.ihiNumber) {
          medicalCards.push('IHI number');
        }
        dimensions.userMedicalCards = medicalCards.length ? medicalCards.join(', ') : 'none';
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for medical cards');
        dimensions.userMedicalCards = null;
      }

      // Account Relationship
      try {
        if (patient.email) {
          let patientList: Patient[] = this.patientService.retrievePatientListByEmail(patient.email);

          if (!patientList) {
            // console.log('[CUSTOM-DIMENSIONS-SERVICE] getPatientsByEmail(', patient.email, ')');
            patientList = await this.patientService.getPatientsByEmail(patient.email);
          }

          if (Array.isArray(patientList) && patientList.length) {
            if (patientList.length === 1) {
              dimensions.multiAccountRelationship = 'single account';
            } else if (patientList.length > 1) {
              const patientDetail: Patient = patientList.find((p: Patient) => p.patientId === patient.patientId);
              if (patientDetail?.relationship) {
                dimensions.multiAccountRelationship = 'multi account - ' + patientDetail.relationship;
              }
            }
          } else {
            dimensions.multiAccountRelationship = null;
          }
        } else {
          dimensions.multiAccountRelationship = null;
        }

        // console.log('[CUSTOM-DIMENSIONS] multiAccountRelationship set');
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for account relationship');
        dimensions.multiAccountRelationship = null;
      }

      this._patientProfileProcessed[patient.patientId] = true;

      // console.log('[CUSTOM-DIMENSIONS] this._patientProfileProcessed[' + patient.patientId + '] set TRUE');
    }

    // Remove any empty values
    dimensions = this.functions.removeUndefined(dimensions);

    this.currentPatientCustomDimensions = {
      ...this.currentPatientCustomDimensions,
      ...dimensions
    };

    return dimensions;
  }

  calculateCustomDimensionsFromMedicalHistory(medicalHistory: GeneralMedical): PatientCustomDimensions {
    // console.log('[CUSTOM-DIMENSIONS-SERVICE] calculateCustomDimensionsFromMedicalHistory()');

    let dimensions: PatientCustomDimensions = new PatientCustomDimensions();

    if (medicalHistory && Object.keys(medicalHistory).length) {
      // SMOKING
      try {
        if (medicalHistory.smoking === true) {
          dimensions.userSmoker = 'smoker';
        } else if (medicalHistory.smoking === false) {
          dimensions.userSmoker = 'non smoker';
        } else {
          // medical history has not been filled in
          dimensions.userSmoker = null;
        }
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for smoking');
        dimensions.userSmoker = null;
      }

      // DRINKING (ALCOHOL)
      // heavy drinker: For men, consuming [more than 4 drinks on any day] or more than 14 drinks per week,
      // For women, consuming [more than 3 drinks on any day] or more than 7 drinks per week
      try {
        if (medicalHistory.alcohol === 'Not at all') {
          dimensions.userDrinker = 'non drinker';
        } else if (medicalHistory.alcohol === 'More than 4 standard drinks per day') {
          dimensions.userDrinker = 'heavy drinker';
        } else if (medicalHistory.alcohol) {
          dimensions.userDrinker = 'drinker';
        } else {
          // medical history has not been filled in
          dimensions.userDrinker = null;
        }
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for drinking');
        dimensions.userDrinker = null;
      }

      // BODY MASS INDEX
      // BMI calculation: weight / (height * height)
      // underweight: Less than 18.5
      // healthy weight: 18.5 – 24.9
      // overweight: 25 – 29.9
      // obese: 30 and over
      if (medicalHistory.height && medicalHistory.weight) {
        const bmiCalculation: BMICalculation = this.functions.calculateBMI(
          medicalHistory.height,
          medicalHistory.weight
        );
        dimensions.userBMI = bmiCalculation?.bmiLabel || null;

        // const heightCentimeters: number = parseInt(medicalHistory.height.replace('cm', ''));
        // const heightMeters: number = heightCentimeters / 100;
        // const weightKg: number = parseInt(medicalHistory.weight.replace('kg', ''));
        // const bmi: number = weightKg / (heightMeters * heightMeters);

        // if (bmi < 18.5) {
        //   dimensions.userBMI = 'underweight';
        // } else if (bmi >= 18.5 && bmi < 25) {
        //   dimensions.userBMI = 'healthy weight';
        // } else if (bmi >= 25 && bmi < 30) {
        //   dimensions.userBMI = 'overweight';
        // } else if (bmi >= 30) {
        //   dimensions.userBMI = 'obese';
        // }
      } else {
        // medical history has not been filled in
        dimensions.userBMI = null;
      }

      // HEART PROBLEMS
      try {
        if (medicalHistory.heartProblems === true) {
          dimensions.userHasHeartProblems = 'heart problems';
        } else if (medicalHistory.heartProblems === false) {
          dimensions.userHasHeartProblems = 'no heart problems';
        } else {
          // medical history has not been filled in
          dimensions.userHasHeartProblems = null;
        }
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for heart problems');
        dimensions.userHasHeartProblems = null;
      }

      // ALLERGIES
      try {
        if (medicalHistory.allergies === true) {
          dimensions.userHasAllergies = 'allergies';
        } else if (medicalHistory.allergies === false) {
          dimensions.userHasAllergies = 'no allergies';
        } else {
          // medical history has not been filled in
          dimensions.userHasAllergies = null;
        }
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for allergies');
        dimensions.userHasAllergies = null;
      }

      // MEDICATION
      try {
        if (medicalHistory.otherMedication === true /* && medicalHistory.otherMedicationMore*/) {
          dimensions.userTakesMedication = 'takes medication';
        } else if (medicalHistory.otherMedication === false) {
          dimensions.userTakesMedication = 'does not take medication';
        } else {
          // medical history has not been filled in
          dimensions.userTakesMedication = null;
        }
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for patient medications');
        dimensions.userTakesMedication = null;
      }

      // BLOOD PRESSURE
      // low: less than 90 mm systolic OR less than 60 Hg diastolic
      // normal: less than 120 mm systolic AND less than 80 Hg diastolic
      // elevated: 120-129 mm systolic AND less than 80 Hg diastolic
      // hypertension: over 130 mm systolic OR over 90 Hg diastolic
      try {
        if (medicalHistory.bloodPressure && medicalHistory.bloodPressureValue) {
          const bloodPressureArr: string[] = medicalHistory.bloodPressureValue
            ? medicalHistory.bloodPressureValue.split('/')
            : [null, null];
          const bloodPressureMm: number = isNaN(parseInt(bloodPressureArr[0])) ? null : parseInt(bloodPressureArr[0]);
          const bloodPressureHg: number = isNaN(parseInt(bloodPressureArr[1])) ? null : parseInt(bloodPressureArr[1]);

          if (bloodPressureMm < 90 || bloodPressureHg < 60) {
            dimensions.userBloodPressureRange = 'low';
          } else if (bloodPressureMm < 120 && bloodPressureHg < 80) {
            dimensions.userBloodPressureRange = 'normal';
          } else if (bloodPressureMm >= 120 && bloodPressureMm < 130 && bloodPressureHg < 80) {
            dimensions.userBloodPressureRange = 'elevated';
          } else if (bloodPressureMm >= 130 || bloodPressureHg > 90) {
            dimensions.userBloodPressureRange = 'hypertension';
          } else {
            // Blood pressure values are invalid or fall outside of the normal ranges
            dimensions.userBloodPressureRange = null;
          }
        } else {
          // medical history has not been filled in
          dimensions.userBloodPressureRange = null;
        }
      } catch (err: any) {
        console.log('[CUSTOM DIMENSIONS] No data for blood pressure');
        dimensions.userBloodPressureRange = null;
      }
    }

    // Remove any empty values
    dimensions = this.functions.removeUndefined(dimensions);

    this.currentPatientCustomDimensions = {
      ...this.currentPatientCustomDimensions,
      ...dimensions
    };

    return dimensions;
  }

  /**
   * @function calculateNextAppointmentAvailability
   * @param {any} availabilities object in the form [dateString]: AvailabilityGroupsForDay[]
   */
  calculateNextAppointmentAvailability(availabilities: any): void {
    if (this.availabilityService.isDoctorOnline) {
      this.updateNextAppointmentAvailability(0);
      this.updateOnDemandAppointmentAvailable(true);
    } else if (availabilities) {
      const patientLocalTime: moment.Moment = this.functions.getUTCMoment(this.currentPatientTimeZoneOffsetHours);

      let minutesUntilNextAppointment: number;

      Object.keys(availabilities).forEach((availabilityDate: string) => {
        const availabilitySessionTimes: AvailabilityGroupsForDay[] = availabilities[availabilityDate];

        if (Array.isArray(availabilitySessionTimes) && availabilitySessionTimes.length) {
          availabilitySessionTimes.forEach((availabilityGroup: AvailabilityGroupsForDay) => {
            if (Array.isArray(availabilityGroup.sessions) && availabilityGroup.sessions.length) {
              try {
                const sessionTime: moment.Moment = moment(availabilityGroup.sessions[0].start);

                if (sessionTime.isValid()) {
                  const minsToAppointment: number = sessionTime.diff(patientLocalTime, 'minutes');

                  // console.log('Mins to next available appointment: ', minsToAppointment);

                  // Check that the appointment is not in the past (may happen if calculated based off
                  // stored availabilities) and only record the closest session time
                  if (
                    minsToAppointment >= 0 &&
                    (!minutesUntilNextAppointment || minutesUntilNextAppointment > minsToAppointment)
                  ) {
                    minutesUntilNextAppointment = minsToAppointment;
                  }
                }
              } catch (err: any) {
                console.warn('Error calculating minutes until next session. ', this.functions.getErrorMessage(err));
              }
            }
          });
        }
      });

      this.updateNextAppointmentAvailability(minutesUntilNextAppointment ?? null);

      // console.log('[CUSTOM-DIMENSIONS] Appointment availability data calculated. Emit update event.');
      this._customDimensionsChange.next(this.allCustomDimensions);
    }
  }

  calculateOnDemandQueueSize(practitioners: Practitioner[]): void {
    let onDemandQueueSize: number;
    if (Array.isArray(practitioners) && practitioners.length) {
      onDemandQueueSize = 0;
      practitioners.forEach((practitioner: Practitioner) => {
        if (typeof practitioner.onDemandQueueSize === 'number') {
          onDemandQueueSize += practitioner.onDemandQueueSize;
        }
      });
    }

    // console.log('[CUSTOM-DIMENSIONS-SERVICE] calculateOnDemandQueueSize() total practitioners = ',
    //   practitioners?.length ?? 0);

    this.updateOnDemandQueueSize(onDemandQueueSize ?? null);
  }

  updateAndStoreCustomDimensions(patientId: string, customDimensions?: PatientCustomDimensions): void {
    // console.log('[CUSTOM-DIMENSIONS-SERVICE] updateAndStoreCustomDimensions()');

    if (!this.allCustomDimensions.patient) {
      this.allCustomDimensions.patient = {};
    }

    this.allCustomDimensions.userId = patientId || null;

    // Update allCustomDimensions with the patient's (user) custom dimensions
    if (patientId && !this.allCustomDimensions.patient[patientId]) {
      this.allCustomDimensions.patient[patientId] = new PatientCustomDimensions();
    }
    if (patientId && customDimensions) {
      this.allCustomDimensions.patient[patientId] = {
        ...this.allCustomDimensions.patient[patientId],
        ...customDimensions
      };
    }

    // Local copy of current patient dimensions
    if (patientId && this.currentPatientId === patientId) {
      this.currentPatientCustomDimensions = this.allCustomDimensions.patient[patientId];
    }

    // Logged in?
    this.updateLoggedInStatus();

    this.storeCustomDimensions();

    // console.log('[CUSTOM-DIMENSIONS] Store fully processed custom dimensions. Emit update event.');
    this._customDimensionsChange.next(this.allCustomDimensions);
  }

  updateHotJarSessionId(hotJarSessionId: string): void {
    this.allCustomDimensions.HotjarId = hotJarSessionId;
  }

  /**
   * @function updateOnDemandQueueSize
   * @description Queue size for on-demand appointments
   *
   * @param {number} onDemandAppointmentQueueSize
   */
  updateOnDemandQueueSize(onDemandAppointmentQueueSize: number): void {
    this._onDemandQueueSizeChange.next(
      (this.allCustomDimensions.onDemandAppointmentQueueSize = onDemandAppointmentQueueSize)
    );

    this.storeCustomDimensions();
  }

  /**
   * @function updateOnDemandAppointmentAvailable
   * @description Track whether an on-demand appointment is available, and if user has a medicare card
   *
   * @param {boolean} onDemandAppointmentAvailable is there an on-demand appointment available
   * @param {boolean} [medicareAvailable] does user have a medicare card
   */
  updateOnDemandAppointmentAvailable(onDemandAppointmentAvailable?: boolean, medicareAvailable?: boolean): void {
    const onDemandAvailable: string =
      onDemandAppointmentAvailable ?? this.availabilityService.isDoctorOnline
        ? 'on demand available'
        : 'on demand not available';
    // const medicareCardAvailable: string = (medicareAvailable ?? this.currentPatientHasMedicare)
    //   ? 'medicare available'
    //   : 'medicare not available';

    this.allCustomDimensions.onDemandAppointmentAvailable = onDemandAvailable; //.concat(' - ', medicareCardAvailable);

    // console.log('[CUSTOM-DIMENSIONS-SERVICE] updateOnDemandAppointmentAvailable(',
    // this.allCustomDimensions.onDemandAppointmentAvailable, ')');

    this.storeCustomDimensions();
  }

  /**
   * @function updateNextAppointmentAvailability
   * @description Track number of minutes before next appointment availability (scheduled or on-demand)
   *
   * @param {number} nextAppointmentAvailability minutes until next available appointment
   */
  updateNextAppointmentAvailability(nextAppointmentAvailability: number): void {
    // console.log('[CUSTOM-DIMENSIONS-SERVICE] updateNextAppointmentAvailability(', nextAppointmentAvailability, ')');
    this.allCustomDimensions.nextAppointmentAvailability = nextAppointmentAvailability;

    this.storeCustomDimensions();
  }

  resetCustomerType(): void {
    try {
      this.sessionStorage.clear(Constants.LocalStorage_Key.userCustomerType);
    } catch (_err: any) {}

    this.updateCustomerType(null);
  }

  /**
   * @function updateCustomerType
   * @description Update customer type based on the applied policy (benefit). eg. b2b rio-tinto
   *
   * @param {Benefit} benefit
   */
  updateCustomerType(benefit: Benefit): void {
    try {
      if (benefit?.customerType) {
        this.allCustomDimensions.userCustomerType = benefit.customerType;
        this._patientBenefitProcessed = true;

        try {
          this.sessionStorage.store(Constants.LocalStorage_Key.userCustomerType, benefit.customerType);
        } catch (_err: any) {}
      } else {
        let storedCustomerType: string = null;

        try {
          storedCustomerType = this.sessionStorage.retrieve(Constants.LocalStorage_Key.userCustomerType);
        } catch (_err: any) {}

        if (storedCustomerType) {
          this.allCustomDimensions.userCustomerType = storedCustomerType;
          this._patientBenefitProcessed = true;
        } else {
          this.allCustomDimensions.userCustomerType = Constants.ANALYTICS_DEFAULTS.userCustomerType;
          this._patientBenefitProcessed = !this.patientService.getStoredBenefitCode();
        }
      }
    } catch (err: any) {}
  }

  /**
   * @function updateAppointmentSessionTimes
   * @description Triggered every minute - updates next available session time by subtracting one minute
   */
  updateAppointmentSessionTimes(): void {
    if (
      this.allCustomDimensions.nextAppointmentAvailability &&
      typeof this.allCustomDimensions.nextAppointmentAvailability === 'number'
    ) {
      this.allCustomDimensions.nextAppointmentAvailability -= 1;
      this.storeCustomDimensions();
    }
  }

  updatePatientHasMedicare(): void {
    this.currentPatientHasMedicare = this.doesPatientHaveMedicare();
  }

  doesPatientHaveMedicare(): boolean {
    let patientHasMedicare: boolean = false;

    try {
      if (this.currentPatient?.medicareCard) {
        patientHasMedicare = Boolean(
          this.currentPatient.medicareCard.cardNumber &&
            this.currentPatient.medicareCard.medicareIRN &&
            this.currentPatient.medicareCard.cardExpiry
        );
      }
    } catch (err: any) {
      console.warn(
        '[CUSTOM DIMENSIONS] Unable to determine medicare card status. Data is invalid. Error: ',
        this.functions.getErrorMessage(err)
      );
    }

    // console.log('[CUSTOM-DIMENSIONS-SERVICE] doesPatientHaveMedicare(', patientHasMedicare, ')');
    return patientHasMedicare;
  }

  /**
   * @function getCustomDomensionsForTagManager
   * @description Construct the custom dimensions object to be sent to Google Tag Manager
   *
   * @returns {CustomDimensionsDataLayer} custom dimensions for current patient
   */
  getCustomDomensionsForTagManager(): CustomDimensionsDataLayer {
    let customDimensions: CustomDimensions = { ...this.allCustomDimensions };
    let customDimensionsDataLayer: CustomDimensionsDataLayer = new CustomDimensionsDataLayer();

    Object.keys(customDimensions).forEach((key: string) => {
      if (key !== 'patient') {
        customDimensionsDataLayer[key] = customDimensions[key];
      }
    });

    if (this.currentPatientId && customDimensions?.patient && customDimensions.patient[this.currentPatientId]) {
      const patientCustomDimensions: PatientCustomDimensions = this.functions.removeUndefined(
        customDimensions.patient[this.currentPatientId]
      );

      if (patientCustomDimensions && Object.keys(patientCustomDimensions).length) {
        Object.keys(patientCustomDimensions).forEach((key: string) => {
          customDimensionsDataLayer[key] = patientCustomDimensions[key];
        });
      }
    }

    return customDimensionsDataLayer;
  }

  patientLoggedIn(): void {
    this.updateLoggedInStatus(true);
    // console.log('[CUSTOM-DIMENSIONS] Logged-in status updated. Emit update event.');
    this._customDimensionsChange.next(this.allCustomDimensions);
  }

  retrieveLatestAvailabilityData(): Promise<boolean> {
    return this.http
      .get(`${this.url}/calculatedValues/onDemandAvailability`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response && response.success) {
          const availabilityData = response.response as AppointmentAvailabilityData;

          const onDemandAvailable: boolean = availabilityData.onDemandAppointmentAvailable ?? undefined;
          const onDemandQueueSize: number = availabilityData.onDemandAppointmentQueueSize ?? undefined;
          const minsToNextAppointment: number = availabilityData.nextAppointmentAvailability ?? undefined;

          if (typeof onDemandAvailable === 'boolean') {
            this.updateOnDemandAppointmentAvailable(onDemandAvailable);
          }
          if (typeof minsToNextAppointment === 'number') {
            this.updateNextAppointmentAvailability(minsToNextAppointment);
          }
          if (typeof onDemandQueueSize === 'number') {
            this.updateOnDemandQueueSize(onDemandQueueSize);
          } else {
            this.updateOnDemandQueueSize(null);
          }

          this.availabilityDataRetrieved = true;
          this._customDimensionsChange.next(this.allCustomDimensions);

          return true;
        }

        return false;
      })
      .catch((err: any) => {
        this.availabilityDataRetrieved = false;
        console.warn(this.functions.getErrorMessage(err));
        return false;
      });
  }

  ngOnDestroy(): void {
    this.resetCustomDimensionsProcessedStatus();
    this.subscription.unsubscribe();
  }

  get userId(): string {
    return this.allCustomDimensions.userId;
  }
  set userId(userId: string) {
    this.allCustomDimensions.userId = userId;
  }
}
