import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'health-card-info',
  templateUrl: './health-card-info.component.html',
  styleUrls: ['./health-card-info.component.scss'],
})
export class HealthCardInfoComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<HealthCardInfoComponent>) {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close();
  }
}
