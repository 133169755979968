export enum ModalType {
  confirmationDialog = 'ConfirmationDialog',
  errorModal = 'ErrorModal'
}

export enum ErrorTypes {
  pricing = 'Pricing',
  booking = 'Checkout',
  payment = 'Payment'
}

export type ErrorModalData = Partial<{
  title: string;
  message: string;
  customCloseButtonText: string;
  isSpeakToDoctor: boolean;
  isCreatePrescription: boolean;
  isRescheduleAppointment: boolean;
  isDoctorSelect: boolean;
  showRemoveBenefitButton: boolean;
  showDashboardButton: boolean;
  isClose: boolean;
  isCancelAppointment: boolean;
  isContactUs: boolean;
  newAppointment: boolean;
  isGoToWaitingRoom: boolean;
  showSwitchPatient: boolean;
  hideHeaderCloseButton: boolean;
  updatePatientProfileButton: boolean;
  updateHealthcareIdentifiers: boolean;
  serviceType: string;
}>;

export class ModalError {
  success: boolean = false;
  modalType: ModalType = ModalType.errorModal;
  modalData?: ErrorModalData;
  customCallbackFn?: any;
}
