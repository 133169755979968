<div class="container-fluid">
  <div class="message-container mt-4">
    <div class="gray-box">
      <p class="mb-0">
        To proceed you will need to either select from an available policy or generate a new policy for this patient.
        Please select policy below or click the 'Generate a new policy' button below and the policy will be applied to
        the current appointment booking.
      </p>
    </div>
  </div>

  <mat-card appearance="outlined" class="card-wrapper mt-4">
    <mat-card-content class="content">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-3 col-xl-2 button-wrapper">
          <button (click)="createNewPolicy()" [disabled]="isSubmitting" class="save-btn" mat-flat-button color="accent">
            <span *ngIf="!isSubmitting">Generate a new policy</span>
            <span *ngIf="isSubmitting">Generating...</span>
          </button>
        </div>
      </div>

      <hr class="mt-4" />

      <h2 class="mt-4">Select Existing Policy</h2>
      <loading-spinner *ngIf="!policiesLoaded"></loading-spinner>
      <ng-container *ngIf="policiesLoaded">
        <p *ngIf="availablePolicies && !availablePolicies.length">No polices available for this patient</p>
        <table class="table" *ngIf="policiesLoaded && availablePolicies?.length">
          <thead>
            <th class="px-3 py-2">Name</th>
            <th class="px-3 py-2 text-center">Select</th>
          </thead>
          <tbody>
            <tr *ngFor="let policy of availablePolicies">
              <td class="px-3 py-2">{{ policy.name }}</td>
              <td class="px-3 py-2 button-wrapper">
                <button
                  (click)="selectPolicy(policy)"
                  [disabled]="isSubmitting"
                  class="save-btn"
                  mat-flat-button
                  color="accent">
                  Select
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
