import { environment } from "@env/environment";
import { IAppInsightsCore, IConfiguration, IPlugin, IProcessTelemetryContext, ITelemetryItem, ITelemetryPlugin, ITelemetryPluginChain } from "@microsoft/applicationinsights-web";

export class GoogleAnalyticsBlockingDetector implements ITelemetryPlugin {
  private readonly logPrefix: string = '[GA Telemetry Collector]';

  private next: ITelemetryPlugin | ITelemetryPluginChain;

  public priority: number = 10;
  public identifier: string = 'dod-ga-block-detector';

  public onGACall: ((success: boolean) => void) = null;

  public initialize(_config: IConfiguration, _core: IAppInsightsCore, _extensions: IPlugin[], _pluginChain?: ITelemetryPluginChain) {
    if (!environment.production) {
      console.log(this.logPrefix, 'Initialised');
    }
  }

  public processTelemetry(item: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
    if (
      item.baseType !== 'RemoteDependencyData' ||               // Don't care about anything other than RemoteDependencyData
      item.baseData?.name?.indexOf('google-analytics') === -1   // Don't care about any non-ga related telemetry
    ) {
      if (this.next) this.next.processTelemetry(item, itemCtx);
      return;
    }

    if (!environment.production && !item.baseData?.success || true) {
      console.log(this.logPrefix, 'Got GA telemetry', item);
    }

    if (typeof this.onGACall === 'function') {
      this.onGACall(item.baseData?.success ?? true);
    }

    if (this.next) this.next.processTelemetry(item, itemCtx);
  }

  public setNextPlugin(next: ITelemetryPlugin | ITelemetryPluginChain) {
    this.next = next;
  }
}