import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { CredentialsService } from '@app/core/services/credentials.service';
import { HttpInterceptorService } from '@app/core/services/http-interceptor.service';
import { AgencyService } from '@app/shared/services/agency.service';
import { AvailabilityService } from '@app/shared/services/availability.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { PatientAttributionService } from '@app/shared/services/patient-attribution.service';
import { TimezoneService } from '@app/shared/services/timezone.service';
import { LocalStorage, LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Subject, Subscription, interval } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { GetPolicyEligibilityDTO } from '../models/GetPolicyEligibilityDTO';
import { IResponseAPI } from '../models/api-response';
import { Appointment } from '../models/appointment';
import { Attachment } from '../models/attachment';
import {
  Benefit,
  BenefitPractitioner,
  BenefitResponseDTO,
  BenefitService,
  PractitionerDiscount
} from '../models/benefit';
import { Credentials } from '../models/credentials';
import { HealthCareCard } from '../models/health-care-card';
import { Medication } from '../models/medication';
import { MedicationHistoryConstraints } from '../models/medication-history-constraints';
import { PatientMHTPHistoryDTO } from '../models/mentalhealth/patientMHTPHistoryDTO';
import { Patient } from '../models/patient';
import { Practitioner } from '../models/practitioner';
import { Prescription } from '../models/prescription';
import { PricingType } from '../models/pricing-type';
import { QuickScriptServiceType } from '../models/quickscriptServiceType';
import { RelationshipType } from '../models/relationship-type';
import { Salutation } from '../models/salutation';
import { SendEmailDTO } from '../models/sendEmailDTO';
import { TimeZone } from '../models/time-zone';
import { ValidateHealthCardsRequestDTO } from '../models/validateHealthCardsRequestDTO';
import { ServiceTypeFunctions } from '../service-type-functions';
import { AIContext, AppInsightsService } from './appinsights.service';
import { PromiseHelperService } from './promise-helper.service';
import { environment } from '@env/environment';
import moment from 'moment';
import 'moment-timezone';
import { PatientVerificationResponseDTO } from '../models/patient/patientVerificationResponseDTO';
import { PolicyToken } from '../models/policy-token';

@Injectable({
  providedIn: 'root'
})
export class PatientService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private readonly appointmentUrl: string = `${this.url}/appointment`;
  private readonly patientUrl: string = `${this.url}/patient`;
  private readonly policyUrl: string = `${this.url}/policy`;
  private readonly patientsUrl: string = `${this.url}/patients`;

  private subscription = new Subscription();
  private _constraintNames: any = null;
  private _failedLoginCounter: number = 0;
  private isLoading: boolean = false;

  public numUnreadMessages: number = 0;
  private aiContext: AIContext;

  @SessionStorage('opsusr')
  private _isAdminUser: string;
  @SessionStorage()
  private _medicalHistoryConstraints: MedicationHistoryConstraints;
  @SessionStorage()
  private _addresses: any;
  @LocalStorage()
  private _relationshipTypes: RelationshipType[];
  @LocalStorage()
  private _titles: Salutation[];

  // Patient
  private _patient: Patient = null;
  private _patientOther: Patient = null;
  private _patientData: any = {};
  private _benefit: Benefit = null;
  private _scriptConstraints: any = null;
  private _patientsByEmail: Patient[];

  // Attachments
  public patientInvoices: Attachment[];
  public patientPrescriptions: Prescription[];
  public patientMedicalCertificates: Attachment[];
  public patientReferrals: Attachment[];
  public patientResults: Attachment[];
  public patientLegacyAttachments: Attachment[];

  // Polling subscriptions
  public prescriptionPollingInterval: Subscription;
  public medicalCertificatePollingInterval: Subscription;
  public referralPollingInterval: Subscription;
  public resultsPollingInterval: Subscription;
  public legacyAttachmentsPollingInterval: Subscription;
  public newMessagesPollingInterval: Subscription;

  // Observers
  private _prescriptionsUpdated: Subject<Prescription[]> = new Subject<Prescription[]>();
  public prescriptionsUpdatedObs = this._prescriptionsUpdated.asObservable();

  private _medicalCertificatesUpdated: Subject<Attachment[]> = new Subject<Attachment[]>();
  public medicalCertificatesUpdatedObs = this._medicalCertificatesUpdated.asObservable();

  private _referralsUpdated: Subject<Attachment[]> = new Subject<Attachment[]>();
  public referralsUpdatedObs = this._referralsUpdated.asObservable();

  private _resultssUpdated: Subject<Attachment[]> = new Subject<Attachment[]>();
  public resultsUpdatedObs = this._resultssUpdated.asObservable();

  private _legacyAttachmentsUpdated: Subject<Attachment[]> = new Subject<Attachment[]>();
  public legacyAttachmentsUpdatedObs = this._legacyAttachmentsUpdated.asObservable();

  private _patientChange: Subject<Patient> = new Subject<Patient>();
  public patientChangeObs = this._patientChange.asObservable();

  private _patientChangeBehaviorSubject: BehaviorSubject<Patient> = new BehaviorSubject<Patient>(null);
  public patientChangeBehaviorObs = this._patientChangeBehaviorSubject.asObservable();

  private _patientOtherChange: Subject<Patient> = new Subject<Patient>();
  public patientOtherChangeObs = this._patientOtherChange.asObservable();

  private _patientDataChange: Subject<Patient> = new Subject<Patient>();
  public patientDataChangeObs = this._patientDataChange.asObservable();

  private _patientListByEmailChange: Subject<Patient[]> = new Subject<Patient[]>();
  public patientListByEmailChangeObs = this._patientListByEmailChange.asObservable();

  private _switchPatientModalOpenChange: Subject<boolean> = new Subject<boolean>();
  public switchPatientModalOpenObs = this._switchPatientModalOpenChange.asObservable();

  private _medicalHistoryConstraintsChange: Subject<MedicationHistoryConstraints> =
    new Subject<MedicationHistoryConstraints>();
  public medicalHistoryConstraintsChangeObs = this._medicalHistoryConstraintsChange.asObservable();

  private _benefitChange: Subject<Benefit> = new Subject<Benefit>();
  public benefitChangeObs = this._benefitChange.asObservable();

  private _inboxChange: Subject<boolean> = new Subject<boolean>();
  public inboxChangeObs = this._inboxChange.asObservable();

  private _unreadMessagesChange: Subject<number> = new Subject<number>();
  public unreadMessagesChangeObs = this._unreadMessagesChange.asObservable();

  public requestPolicyEligibilityCheck: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private serviceTypeFunctions: ServiceTypeFunctions,
    private credentialsService: CredentialsService,
    private timezoneService: TimezoneService,
    private availabilityService: AvailabilityService,
    private agencyService: AgencyService,
    private localStore: LocalStorageService,
    private sessionStore: SessionStorageService,
    private analytics: GoogleAnalyticsService,
    private httpInterceptor: HttpInterceptorService,
    private patientAttributionService: PatientAttributionService,
    private promiseHelperService: PromiseHelperService,
    private aiService: AppInsightsService
  ) {
    this.aiContext = this.aiService.createContext('PatientService');

    this._init();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private _init(): void {
    // LOAD THE STORED SCRIPT CONSTRAINTS
    // this._scriptConstraints = this.localStore.retrieve(Constants.LocalStorage_Key.scriptConstraints);

    // AUTHENTICATION FAILED INTERCEPTOR
    this.subscription.add(
      this.httpInterceptor.authenticationFailedObs.subscribe(() => {
        this._failedLoginCounter++;

        // If user authentication fails 4 or more times during one session, clear user credentials,
        // remove all user-related data from memory and force user to re-login
        if (this._failedLoginCounter >= Constants.MaxFailedAuthentications) {
          this.credentialsService.removeCredentials();
          this.credentialsService.removePatientData();
          this.clearPatientListByEmail();
          this.removePatientData();
          this.resetFailedLoginCounter();

          // Reload current page instead of navigating to login (as user may be on an unauthenticated route)
          window.location.reload();
        }
      })
    );

    // PATIENT
    this.subscription.add(
      this.patientChangeObs.subscribe((patient: Patient) => {
        // Update current patient in analytics
        this.analytics.userId = patient?.patientId ?? null;
      })
    );

    // BENEFIT CHANGED
    this.subscription.add(
      this.benefitChangeObs.subscribe((benefit: Benefit) => {
        try {
          this.sessionStore.store(Constants.LocalStorage_Key.benefit, benefit?.name || null);
          this.sessionStore.store(Constants.LocalStorage_Key.benefit_object, benefit || null);
        } catch (_err: any) {}
      })
    );

    // BENEFIT REMOVED
    this.subscription.add(
      this.agencyService.agencyBenefitRemovedObs.subscribe((removedSuccess: boolean) => {
        if (removedSuccess) {
          this.benefit = null;
        }
      })
    );

    // PRESCRIPTIONS
    this.subscription.add(
      this.prescriptionsUpdatedObs.subscribe((prescriptions: Prescription[]) => {
        this.patientPrescriptions = prescriptions || [];
        const patientId: string = this.patient?.patientId || this.getStoredPatientId();
        if (patientId) {
          try {
            this.sessionStore.store(Constants.LocalStorage_Key.prescriptions + '.' + patientId, prescriptions);
          } catch (_err: any) {}
        }
      })
    );

    // MEDICAL CERTIFICATES
    this.subscription.add(
      this.medicalCertificatesUpdatedObs.subscribe((medicalCertificates: Attachment[]) => {
        this.patientMedicalCertificates = medicalCertificates || [];
        const patientId: string = this.patient?.patientId || this.getStoredPatientId();
        if (patientId) {
          try {
            this.sessionStore.store(
              Constants.LocalStorage_Key.medicalCertificates + '.' + patientId,
              medicalCertificates
            );
          } catch (_err: any) {}
        }
      })
    );

    // REFERRALS
    this.subscription.add(
      this.referralsUpdatedObs.subscribe((referrals: Attachment[]) => {
        this.patientReferrals = referrals || [];
        const patientId: string = this.patient?.patientId || this.getStoredPatientId();
        if (patientId) {
          try {
            this.sessionStore.store(Constants.LocalStorage_Key.referrals + '.' + patientId, referrals);
          } catch (_err: any) {}
        }
      })
    );

    // RESULTS
    this.subscription.add(
      this.resultsUpdatedObs.subscribe((results: Attachment[]) => {
        this.patientResults = results || [];
        const patientId: string = this.patient?.patientId || this.getStoredPatientId();
        if (patientId) {
          try {
            this.sessionStore.store(Constants.LocalStorage_Key.results + '.' + patientId, results);
          } catch (_err: any) {}
        }
      })
    );

    // LEGACY ATTACHMENTS (Medical Certificates and Referrals)
    this.subscription.add(
      this.legacyAttachmentsUpdatedObs.subscribe((legacyAttachments: Attachment[]) => {
        this.patientLegacyAttachments = legacyAttachments || [];
        const patientId: string = this.patient?.patientId || this.getStoredPatientId();
        if (patientId) {
          try {
            this.sessionStore.store(Constants.LocalStorage_Key.legacyAttachments + '.' + patientId, legacyAttachments);
          } catch (_err: any) {}
        }
      })
    );

    // SCRIPT CONSTRAINTS
    // this.subscription.add(
    //   this.scriptConstraintsChangeObs.subscribe((allScriptConstraints: any) => {
    //     // let scriptConstraints: any = this._scriptConstraints || {};
    //     // const patientId: string = this.patient?.patientId || this.getStoredPatientId();
    //     // if (patientId) {
    //     try {
    //       // this.localStore.store(Constants.LocalStorage_Key.scriptConstraints + '.' + patientId, scriptConstraints);
    //       this.localStore.store(Constants.LocalStorage_Key.scriptConstraints, allScriptConstraints);
    //     } catch (_err: any) {}
    //     // }
    //   })
    // );
  }

  /**
   * @function setPatientData
   * @description Update and save patient profile data to memory and storage
   *
   * @param {Patient} patient patient data to save
   *
   * @returns {Patient} merged patient data
   */
  setPatientData(patient: Patient): Patient {
    if (!(this._patientData && typeof this._patientData === 'object')) {
      this._patientData = {};
    }

    let updatedPatient: Patient = null;

    if (patient && patient.patientId) {
      if (this.patientData[patient.patientId]) {
        updatedPatient = { ...this.patientData[patient.patientId], ...patient };
      } else {
        updatedPatient = patient;
      }

      // Fix expiry dates for healthcare cards in storage (needs to be in full Australian date format)
      updatedPatient = this.fixHealthCareCardExpiryDates(updatedPatient);

      updatedPatient.password = undefined;

      this._patientData[patient.patientId] = updatedPatient;
      this._patientDataChange.next(updatedPatient);
    }

    this.storePatientData();

    return updatedPatient;
  }

  /**
   * @function storePatientData
   * @description Save patient account data to session storage
   */
  storePatientData(): void {
    try {
      this.sessionStore.store(Constants.LocalStorage_Key.patientsData, this._patientData || null);
    } catch (_err: any) {}
  }

  /**
   * @function removePatientData
   * @description Clear all saved patient data from memory and storage
   */
  removePatientData(): void {
    this._patientData = null;

    try {
      this.sessionStore.clear(Constants.LocalStorage_Key.patientsData);
    } catch (_err: any) {}
  }

  /**
   * @async
   * @function checkLoggedInPatient
   * @description Determine whether the logged-in patient, or another patient under the same account
   * has access to a specified patient's data. If it's another patient, log them in, otherwise display
   * an error message to the user.
   *
   * @param {string} patientId the patientId to compare current patient to
   *
   * @returns {Promise<boolean>} true if it's the correct patient
   */
  async checkLoggedInPatient(patientId: string): Promise<boolean> {
    let found: boolean = false;

    if (patientId === this.patient?.patientId) {
      return true;
    }

    if (patientId === this.getStoredPatientId() && !this.isAuthenticatedPatient()) {
      try {
        const patient: Patient = await this.getPatientById(patientId, true);
        if (patient) {
          this.setPatient(patient);
          return true;
        }
      } catch (err: any) {
        console.log('Unable to retrieve patient with id', patientId, 'Error:', this.functions.getErrorMessage(err));
      }
    }

    // If we don't yet have a list of patients by email, retrieve it from the API
    if (!this.patientsByEmail?.length) {
      try {
        await this.getPatientsByEmail(this.patient.email, true);
      } catch (err: any) {
        console.log('Unable to retrieve patients by email! Error:', this.functions.getErrorMessage(err));
      }
    }

    // If we have a saved list of patients by email address, check each patient for a matching patientId
    if (this.patientsByEmail?.length) {
      for (let i = 0; i < this.patientsByEmail.length; i++) {
        if (this.patientsByEmail[i].patientId === patientId) {
          try {
            const newPatient: Patient = await this.getPatientById(patientId); //, true);
            if (newPatient) {
              this.setPatient(newPatient);
              found = true;
            }
          } catch (err: any) {
            console.log('Unable to retrieve patient with id', patientId, 'Error:', this.functions.getErrorMessage(err));
          }
          break;
        }
      }
    }

    return found;
  }

  private fixHealthCareCardExpiryDates(patient: Patient): Patient {
    if (patient?.medicareCard?.cardExpiry) {
      const medicareExpiryDate: string = patient.medicareCard.cardExpiry.substring(0, 10);
      patient.medicareCard.cardExpiry = moment(medicareExpiryDate, Constants.YearFirstDate).isValid()
        ? medicareExpiryDate
        : null;
    }
    if (patient.healthCareCard && patient.healthCareCard.cardExpiry) {
      const healthCardExpiryDate: string = patient.healthCareCard.cardExpiry.substring(0, 10);
      patient.healthCareCard.cardExpiry = moment(healthCardExpiryDate, Constants.YearFirstDate).isValid()
        ? healthCardExpiryDate
        : null;
    }
    if (patient.pensionCard && patient.pensionCard.cardExpiry) {
      const pensionCardExpiryDate: string = patient.pensionCard.cardExpiry.substring(0, 10);
      patient.pensionCard.cardExpiry = moment(pensionCardExpiryDate, Constants.YearFirstDate).isValid()
        ? pensionCardExpiryDate
        : null;
    }

    return patient;
  }

  /**
   * @function updateNumberOfUnreadMessages
   * @description Update number of unread messages for current patient, trigger observers
   *
   * @param {number} [numUnreadMessages=0]
   */
  updateNumberOfUnreadMessages(numUnreadMessages: number = 0): void {
    this._unreadMessagesChange.next((this.numUnreadMessages = numUnreadMessages));
  }

  /**
   * @function getStoredBenefitCode
   * @description Retrieve stored benefit code from local storage
   *
   * @returns {string}
   */
  getStoredBenefitCode(): string {
    let benefitCode: string = null;

    try {
      benefitCode = this.sessionStore.retrieve(Constants.LocalStorage_Key.benefit) || null;
    } catch (_err: any) {
      benefitCode = this.benefit?.name || null;
    }

    return benefitCode;
  }

  /**
   * @function retrieveBenefit
   * @description Retrieve user's applied Benefit. Start with the in-memory benefit object, then try to retrieve
   * benefit object from session storage, then try to retrieve benefit code from local storage and retrieve benefit
   * details from the API using this code.
   *
   * @returns {Promise<Benefit>} Benefit object or null
   */
  async retrieveBenefit(setBenefit: boolean = true): Promise<Benefit> {
    let benefit: Benefit;

    if (this._benefit) {
      benefit = this._benefit;
    } else {
      const storedBenefit: Benefit = this.sessionStore.retrieve(Constants.LocalStorage_Key.benefit_object);

      if (storedBenefit) {
        benefit = storedBenefit;
      } else {
        const benefitCode: string = this.getStoredBenefitCode();

        if (benefitCode) {
          const getBenefitResponse: BenefitResponseDTO = await this.getBenefit(benefitCode, null);
          return getBenefitResponse?.benefit;
        }
      }
    }

    if (setBenefit && this.benefit?.policyId !== benefit?.policyId) {
      this.setBenefit(benefit);
    }

    return Promise.resolve(benefit);
  }

  /**
   * @function getStoredPatientId
   * @description Retrieve the patient's id from local storage
   *
   * @returns {string} patientId
   */
  getStoredPatientId(): string {
    return this.functions.getStoredPatientId();
  }

  removeStoredPatientId(): void {
    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.patientId);
    } catch (_err: any) {}
  }

  /**
   * @function getLocalTimeZoneOffset
   * @description Get the timezone offset based on local computer time
   *
   * @returns {string} timezone offset. eg. "-4"
   */
  getLocalTimeZoneOffset(): string {
    return String(moment().utcOffset() / Constants.MINUTES_IN_HOUR);
  }

  /**
   * @function getPatientTimeZoneOffset
   * @description Retrieve the patient's timezone and return the timezone offset
   * Due to daylight savings time, the timezoneOffsetHours for a particular timezone can be different
   * depending on the particular time in question. For example, under the AUS Eastern Standard Time timezone,
   * at 2023-09-31T10:00:00 the timezone offset would be +10,
   * but at 2023-10-20T10:00:00, the timezone offset would be +11 since daylight savings would be in effect.
   *
   * @param {string | moment.Moment } [utcDateOfInterest] the dateTime for which we want to know the utc offset
   * @param {boolean} [patientOther=false] read main patient or other patient data?
   *
   * @returns {string} timezone offset. eg. "9.5"
   */
  getPatientTimeZoneOffset(utcDateOfInterest: string | moment.Moment = null, patientOther: boolean = false): string {
    let patientTimeZoneId: string = '';

    const patient: Patient = patientOther ? this.patientOther ?? this.patient : this.patient;

    if (patient?.timeZone) {
      patientTimeZoneId = patient.timeZone;
    } else {
      let tz: TimeZone = this.getPatientTimeZone(patientOther);

      if (tz) {
        patientTimeZoneId = tz.timeZone;
      } else {
        patientTimeZoneId = String(Constants.Default_TimeZone_Name);
      }
    }

    return this.timezoneService.getTimezoneUtcOffsetForGivenDateAndTimezoneId(utcDateOfInterest, patientTimeZoneId);
  }

  /**
   * @function getPatientTimeZoneId
   * @description Retrieve the patient's timezone and return the windows timezone Id
   *
   * @param {boolean} [patientOther=false] read main patient or other patient data?
   *
   * @returns {string} timezone id. eg. "E. Australia Standard Time"
   */
  getPatientTimeZoneId(patientOther: boolean = false): string {
    const patient: Patient = patientOther ? this.patientOther ?? this.patient : this.patient;

    if (patient?.timeZone) {
      return patient.timeZone;
    } else {
      const tz: TimeZone = this.getPatientLocalTimeZone(patientOther);

      if (tz) {
        return tz.timeZone;
      } else {
        return Constants.Default_TimeZone_Name;
      }
    }
  }

  /**
   * @function getPatientTimeZoneLabel
   * @description Retrieve the patient's timezone and return the timezone label
   * Due to daylight savings time, the timezoneOffsetHours for a particular timezone can be different
   * depending on the particular time in question. For example, under the AUS Eastern Standard Time timezone,
   * at 2023-09-31T10:00:00 the timezone offset would be +10,
   * but at 2023-10-20T10:00:00, the timezone offset would be +11 since daylight savings would be in effect.
   *
   * @param {boolean} [patientOther=false] read main patient or other patient data?
   * @param {string | moment.Moment } [utcDateOfInterest] the dateTime for which we want to know the utc offset
   *
   * @returns {string} timezone label. eg. "Sydney, Australia (UTC+10:00)"
   */
  getPatientTimeZoneLabel(utcDateOfInterest: string | moment.Moment = null, patientOther: boolean = false): string {
    const tz: TimeZone = this.getPatientTimeZone(patientOther);

    if (tz) {
      // If a particular date has been specified, caclulate the timezone offset for this particular time, taking DST into account,
      // otherwise use the offset value retreived from the Timezonez EP, which will be the offset for the current time.
      let utcOffset: string;
      if (utcDateOfInterest) {
        utcOffset = this.getPatientTimeZoneOffset(utcDateOfInterest, patientOther);
      } else {
        utcOffset = tz.offset;
      }

      // 23/10/2023: For the first 24 hours of the release, the timezoneLabelNoOffset field may not be available for users that
      // already have the timezones cached in local storage, so just use the timezoneLabel in that case.
      if (tz.timezoneLabelNoOffset) {
        return this.timezoneService.getTimezoneLabelFromNoOffsetLabelAndCalculatedUtcOffset(
          tz.timezoneLabelNoOffset,
          utcOffset
        );
      } else {
        return tz.timezoneLabel;
      }
    } else {
      return Constants.Default_TimeZone_Label;
    }
  }

  /**
   * @function getPatientIanaTimeZoneName
   * @description Retrieve the patient's timezone and return the IANA timezone name
   *
   * @param {boolean} [patientOther=false] read main patient or other patient data?
   *
   * @returns {string} IANA timezone name. eg. "Australia/Sydney"
   */
  getPatientIanaTimeZoneName(patientOther: boolean = false): string {
    const ianaTimeZoneName: string = this.timezoneService.getIanaTimeZone();

    if (ianaTimeZoneName) {
      return ianaTimeZoneName;
    } else {
      const timeZoneName: string = this.getPatientTimeZoneId(patientOther);
      if (timeZoneName) {
        const tz: TimeZone = this.timezoneService.getTimeZoneFromStandardTimeZoneId(timeZoneName);

        if (tz && tz.ianaTimeZones?.length) {
          return tz.ianaTimeZones[0];
        }
      }
    }

    return null;
  }

  /**
   * @function getPatientTimeZone
   * @description Get the TimeZone object that most closely matches data in the patient's profile
   *
   * @param {boolean} [patientOther=false] read main patient or other patient data?
   *
   * @returns {TimeZone} TimeZone object
   */
  getPatientTimeZone(patientOther: boolean = false): TimeZone {
    let tz: TimeZone;
    const timeZoneName: string = this.getPatientTimeZoneId(patientOther);

    if (timeZoneName) {
      tz = this.timezoneService.getTimeZoneFromStandardTimeZoneId(timeZoneName);
    }

    if (!tz) {
      return this.getPatientLocalTimeZone();
    } else {
      return tz;
    }
  }

  /**
   * @function getPatientLocalTimeZone
   * @description Get the TimeZone object by matching name of IANA timezone. If IANA timezone name is not
   * available (ie. iOS < v13) get the TimeZone based on the patient's timezone offset.
   *
   * @param {boolean} [patientOther=false] read main patient or other patient data?
   *
   * @returns {TimeZone} TimeZone object
   */
  getPatientLocalTimeZone(patientOther: boolean = false): TimeZone {
    let tz: TimeZone;

    const ianaTimeZoneName: string = this.timezoneService.getIanaTimeZone();
    if (ianaTimeZoneName) {
      tz = this.timezoneService.getTimeZoneFromIanaTimeZoneName(ianaTimeZoneName);
    }

    if (!tz) {
      const patient: Patient = patientOther ? this.patientOther ?? this.patient : this.patient;

      let patientTimeZoneOffset: string = patient?.timeZoneOffset;

      if (!patientTimeZoneOffset) {
        patientTimeZoneOffset = this.getLocalTimeZoneOffset();
      }

      tz = this.timezoneService.timeZoneOptions.find((tz: TimeZone) => tz.offset === patientTimeZoneOffset);
    }

    return tz || null;
  }

  /**
   * @function getTimeForPatientTimeZone
   * @description Get Meridiem formatted time for the patient's timezone offset
   *
   * @param {string|Date|moment.Moment} inputDate
   *
   * @returns {string}
   */
  getTimeForPatientTimeZone(inputDate: string | Date | moment.Moment): string {
    const offsetString: string = this.getPatientTimeZoneOffset();
    const offset: number = offsetString ? parseFloat(offsetString) : Constants.Default_TimeZone_Offset;
    return moment(inputDate).utcOffset(offset).format(Constants.TimeFormat_Meridiem);
  }

  /**
   * @function doesPatientHaveIHI
   * @description Determine whether the current or specified patient has an IHI Number
   *
   * @param {Patient} [currentPatient] defaults to current patient
   *
   * @returns {boolean} true if IHI card details are present in the patient profile
   */
  doesPatientHaveIHI(currentPatient?: Patient): boolean {
    const patient: Patient = !currentPatient ? this.patient : currentPatient;

    return Boolean(patient?.ihiNumber);
  }

  /**
   * @function doesPatientHaveMedicare
   * @description Determine whether the current or specified patient has a Medicare card
   *
   * @param {Patient} [currentPatient] defaults to current patient
   *
   * @returns {boolean} true if all of the Medicare card details are present in the patient profile
   */
  doesPatientHaveMedicare(currentPatient?: Patient): boolean {
    const patient: Patient = !currentPatient ? this.patient : currentPatient;

    if (
      patient?.medicareCard &&
      patient.medicareCard.medicareIRN &&
      patient.medicareCard.cardNumber &&
      patient.medicareCard.cardExpiry
    ) {
      return true;
    }

    return false;
  }

  doesPatientHaveRelevantHealthCard(pricingType: string = PricingType.Private): boolean {
    const medicareCard: HealthCareCard = this.patient?.medicareCard;
    const healthCareCard: HealthCareCard = this.patient?.healthCareCard;
    const pensionCard: HealthCareCard = this.patient?.pensionCard;
    const safetyNetCardNumber: string = this.patient?.safetyNetCardNumber;
    // const ihiNumber: string = this.patient.ihiNumber;

    if (pricingType == PricingType.PBS && !medicareCard) {
      return false;
    } else if (pricingType == PricingType.Pension && !pensionCard) {
      return false;
    } else if (pricingType == PricingType.HealthCare && !healthCareCard) {
      return false;
    } else if (pricingType == PricingType.SafetyNet && !(medicareCard && safetyNetCardNumber)) {
      return false;
    } else if (pricingType == PricingType.Private) {
      return true;
    }

    return true;
  }

  isPatientNameValid(currentPatient?: Patient): boolean {
    const patient: Patient = currentPatient || this.patient;

    if (!patient) {
      return true;
    }

    const patientNameValid: boolean =
      patient.firstName?.length > 1 &&
      !Constants.specialCharactersNameRegExp.test(patient.firstName) &&
      (!patient.middleName?.length ||
        (patient.middleName?.length > 1 && !Constants.specialCharactersNameRegExp.test(patient.middleName))) &&
      patient.lastName?.length > 1 &&
      !Constants.specialCharactersNameRegExp.test(patient.lastName) &&
      (!patient.preferredName?.length ||
        (patient.preferredName?.length > 1 && !Constants.specialCharactersNameRegExp.test(patient.preferredName)));

    return patientNameValid;
  }

  setPatient(patient: Patient, skipAttribution: boolean = false): Patient {
    if (patient?.patientId) {
      try {
        sessionStorage.setItem(Constants.LocalStorage_Key.patientId, JSON.stringify(patient.patientId));
      } catch (_err: any) {}

      if (!skipAttribution && this.patientHasLoginCredentials()) {
        this.patientAttributionService.saveAttribution(patient.patientId);
      }
    }

    // Update current patient and trigger observers
    this._patientChange.next((this._patient = patient || null));

    this._patientChangeBehaviorSubject.next((this._patient = patient || null));

    return this._patient;
  }

  setPatientOther(patient: Patient): void {
    this._patientOtherChange.next((this._patientOther = patient || null));
  }

  setMedicalHistoryConstraints(medicalHistoryConstraints?: MedicationHistoryConstraints): void {
    this.medicalHistoryConstraints = medicalHistoryConstraints || null;
  }

  setBenefit(benefit?: Benefit): void {
    if (!this.functions.deepCompare(this.benefit, benefit)) {
      this.benefit = benefit;
    }

    this.patientAttributionService.setB2BCustomerId(benefit?.b2BCustomerId, true);
  }

  patientHasLoginCredentials(): boolean {
    return this.credentialsService.isAuthenticated();
  }

  /**
   * @function isAuthenticatedPatient
   * @description Check if user credentials are stored in memory and the patient's profile has been loaded
   *
   * @returns {boolean} true if patient profile and authentication credentials exist in memory
   */
  isAuthenticatedPatient(): boolean {
    return Boolean(this.patient?.patientId && this.patientHasLoginCredentials());
  }

  /**
   * @function isMobilePhoneValid
   * @description Check if a mobile phone number is valid
   *
   * @param {string} [mobilePhone] defaults to current patient's mobile number
   *
   * @returns {boolean} true if valid
   */
  isMobilePhoneValid(mobilePhone?: string): boolean {
    const mobile: string = mobilePhone ?? this.patient?.mobilePhone;

    if (mobile) {
      return Constants.mobilePhoneRegex.test(mobile);
    }

    return false;
  }

  /**
   * @function isAddressValid
   * @description Check if an address is valid
   *
   * @param {string} [patientAddress] defaults to current patient's address
   * @param {string} [patientAddressId] defaults to current patient's addressId
   *
   * @returns {boolean} true if valid
   */
  isAddressValid(patientAddress?: any, patientAddressId?: string): boolean {
    const address: any = patientAddress ?? this.patient?.address;
    const addressId: string = patientAddressId ?? this.patient?.address?.addressId ?? this.patient?.addressId;

    return Boolean(
      (address?.streetNumber && address?.route && address?.postalCode) ||
        (addressId?.length === Constants.GUID_Length && addressId !== Constants.BlankGUID)
    );
  }

  /**
   * @function isDateOfBirthValid
   * @description Check if a date of birth string is a valid date
   *
   * @param {string|moment.Moment} [dateOfBirth] defaults to current patient's date of birth
   *
   * @returns {boolean} true if DOB is valid
   */
  isDateOfBirthValid(dateOfBirth?: string | moment.Moment): boolean {
    let dob: string | moment.Moment = dateOfBirth ?? this.patient?.dateOfBirth;

    if (dob) {
      if (moment.isMoment(dob) && dob.isValid() && moment().diff(dob, 'days') > 0) {
        return true;
      } else if (typeof dob === 'string' && dob.length >= 10) {
        return this.isDateOfBirthValid(moment(dob));
      }
    }

    return false;
  }

  /**
   * @async
   * @function setDefaultPatient
   * @description Retrieve patient credentials from memory or storage and call API to get the
   * patient's profile data.
   *
   * @returns {Promise<Patient>}
   */
  async setDefaultPatient(): Promise<Patient> {
    const patientId: string = this.getStoredPatientId();
    let patient: Patient = this.patient || null;

    // Retrieve patient details from API if:
    // 1. No patient profile is loaded
    // 2. Only a partial patient profile is loaded (via getPatientsByEmail())
    // 3. Patient id in LocalStorage is not the same as the current patient
    if (patientId && (!patient || (patient && (patient.patientId !== patientId || !patient.sourceName)))) {
      try {
        patient = await this.getPatientById(patientId, true);

        if (patient) {
          this.resetFailedLoginCounter();

          // This is where patientId is added to local storage (_p = GUID)
          if (this.patient?.patientId !== patient.patientId) {
            this.setPatient(patient);
          }
        }
      } catch (err: any) {
        console.warn(
          '[PATIENT SERVICE] Error retrieving patient ' + patientId + ' profile: ',
          this.functions.getErrorMessage(err)
        );
      }
    }

    // If no patient found, but user is authenticated, we will assume that they did a hard refresh
    if (!patient && this.patientHasLoginCredentials()) {
      try {
        const response: IResponseAPI = await this.getAccountHolder();
        if (response?.success && response.response?.patiendId) {
          patient = await this.getPatientById(response.response.patiendId);

          if (patient) {
            this.resetFailedLoginCounter();

            // This is where patientId is added to local storage (_p = GUID)
            if (this.patient?.patientId !== patient.patientId) {
              this.setPatient(patient);
            }
          } else {
            console.warn('Could not find Patient based on account holder patientId!');
          }
        } else if (this.functions.hasTokenExpired(response)) {
          // If the refreshToken has expired, the HTTP Interceptor will handle redirecting user back to login
          console.warn(
            '[PATIENT SERVICE] Could not retrieve Account Holder information from API. ' + 'Access token has expired!'
          );
        }
      } catch (err: any) {
        console.warn('[PATIENT SERVICE] Error retrieving account holder data: ', this.functions.getErrorMessage(err));
      }
    }

    return patient;
  }

  resetFailedLoginCounter(): void {
    this._failedLoginCounter = 0;
  }

  emitInbox(): void {
    this._inboxChange.next(true);
  }

  resetBenefit(): void {
    try {
      this.sessionStore.clear(Constants.LocalStorage_Key.benefit);
      this.sessionStore.clear(Constants.LocalStorage_Key.benefit_object);
    } catch (_err: any) {}

    // The setter will trigger an update for the Benefit observable
    this.benefit = null;

    this.patientAttributionService.resetB2BCustomerId(true);
  }

  createPatient(patient: Patient): Promise<IResponseAPI> {
    let amendedPatient: Patient = { ...patient };

    // if (amendedPatient?.relationship === 'Account Holder') {
    //   amendedPatient.relationship = 'AccountHolder'
    // }

    return this.http
      .post(`${this.patientUrl}`, amendedPatient)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const patientId: string = response.response.patientId;

          if (patientId !== Constants.BlankGUID) {
            let createdPatient: Patient = { ...amendedPatient, patientId };
            const accountHolderEmail: string = amendedPatient?.email || this.patient?.email;

            this.analytics.patientRegistered(patientId);

            createdPatient = this.setPatientData(createdPatient);
            this.updatePatientInPatientListByEmail(accountHolderEmail, createdPatient);

            // Update email address in user credentials
            const currentCredentials: Credentials = this.credentialsService.credentials || null;
            if (patient.email !== currentCredentials?.email) {
              this.credentialsService.setCredentials({
                ...currentCredentials,
                email: patient.email
              });
            }
          } else {
            return null;
          }
        }

        return response;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        throw err;
        // return null;
      });
  }

  createAdditionalPatient(patient: Patient): Promise<IResponseAPI> {
    return this.http
      .post(`${this.patientUrl}/additional`, patient)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const patientId: string = response.response.patientId || null;
          let createdPatient: Patient = { ...patient, patientId };
          const accountHolderEmail: string = createdPatient?.email || this.patient?.email;
          const patientAddedLabel: string = this.patient?.patientId + ' => ' + patientId;

          this.analytics.patientAdded(patientAddedLabel);

          createdPatient = this.setPatientData(createdPatient);
          this.updatePatientInPatientListByEmail(accountHolderEmail, createdPatient);
        }

        return response;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  changePassword(email: string, payload: any): Promise<IResponseAPI> {
    return this.http
      .put(`${this.patientUrl}Users/${email}/password`, payload)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          this.analytics.changePassword();
        }
        return response;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // PUT https://api3.doctorsondemand.com.au/api/v1/patient/5bef9f0b-5b65-4d3b-9253-f75004338cee
  updatePatient(patientId: string, patient: Patient): Promise<IResponseAPI> {
    // do not post 'relationship' to the API
    const { relationship, ...amendedPatient } = patient;

    return this.http
      .put(`${this.patientUrl}/${patientId}`, amendedPatient)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          let updatedPatient: Patient = { ...this.patientData[patientId], ...patient, patientId };

          updatedPatient = this.setPatientData(updatedPatient);

          if (!this.patient || patientId === this.patient.patientId) {
            this.setPatient(updatedPatient);
          } else {
            this.setPatientOther(updatedPatient);
          }

          const accountHolderEmail: string = updatedPatient.email || this.patient?.email;
          this.updatePatientInPatientListByEmail(accountHolderEmail, updatedPatient);
        }

        return response;
      })
      .catch((err: any) => {
        this.functions.handleError(err);

        const toastMessage: string =
          this.functions.getErrorCode(err) === Constants.API_ERROR_CODES.INVALID_DATA
            ? 'Error saving patient profile. One or more fields contains invalid data!'
            : 'Server Error: could not update patient profile!';

        this.functions.showToast(toastMessage);

        const error: any = err?.error || { message: toastMessage };

        return { success: false, response: null, error } as IResponseAPI;
      });
  }

  // PUT https://api3.doctorsondemand.com.au/api/v1/patient/5bef9f0b-5b65-4d3b-9253-f75004338cee/mobilephonenumber
  async updatePatientMobilePhoneNumber(patientId: string, mobilePhoneNumber): Promise<IResponseAPI> {
    this.patient.mobilePhone = mobilePhoneNumber;
    return await this.updatePatient(patientId, this.patient);
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/patient/5bef9f0b-5b65-4d3b-9253-f75004338cee
  async getPatientById(patientId: string, forceAPIcall: boolean = false): Promise<Patient | null> {
    if (!patientId) {
      return Promise.resolve(null);
    }

    const promiseStorageKey: string = 'getPatientById';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      patientId,
      Constants.API_Polling_Times.getPatientById_SecondsBetweenRequests
    );

    if (forceAPIcall || !returnExistingPromise) {
      this.aiContext.info('GetPatientById', {
        source: 'API',
        message: 'Retrieving patient details',
        patientId
      });

      const timeZones: TimeZone[] = await this.timezoneService.getTimezoneData();

      // if (
      //   !forceAPIcall &&
      //   this.patientData &&
      //   this.patientData[patientId] &&
      //   // Need to check that we have the full Patient dataset saved in patientData
      //   typeof this.patientData[patientId].sourceName === 'string'
      // ) {
      //   return Promise.resolve(this._patientData[patientId] as Patient);
      // } else {
      // let timeZones: TimeZone[] = await this.timezoneService.getTimezoneData();

      const newPromise: Promise<Patient | null> = this.http
        .get(`${this.patientUrl}/${patientId}`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.success && response.response) {
            let patient: Patient = response.response as Patient;

            // Match timezone by name
            let timeZone: TimeZone = timeZones.find((tz: TimeZone) => tz.timeZone === patient.timeZone);

            if (!timeZone) {
              timeZone = this.getPatientLocalTimeZone();
            }

            // Set timezone name and offset to a defaults if not found
            if (timeZone?.offset) {
              patient.timeZoneOffset = timeZone.offset;
              patient.timeZone = timeZone.timeZone;
            } else {
              patient.timeZoneOffset = String(Constants.Default_TimeZone_Offset);
              patient.timeZone = Constants.Default_TimeZone_Name;
            }

            patient = this.setPatientData(patient);
            this.updatePatientInPatientListByEmail(patient.email, patient);

            return patient;
          }

          this.aiContext.error('GetPatientById', { patientId, error: 'Patient details not found!' });

          return null;
        })
        .catch((err: any) => {
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          // throw err;
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<Patient | null>(promiseStorageKey, newPromise, patientId);
    }

    return this.promiseHelperService.getPromiseByKey<Patient | null>(promiseStorageKey);
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patients/{emailAddress}
  getPatientsByEmail(email?: string, forceAPIcall: boolean = false): Promise<any> {
    const emailAddress: string = email || this.credentialsService.credentials?.email;

    if (emailAddress && this.patientHasLoginCredentials()) {
      // Seny - stored array of patients by email may not be up to date, always check memory, then API
      // if (!(Array.isArray(this.patientsByEmail) && this.patientsByEmail.length)) {
      //   this.patientsByEmail = this.retrievePatientListByEmail(emailAddress);
      // }

      if (!forceAPIcall && Array.isArray(this.patientsByEmail) && this.patientsByEmail.length) {
        return Promise.resolve(this.patientsByEmail);
      } else {
        return this.http
          .get(`${this.patientsUrl}/byEmail?emailAddress=${encodeURIComponent(emailAddress)}`)
          .toPromise()
          .then((response: IResponseAPI) => {
            if (response?.success && response.response) {
              let patients = response.response.patients as Patient[];

              patients.forEach((patient: Patient) => {
                // For whatever reason, dateOfBirth comes back in a different format than getPatientById
                // eg. dateOfBirth: "2004-06-01T00:00:00"
                patient.dateOfBirth = String(patient.dateOfBirth).substring(0, 10);
                patient = this.setPatientData(patient);
              });

              // Save patient list to storage
              this.storePatientListByEmail(emailAddress, patients);

              return patients;
            }
            return [];
          })
          .catch((err: any) => {
            this.functions.handleError(err);
            return null;
          });
      }
    } else {
      return Promise.resolve([]);
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/titles
  getTitles(): Promise<Salutation[]> {
    if (Array.isArray(this.titles) && this.titles.length) {
      return Promise.resolve(this.titles);
    } else {
      return this.http
        .get(`${this.patientUrl}/titles`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response && response.success) {
            const titles = response.response as Salutation[];
            this._titles = titles;
            return titles;
          }
          return [];
        })
        .catch((err: any) => {
          console.log('Failed to load salutations from API. Error: ', this.functions.getErrorMessage(err));

          return Constants.SALUTATIONS as Salutation[];
          // return this.functions.handleError(err);
        });
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/scripts
  getScripts(forceAPICall: boolean = false): Promise<Prescription[]> {
    if (this.patient?.patientId && this.patientHasLoginCredentials()) {
      if (!forceAPICall && this.patientPrescriptions?.length) {
        return Promise.resolve(this.patientPrescriptions);
      } else if (forceAPICall || !this.functions.checkDummyPatient(this.patient)) {
        return this.http
          .get(`${this.patientUrl}/${this.patient.patientId}/scripts`)
          .toPromise()
          .then((response: IResponseAPI) => {
            if (response && response.success && response.response) {
              const prescriptions = response.response.prescriptions as Prescription[];
              return prescriptions;
            }
            return [];
          })
          .catch((err: any) => {
            this.functions.handleError(err);
            return null;
          });
      } else {
        return this.http
          .get(`${this.endpointPrefix}/prescription`)
          .toPromise()
          .then((response) => response as Prescription[])
          .catch((err: any) => {
            this.functions.handleError(err);
            return null;
          });
      }
    } else {
      return Promise.resolve([]);
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/attachments/{attachmentType}
  getAttachments(type: string): Promise<any> {
    if (this.patient?.patientId && this.patientHasLoginCredentials()) {
      if (!this.functions.checkDummyPatient(this.patient)) {
        return this.http
          .get(`${this.patientUrl}/${this.patient.patientId}/attachments/${type}`)
          .toPromise()
          .then((response: IResponseAPI) => {
            if (Array.isArray(response?.response?.attachments)) {
              const attachments = response.response.attachments as Attachment[];
              return attachments;
            }
            return [];
          })
          .catch((err: any) => {
            this.functions.handleError(err);
            return null;
          });
      } else {
        return this.http
          .get(`${this.endpointPrefix}/${type}`)
          .toPromise()
          .then((response: any) => (this.patient ? (response as Attachment[]) : []))
          .catch((err: any) => {
            this.functions.handleError(err);
            return null;
          });
      }
    } else {
      return Promise.resolve([]);
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/mhtpHistory
  getPatientMentalHealthHistoryStatus(patientId: string): Promise<PatientMHTPHistoryDTO> {
    return this.http
      .get(`${this.patientUrl}/${patientId}/mhtpHistory`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          return response.response as PatientMHTPHistoryDTO;
        }
        return null;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/patientverification
  getPatientVerificationInfo(patientId: string): Promise<PatientVerificationResponseDTO> {
    if (!this.isAuthenticatedPatient()) {
      return Promise.resolve(null);
    }

    return this.http
      .get(`${this.patientUrl}/${patientId}/patientverification`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          return response.response as PatientVerificationResponseDTO;
        }
        return null;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/accountholder
  private getAccountHolder(): Promise<IResponseAPI> {
    return this.http
      .get(`${this.patientUrl}/accountholder`)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/unreadmessages
  getUnreadMessages(patient?: Patient): Promise<number> {
    const currentPatient: Patient = patient || this.patient;

    if (currentPatient?.patientId && this.patientHasLoginCredentials()) {
      // Real patient
      if (!this.functions.checkDummyPatient(currentPatient)) {
        return this.http
          .get(`${this.patientUrl}/${currentPatient.patientId}/unreadmessages`)
          .toPromise()
          .then((response: IResponseAPI) => {
            if (response && response.success && response.response) {
              const numUnreadMessages = response.response.numberOfUnReadMessages as number;

              this.updateNumberOfUnreadMessages(numUnreadMessages);

              return numUnreadMessages;
            }
            return 0;
          })
          .catch((err: any) => {
            return this.functions.handleError(err);
          });

        // Test patient
      } else {
        return Promise.resolve(3);
      }
    } else {
      return Promise.resolve(0);
    }
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/policy/{policyNumber}/availableServicesAndPractitioners
  getBenefit(
    policyNumber: string,
    serviceType: string,
    setBenefit: boolean = false
  ): Promise<BenefitResponseDTO | null> {
    if (policyNumber) {
      let requestUrl = `${this.policyUrl}/${policyNumber}/availableServicesAndPractitioners`;
      if (serviceType) {
        requestUrl += `?serviceType=${serviceType}`;
      }
      return this.http
        .get(requestUrl)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            let benefit = response.response as Benefit;
            if (benefit?.policyId) {
              benefit.name = policyNumber.toUpperCase();

              if (setBenefit) {
                this.setBenefit(benefit);
              }

              return { benefit, errorMessage: null } as BenefitResponseDTO;
            } else {
              return null;
            }
          }
          return null;
        })
        .catch((err: any) => {
          const errorCode: string = this.functions.getErrorCode(err);
          const errorMessage: string = this.functions.getErrorMessage(err);

          console.warn(
            '[PATIENT_SERVICE] getBenefit() :: Unable to retrieve policy details. Error:',
            errorCode,
            '::',
            errorMessage
          );

          // if (errCode === Constants.API_ERROR_CODES.INVALID_POLICY_NUMBER) {
          //   this.agencyService.resetSessionAgencyToDefault(true);
          //   this.whiteLabelService.setWhiteLabelConfig(null);
          // }

          return { benefit: null, errorMessage } as BenefitResponseDTO;
        });
    } else {
      return Promise.resolve({ benefit: null, errorMessage: 'No benefit code was provided' });
    }
  }

  // GET /api/v1/relationshipTypes
  getRelationshipTypes(): Promise<RelationshipType[]> {
    if (this.relationshipTypes?.length) {
      return Promise.resolve(this.relationshipTypes);
    } else {
      return this.http
        .get(`${this.patientUrl}/relationshiptypes`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response && response.success) {
            const relationshipTypes = response.response as RelationshipType[];
            this._relationshipTypes = relationshipTypes;
            return relationshipTypes;
          }
          return [];
        })
        .catch((err: any) => {
          this.functions.handleError(err);
          return null;
        });
    }
  }

  // GET /api/v1/patient/{patientId}/policyeligibility
  getPolicyEligibility(): Promise<GetPolicyEligibilityDTO> {
    if (!this.patient || !this.credentialsService.isAuthenticated()) {
      return Promise.resolve(null);
    }

    return this.http
      .get(`${this.patientUrl}/${this.patient.patientId}/policyeligibility`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response && response.success) {
          const resp = response.response as GetPolicyEligibilityDTO;
          return resp;
        }
        return null;
      })
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // POST /api/v1/patient/healthCards/validate
  validateHealthCards(
    validateHealthCardsRequestDTO: ValidateHealthCardsRequestDTO,
    throwException: boolean = false
  ): Promise<IResponseAPI | null> {
    return this.http
      .post(`${this.patientUrl}${Constants.API_PATHS.validateHealthcareCards}`, validateHealthCardsRequestDTO)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          return response;
        }
        return null;
      })
      .catch((err: any) => {
        this.aiContext.error('ValidateHealthCards', {
          result: false,
          validateHealthCardsRequestDTO,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);

        if (throwException) {
          throw err;
        } else {
          return null;
        }
      });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/attachment/MedicalCertificate/send
  sendMedicalCertificateEmail(sendEmailDTO: SendEmailDTO): Promise<IResponseAPI | null> {
    return this.http
      .post(`${this.appointmentUrl}/attachment/MedicalCertificate/send`, sendEmailDTO)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/attachment/Referral/send
  sendReferralEmail(sendEmailDTO: SendEmailDTO): Promise<IResponseAPI> {
    return this.http
      .post(`${this.appointmentUrl}/attachment/Referral/send`, sendEmailDTO)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/attachment/Referral/send
  sendLegacyAttachment(sendEmailDTO: SendEmailDTO): Promise<IResponseAPI> {
    return this.http
      .post(`${this.appointmentUrl}/attachment/CertificateOrReferralLegacy/send`, sendEmailDTO)
      .toPromise()
      .then((response: IResponseAPI) => response)
      .catch((err: any) => {
        this.functions.handleError(err);
        return null;
      });
  }

  /**
   * @function isBenefitSupportedByPractitioner
   * @description Determine whether the specified practitioner(s) service the supplied policy
   *
   * @param {Benefit} benefit
   * @param practitionerId
   * @param practitionerList
   * @returns
   */
  isBenefitSupportedByPractitioner(benefit: Benefit, practitionerId: string, practitionerList: Practitioner[]): any {
    if (!benefit) {
      return true;
    }

    let practitionerValidForBenefit: boolean = false;
    let errorCode: string = null;

    if (Array.isArray(benefit?.validPractitioners)) {
      if (practitionerId) {
        const currentPractitionerIsValid: BenefitPractitioner = benefit.validPractitioners.find(
          (prac: BenefitPractitioner) => prac.practitionerId === practitionerId
        );

        if (currentPractitionerIsValid) {
          practitionerValidForBenefit = true;
        } else {
          errorCode = Constants.POLICY_ERROR_CODES.SELECTED_PRACTITIONER_DOES_NOT_SUPPORT_BENEFIT;
        }
      } else {
        if (practitionerList?.length) {
          practitionerList.forEach((practitioner: Practitioner) => {
            const practitionerIsValid: BenefitPractitioner = benefit.validPractitioners.find(
              (prac: BenefitPractitioner) => prac.practitionerId === practitioner.practitionerId
            );

            if (practitionerIsValid) {
              practitionerValidForBenefit = true;
            }
          });
        }

        if (!practitionerValidForBenefit) {
          errorCode = Constants.POLICY_ERROR_CODES.NO_PRACTITIONERS_SUPPORT_BENEFIT;
        }
      }
    }

    return { practitionerValidForBenefit, errorCode };
  }

  /**
   * @function isBenefitValid
   * @description Determine whether a benefit is valid for the specified appointment
   *
   * @param {Appointment} appointment
   * @param {Benefit} [aBenefit] Benefit to test; defaults to patient's benefit stored in this service
   *
   * @returns {boolean} true if benefit is valid for the appointment's practitioner
   * or the serviceType of this appointment.
   */
  isBenefitValid(appointment: Appointment, aBenefit?: Benefit): boolean {
    const benefit: Benefit = aBenefit ?? this.benefit;

    if (!benefit) {
      return true;
    }

    if (benefit && appointment) {
      let hours: number;
      if (appointment.startTime instanceof Date) {
        hours = appointment.startTime.getHours();
      } else if (appointment.startTimeUTC) {
        hours = new Date(appointment.startTimeUTC).getHours();
      }

      const isBusinessHours: boolean = this.availabilityService.isBusinessHours(hours);

      if (Array.isArray(benefit.validPractitioners) && appointment.practitionerId) {
        const validPractitioner: BenefitPractitioner = benefit.validPractitioners.find(
          (prac: BenefitPractitioner) => prac.practitionerId === appointment.practitionerId
        );

        if (validPractitioner) {
          const discount: PractitionerDiscount = validPractitioner.practitionerDiscountsForServices?.find(
            (prac: PractitionerDiscount) => prac.serviceType === appointment.serviceType
          );

          return (
            (isBusinessHours && typeof discount?.bhDiscountPercentage === 'number') ||
            (!isBusinessHours && typeof discount?.ahDiscountPercentage === 'number')
          );
        } else {
          return false;
        }
      } else if (Array.isArray(benefit.services) && appointment.serviceType) {
        const service: BenefitService = benefit.services.find(
          (bService: BenefitService) => bService.serviceType === appointment.serviceType
        );
        if (service) {
          try {
            const dollarDiscountBH: boolean =
              typeof service.originalPriceBH === 'number' &&
              typeof service.discountedPriceBH === 'number' &&
              service.originalPriceBH >= service.discountedPriceBH;
            const dollarDiscountAH: boolean =
              typeof service.originalPriceAH === 'number' &&
              typeof service.discountedPriceAH === 'number' &&
              service.originalPriceAH >= service.discountedPriceAH;
            const percentDiscountBH: boolean =
              typeof service.totalDiscountPercentageBH === 'number' && service.totalDiscountPercentageBH >= 0;
            const percentDiscountAH: boolean =
              typeof service.totalDiscountPercentageAH === 'number' && service.totalDiscountPercentageAH >= 0;

            return (
              (isBusinessHours && (dollarDiscountBH || percentDiscountBH)) ||
              (!isBusinessHours && (dollarDiscountAH || percentDiscountAH))
            );
          } catch (err: any) {
            this.aiContext.error('IsBenefitValid', {
              result: false,
              benefit,
              service,
              error: this.functions.getErrorMessage(err)
            });

            return false;
          }
        } else {
          return false;
        }
      }
    }

    return false;
  }

  /**
   * @function getBenefitText
   * @description Determine the discount text to be displayed based on the current appointment and applied benefit
   *
   * @param {Appointment} appointment the appointment this benefit will be applied to
   * @param {Benefit} [aBenefit] a Benefit object to parse for discount data. Use saved benefit if not specified.
   *
   * @returns {string} discount text of the applied benefit
   */
  getBenefitText(appointment: Appointment, aBenefit?: Benefit): string {
    const benefit: Benefit = aBenefit ?? this.benefit;

    if (benefit?.discountTextOverride) {
      return benefit.discountTextOverride;
    } else if (benefit?.name?.toLowerCase().indexOf('medicare_') !== -1) {
      //TODO: change this hardcoded check as maybe not all medicare policies will be bulk bill in the future
      return Constants.BenefitCustomText.medicareBulkBillingSupported;
    }

    const serviceTypeText: string =
      appointment?.serviceType == QuickScriptServiceType.quickscriptpharm ||
      appointment?.serviceType == QuickScriptServiceType.quickscriptdeliver
        ? 'QuickScript order'
        : 'appointment';

    if (benefit && appointment) {
      let hours: number;
      if (appointment.startTime instanceof Date) {
        hours = appointment.startTime.getHours();
      } else if (appointment.startTimeUTC) {
        hours = new Date(appointment.startTimeUTC).getHours();
      }

      const isBusinessHours: boolean = this.availabilityService.isBusinessHours(hours);

      if (appointment.practitionerId && benefit.validPractitioners) {
        const validPractitioner: BenefitPractitioner = benefit.validPractitioners.find(
          (prac: BenefitPractitioner) => prac.practitionerId === appointment.practitionerId
        );

        if (validPractitioner) {
          const discount: PractitionerDiscount = validPractitioner.practitionerDiscountsForServices.find(
            (prac: PractitionerDiscount) => prac.serviceType === appointment.serviceType
          );
          if (discount) {
            const discountPercentage = isBusinessHours ? discount.bhDiscountPercentage : discount.ahDiscountPercentage;
            if (discountPercentage <= 0) {
              return null;
            }
            return `Discount of ${discountPercentage}% for ${this.serviceTypeFunctions.getDisplayFriendlyServiceTypeName(appointment.serviceType)}`;
          } else {
            return 'Applied benefit does not provide any discounts with the current practitioner!';
          }
        } else {
          return 'Applied benefit does not provide any discounts with the current practitioner!';
        }
      } else if (benefit.services && appointment.serviceType) {
        const service: BenefitService = benefit.services.find(
          (bService: BenefitService) => bService.serviceType === appointment.serviceType
        );
        if (service) {
          try {
            if (
              (isBusinessHours &&
                !service.totalDiscountPercentageBH &&
                service.originalPriceBH !== service.discountedPriceBH) ||
              (!isBusinessHours &&
                !service.totalDiscountPercentageAH &&
                service.originalPriceAH !== service.discountedPriceAH)
            ) {
              const discountPercentage = isBusinessHours
                ? service.originalPriceBH - service.discountedPriceBH
                : service.originalPriceAH - service.discountedPriceAH;
              if (discountPercentage <= 0) {
                return null;
              }
              return `Discount of $${discountPercentage} for ${this.serviceTypeFunctions.getDisplayFriendlyServiceTypeName(appointment.serviceType)}`;
            } else {
              const discountPercentage = isBusinessHours
                ? service.totalDiscountPercentageBH
                : service.totalDiscountPercentageAH;
              if (discountPercentage <= 0) {
                return null;
              }
              return `Discount of ${discountPercentage}% for ${this.serviceTypeFunctions.getDisplayFriendlyServiceTypeName(appointment.serviceType)}`;
            }
          } catch (err: any) {
            console.log('Failed to calculate discount for benefit: ', benefit, ' from service: ', service);
            return `Could not determine discount for this type of ${serviceTypeText}!`;
          }
        } else {
          return `Applied benefit does not provide a discount for this type of ${serviceTypeText}!`;
        }
      }
    }

    return `Applied benefit does not provide any discounts for this ${serviceTypeText}.`;
  }

  /**
   * @function findAndUpdatePatientInList
   * @description Find specified patient in list of patients by email
   *
   * @param {Patient} patient
   *
   * @returns {boolean} true if the specified patient was found
   */
  findAndUpdatePatientInList(patient: Patient): boolean {
    let patientFound: boolean = false;

    if (this.patientsByEmail?.length) {
      for (let i = 0; i < this.patientsByEmail.length; i++) {
        if (this.patientsByEmail[i].patientId === patient.patientId) {
          this.patientsByEmail[i] = patient;
          patientFound = true;
          break;
        }
      }
    }

    return patientFound;
  }

  /**
   * @functions updatePatientInPatientListByEmail
   * @description Update a specific patient's data within the list of patients by email
   *
   * @param {string} email account holder's email address
   * @param {Patient} patient updated patient data
   */
  updatePatientInPatientListByEmail(email: string, patient: Patient): void {
    if (this.isLoading || !email || !patient) {
      return;
    }

    if (!this.findAndUpdatePatientInList(patient)) {
      this.isLoading = true;
      this.getPatientsByEmail(email, true)
        .then((patientsByEmail: Patient[]) => {
          let patients: Patient[];
          if (patientsByEmail?.length) {
            patients = [...patientsByEmail];
            for (let i = 0; i < patients.length; i++) {
              if (patients[i].patientId === patient.patientId) {
                patients[i] = patient;
                break;
              }
            }
          } else {
            patients = [patient];
          }

          this.patientsByEmail = patients;
          this.storePatientListByEmail(email, this.patientsByEmail);
        })
        .catch((err: any) => {
          console.warn('GetPatientsByEmail(', email, ') failed! Error:', this.functions.getErrorMessage(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.storePatientListByEmail(email, this.patientsByEmail);
    }
  }

  /**
   * @function storePatientListByEmail
   * @description Store list of patients by email in local storage
   *
   * @param {string} email account holder's email address
   * @param {Patient[]} patients list of patients to save
   */
  storePatientListByEmail(email: string, patients: Patient[]): void {
    if (!(email && patients?.length)) {
      return;
    }

    try {
      this.sessionStore.store(Constants.LocalStorage_Key.accountPatients.concat('.', email), patients);
    } catch (_err: any) {}

    this._patientListByEmailChange.next((this._patientsByEmail = patients));
  }

  /**
   * @function retrievePatientListByEmail
   * @description Retrieve list of patients by email addres
   *
   * @param {string} email account holder's email address
   *
   * @returns {Patient[]} list of patients
   */
  retrievePatientListByEmail(email: string): Patient[] {
    let patientList: Patient[] = null;

    try {
      patientList = this.sessionStore.retrieve(
        Constants.LocalStorage_Key.accountPatients.concat('.', email)
      ) as Patient[];
    } catch (_err: any) {}

    return patientList;
  }

  /**
   * @function clearPatientListByEmail
   * @description Remove list of patients by email from local storage
   *
   * @param {string} [email] defaults to the current patient's email address
   */
  clearPatientListByEmail(email?: string): void {
    this._patientsByEmail = null;

    const emailAddress: string =
      email || this.credentialsService.getEmailAddressAssociatedWithAccount() || this.patient?.email;

    if (emailAddress) {
      try {
        this.sessionStore.clear(Constants.LocalStorage_Key.accountPatients.concat('.', emailAddress));
      } catch (_err: any) {}
    }
  }

  /**
   * @function getPatientPrescriptions
   * @description Retrieve all of the patient's prescriptions
   *
   * @param {boolean} [forceAPICall=false] if false, will retrieve prescriptions from memory/storage first
   *
   * @returns {Promise<Prescription[]>} a filtered and sorted array of prescriptions
   */
  async getPatientPrescriptions(forceAPICall: boolean = false): Promise<Prescription[]> {
    if (this.patient && this.patientHasLoginCredentials()) {
      const prescriptions: Prescription[] = await this.getScripts(forceAPICall);

      let prescriptionOutput: Prescription[] = [];

      if (Array.isArray(prescriptions) && prescriptions.length) {
        prescriptions.forEach((prescription: Prescription) => {
          let datum: Prescription = prescription;

          // Use either the prescription received date or the prescription order created date
          const prescriptionDate: string = this.functions.appendZeroTimezoneIfMissing(
            datum.prescriptionProvidedDateUTC || datum.orderCreatedDateUTC
          );

          // Must return a Date for prescriptionDate
          datum.prescriptionDate = prescriptionDate ? new Date(prescriptionDate) : new Date();
          datum.isExpired = prescriptionDate ? moment(prescriptionDate).diff(moment(), 'year') > 0 : false;
          datum.price = null; // Price is calculated from prescription attachments

          // Do not allow prescriptions older than 30 days to be resent to pharmacy
          datum.canReSendScriptToPharmacy = prescriptionDate
            ? datum.canReSendScriptToPharmacy && this.isResendable(prescriptionDate)
            : false;

          // if (
          //   datum.prescriptionStatus === Constants.PRESCRIPTION_STATUS.ACCEPTED ||
          //   datum.prescriptionStatus === Constants.PRESCRIPTION_STATUS.MODIFIED ||
          //   datum.prescriptionStatus === Constants.PRESCRIPTION_STATUS.FINISHED ||
          //   datum.prescriptionStatus === Constants.PRESCRIPTION_STATUS.PHARMACY ||
          //   datum.prescriptionStatus === Constants.PRESCRIPTION_STATUS.SHIPPED ||
          //   datum.prescriptionStatus === Constants.PRESCRIPTION_STATUS.ORDERED
          // ) {
          if (datum?.medications?.length) {
            datum.medicationsNames = [...new Set(datum.medications.map((m: Medication) => m.name))].join(', ');
          }

          if (datum.isQuickScript) {
            datum.price = datum.quickScriptPrice;
          } else {
            datum.price =
              datum?.scriptAttachments?.reduce<number>((prev: number, current: Attachment) => {
                return (prev || 0) + (current.price || 0);
              }, 0) ?? null;
          }
          // }

          prescriptionOutput.push(datum);
        });
      }

      // Sort prescriptions by date (descending)
      const sortedPrescriptions: Prescription[] = prescriptionOutput.sort(
        (a: Prescription, b: Prescription) => b.prescriptionDate.getTime() - a.prescriptionDate.getTime()
      );

      // Update prescription observer
      this._prescriptionsUpdated.next(sortedPrescriptions);

      return Promise.resolve(sortedPrescriptions);
    } else {
      return Promise.resolve([]);
    }
  }

  isResendable(prescriptionDate: string): boolean {
    return (
      moment().diff(moment(prescriptionDate), 'days', true) <=
      Constants.Medications_Configuration.resendPrescriptionDays
    );

    // return moment.duration(
    //   moment().startOf('day').diff(moment(prescriptionDate))
    // ).asDays() <= Constants.Medications_Configuration.resendPrescriptionDays;
  }

  /**
   * @function initPrescriptionInterval
   * @description Poll for updates to prescriptions at a pre-set interval
   */
  initPrescriptionInterval(): void {
    if (!this.prescriptionPollingInterval) {
      this.subscription.add(
        (this.prescriptionPollingInterval = interval(
          Constants.API_Polling_Times.prescriptionList_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          this.getPatientPrescriptions();
        }))
      );
    }
  }

  /**
   * @function initMedicalCertificatesInterval
   * @description Poll for updates to medical certificates at a pre-set interval
   */
  initMedicalCertificatesInterval(): void {
    if (!this.medicalCertificatePollingInterval) {
      this.subscription.add(
        (this.medicalCertificatePollingInterval = interval(
          Constants.API_Polling_Times.medicalCerficitatesList_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          this.getAttachments(Constants.ATTACHMENT_TYPES.MEDICAL_CERTIFICATE).then(
            (medicalCertificates: Attachment[]) => {
              if (medicalCertificates && Array.isArray(medicalCertificates)) {
                medicalCertificates.forEach((attachment: Attachment) => {
                  attachment.dateCreated = new Date(attachment.dateCreated);
                });
                this._medicalCertificatesUpdated.next(medicalCertificates);
              } else {
                this._medicalCertificatesUpdated.next([]);
              }
            }
          );
        }))
      );
    }
  }

  /**
   * @function initReferralsInterval
   * @description Poll for updates to doctor referrals at a pre-set interval
   */
  initReferralsInterval(): void {
    if (!this.referralPollingInterval) {
      this.subscription.add(
        (this.referralPollingInterval = interval(
          Constants.API_Polling_Times.referralsList_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          this.getAttachments(Constants.ATTACHMENT_TYPES.REFERRAL).then((referrals: Attachment[]) => {
            if (referrals && Array.isArray(referrals)) {
              referrals.forEach((attachment: Attachment) => {
                attachment.dateCreated = new Date(attachment.dateCreated);
              });
              this._referralsUpdated.next(referrals);
            } else {
              this._referralsUpdated.next([]);
            }
          });
        }))
      );
    }
  }

  /**
   * @function initResultsInterval
   * @description Poll for updates to imaging/pathology results at a pre-set interval
   */
  initResultsInterval(): void {
    // Turn off Results polling mechanism until API is ready
    if (!this.resultsPollingInterval) {
      this.subscription.add(
        (this.resultsPollingInterval = interval(
          Constants.API_Polling_Times.resultsList_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          this.getAttachments(Constants.ATTACHMENT_TYPES.RESULTS).then((results: Attachment[]) => {
            if (results && Array.isArray(results)) {
              results.forEach((attachment: Attachment) => {
                attachment.dateCreated = new Date(attachment.dateCreated);
              });
              this._resultssUpdated.next(results);
            } else {
              this._resultssUpdated.next([]);
            }
          });
        }))
      );
    }
  }

  /**
   * @function initLegacyAttachmentsInterval
   * @description Poll for updates to legacy attachments (referrals and med certs) at a pre-set interval
   */
  initLegacyAttachmentsInterval(): void {
    if (!this.legacyAttachmentsPollingInterval) {
      this.subscription.add(
        (this.legacyAttachmentsPollingInterval = interval(
          Constants.API_Polling_Times.legacyAttachmentsList_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          this.getAttachments(Constants.ATTACHMENT_TYPES.CERTIFICATE_REFERRAL_LEGACY).then(
            (attachmentsLegacy: Attachment[]) => {
              if (attachmentsLegacy && Array.isArray(attachmentsLegacy)) {
                attachmentsLegacy.forEach((attachment: Attachment) => {
                  attachment.dateCreated = new Date(attachment.dateCreated);
                });
                this._legacyAttachmentsUpdated.next(attachmentsLegacy);
              } else {
                this._legacyAttachmentsUpdated.next([]);
              }
            }
          );
        }))
      );
    }
  }

  /**
   * @function initUnreadMessagesInterval
   * @description Poll for updates to unread messages at a pre-set interval
   */
  initUnreadMessagesInterval(): void {
    if (!this.newMessagesPollingInterval) {
      this.subscription.add(
        (this.newMessagesPollingInterval = interval(
          Constants.API_Polling_Times.pollUnreadMessages_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          this.getUnreadMessages();
        }))
      );
    }
  }

  /**
   * @function stopPrescriptionInterval
   * @description Stop polling for updates to prescriptions
   */
  stopPrescriptionInterval(): void {
    if (this.prescriptionPollingInterval && !this.prescriptionPollingInterval.closed) {
      this.prescriptionPollingInterval.unsubscribe();
    }
    this.prescriptionPollingInterval = null;
  }

  /**
   * @function stopMedicalCertificateInterval
   * @description Stop polling for updates to medical certificates
   */
  stopMedicalCertificateInterval(): void {
    if (this.medicalCertificatePollingInterval && !this.medicalCertificatePollingInterval.closed) {
      this.medicalCertificatePollingInterval.unsubscribe();
    }
    this.medicalCertificatePollingInterval = null;
  }

  /**
   * @function stopReferralInterval
   * @description Stop polling for updates to referrals
   */
  stopReferralInterval(): void {
    if (this.referralPollingInterval && !this.referralPollingInterval.closed) {
      this.referralPollingInterval.unsubscribe();
    }
    this.referralPollingInterval = null;
  }

  /**
   * @function stopResultsInterval
   * @description Stop polling for updates to results
   */
  stopResultsInterval(): void {
    if (this.resultsPollingInterval && !this.resultsPollingInterval.closed) {
      this.resultsPollingInterval.unsubscribe();
    }
    this.resultsPollingInterval = null;
  }

  /**
   * @function stopLegacyAttachmentsInterval
   * @description Stop polling for updates to legacy attachments (referrals and med certs)
   */
  stopLegacyAttachmentsInterval(): void {
    if (this.legacyAttachmentsPollingInterval && !this.legacyAttachmentsPollingInterval.closed) {
      this.legacyAttachmentsPollingInterval.unsubscribe();
    }
    this.legacyAttachmentsPollingInterval = null;
  }

  /**
   * @function stopUnreadMessagesInterval
   * @description Stop polling for updates to unread messages
   */
  stopUnreadMessagesInterval(): void {
    if (this.newMessagesPollingInterval && !this.newMessagesPollingInterval.closed) {
      this.newMessagesPollingInterval.unsubscribe();
    }
    this.newMessagesPollingInterval = null;
  }

  trackSwitchPatientPopupOpen(opened: boolean): void {
    this._switchPatientModalOpenChange.next(opened);
  }

  setIsAdminUser(patientEmail: string): void {
    this._isAdminUser = patientEmail ?? null;
  }

  isAdminUser(): boolean {
    return this._isAdminUser === this.patient?.email;
  }

  notifyRequestPolicyEligibilityCheck() {
    this.requestPolicyEligibilityCheck.emit();
  }

  async checkAndGetPolicyEligibilityNotice(): Promise<GetPolicyEligibilityDTO | null> {
    const patient: Patient = this.patient;

    if (!patient) {
      return null;
    }

    let alreadyNotified: boolean;

    try {
      alreadyNotified = this.sessionStore.retrieve(
        Constants.LocalStorage_Key.patientHasHadPolicyEligibilityNotification.concat('.' + patient.email)
      ) as boolean;
    } catch (_err: any) {}

    if (!alreadyNotified && !this.benefit) {
      try {
        this.sessionStore.store(
          Constants.LocalStorage_Key.patientHasHadPolicyEligibilityNotification.concat('.' + patient.email),
          true
        );
      } catch (_err: any) {}

      const result = await this.getPolicyEligibility();

      if (result && result.isEligibleForPolicy && result.url) {
        return result;
      } else {
        return null;
      }
    }

    return null;
  }

  /**
   * @async
   * @function resetPatient
   * @description Reset all main patient and additional patient data and stop polling services
   */
  async resetPatient(): Promise<void> {
    this.setPatient(null);
    this.setPatientOther(null); // Added by Seny
    this.setMedicalHistoryConstraints(null); // Added by Seny

    // Remove value for _p (patient id in local storage)
    this.removeStoredPatientId();

    // If we have no patient, we cannot poll API for updates
    this.stopPrescriptionInterval();
    this.stopMedicalCertificateInterval();
    this.stopReferralInterval();
    this.stopResultsInterval();
    this.stopLegacyAttachmentsInterval();
  }

  get patient(): Patient {
    return this._patient || null;
  }
  get patientOther(): Patient {
    return this._patientOther || null;
  }
  get patientData(): any {
    return this._patientData || {};
  }

  get patientsByEmail(): Patient[] {
    return this._patientsByEmail || [];
  }
  set patientsByEmail(patients: Patient[]) {
    this._patientsByEmail = patients || [];
  }

  get benefit(): Benefit {
    return this._benefit || null;
  }
  set benefit(benefit: Benefit) {
    this._benefitChange.next((this._benefit = benefit || null));
  }

  get constraintNames(): any {
    return this._constraintNames;
  }
  set constraintNames(constraintNames: any) {
    this._constraintNames = constraintNames || null;
  }

  set medicalHistoryConstraints(constraints: MedicationHistoryConstraints) {
    this._medicalHistoryConstraints = constraints;
  }

  get titles(): Salutation[] {
    if (!this._titles?.length) {
      try {
        this._titles = this.sessionStore.retrieve(Constants.LocalStorage_Key.titles) || [];
      } catch (_err: any) {}
    }
    return this._titles || [];
  }

  get relationshipTypes(): RelationshipType[] {
    if (!this._relationshipTypes?.length) {
      try {
        this._relationshipTypes = this.sessionStore.retrieve(Constants.LocalStorage_Key.relationshipTypes) || [];
      } catch (_err: any) {}
    }
    return this._relationshipTypes || [];
  }

  get addresses(): any {
    if (!this._addresses) {
      this._addresses = {};
    }
    return this._addresses;
  }
}
