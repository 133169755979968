<div class="container-fluid">
  <div class="mt-4">
    <div class="row content-section">
      <div class="col-12">
        <mat-card appearance="outlined" class="card-wrapper">
          <mat-card-content class="content">
            <div class="form-content">
              <p>
                Welcome to the {{ (b2bCustomerService.b2bCustomerPortalInfo | async)?.b2bCustomerName }} business
                portal. You can perform the following available actions:
              </p>

              <div class="row">
                <div
                  class="col-12 col-sm-6 col-md-4 col-xl-2 button-wrapper"
                  *ngFor="
                    let serviceType of (b2bCustomerService.b2bCustomerPortalInfo | async)?.availableBookingServiceTypes
                  "
                >
                  <a [routerLink]="[B2B_STEP_PATH.BOOK_APPOINTMENT, B2B_STEP_PATH.LOGIN, serviceType]">
                    <button class="save-btn text-center" mat-flat-button color="accent">
                      <div>Book Patient Appointment:</div>
                      {{ getDisplayName(serviceType) }}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
