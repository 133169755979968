<button
  mat-stroked-button
  color="accent"
  class="expan-button"
  [class.spinner]="shouldShowSpinner()"
  [class.isInModalFooter]="isInModalFooter"
  [disabled]="isDownloading"
  (click)="onDownloadClicked()">
  Download
</button>
