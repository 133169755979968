<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center" [ngClass]="{ 'two-lines': isConfirmation }">
    <div class="col-11 pl-0">
      <div class="modal-header-subtitle">Practitioner Type - {{ getPractitionerType() | titlecase }}</div>
      <div *ngIf="!isConfirmation" class="text-primary modal-header-title">{{ dialogData.title }}</div>
      <div *ngIf="isConfirmation" class="text-primary modal-header-title">{{ dialogData.confirmationTitle }}</div>
    </div>
    <div class="col-1 px-0">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <!-- CHOSE TIME -->
  <div *ngIf="!isConfirmation" class="modal-body container-fluid text-secondary">
    <p *ngIf="isFilteredData && !dialogData?.isReschedule">
      Note: your appointment filters are still applied.
      <a (click)="resetFilters($event)">Clear Filters</a>.
    </p>
    <div class="schedule-box">
      <div class="schedule-header">
        <div class="col-12 col-xl-6 p-0 text-primary select-time">
          <p class="select-text">Please Select a New Time</p>
        </div>
        <div class="col-2 col-xl-1 text-primary p-0">
          <button
            [disabled]="selectedDateIsToday"
            [class.chevron-disabled]="selectedDateIsToday"
            (click)="previousDate()">
            <img [class.d-none]="selectedDateIsToday" src="assets/icons/chevron-left.svg" alt="previous date" />
            <img [class.d-none]="!selectedDateIsToday" src="assets/icons/chevron-left-dis.svg" alt="previous date disabled" />
          </button>
        </div>
        <div class="col-8 col-xl-4 p-0 text-primary text-center selected-date">
          <p>{{ displayedDate }}</p>
        </div>
        <div class="col-2 col-xl-1 text-primary text-right p-0 pr-1">
          <button
            [disabled]="selectedDateIsLastDay"
            [class.chevron-disabled]="selectedDateIsLastDay"
            (click)="nextDate()">
            <img [class.d-none]="selectedDateIsLastDay" src="assets/icons/chevron-right.svg" alt="next date" />
            <img [class.d-none]="!selectedDateIsLastDay" src="assets/icons/chevron-right-dis.svg" alt="next date disabled" />
          </button>
        </div>
      </div>

      <div class="schedule-body" #schedule>
        <ng-container *ngIf="isLoading; else loaded">
          <div class="my-5">
            <loading-spinner></loading-spinner>
          </div>
        </ng-container>
        <ng-template #loaded>
          <ng-container *ngIf="rowCount && rowCount.length">
            <div *ngFor="let row of rowCount" class="grayed-row">
              <ng-container *ngFor="let session of row">
                <button
                  *ngIf="session.start === newAppointment?.start || session.start === oldAppointment?.startTimeUTC"
                  mat-flat-button
                  color="accent"
                  class="time-button"
                  [class.after-hours]="!session.businessHours"
                  [class.original-session]="session.start === oldAppointment?.startTimeUTC"
                  [class.session-selected]="session.start === newAppointment?.start || !newAppointment?.end"
                  [disabled]="session.start === oldAppointment?.startTimeUTC">
                  <!-- Medicare Eligible sessions are not available yet -->
                  <!-- <div
                    *ngIf="session.isPractitionerMBSCapable &&
                      (session.businessHours || (session.bulkbillAfterHours && !session.businessHours))"
                    class="ribbon"></div> -->
                  {{ session.displayedTime }}
                </button>
                <button
                  *ngIf="session.start !== newAppointment?.start && session.start !== oldAppointment?.startTimeUTC"
                  mat-stroked-button
                  color="accent"
                  class="time-button"
                  [class.after-hours]="!session.businessHours"
                  (click)="chooseTime(session)">
                  <!-- Medicare Eligible sessions are not available yet -->
                  <!-- <div
                    *ngIf="session.isPractitionerMBSCapable &&
                      (session.businessHours || (session.bulkbillAfterHours && !session.businessHours))"
                    class="ribbon"></div> -->
                  {{ session.displayedTime }}
                </button>
              </ng-container>
            </div>
          </ng-container>

          <div *ngIf="!rowCount?.length" class="my-5"> No Available time for this date </div>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- CONFIRMATION -->
  <div *ngIf="isConfirmation" class="modal-body reschedule-confirmation container-fluid text-secondary">
    <div class="row">
      <div class="col-12 pb-2">
        <p>
          Are you sure you want to reschedule this appointment? You may be allocated
          a new practitioner. Please confirm.
        </p>
      </div>
    </div>

    <mat-card appearance="outlined" class="schedule-box">
      <div class="row header">
        <div class="col-sm-12 col-xl-6">
          <span class="title">Previous Appointment Time</span>
        </div>
      </div>
      <mat-card-content class="content">
        <div class="row list-row margin-separator">
          <div class="col-12 col-xl-4 my-auto date-container" [class.on-demand]="oldAppointment?.isOnDemand">
            <div
              class="date-box date-shorter"
              [title]="(oldAppointmentDayDate || '') + ' ' + (oldAppointmentDayMonth || '')">
              <span class="date-box-date">
                {{ oldAppointmentDayDate || '' }}
              </span>
              <span class="date-box-month">
                {{ oldAppointmentDayMonth || '' }}
              </span>
            </div>
            <div
              class="date-box date-longer"
              [title]="(oldAppointmentDayWeekday || '') + ' ' + (oldAppointmentTime || '') + ' - ' + appointmentTimeZoneLabel">
              <span class="date-box-day">
                {{ oldAppointmentDayWeekday || '' }}
              </span>
              <span class="date-box-time">
                {{ oldAppointmentTime || '' }}
              </span>
            </div>
          </div>
          <div class="col-12 col-xl-8 my-auto practitioner-info">
            <div class="row">
              <div
                class="col-xl-4 list-item"
                [title]="'Practitioner Type: ' + (oldAppointment?.serviceType || 'doctor')">
                <span class="list-item__header">Practitioner Type</span>
                <span class="list-item__sub">{{ (oldAppointment?.serviceType || 'doctor') | titlecase }}</span>
              </div>
              <div
                class="col-xl-6 list-item"
                [title]="'Practitioner: ' + (oldAppointment?.practitionerName || 'first available')">
                <span class="list-item__header">Doctor</span>
                <span class="list-item__sub">{{ oldAppointment?.practitionerName || 'First available' }}</span>
              </div>
              <div
                *ngIf="!dialogData?.isReschedule"
                class="col-xl-2 px-xl-0 list-item"
                [title]="'Old Appointment Price: ' + (oldAppointmentPrice | currency)">
                <span class="list-item__header">Fee</span>
                <span class="list-item__sub">{{ oldAppointmentPrice | currency }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="schedule-box mt-4">
      <div class="row header">
        <div class="col-sm-12 col-xl-6">
          <span class="title">New Appointment Time</span>
        </div>
      </div>
      <mat-card-content class="content">
        <div class="row list-row margin-separator">
          <div class="col-12 col-xl-4 my-auto date-container">
            <div
              class="date-box date-shorter"
              [title]="(appointmentDayDate || '') + ' ' + (appointmentDayMonth || '')">
              <span class="date-box-date">
                {{ appointmentDayDate || '' }}
              </span>
              <span class="date-box-month">
                {{ appointmentDayMonth || '' }}
              </span>
            </div>
            <div
              class="date-box date-longer"
              [title]="(appointmentDayWeekday || '') + ' ' + (appointmentTime || '') + ' - ' + appointmentTimeZoneLabel">
              <span class="date-box-day">
                {{ appointmentDayWeekday || '' }}
              </span>
              <span class="date-box-time">
                {{ appointmentTime || '' }}
              </span>
            </div>
          </div>
          <div class="col-12 col-xl-8 my-auto practitioner-info">
            <div class="row">
              <div
                class="col-xl-4 list-item"
                [title]="'Practitioner Type: ' + (newAppointment?.serviceType || 'doctor')">
                <span class="list-item__header">Practitioner Type</span>
                <span class="list-item__sub">{{ (newAppointment?.serviceType || 'doctor') | titlecase }}</span>
              </div>
              <div
                class="col-xl-6 list-item"
                [title]="'Practitioner: ' + (newPractitioner?.practitionerName || 'first available')">
                <span class="list-item__header">Doctor</span>
                <span class="list-item__sub">{{ newPractitioner?.practitionerName || 'First available' }}</span>
              </div>
              <div
                *ngIf="!dialogData?.isReschedule"
                class="col-xl-2 px-xl-0 list-item"
                [title]="'New Appointment Price: ' + (newAppointmentPrice | currency)">
                <span class="list-item__header">Fee</span>
                <span class="list-item__sub">{{ newAppointmentPrice | currency }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="!isConfirmation && !isMobile" class="modal-footer container-fluid bg-muted">
    <div class="first-button col-xl-6 col-xs-12 mx-0">
      <button
        mat-stroked-button
        color="accent"
        class="btn-block"
        (click)="onClose()">
        Close
      </button>
    </div>
    <div class="second-button col-xl-6 col-xs-12 mx-0 button-header">
      <button
        mat-flat-button
        color="accent"
        class="btn-block"
        [disabled]="newAppointment === null
          || !newAppointment?.end
          || oldAppointment?.startTimeUTC === newAppointment?.start"
        (click)="confirmTime()">
        Change Appointment Time
      </button>
    </div>
  </div>
  <div *ngIf="!isConfirmation && isMobile" class="modal-footer container-fluid bg-muted">
    <div class="first-button col-xl-6 col-xs-12 mx-0 button-header">
      <button
        mat-flat-button
        color="accent"
        class="btn-block"
        [disabled]="newAppointment === null
          || !newAppointment?.end
          || oldAppointment?.startTimeUTC === newAppointment?.start"
        (click)="confirmTime()">
        Change Appointment Time
      </button>
    </div>
    <div class="second-button col-xl-6 col-xs-12 mx-0">
      <button
        mat-stroked-button
        color="accent"
        class="btn-block"
        (click)="onClose()">
        Close
      </button>
    </div>
  </div>

  <div *ngIf="isConfirmation && !isMobile" class="modal-footer container-fluid bg-muted">
    <div class="first-button col-xl-6 col-xs-12 mx-0 button-header">
      <button
        mat-stroked-button
        color="accent"
        class="btn-block"
        (click)="isConfirmation = !isConfirmation">
        Cancel
      </button>
    </div>
    <div class="second-button col-xl-6 col-xs-12 mx-0 button-header">
      <button
        mat-flat-button
        color="accent"
        class="btn-block"
        (click)="confirmReschedule()">
        Confirm New Appointment Time
      </button>
    </div>
  </div>
  <div *ngIf="isConfirmation && isMobile" class="modal-footer container-fluid bg-muted">
    <div class="first-button col-xl-6 col-xs-12 mx-0 button-header">
      <button
        mat-flat-button
        color="accent"
        class="btn-block"
        (click)="confirmReschedule()">
        Confirm New Appointment Time
      </button>
    </div>
    <div class="second-button col-xl-6 col-xs-12 mx-0 button-header">
      <button
        mat-stroked-button
        color="accent"
        class="btn-block"
        (click)="isConfirmation = !isConfirmation">
        Cancel
      </button>
    </div>
  </div>
</div>
