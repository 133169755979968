import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(private analytics: GoogleAnalyticsService) {
    // If user goes directly to this page (no referrer) or is redirected from another page in
    // the PWA, refresh the page (this will trigger server proxy to produce a 404 error)
    if (!document.referrer || document.referrer !== window.location.href) {
      this.analytics.pageNotFound();
      window.location.href = window.location.href;
      return;
    }
  }

  ngOnInit(): void {}
}
