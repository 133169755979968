import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { Constants, Labels } from '../constants';
import { Functions } from '../functions';
import { WhiteLabelConfigDTO } from '../models/whitelabel/WhiteLabelConfigDTO';
import { WhiteLabelServiceConfiguration } from '../models/whitelabel/WhiteLabelServiceConfiguration';
import { WhiteLabelUIConfig } from '../models/whitelabel/WhiteLabelUIConfigModel';
import { STEP_PATH } from '../step-configuration';
import { Benefit } from '../models/benefit';
import { PolicyServiceConfigurationDTO } from '../models/whitelabel/Policy/PolicyServiceConfigurationDTO';
import { HttpClient } from '@angular/common/http';
import { IResponseAPI } from '../models/api-response';
import { PatientAttributionService } from './patient-attribution.service';
import { AIContext, AppInsightsService } from './appinsights.service';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private aiContext: AIContext;

  private _currentWhiteLabelConfig: WhiteLabelConfigDTO;
  private _currentWhiteLabelUIConfig: WhiteLabelUIConfig;

  private serviceTypes: Record<string, boolean> = {};
  private _primaryServiceType: string;

  private _whiteLabelConfig: Subject<WhiteLabelConfigDTO> = new Subject<WhiteLabelConfigDTO>();
  public whitelabelConfigObs: Observable<WhiteLabelConfigDTO> = this._whiteLabelConfig.asObservable();

  private _whitelabelUIConfig: Subject<WhiteLabelUIConfig> = new Subject<WhiteLabelUIConfig>();
  public whitelabelUIConfigObs: Observable<WhiteLabelUIConfig> = this._whitelabelUIConfig.asObservable();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private storage: SessionStorageService,
    private patientAttributionService: PatientAttributionService,
    private appInsights: AppInsightsService
  ) {
    this.aiContext = this.appInsights.createContext('WhiteLabelService');

    this._currentWhiteLabelConfig = this._getDefaultWhiteLabelConfig();
    this._currentWhiteLabelUIConfig = this._getDefaultUIConfig();
  }

  /**
   * @function setWhiteLabelConfig
   * @description Set the current WhiteLabel configuration
   *
   * @param {WhiteLabelConfigDTO} config
   */
  setWhiteLabelConfig(config: WhiteLabelConfigDTO): void {
    if (!config) {
      config = this._getDefaultWhiteLabelConfig();
    }

    try {
      // Retrieve stored whitelabel configuration
      const storedAgencyConfig = this.storage.retrieve(
        Constants.LocalStorage_Key.agencyServiceConfiguration
      ) as WhiteLabelConfigDTO;

      if (storedAgencyConfig) {
        // Compare stored configuration to the supplied configuration, if different, then apply changes
        if (
          !this.functions.deepCompare(storedAgencyConfig, config) || // compare storage
          !this.functions.deepCompare(this.currentWhiteLabelConfig, config) // compare in-memory
        ) {
          this.updateWhiteLabelConfiguration(config);
        }

        // Get the current UI config (calculated from the WhiteLabel service configuration above), and compare
        // it to the existing UI config in memory, if different, then apply changes
        const uiConfig: WhiteLabelUIConfig = this.getUIConfig(config);
        if (!this.functions.deepCompare(this.currentWhiteLabelUIConfig, uiConfig)) {
          this.updateWhiteLabelUIConfiguration(uiConfig);
        }
      } else {
        this.updateAllWhiteLabelConfigurations(config);
      }
    } catch (_err: any) {
      // If the above code fails (perhaps due to storage being blocked), apply whitelabel configurations as normal
      this.updateAllWhiteLabelConfigurations(config);
    }
  }

  /**
   * @function updateAllWhiteLabelConfigurations
   * @description Update WhiteLabel Service and UI configurations
   *
   * @param {WhiteLabelConfigDTO} config
   */
  updateAllWhiteLabelConfigurations(config: WhiteLabelConfigDTO): void {
    this.updateWhiteLabelConfiguration(config);
    this.updateWhiteLabelUIConfiguration(this.getUIConfig(config));
  }

  /**
   * @function updateWhiteLabelConfiguration
   * @description Update the WhiteLabel configuration and trigger observers
   *
   * @param {WhiteLabelConfigDTO} config WhiteLabel core configuration
   */
  updateWhiteLabelConfiguration(config: WhiteLabelConfigDTO): void {
    this.currentWhiteLabelConfig = config;

    this.serviceTypes = {};
    config.services?.serviceTypes?.forEach((serviceType: string) => {
      this.serviceTypes[serviceType] = true;
    });

    if (Object.keys(this.serviceTypes).length) {
      this._primaryServiceType = this.getPrimaryServiceType();
    }

    try {
      this.storage.store(Constants.LocalStorage_Key.agencyServiceConfiguration, config);

      if (config?.code && config.code.toLowerCase() !== environment.agencyCode) {
        this.storage.store(Constants.LocalStorage_Key.sessionAgencyCode, config.code);
      } else {
        this.storage.clear(Constants.LocalStorage_Key.sessionAgencyCode);
      }
    } catch (_err: any) {
      console.log('Unable to store whitelabel configuration and session agency code!');
    }

    this.patientAttributionService.setAgencyId(config.agencyId, false);
  }

  /**
   * @function updateWhiteLabelUIConfiguration
   * @description Update the WhiteLabel UI configuration and trigger observers
   *
   * @param {WhiteLabelUIConfig} uiConfig
   */
  updateWhiteLabelUIConfiguration(uiConfig: WhiteLabelUIConfig): void {
    this.currentWhiteLabelUIConfig = uiConfig;
  }

  /**
   * @function getWhiteLabelConfig
   * @description Retrieve current WhiteLabel configuration, return default config if not set
   *
   * @returns {WhiteLabelConfigDTO} WhiteLabel core configuration
   */
  getWhiteLabelConfig(): WhiteLabelConfigDTO {
    // Retrieve stored whitelabel configuration
    const storedAgencyConfig = this.storage.retrieve(
      Constants.LocalStorage_Key.agencyServiceConfiguration
    ) as WhiteLabelConfigDTO;

    if (!storedAgencyConfig && !this._currentWhiteLabelConfig) {
      this.currentWhiteLabelConfig = this._getDefaultWhiteLabelConfig();
      this.currentWhiteLabelUIConfig = this._getDefaultUIConfig();
    } else if (storedAgencyConfig?.code?.length) {
      this.updateWhiteLabelConfiguration(storedAgencyConfig);
      this.updateWhiteLabelUIConfiguration(this.getUIConfig(storedAgencyConfig));
    }

    return this.currentWhiteLabelConfig;
  }

  /**
   * @private
   * @function _getDefaultWhiteLabelConfig
   * @description Return the default (DoD) WhiteLabel configuration
   *
   * @returns {WhiteLabelConfigDTO} DoD WhiteLabel core configuration
   */
  private _getDefaultWhiteLabelConfig(serviceType: string = Constants.SERVICE_TYPE.DOCTOR): WhiteLabelConfigDTO {
    const defaultServiceConfiguration: WhiteLabelServiceConfiguration =
      this._getDefaultServiceConfiguration(serviceType);

    return {
      name: 'Doctors on Demand',
      code: 'dod',
      primaryAppointmentServiceType: Constants.SERVICE_TYPE.DOCTOR,
      isDefault: true,
      brand: {
        logos: {
          desktop: Constants.DEFAULT_THEME.logoImg,
          mobile: Constants.DEFAULT_THEME.logoImg
        },
        colours: {
          logoBg: Constants.DEFAULT_THEME.logoBg,
          logoBgComplement: Constants.DEFAULT_THEME.logoBgComplement
        },
        isCoBranded: false
      },
      services: {
        // serviceTypes: ['*'],
        serviceTypes: [
          Constants.SERVICE_TYPE.DOCTOR,
          Constants.SERVICE_TYPE.BULKBILL_GP,
          Constants.SERVICE_TYPE.WEIGHT_LOSS,
          Constants.SERVICE_TYPE.MENTAL_HEALTH,
          Constants.SERVICE_TYPE.SMOKING_CESSATION,
          Constants.SERVICE_TYPE.QUICKSCRIPT_DELIVERY,
          Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY
        ],
        configurations: {
          [Constants.SERVICE_TYPE.DOCTOR]: defaultServiceConfiguration,
          [Constants.SERVICE_TYPE.BULKBILL_GP]: defaultServiceConfiguration,
          [Constants.SERVICE_TYPE.WEIGHT_LOSS]: this._getDefaultServiceConfiguration(
            Constants.SERVICE_TYPE.WEIGHT_LOSS
          ),
          [Constants.SERVICE_TYPE.MENTAL_HEALTH]: this._getDefaultServiceConfiguration(
            Constants.SERVICE_TYPE.MENTAL_HEALTH
          ),
          [Constants.SERVICE_TYPE.SMOKING_CESSATION]: this._getDefaultServiceConfiguration(
            Constants.SERVICE_TYPE.SMOKING_CESSATION
          ),
          [Constants.SERVICE_TYPE.QUICKSCRIPT_DELIVERY]: this._getDefaultServiceConfiguration(
            Constants.SERVICE_TYPE.QUICKSCRIPT_DELIVERY
          ),
          [Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY]: this._getDefaultServiceConfiguration(
            Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY
          )
        }
      },
      urls: {
        landingPage: null,
        policyTokenValidationFailed: null,
        postAppointment: null
      }
    } as WhiteLabelConfigDTO;
  }

  /**
   * @private
   * @function _getDefaultUIConfig
   * @description Return the default (DoD) WhiteLabel UI configuration
   *
   * @returns {WhiteLabelUIConfig} DoD WhiteLabel UI configuration
   */
  private _getDefaultUIConfig(): WhiteLabelUIConfig {
    const config: WhiteLabelConfigDTO = this._getDefaultWhiteLabelConfig();

    return {
      isDefault: true,
      header: {
        logo: config.brand.logos,
        colours: config.brand.colours
      },
      cobranding: null,
      policy: {
        logo: config.brand.logos
      }
    } as WhiteLabelUIConfig;
  }

  /**
   * @function getUIConfig
   * @description Get the current WhiteLabel UI configuration
   *
   * @param {WhiteLabelUIConfig} serviceConfiguration
   *
   * @returns {WhiteLabelUIConfig} Current WhiteLabel UI configuration
   */
  getUIConfig(serviceConfiguration?: WhiteLabelConfigDTO): WhiteLabelUIConfig {
    const config: WhiteLabelConfigDTO = serviceConfiguration || this.getWhiteLabelConfig();

    if (config?.isDefault) {
      return this._getDefaultUIConfig();
    }

    const defaultConfig: WhiteLabelConfigDTO = this._getDefaultWhiteLabelConfig();

    return {
      isDefault: false,
      header: {
        logo: config.brand.isCoBranded ? defaultConfig.brand.logos : config.brand.logos,
        colours: config.brand.isCoBranded ? defaultConfig.brand.colours : config.brand.colours
      },
      cobranding: config.brand.isCoBranded
        ? { logo: config.brand.logos, text: config.brand.coBrandMessage ?? Labels.onlineDoctors247 }
        : null,
      policy: {
        logo: config.brand.logos
      }
    } as WhiteLabelUIConfig;
  }

  /**
   * @function getPrimaryServiceType
   * @description Get the primary appointment service type from the current WhiteLabel configuration. If not specified,
   * defaults to the first service type found in the whitelabel service configuration.
   *
   * @returns {string} primary appointment serviceType
   */
  getPrimaryServiceType(): string {
    if (this.currentWhiteLabelConfig?.primaryAppointmentServiceType?.length) {
      return this.currentWhiteLabelConfig.primaryAppointmentServiceType;
    } else if (this.currentWhiteLabelConfig?.services?.serviceTypes?.length) {
      return this._currentWhiteLabelConfig.services.serviceTypes[0];
    }

    return null;
  }

  /**
   * @function getPrimaryFlowType
   * @description Using the primary serviceType, retrieve the 'serviceFlow' param from the
   * WhiteLabel service configuration. 'serviceFlow' determines the steps and path
   * user takes when this agency is active (utilised by the StepService).
   *
   * @returns {string} Primary flow name. eg. 'doctor', 'fertility', 'superpharmacy'
   */
  getPrimaryFlowType(): string {
    return this.getFlowForServiceConfiguration(this.getPrimaryServiceType());
  }

  /**
   * @function getAppointmentStepType
   * @description Get the Appointment STEP_PATH for the currently configured 'serviceFlow'. This STEP_PATH is
   * used to initialise the StepService when starting an Appointment.
   *
   * @param {string} [serviceType] service type. eg. 'doctor'
   *
   * @returns {string} Appointment step type. eg. 'APPOINTMENT_TYPE_DOCTOR', 'APPOINTMENT_TYPE_FERTILITY'
   */
  getAppointmentStepType(serviceType?: string): string {
    let stepType: string = 'APPOINTMENT_TYPE_DOCTOR';

    const serviceFlow: string = this.getFlowForServiceConfiguration(serviceType);
    if (serviceFlow) {
      const serviceFlows: string[] = Object.keys(STEP_PATH);

      for (let i = 0; i < serviceFlows.length; i++) {
        const key: string = serviceFlows[i];

        if (STEP_PATH[key] === serviceFlow) {
          stepType = key;
          break;
        }
      }
    }

    return stepType;
  }

  /**
   * @async
   * @function getPolicyServiceConfigurationOverride
   * @description Retrieve whitelabel service configuration overrides by policy from the API
   *
   * @param {string} policyNumber
   * @param {strig} serviceType
   * @returns {Promise<PolicyServiceConfigurationDTO>}
   */
  async getPolicyServiceConfigurationOverride(
    policyNumber: string,
    serviceType: string
  ): Promise<PolicyServiceConfigurationDTO> {
    return await this.http
      .get(`${this.url}/policy/${policyNumber}/services/${serviceType}/configuration`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          return response.response as PolicyServiceConfigurationDTO;
        }

        return null;
      })
      .catch((err: any) => {
        console.log(
          'Failed to retrieve service configuration override for service type',
          serviceType,
          'and policy',
          policyNumber,
          ' Error:',
          this.functions.getErrorMessage(err)
        );
        return null;
      });
  }

  /**
   * @async
   * @function getServiceConfiguration
   * @description Get the WhiteLabel service configuration for the specified serviceType (use primary service type
   * when no serviceType is provided).
   *
   * @param {string} [serviceType] service type. eg. 'doctor'
   *
   * @returns {WhiteLabelServiceConfiguration} Current service configuration
   */
  async getServiceConfiguration(serviceType?: string, benefit?: Benefit): Promise<WhiteLabelServiceConfiguration> {
    const config: WhiteLabelServiceConfiguration = this.getServiceConfigurationSync(serviceType);

    if (benefit?.name?.length) {
      let override: PolicyServiceConfigurationDTO = await this.getPolicyServiceConfigurationOverride(
        benefit.name,
        serviceType
      );

      if (override) {
        if (override.enableConcessionCard !== null) {
          config.enableConcessionCard = override.enableConcessionCard;
        }
        if (override.enableHealthcareCard !== null) {
          config.enableHealthcareCard = override.enableHealthcareCard;
        }
        if (override.enableMedicareCard !== null) {
          config.enableMedicareCard = override.enableMedicareCard;
        }
        if (override.enableSafetyNetCard !== null) {
          config.enableSafetyNetCard = override.enableSafetyNetCard;
        }

        if (override.strings !== null) {
          if (override.strings.medicareRebatesWarning !== null) {
            config.strings.medicareRebatesWarning = override.strings.medicareRebatesWarning;
          }
        }
      }
    }

    return config;
  }

  /**
   * @function getServiceConfigurationSync
   * @description Get the WhiteLabel service configuration for the specified serviceType
   * (use primary service type when no serviceType is provided).
   *
   * @param {string} [serviceType] service type. eg. 'doctor'
   *
   * @returns {WhiteLabelServiceConfiguration} Current service configuration
   */
  getServiceConfigurationSync(serviceType?: string): WhiteLabelServiceConfiguration {
    const service: string = serviceType || this.getPrimaryServiceType();

    if (
      service &&
      this._currentWhiteLabelConfig?.services?.configurations &&
      this._currentWhiteLabelConfig.services.configurations[service]
    ) {
      return this._currentWhiteLabelConfig.services.configurations[service];
    }

    return this._getDefaultServiceConfiguration(service);
  }

  /**
   * @private
   * @function _getDefaultServiceConfiguration
   * @description Get the default DoD WhiteLabel service configuration
   *
   * @param {string} [serviceType] service type. eg. 'doctor'
   *
   * @returns {WhiteLabelServiceConfiguration} DoD (default) service configuration
   */
  private _getDefaultServiceConfiguration(serviceType?: string): WhiteLabelServiceConfiguration {
    const service: string = serviceType || Constants.SERVICE_TYPE.DOCTOR;

    let serviceConfig: WhiteLabelServiceConfiguration = {
      // Allow links out to the Marketing site
      enableMarketingSite: true,

      // Supported HealthCare cards
      enableConcessionCard: true,
      enableHealthcareCard: true,
      enableMedicareCard: true,
      enableSafetyNetCard: false,
      enableDVACard: false,

      // Supported features and services
      enableBenefits: true,
      enableLiveChat: true,
      enableQuickScript: true,
      enableSmokingCessation: true,
      enableReferrals: true,
      enableMedicalCertificates: true,
      enablePrescriptions: true,
      enableResults: true,
      enableMessages: true,
      enableInvoices: true,

      // DoD service types that feature a Questionnaire during an appointment (AppointmentQuestionnaire step)
      enableCustomQuestionnaire:
        serviceType === Constants.SERVICE_TYPE.FERTILITY || serviceType === Constants.SERVICE_TYPE.MENTAL_HEALTH,

      // Post-consult questionnaires (WaitingRoomComplete step)
      enablePostConsultQuestionnaire: true,

      // Post-quickscript questionnaires (OrderComplete step)
      enablePostQuickScriptQuestionnaire: true,

      // Prevent patients leaving the default flow (usually an appointment), if manually exited, the patient
      // is taken back to the Agency's website instead of the dashboard
      preventFlowEscape: false,

      // The primary [appointment] service type for DoD
      serviceType: service,

      // Service Flow is what is utilised by the StepService to determine the actual
      // steps to take / routes for the specified service type (for DoD, these remain the same)
      // eg. For the 'fertility' service type, the service flow will be 'doctor'
      serviceFlow: service,

      // Does the specified service type support onDemand appointments?
      enableOnDemand: this.serviceSupportsOnDemand(serviceType),

      // Overrides for warning messages
      strings: {
        controlledDrugs: null,
        controlledDrugsProceed: null,
        medicareRebatesWarning: null
      }
    };

    return serviceConfig;
  }

  /**
   * @function canProvideService
   * @description Determine whether the current agency is able to provide the specified serviceType.
   *
   * @param {string} serviceType service type. eg. 'doctor'
   *
   * @returns {boolean} true if agency can provide service for the specified serviceType
   */
  canProvideService(serviceType: string): boolean {
    if (
      this.currentWhiteLabelConfig?.services?.serviceTypes?.length &&
      Object.entries(this.currentWhiteLabelConfig?.services?.configurations)?.length
    ) {
      return (
        this.currentWhiteLabelConfig.services.serviceTypes.indexOf(serviceType) !== -1 &&
        !!this.currentWhiteLabelConfig.services.configurations[serviceType]
      );
    }

    /*
    if (this.serviceTypes && Object.keys(this.serviceTypes).length) {
      if (this.serviceTypes['*']) {
        return true;
      } else if (serviceType && this.serviceTypes[serviceType]) {
        return true;
      }
    }
    */

    return false;
  }

  serviceSupportsOnDemand(serviceType: string): boolean {
    return (
      serviceType === Constants.SERVICE_TYPE.DOCTOR ||
      serviceType === Constants.SERVICE_TYPE.FERTILITY ||
      serviceType === Constants.SERVICE_TYPE.SMOKING_CESSATION
    );
  }

  /**
   * @function canProvideAnyService
   * @description Determine whether the current agency is able to provide services specified in an
   * array of serviceTypes
   *
   * @param {string[]} serviceTypes array of serviceTypes. eg. ['doctor', 'bulkbillgp']
   *
   * @returns {boolean} true if agency can provide at least one of the specified services
   */
  canProvideAnyService(serviceTypes: string[]): boolean {
    if (Array.isArray(serviceTypes) && serviceTypes.length) {
      return serviceTypes?.some((serviceType: string) => this.canProvideService(serviceType));
    }

    return false;
  }

  /**
   * @function canProvideAppointments
   * @description Determine whether current agency can provide any one of the listed Appointment serviceTypes:
   * 'doctor', 'bulkbillgp', 'sleep-gp', 'sleep-specialist', 'weightloss', 'fertility'
   *
   * @returns {boolean} true if agency can provide any of the listed Appointment-based services
   */
  canProvideAppointments(): boolean {
    return this.canProvideAnyService([
      Constants.SERVICE_TYPE.DOCTOR,
      Constants.SERVICE_TYPE.BULKBILL_GP,
      Constants.SERVICE_TYPE.SLEEP_GP,
      Constants.SERVICE_TYPE.SLEEP_SPECIALIST,
      Constants.SERVICE_TYPE.WEIGHT_LOSS,
      Constants.SERVICE_TYPE.MENTAL_HEALTH,
      Constants.SERVICE_TYPE.FERTILITY,
      Constants.SERVICE_TYPE.SMOKING_CESSATION
    ]);
  }

  /**
   * @function canProvideQuickScripts
   * @description Determine whether current agency can provide any one of the listed QuickScript serviceTypes:
   * 'quickscriptpharm', 'quickscriptdeliver'
   *
   * @returns {boolean} true if agency can provide a QuickScript service
   */
  canProvideQuickScripts(): boolean {
    return this.canProvideAnyService([
      Constants.SERVICE_TYPE.QUICKSCRIPT_DELIVERY,
      Constants.SERVICE_TYPE.QUICKSCRIPT_PHARMACY
    ]);
  }

  /**
   * @function getFlowForServiceConfiguration
   * @description Determine the 'serviceFlow' for the specified 'serviceType'. This will be used by the
   * StepService to determine the step configuration and routes for the given service type.
   *
   * @param {string} [serviceType] service type. eg. 'doctor', 'quickscriptdeliver'
   *
   * @returns {string}
   */
  getFlowForServiceConfiguration(serviceType?: string): string {
    const serviceConfig: WhiteLabelServiceConfiguration = this.getServiceConfigurationSync(serviceType);

    if (serviceConfig?.serviceType) {
      return serviceConfig.serviceFlow || serviceConfig.serviceType;
    }

    return STEP_PATH.APPOINTMENT_TYPE_DOCTOR;
  }

  getOnDemandFlowForServiceConfiguration(serviceType?: string): string {
    if (this.serviceSupportsOnDemand(serviceType)) {
      switch (serviceType) {
        case Constants.SERVICE_TYPE.FERTILITY:
          return 'speak-to-fertility-consultant';
        case Constants.SERVICE_TYPE.SMOKING_CESSATION:
          return 'speak-to-a-smoking-cessation-doctor';
        default:
          return 'speak-to-doctor';
      }
    }

    return null;
  }

  marketingSiteEnabled(): boolean {
    return this.canProvideFunctionality('enableMarketingSite');
  }

  canProvideBenefits(): boolean {
    return this.canProvideFunctionality('enableBenefits');
  }

  shouldClearPatientSelectionOnAvailabilitySelection(): boolean {
    return this.canProvideFunctionality('clearPatientSelectionOnAvailabilitySelection');
  }

  canProvideLiveChat(): boolean {
    return this.canProvideFunctionality('enableLiveChat') && environment.environmentName !== 'development';
  }

  canProvideReferrals(): boolean {
    return this.canProvideFunctionality('enableReferrals');
  }

  canProvideMedicalCertificates(): boolean {
    return this.canProvideFunctionality('enableMedicalCertificates');
  }

  canProvidePrescriptions(): boolean {
    return this.canProvideFunctionality('enablePrescriptions');
  }

  quickScriptServiceEnabled(): boolean {
    return this.canProvideFunctionality('enableQuickScript');
  }

  smokingCessationServiceEnabled(): boolean {
    return this.canProvideFunctionality('enableSmokingCessation');
  }

  canProvideResults(): boolean {
    return this.canProvideFunctionality('enableResults');
  }

  canProvideMessages(): boolean {
    return this.canProvideFunctionality('enableMessages');
  }

  canProvideInvoices(): boolean {
    return this.canProvideFunctionality('enableInvoices');
  }

  enablePostConsultQuestionnaire(): boolean {
    return this.canProvideFunctionality('enablePostConsultQuestionnaire');
  }

  enablePostQuickScriptQuestionnaire(): boolean {
    return this.canProvideFunctionality('enablePostQuickScriptQuestionnaire');
  }

  enableWeightLoss(): boolean {
    return this.canProvideService('weightloss');
  }

  isMentalHealthEnabled(): boolean {
    return this.canProvideService('mentalhealth');
  }

  enableCustomQuestionnaire(): boolean {
    return this.canProvideFunctionality('enableCustomQuestionnaire');
  }

  isPreventFlowEscape(serviceType?: string): boolean {
    return this.canProvideFunctionality('preventFlowEscape', serviceType);
  }

  onDemandEnabledForServiceType(serviceType: string): boolean {
    const serviceConfiguration: WhiteLabelServiceConfiguration = this.getServiceConfigurationSync(serviceType);
    return Boolean(serviceConfiguration?.enableOnDemand);
  }

  onDemandEnabledForAppointments(serviceType: string): boolean {
    return this.onDemandEnabledForServiceType(serviceType || this.getPrimaryServiceType());
  }

  canProvideFunctionality(functionality: string, serviceType?: string): boolean {
    let enabled: boolean = false;

    try {
      if (Object.entries(this._currentWhiteLabelConfig?.services?.configurations)?.length) {
        if (serviceType && this._currentWhiteLabelConfig.services.configurations[serviceType]) {
          enabled = this._currentWhiteLabelConfig.services.configurations[serviceType][functionality];
        } else {
          for (let [_key, config] of Object.entries(this._currentWhiteLabelConfig.services.configurations)) {
            enabled = config[functionality];
            if (enabled) {
              break;
            }
          }
        }
      }
    } catch (err: any) {}

    return enabled;
  }

  // WhiteLabel Core Configuration
  get currentWhiteLabelConfig(): WhiteLabelConfigDTO {
    return this._currentWhiteLabelConfig;
  }
  set currentWhiteLabelConfig(config: WhiteLabelConfigDTO) {
    if (!this.functions.deepCompare(this._currentWhiteLabelConfig, config)) {
      this._whiteLabelConfig.next((this._currentWhiteLabelConfig = config || null));
    } else {
      this._currentWhiteLabelConfig = config;
    }
    // this._whiteLabelConfig.next((this._currentWhiteLabelConfig = config || null));
    this.aiContext.debugLog('WhiteLabelServiceConfigurationUpdated', { config });
  }

  // WhiteLabel UI Configuration
  get currentWhiteLabelUIConfig(): WhiteLabelUIConfig {
    return this._currentWhiteLabelUIConfig || null;
  }
  set currentWhiteLabelUIConfig(uiConfig: WhiteLabelUIConfig) {
    if (!this.functions.deepCompare(this._currentWhiteLabelUIConfig, uiConfig)) {
      this._whitelabelUIConfig.next((this._currentWhiteLabelUIConfig = uiConfig || null));
    } else {
      this._currentWhiteLabelUIConfig = uiConfig;
    }
    // this._whitelabelUIConfig.next((this._currentWhiteLabelUIConfig = uiConfig || null));
    this.aiContext.debugLog('WhiteLabelUIConfigurationUpdated', { uiConfig });
  }

  // Service Type
  get primaryServiceType(): string {
    return this._primaryServiceType;
  }

  // Agency Code
  get agencyCode(): string {
    return this.currentWhiteLabelConfig?.code;
  }
}
