import { Labels } from './constants';
import { PractitionerTypeConstants } from './models/practitioner-type';
import { commonStepConfigurations } from './step-configuration-utils';
import { commonStepHierarchy } from './step-configuration-utils-step-hierarchy';

/**
 * @description Step Configuration constants - provide paths (for step storage) and
 * virtual paths (for routes) for each step within each 'flow'. eg. appointment, quick-script, sign-up
 * @format
 */
export const STEP_PATH = {
  // Doctor
  APPOINTMENT_TYPE_DOCTOR: 'doctor',
  ONDEMAND_TYPE_DOCTOR: 'speak-to-doctor',

  // Fertility
  APPOINTMENT_TYPE_FERTILITY: 'fertility',
  ONDEMAND_TYPE_FERTILITY: 'fertility-referral',

  // MentalHealth
  APPOINTMENT_TYPE_MENTALHEALTH: 'mentalhealth',

  // SmokingCessation
  APPOINTMENT_TYPE_SMOKINGCESSATION: PractitionerTypeConstants.smokingcessation,
  SMOKING_CESSATION: 'smoking-cessation',
  ONDEMAND_TYPE_SMOKING_CESSATION: 'speak-to-a-smoking-cessation-doctor',
  PRODUCTS: 'products',

  // QuickScript
  QUICK_SCRIPT: 'quickscript',

  // WeightLoss
  APPOINTMENT_TYPE_WEIGHTLOSS: 'weightloss',
  WEIGHT_LOSS_CHECKIN: 'weight-loss',

  // Other routes
  DASHBOARD: 'dashboard',
  WAITING_ROOM: 'your-appointment',
  APPOINTMENT: 'appointment',
  YOUR_ACCOUNT: 'your-account',
  SIGN_UP: 'signup',
  LOGIN: 'login',
  INBOX: 'messages',
  FALLBACK: 'fallback',
  RESET_PASSWORD: 'reset-password',
  CONTACT_US: 'contact-us',
  TEST_DEVICE: 'test-your-device',
  HEALTHCARE: 'healthcare'
};

export const ONDEMAND_STEP_PATH_MAPPING = {
  APPOINTMENT_TYPE_DOCTOR: 'ONDEMAND_TYPE_DOCTOR',
  APPOINTMENT_TYPE_FERTILITY: 'ONDEMAND_TYPE_FERTILITY',
  APPOINTMENT_TYPE_SMOKINGCESSATION: 'ONDEMAND_TYPE_SMOKING_CESSATION'
};

export const STEP_CONFIGURATION = {
  SMOKING_CESSATION: {
    PRODUCT_SELECTION: {
      path: 'medications',
      virtualPath: 'medications',
      label: Labels.productSelection
    },
    CHECKOUT: {
      path: 'checkout',
      virtualPath: 'checkout',
      label: Labels.confirmPayment
    },
    ORDER_PLACED: {
      path: 'order-complete',
      virtualPath: 'order-complete',
      label: Labels.orderPlaced
    },
    PHARMACY_PICKUP: {
      path: 'pharmacy-pickup',
      virtualPath: 'pharmacy-pickup',
      label: Labels.pharmacyPickup
    }
  },
  APPOINTMENT_TYPE_DOCTOR: {
    // BEFORE_YOU_PROCEED: { path: 'appointment-intro', virtualPath: 'before-you-proceed', label: Labels.beforeProceed },
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  ONDEMAND_TYPE_DOCTOR: {
    // BEFORE_YOU_PROCEED: { path: 'appointment-intro', virtualPath: 'before-you-proceed', label: Labels.beforeProceed },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  APPOINTMENT_TYPE_PSYCHOLOGY: {
    // BEFORE_YOU_PROCEED: { path: 'appointment-intro', virtualPath: 'before-you-proceed', label: Labels.beforeProceed },
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    QUESTIONNAIRE: {
      path: 'appointment-questionnaire',
      virtualPath: 'mentalhealth-questionnaire',
      label: Labels.mentalHealthQuestionnaire
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  APPOINTMENT_TYPE_FERTILITY: {
    // BEFORE_YOU_PROCEED: { path: 'appointment-intro', virtualPath: 'before-you-proceed', label: Labels.beforeProceed },
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    QUESTIONNAIRE: {
      path: 'appointment-questionnaire',
      virtualPath: 'fertility-questionnaire',
      label: Labels.fertilityQuestionnaire
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  APPOINTMENT_TYPE_SUPERPHARMACY: {
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  APPOINTMENT_TYPE_WEIGHTLOSS: {
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  APPOINTMENT_TYPE_MENTALHEALTH: {
    CHOOSE_APPOINTMENT_TIME: {
      path: 'appointment-time-table',
      virtualPath: 'choose-an-appointment-time',
      label: Labels.chooseTime
    },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-confirm-med-history',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    QUESTIONNAIRE: {
      path: 'appointment-questionnaire',
      virtualPath: 'mentalhealth-questionnaire',
      label: Labels.mentalHealthQuestionnaire
    },
    REDEEM_BENEFIT_AND_CONSENT: {
      path: 'benefit-and-consent',
      virtualPath: 'confirm-your-appointment-details',
      label: Labels.consentQuestions
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'appointment-payment', virtualPath: 'checkout', label: Labels.payment },
    CHAT_WITH_YOUR_PRACTITIONER: {
      path: 'appointment-booked',
      virtualPath: 'appointment-booked',
      label: Labels.chatWithPractitioner
    }
  },
  APPOINTMENT_TYPE_SMOKINGCESSATION: {
    ...commonStepConfigurations
  },
  QUICK_SCRIPT: {
    SIGNUP_OR_LOGIN: {
      path: 'signup-or-login',
      virtualPath: 'signup-or-login',
      label: Labels.registerLogin
    },
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    HEALTHCARE_CARD_DETAILS: {
      path: 'healthcare-cards',
      virtualPath: 'healthcare-details',
      label: Labels.healthcareDetails
    },
    MEDICATION_DETAIL: { path: 'medication-detail', virtualPath: null, label: Labels.medicationDetail },
    MEDICATION_1: { path: 'quickscript-med1', virtualPath: 'medication-1', label: 'medication-1' },
    // MEDICATION_2: { path: 'quickscript-med2', virtualPath: 'medication-2', label: 'medication-2' },
    CONFIRM_MEDICAL_HISTORY: {
      path: 'confirm-med-history',
      virtualPath: 'confirm-medical-history',
      label: Labels.confirmMedicalHistory
    },
    CONSENT_QUESTIONS: {
      path: 'quickscript-consent',
      virtualPath: 'consent-questions',
      label: Labels.consentQuestions
    },
    // SELECT_PHARMACY: {
    //   path: 'confirm-address-select-pharmacy',
    //   virtualPath: 'select-your-pharmacy',
    //   label: Labels.selectPharmacy
    // },
    CONFIRM_ADDRESS: {
      path: 'confirm-address', // 'confirm-address-select-pharmacy'
      virtualPath: 'confirm-shipping-address',
      label: Labels.addressConfirm
    },
    PATIENT_VERIFICATION: {
      path: 'patient-verification',
      virtualPath: 'patient-verification',
      label: Labels.patientVerification
    },
    PAYMENT: { path: 'quickscript-payment', virtualPath: 'checkout', label: Labels.confirmPayment },
    QUICKSCRIPT_COMPLETE: {
      path: 'quickscript-complete',
      virtualPath: 'quickscript-complete',
      label: Labels.qsComplete
    }
  },
  SIGN_UP: {
    ACCOUNT_HOLDER: { path: 'signup-account-holder', virtualPath: 'account-holder-data', label: Labels.accountHolder },
    PATIENT_DATA: { path: 'signup-patient-data', virtualPath: 'patient-data', label: Labels.patientData },
    MEDICAL_HISTORY: { path: 'signup-med-history', virtualPath: 'medical-history', label: Labels.medicalHistory }
    // POST_SIGNUP_LOGIN: { path: 'post-signup-login', virtualPath: 'signup-login', label: Labels.login }
  },
  WAITING_ROOM: {
    WAITING_ROOM_BEFORE: { path: 'waiting-room-before', virtualPath: 'waiting-room', label: Labels.waitingRoom },
    IN_APPOINTMENT: { path: 'in-appointment', virtualPath: 'in-appointment', label: Labels.inAppointment },
    CONFIRM_YOUR_INFORMATION: {
      path: 'post-appointment-confirmation',
      virtualPath: 'post-appointment-additional-information',
      label: Labels.confirmInfo
    },
    APPOINTMENT_COMPLETE: {
      path: 'appointment-complete',
      virtualPath: 'appointment-complete',
      label: Labels.appointmentComplete
    }
  }
};

export const SERVICE_STEP_PATH_MAPPING = {
  // DoD scheduled doctor appointment flow
  bulkbillgp: [
    STEP_PATH.APPOINTMENT,
    STEP_PATH.APPOINTMENT_TYPE_DOCTOR,
    STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.virtualPath
  ],
  doctor: [
    STEP_PATH.APPOINTMENT,
    STEP_PATH.APPOINTMENT_TYPE_DOCTOR,
    STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.virtualPath
  ],
  // DoD on-demand appointment flow
  'speak-to-doctor': [STEP_PATH.APPOINTMENT, 'speak-to-doctor'],

  // City Fertility / Rainbow Fertility scheduled appointment flow
  fertility: [
    STEP_PATH.APPOINTMENT,
    STEP_PATH.APPOINTMENT_TYPE_FERTILITY,
    STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CHOOSE_APPOINTMENT_TIME.virtualPath
  ],
  // City Fertility / Rainbow Fertility on-demand apointment flow
  'speak-to-fertility-consultant': [
    STEP_PATH.APPOINTMENT,
    STEP_PATH[ONDEMAND_STEP_PATH_MAPPING.APPOINTMENT_TYPE_FERTILITY]
  ],

  // Weight Loss scheduled appointment flow (no on-demand!)
  weightloss: [
    STEP_PATH.APPOINTMENT,
    STEP_PATH.APPOINTMENT_TYPE_WEIGHTLOSS,
    STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CHOOSE_APPOINTMENT_TIME.virtualPath
  ],
  mentalhealth: [
    STEP_PATH.APPOINTMENT,
    STEP_PATH.APPOINTMENT_TYPE_MENTALHEALTH,
    STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CHOOSE_APPOINTMENT_TIME.virtualPath
  ],
  smokingcessation: [
    STEP_PATH.APPOINTMENT,
    STEP_PATH.APPOINTMENT_TYPE_SMOKINGCESSATION,
    STEP_CONFIGURATION.APPOINTMENT_TYPE_SMOKINGCESSATION.CHOOSE_APPOINTMENT_TIME.virtualPath
  ],
  'speak-to-a-smoking-cessation-doctor': [
    STEP_PATH.APPOINTMENT,
    STEP_PATH[ONDEMAND_STEP_PATH_MAPPING.APPOINTMENT_TYPE_SMOKINGCESSATION]
  ]
};

export const STEP_HIERARCHY = {
  // Doctor Appointment flow
  APPOINTMENT_TYPE_DOCTOR: [
    {
      label: Labels.chooseTime,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    /*
    {
      label: Labels.bookAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: true,
      partList: [
        {
          label: Labels.beforeProceed,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.BEFORE_YOU_PROCEED.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.BEFORE_YOU_PROCEED.path,
          display: true,
          selected: true,
          finish: false,
          isClickable: true,
        },
        {
          label: Labels.chooseTime,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    */
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],
  APPOINTMENT_TYPE_SMOKINGCESSATION: [
    commonStepHierarchy.chooseTime,
    commonStepHierarchy.registerLogin,
    commonStepHierarchy.confirmMedicalHistory,
    commonStepHierarchy.healthcareDetails,
    commonStepHierarchy.confirmAppointment,
    commonStepHierarchy.chatWithPractitioner
  ],

  // Fertility Whitelabel Doctor Appointment flow
  APPOINTMENT_TYPE_FERTILITY: [
    {
      label: Labels.chooseTime,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CHOOSE_APPOINTMENT_TIME.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CHOOSE_APPOINTMENT_TIME.path,
      display: true,
      finish: false,
      isClickable: true,
      partList: []
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.fertilityQuestionnaire,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.QUESTIONNAIRE.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.QUESTIONNAIRE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_FERTILITY.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],

  // SUPER-PHARMACY Appointment flow
  APPOINTMENT_TYPE_SUPERPHARMACY: [
    {
      label: Labels.chooseTime,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.CHOOSE_APPOINTMENT_TIME.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.CHOOSE_APPOINTMENT_TIME.path,
      display: true,
      finish: false,
      isClickable: true,
      partList: []
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SUPERPHARMACY.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],

  // WeightLoss Appointment flow
  APPOINTMENT_TYPE_WEIGHTLOSS: [
    {
      label: Labels.chooseTime,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CHOOSE_APPOINTMENT_TIME.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CHOOSE_APPOINTMENT_TIME.path,
      display: true,
      finish: false,
      isClickable: true,
      partList: []
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WEIGHTLOSS.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],

  // Mental health Appointment flow
  APPOINTMENT_TYPE_MENTALHEALTH: [
    {
      label: Labels.chooseTime,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CHOOSE_APPOINTMENT_TIME.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CHOOSE_APPOINTMENT_TIME.path,
      display: true,
      finish: false,
      isClickable: true,
      partList: []
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.mentalHealthQuestionnaire,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.QUESTIONNAIRE.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.QUESTIONNAIRE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_MENTALHEALTH.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],

  /*
  // Sleep Appointment flow
  APPOINTMENT_TYPE_SLEEP: [
    {
      label: Labels.bookAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: true,
      partList: [
        {
          label: Labels.beforeProceed,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.BEFORE_YOU_PROCEED.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.BEFORE_YOU_PROCEED.path,
          display: true,
          selected: true,
          finish: false,
          isClickable: true,
        },
        {
          label: Labels.chooseTime,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.CHOOSE_APPOINTMENT_TIME.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.CHOOSE_APPOINTMENT_TIME.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: [],
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
    {
      label: Labels.sleepQuestionnaire,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.QUESTIONNAIRE.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.QUESTIONNAIRE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.emailMobileVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_SLEEP.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
  ],
  */

  /*
  // Psychologist flow
  APPOINTMENT_TYPE_PSYCHOLOGY: [
    {
      label: Labels.bookAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: true,
      partList: [
        {
          label: Labels.beforeProceed,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.BEFORE_YOU_PROCEED.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.BEFORE_YOU_PROCEED.path,
          display: true,
          selected: true,
          finish: false,
          isClickable: true,
        },
        {
          label: Labels.chooseTime,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.CHOOSE_APPOINTMENT_TIME.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.CHOOSE_APPOINTMENT_TIME.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.MEDICAL_HISTORY.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.MEDICAL_HISTORY.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.CONFIRM_MEDICAL_HISTORY.path,
      display: false, // do not show by default
      finish: false,
      isClickable: false,
      partList: [],
    },
    {
      label: Labels.qeQuestion,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.QUESTIONNAIRE.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.QUESTIONNAIRE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      stepPath: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.REDEEM_BENEFIT_AND_CONSENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.emailMobileVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.PAYMENT.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.PAYMENT.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_PSYCHOLOGY.CHAT_WITH_YOUR_PRACTITIONER.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
  ],
  */

  /*
  // Dietitian flow
  APPOINTMENT_TYPE_DIETITIAN: [
    {
      label: Labels.bookAppointment,
      link: null,
      display: true,
      finish: false,
      isClickable: true,
      partList: [
        {
          label: Labels.beforeProceed,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.BEFORE_YOU_PROCEED.virtualPath,
          display: true,
          selected: true,
          finish: false,
          isClickable: true,
        },
        {
          label: Labels.chooseTime,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHOOSE_APPOINTMENT_TIME.virtualPath,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.SIGNUP_OR_LOGIN.virtualPath,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.ACCOUNT_HOLDER.virtualPath,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_DATA.virtualPath,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.MEDICAL_HISTORY.virtualPath,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.REDEEM_BENEFIT_AND_CONSENT.virtualPath,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.emailMobileVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.PAYMENT.virtualPath,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_DOCTOR.CHAT_WITH_YOUR_PRACTITIONER.virtualPath,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
  ],
  */

  /*
  // Wellness Specialist flow
  APPOINTMENT_TYPE_WELLNESS: [
    {
      label: Labels.bookAppointment,
      link: null,
      display: true,
      finish: false,
      isClickable: true,
      partList: [
        {
          label: Labels.beforeProceed,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.BEFORE_YOU_PROCEED,
          display: true,
          selected: true,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.chooseTime,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.CHOOSE_APPOINTMENT_TIME,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.SIGNUP_OR_LOGIN,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.ACCOUNT_HOLDER,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.PATIENT_DATA,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.medicalHistory,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.MEDICAL_HISTORY,
          display: false,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.confirmAppointment,
      link: null,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.redeemBenefit,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.REDEEM_BENEFIT_AND_CONSENT,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
        {
          label: Labels.emailMobileVerification,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.PATIENT_VERIFICATION.virtualPath,
          stepPath: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.PATIENT_VERIFICATION.path,
          display: true,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.payment,
          link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.PAYMENT,
          display: true,
          selected: false,
          finish: false,
          isClickable: false,
        },
      ],
    },
    {
      label: Labels.chatWithPractitioner,
      link: STEP_CONFIGURATION.APPOINTMENT_TYPE_WELLNESS.CHAT_WITH_YOUR_PRACTITIONER,
      display: true,
      finish: false,
      isClickable: false,
      partList: [],
    },
  ],
  */

  // QuickScript flow
  QUICK_SCRIPT: [
    {
      label: Labels.registerLogin,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.SIGNUP_OR_LOGIN.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.SIGNUP_OR_LOGIN.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: [
        {
          label: Labels.accountHolder,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.QUICK_SCRIPT.ACCOUNT_HOLDER.virtualPath,
          stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.ACCOUNT_HOLDER.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        },
        {
          label: Labels.patientData,
          link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.QUICK_SCRIPT.PATIENT_DATA.virtualPath,
          stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.PATIENT_DATA.path,
          display: false,
          selected: false,
          finish: false,
          isClickable: false
        }
        // {
        //   label: Labels.medicalHistory,
        //   link: STEP_PATH.SIGN_UP + '/' + STEP_CONFIGURATION.QUICK_SCRIPT.MEDICAL_HISTORY.virtualPath,
        //   stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.MEDICAL_HISTORY.path,
        //   display: false,
        //   selected: false,
        //   finish: false,
        //   isClickable: false
        // }
      ]
    },
    {
      label: Labels.healthcareDetails,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.HEALTHCARE_CARD_DETAILS.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.HEALTHCARE_CARD_DETAILS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.medicationDetail,
      link: null, // no route available
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_DETAIL.path,
      display: true,
      finish: false,
      isClickable: true,
      partList: [
        {
          label: Labels.med1,
          link: STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_1.virtualPath,
          stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_1.path,
          display: true,
          selected: true,
          finish: true,
          isClickable: false
        }
      ]
    },
    {
      label: Labels.confirmMedicalHistory,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.CONFIRM_MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.CONFIRM_MEDICAL_HISTORY.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.consentQuestions,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.CONSENT_QUESTIONS.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.CONSENT_QUESTIONS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.addressConfirm,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.CONFIRM_ADDRESS.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.CONFIRM_ADDRESS.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.patientVerification,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.PATIENT_VERIFICATION.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.PATIENT_VERIFICATION.path,
      display: false,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmPayment,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.PAYMENT.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.PAYMENT.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.qsComplete,
      link: STEP_CONFIGURATION.QUICK_SCRIPT.QUICKSCRIPT_COMPLETE.virtualPath,
      stepPath: STEP_CONFIGURATION.QUICK_SCRIPT.QUICKSCRIPT_COMPLETE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],

  // Independent Sign-up flow
  SIGN_UP: [
    {
      label: Labels.accountHolder,
      link: STEP_CONFIGURATION.SIGN_UP.ACCOUNT_HOLDER.virtualPath,
      stepPath: STEP_CONFIGURATION.SIGN_UP.ACCOUNT_HOLDER.path,
      display: true,
      finish: false,
      isClickable: true,
      partList: []
    },
    {
      label: Labels.patientData,
      link: STEP_CONFIGURATION.SIGN_UP.PATIENT_DATA.virtualPath,
      stepPath: STEP_CONFIGURATION.SIGN_UP.PATIENT_DATA.path,
      display: false,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.medicalHistory,
      link: STEP_CONFIGURATION.SIGN_UP.MEDICAL_HISTORY.virtualPath,
      stepPath: STEP_CONFIGURATION.SIGN_UP.MEDICAL_HISTORY.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ],

  // Waiting Room (In-Appointment flow)
  WAITING_ROOM: [
    {
      label: Labels.waitingRoom,
      link: STEP_CONFIGURATION.WAITING_ROOM.WAITING_ROOM_BEFORE.virtualPath,
      stepPath: STEP_CONFIGURATION.WAITING_ROOM.WAITING_ROOM_BEFORE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.inAppointment,
      link: STEP_CONFIGURATION.WAITING_ROOM.IN_APPOINTMENT.virtualPath,
      stepPath: STEP_CONFIGURATION.WAITING_ROOM.IN_APPOINTMENT.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.confirmInfo,
      link: STEP_CONFIGURATION.WAITING_ROOM.CONFIRM_YOUR_INFORMATION.virtualPath,
      stepPath: STEP_CONFIGURATION.WAITING_ROOM.CONFIRM_YOUR_INFORMATION.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    },
    {
      label: Labels.appointmentComplete,
      link: STEP_CONFIGURATION.WAITING_ROOM.APPOINTMENT_COMPLETE.virtualPath,
      stepPath: STEP_CONFIGURATION.WAITING_ROOM.APPOINTMENT_COMPLETE.path,
      display: true,
      finish: false,
      isClickable: false,
      partList: []
    }
  ]
};
