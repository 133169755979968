import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from '@app/shared/functions';
import { AppInsightsService } from '@app/shared/services/appinsights.service';
import { AuthenticationService } from '@src/app/core/services/authentication.service';
import { CredentialsService } from '@src/app/core/services/credentials.service';
import { Constants } from '@src/app/shared/constants';
import { Credentials } from '@src/app/shared/models/credentials';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { SessionStorageService } from 'ngx-webstorage';
import { first } from 'rxjs';
import { B2B_STEP_PATH } from '../../../business-portal-routing.module';
import {
  B2BCustomerBookingStatus,
  B2BPortalUserLoginAccessTokens
} from '@src/app/shared/models/b2b-customer-portal/models';

@Component({
  selector: 'business-portal-patient-booking-login',
  templateUrl: './business-portal-patient-booking-login.component.html',
  styleUrls: ['./business-portal-patient-booking-login.component.scss']
})
export class BusinessPortalPatientBookingLoginComponent implements OnInit {
  formGroupSend: FormGroup;
  formGroupVerify: FormGroup;

  isSubmitting: boolean;

  invalidDomainError: boolean;
  invalidPasswordOrCode: boolean;

  enrolmentId: string;

  B2BCustomerBookingStatus = B2BCustomerBookingStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private functions: Functions,
    private aiService: AppInsightsService,
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    // log out any existing user as we are going to be logging in with business account
    this.authenticationService.logoutQuiet();

    const serviceType: string = this.activatedRoute.snapshot.paramMap.get('serviceType');

    this.b2bCustomerService.setServiceType(serviceType);

    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.UserSendCode);

    if (!this.b2bCustomerService.b2bCode) {
      this.functions.showToast('No business has been set for accessing the business portal');
    }

    this.createForms();
  }

  createForms(): void {
    this.formGroupSend = this.formBuilder.group({
      email: [
        this.b2bCustomerService.getVerifiedBusinessUser,
        [Validators.required, Validators.pattern(Constants.emailRegexString)]
      ]
    });

    this.formGroupVerify = this.formBuilder.group({
      password: [null, [Validators.required]],
      mfacode: [null, [Validators.required]]
    });
  }

  get email() {
    return this.formGroupSend.get('email');
  }

  get mfacode() {
    return this.formGroupVerify.get('mfacode');
  }

  get password() {
    return this.formGroupVerify.get('password');
  }

  async sendCode() {
    if (!this.formGroupSend.valid) {
      return;
    } else {
      this.formGroupSend.setErrors(null);
      this.formGroupSend.markAsPristine();
    }

    this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first()).subscribe(async (info) => {
      if (info) {
        this.isSubmitting = true;
        const enrolmentId: string = await this.b2bCustomerService.validateEmailDomainAndSendCode(this.email.value);

        this.invalidDomainError = !enrolmentId;

        if (enrolmentId) {
          this.functions.showToast('Code notification sent successfully');
          this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.UserVerifyCodeAndPassword);
        }

        this.enrolmentId = enrolmentId;
        this.isSubmitting = false;
      } else {
        console.error('ERROR in sendCode');
      }
    });
  }

  async verifyPasswordAndCode() {
    if (!this.formGroupVerify.valid) {
      return;
    } else {
      this.formGroupVerify.setErrors(null);
      this.formGroupVerify.markAsPristine();
    }

    this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first()).subscribe(async (info) => {
      if (info) {
        this.isSubmitting = true;
        const tokens: B2BPortalUserLoginAccessTokens = await this.b2bCustomerService.verifyB2BPasswordAndEmailCode(
          this.email.value,
          this.password.value,
          this.enrolmentId,
          this.mfacode.value
        );

        this.b2bCustomerService.setResourceAccessToken(tokens.resourceAccessToken);

        this.invalidPasswordOrCode = !tokens.jwtAccessToken;
        this.isSubmitting = false;

        if (tokens.jwtAccessToken) {
          this.b2bCustomerService.setVerifiedBusinessUser(this.email.value);
          this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PolicyNumber);

          const credentials: Credentials = {
            email: this.email.value,
            accessToken: tokens.jwtAccessToken.accessToken,
            refreshToken: tokens.jwtAccessToken.refreshToken,
            expiresInSeconds: tokens.jwtAccessToken.expiresInSeconds
          };
          // login as the B2B user
          this.credentialsService.setCredentials(credentials);
          this.sessionStorageService.store('b2bbookingmode', true);
          this.router.navigate(['../../', B2B_STEP_PATH.POLICY_NUMBER], { relativeTo: this.activatedRoute });
        }
      } else {
        console.error('ERROR in verifyPasswordAndCode');
      }
    });
  }
}
