<div id="pwa-app-root" [class.virtualKeyboardOpen]="virtualKeyboardVisible">
  <router-outlet></router-outlet>
  <livechat-widget
    *ngIf="liveChatWidgetOpen"
    [class.d-none]="liveChatVisibility === 'hidden'"
    [license]="liveChatLicense"
    [customerName]="patientName"
    [customerEmail]="patientEmail"
    [visibility]="liveChatVisibility"
    (onNewEvent)="handleLiveChatNewEvent($event)"
    (onVisibilityChanged)="handleLiveChatVisibility($event)"
  >
  </livechat-widget>
</div>
