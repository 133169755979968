import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants, Labels } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { IResponseAPI } from '@app/shared/models/api-response';
import { Appointment } from '@app/shared/models/appointment';
import { AppointmentHours } from '@app/shared/models/appointment-hours';
import { AvailabilityDateRange } from '@app/shared/models/availability-date-range';
import { AvailabilityGroups } from '@app/shared/models/availabilityGroups';
import { AvailabilityGroupsForDay } from '@app/shared/models/availabilityGroupsForDay';
import { AvailabilitySearch } from '@app/shared/models/availabilitySearch';
import { Benefit } from '@app/shared/models/benefit';
import { Practitioner } from '@app/shared/models/practitioner';
import { PractitionerGender } from '@app/shared/models/practitionerGender';
import { Session } from '@app/shared/models/session';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { ModalsService } from '@app/shared/services/modals.service';
import { PatientService } from '@app/shared/services/patient.service';
import { PractitionerService } from '@app/shared/services/practitioner.service';
import { PricingService } from '@app/shared/services/pricing.service';
import { StepService } from '@app/shared/services/step.service';
import { STEP_CONFIGURATION, STEP_PATH } from '@app/shared/step-configuration';
import { AppointmentPricingData } from '../../models/appointment-pricing-data';
import { AppointmentDTO } from '../../models/appointmentDTO';
// import { ISessionInfo } from '../../models/availabilities/segmented/SegmentedAvailabilities';
import { RecentlyBookedAppointment } from '../../models/recentlyBookedAppointment';
import { TimezoneService } from '../../services/timezone.service';
import { ErrorMessageModalComponent } from '../error-message-modal/error-message-modal.component';
// import { AvailabilityPricingItem } from '../../models/availabilities/AvailabilityConstraints';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.scss']
})
export class RescheduleAppointmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('schedule') schedule: ElementRef;

  private _currentSelectedDate: moment.Moment;
  private _availabilityGroupsArray: AvailabilityGroups[];
  private subscription = new Subscription();

  dialogData: any;
  today: moment.Moment;
  lastDay: moment.Moment;
  todayString: string;
  lastDayString: string;
  selectedDateIsToday: boolean;
  selectedDateIsLastDay: boolean;
  displayedDate: string;
  isConfirmation: boolean = false;
  isLoading: boolean = true;
  isMobile: boolean;
  isFilteredData: boolean;
  isNarrowMobile: boolean = false;

  sessions: Session[];
  rowCount: Session[][] = [];
  columnCount: Session[] = [];

  oldAppointment: Appointment;
  newAppointment: Appointment;
  newPractitioner: Practitioner;
  oldDisplayDate: moment.Moment;
  newDisplayDate: moment.Moment;

  availabilitySearch: AvailabilitySearch;
  availability: AvailabilitySearch;
  timeZoneOffset: string;
  numericTimezoneOffset: number = Constants.Default_TimeZone_Offset;
  appointmentTimeZoneLabel: string;

  appointmentDayDate: string;
  appointmentDayMonth: string;
  appointmentDayWeekday: string;
  appointmentTime: string;
  newAppointmentPrice: number = 0;

  oldAppointmentIsBusinessHours: boolean = false;
  oldAppointmentDayDate: string;
  oldAppointmentDayMonth: string;
  oldAppointmentDayWeekday: string;
  oldAppointmentTime: string;
  oldAppointmentPrice: number = 0;

  appliedBenefit: Benefit = null;

  recentlyBookedErrorMessage: string = '';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<RescheduleAppointmentComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private functions: Functions,
    private timezoneService: TimezoneService,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private practitionerService: PractitionerService,
    private pricingService: PricingService,
    private dialog: MatDialog,
    private analytics: GoogleAnalyticsService,
    private modalService: ModalsService,
    private stepService: StepService
  ) {
    this.dialogData = this.data;

    this.onResize();

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
    this.isNarrowMobile = window.innerWidth <= 423;

    if (Array.isArray(this._availabilityGroupsArray) && this._availabilityGroupsArray.length && !this.isConfirmation) {
      this.createSessionMatrix(this._availabilityGroupsArray, this.getTimeZoneOffset());
    }
  }

  ngOnInit(): void {
    this._init();
  }

  ngAfterViewInit(): void {
    // AVAILABILITY PARAMS
    this.subscription.add(
      this.appointmentService.availabilityParamsChangeObs.subscribe((availability: AvailabilitySearch) => {
        this.availability = availability;
        this._init();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async _init(): Promise<void> {
    this.availability = this.availability ?? this.appointmentService.availabilityParameter;

    this.checkIsFilteredData(this.availability);

    if (this.data.appointment) {
      this.oldAppointment = { ...(this.data.appointment as Appointment) };
    } else if (this.appointmentService.appointment) {
      this.oldAppointment = { ...this.appointmentService.appointment };
    }

    this.timeZoneOffset = this.getTimeZoneOffset();
    this.numericTimezoneOffset = this.timeZoneOffset
      ? parseFloat(this.timeZoneOffset)
      : Constants.Default_TimeZone_Offset;

    this.updateTimeZoneLabel();

    this.today = this.functions.getUTCMoment(this.timeZoneOffset || undefined);
    this.lastDay = this.today.clone().add(Constants.AppointmentTimeTable_Configuration.maxDaysToDisplay, 'days');

    this.todayString = this.today.clone().utcOffset(this.numericTimezoneOffset).format(Constants.YearFirstDate);
    this.lastDayString = this.lastDay.clone().utcOffset(this.numericTimezoneOffset).format(Constants.YearFirstDate);

    if (this.oldAppointment) {
      if (typeof this.oldAppointment.businessHours === 'boolean') {
        this.oldAppointmentIsBusinessHours = this.oldAppointment.businessHours;
      } else if (typeof this.oldAppointment.isAfterHours === 'boolean') {
        this.oldAppointmentIsBusinessHours = !this.oldAppointment.isAfterHours;
      }

      const oldAppointmentStartTime: string = this.oldAppointment.startTimeUTC || this.oldAppointment.start;

      // Previous appointment details
      this.oldDisplayDate = this.functions.getUTCMoment(this.timeZoneOffset, false, false, oldAppointmentStartTime);

      this.oldAppointmentDayDate = this.oldDisplayDate.clone().utcOffset(this.numericTimezoneOffset).format('DD');
      this.oldAppointmentDayMonth = this.oldDisplayDate
        .clone()
        .utcOffset(this.numericTimezoneOffset)
        .format('MMM')
        .toUpperCase();
      this.oldAppointmentDayWeekday = this.oldDisplayDate.clone().utcOffset(this.numericTimezoneOffset).format('dddd');
      this.oldAppointmentTime = this.timezoneService.getFormattedSessionDisplayTime(
        this.oldAppointment.startTimeUTC || this.oldAppointment.start,
        this.timeZoneOffset
      );

      this.oldAppointmentPrice = await this.getDiscountedAppointmentPrice(this.oldAppointment);

      console.log('[RESCHEDULE] Old appointment price:', this.oldAppointmentPrice);

      this._currentSelectedDate = this.functions
        .getUTCMoment(this.timeZoneOffset, false, false, oldAppointmentStartTime)
        .subtract(1, 'day'); // one day will be added by the while loop below

      // If there are availabilities for the same date as the originally selected appointment, set date to that date,
      // else set the date to the next date with availabilities
      let doesCurrentSelectedDateHaveAvailabilities: boolean = false;
      while (
        !doesCurrentSelectedDateHaveAvailabilities &&
        this._currentSelectedDate.isSameOrBefore(this.lastDay, 'date')
      ) {
        this._currentSelectedDate = this._currentSelectedDate.clone().add(1, 'day');

        // Determine date label to display
        this.setDisplayDate();

        // New appointment details (initialise only)
        this.newAppointment = {
          start: this._currentSelectedDate
            .clone()
            .utcOffset(this.numericTimezoneOffset)
            .format(Constants.UTC_Date_Format_Shortened)
        };

        await this.getAvailabilityData();

        if (this.rowCount?.length) {
          doesCurrentSelectedDateHaveAvailabilities = true;
        }
      }
    } else {
      this.router.navigate([STEP_PATH.DASHBOARD]).then(() => {
        this.functions.showToast('Appointment details could not be found!');
      });
      this.onClose(true);
    }
  }

  updateTimeZoneLabel(): void {
    this.appointmentTimeZoneLabel = this.functions.removeCountryFromTimeZone(this.getTimeZoneOffsetLabel());
  }

  checkIsFilteredData(availabilityFilter: AvailabilitySearch): void {
    this.isFilteredData = Boolean(
      (availabilityFilter?.sex && availabilityFilter.sex != PractitionerGender.All) ||
        (availabilityFilter?.languageId && availabilityFilter.languageId !== 'All') ||
        (availabilityFilter?.hours && availabilityFilter.hours != AppointmentHours.All) ||
        availabilityFilter?.practitionerIdsToLimitBy ||
        availabilityFilter?.shouldLimitByAgencyCode
    );
  }

  setDisplayDate(): void {
    let dayDiff: number;

    try {
      const selectedDayString: string = this._currentSelectedDate
        .clone()
        .utcOffset(this.numericTimezoneOffset)
        .format(Constants.YearFirstDate);

      if (this.todayString === selectedDayString) {
        dayDiff = 0;
      } else {
        const todayMoment: moment.Moment = this.functions.getUTCMoment(
          this.timeZoneOffset, // offset string
          true, // is day start?
          false, // is day end?
          this.todayString // seed date
        );
        const selectedDayMoment: moment.Moment = this.functions.getUTCMoment(
          this.timeZoneOffset, // offset string
          true, // is day start?
          false, // is day end?
          selectedDayString // seed date
        );

        dayDiff = Math.abs(selectedDayMoment.diff(todayMoment, 'days'));
      }
    } catch (err: any) {}

    // const diff: number = Math.abs(Math.floor(this.today.diff(this._currentSelectedDate, 'days', true)));
    switch (dayDiff) {
      case 0:
        this.displayedDate = `Today, ${this._currentSelectedDate.clone().utcOffset(this.numericTimezoneOffset).format('Do MMMM')}`;
        break;
      case 1:
        this.displayedDate = `Tomorrow, ${this._currentSelectedDate.clone().utcOffset(this.numericTimezoneOffset).format('Do MMMM')}`;
        break;
      default:
        this.displayedDate = this._currentSelectedDate
          .clone()
          .utcOffset(this.numericTimezoneOffset)
          .format('dddd, Do MMMM');
        break;
    }

    this.selectedDateIsToday = this.isToday();
    this.selectedDateIsLastDay = this.isLastDay();
  }

  previousDate(): void {
    const isToday: boolean = this.isToday();
    if (!isToday && !this.isLoading) {
      this._currentSelectedDate.subtract(1, 'day');
      this.setDisplayDate();
      this.getAvailabilityData();
    }
  }

  nextDate(): void {
    const isLastDay: boolean = this.isLastDay();
    if (!isLastDay && !this.isLoading) {
      this._currentSelectedDate.add(1, 'day');
      this.setDisplayDate();
      this.getAvailabilityData();
    }
  }

  isToday(): boolean {
    return (
      this._currentSelectedDate.clone().utcOffset(this.numericTimezoneOffset).format(Constants.YearFirstDate) ===
      this.todayString
    );
  }

  isLastDay(): boolean {
    return (
      this._currentSelectedDate.clone().utcOffset(this.numericTimezoneOffset).format(Constants.YearFirstDate) ===
      this.lastDayString
    );
  }

  getTimeZoneOffset(): string {
    return (
      this.oldAppointment?.patientTimezoneOffsetHours ||
      this.availability?.timezoneOffSetHours ||
      this.patientService.getPatientTimeZoneOffset(this.oldAppointment?.startTimeUTC || this.oldAppointment.start)
    );
  }

  getTimeZoneId(): string {
    return (
      this.oldAppointment?.patientTimezoneOffsetId ||
      this.availability?.timezoneId ||
      this.patientService.getPatientTimeZoneId()
    );
  }

  getTimeZoneOffsetLabel(): string {
    if (this.oldAppointment?.patientTimezoneLabel) {
      return this.oldAppointment.patientTimezoneLabel;
    } else if (this.oldAppointment?.patientTimezoneOffsetId) {
      return this.timezoneService.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
        this.oldAppointment.patientTimezoneOffsetId,
        this.getTimeZoneOffset()
      );
    }

    return this.patientService.getPatientTimeZoneLabel(this.oldAppointment?.startTimeUTC || this.oldAppointment.start);
  }

  async getAvailabilityData(): Promise<void> {
    this.rowCount = [];

    if (!this.availability) {
      this.isLoading = false;
      return;
    }

    // Do another check to see if we need to show the Clear Filters button
    this.checkIsFilteredData(this.availability);

    const stringDate: string = this._currentSelectedDate
      .clone()
      .utcOffset(this.numericTimezoneOffset)
      .format(Constants.UTC_Date_Format_Shortened);

    let dateParams: AvailabilityDateRange = this.functions.getAvailabilityDateParams(
      this.timeZoneOffset,
      stringDate,
      stringDate
    );

    ///////////
    // DOC-3053 - Check that the start/end dates are not in the past
    ///////////
    const now: moment.Moment = this.functions.getUTCMoment(this.timeZoneOffset);

    // is endDate in the past?
    if (moment.isMoment(dateParams?.endDatePatientLocal) && dateParams.endDatePatientLocal.diff(now, 'minutes') < 0) {
      this._availabilityGroupsArray = [];
      this.isLoading = false;
      return;

      // is startDate in the past?
    } else if (
      moment.isMoment(dateParams?.startDatePatientLocal) &&
      dateParams.startDatePatientLocal.diff(now, 'minutes') < 0
    ) {
      dateParams.startDatePatientLocal = now.clone();
    }
    ///////////

    this.isLoading = true;
    this.changeDetectorRef.detectChanges();

    // Make sure we provide the current benefit (policyId) in the appointment availability request
    let policyId: string = null;
    this.appliedBenefit = await this.getApplicableBenefit();

    if (this.appliedBenefit) {
      if (this.appliedBenefit.policyId) {
        policyId = this.appliedBenefit.policyId;
      } else if (this.appliedBenefit.name) {
        this.appliedBenefit = (
          await this.patientService.getBenefit(this.appliedBenefit.name, this.getServiceType())
        )?.benefit;
        policyId = this.appliedBenefit?.policyId;
      }
    } else if (this.availability?.policyId && !this.dialogData?.isReschedule) {
      policyId = this.availability.policyId;
    }

    let practitionerIdsToLimitBy: string = this.availability?.practitionerIdsToLimitBy;

    // If patient clears filters, they may have selected a session with a different doctor, do not limit
    // practitionerIds to the old appointment's practitioner
    // if (!practitionerIdsToLimitBy && this.dialogData?.isReschedule && this.oldAppointment?.practitionerId) {
    //   practitionerIdsToLimitBy = JSON.stringify([this.oldAppointment.practitionerId]);
    // }

    this.availabilitySearch = {
      startDatePatientLocal: this.functions.getUTCMomentString(
        this.timeZoneOffset,
        false,
        false,
        dateParams.startDatePatientLocal
      ),
      endDatePatientLocal: this.functions.getUTCMomentString(
        this.timeZoneOffset,
        false,
        false,
        dateParams.endDatePatientLocal
      ),
      timezoneId: this.getTimeZoneId(),
      timezoneOffSetHours: this.getTimeZoneOffset(),
      serviceTypes: this.availability?.serviceTypes || Constants.DEFAULT_SERVICE_TYPES,
      sex: this.availability?.sex || PractitionerGender.All,
      billingType: this.availability?.billingType || Constants.APPOINTMENT_BILLING_TYPE.ALL,
      appointmentBookingType: Constants.APPOINTMENT_BOOKING_TYPE.ALL,
      policyId,
      doesPatientHaveMedicare: this.availability?.doesPatientHaveMedicare,
      hours: Boolean(this.oldAppointmentIsBusinessHours && this.dialogData?.isReschedule)
        ? AppointmentHours.BusinessHours
        : this.availability?.hours ?? AppointmentHours.All,
      languageId: this.availability.languageId || null,
      shouldLimitByAgencyCode: this.availability?.shouldLimitByAgencyCode || null,
      shouldGetOnDemandQueueSizes: false, //Boolean(this.oldAppointment?.isOnDemand),
      practitionerIdsToLimitBy
    };

    return this.appointmentService
      .getAvailability(this.functions.removeEmpty(this.availabilitySearch))
      .then((response: AvailabilityGroups[]) => {
        this._availabilityGroupsArray = response ?? null;
        this.createSessionMatrix(this._availabilityGroupsArray, this.timeZoneOffset);
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  resetFilters($event: any): void {
    if ($event) {
      $event.stopImmediatePropagation();
    }

    this.appointmentService.setAvailabilityParameter({
      ...this.appointmentService.availabilityParameter,
      sex: PractitionerGender.All,
      hours: AppointmentHours.All,
      practitionerIdsToLimitBy: null,
      shouldLimitByAgencyCode: false,
      languageId: null,
      appointmentBookingType: Constants.APPOINTMENT_BOOKING_TYPE.ALL,
      billingType: Constants.APPOINTMENT_BILLING_TYPE.ALL
    });
  }

  createSessionMatrix(availabilityGroupsArray: AvailabilityGroups[], patientTimeZoneOffset: string): void {
    let tempSession: Session[] = [];

    if (Array.isArray(availabilityGroupsArray) && availabilityGroupsArray.length) {
      this.rowCount = [];

      const businessHoursOnly = Boolean(this.oldAppointmentIsBusinessHours && this.dialogData?.isReschedule);

      availabilityGroupsArray.forEach((groups: AvailabilityGroups) => {
        const rebates: AvailabilityGroupsForDay[] = groups.availabilityGroupsForDay.filter(
          (groups: AvailabilityGroupsForDay) => groups.billingType === 'Rebate'
        );
        const privates: AvailabilityGroupsForDay[] = groups.availabilityGroupsForDay.filter(
          (groups: AvailabilityGroupsForDay) => groups.billingType === 'Private'
        );

        rebates.forEach((group: AvailabilityGroupsForDay) => {
          (group?.sessions ?? []).forEach((session: Session) => {
            if (!(businessHoursOnly && !(session.businessHours || group.isBusinessHours))) {
              const sessionData: Session = this.generateSessionObject(group, session);
              tempSession.push(sessionData);
            }
          });
          // (group?.sessions ?? []).forEach((sessionInfo: ISessionInfo) => {
          //   if (
          //     !(
          //       businessHoursOnly &&
          //       !(
          //         sessionInfo.availabilityConstraintsPriceType.priceType == AppointmentHours.BusinessHours ||
          //         group.isBusinessHours
          //       )
          //     )
          //   ) {
          //     const sessionData: Session = this.generateSessionObject(group, sessionInfo, patientTimeZoneOffset);
          //     tempSession.push(sessionData);
          //   }
          // });
        });

        privates.forEach((group: AvailabilityGroupsForDay) => {
          (group?.sessions ?? []).forEach((session: Session) => {
            if (!(businessHoursOnly && !(session.businessHours || group.isBusinessHours))) {
              const sessionData: Session = this.generateSessionObject(group, session);
              if (
                !tempSession.find(
                  (x: Session) => x.displayedTime.toUpperCase() === sessionData.displayedTime.toUpperCase()
                )
              ) {
                tempSession.push(sessionData);
              }
            }
            // (group?.sessions ?? []).forEach((sessionInfo: ISessionInfo) => {
            //   if (
            //     !(
            //       businessHoursOnly &&
            //       !(
            //         sessionInfo.availabilityConstraintsPriceType.priceType == AppointmentHours.BusinessHours ||
            //         group.isBusinessHours
            //       )
            //     )
            //   ) {
            //     const sessionData: Session = this.generateSessionObject(group, sessionInfo, patientTimeZoneOffset);
            //     if (!tempSession.find((x: Session) => x.start === sessionData.start)) {
            //       tempSession.push(sessionData);
            //     }
            //   }
          });
        });
      });
    }

    tempSession.sort((a: Session, b: Session) => new Date(a.start).getTime() - new Date(b.start).getTime());

    // TODO: calculate for Tablet size, or abandon concept of dividing sessions into rows and use flex-wrap

    // If we can't calculate available width, default to 3 items per row (narrow mobile)
    const divider: number =
      this.schedule && this.schedule.nativeElement && !this.isNarrowMobile
        ? Math.floor(this.schedule.nativeElement.offsetWidth / 114.2)
        : 3;

    if (divider <= 0) {
      this.rowCount = [];
      this.columnCount = [];
      return;
    }

    for (let i = 0; i < Math.ceil(tempSession.length / divider); i++) {
      this.columnCount = [];
      for (let j = 0; j < divider; j++) {
        if (i * divider + j < tempSession.length) {
          this.columnCount.push(tempSession[i * divider + j]);
        }
      }

      this.rowCount.push(this.columnCount);
    }
  }

  /**
   * @function generateSessionObject
   * @description Create a 'Session' object from the returned availability groups for day and individual session data
   *
   * @param {AvailabilityGroupsForDay} group
   * @param {Session} session
   *
   * @returns {Session}
   */
  generateSessionObject(group: AvailabilityGroupsForDay, session: Session): Session {
    return {
      billingType: group.billingType,
      businessHours: group.isBusinessHours ?? session.businessHours,
      bulkbillAfterHours: session.bulkbillAfterHours,
      start: session.start,
      end: session.end,
      isPractitionerMBSCapable: session.isPractitionerMBSCapable,
      practitionerId: session.practitionerId,
      serviceType: session.serviceType,
      startLocal: session.startLocal, //appointmentStart,
      duration:
        session.targetDuration ||
        group.appointmentLength ||
        Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration,
      price: group.price || 0,
      originalPrice: group.originalPrice ?? (group.price || 0),
      bookingFee: group.bookingFee ?? (session.bookingFee || 0),
      displayedTime: this.timezoneService.getFormattedSessionDisplayTimeLocal(session.startLocal)
    } as Session;

    // generateSessionObject(
    //   group: AvailabilityGroupsForDay,
    //   sessionInfo: ISessionInfo,
    //   patientTimeZoneOffset: string
    // ): Session {
    // const appointmentStart: string = this.functions.getUTCMomentString(patientTimeZoneOffset, false, false, sessionInfo.availability.start.dateValueUTC);

    // return {
    //   billingType: group.billingType,
    //   businessHours:
    //     group.isBusinessHours ??
    //     sessionInfo.availabilityConstraintsPriceType.price.priceType == AppointmentHours.BusinessHours,
    //   bulkbillAfterHours: false,
    //   start: sessionInfo.availability.start.dateValueUTC,
    //   end: 'TEST', //sessionInfo.priceType.end.,
    //   isPractitionerMBSCapable: sessionInfo.practitioner.isMBSCapable,
    //   practitionerId: sessionInfo.practitioner.practitionerId,
    //   serviceType: sessionInfo.availabilityPricingItem.serviceType,
    //   startLocal: sessionInfo.availability.start.dateValueLocal,
    //   duration:
    //     sessionInfo.availabilityConstraintsPriceType.appointmentDuration ||
    //     group.appointmentLength ||
    //     Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration,
    //   price: group.price || 0,
    //   originalPrice: group.originalPrice ?? (group.price || 0),
    //   bookingFee:
    //     group.bookingFee ??
    //     ((sessionInfo.availabilityConstraintsPriceType.price.hasBookingFee
    //       ? sessionInfo.availabilityConstraintsPriceType.price.components.find(
    //           (comp: AvailabilityPricingItem) => comp.productType == ProductServiceType.appointmentBookingFee
    //         )?.price
    //       : 0) ||
    //       0),
    //   displayedTime: this.timezoneService.getFormattedSessionDisplayTimeLocal(
    //     sessionInfo.availability.start.dateValueLocal
    //   )
    // } as Session;
  }

  /**
   * @async
   * @function chooseTime
   * @description Patient has chosen a new time slot. Calculate display time and price for the selected session.
   *
   * @param {Session} session
   */
  async chooseTime(session: Session): Promise<void> {
    this.newAppointment = session;

    this.newDisplayDate = this.functions.getUTCMoment(this.timeZoneOffset, false, false, this.newAppointment.start);

    this.appointmentDayDate = this.newDisplayDate.clone().utcOffset(this.numericTimezoneOffset).format('DD');
    this.appointmentDayMonth = this.newDisplayDate
      .clone()
      .utcOffset(this.numericTimezoneOffset)
      .format('MMM')
      .toUpperCase();
    this.appointmentDayWeekday = this.newDisplayDate.clone().utcOffset(this.numericTimezoneOffset).format('dddd');
    this.appointmentTime = this.timezoneService.getFormattedSessionDisplayTime(
      this.newAppointment.start,
      this.timeZoneOffset
    );

    this.newAppointmentPrice = await this.getDiscountedAppointmentPrice(session);
    console.log('[RESCHEDULE] New appointment price:', this.newAppointmentPrice);
  }

  /**
   * @async
   * @function getDiscountedAppointmentPrice
   * @description Get the discounted total appointment price by applying the current benefit to the
   * service fee and booking fee of the specified Session or Appointment.
   *
   * @param {Session|Appointment} session can use a Session or Appointment object to calculate total price
   *
   * @returns {Promise<number>}
   */
  async getDiscountedAppointmentPrice(session: any): Promise<number> {
    const benefit: Benefit = await this.getApplicableBenefit();
    const appointmentDTO: AppointmentDTO = {
      policyId: benefit?.policyId || null,
      serviceType: session.serviceType,
      isAfterHours: !session.businessHours
    };
    const servicePrice: number = session.servicePrice ?? session.originalPrice ?? session.price;
    const bookingFee: number = session.bookingFeePrice ?? session.originalBookingFee ?? session.bookingFee;

    const discountedTotalPrice: number = this.pricingService.getAppointmentDiscountedTotalPrice(
      appointmentDTO,
      benefit,
      servicePrice,
      bookingFee
    );

    return discountedTotalPrice;
  }

  /**
   * @async
   * @function getApplicableBenefit
   * @description Set Benefit applicable to this appoitment and return it. We do not apply the benefit currently in
   * memory to an already booked appointment - we must use the old appointment's benefit, if any.
   *
   * @returns {Promise<Benefit|null>} the applied benefit
   */
  async getApplicableBenefit(): Promise<Benefit | null> {
    const isBookedAppointment: boolean = this.dialogData?.isReschedule;

    // If the original appointment's policy does not match the policy stored in this browser session, retrieve
    // the policy details based on the code from the original appointment
    if (
      this.oldAppointment?.policyNumber &&
      this.oldAppointment?.policyId &&
      this.oldAppointment.policyId !== this.appliedBenefit?.policyId
    ) {
      this.appliedBenefit = (
        await this.patientService.getBenefit(this.oldAppointment.policyNumber, this.getServiceType(), false)
      )?.benefit;

      // Otherwise retrieve benefit based on the stored policy code. Update current benefit in memory and storage
      // only if the appointment has not yet been created
    } else if (!isBookedAppointment) {
      this.appliedBenefit = await this.patientService.retrieveBenefit(true);
    }

    return Promise.resolve(this.appliedBenefit);
  }

  /**
   * @function confirmTime
   * @description
   */
  confirmTime(): void {
    const practitionerId: string = this.oldAppointment?.appointmentId
      ? this.oldAppointment.practitionerId
      : this.newAppointment?.practitionerId;

    console.log(
      '[RESCHEDULE] Setting practitionerId from',
      this.oldAppointment?.appointmentId ? 'old appointment' : 'new appointment',
      'to:',
      practitionerId
    );
    console.log('[RESCHEDULE] confirmTime() Setting new time to:', this.newAppointment.startLocal);

    // Note: newAppointment contains the practitionerId of the selected session, not necessarily the
    // practitionerId of the oldAppointment!
    if (practitionerId) {
      this.practitionerService.getPractitionerById(practitionerId).then((practitioner: Practitioner) => {
        // Seny - here we assume that only one practitioner may be chosen using the doctor filter,
        // otherwise practitionerIdsToLimitBy will be set to null.
        // If this is an existing appointment, keep the practitioner from the old appointment
        if (practitioner && (this.availability?.practitionerIdsToLimitBy || this.oldAppointment.appointmentId)) {
          this.newPractitioner = practitioner;
          console.log(
            '[RESCHEDULE] confirmTime() Setting practitioner to:',
            practitionerId,
            '(' + practitioner.practitionerName + ')'
          );
        } else {
          this.newPractitioner = null; // Display 'First available'
        }

        this.isConfirmation = true;
        this.changeDetectorRef.detectChanges();
      });
    } else {
      this.newPractitioner = null;
      this.isConfirmation = true;
    }
  }

  async confirmReschedule(): Promise<any> {
    // RESCHEDULE EXISTING APPOINTMENT
    if (this.oldAppointment?.appointmentId) {
      this.newAppointment.appointmentId = this.oldAppointment.appointmentId;
      const patientHasRecentlyBookedAppointment: boolean = await this.checkRecentlyBookedAppointments(
        this.newAppointment.start
      );

      // Check if there is an appointment already booked that's
      if (!patientHasRecentlyBookedAppointment) {
        this.isLoading = true;
        this.appointmentService
          .updateAppointment(this.oldAppointment.appointmentId, this.newAppointment.start)
          .then((response: IResponseAPI) => {
            if (response?.success) {
              // Update existing booked appointment in local storage
              let appointment: Appointment = { ...this.oldAppointment };

              if (appointment) {
                // TODO - Refactor - too many repeating appointment dates
                appointment.start = this.newAppointment.start; // string date ending with Z
                appointment.startTimeUTC = appointment.start; // string date ending with Z
                appointment.startTime = moment(appointment.start).toDate(); // Date version of the above
                appointment.startLocal = this.newAppointment.startLocal; // local computer time (no timezone?)
                appointment.practitionerId = this.newPractitioner.practitionerId || null;
                appointment.practitionerName = this.newPractitioner.practitionerName || null;
                appointment.practitionerPic = this.newPractitioner.profileImageUrl || null;

                // this.appointmentService.addAppointment(appointment);
                // this.appointmentService.setRecentlyBookedAppointment(appointment);
              }
            }
          })
          .catch((err: any) => {
            let errorMessage: string = this.functions.getErrorMessage(err);
            if (errorMessage === Labels.serverError) {
              errorMessage =
                'Unable to reschedule appointment. This appointment may have been booked under a ' +
                'different account or the service is unavailable.';
            }
            this.showErrorMessage('Reschedule Appointment Problem', errorMessage);
          })
          .finally(() => {
            this.isLoading = false;
            this.onClose();
          });
      }

      // CHANGE APPOINTMENT TIME FOR UNBOOKED APPOINTMENT
    } else if (this.newAppointment) {
      const onlyOnePractitionerSelected: boolean = Boolean(this.availability?.practitionerIdsToLimitBy);
      const timezoneOffsetId: string =
        this.oldAppointment?.patientTimezoneOffsetId || this.patientService.getPatientTimeZoneId();
      const timezoneOffsetHours: string =
        this.oldAppointment?.patientTimezoneOffsetHours ||
        this.patientService.getPatientTimeZoneOffset(this.newAppointment.start);
      const timeZoneLabel: string =
        this.oldAppointment?.patientTimezoneLabel ||
        this.patientService.getPatientTimeZoneLabel(this.newAppointment.start);

      // const appointmentStart: string = this.functions.getUTCMomentString(
      //   timezoneOffsetHours,
      //   false,
      //   false,
      //   this.newAppointment.start
      // );

      const servicePrice: number =
        this.newAppointment.servicePrice ?? this.newAppointment.originalPrice ?? (this.newAppointment.price || 0);
      const bookingFeePrice: number =
        this.newAppointment.bookingFeePrice ??
        this.newAppointment.originalBookingFee ??
        (this.newAppointment.bookingFee || 0);

      let newAppointment: Appointment = {
        // agency
        agencyCode: this.oldAppointment.agencyCode,

        // patient
        patientId: this.oldAppointment.patientId,
        patientTimezoneOffsetId: timezoneOffsetId,
        patientTimezoneOffsetHours: timezoneOffsetHours,
        patientTimezoneLabel: timeZoneLabel,

        // set ids if only one practitioner is selected, otherwise set to any practitioner
        practitionerId: onlyOnePractitionerSelected ? this.newPractitioner.practitionerId : null,
        practitionerName: onlyOnePractitionerSelected ? this.newPractitioner.practitionerName : null,
        practitionerPic: onlyOnePractitionerSelected ? this.newPractitioner.profileImageUrl : null,
        isPractitionerMBSCapable: onlyOnePractitionerSelected ? Boolean(this.newPractitioner.isMBSCapable) : null,

        // appointment details
        appointmentReason: this.oldAppointment.appointmentReason,
        followupId: this.oldAppointment.followupId,
        billingType: this.newAppointment.billingType,
        serviceType:
          this.newAppointment.serviceType ?? this.oldAppointment?.serviceType ?? Constants.SERVICE_TYPE.DOCTOR,
        businessHours: this.newAppointment.businessHours,
        isAfterHours: !this.newAppointment.businessHours,
        displayedTime: this.newAppointment.displayedTime,
        duration:
          this.newAppointment.duration ??
          this.oldAppointment.duration ??
          Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration,
        end: this.newAppointment.end,
        isOnDemand: false,
        // This parameter is not currently being utilised by the API
        bulkbillAfterHours: this.newAppointment.bulkbillAfterHours,

        // policy
        policyId: this.appliedBenefit?.policyId || null,
        policyNumber: this.appliedBenefit?.name || null,
        b2BCustomerName: this.appliedBenefit?.b2BCustomerName || null,

        // price
        price: this.newAppointment.price || 0,
        originalPrice: servicePrice,
        bookingFeePrice,
        originalBookingFee: bookingFeePrice,

        // session time
        start: this.newAppointment.start,
        startLocal: this.newAppointment.startLocal, //appointmentStart,
        startTimeUTC: this.newAppointment.start,
        startTime: new Date(this.newAppointment.startLocal)
      };

      const appointmentPricingData: AppointmentPricingData =
        await this.appointmentService.getAppointmentPricingFromAppointmentAndFilterParams(
          newAppointment,
          this.availability
        );

      if (appointmentPricingData?.pricing) {
        newAppointment = this.appointmentService.updateAppointmentDetailPricing(
          newAppointment,
          appointmentPricingData.pricing,
          false
        );
      }

      // Set new appointment
      this.newAppointment = this.appointmentService.setAppointment(newAppointment);

      // Copy over relevant availability parameters
      // this.availability.timezoneId = timezoneOffsetId;
      // this.availability.timezoneOffSetHours = timezoneOffsetHours;
      // this.availability.startDatePatientLocal = this.availabilitySearch.startDatePatientLocal;
      // this.availability.endDatePatientLocal = this.availabilitySearch.endDatePatientLocal;
      // this.availability.hours = this.availabilitySearch.hours;

      // Set availability params
      this.appointmentService.setAvailabilityParameter({
        ...this.availability,
        timezoneId: timezoneOffsetId,
        timezoneOffSetHours: timezoneOffsetHours,
        startDatePatientLocal: this.availabilitySearch.startDatePatientLocal,
        endDatePatientLocal: this.availabilitySearch.endDatePatientLocal,
        hours: this.availabilitySearch.hours
      });

      // Seny - if we have selected only one practitioner, set them as the practitioner for updated appointment
      if (onlyOnePractitionerSelected) {
        this.practitionerService.setPractitioner(this.newPractitioner);
      } else {
        this.practitionerService.setPractitioner(null);
        // this.getPractitionerOptions();
      }

      this.analytics.changeAppointmentTime();

      // Make recently booked appointment check for new appointment time
      this.checkRecentlyBookedAppointments(this.newAppointment.start);

      this.onClose();
    }
  }

  onClose(value?: boolean): void {
    this.dialogRef.close(value);
  }

  /**
   * @function checkRecentlyBookedAppointments
   * @description Check if there are any recently booked appointments, and if there are,
   * display an error modal with options:reschedule, cancel, join
   *
   * @param {string} startTimeUTC
   *
   * @returns {boolean}
   */
  async checkRecentlyBookedAppointments(startTimeUTC: string): Promise<boolean> {
    const recentlyBookedAppointment: RecentlyBookedAppointment =
      await this.appointmentService.checkRecentlyBookedAppointments(
        this.patientService.patient?.patientId,
        startTimeUTC,
        this.newAppointment
      );

    if (recentlyBookedAppointment) {
      const appointment: Appointment = recentlyBookedAppointment.bookedAppointment;
      const recentlyBookedErrorMessage: string = recentlyBookedAppointment.recentlyBookedErrorMessage;
      const withinAppointmentJoinThreshold: boolean = recentlyBookedAppointment.withinAppointmentJoinThreshold;
      const withinCancellationThreshold: boolean = recentlyBookedAppointment.withinCancellationThreshold;
      const isRecentlyFinishedAppointment: boolean = recentlyBookedAppointment.isRecentlyFinishedAppointment;

      if (isRecentlyFinishedAppointment || !recentlyBookedErrorMessage) {
        return;
      }

      this.dialog.closeAll();

      this.showErrorMessage(
        'Upcoming Appointment',

        // Display previous appointment warning message
        recentlyBookedErrorMessage,

        // Button options for error modal
        {
          isCancelAppointment: withinCancellationThreshold,
          //isRescheduleAppointment: !withinAppointmentJoinThreshold && (appointment.canBeRescheduled ?? true),
          isGoToWaitingRoom: withinAppointmentJoinThreshold,
          showDashboardButton: true,
          isClose: false
        },

        // Callback function for when user closes the error modal
        (result: string) => {
          switch (result) {
            case 'reschedule':
              this.onClose(false);
              this.modalService.rescheduleAppointment(appointment);
              break;
            case 'selectPractitioner':
              this.onClose(false);
              this.modalService.changePractitioner(appointment);
              break;
            case 'cancelAppointment':
              this.onClose(false);
              this.modalService.cancelAppointment(appointment.appointmentId);
              break;
            case 'waitingRoom':
              this.onClose(false);
              this.stepService.resetService();
              this.stepService.removeAppointmentFromStorage();
              this.stepService.removeWaitingRoomFromStorage();
              this.appointmentService.resetService();

              this.router.navigate([
                STEP_PATH.WAITING_ROOM,
                appointment.appointmentId,
                STEP_CONFIGURATION.WAITING_ROOM.WAITING_ROOM_BEFORE.virtualPath
              ]);
              break;
          }
        }
      );
    }

    return false;
  }

  getPractitionerType(): string {
    return this.oldAppointment?.serviceType
      ? this.functions.getAppointmentType(this.oldAppointment.serviceType)
      : Constants.SERVICE_TYPE.DOCTOR;
  }

  getServiceType(): string {
    if (this.availabilitySearch?.serviceTypes) return JSON.parse(this.availabilitySearch?.serviceTypes)[0];
    if (this.oldAppointment?.serviceType) return this.oldAppointment.serviceType;
    return Constants.SERVICE_TYPE.DOCTOR;
  }

  showErrorMessage(title: string, message: string, dialogOptions?: any, callbackFn?: Function): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title,
      message,
      isSpeakToDoctor: false,
      isClose: true,
      ...(dialogOptions || {})
    };
    dialogConfig.maxWidth = 573;

    this.dialog
      .open(ErrorMessageModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: string) => {
        if (result) {
          callbackFn(result);
        }
      });
  }
}
