import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import externalUrls from '@app/shared/config/external-urls.json';

@Component({
  selector: 'ihi-number-info',
  templateUrl: './ihi-number.component.html',
  styleUrls: ['./ihi-number.component.scss']
})
export class IhiNumberComponent implements OnInit {
  myGovUrl: string = externalUrls.myGov;
  createMyGovAccountUrl: string = externalUrls.createMyGovAccount;
  isNative: boolean = Boolean(Capacitor.isNativePlatform());

  constructor(
    public dialogRef: MatDialogRef<IhiNumberComponent>,
    private analytics: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close();
  }

  openExternalURL($event: any): void {
    const url: string = $event.currentTarget.attributes.href.nodeValue;

    this.analytics.externalLinkClick(url);

    if (this.isNative) {
      try {
        $event.preventDefault();
        Browser.open({ url });
      } catch (err: any) {
        console.warn('Failed to open external URL in native browser!');
      }
    }
  }
}
