import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { B2BCustomerBookingStatus, B2BPortalClaimViewModel } from '@src/app/shared/models/b2b-customer-portal/models';
import { B2BBookingPatientInputType, B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { PatientService } from '@src/app/shared/services/patient.service';
import { first } from 'rxjs';
import { B2B_STEP_PATH } from '../../../business-portal-routing.module';

@Component({
  selector: 'business-portal-patient-booking-policy-number',
  templateUrl: './business-portal-patient-booking-policy-number.component.html',
  styleUrls: ['./business-portal-patient-booking-policy-number.component.scss']
})
export class BusinessPortalPatientBookingPolicyNumberComponent implements OnInit {
  formGroup: FormGroup;

  isSubmitting: boolean;

  invalidPolicy: boolean;

  constructor(
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PolicyNumber);

    this.b2bCustomerService.setPatient(null);
    this.createForm();

    if (this.b2bCustomerService.getVerifiedPolicyClaim?.number) {
      this.formGroup.get('policyNumber').setValue(this.b2bCustomerService.getVerifiedPolicyClaim?.number);
    }

    this.b2bCustomerService.setVerifiedPolicy(null);
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      policyNumber: [this.b2bCustomerService.getVerifiedPolicyClaim?.number, []]
    });
  }

  get policyNumber() {
    return this.formGroup.get('policyNumber');
  }

  patientDoesntKnowPolicy() {
    this.b2bCustomerService.setVerifiedPolicy(null);
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientEnterEmail);
    this.router.navigate(['../patient-email'], { relativeTo: this.activatedRoute });
  }

  completePolicyNumberInput() {
    if (!this.formGroup.valid) {
      return;
    } else {
      this.formGroup.setErrors(null);
      this.formGroup.markAsPristine();
    }

    const policyNumber: string = this.policyNumber.value;

    this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first()).subscribe(async (info) => {
      if (info) {
        this.isSubmitting = true;
        const b2bPortalClaim: B2BPortalClaimViewModel = await this.b2bCustomerService.verifyPolicy(policyNumber);
        this.invalidPolicy = !b2bPortalClaim;
        this.isSubmitting = false;

        if (b2bPortalClaim) {
          this.b2bCustomerService.setVerifiedPolicy(b2bPortalClaim);
          this.b2bCustomerService.setPatientManuallyTypedEmail(b2bPortalClaim.user.email);
          this.b2bCustomerService.setPatientSource(B2BBookingPatientInputType.policy);
          this.patientService.getBenefit(policyNumber, null, true);
          this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientAdd);
          this.router.navigate(['../', B2B_STEP_PATH.PATIENT_SELECT], { relativeTo: this.activatedRoute });
        }
      } else {
        console.error('ERROR in completePolicyNumberInput');
      }
    });
  }
}
