import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  // Default spinner diameter is 30px
  @Input() size: number = 30;
  @Input() loadingText: string = 'Loading ...';

  constructor() {}
}
