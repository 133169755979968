type BootstrapLoggerLevel = 'debug' | 'info' | 'warn' | 'error' | 'critical';
export type BootstrapLoggerMessage = {
    name: string,
    index: number,
    properties: Record<string, any>
}

export class BootstrapLogger {
    private static KEY = '_BootstrapLogger';
    private static isLocked: boolean = false;
    private static index: number = 0;

    static log(level: BootstrapLoggerLevel, context: string, name: string, properties: Record<string, any> = {}) {
        if (this.isLocked) {
            console.error('[BootstrapLogger]', 'Failed to log message - logger is locked', { level, name, properties });
            return;
        }

        if (!window[this.KEY]) {
            window[this.KEY] = {};
        }

        if (!window[this.KEY][context]) {
            window[this.KEY][context] = [];
        }

        properties['_level'] = level;

        window[this.KEY][context].push({
            name,
            index: this.index++,
            properties
        });
    }

    static retrieve(): Record<string, BootstrapLoggerMessage[]> {
        this.isLocked = true;

        return window[this.KEY] ?? {};
    }

    static clear(): void {
        window[this.KEY] = null;
    }
}