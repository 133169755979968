import { AbstractControl, ValidationErrors } from '@angular/forms';

export function medicareValidator(control: AbstractControl): ValidationErrors | null {
  const input: string = control.value ?? '';

  // Full card number length is 12, but validators get triggered before the formatting functions, so we have 1 less char
  if (!input || (input && input.length < 11)) {
    return null;
  }

  const medicareNumber: string = input.replace(/-/g, '');
  const pattern: RegExp = /^(\d{8})(\d)/;
  const length: number = 10;

  let matches: RegExpExecArray;
  let base: any;
  let checkDigit: string | number;
  let total: number;
  let multipliers: Array<number>;
  let isValid: boolean;

  if (medicareNumber.length === length) {
    matches = pattern.exec(medicareNumber);
    if (matches) {
      base = matches[1];
      checkDigit = matches[2];
      total = 0;
      multipliers = [1, 3, 7, 9, 1, 3, 7, 9];

      for (let i = 0; i < multipliers.length; i++) {
        total += base[i] * multipliers[i];
      }

      isValid = total % 10 === Number(checkDigit);
    } else {
      isValid = false;
    }
  } else {
    isValid = false;
  }

  if (!isValid) {
    return { invalid: true };
  } else {
    return null;
  }
}
