<div class="container-fluid">
  <ng-container *ngIf="b2bCustomerService.currentPatientBookingStatus === B2BCustomerBookingStatus.PatientSendCode">
    <div class="message-container mt-4">
      <div class="gray-box">
        <p class="mb-0">
          We'll send an email or SMS to the patient, please ask the patient to read the code back to you to proceed.
        </p>
      </div>
    </div>
    <mat-card appearance="outlined" class="card-wrapper mt-4">
      <mat-card-content class="content">
        <div class="row">
          <div class="col-12 col-sm-3 col-md-2 mb-2 button-wrapper">
            <button
              (click)="sendCode(MFAEnrolmentMedium.sms)"
              [disabled]="isSubmitting"
              class="save-btn"
              mat-flat-button
              color="accent"
            >
              <span>Send SMS</span>
            </button>
          </div>
          <div class="col-12 col-sm-3 col-md-2 mb-2 button-wrapper">
            <button
              (click)="sendCode(MFAEnrolmentMedium.email)"
              [disabled]="isSubmitting"
              class="save-btn"
              mat-flat-button
              color="accent"
            >
              <span *ngIf="!isSubmitting">Send Email</span>
              <span *ngIf="isSubmitting">Sending...</span>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="b2bCustomerService.currentPatientBookingStatus === B2BCustomerBookingStatus.PatientVerifyCode">
    <div class="message-container mt-4">
      <div class="gray-box">
        <p>
          We sent a 6-digit verification code to the patient. Please ask the patient to read the code to you, then enter
          the code below to continue.
        </p>
        <p class="mb-0"><span class="link-appearance"></span></p>
        <button (click)="resend()" class="second-button" mat-flat-button>Patient didn't get the code?</button>
      </div>
    </div>

    <form [formGroup]="formGroupVerify">
      <div class="row content-section mt-4">
        <div class="col-12">
          <mat-card appearance="outlined" class="card-wrapper">
            <mat-card-content class="content">
              <div class="form-content">
                <div class="row">
                  <div class="col-md-6 content-box">
                    <mat-form-field appearance="outline" class="default-input w-100">
                      <mat-label>Authentication Code</mat-label>
                      <input
                        formControlName="mfacode"
                        autocomplete="mfacode"
                        matInput
                        maxlength="255"
                        placeholder="Authentication Code"
                        required
                      />
                      <mat-error *ngIf="mfacode.invalid">
                        <span *ngIf="mfacode.hasError('required')">Authentication Code is required.&nbsp;</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <p class="error-message mb-3" *ngIf="invalidCode">
                    The verification code is incorrect. Please try the booking process again or contact an
                    administrator.
                  </p>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-3 col-md-2 button-wrapper">
                    <button
                      (click)="verifyPatientCode()"
                      [disabled]="isSubmitting"
                      class="save-btn"
                      mat-flat-button
                      color="accent"
                    >
                      <span *ngIf="!isSubmitting">Verify</span>
                      <span *ngIf="isSubmitting">Verifying...</span>
                    </button>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
  </ng-container>
</div>
