import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { Constants } from '@app/shared/constants';
import { IResponseAPI } from '@app/shared/models/api-response';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  isConfirm: boolean = false;
  forgotPasswordForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern(Constants.emailRegexString)
      ]],
    });

    if (this.data && this.data.email) {
      this.forgotPasswordForm.patchValue({
        email: String(this.data.email)
      });
    }
  }

  onSubmit() {
    const email: string = this.email.value;

    if (email) {
      this.authService.initiatePasswordReset(email).then((response: IResponseAPI) => {
        if (response && response.success) {
          this.isConfirm = true;
        } else {
          console.warn('Error. Could not reset users password. API did not respond with success.');
          this.isConfirm = false;
        }
      });
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }
}
