<div class="modal-wrapper">
  <form [formGroup]="forgotPasswordForm" (submit)="onSubmit()">
    <div class="modal-header container-fluid align-items-center">
      <div class="col-10 px-0">
        <h4 class="text-primary title mb-0">Forgot Password</h4>
      </div>
      <div class="col-2 px-0">
        <button type="button" class="close" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div *ngIf="isConfirm" class="modal-body container-fluid text-primary">
      <p class="col-12 px-0 m-0">
        We've sent you an email with instructions on resetting your password.
        <!-- A new password has been generated and emailed to you.
        You should receive an email shortly. -->
      </p>
    </div>
    <div *ngIf="!isConfirm" class="modal-body container-fluid text-primary">
      <p class="col-12 px-0">
        If this email address is registered with Doctors On Demand, instructions on resetting your password
        will be emailed to you shortly.
      </p>
      <mat-card appearance="outlined">
        <mat-card-subtitle class="text-secondary">Please enter your email address:</mat-card-subtitle>
        <mat-card-content>
          <div class="form-container">
            <div class="form-group m-0">
              <mat-form-field appearance="outline" class="custom-field w-100">
                <input
                  formControlName="email"
                  autocomplete="email"
                  maxlength="255"
                  matInput
                  id="email"
                  type="text" />
                <mat-error *ngIf="email.invalid && email.errors && (email.touched || email.dirty)">
                  <span *ngIf="email.hasError('required')">
                    Email address is required
                  </span>
                  <span  *ngIf="email.hasError('pattern')">
                    Email format is not valid
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="modal-footer container-fluid bg-muted flex-nowrap">
      <button
        *ngIf="!isConfirm"
        type="submit"
        mat-flat-button
        color="accent"
        class="btn-block"
        [disabled]="!forgotPasswordForm.valid">
        Reset Password
      </button>
      <button
        *ngIf="isConfirm"
        mat-flat-button
        type="button"
        color="accent"
        class="btn-block"
        (click)="onClose()">
        Close
      </button>
    </div>
  </form>
</div>
