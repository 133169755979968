<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title my-auto">{{ titles.modalCart }}</h4>
    </div>
    <div *ngIf="!data?.hideHeaderCloseButton" class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="cart-modal-wrapper">
    <div class="row">
      <div class="col-12">
        <cart
          [isCartModal]="true"
          (onClose)="onClose($event)"
          (redirectUrl)="redirectTo($event)">
        </cart>
      </div>
    </div>
  </div>
</div>
