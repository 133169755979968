import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from '@app/shared/functions';
import { CredentialsService } from '@src/app/core/services/credentials.service';
import { Credentials } from '@src/app/shared/models/credentials';
import { Patient } from '@src/app/shared/models/patient';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { PatientService } from '@src/app/shared/services/patient.service';
import { first } from 'rxjs';
import { B2B_STEP_PATH } from '../../../business-portal-routing.module';
import { STEP_CONFIGURATION, STEP_PATH } from '@src/app/shared/step-configuration';
import { WhitelabelService } from '@src/app/shared/services/whitelabel.service';
import {
  B2BCustomerBookingStatus,
  JwtAccessToken,
  MFAEnrolmentMedium
} from '@src/app/shared/models/b2b-customer-portal/models';

@Component({
  selector: 'business-portal-patient-booking-patient-code',
  templateUrl: './business-portal-patient-booking-patient-code.component.html',
  styleUrls: ['./business-portal-patient-booking-patient-code.component.scss']
})
export class BusinessPortalPatientBookingPatientCodeComponent implements OnInit {
  formGroupVerify: FormGroup;
  isSubmitting: boolean;
  invalidCode: boolean;
  enrolmentId: string;

  B2BCustomerBookingStatus = B2BCustomerBookingStatus;
  MFAEnrolmentMedium = MFAEnrolmentMedium;

  constructor(
    private activatedRoute: ActivatedRoute,
    private functions: Functions,
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private patientService: PatientService,
    private whiteLabelService: WhitelabelService
  ) {}

  ngOnInit(): void {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientSendCode);

    this.createForm();
  }

  createForm(): void {
    this.formGroupVerify = this.formBuilder.group({
      mfacode: [null, [Validators.required]]
    });
  }

  get mfacode() {
    return this.formGroupVerify.get('mfacode');
  }

  async sendCode(mode: MFAEnrolmentMedium) {
    this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first()).subscribe(async (info) => {
      if (info) {
        this.isSubmitting = true;
        this.b2bCustomerService.patient.pipe(first()).subscribe(async (patient) => {
          const enrolmentId: string = await this.b2bCustomerService.sendPatientEmailCode(patient, mode);
          if (enrolmentId) {
            this.functions.showToast('Code notification to patient sent successfully');
            this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientVerifyCode);
            this.enrolmentId = enrolmentId;
          }

          this.isSubmitting = false;
        });
      } else {
        console.error('ERROR in patient sendCode');
      }
    });
  }

  async verifyPatientCode() {
    if (!this.formGroupVerify.valid) {
      return;
    } else {
      this.formGroupVerify.setErrors(null);
      this.formGroupVerify.markAsPristine();
    }

    this.isSubmitting = true;
    this.b2bCustomerService.patient.pipe(first()).subscribe(async (patient) => {
      const email: string = patient?.email || this.b2bCustomerService.getPatientEmail;

      const jwtToken: JwtAccessToken = await this.b2bCustomerService.verifyPatientEmailCode(
        patient.id,
        email,
        this.enrolmentId,
        this.mfacode.value
      );

      this.invalidCode = !jwtToken;

      if (jwtToken) {
        // this.b2bCustomerService.setVerifiedPatient(this.email.value);

        const credentials: Credentials = {
          email: email,
          accessToken: jwtToken.accessToken,
          refreshToken: jwtToken.refreshToken,
          expiresInSeconds: jwtToken.expiresInSeconds
        };

        const existingPolicy: boolean = !!this.b2bCustomerService.getVerifiedPolicyClaim;

        const patientServicePatient: Patient = await this.patientService.getPatientById(patient.id, true);
        this.patientService.setPatient(patientServicePatient);

        if (existingPolicy) {
          // login as the patient
          this.credentialsService.setCredentials(credentials);

          this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.MakeBooking);
          const appointmentStepType: string = this.whiteLabelService.getAppointmentStepType(
            this.b2bCustomerService.serviceType || 'doctor'
          );
          this.router.navigate([
            STEP_PATH.APPOINTMENT,
            this.b2bCustomerService.serviceType || 'doctor',
            STEP_CONFIGURATION[appointmentStepType].CHOOSE_APPOINTMENT_TIME.virtualPath
          ]);
        } else {
          this.b2bCustomerService.setTempPatientCredentials(credentials);
          this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.GenerateNewPolicy);
          this.router.navigate(['../', B2B_STEP_PATH.GENERATE_NEW_POLICY], { relativeTo: this.activatedRoute });
        }
      } else {
        this.functions.showToast('An error occurred authenticating the patient for login');
        this.isSubmitting = false;
      }
    });
  }

  resend() {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientSendCode);
  }
}
