<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">{{ dialogData.title }}</h4>
    </div>
    <div *ngIf="!dialogData.noClose && stage != stageOption.userAnswerNo" class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div
    [formGroup]="searchForm"
    class="container-fluid text-secondary"
    [class.modal-body]="stage == stageOption.askUser"
    [class.modal-body-search]="stage == stageOption.userAnswerYes"
    [class.modal-body-search-no]="stage == stageOption.userAnswerNo">

    <p *ngIf="stage == stageOption.askUser || (stage == stageOption.userAnswerYes && items?.length < 2)" class="col-12">
      Do you need a prescription for your regular medication?
      If you have not had this medication before, you will need to speak with a Doctor
      about your health condition.
    </p>
    <p *ngIf="stage == stageOption.userAnswerNo" class="col-12">
      Sorry. Our Quickscript service is only available for repeat prescriptions of your
      current medication. If you think you need this medication, please talk to a doctor first.
    </p>

    <!-- Search / User Answer Yes -->
    <div *ngIf="items?.controls?.length">
      <div
        formArrayName="items"
        *ngFor="let item of items.controls; let i = index"
        [hidden]="stage !== stageOption.userAnswerYes"
        class="search-box">
        <div class="col-12 d-flex justify-content-end">
          <button
            *ngIf="items.length > 1"
            type="button"
            mat-button
            color="warn"
            class="del-btn"
            (click)="removeItem(i)"
            (keydown.enter)="$event.preventDefault()">
            <span>Remove item</span>
          </button>
          <br *ngIf="items.length < 2" />
        </div>
        <div class="col-12">
          <ng-container [formGroupName]="i">
            <mat-form-field appearance="outline" class="medication-search">
              <input
                formControlName="name"
                autocomplete="off"
                matInput
                [placeholder]="isNarrowMobile
                  ? 'Search your regular medication'
                  : 'Search for your regular prescription medication'"
                [matAutocomplete]="auto"
                (keydown.enter)="$event.preventDefault(); $event.target.blur();" />
              <button type="button" class="search-button" matSuffix mat-icon-button>
                <mat-icon>search</mat-icon>
              </button>
              <mat-autocomplete
                class="repeat-prescription-autocomplete"
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="displayFn"
                (optionSelected)="onMedicationSelected($event)">
                <mat-option *ngFor="let option of filteredOptions[i] | async" [value]="option">
                  <strong>{{ option.name }}</strong> {{
                    (option.shortDescription && option.name !== option.shortDescription) ? (' - ' + option.shortDescription) : '' }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <p *ngIf="messages[i] && !messages[i].blacklist" class="message">
              <span [innerHtml]="messages[i].msg"></span>
              <span *ngIf="messages[i].msg === messageOptions.notFound">
                Please <a
                  class="warn-msg-link"
                  (click)="scheduleAppointment($event)">
                  Schedule an Appointment
                </a>
              </span>
              <span *ngIf="messages[i].redirectServiceType">
                Please <a
                class="warn-msg-link"
                (click)="scheduleAppointment($event, messages[i].redirectServiceType)">
                  Schedule an Appointment
                </a>
              </span>
            </p>
            <p *ngIf="messages[i] && messages[i].blacklist" class="message message__danger">
              {{ controlledDrugWarning }}
              <strong>
                {{ controlledDrugsProceed }}
              </strong>
            </p>
            <div *ngIf="!(messages && messages[i])" class="no-msg"></div>
          </ng-container>
        </div>
      </div>
      <div class="col-12 add-section-container">
        <ng-container
          *ngIf="enableSmokingCessation && medicationCategoryName === labels.smokingCessation; else defaultSubFooterTemplate">
          <span class="warn-msg px-1">
            {{ smokingCessationMedicationWarning }}
          </span>
        </ng-container>
        <ng-template #defaultSubFooterTemplate>
          <button
            type="button"
            [hidden]="
              stage !== stageOption.userAnswerYes ||
              !isValid ||
              items.length >= maxMedicationsPerSession ||
              isAddMedication"
            mat-button
            color="accent"
            (click)="addItem()">
            Add another medication?
          </button>
          <span class="warn-msg" [hidden]="items.length < maxMedicationsPerSession || !isValid">
            You are only allowed to order {{ maxMedicationsPerSession }} medication{{
              maxMedicationsPerSession > 1 ? 's' : '' }}, if you need
            another medication please
          </span>
          <br *ngIf="!isMobile" />
          <a
            *ngIf="enableAppointments && enableOnDemand && isOnline"
            class="warn-msg-link"
            [hidden]="items.length < maxMedicationsPerSession || !isValid"
            (click)="speakToDoctor()">
            Speak to a Doctor Now {{ queueSize }}
          </a>
          <span
            *ngIf="enableAppointments && enableOnDemand && isOnline"
            [hidden]="items.length < maxMedicationsPerSession || !isValid"
            class="warn-msg">
            or
          </span>
          <a
            *ngIf="enableAppointments"
            class="warn-msg-link"
            [hidden]="items.length < maxMedicationsPerSession || !isValid"
            (click)="scheduleAppointment()">
            Schedule an Appointment
          </a>
        </ng-template>
      </div>
    </div>

    <!-- Step 1 - Ask User -->
    <div *ngIf="stage == stageOption.askUser" class="row gray-box">
      <div class="col-12 p-0">
        <div class="title">Are you currently taking this medication?</div>
      </div>
      <div class="col-6 action-buttons p-0 first-button">
        <button
          type="button"
          mat-stroked-button
          color="accent"
          class="text-wrap btn-block"
          (click)="changeStage(stageOption.userAnswerYes)">
          Yes
        </button>
      </div>
      <div class="col-6 action-buttons p-0 second-button">
        <button
        type="button"
          mat-stroked-button
          color="accent"
          class="text-wrap btn-block"
          (click)="changeStage(stageOption.userAnswerNo)">
          No
        </button>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="stage == stageOption.askUser" class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="col-12 col-md-6">
      <button
        mat-flat-button
        color="accent"
        class="text-wrap btn-block"
        (click)="onClose(true)">
        Close
      </button>
    </div>
  </div> -->

  <!-- User Answered Yes -->
  <div
    *ngIf="stage == stageOption.userAnswerYes && !(enableSmokingCessation && medicationCategoryName === labels.smokingCessation)"
    class="modal-footer container-fluid bg-muted flex-nowrap">
    <div class="col-12 mx-0 my-0 px-0 action-buttons button-wrapper">
      <ng-container *ngIf="isNoticed && !isDuplicate; else elseTemplate">
        <button
          type="button"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="speakToDoctor()">
          Speak to a Doctor Now<br />{{ queueSize }}
        </button>
      </ng-container>
      <ng-template #elseTemplate>
        <button
          type="button"
          mat-flat-button
          color="accent"
          [disabled]="!isValid || isDuplicate"
          class="btn-block text-wrap"
          (click)="onSubmit()">
          Request Medication Now
        </button>
      </ng-template>
    </div>
  </div>

  <!-- User Answered No -->
  <div
    *ngIf="stage == stageOption.userAnswerNo || (enableSmokingCessation && medicationCategoryName === labels.smokingCessation)"
    class="modal-footer container-fluid bg-muted button-wrapper">
    <div *ngIf="!isMobile" class="row w-100 m-0">
      <div *ngIf="enableAppointments && isOnline" class="first-button col-12 col-md-6 action-buttons-decline">
        <button
          type="button"
          mat-stroked-button
          color="accent"
          class="text-wrap btn-block"
          (click)="scheduleAppointment()">
          Schedule Appointment
        </button>
      </div>
      <div *ngIf="enableAppointments && !isOnline" class="col-12 action-buttons-decline">
        <button
          type="button"
          mat-flat-button
          color="accent"
          class="text-wrap btn-block"
          (click)="scheduleAppointment()">
          Schedule Appointment
        </button>
      </div>
      <div
        *ngIf="enableAppointments && enableOnDemand && isOnline"
        class="second-button col-12 col-md-6 action-buttons-decline">
        <button
          type="button"
          mat-flat-button
          color="accent"
          class="text-wrap btn-block"
          (click)="speakToDoctor()">
          Speak to a Doctor Now<br />{{ queueSize }}
        </button>
      </div>
    </div>
    <div *ngIf="isMobile" class="row w-100 m-0">
      <div class="first-button col-12 col-md-6 action-buttons-decline">
        <button
          *ngIf="enableAppointments && enableOnDemand && isOnline"
          type="button"
          mat-flat-button
          color="accent"
          class="text-wrap btn-block"
          (click)="speakToDoctor()">
          Speak to a Doctor Now<br />{{ queueSize }}
        </button>
      </div>
      <div *ngIf="enableAppointments" class="second-button col-12 col-md-6 action-buttons-decline">
        <button
          type="button"
          mat-stroked-button
          color="accent"
          class="text-wrap btn-block"
          (click)="scheduleAppointment()">
          Schedule Appointment
        </button>
      </div>
    </div>
  </div>
</div>
