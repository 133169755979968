import { Injectable } from '@angular/core';
import { Functions } from '../functions';
import { PromiseWithParams } from '../models/http/promiseWithParams';

@Injectable({
  providedIn: 'root',
})
export class PromiseHelperService {
  private _promiseStore: any = {};

  constructor(
    private functions: Functions
  ) {}

  /**
   * @function validatePromise
   * @description Check whether a stored Promise meets the specified criteria
   * eg. the parameters have changed, an error occurred, the promise was recently returned
   *
   * @param {string} key storage key of Promise
   * @param {T} [newParams] optionally check parameters to see if they are the same as the stored request paramters
   * @param {number} [secondsAllowedBetweenRequests] number of seconds allowed between requests
   *
   * @returns {boolean} true if the specified Promise passes criteria
   */
  validatePromise<T>(key: string, newParams?: T, secondsAllowedBetweenRequests?: number): boolean {
    if (
      // Check if the Promise exists
      this._promiseStore[key]?.promise &&
      // Make sure the previous API call did not return an error
      !this._promiseStore[key].error &&
      // Check whether an API call is in progress (loading) or has been made recently
      (
        this._promiseStore[key].loading ||
        !this._promiseStore[key].timestamp ||
        (
          typeof secondsAllowedBetweenRequests === 'number' &&
          this.isRecent(this._promiseStore[key].timestamp, secondsAllowedBetweenRequests)
        )
      ) &&
      // Check that the parameters are the same
      (this._promiseStore[key].params && newParams && this.functions.deepCompare(this._promiseStore[key].params, newParams))
    ) {
      return true;
    }

    return false;
  }

  /**
   * @function storePromise<T>
   * @description Store a Promise object in this service
   *
   * @param {string} key storage key
   * @param {Promise<T>} promiseToStore Promise object to store
   * @param {any} [params] parameters to store so we can check if they have changed between requests
   */
  storePromise<T>(key: string, promiseToStore: Promise<T>, params?: any): void {
    if (!this._promiseStore[key]) {
      this._promiseStore[key] = new PromiseWithParams();
    }

    this._promiseStore[key].error = null;
    this._promiseStore[key].loading = true;
    this._promiseStore[key].params = params ?? null;
    this._promiseStore[key].timestamp = new Date();
    this._promiseStore[key].promise = promiseToStore;
  }

  storePromiseParams<T>(key: string, params: T): void {
    if (!this._promiseStore[key]) {
      this._promiseStore[key] = new PromiseWithParams();
    }
    this._promiseStore[key].params = params;
  }

  /**
   * @function retrieveParams
   * @description Retrieve 'params' from a stored promise
   *
   * @param {string} key Promise storage key
   * @returns {T} parameters of the stored Promise
   */
  retrieveParams<T>(key: string): T | null {
    if (!this._promiseStore[key]?.params) {
      return null;
    }
    return this._promiseStore[key].params as T;
  }

  /**
   * @function getPromiseByKey
   * @description Retrieve a Promise object by its storage key
   *
   * @param {string} key
   * @returns {Promise<any>|null}
   */
  getPromiseByKey<T>(key: string): Promise<T> | null {
    if (!this._promiseStore[key]?.promise) {
      return Promise.resolve(null);
    }
    return this._promiseStore[key].promise;
  }

  setLoadingState(key: string, isLoading: boolean = false): void {
    this._promiseStore[key].loading = isLoading;
  }

  resetLoadingState(key: string): void {
    this._promiseStore[key].loading = false;
  }

  toggleLoadingState(key: string): void {
    this._promiseStore[key].loading = !this._promiseStore[key].loading;
  }

  setErrorState(key: string, error: any): void {
    this._promiseStore[key].error = error;
  }

  resetErrorState(key: string): void {
    this._promiseStore[key].error = null;
  }

  resetPromise(key: string): void {
    if (!this._promiseStore[key]) {
      this._promiseStore[key] = new PromiseWithParams();
    }
    this._promiseStore[key].error = null;
    this._promiseStore[key].loading = null;
    this._promiseStore[key].params = null;
    this._promiseStore[key].timestamp = null;
    this._promiseStore[key].promise = null;
  }

  /**
   * @private
   * @function isRecent
   * @description Check whether the timestamp for the last API execution
   * occurred within a set time period (eg. within the last 25 seconds)
   *
   * @param {Date} date
   *
   * @returns {boolean} true if timestamp is within the specified time period
   */
  private isRecent(date: Date, secondsBetweenRequests: number): boolean {
    return this.functions.isRecent(date, secondsBetweenRequests);
  }
}
