import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { Appointment } from '../models/appointment';
import { AppointmentHours } from '../models/appointment-hours';
import { AppointmentPrice } from '../models/appointment-price';
import { AppointmentPricingData } from '../models/appointment-pricing-data';
import { AppointmentDTO } from '../models/appointmentDTO';
import { AppointmentPatientNotifyWaitingRequestDTO } from '../models/appointmentPatientNotifyWaitingRequestDTO';
import { AppointmentPricingSearch } from '../models/appointmentPricingSearch';
import { AppointmentStatus } from '../models/appointmentStatus';
import { AppointmentTermsDTO } from '../models/appointmentTermsDTO';
import { AvailabilityGroups } from '../models/availabilityGroups';
import { AvailabilitySearch } from '../models/availabilitySearch';
import { Benefit } from '../models/benefit';
import { CancelAppointmentDTO } from '../models/cancelAppointmentDTO';
import { ErrorTypes, ModalError, ModalType } from '../models/error-data';
import { FollowUpAppointment } from '../models/followup';
import { GetAppointmentTermsDTO } from '../models/getAppointmentTermsDTO';
import { Patient } from '../models/patient';
import { Practitioner } from '../models/practitioner';
import { PractitionerGender } from '../models/practitionerGender';
import { Prescription } from '../models/prescription';
import { Question, Questionnaire, ValueMap } from '../models/questionnaire';
import { RecentlyBookedAppointment } from '../models/recentlyBookedAppointment';
import { ServicePrice } from '../models/service-price';
import { Term } from '../models/term';
import { TimeZone } from '../models/time-zone';
import { WordpressSearch } from '../models/wordpressSearch';
import { ActiveCampaignService } from './active-campaign.service';
import { AgencyService } from './agency.service';
import { AIContext, AppInsightsService } from './appinsights.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { PatientService } from './patient.service';
import { PractitionerService } from './practitioner.service';
import { PricingService } from './pricing.service';
import { PromiseHelperService } from './promise-helper.service';
import { StepService } from './step.service';
import { TimezoneService } from './timezone.service';
import { WhitelabelService } from './whitelabel.service';
import { STEP_CONFIGURATION } from '../step-configuration';
import { AppointmentDataService } from './appointment/appointment-data.service';
import { AppointmentErrorsService } from '@src/app/core/services/appointment-errors.service';
import { environment } from '@env/environment';
import BluaPostAppointmentQuestionnaire from '@app/shared/static-resources/bluaPostConsultQuestionnaire.json';
import DoDPostAppointmentQuestionnaire from '@app/shared/static-resources/dodPostConsultQuestionnaire.json';
import FertilityQuestionnaire from '@app/shared/static-resources/fertilityQuestionnaire.json';
import MentalHealthQuestionnaire from '@app/shared/static-resources/mentalHealthQuestionnaire.json';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private readonly aiContext: AIContext;

  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private readonly patientUrl: string = `${this.url}/patient`;
  private readonly agentStatusUrl: string = `${environment.apiBaseUrl}${this.endpointPrefix}/liveChatAgentOnlineStatus`;

  private subscription = new Subscription();

  // If running PWA locally, this will attempt to access the API locally too
  // private readonly agentStatusUrl: string = environment.useMockServer
  //   ? `${this.endpointPrefix}/agentOnlineStatus`
  //   : `${environment.apiBaseUrl}${this.endpointPrefix}/agentOnlineStatus`;

  private _faqsAndGuides: WordpressSearch[];

  private _benefit: Benefit = null;

  //@LocalStorage()
  private _appointment: Appointment = null;
  //@LocalStorage()
  private _availabilityParameter: AvailabilitySearch = null;

  private _recentlyBookedAppointment: any = {};
  private _recentlyBookedAppointmentCheck: Subject<string> = new Subject<string>();
  public recentlyBookedAppointmentCheckObs = this._recentlyBookedAppointmentCheck.asObservable();

  // @LocalStorage()
  private _appointments: any;

  private _sessionChange: Subject<Appointment> = new Subject<Appointment>();
  public sessionChangeObs = this._sessionChange.asObservable();

  private _sessionTimesChange: Subject<any> = new Subject<any>();
  public sessionTimesChangeObs = this._sessionTimesChange.asObservable();

  private _availabilityParamsChange: Subject<AvailabilitySearch> = new Subject<AvailabilitySearch>();
  public availabilityParamsChangeObs = this._availabilityParamsChange.asObservable();

  private _appointmentListChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public appointmentListChangeObs = this._appointmentListChange.asObservable();

  // private _liveChatAgentOnlineStatusChange: Subject<boolean> = new Subject<boolean>();
  // public liveChatAgentOnlineStatusChangeObs = this._liveChatAgentOnlineStatusChange.asObservable();

  private acceptedAppointmentTerms: any = {};
  private _availabilityGroupsForDay: any;

  private _isCreatingAppointment: boolean = false;
  public get isCreatingAppointment(): boolean {
    return this._isCreatingAppointment;
  }

  private _appointmentCreationNotifier = new Subject<string>();
  public appointmentCreationNotifierObs = this._appointmentCreationNotifier.asObservable();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private patientService: PatientService,
    private stepService: StepService,
    private pricingService: PricingService,
    private credentialsService: CredentialsService,
    private analytics: GoogleAnalyticsService,
    private storageLocal: SessionStorageService,
    private timezoneService: TimezoneService,
    private agencyService: AgencyService,
    private appinsightsService: AppInsightsService,
    private authenticationService: AuthenticationService,
    private activeCampaignService: ActiveCampaignService,
    private practitionerService: PractitionerService,
    private appointmentErrorService: AppointmentErrorsService,
    private whiteLabelService: WhitelabelService,
    private promiseHelperService: PromiseHelperService,
    private appointmentDataService: AppointmentDataService
  ) {
    this.aiContext = this.appinsightsService.createContext('AppointmentService');

    this._init();
  }

  private _init(): void {
    this._benefit = this.patientService.benefit || null;
    // this.updateAppointmentPricing();

    // APPOINTMENT
    this.subscription.add(
      this.sessionChangeObs.subscribe((appointment: Appointment) => {
        this.addAppointment(appointment); // add appointment to service
        this.storeAppointments(); // save service appointments to local storage
      })
    );

    // BENEFIT CHANGED
    // If the benefit is updated we need to update the appointment pricing as the applied benefit
    // affects several things such as pricing and what times are considered BH/AH
    this.subscription.add(
      this.patientService.benefitChangeObs.subscribe((benefit: Benefit) => {
        if (benefit === null || benefit?.policyId !== this._benefit?.policyId) {
          this._benefit = benefit;
          // this.updateAppointmentPricing();
        }
      })
    );

    // BENEFIT REMOVED
    this.subscription.add(
      this.agencyService.agencyBenefitRemovedObs.subscribe((removedSuccess: boolean) => {
        if (removedSuccess) {
          this._benefit = null;
          // this.patientService.setBenefit(null);
          if (this._appointment) {
            this._appointment.policyId = null;
            this._appointment.policyNumber = null;
          }
        }
      })
    );

    // PATIENT
    this.subscription.add(
      this.patientService.patientChangeObs.subscribe((patient: Patient) => {
        if (this._appointment) {
          this._appointment.patientId = patient?.patientId || null;
        }
      })
    );
  }

  triggerRecentlyBookedAppointmentCheck(): void {
    this._recentlyBookedAppointmentCheck.next(null);
  }

  /**
   * @async
   * @function updateAppointmentPricing
   * @description Update the duration, AH/BH param and price for the current appointment (if any)
   *
   * @returns {Promise<ModalError | null>}
   */
  async updateAppointmentPricing(): Promise<ModalError | null> {
    if (!this._appointment || (!this._appointment?.isOnDemand && !this._appointment?.startTimeUTC)) {
      return null;
    }

    const languageFilterId: string =
      this.availabilityParameter?.languageId && this.availabilityParameter.languageId !== 'All'
        ? this.availabilityParameter.languageId
        : null;

    const appointmentStartTime: string = !this._appointment.isOnDemand
      ? this._appointment.startTimeUTC || this._appointment.start
      : null;

    const timezoneOffSetHours: string =
      this._appointment.patientTimezoneOffsetHours ||
      this.availabilityParameter?.timezoneOffSetHours ||
      this.patientService.getPatientTimeZoneOffset(appointmentStartTime);

    let pricingSearchDTO: AppointmentPricingSearch = this.createAppointmentPricingDTO(
      this._appointment,
      this.availabilityParameter,
      timezoneOffSetHours,
      appointmentStartTime,
      languageFilterId,
      this.patientService.benefit?.policyId || null
    );

    if (sessionStorage.getItem(Constants.LocalStorage_Key.availabilityLockResourceAccessToken)) {
      pricingSearchDTO.availabilityLockResourceAccessToken = sessionStorage.getItem(
        Constants.LocalStorage_Key.availabilityLockResourceAccessToken
      );
    }

    const appointmentPricingErrorObj: ModalError = await this.getAppointmentPricing(
      this.functions.removeEmpty(pricingSearchDTO),
      true
    )
      .then((response: AppointmentPrice) => {
        if (response?.servicePrice) {
          this.updateAppointmentDetailPricing({ ...this._appointment }, response, true);
          return null;
        }
        return {
          success: false,
          modalType: ModalType.errorModal,
          modalData: {
            title: 'Unable to Retrieve Appointment Pricing',
            message: 'Pricing details are missing in the response. Please contact support.',
            isClose: true
          }
        } as ModalError;
      })
      .catch((err: any) => {
        this.aiContext.error('UpdateAppointmentPricing', {
          error: err,
          errorMessage: this.functions.getErrorMessage(err)
        });

        return this.appointmentErrorService.getAppointmentError(
          this._appointment,
          ErrorTypes.pricing,
          err,
          this.practitionerService.practitionerList?.length,
          this.whiteLabelService.getWhiteLabelConfig()
        );
      });

    return appointmentPricingErrorObj;
  }

  /**
   * @function addAppointment
   * @description Add the specified appointment to the data store (map) of appointments
   *
   * @param {Appointment} appointment
   */
  addAppointment(appointment: Appointment): void {
    if (!this._appointments) {
      this._appointments = {};
    }

    const appointmentId: string = appointment?.appointmentId ?? null;

    if (appointmentId) {
      this._appointments[appointmentId] = appointment;
    }
  }

  /**
   * @function setAppointment
   * @description Save the current appointment to the Step Service and trigger Appointment change
   * observer (if any of the appointment details have changed)
   *
   * @param {Appointment} appointment
   *
   * @returns {Appointment} appointment with updated details
   */
  setAppointment(appointment?: Appointment): Appointment {
    if (appointment && !appointment.patientId) {
      appointment.patientId = this.patientService.patient?.patientId || null;
    }

    if (!this._appointment || !this.functions.deepCompare(appointment, this._appointment)) {
      this._sessionChange.next((this._appointment = appointment || null));
    }

    if (appointment) {
      this.addAppointment(appointment);

      // Save appointment to StepService (will also be saved in sessionStorage)
      if (this.stepService.isAppointmentStepType() || this.stepService.isWaitingRoomStepType()) {
        this.stepService.setAppointmentSessionData({
          ...(this.stepService.getServiceData() || {}),
          appointment
        });
      }
    }

    return appointment || null;
  }

  storeAppointments(): void {
    try {
      this.storageLocal.store(Constants.LocalStorage_Key.appointments, this._appointments || null);
    } catch (_err: any) {}
  }

  /**
   * @function isAppointmentFinished
   * @description Check if the specified appointment is in a finished state, with one of the following statuses:
   * 'Appointment Finalised', 'Appointment Finished', 'Session Ended', or 'Appointment Closed'.
   *
   * @param {Appointment} appointment
   *
   * @returns {boolean} true if appointment is finished
   */
  isAppointmentFinished(appointment?: Appointment): boolean {
    const appointmentStatus: string = appointment
      ? appointment.appointmentStatus
      : this.appointment
        ? this.appointment.appointmentStatus
        : null;

    if (appointmentStatus) {
      return (
        [
          Constants.Appointment_Status.AppointmentFinalised,
          Constants.Appointment_Status.AppointmentFinished,
          Constants.Appointment_Status.SessionEnded,
          Constants.Appointment_Status.AppointmentClosed
        ].indexOf(appointmentStatus) !== -1
      );
    }

    return false;
  }

  /**
   * @function isAppointmentCancelled
   * @description Check if the specified appointment has been cancelled (has "Appointment Cancelled" status)
   * @param {Appointment} [appointment]
   *
   * @returns {boolean} true if cancelled
   */
  isAppointmentCancelled(appointment?: Appointment): boolean {
    return appointment && appointment.appointmentStatus === Constants.Appointment_Status.AppointmentCancelled;
  }

  /**
   * @function isAppointmentExpired
   * @description Check if the specified appointment has expired (has "Appointment Expired" status)
   * @param {Appointment} [appointment]
   *
   * @returns {boolean} true if expired
   */
  isAppointmentExpired(appointment?: Appointment): boolean {
    return appointment && appointment.appointmentStatus === Constants.Appointment_Status.AppointmentExpired;
  }

  /**
   * @function isAppointmentNotAttendedByPatient
   * @description Check if the specified appointment has been marked as missed by patient (has "MISSED_BY_PATIENT" status)
   * @param {Appointment} [appointment]
   *
   * @returns {boolean} true if patient has missed
   */
  isAppointmentPatientNotAttended(appointment?: Appointment): boolean {
    return (
      appointment &&
      AppointmentStatus[appointment.lastClosedStatusCode]?.toString() === AppointmentStatus.MISSED_BY_PATIENT.toString()
    );
  }

  /**
   * @function isAppointmentNeverJoinedByPatient
   * @description Check if the specified appointment has been joined by the patient
   * @param {Appointment} [appointment]
   *
   * @returns {boolean} true if patient never joined
   */
  isAppointmentNeverJoinedByPatient(appointment?: Appointment): boolean {
    return appointment && !appointment.patientHasJoinedAppointment;
  }

  /**
   * @function getRecentlyBookedAppointment
   * @description Retrieve recently booked appointments for the specified patient
   *
   * @param {string} patientId the patient who's recently booked appointments need to be retrieved
   *
   * @returns {Appointment} recently booked appointment for the specified patient, or null
   */
  getRecentlyBookedAppointment(patientId: string): Appointment {
    if (!this._recentlyBookedAppointment) {
      this._recentlyBookedAppointment = {};
    }

    if (!this._recentlyBookedAppointment[patientId]) {
      try {
        this._recentlyBookedAppointment[patientId] =
          this.storageLocal.retrieve(Constants.LocalStorage_Key.appointment.concat('.', patientId)) || null;
      } catch (_err: any) {}
    }

    return this._recentlyBookedAppointment[patientId] || null;
  }

  /**
   * @function setRecentlyBookedAppointment
   * @description Save the recently booked appointment to memory and Local Storage
   *
   * @param {Appointment} appointment the recently booked appointment to save
   */
  setRecentlyBookedAppointment(appointment: Appointment): void {
    const patientId: string = appointment?.patientId || this.patientService.patient?.patientId || null;

    if (patientId) {
      this._recentlyBookedAppointment[patientId] = appointment;

      try {
        this.storageLocal.store(Constants.LocalStorage_Key.appointment.concat('.', patientId), appointment);
      } catch (_err: any) {}
    }
  }

  /**
   * @function clearRecentlyBookedAppointment
   * @description Remove from Local Storage all recently booked appointments for the specified patient
   *
   * @param {string} patientId the patient who's recently booked appointments need to be removed
   * @param {string} appointmentId specific appointmentId to clear, if not found abort operation
   */
  clearRecentlyBookedAppointment(patientId: string, appointmentId?: string): void {
    const appointment: Appointment = this.getRecentlyBookedAppointment(patientId);

    if (appointment && appointmentId && this._recentlyBookedAppointment[patientId]?.appointmentId !== appointmentId) {
      return;
    }

    this._recentlyBookedAppointment[patientId] = null;

    try {
      this.storageLocal.clear(Constants.LocalStorage_Key.appointment.concat('.', patientId));
    } catch (_err: any) {}
  }

  /**
   * @async
   * @function checkRecentlyBookedAppointments
   * @description Check if another appointment has been booked recently by the specified patient
   *
   * @param {string} patientId
   * @param {string} newAppointmentStartTime
   * @param {Appointment} [currentAppointment]
   *
   * @returns {Promise<RecentlyBookedAppointment>} object with details of the most recently booked appointment
   */
  async checkRecentlyBookedAppointments(
    patientId: string,
    newAppointmentStartTime: string,
    currentAppointment?: Appointment
  ): Promise<RecentlyBookedAppointment> {
    if (patientId && currentAppointment) {
      // const bookedAppointment: Appointment = this.getRecentlyBookedAppointment(patientId);
      let allPatientAppointments: Appointment[] = await this.getAppointments(patientId);

      // if (allPatientAppointments?.length) {
      //   allPatientAppointments = allPatientAppointments.filter((appt: Appointment) => {
      //     return !this.isAppointmentCancelled(appt)
      //       && !this.isAppointmentFinished(appt)
      //       && appt.startTimeUTC
      //       && moment(newAppointmentStartTimeUTC).is;
      //   });
      // }

      const newAppointmentStartTimeUTC: string =
        currentAppointment.isOnDemand && !newAppointmentStartTime ? moment.utc().format() : newAppointmentStartTime;

      for (let i = 0; i < allPatientAppointments?.length; i++) {
        const bookedAppointment: Appointment = allPatientAppointments[i];

        if (
          newAppointmentStartTimeUTC &&
          bookedAppointment &&
          (bookedAppointment?.startTimeUTC || bookedAppointment?.start) &&
          bookedAppointment.appointmentId &&
          bookedAppointment.appointmentId !== currentAppointment?.appointmentId &&
          (!bookedAppointment.patientId ||
            !currentAppointment?.patientId ||
            bookedAppointment.patientId === currentAppointment.patientId) &&
          !this.isAppointmentFinished(bookedAppointment) &&
          !this.isAppointmentCancelled(bookedAppointment) &&
          !this.isAppointmentExpired(bookedAppointment)
        ) {
          // We need to confirm that the previously booked appointment is still valid, query the API for details
          // const appointment: Appointment = await this.getAppointmentDetail(bookedAppointment.appointmentId);

          // if (
          //   !appointment ||
          //   this.isAppointmentFinished(appointment) ||
          //   this.isAppointmentCancelled(appointment) ||
          //   this.isAppointmentExpired(appointment)
          // ) {
          //   this.clearRecentlyBookedAppointment(patientId, bookedAppointment.appointmentId);
          //   continue;
          // }

          const bookedAppointmentStartTime: string = bookedAppointment.startTimeUTC || bookedAppointment.start;

          // Quick Check - if appointments are more than a day apart, exit here to avoid performing additional calculations
          if (
            Math.abs(
              moment.utc(newAppointmentStartTimeUTC).diff(moment.utc(bookedAppointmentStartTime), 'days', true)
            ) >= 1
          ) {
            continue;
          }

          /*
          const offsetHoursForPreviouslyBookedAppointment: string = bookedAppointment.patientTimezoneOffsetHours
            || this.patientService.getPatientTimeZoneOffset(bookedAppointmentStartTime);

          const offsetHoursForNewAppointment: string = currentAppointment.patientTimezoneOffsetHours
            || this.patientService.getPatientTimeZoneOffset(newAppointmentStartTimeUTC || undefined);

          // const offsetHoursForNow: string = currentAppointment.patientTimezoneOffsetHours
          //   || this.patientService.getPatientTimeZoneOffset();

          const previouslyBookedAppointmentTime: moment.Moment = this.functions.getUTCMoment(
            offsetHoursForPreviouslyBookedAppointment,
            false, // start of day
            false, // end of day
            bookedAppointmentStartTime
          );
          const newAppointmentDateTime: moment.Moment = this.functions.getUTCMoment(
            offsetHoursForNewAppointment,
            false, // start of day
            false, // end of day
            newAppointmentStartTimeUTC
          );

          // const now: moment.Moment = this.functions.getUTCMoment(offsetHoursForNow);
          const now: moment.Moment = this.functions.getUTCMoment(offsetHoursForNewAppointment);
          */

          const previouslyBookedAppointmentTime: moment.Moment = moment.utc(bookedAppointmentStartTime);
          const newAppointmentDateTime: moment.Moment = moment.utc(newAppointmentStartTimeUTC);
          const now: moment.Moment = moment.utc();

          // Calculate absolute minutes from last booked appointment
          const previouslyBookedMinutesFromNewAppointment: number = Math.abs(
            newAppointmentDateTime.diff(previouslyBookedAppointmentTime, 'minutes')
          );

          const minutesUntilPreviouslyBookedAppointment: number = previouslyBookedAppointmentTime.diff(now, 'minutes');

          // const recentlyBookedThresholdMinutes: number = bookedAppointment?.duration
          //   ? Math.floor(bookedAppointment.duration / 2)
          //   : Constants.SERVICE_TIME_CONFIG.RECENTLY_BOOKED_THRESHOLD_BOTTOM;

          // Has the previous booked appointment finished/expired?
          // if (minutesUntilPreviouslyBookedAppointment < (-1 * recentlyBookedThresholdMinutes)) {
          // this.clearRecentlyBookedAppointment(patientId, bookedAppointment.appointmentId);

          // Is there another appointment booked for current patient within set timeframe? (eg. 6 hours)
          // Special case for appointments with a different service type, we allow for a 60 minute window between
          // different kinds of appointments
          // } else
          if (
            previouslyBookedMinutesFromNewAppointment <
              Constants.SERVICE_TIME_CONFIG.RECENTLY_BOOKED_THRESHOLD_MINUTES &&
            (currentAppointment.serviceType === bookedAppointment.serviceType ||
              previouslyBookedMinutesFromNewAppointment < 60)
          ) {
            let practitionerName: string = bookedAppointment.practitionerName;
            if (!practitionerName && bookedAppointment.practitionerId) {
              const practitioner: Practitioner = await this.practitionerService.getPractitionerById(
                bookedAppointment.practitionerId
              );
              practitionerName = practitioner?.practitionerName || practitioner?.name;
            }
            if (!practitionerName) {
              practitionerName = 'First available doctor';
            }

            const numHours: number = Math.ceil(
              Constants.SERVICE_TIME_CONFIG.RECENTLY_BOOKED_THRESHOLD_MINUTES / Constants.MINUTES_IN_HOUR
            );
            const withinAppointmentJoinThreshold: boolean =
              minutesUntilPreviouslyBookedAppointment <= Constants.SERVICE_TIME_CONFIG.APPOINTMENT_TIME_THRESHOLD &&
              minutesUntilPreviouslyBookedAppointment > Constants.SERVICE_TIME_CONFIG.APPOINTMENT_TIME_THRESHOLD_BOTTOM;
            const withinCancellationThreshold: boolean =
              bookedAppointment.canBeCancelled &&
              moment(bookedAppointmentStartTime).diff(now, 'minutes') >=
                Constants.SERVICE_TIME_CONFIG.CANCELLATION_THRESHOLD;
            const isRecentlyFinishedAppointment: boolean = minutesUntilPreviouslyBookedAppointment <= -10; // within 10 minutes of appointment finish
            // const appointmentCanBeCancelled: boolean =
            //   minutesUntilPreviouslyBookedAppointment <= Constants.SERVICE_TIME_CONFIG.CANCELLATION_THRESHOLD;
            // currentAppointment.followupId &&
            // currentAppointment.followUpPatientId === patientId &&

            const recentlyBookedErrorMessage: string = this.getPreviouslyBookedAppointmentMessage(
              numHours,
              practitionerName,
              previouslyBookedAppointmentTime,
              bookedAppointment,
              withinAppointmentJoinThreshold,
              withinCancellationThreshold,
              isRecentlyFinishedAppointment
            );

            return {
              bookedAppointment,
              recentlyBookedErrorMessage,
              withinAppointmentJoinThreshold,
              withinCancellationThreshold,
              isRecentlyFinishedAppointment
            } as RecentlyBookedAppointment;
          }
        }
      }
    }

    return null;
  }

  /**
   * @function getPreviouslyBookedAppointmentMessage
   * @description Generate a message with the previously booked appointment details
   *
   * @param {number} numHours
   * @param {string} practitionerName
   * @param {moment.Moment} previouslyBookedAppointmentTime
   * @param {Appointment} previouslyBookedAppointment
   * @param {boolean} withinAppointmentJoinThreshold
   * @param {boolean} withinCancellationThreshold
   * @param {boolean} isRecentlyFinishedAppointment
   *
   * @returns {string} message to display to the patient
   */
  getPreviouslyBookedAppointmentMessage(
    numHours: number,
    practitionerName: string,
    previouslyBookedAppointmentTime: moment.Moment,
    previouslyBookedAppointment: Appointment,
    withinAppointmentJoinThreshold: boolean,
    withinCancellationThreshold: boolean,
    isRecentlyFinishedAppointment: boolean
  ): string {
    const timezoneOffsetString: string =
      previouslyBookedAppointment.patientTimezoneOffsetHours || this.patientService.getPatientTimeZoneOffset();
    const numericTimezoneOffset: number = timezoneOffsetString
      ? parseFloat(timezoneOffsetString)
      : Constants.Default_TimeZone_Offset;
    const timeZoneLabel: string = this.functions.getTimeZoneOffsetString(timezoneOffsetString);
    const formattedDisplayTime: string = previouslyBookedAppointmentTime
      .clone()
      .utcOffset(numericTimezoneOffset)
      .format(Constants.appointmentBookingDateTime);

    let message: string = `
      You have another appointment booked within ${numHours} hours of the selected session:
      <br/><br/>
      <strong>
        ${practitionerName} - ${formattedDisplayTime} (UTC${timeZoneLabel})
      </strong>
      <br/><br/>
      Are you sure you would like to schedule another appointment?
      <br/><br/>
    `;

    if (withinAppointmentJoinThreshold && !isRecentlyFinishedAppointment) {
      message += 'You may join this appointment by clicking on the Go to Waiting Room button.';
    } else if (withinCancellationThreshold || previouslyBookedAppointment.canBeRescheduled) {
      message += 'Please ' + (withinCancellationThreshold ? 'cancel' : '');
      message += withinCancellationThreshold && previouslyBookedAppointment.canBeRescheduled ? ' or ' : '';
      message += previouslyBookedAppointment.canBeRescheduled ? 'reschedule' : '';
      message += ' your <strong>existing appointment</strong> or you would be charged for both bookings.';
    } else {
      message += `Your <strong>existing appointment</strong> cannot be cancelled or rescheduled! If you proceed, you will be
        charged for both bookings.`;
    }

    message +=
      '<br/><br/>If you wish to proceed with the current booking anyway, click on the X in the top right corner of this popup.';

    return message;
  }

  /**
   * @async
   * @function setSessionScheduled
   * @description Create a scheduled appointment session
   * (initialise patient profile, retrieve benefit, set availability parameters)
   *
   * @param {string} [serviceType=Constants.SERVICE_TYPE.DOCTOR] appointment service type (defaults to 'doctor')
   * @param {string} appointmentReason reason for appointment
   * @param {AvailabilitySearch} [availability] availability parameters
   * @param {TimeZone} [selectedTimezone=null] timezone object
   *
   * @returns {Promise<boolean>} 'true' if retrieved pricing successfully and set availability parameters
   */
  async setSessionScheduled(
    serviceType: string,
    appointmentReason: string,
    availability?: AvailabilitySearch,
    selectedTimezone: TimeZone = null
  ): Promise<void> {
    this.clearMedicareBenefitIfNotApplicable();

    // Load TimeZone data
    await this.timezoneService.getTimezoneData();

    // Load Patient profile
    const patient: Patient = await this.patientService.setDefaultPatient();

    const haveMedicare: boolean = this.patientService.doesPatientHaveMedicare();
    const offsetTimeZone: string = this.patientService.getPatientTimeZoneId();
    const offsetHours: string = selectedTimezone?.offset || this.patientService.getPatientTimeZoneOffset();
    const timeZoneName: string = selectedTimezone?.timeZone || this.patientService.getPatientTimeZoneId();
    const timeZoneLabel: string = this.timezoneService.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
      timeZoneName,
      offsetHours
    );

    // Load Benefit
    const benefitCode: string = this.patientService.getStoredBenefitCode();
    let benefit: Benefit = this.patientService.benefit || null;

    if ((!benefit && benefitCode) || (benefit && benefit.name !== benefitCode)) {
      benefit = await this.patientService.retrieveBenefit();
    }

    const appointment: Appointment = {
      patientId: patient?.patientId || null,
      anonymousStart: !this.patientService.isAuthenticatedPatient(),
      billingType: Constants.APPOINTMENT_BILLING_TYPE.ALL,
      serviceType: serviceType || Constants.SERVICE_TYPE.DOCTOR,
      isOnDemand: false,
      appointmentReason,
      doesPatientHaveMedicare: haveMedicare,
      patientTimezoneOffsetId: offsetTimeZone,
      patientTimezoneOffsetHours: offsetHours,
      patientTimezoneLabel: timeZoneLabel,
      policyId: benefit?.policyId || null,
      policyNumber: benefit?.name || null,
      startTimeUTC: this._appointment?.startTimeUTC || this._appointment?.start
    } as Appointment;

    this._appointment = this.setAppointment(appointment);

    const availabilityParam: AvailabilitySearch = this.createAvailabilityParameter(
      serviceType,
      false, // onDemand
      benefit?.policyId || null,
      null,
      null,
      offsetTimeZone,
      offsetHours
    );

    this.setAvailabilityParameter({
      ...availabilityParam,
      ...(availability ?? {})
    });
  }

  /**
   * @async
   * @function setSessionOnDemand
   * @description Create an on-demand appointment session
   * (initialise patient profile, retrieve benefit, retrieve pricing and set availability parameters)
   *
   * @param {string} [serviceType=Constants.SERVICE_TYPE.DOCTOR] appointment service type (defaults to 'doctor')
   * @param {string} [appointmentReason=''] reason for appointment
   * @param {AvailabilitySearch} [availability] availability parameters
   * @param {TimeZone} [selectedTimezone=null] timezone object
   *
   * @returns {Promise<boolean>} 'true' if retrieved pricing successfully and set availability parameters
   */
  async setSessionOnDemand(
    serviceType: string = Constants.SERVICE_TYPE.DOCTOR,
    appointmentReason: string = '',
    availability?: AvailabilitySearch,
    selectedTimezone: TimeZone = null
  ): Promise<boolean> {
    this.clearMedicareBenefitIfNotApplicable();

    // Load TimeZone data
    await this.timezoneService.getTimezoneData();

    // Load Patient profile
    const patient: Patient = await this.patientService.setDefaultPatient();

    // Load Benefit
    const benefit: Benefit = await this.patientService.retrieveBenefit();
    const timeZoneName: string = selectedTimezone?.timeZone || this.patientService.getPatientTimeZoneId();
    const offsetHours: string = timeZoneName
      ? this.timezoneService.getTimezoneUtcOffsetForGivenDateAndTimezoneId(undefined, timeZoneName)
      : this.patientService.getPatientTimeZoneOffset();
    const timeZoneLabel: string = this.timezoneService.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
      timeZoneName,
      offsetHours
    );

    const pricingParameter: AppointmentPricingSearch = {
      isOnDemand: true,
      serviceType,
      duration: Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration,
      patientTimezoneOffsetHours: offsetHours,
      policyId: benefit?.policyId || null,
      sexFilter: this.availabilityParameter?.sex || PractitionerGender.All,
      businessHoursOptions: this.availabilityParameter?.hours || AppointmentHours.All
    };

    // Get appointment pricing
    const pricing: AppointmentPrice = await this.getAppointmentPricing(pricingParameter).catch((error: any) => {
      if (
        this.functions.getErrorCode(error) ===
        Constants.API_ERROR_CODES.CANT_CREATE_APPOINTMENT_NO_PRACTITIONER_AVAILABLE
      ) {
        // onDemand doctor not available.
        console.log(
          '[APPOINTMENT-SERVICE] setSessionOnDemand() :: Pricing call failed. ' + 'onDemand doctor NOT available!'
        );
      } else {
        console.log(
          '[APPOINTMENT-SERVICE] setSessionOnDemand() :: Failed to retrieve pricing ' + 'for an appointment. Error: ',
          this.functions.getErrorMessage(error)
        );
      }
      return null;
    });

    if (pricing) {
      const policyId: string = benefit?.policyId || null;
      const benefitCode: string = benefit?.name || null;
      // const offsetHours: string = selectedTimezone?.offset || this.patientService.getPatientTimeZoneOffset();
      // const timeZoneName: string = selectedTimezone?.timeZone || this.patientService.getPatientTimeZoneId();
      // const timeZoneLabel: string = this.timezoneService.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
      //   timeZoneName,
      //   offsetHours
      // );
      const now: moment.Moment = this.functions.getUTCMoment(offsetHours);
      const haveMedicare: boolean = this.patientService.doesPatientHaveMedicare();
      const servicePrice: number = this.pricingService.calculateServicePrice(pricing.servicePrice);
      const bookingFee: number = this.pricingService.getBookingFeeNonDiscountedPrice(pricing.servicePrice);

      const appointment: Appointment = {
        patientId: patient?.patientId || null,
        anonymousStart: !this.patientService.isAuthenticatedPatient(),
        isOnDemand: true,
        isAfterHours: pricing.isAfterHours,
        serviceType,
        billingType: Constants.APPOINTMENT_BILLING_TYPE.ALL,
        doesPatientHaveMedicare: haveMedicare,
        patientTimezoneOffsetId: timeZoneName,
        patientTimezoneOffsetHours: offsetHours,
        patientTimezoneLabel: timeZoneLabel,
        appointmentReason,
        duration: pricing.appointmentDuration,
        startTime: now.toDate(),
        startTimeUTC: now.clone().utc().format(),
        price: servicePrice,
        originalPrice: pricing.servicePrice?.originalServiceAmount ?? servicePrice,
        originalBookingFee: bookingFee,
        policyId,
        policyNumber: benefitCode
      };

      this._appointment = this.setAppointment(appointment);

      const availabilityParam: AvailabilitySearch = this.createAvailabilityParameter(
        serviceType,
        true, // onDemand
        policyId,
        null,
        null,
        timeZoneName,
        offsetHours
      );

      this.setAvailabilityParameter({
        ...availabilityParam,
        ...(availability ?? {})
      });

      return true;
    }

    return false;
  }

  clearMedicareBenefitIfNotApplicable(): void {
    const benefitCode: string = this.patientService.getStoredBenefitCode();

    if (
      benefitCode &&
      /medicare/i.test(benefitCode) &&
      this._appointment?.serviceType !== Constants.SERVICE_TYPE.MENTAL_HEALTH
    ) {
      this.patientService.resetBenefit();
    }
  }

  /**
   * @function setAvailabilityParameter
   * @description Remember the selected appointment availability parameters to be used
   * in subsequent availability calls to the API
   *
   * @param {AvailabilitySearch} parameter
   * @param {boolean} [haltUpdate=false]
   */
  setAvailabilityParameter(parameter?: AvailabilitySearch, haltUpdate: boolean = false): AvailabilitySearch {
    this._availabilityParameter = parameter || null;

    // Save availability params to StepService (will also be saved in sessionStorage)
    if (this.stepService.isAppointmentStepType()) {
      this.stepService.setAppointmentSessionData({
        ...this.stepService.getServiceData(),
        availability: this._availabilityParameter
      });
    }

    if (!haltUpdate) {
      this._availabilityParamsChange.next(this._availabilityParameter);
    }

    return this._availabilityParameter;
  }

  /**
   * @function createAvailabilityParameter
   * @description Create a minimal availability request object (parameters for an appointment availability API call)
   *
   * @param {string} serviceType eg. 'doctor' or 'psychietrist'
   * @param {boolean} isOnDemand
   * @param {string} [policyId=null]
   * @param {AppointmentHours|string} [hours=AppointmentHours.All]
   * @param {string[]} [practitioners]
   * @param {string} [timezoneId]
   * @param {string} [timezoneOffsetHours]
   *
   * @returns {AvailabilitySearch} core parameters for an availability search
   */
  createAvailabilityParameter(
    serviceType: string = Constants.SERVICE_TYPE.DOCTOR,
    isOnDemand: boolean,
    policyId: string = null,
    hours: AppointmentHours = AppointmentHours.All,
    practitioners?: string[],
    timezoneId?: string,
    timezoneOffsetHours?: string,
    includeAllBookingTypes: boolean = false
  ): AvailabilitySearch {
    const param: AvailabilitySearch = {
      appointmentBookingType: includeAllBookingTypes
        ? Constants.APPOINTMENT_BOOKING_TYPE.ALL
        : isOnDemand
          ? Constants.APPOINTMENT_BOOKING_TYPE.ON_DEMAND
          : Constants.APPOINTMENT_BOOKING_TYPE.SCHEDULED,
      serviceTypes: JSON.stringify([serviceType]),
      billingType: Constants.APPOINTMENT_BILLING_TYPE.ALL,
      timezoneId: timezoneId ?? this.patientService.getPatientTimeZoneId(),
      timezoneOffSetHours: timezoneOffsetHours ?? this.patientService.getPatientTimeZoneOffset(),
      doesPatientHaveMedicare: this.patientService.doesPatientHaveMedicare(),
      practitionerIdsToLimitBy: practitioners?.length ? JSON.stringify(practitioners) : null,
      shouldGetOnDemandQueueSizes: Boolean(isOnDemand),
      policyId,
      hours,
      sex: PractitionerGender.All
    };

    return param;
  }

  /**
   * @function resetService
   * @description Remove all appointment related data stored in memory and local/session storage
   */
  resetService(): void {
    this.appointmentDataService.reset();
    this.stepService.clearServiceData();
    this.stepService.removeAppointmentFromStorage();
    this.stepService.removeWaitingRoomFromStorage();
    this.authenticationService.removeAppointmentData();
    this.activeCampaignService.clearStoredIdsForEventsOfType('appointment');
    this.clearAllAcceptedAppointmentTerms();
    this.setAvailabilityParameter(null);
    this.setAppointment(null);
  }

  /**
   * @function getLocalDateFromAppointment
   * @description Get the local representation of an appointment start or end date using patient timezone offset
   *
   * @param {Appointment} appointment
   * @param {string} [timezoneOffsetHoursString] if not provided will
   *
   * @returns {[string, string]} StartDateLocal and EndDateLocal strings
   */
  getLocalDatesFromAppointment(appointment: Appointment, timezoneOffsetHoursString?: string): [string, string] {
    let startDate: string;
    let endDate: string;

    if (!timezoneOffsetHoursString) {
      timezoneOffsetHoursString =
        appointment?.patientTimezoneOffsetHours ||
        this.patientService.getPatientTimeZoneOffset(appointment?.startTimeUTC || appointment?.start);
    }

    try {
      // Start Date
      if (!appointment?.start) {
        if (appointment?.startTimeUTC) {
          startDate = this.functions.getUTCMomentString(
            timezoneOffsetHoursString,
            false,
            false,
            appointment?.startTimeUTC
          );
        } else {
          // Now
          startDate = this.functions.getUTCMomentString(timezoneOffsetHoursString);
        }
      } else {
        startDate = this.functions.getUTCMomentString(timezoneOffsetHoursString, false, false, appointment.start);
      }

      // End Date
      if (!appointment?.end) {
        endDate = this.functions.getUTCMomentString(
          timezoneOffsetHoursString,
          false,
          true, // set to end of day
          startDate
        );
      } else {
        endDate = this.functions.getUTCMomentString(timezoneOffsetHoursString, false, false, appointment.end);
      }

      const now: moment.Moment = this.functions.getUTCMoment(timezoneOffsetHoursString);

      // is startDate in the past? set it to now
      if (moment(startDate).diff(now, 'minutes') < 0) {
        startDate = this.functions.getUTCMomentString(timezoneOffsetHoursString);
      }

      // is endDate in the past? set it to end of day
      if (moment(endDate).diff(now, 'minutes') < 0) {
        endDate = this.functions.getUTCMomentString(
          timezoneOffsetHoursString,
          false,
          true, // set to end of day
          startDate
        );
      }
    } catch (err: any) {
      console.log(
        'Unable to calculate start and end dates for appointment. Error:',
        this.functions.getErrorMessage(err)
      );
    }

    return [startDate, endDate];
  }

  /**
   * @function isRebateOrBulkBill
   * @description Determine whether the specified appointment is Bulk Bill or Rebate only.
   *
   * @param {Appointment} [appointment] defaults to current appointment
   *
   * @returns 'true' if billing type is Bulk Bill or Rebate only, otherwise 'false'
   */
  isRebateOrBulkBill(appointment?: Appointment): string {
    const appt: Appointment = appointment ?? this.appointment;

    if (appt?.serviceType === Constants.SERVICE_TYPE.MENTAL_HEALTH && this.patientService.doesPatientHaveMedicare()) {
      return 'true';
    }

    let isRebate: string = 'false';

    if (appt?.billingType) {
      isRebate =
        appt.billingType === Constants.APPOINTMENT_BILLING_TYPE.BULKBILL ||
        appt.billingType === Constants.APPOINTMENT_BILLING_TYPE.REBATE
          ? 'true'
          : 'false';
    }

    return isRebate;
  }

  /**
   * @function updateAppointmentDetailPricing
   * @description Update appointment with the latest pricing data, duration
   *
   * @param {Appointment} appointment
   * @param {AppointmentPrice} pricing
   * @param {boolean} [setAppointment=false] Save the modified appointment
   *
   * @returns {Appointment} updated appointment object
   */
  updateAppointmentDetailPricing(
    appointment: Appointment,
    pricing: AppointmentPrice,
    setAppointment: boolean = false
  ): Appointment {
    let appt: Appointment = { ...appointment };
    const servicePrice: ServicePrice = pricing?.servicePrice;

    appt.patientId = this.patientService.patient?.patientId || null;

    if (!servicePrice) {
      return appt;
    }

    // Calculate duration for this appointment
    let duration: number;
    if (typeof pricing?.appointmentDuration === 'number' && pricing?.appointmentDuration /* && appt.isOnDemand*/) {
      duration = pricing.appointmentDuration;
    } else {
      duration =
        typeof appt.duration === 'number'
          ? appt.duration
          : Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration;
    }

    // Determine whether the appointment is After Hours
    let isAfterHours: boolean;
    if (typeof pricing?.isAfterHours === 'boolean') {
      isAfterHours = pricing.isAfterHours;
    } else {
      isAfterHours = typeof appt.isAfterHours === 'boolean' ? appt.isAfterHours : !appt.businessHours;
    }

    // Update appointment data and pricing
    appt.duration = duration;
    appt.isAfterHours = isAfterHours;
    appt.price = this.pricingService.calculateTotal(servicePrice);
    appt.originalPrice = servicePrice.serviceAmount;
    appt.originalBookingFee = this.pricingService.getBookingFeeNonDiscountedPrice(servicePrice);

    if (setAppointment) {
      appt = this.setAppointment(appt);
    }

    return appt;
  }

  /**
   * @function storeAcceptedAppointmentTerms
   * @description Save completed terms and conditions consent for the specified patient in local storage
   *
   * @param {Term[]} terms
   * @param {string} patientId
   */
  storeAcceptedAppointmentTerms(terms: Term[], patientId: string): void {
    if (!patientId) {
      return;
    }

    this.acceptedAppointmentTerms[patientId] = terms || null;

    try {
      this.storageLocal.store(
        Constants.LocalStorage_Key.acceptedAppointmentTerms.concat('.', patientId),
        terms || null
      );
    } catch (_err: any) {}
  }

  /**
   * @function retrieveStoredAcceptedAppointmentTerms
   * @description Retrieve completed terms and conditions consent for the specified patient from local storage
   *
   * @param {string} patientId
   */
  retrieveStoredAcceptedAppointmentTerms(patientId: string): Term[] {
    if (!patientId) {
      return null;
    } else if (this.acceptedAppointmentTerms[patientId]) {
      return this.acceptedAppointmentTerms[patientId];
    }

    try {
      return (
        this.storageLocal.retrieve(Constants.LocalStorage_Key.acceptedAppointmentTerms.concat('.', patientId)) || null
      );
    } catch (_err: any) {}
  }

  /**
   * @function clearAcceptedAppointmentTerms
   * @description Remove completed terms and conditions consent for the specified patient from local storage
   *
   * @param {string} patientId
   */
  clearAcceptedAppointmentTerms(patientId: string): void {
    if (!patientId) {
      return;
    }

    this.acceptedAppointmentTerms[patientId] = null;

    try {
      Object.keys(window.localStorage).forEach((key: string) => {
        if (key.indexOf(Constants.LocalStorage_Key.acceptedAppointmentTerms.concat('.', patientId)) !== -1) {
          window.sessionStorage.removeItem(key);
        }
      });
    } catch (_err: any) {}
  }

  /**
   * @function clearAllAcceptedAppointmentTerms
   * @description Remove completed terms and conditions consent for all patients from memory and local storage
   */
  clearAllAcceptedAppointmentTerms(): void {
    this.acceptedAppointmentTerms = {};

    try {
      Object.keys(window.localStorage).forEach((key: string) => {
        if (key.indexOf(Constants.LocalStorage_Key.acceptedAppointmentTerms) !== -1) {
          window.sessionStorage.removeItem(key);
        }
      });
    } catch (_err: any) {}
  }

  // GET https://www.doctorsondemand.com.au/wp-json/pwa/v1/faqs/user_guides?search=
  getFaqsAndGuides(): Promise<WordpressSearch[]> {
    if (Array.isArray(this._faqsAndGuides) && this._faqsAndGuides.length) {
      return Promise.resolve(this._faqsAndGuides);
    }
    return this.http
      .get(environment.faqsAndGuidesUrl)
      .toPromise()
      .then((response: WordpressSearch[]) => {
        if (response && Array.isArray(response)) {
          let filteredArray: WordpressSearch[] = [];

          response.forEach((item: WordpressSearch) => {
            const index: number = filteredArray.findIndex(
              (x: WordpressSearch) => x.sub_category_link === item.sub_category_link
            );

            if (index === -1) {
              filteredArray.push(item);
            }
          });

          this._faqsAndGuides = filteredArray;

          return this._faqsAndGuides;
        }
        return [];
      })
      .catch((err: any) => this.functions.handleError(err));
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/liveChatAgentOnlineStatus
  getAgentOnlineStatus(): Promise<any> {
    return this.http
      .get(this.agentStatusUrl)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          const isAgentOnline: boolean = !!response.response;
          // this._liveChatAgentOnlineStatusChange.next(isAgentOnline);

          return isAgentOnline;
        }

        // this._liveChatAgentOnlineStatusChange.next(false);
        return false;
      })
      .catch((err: any) => {
        // this._liveChatAgentOnlineStatusChange.next(false);
        this.functions.handleError(err);
      });
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/availabilities
  getAvailability(
    availabilitySearch: AvailabilitySearch,
    storeResults: boolean = false
  ): Promise<AvailabilityGroups[]> {
    let params = new HttpParams();
    let search: AvailabilitySearch = { ...availabilitySearch };

    if (search?.startDatePatientLocal?.indexOf('+') > 0) {
      search.startDatePatientLocal = this.functions.formatStringDateForAPI(search.startDatePatientLocal);
    }
    if (search?.endDatePatientLocal?.indexOf('+') > 0) {
      search.endDatePatientLocal = this.functions.formatStringDateForAPI(search.endDatePatientLocal);
    }

    for (const key of Object.keys(search)) {
      if (typeof search[key] !== 'undefined') {
        params = params.append(key, search[key]);
      }
    }

    return this.http
      .get(`${this.url}/availabilities`, { params })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && Array.isArray(response.response?.availabilitiesForPeriod)) {
          let availabilities = response.response.availabilitiesForPeriod as AvailabilityGroups[];

          // Track session times by date
          if (!this._availabilityGroupsForDay) {
            this._availabilityGroupsForDay = {};
          }

          availabilities.forEach((availability: AvailabilityGroups) => {
            if (availability.date) {
              const shortDate: string = availability.date.substring(0, 10); //.replace('T00:00:00', '');
              availability.date = shortDate;
              this._availabilityGroupsForDay[shortDate] = availability.availabilityGroupsForDay;
            }
          });

          this._sessionTimesChange.next(this._availabilityGroupsForDay);

          if (storeResults) {
            try {
              this.storageLocal.store(Constants.LocalStorage_Key.availabilities, availabilities);
              this.storageLocal.store(Constants.LocalStorage_Key.availabilityDayGroups, this._availabilityGroupsForDay);
            } catch (_err: any) {}
          }

          return availabilities;
        }
        return [];
      })
      .catch((err: any) => this.functions.handleError(err));
  }

  /**
   * @function getAppointmentPricingFromAppointmentAndFilterParams
   * @description Retrieve appointment pricing based on supplied appointment and availability parameter data
   *
   * @param {Appointment} appointment
   * @param {AvailabilitySearch} availabilityParameters
   * @param {boolean} [throwOnError=false] re-throw Exception if there is an appointment pricing error?
   *
   * @returns {Promise<AppointmentPricingData>} return the pricing object as well as the parameters that were used in the API call
   */
  async getAppointmentPricingFromAppointmentAndFilterParams(
    appointment: Appointment,
    availabilityParameters: AvailabilitySearch,
    // Some consumers of this method have their custom error handling.
    // Hence, we should rethrow the exception in those cases.
    throwOnError: boolean = false
  ): Promise<AppointmentPricingData> {
    let pricingSearchDTO = await this.getAppointmentPricingSearchDTO(appointment, availabilityParameters);

    return await this.getAppointmentPricingFromPricingSearchDTO(pricingSearchDTO, throwOnError);
  }

  /**
   * @function getAppointmentPricingFromAppointmentAndFilterParams
   * @description Retrieve appointment pricing based on supplied pricingSearchDTO
   *
   * @param {AppointmentPricingSearch} pricingSearchDTO
   * @param {boolean} [throwOnError=false] re-throw Exception if there is an appointment pricing error?
   *
   * @returns {Promise<AppointmentPricingData>} return the pricing object as well as the parameters that were used in the API call
   */
  async getAppointmentPricingFromPricingSearchDTO(
    pricingSearchDTO: AppointmentPricingSearch,
    // Some consumers of this method have their custom error handling.
    // Hence, we should rethrow the exception in those cases.
    throwOnError: boolean = false
  ): Promise<AppointmentPricingData> {
    // Get appointment pricing
    const pricing: AppointmentPrice = await this.getAppointmentPricing(
      this.functions.removeEmpty(pricingSearchDTO),
      throwOnError
    ).catch((error: any) => {
      if (
        this.functions.getErrorCode(error) ===
        Constants.API_ERROR_CODES.CANT_CREATE_APPOINTMENT_NO_PRACTITIONER_AVAILABLE
      ) {
        // onDemand doctor not available.
        this.aiContext.warn('GetAppointmentPricingFromAppointmentAndFilterParams', {
          errorMessage: 'On-Demand Practitioner Unavailable'
        });
      } else {
        this.aiContext.warn('GetAppointmentPricingFromAppointmentAndFilterParams', {
          errorMessage: this.functions.getErrorMessage(error)
        });
      }

      if (throwOnError) {
        throw error;
      } else {
        this.functions.handleError(error);
      }

      return null;
    });

    return { pricing, pricingSearchDTO } as AppointmentPricingData;
  }

  async getAppointmentPricingSearchDTO(
    appointment: Appointment,
    availabilityParameters: AvailabilitySearch
  ): Promise<AppointmentPricingSearch> {
    const languageFilterId: string =
      this.availabilityParameter?.languageId && this.availabilityParameter.languageId !== 'All'
        ? this.availabilityParameter.languageId
        : null;

    const appointmentStartTime: string = !appointment.isOnDemand ? appointment.startTimeUTC || appointment.start : null;

    const timezoneOffSetHours: string =
      appointment?.patientTimezoneOffsetHours ||
      availabilityParameters?.timezoneOffSetHours ||
      this.patientService.getPatientTimeZoneOffset(appointmentStartTime);

    const benefit: Benefit = await this.patientService.retrieveBenefit();

    const pricingSearchDTO: AppointmentPricingSearch = this.createAppointmentPricingDTO(
      appointment,
      availabilityParameters,
      timezoneOffSetHours,
      appointmentStartTime,
      languageFilterId,
      benefit?.policyId || null
    );

    return pricingSearchDTO;
  }

  /**
   * @function createAppointmentPricingDTO
   * @description Generate a pricing search object with all the required parameters
   *
   * @param {Appointment} appointment
   * @param {AvailabilitySearch} availabilityParams
   * @param {string} timezoneOffSetHours
   * @param {string} [appointmentStartTime]
   * @param {string} [languageFilterId]
   * @param {string} [policyId]
   *
   * @returns {AppointmentPricingSearch}
   */
  createAppointmentPricingDTO(
    appointment: Appointment,
    availabilityParams: AvailabilitySearch,
    timezoneOffSetHours: string,
    appointmentStartTime: string = null,
    languageFilterId: string = null,
    policyId: string = null
  ): AppointmentPricingSearch {
    return {
      isOnDemand: Boolean(appointment.isOnDemand),
      serviceType: appointment.serviceType || Constants.SERVICE_TYPE.DOCTOR,
      duration:
        typeof appointment.duration === 'number'
          ? appointment.duration
          : Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration,
      practitionerId: appointment.practitionerId || null,
      sexFilter: availabilityParams?.sex || PractitionerGender.All,
      businessHoursOptions: availabilityParams?.hours || AppointmentHours.All,
      languageFilterId,
      policyId,
      shouldLimitByAgencyCode:
        appointment.shouldLimitByAgencyCode ?? (availabilityParams?.shouldLimitByAgencyCode || null),
      patientTimezoneOffsetHours: timezoneOffSetHours,
      startTimeUTC: Boolean(appointment.isOnDemand) ? null : appointmentStartTime,
      availabilityLockResourceAccessToken: sessionStorage.getItem(
        Constants.LocalStorage_Key.availabilityLockResourceAccessToken
      )
    } as AppointmentPricingSearch;
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/pricingForAnAppointment
  getAppointmentPricing(
    searchParams: AppointmentPricingSearch,
    throwOnError: boolean = false
  ): Promise<AppointmentPrice | null> {
    const promiseStorageKey: string = 'appointmentPricing';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<AppointmentPricingSearch>(
      promiseStorageKey,
      searchParams,
      Constants.API_Polling_Times.appointmentPricing_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      this.aiContext.info('GetAppointmentPricing', {
        source: 'API',
        message: 'Retrieving Appointment pricing',
        searchParams
      });

      let params = new HttpParams();
      for (const key of Object.keys(searchParams)) {
        if (typeof searchParams[key] !== 'undefined') {
          params = params.append(key, searchParams[key]);
        }
      }

      const newPromise: Promise<AppointmentPrice | null> = this.http
        .get(`${this.url}/appointment/pricingForAnAppointment`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          if (response?.response) {
            return response.response as AppointmentPrice;
          }

          return null;
        })
        .catch((err: any) => {
          this.aiContext.error('GetAppointmentPricing', {
            searchParams,
            source: 'API',
            error: err,
            errorMessage: this.functions.getErrorMessage(err)
          });

          this.promiseHelperService.setErrorState(promiseStorageKey, err);

          if (throwOnError) {
            throw err;
          } else {
            this.functions.handleError(err);
            return null;
          }
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<AppointmentPrice>(promiseStorageKey, newPromise, searchParams);
    } else {
      this.aiContext.info('GetAppointmentPricing', {
        source: 'Cache',
        message: 'Returning cached Appointment pricing',
        searchParams
      });
    }

    return this.promiseHelperService.getPromiseByKey<AppointmentPrice | null>(promiseStorageKey);
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/appointment
  createAppointment(appointmentDTO: AppointmentDTO): Promise<IResponseAPI | null> {
    this.aiContext.info('CreateAppointment', {
      policyId: appointmentDTO.policyId,
      practitionerId: appointmentDTO.practitionerId,
      serviceType: appointmentDTO.serviceType,
      isOnDemand: appointmentDTO.isOnDemand,
      startTimeUTC: appointmentDTO.startTimeUTC,
      agency: this.agencyService.agencyCode
    });

    const promiseStorageKey: string = 'createAppointment';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<AppointmentDTO>(
      promiseStorageKey,
      appointmentDTO,
      Constants.API_Polling_Times.createAppointment_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      this._isCreatingAppointment = true;
      const newPromise: Promise<IResponseAPI | null> = this.http
        .post(`${this.url}/appointment`, appointmentDTO)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.aiContext.debug('CreateAppointmentResult', {
              result: true,
              appointmentId: response.response?.appointmentId
            });

            this.promiseHelperService.resetErrorState(promiseStorageKey);

            this._appointmentCreationNotifier.next(response.response?.appointmentId);

            return response;
          }

          this.aiContext.error('CreateAppointmentResult', {
            result: false,
            reason: 'API call unsuccessful',
            success: response?.success,
            error: response?.error
            // errorData: response?.errorData ?? 'N/A',
            // warning: response?.warning ?? 'N/A'
          });

          this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');

          return response;
        })
        .catch((err: any) => {
          if (err.status !== 401) {
            this.aiContext.trackException(err, 'CreateAppointmentResult', { result: false, reason: 'ExceptionThrown' });
          }

          this.promiseHelperService.setErrorState(promiseStorageKey, err);

          this.functions.handleError(err);

          return err;
        })
        .finally(() => {
          this._isCreatingAppointment = false;
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<IResponseAPI | null>(promiseStorageKey, newPromise, appointmentDTO);
    }

    return this.promiseHelperService.getPromiseByKey<IResponseAPI | null>(promiseStorageKey);
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/{appointmentId}/cancel
  cancelAppointment(
    appointmentId: string,
    patientId: string,
    cancelReason: CancelAppointmentDTO
  ): Promise<IResponseAPI | null> {
    if (!appointmentId || !cancelReason) {
      return Promise.resolve(null);
    }

    const promiseStorageKey: string = 'cancelAppointment';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<string>(
      promiseStorageKey,
      appointmentId,
      Constants.API_Polling_Times.cancelAppointment_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      const newPromise: Promise<IResponseAPI | null> = this.http
        .post(`${this.url}/appointment/${appointmentId}/cancel`, cancelReason)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);

            this.clearRecentlyBookedAppointment(patientId, appointmentId);
            this.analytics.appointmentCancellation(appointmentId);
            this._appointmentListChange.next(null);

            return response;
          }

          this.aiContext.error('CancelAppointment', {
            reason: 'API call unsuccessful',
            success: false,
            result: response?.response,
            error: response?.error
          });

          this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');

          return response;
        })
        .catch((err: any) => {
          this.aiContext.error('CancelAppointment', {
            reason: 'API call threw exception',
            success: false,
            result: null,
            error: err,
            errorMessage: this.functions.getErrorMessage(err)
          });
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<IResponseAPI | null>(promiseStorageKey, newPromise, appointmentId);
    }

    return this.promiseHelperService.getPromiseByKey<IResponseAPI | null>(promiseStorageKey);
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/terms/appointment/{serviceType}
  getAppointmentTerms(appointment?: Appointment): Promise<Term[] | null> {
    const appt: Appointment = appointment || this.appointment;
    const serviceType: string = appt?.serviceType || null;

    if (!serviceType) {
      return Promise.resolve(null);
    }

    const isAppointmentRebateOrBulkBill: string = this.isRebateOrBulkBill(appt);
    const isCustomPharmacyBeingUsed: string = 'false';
    let policyId: string = null;

    let params = new HttpParams();

    params = params
      .append('isAppointmentRebateOrBulkBill', isAppointmentRebateOrBulkBill)
      .append('isCustomPharmacyBeingUsed', isCustomPharmacyBeingUsed);

    if (appt.policyId) {
      params = params.append('policyId', appt.policyId);
      policyId = appt.policyId;
    }

    const getAppointmentTermsDTO: GetAppointmentTermsDTO = {
      serviceType,
      isAppointmentRebateOrBulkBill,
      isCustomPharmacyBeingUsed,
      policyId
    };

    const promiseStorageKey: string = 'getAppointmentTerms';
    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<GetAppointmentTermsDTO>(
      promiseStorageKey,
      getAppointmentTermsDTO,
      Constants.API_Polling_Times.cancelAppointment_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      const newPromise: Promise<Term[] | null> = this.http
        .get(`${this.url}/terms/appointment/${serviceType}`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response?.terms) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);

            const terms = response.response.terms as Term[];
            return terms;
          }

          this.aiContext.error('GetAppointmentTerms', {
            reason: 'API call unsuccessful',
            success: false,
            result: response?.response,
            error: response?.error
          });

          this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');

          return null;
        })
        .catch((err: any) => {
          this.aiContext.error('GetAppointmentTerms', {
            reason: 'API call threw exception',
            success: false,
            result: null,
            error: err,
            errorMessage: this.functions.getErrorMessage(err)
          });
          this.promiseHelperService.setErrorState(promiseStorageKey, err);
          this.functions.handleError(err);
          return null;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<Term[] | null>(promiseStorageKey, newPromise, getAppointmentTermsDTO);
    }

    return this.promiseHelperService.getPromiseByKey<Term[] | null>(promiseStorageKey);
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/acceptedTerms/appointment
  postAppointmentTerms(terms: Term[], appointment?: Appointment): Promise<boolean> {
    if (!terms?.length) {
      return Promise.resolve(false);
    }

    const appt: Appointment = appointment || this.appointment;
    const patientId: string = appointment?.patientId || this.patientService.patient?.patientId || null;

    if (patientId) {
      if (appt?.serviceType) {
        const appointmentTermsDTO: AppointmentTermsDTO = {
          patientId,
          acceptedTerms: terms.map((t: Term) => {
            return {
              termId: t.termId,
              isTermAccepted: t.isTermAccepted
            };
          }),
          serviceType: appt.serviceType,
          appointmentFlowTermVariables: {
            isAppointmentRebateOrBulkBill: this.isRebateOrBulkBill(appt),
            isCustomPharmacyBeingUsed: false,
            agencyCode: this.agencyService.agencyCode,
            policyId: appt.policyId
          },
          appointmentId: appointment?.appointmentId || null
        };

        return this.http
          .post(`${this.url}/acceptedTerms/appointment`, appointmentTermsDTO)
          .toPromise()
          .then((response: IResponseAPI) => {
            if (response?.success) {
              const success = Boolean(response.response);

              if (success) {
                // this.clearAcceptedAppointmentTerms(patientId);
                this.clearAllAcceptedAppointmentTerms();
              }

              return success;
            }
            return false;
          })
          .catch((err: any) => {
            this.aiContext.error('PostAppointmentTerms', {
              result: false,
              error: err,
              errorMessage: this.functions.getErrorMessage(err)
            });
            return false;
          });
      } else {
        this.functions.handleError('Cannot POST Terms. Appointment data not available.');
        return Promise.resolve(false);
      }
    } else {
      this.functions.handleError('Cannot POST Terms. Patient data not available.');
      return Promise.resolve(false);
    }
  }

  /**
   * @function getFertilityQuestions
   * @description Get static Fertility Questionnaire
   */
  getFertilityQuestions(): Promise<Questionnaire> {
    return Promise.resolve(FertilityQuestionnaire);
  }

  /**
   * @function getMentalHealthQuestions
   * @description Get static Mental Health Questionnaire
   */
  getMentalHealthQuestions(): Promise<Questionnaire> {
    return Promise.resolve(MentalHealthQuestionnaire);
  }

  /**
   * @function getBluaPostAppointmentQuestions
   * @description Get static Blua NPS Questionnaire
   */
  getBluaPostAppointmentQuestions(appointment?: Appointment): Promise<Questionnaire | null> {
    if (this.isAppointmentPatientNotAttended(appointment)) {
      return Promise.resolve(null);
    }
    return Promise.resolve(BluaPostAppointmentQuestionnaire);
  }

  /**
   * @function getDoDPostAppointmentQuestions
   * @description Get static DoD NPS Questionnaire
   */
  getDoDPostAppointmentQuestions(): Promise<Questionnaire> {
    return Promise.resolve(DoDPostAppointmentQuestionnaire);
  }

  mapSpecialistFormValues(formQuestions: Question[]): ValueMap | null {
    if (!formQuestions?.length) {
      return null;
    }

    return formQuestions.reduce<ValueMap>((prev: Question, current: Question) => {
      if (current.isShown) {
        return Object.assign(prev, { [current.questionId]: current.responseValue });
      } else {
        return prev;
      }
    }, {});
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/{appointmentId}/postAppointmentQuestions
  getPostAppointmentQuestions(
    appointmentId?: string,
    agencyCode?: string,
    b2BCustomerName?: string
  ): Promise<Questionnaire | null> {
    if (!appointmentId) {
      return Promise.resolve(null);
    }

    // Client is Blua or Blua BVAC?
    if (agencyCode === 'blua' || b2BCustomerName === Constants.B2B_CUSTOMER_NAME.BLUA_BVAC) {
      return this.getBluaPostAppointmentQuestions(this._appointment);
    } else if (!agencyCode || agencyCode === 'dod') {
      return this.getDoDPostAppointmentQuestions();
    }

    return this.http
      .get(`${this.url}/appointment/${appointmentId}/postAppointmentQuestions/${agencyCode}`)
      .toPromise()
      .then((response: IResponseAPI) => {
        return response?.success ?? false;
      })
      .catch((err: any) => {
        this.aiContext.error('GetPostAppointmentQuestions', {
          result: false,
          appointmentId,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // POST https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/{appointmentId}/specialistForm
  savePreAppointmentQuestions(
    appointmentId?: string,
    preAppointmentQuestions: Question[] = []
  ): Promise<boolean | null> {
    const specialistForm: ValueMap = this.mapSpecialistFormValues(preAppointmentQuestions);

    if (!appointmentId || !specialistForm) {
      return Promise.resolve(null);
    }

    return this.http
      .post(`${this.url}/appointment/${appointmentId}/specialistForm`, { specialistForm })
      .toPromise()
      .then((response: IResponseAPI) => {
        return response?.success ?? false;
      })
      .catch((err: any) => {
        this.aiContext.error('SavePreAppointmentQuestions', {
          result: false,
          appointmentId,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/patient/{patientId}/appointments
  getAppointments(patientId?: string, forceAPICall: boolean = false): Promise<Appointment[]> {
    const currentPatientId: string = patientId || this.patientService.patient?.patientId;

    if (!currentPatientId) {
      return Promise.resolve([]);
    }

    const promiseStorageKey: string = 'retrieveAppointmentList';
    const returnExistingPromise: boolean =
      !forceAPICall &&
      this.promiseHelperService.validatePromise<string>(
        promiseStorageKey,
        currentPatientId,
        Constants.API_Polling_Times.retrieveAppointmentList_SecondsBetweenRequests
      );

    if (!returnExistingPromise) {
      const newPromise: Promise<Appointment[]> = this.http
        .get(`${this.patientUrl}/${currentPatientId}/appointments`)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);

            this._appointments = {};
            let appointments = (response.response.appointments || []) as Appointment[];
            if (appointments?.length) {
              appointments.forEach((appointment: Appointment) => {
                // This is a legacy issue and no longer requires a fix
                // appointment.startTimeUTC = this.functions.appendZeroTimezoneIfMissing(appointment.startTimeUTC);
                this.addAppointment(appointment);
              });
              this.storeAppointments();
            }

            return appointments;
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          return [];
        })
        .catch((err: any) => {
          this.aiContext.error('GetPatientAppointments', {
            result: false,
            patientId,
            error: this.functions.getErrorMessage(err)
          });

          this.promiseHelperService.setErrorState(promiseStorageKey, err);

          this.functions.handleError(err);

          return [];
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<Appointment[]>(promiseStorageKey, newPromise, patientId);
    }

    return this.promiseHelperService.getPromiseByKey<Appointment[]>(promiseStorageKey);
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/{appointmentId}/details
  getAppointmentDetail(appointmentId: string): Promise<Appointment> {
    if (!appointmentId || !this.credentialsService.isAuthenticated()) {
      return Promise.resolve(null);
    }

    return this.http
      .get(`${this.url}/appointment/${appointmentId}/details`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          let agencyCode: string = response.response.agencyCode ?? null;
          let appointment = response.response.appointmentDetails as Appointment;

          if (appointment) {
            appointment.agencyCode = agencyCode;
            appointment.startTimeUTC = this.functions.appendZeroTimezoneIfMissing(appointment.startTimeUTC);
            appointment.originalPrice = response.response.appointmentDetails.servicePrice || 0;
            appointment.originalBookingFee = response.response.appointmentDetails.bookingFeePrice || 0;
            this.addAppointment(appointment);
            this.storeAppointments();
          } else {
            this.aiContext.error('GetAppointmentDetail', {
              result: true,
              appointmentId,
              error: 'Appointment details missing from response.'
            });
          }

          return appointment;
        }
        return null;
      })
      .catch((err: any) => {
        this.aiContext.error('GetAppointmentDetail', {
          result: false,
          appointmentId,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // POST /api/v1/appointment/{appointmentId}/scriptResend
  scriptResend(appointmentId: string, prescription?: Prescription): Promise<IResponseAPI | null> {
    return this.http
      .post(`${this.url}/appointment/${appointmentId}/scriptResend`, {})
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          this.analytics.resendPrescriptionToPharmacy(prescription);
        }
        return response;
      })
      .catch((err: any) => {
        this.aiContext.error('ScriptResend', {
          result: false,
          appointmentId,
          prescription,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // PUT https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/{appointmentId}
  updateAppointment(appointmentId: string, newDate: string): Promise<IResponseAPI | null> {
    if (!appointmentId || !newDate) {
      return Promise.resolve(null);
    }

    let updateAppointmentRequestDTO: any = {
      newStartTimeUTC: newDate
    };

    return this.http
      .put(`${this.url}/appointment/${appointmentId}`, updateAppointmentRequestDTO)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          this.analytics.appointmentReschedule(appointmentId);

          if (typeof this._appointments === 'object' && this._appointments[appointmentId]) {
            const appointment: Appointment = this._appointments[appointmentId];
            const duration: number =
              typeof appointment.duration === 'number'
                ? appointment.duration
                : Constants.AppointmentTimeTable_Configuration.defaultAppointmentDuration;

            const offsetHours: string =
              appointment.patientTimezoneOffsetHours || this.patientService.getPatientTimeZoneOffset(newDate);
            const numericTimezoneOffset: number = offsetHours
              ? parseFloat(offsetHours)
              : Constants.Default_TimeZone_Offset;

            const startDate: moment.Moment = this.functions.getUTCMoment(offsetHours, false, false, newDate);

            this.addAppointment({
              ...appointment,
              start: newDate,
              startTimeUTC: newDate,
              end: startDate
                .clone()
                .utcOffset(numericTimezoneOffset)
                .add(duration, 'minutes')
                .format(Constants.UTC_Date_Format_Shortened)
            });
          }
        }
        return response;
      })
      .catch((err: any) => {
        this.aiContext.error('UpdateAppointment', {
          result: false,
          appointmentId,
          newDate,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // GET https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/followup/{followupId}
  getFollowupById(followupId: string): Promise<FollowUpAppointment | null> {
    return this.http
      .get(`${this.url}/appointment/followup/${followupId}`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          let followup = response.response as FollowUpAppointment;

          if (followup?.oldAppointment) {
            followup.oldAppointment = followup.oldAppointment as Appointment;
          } else {
            this.aiContext.error('GetFollowupById', {
              result: true,
              followupId,
              followup,
              error: `Can't proceed with a follow-up appointment, original appointment details are missing.`
            });
            return null;
          }

          return followup;
        }

        return null;
      })
      .catch((err: any) => {
        this.aiContext.error('GetFollowupById', {
          result: false,
          followupId,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // POST /api/v1/appointment/{appointmentId}/patientNotifyWaiting
  patientNotifyWaiting(
    appointmentPatientNotifyWaitingRequestDTO: AppointmentPatientNotifyWaitingRequestDTO
  ): Promise<IResponseAPI | null> {
    return this.http
      .post(`${this.url}/appointment/patientNotifyWaiting`, appointmentPatientNotifyWaitingRequestDTO)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          return response;
        }
        return null;
      })
      .catch((err: any) => {
        this.aiContext.error('PatientNotifyWaiting', {
          result: false,
          requestDTO: appointmentPatientNotifyWaitingRequestDTO,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  // POST /api/v1/appointment/{appointmentId}/appointmentStatusLog/{appointmentStatus}
  createAppointmentStatusLog(appointmentId: string, appointmentStatus: AppointmentStatus): Promise<IResponseAPI> {
    if (!appointmentId || !appointmentStatus) {
      return Promise.resolve(null);
    }

    return this.http
      .post(`${this.url}/appointment/${appointmentId}/appointmentStatusLog/${appointmentStatus}`, null)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success) {
          return response;
        }
        return null;
      })
      .catch((err: any) => {
        this.aiContext.error('CreateAppointmentStatusLog', {
          result: false,
          appointmentId,
          appointmentStatus,
          error: this.functions.getErrorMessage(err)
        });
        this.functions.handleError(err);
        return null;
      });
  }

  get appointment(): Appointment {
    return this._appointment;
  }
  get availabilityParameter(): AvailabilitySearch {
    return this._availabilityParameter;
  }
  set availabilityParameter(parameter: AvailabilitySearch) {
    this._availabilityParameter = parameter;
  }
  get storedAvailabilityGroupsForDay() {
    let availabilities: any = null;

    try {
      availabilities = this.storageLocal.retrieve(Constants.LocalStorage_Key.availabilityDayGroups);
    } catch (_err: any) {}

    return availabilities;
  }

  resetAppointment(): void {
    this.stepService.resetService();
    this.resetService();
  }

  checkAndProcessAppointmentCancelledOrPatientNotAttendedOrExpired(appointment: Appointment): boolean {
    if (!appointment) {
      return true;
    }

    if (this.isAppointmentCancelled(appointment)) {
      this.appointmentErrorService.appointmentCancelled(() => {
        this.resetAppointment();
      });
      return false;
    } else if (this.isAppointmentPatientNotAttended(appointment)) {
      this.appointmentErrorService.appointmentPatientNotAttended(() => {
        this.resetAppointment();
      });
      return false;
    } else if (this.isAppointmentExpired(appointment)) {
      this.appointmentErrorService.appointmentExpired(() => {
        this.resetAppointment();
      });
      return false;
    }

    return true;
  }

  checkIfPatientEverJoinedAppointment(appointment: Appointment): boolean {
    if (!appointment) {
      return true;
    }

    if (this.isAppointmentNeverJoinedByPatient(appointment)) {
      this.appointmentErrorService.appointmentPatientNotAttended(() => {
        this.resetAppointment();
      });
      return false;
    }
    return true;
  }

  processAppointmentInvalid(): void {
    this.appointmentErrorService.appointmentInvalid(() => {
      this.resetAppointment();
    });
  }
}
