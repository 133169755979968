import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { Constants } from '@app/shared/constants';
import { ComponentDisplayType } from '@src/app/shared/models/component-display-type';
import { PatientService } from '@app/shared/services/patient.service';
import { Patient } from '@app/shared/models/patient';
import { Functions } from '@app/shared/functions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorModalService } from '@app/shared/services/error-modal.service';
import { IResponseAPI } from '@app/shared/models/api-response';
import { MonthOption } from '@app/shared/models/month-option';
import { ValidateHealthCardsRequestDTO } from '@app/shared/models/validateHealthCardsRequestDTO';
import { HealthCareCard } from '@app/shared/models/health-care-card';
import { medicareValidator } from '@app/shared/validators/medicare-validator';
import { healthcareValidator } from '@app/shared/validators/healthcare-validator';
import { ihiValidator } from '@app/shared/validators/ihi-validator';
import { safetyNetValidator } from '@app/shared/validators/safetynet-validator';
import { MedicareIrnComponent } from '@app/shared/components/medicare-irn/medicare-irn.component';
import { IhiNumberComponent } from '@app/shared/components/ihi-number/ihi-number/ihi-number.component';
import { CalendarStyles } from '@src/app/shared/models/calendarStyles';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { InputValidationFunctions } from '@app/shared/input-validation-functions';
import { WhiteLabelServiceConfiguration } from '@app/shared/models/whitelabel/WhiteLabelServiceConfiguration';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { Capacitor } from '@capacitor/core';
import moment from 'moment';

@Component({
  selector: 'healthcare-cards',
  templateUrl: './healthcare.component.html',
  styleUrls: ['./healthcare.component.scss']
})
export class HealthcareComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('displayType') displayType: string = ComponentDisplayType.page;
  @Input('isMedicareOrIHIMandatory') isMedicareOrIHIMandatory: boolean = false;
  @Input('serviceType') serviceType: string = Constants.SERVICE_TYPE.DOCTOR;

  @ViewChild('medicareExpiryMonthField') medicareExpiryMonthField: MatSelect;
  @ViewChild('healthcareExpiryMonthField') healthcareExpiryMonthField: MatSelect;
  @ViewChild('concessionExpiryMonthField') concessionExpiryMonthField: MatSelect;
  @ViewChild('safetyNetExpiryMonthField') safetyNetExpiryMonthField: MatSelect;
  @ViewChild('healthcareCardNumberField') healthcareCardNumberField: ElementRef;
  @ViewChild('concessionCardNumberField') concessionCardNumberField: ElementRef;
  @ViewChild('safetyNetCardNumberField') safetyNetCardNumberField: ElementRef;
  @ViewChild('ihiNumberField') ihiNumberField: ElementRef;
  @ViewChild('medicareCardNumberField') medicareCardNumberField: ElementRef;
  @ViewChild('medicareIRNField') medicareIRNField: ElementRef;
  @ViewChild('firstNameField') firstNameField: ElementRef;

  private subscription = new Subscription();

  dateOfBirthForm: FormGroup;
  medicareCardForm: FormGroup;
  healthcareCardForm: FormGroup;
  concessionCardForm: FormGroup;
  ihiNumberForm: FormGroup;
  safetyNetForm: FormGroup;
  months: MonthOption[] = [];
  years: string[] = [];
  healthCareCardsData: ValidateHealthCardsRequestDTO;
  patient: Patient;
  patientId: string;
  dob: string;
  patientFirstName: string;
  patientLastName: string;
  isMobile: boolean = false;
  isNarrowMobile: boolean = false;
  isModal: boolean = false;
  inlineFull: boolean = false;
  medicareAndIHIOnly: boolean = false;
  patientHasMedicare: boolean = false;
  invalidDOB: boolean = false;
  today: moment.Moment;
  dobStartYear: moment.Moment;
  displayInvalidFieldsOnly: boolean = false;
  displayDOBField: boolean = false;
  componentDisplayType = ComponentDisplayType;

  // toggles
  medicareCardToggle: boolean = false;
  healthcareCardToggle: boolean = false;
  concessionCardToggle: boolean = false;
  ihiNumberToggle: boolean = false;
  safetyNetToggle: boolean = false;

  // Loading states
  dateOfBirthFormLoading: boolean = false;
  medicareFormLoading: boolean = false;
  healthcareCardLoading: boolean = false;
  concessionCardLoading: boolean = false;
  ihiNumberLoading: boolean = false;
  safetyNetLoading: boolean = false;
  saveAllLoading: boolean = false;

  // Mobile app
  isNative: boolean = Capacitor.isNativePlatform();

  // Service configurations
  private serviceConfiguration: WhiteLabelServiceConfiguration;
  hideMedicareFields: boolean = true;

  dateStyleClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate: moment.Moment, view: string) => {
    if (this.dateOfBirth.value && moment(this.dateOfBirth.value).isValid() && cellDate?.isValid()) {
      switch (view) {
        case 'multi-year':
          return cellDate.get('year') === this.dateOfBirth.value.get('year')
            ? CalendarStyles.matCalendarBody
            : CalendarStyles.noHighlight;
        case 'year':
          return cellDate.get('month') === this.dateOfBirth.value.get('month')
            ? CalendarStyles.matCalendarBody
            : CalendarStyles.noHighlight;
        case 'month':
          return cellDate.get('date') === this.dateOfBirth.value.get('date')
            ? CalendarStyles.matCalendarBody
            : CalendarStyles.noHighlight;
        default:
          return CalendarStyles.noHighlight;
      }
    }
    return CalendarStyles.noHighlight;
  };

  constructor(
    public inputValidationFunctions: InputValidationFunctions,
    private changeDetection: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private functions: Functions,
    private dialog: MatDialog,
    private errorModal: ErrorModalService,
    private whitelabelService: WhitelabelService
  ) {
    this.onResize();

    const timezoneOffset: string = this.patientService.getPatientTimeZoneOffset();
    const startDate: moment.Moment = this.functions.getUTCMoment(timezoneOffset);

    this.today = startDate.clone();

    // Set min date for birthday datepicker - maximum 120 years old from today
    this.dobStartYear = startDate.clone().subtract(120, 'years');

    this.createForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event?: Event) {
    this.isMobile = this.functions.checkMobile();
    this.isNarrowMobile = window.innerWidth <= 378;
  }

  async ngOnInit(): Promise<void> {
    // HEALTHCARE IDENTIFIERS FORM DISPLAY CONFIGURATION
    this.isModal = this.displayType == ComponentDisplayType.modal;
    this.medicareAndIHIOnly = this.displayType == ComponentDisplayType.inlineMinimal;
    this.inlineFull = this.displayType == ComponentDisplayType.inlineFull;

    // CALENDAR
    this.setInitDateOptions();

    // WHITELABEL SERVICE CONFIGURATION
    this.serviceConfiguration = await this.whitelabelService.getServiceConfiguration(
      this.serviceType,
      this.patientService.benefit
    );
    this.serviceConfigurationUpdated();

    // PATIENT
    await this.patientUpdated();

    // WHITELABEL SERVICE CONFIGURATION OBSERVER
    this.subscription.add(
      this.whitelabelService.whitelabelConfigObs.subscribe(async () => {
        this.serviceConfiguration = await this.whitelabelService.getServiceConfiguration(
          this.serviceType,
          this.patientService.benefit
        );
        this.serviceConfigurationUpdated();
      })
    );
  }

  ngAfterViewInit(): void {
    // PATIENT CHANGE
    this.subscription.add(
      this.patientService.patientChangeObs.subscribe((patient: Patient) => {
        if (patient && !this.functions.deepCompare(patient, this.patient)) {
          if (patient.patientId !== this.patient?.patientId) {
            this.patientUpdated(true); // overwrite patient name
          } else if (patient.dateOfBirth !== this.patient?.dateOfBirth) {
            this.patientUpdated();
          }
        }
      })
    );

    // PATIENT DATA CHANGE
    this.subscription.add(
      this.patientService.patientDataChangeObs.subscribe((patient: Patient) => {
        if (patient?.patientId) {
          this.patientUpdated();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  serviceConfigurationUpdated(): void {
    const patientHasMedicare: boolean = this.hasFullMedicareDetails();
    const hasIHINumber: boolean =
      !this.functions.isNullOrEmpty(this.patient?.ihiNumber) ||
      (this.ihiNumberToggle && this.ihiNumber.value && this.ihiNumber.valid);

    this.hideMedicareFields =
      !this.serviceConfiguration?.enableMedicareCard && !patientHasMedicare && !this.isMedicareOrIHIMandatory;

    this.medicareCardToggle =
      (this.inlineFull && patientHasMedicare) ||
      (this.medicareAndIHIOnly && (patientHasMedicare || (this.isMedicareOrIHIMandatory && !hasIHINumber))) ||
      (!this.medicareAndIHIOnly && patientHasMedicare);
  }

  /**
   * @async
   * @function patientUpdated
   * @description Patient or patient data has changed, re-validate personal data and apply
   * new values to the healthcare card forms.
   *
   * @param {boolean} [forceUpdateName=false] overwrite first and last name in the medicare card form?
   */
  async patientUpdated(forceUpdateName: boolean = false): Promise<void> {
    this.patient = this.patientService.patient;
    this.patientId = this.patient?.patientId;
    this.dob = this.patient?.dateOfBirth;

    if (this.patientId && !this.patient?.sourceName) {
      await this.patientService.getPatientById(this.patientId, true).then((patient: Patient) => {
        this.patient = patient;
        this.patientId = patient?.patientId;
        this.updatePersonalDetails(forceUpdateName);
        this.serviceConfigurationUpdated();
      });
    } else {
      this.updatePersonalDetails(forceUpdateName);
      this.serviceConfigurationUpdated();
    }
  }

  updatePersonalDetails(forceUpdateName: boolean = false): void {
    // Don't perform date of birth validation when Healthcare Cards form is displayed inline (on an Appointment page)
    if (!this.medicareAndIHIOnly) {
      this.dob =
        typeof this.patient?.dateOfBirth === 'string' && this.patient.dateOfBirth.length >= 10
          ? moment(this.patient.dateOfBirth).format(Constants.YearFirstDate)
          : null;

      this.invalidDOB = !this.patientService.isDateOfBirthValid(this.dob);

      if (this.invalidDOB) {
        this.showErrorMessage(`Your date of birth is invalid or has not been set.
          You will be unable to verify and save your healthcare card details until a valid date of birth is provided.
          You may use the calendar widget or enter it manually in the following format: DD/MM/YYYY`);

        this.displayDOBField = true;
        this.dateOfBirth.setValue(null);
        this.dateOfBirth.setValidators([Validators.required]);
        this.dateOfBirth.setErrors({ dateFormat: true, required: false });
      } else {
        this.dateOfBirth.setValidators(null);
        this.dateOfBirth.setErrors(null);
      }
    }

    this.patientFirstName = (this.patient?.firstName || '').trim();
    this.patientLastName = (this.patient?.lastName || '').trim();

    this.setFormValues(forceUpdateName);
  }

  setInitDateOptions(): void {
    this.months = Constants.MONTHS;

    const currentYear: number = new Date().getFullYear();
    for (let index = 0; index < 50; index++) {
      let year: number = currentYear + index;
      this.years.push(year.toString());
    }
  }

  openIRNModal(): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Individual Reference Number',
      isClose: true
    };

    this.dialog
      .open(MedicareIrnComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.functions.triggerResizeEvent();
      });
  }

  openIHIModal(): void {
    let dialogConfig: MatDialogConfig = this.functions.getModalConfig();

    dialogConfig.data = {
      title: 'Individual Healthcare Identifier',
      isClose: true
    };
    dialogConfig.maxWidth = 984;

    this.dialog
      .open(IhiNumberComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this.functions.triggerResizeEvent();
      });
  }

  createForm(): void {
    this.dateOfBirthForm = this.formBuilder.group({
      dateOfBirth: [null]
    });

    this.medicareCardForm = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      medicareCardNumber: [null],
      medicareIRN: [null],
      medicareExpiryMonth: [null],
      medicareExpiryYear: [null]
    });

    this.healthcareCardForm = this.formBuilder.group({
      healthcareCardNumber: [null],
      healthcareExpiryMonth: [null],
      healthcareExpiryYear: [null]
    });

    this.concessionCardForm = this.formBuilder.group({
      concessionCardNumber: [null],
      concessionExpiryMonth: [null],
      concessionExpiryYear: [null]
    });

    this.safetyNetForm = this.formBuilder.group({
      safetyNetCardNumber: [null]
    });

    this.ihiNumberForm = this.formBuilder.group({
      ihiNumber: [null]
    });
  }

  setFormValues(forceUpdateName: boolean = false): void {
    const medicare: HealthCareCard = this.patient?.medicareCard;
    const healthcareCard: HealthCareCard = this.patient.healthCareCard;
    const pensionCard: HealthCareCard = this.patient.pensionCard;
    // const safetyNetCardNumber: string = this.patient.safetyNetCardNumber;
    const ihiNumber: string = this.patient?.ihiNumber;

    if (forceUpdateName || !this.firstName.value) {
      this.firstName.setValue(this.patientFirstName);
    }
    if (forceUpdateName || !this.lastName.value) {
      this.lastName.setValue(this.patientLastName);
    }

    // Medicare Card
    if (medicare && medicare.cardNumber) {
      this.medicareCardNumber.setValue(medicare.cardNumber);
      this.medicareIRN.setValue(String(medicare.medicareIRN));
      if (medicare.cardExpiry) {
        const medicareCardExpiry: string = medicare.cardExpiry.substring(0, 10);
        if (moment(medicareCardExpiry, Constants.YearFirstDate).isValid()) {
          this.medicareExpiryYear.setValue(medicareCardExpiry.split('-')[0]);
          this.medicareExpiryMonth.setValue(medicareCardExpiry.split('-')[1]);
        }
      }
    } else {
      this.medicareCardNumber.setValue(null);
      this.medicareIRN.setValue(null);
      this.medicareExpiryMonth.setValue(null);
      this.medicareExpiryYear.setValue(null);
    }

    // Healthcare Card
    if (healthcareCard && healthcareCard.cardNumber) {
      this.updateHealthcareCardToggle(true);
      this.healthcareCardNumber.setValue(healthcareCard.cardNumber);
      if (healthcareCard.cardExpiry) {
        const healthCardExpiry: string = healthcareCard.cardExpiry.substring(0, 10);
        if (moment(healthCardExpiry, Constants.YearFirstDate).isValid()) {
          this.healthcareExpiryYear.setValue(healthCardExpiry.split('-')[0]);
          this.healthcareExpiryMonth.setValue(healthCardExpiry.split('-')[1]);
        }
      }
    } else {
      this.healthcareCardNumber.setValue(null);
      this.healthcareExpiryMonth.setValue(null);
      this.healthcareExpiryYear.setValue(null);
      this.updateHealthcareCardToggle(false);
    }

    // Concession Card
    if (pensionCard && pensionCard.cardNumber) {
      this.updateConcessionCardToggle(true);
      this.concessionCardNumber.setValue(pensionCard.cardNumber);
      if (pensionCard.cardExpiry) {
        const pensionCardExpiry: string = pensionCard.cardExpiry.substring(0, 10);
        if (moment(pensionCardExpiry, Constants.YearFirstDate).isValid()) {
          this.concessionExpiryYear.setValue(pensionCardExpiry.split('-')[0]);
          this.concessionExpiryMonth.setValue(pensionCardExpiry.split('-')[1]);
        }
      }
    } else {
      this.concessionCardNumber.setValue(null);
      this.concessionExpiryMonth.setValue(null);
      this.concessionExpiryYear.setValue(null);
      this.updateConcessionCardToggle(false);
    }

    // DOC-4418 - SafetyNet is disabled
    // SafetyNet Number
    // if (safetyNetCardNumber) {
    //   this.updateSafetyNetToggle(true);
    //   this.safetyNetCardNumber.setValue(safetyNetCardNumber);
    // } else {
    //   this.safetyNetCardNumber.setValue(null);
    //   this.updateSafetyNetToggle(false);
    // }

    // IHI Number
    if (ihiNumber) {
      this.updateIHIToggle(true);
      this.ihiNumber.setValue(ihiNumber);
    } else {
      this.ihiNumber.setValue(null);
      this.updateIHIToggle(false);
    }

    if (
      this.displayType != ComponentDisplayType.inlineMinimal &&
      this.displayType != ComponentDisplayType.inlineFull &&
      !this.isNative
    ) {
      // Angular will focus on the last toggled element - return focus to top of page instead
      setTimeout(() => {
        try {
          this.firstNameField?.nativeElement?.focus();
          this.firstNameField?.nativeElement?.blur();

          let scrollingElement: HTMLElement = document.getElementById('health-card-modal-wrapper');
          if (!scrollingElement) {
            scrollingElement = document.getElementById(Constants.PAGE_ELEMENTS.mainScrollingContainer);
          }
          if (scrollingElement) {
            scrollingElement.scrollTo(0, 0);
          }
        } catch (_err: any) {}
      }, 150);
    }

    this.patientHasMedicare = this.hasFullMedicareDetails();
    this.medicareCardToggle = this.medicareCardToggle || this.patientHasMedicare;

    this.medicareCardForm.markAsPristine();
    this.healthcareCardForm.markAsPristine();
    this.concessionCardForm.markAsPristine();
    this.safetyNetForm.markAsPristine();
    this.ihiNumberForm.markAsPristine();
  }

  hasFullMedicareDetails(): boolean {
    return Boolean(
      this.patient?.medicareCard?.cardNumber &&
        this.patient?.medicareCard?.cardExpiry &&
        this.patient?.medicareCard?.medicareIRN
    );
  }

  updateMedicareCardToggle(value?: boolean): void {
    this.medicareCardToggle = value === null || typeof value === 'boolean' ? value : !this.medicareCardToggle;

    if (this.medicareCardToggle) {
      this.firstName.setValidators([Validators.required]);
      this.lastName.setValidators([Validators.required]);
      this.medicareCardNumber.setValidators([Validators.required, medicareValidator]);
      this.medicareExpiryMonth.setValidators([Validators.required]);
      this.medicareExpiryYear.setValidators([Validators.required]);
      this.medicareIRN.setValidators([Validators.required, Validators.pattern(Constants.irnRegexString)]);
    } else {
      // validators
      this.firstName.setValidators(null);
      this.lastName.setValidators(null);
      this.medicareCardNumber.setValidators(null);
      this.medicareExpiryMonth.setValidators(null);
      this.medicareExpiryYear.setValidators(null);
      this.medicareIRN.setValidators(null);

      // errors
      this.firstName.setErrors(null);
      this.lastName.setErrors(null);
      this.medicareCardNumber.setErrors(null);
      this.medicareExpiryMonth.setErrors(null);
      this.medicareExpiryYear.setErrors(null);
      this.medicareIRN.setErrors(null);
    }
    this.changeDetection.detectChanges();
    this.medicareCardForm.updateValueAndValidity();

    /*
    if (this.isMobile && this.medicareCardToggle && ((typeof value === 'boolean' && value) || typeof value === 'undefined')) {
      this.medicareCardNumberField?.nativeElement?.focus();
    }
    */
  }

  updateHealthcareCardToggle(value?: boolean): void {
    this.healthcareCardToggle = value === null || typeof value === 'boolean' ? value : !this.healthcareCardToggle;

    if (this.healthcareCardToggle) {
      this.healthcareCardNumber.setValidators([Validators.required, healthcareValidator]);
      this.healthcareExpiryMonth.setValidators([Validators.required]);
      this.healthcareExpiryYear.setValidators([Validators.required]);
    } else {
      // validators
      this.healthcareCardNumber.setValidators(null);
      this.healthcareExpiryMonth.setValidators(null);
      this.healthcareExpiryYear.setValidators(null);

      // errors
      this.healthcareCardNumber.setErrors(null);
      this.healthcareExpiryMonth.setErrors(null);
      this.healthcareExpiryYear.setErrors(null);
    }
    this.changeDetection.detectChanges();
    this.healthcareCardForm.updateValueAndValidity();

    /*
    if (this.isMobile && this.healthcareCardToggle && ((typeof value === 'boolean' && value) || typeof value === 'undefined')) {
      this.healthcareCardNumberField?.nativeElement?.focus();
    }
    */
  }

  updateConcessionCardToggle(value?: boolean): void {
    this.concessionCardToggle = value === null || typeof value === 'boolean' ? value : !this.concessionCardToggle;

    if (this.concessionCardToggle) {
      this.concessionCardNumber.setValidators([Validators.required, healthcareValidator]);
      this.concessionExpiryMonth.setValidators([Validators.required]);
      this.concessionExpiryYear.setValidators([Validators.required]);
    } else {
      this.concessionCardNumber.setValidators(null);
      this.concessionExpiryMonth.setValidators(null);
      this.concessionExpiryYear.setValidators(null);
      this.concessionCardNumber.setErrors(null);
      this.concessionExpiryMonth.setErrors(null);
      this.concessionExpiryYear.setErrors(null);
    }
    this.changeDetection.detectChanges();
    this.concessionCardForm.updateValueAndValidity();

    /*
    if (this.isMobile && this.concessionCardToggle && ((typeof value === 'boolean' && value) || typeof value === 'undefined')) {
      this.concessionCardNumberField?.nativeElement?.focus();
    }
    */
  }

  updateSafetyNetToggle(value?: boolean): void {
    this.safetyNetToggle = value === null || typeof value === 'boolean' ? value : !this.safetyNetToggle;

    if (this.safetyNetToggle) {
      this.safetyNetCardNumber.setValidators([Validators.required, safetyNetValidator]);
    } else {
      this.safetyNetCardNumber.setValidators(null);
      this.safetyNetCardNumber.setErrors(null);
    }
    this.changeDetection.detectChanges();
    this.safetyNetCardNumber.updateValueAndValidity();

    /*
    if (this.isMobile && this.safetyNetToggle && ((typeof value === 'boolean' && value) || typeof value === 'undefined')) {
      this.safetyNetCardNumberField?.nativeElement?.focus();
    }
    */
  }

  updateIHIToggle(value?: boolean): void {
    this.ihiNumberToggle = value === null || typeof value === 'boolean' ? value : !this.ihiNumberToggle;

    if (this.ihiNumberToggle) {
      this.ihiNumber.setValidators([Validators.required, ihiValidator]);
    } else {
      this.ihiNumber.setValidators(null);
      this.ihiNumber.setErrors(null);
    }
    this.changeDetection.detectChanges();
    this.ihiNumber.updateValueAndValidity();

    /*
    if (this.isMobile && this.ihiNumberToggle && ((typeof value === 'boolean' && value) || typeof value === 'undefined')) {
      this.ihiNumberField?.nativeElement?.focus();
    }
    */
  }

  getMedicareCard(): HealthCareCard {
    let medicareCard: HealthCareCard = null;

    if (
      this.medicareCardNumber.value &&
      this.medicareExpiryMonth.value &&
      this.medicareExpiryYear.value &&
      this.medicareIRN.value
    ) {
      const medicareIRNString: string = String(this.medicareIRN.value);
      let medicareIRN: number;

      try {
        medicareIRN = parseInt(medicareIRNString);
      } catch (_err) {
        medicareIRN = null;
      }

      medicareCard = {
        cardNumber: this.functions.convertToMedicareFormat(this.medicareCardNumber.value),
        cardExpiry: this.medicareExpiryYear.value + '-' + this.medicareExpiryMonth.value + '-01',
        medicareIRN
      };

      // Determine end of month for expiry day
      medicareCard.cardExpiry = moment(medicareCard.cardExpiry).endOf('month').format(Constants.YearFirstDate);
    }

    return medicareCard;
  }

  async onSaveDOB(): Promise<boolean> {
    const dob: moment.Moment = this.dateOfBirth.value ? moment(this.dateOfBirth.value) : null;

    if (dob && moment.isMoment(dob) && dob.isValid() && this.today.diff(dob, 'days') > 0) {
      this.dateOfBirth.setErrors(null);
    } else {
      this.dateOfBirth.setErrors({ dateFormat: true, required: false });
      // this.showErrorMessage('You have entered an invalid date of birth!');
      return false;
    }

    const dateOfBirth: string = dob.format(Constants.YearFirstDate);
    const patientData: Patient = { patientId: this.patientId, dateOfBirth };

    let valid: boolean = false;

    this.dateOfBirthFormLoading = true;

    await this.patientService
      .updatePatient(this.patientId, patientData)
      .then((response: IResponseAPI) => {
        valid = Boolean(response?.success);
        if (valid) {
          this.patientUpdated();
        }
      })
      .catch((err: any) => {
        valid = false;
        this.showErrorMessage(this.functions.getErrorMessage(err));
      });

    this.dateOfBirthFormLoading = false;
    this.changeDetection.detectChanges();

    return valid;
  }

  async onSaveMedicare(): Promise<void> {
    if (!this.checkDateOfBirthIsValid()) {
      return;
    }

    const medicareCard: HealthCareCard = this.getMedicareCard();

    this.medicareFormLoading = true;

    const firstName: string = (this.firstName.value || '').trim();
    const lastName: string = (this.lastName.value || '').trim();

    const isValid: boolean = await this.validateHealthCards(
      this.patientId,
      firstName,
      lastName,
      this.dob,
      medicareCard,
      null, // concession card
      null, // healthcare card
      null, // safetynet
      null // ihi number
    );

    this.medicareFormLoading = false;
    this.changeDetection.detectChanges();

    if (isValid) {
      this.functions.showToast('Medicare details saved successfully!');
      // this.patient.medicareCard = medicareCard;
      // this.patientService.setPatient(this.patient);
    }
  }

  getHealthcareCard(): HealthCareCard {
    let healthCareCard: HealthCareCard = null;

    if (this.healthcareCardNumber.value && this.healthcareExpiryMonth.value && this.healthcareExpiryYear.value) {
      healthCareCard = {
        cardNumber: this.functions.convertToHealthcareFormat(this.healthcareCardNumber.value),
        cardExpiry: this.healthcareExpiryYear.value + '-' + this.healthcareExpiryMonth.value + '-01'
      };

      // Determine end of month for expiry day
      healthCareCard.cardExpiry = moment(healthCareCard.cardExpiry).endOf('month').format(Constants.YearFirstDate);
    }

    return healthCareCard;
  }

  async onSaveHealthcare(): Promise<void> {
    if (!this.checkDateOfBirthIsValid()) {
      return;
    }

    const healthcareCard: HealthCareCard = this.getHealthcareCard();

    this.healthcareCardLoading = true;

    const isValid: boolean = await this.validateHealthCards(
      this.patientId,
      this.patientFirstName,
      this.patientLastName,
      this.dob,
      null, // medicare
      null, // concession card
      healthcareCard,
      null, // safetynet
      null // ihi number
    )
      .catch((err: any) => {
        return false;
      })
      .finally(() => {
        this.healthcareCardLoading = false;
        this.changeDetection.detectChanges();
      });

    if (isValid) {
      this.functions.showToast('Healthcare Card details saved successfully!');
      // this.patient.healthCareCard = healthcareCard;
      // this.patientService.setPatient(this.patient);
    }
  }

  getConcessionCard(): HealthCareCard {
    let concessionCard: HealthCareCard = null;

    if (this.concessionCardNumber.value && this.concessionExpiryMonth.value && this.concessionExpiryYear.value) {
      concessionCard = {
        cardNumber: this.functions.convertToHealthcareFormat(this.concessionCardNumber.value),
        cardExpiry: this.concessionExpiryYear.value + '-' + this.concessionExpiryMonth.value + '-01'
      };

      // Determine end of month for expiry day
      concessionCard.cardExpiry = moment(concessionCard.cardExpiry).endOf('month').format(Constants.YearFirstDate);
    }

    return concessionCard;
  }

  async onSaveConcession(): Promise<void> {
    if (!this.checkDateOfBirthIsValid()) {
      return;
    }

    const concessionCard: HealthCareCard = this.getConcessionCard();

    this.concessionCardLoading = true;

    const isValid: boolean = await this.validateHealthCards(
      this.patientId,
      this.patientFirstName,
      this.patientLastName,
      this.dob,
      null, // medicare card
      concessionCard,
      null, // healthcare card
      null, // safety net card number
      null // ihi number
    )
      .catch((err: any) => {
        return false;
      })
      .finally(() => {
        this.concessionCardLoading = false;
        this.changeDetection.detectChanges();
      });

    if (isValid) {
      this.functions.showToast('Concession Card details saved successfully!');
      // this.patient.pensionCard = concessionCard;
      // this.patientService.setPatient(this.patient);
    }
  }

  getSafetyNetCard(): string {
    let safetyNetCardNumber: string = null;

    if (this.safetyNetCardNumber.value) {
      safetyNetCardNumber = this.functions.convertToSafetynetFormat(this.safetyNetCardNumber.value);
    }

    return safetyNetCardNumber;
  }

  async onSaveSafetyNetCard(): Promise<void> {
    if (!this.checkDateOfBirthIsValid()) {
      return;
    }

    const safetyNetCardNumber: string = this.getSafetyNetCard();

    this.safetyNetLoading = true;

    const isValid: boolean = await this.validateHealthCards(
      this.patientId,
      this.patientFirstName,
      this.patientLastName,
      this.dob,
      null, // medicare card
      null, // concession card
      null, // healthcare card
      safetyNetCardNumber,
      null // ihi number
    )
      .catch((err: any) => {
        return false;
      })
      .finally(() => {
        this.safetyNetLoading = false;
        this.changeDetection.detectChanges();
      });

    if (isValid) {
      this.functions.showToast('Safety Net Card number was saved successfully!');
      // this.patient.safetyNetCardNumber = safetyNetCardNumber;
      // this.patientService.setPatient(this.patient);
    }
  }

  getIHICardNumber(): string {
    let ihiNumber: string = null;

    if (this.ihiNumber.value) {
      ihiNumber = this.functions.convertToIHINumberFormat(this.ihiNumber.value);
    }

    return ihiNumber;
  }

  async onSaveIHINumber(): Promise<void> {
    if (!this.checkDateOfBirthIsValid()) {
      return;
    }

    const ihiNumber: string = this.getIHICardNumber();

    this.ihiNumberLoading = true;

    const isValid: boolean = await this.validateHealthCards(
      this.patientId,
      this.patientFirstName,
      this.patientLastName,
      this.dob,
      null, // medicare card
      null, // concession card
      null, // healthcare card
      null, // safety net card number
      ihiNumber
    )
      .catch((err: any) => {
        return false;
      })
      .finally(() => {
        this.ihiNumberLoading = false;
        this.changeDetection.detectChanges();
      });

    if (isValid) {
      this.functions.showToast('IHI Number was saved successfully!');
      // this.patient.ihiNumber = ihiNumber;
      // this.patientService.setPatient(this.patient);
    }
  }

  handleKeyPress($event: any, focusTarget?: any): void {
    const target: any = focusTarget?.nativeElement ? focusTarget.nativeElement : focusTarget;
    this.functions.handleKeyPress($event, target);
  }

  validateNumber(event: any): boolean {
    const isValid: boolean = this.functions.validateNumber(event);

    if (!isValid) {
      event.preventDefault();
    }

    return isValid;
  }

  validateSafetyNetInput(event: any): boolean {
    const safetyNetCardNumber: string = (
      event?.currentTarget?.value ||
      this.safetyNetCardNumber.value ||
      ''
    ).toUpperCase();
    const isValid: boolean = this.functions.validateSafetyNetInput(event, safetyNetCardNumber);

    if (!isValid && safetyNetCardNumber?.length) {
      event.preventDefault();

      if (
        (safetyNetCardNumber.length < 4 && event.keyCode === 8) ||
        (safetyNetCardNumber.length < 3 && (event.keyCode === 46 || event.keyCode === 190))
      ) {
        this.safetyNetCardNumber.setValue(null);
      }

      return false;
    }
    return true;
  }

  async validateHealthCards(
    patientId: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    medicareCard: HealthCareCard,
    pensionCard: HealthCareCard,
    healthCareCard: HealthCareCard,
    safetyNetCardNumber: string,
    individualHealthcareNumber: string,
    saveAll: boolean = false
  ): Promise<boolean> {
    this.healthCareCardsData = {
      patientId,
      firstName,
      lastName,
      dateOfBirth,
      medicareCard,
      healthCareCard,
      pensionCard,
      safetyNetCardNumber,
      individualHealthcareNumber
    };

    let patient: Patient = {
      patientId: this.patientId,
      dateOfBirth: this.dob,
      medicareCard,
      pensionCard,
      healthCareCard,
      safetyNetCardNumber,
      ihiNumber: individualHealthcareNumber
    };

    let valid: boolean = false;

    const healthcareCardData: ValidateHealthCardsRequestDTO = saveAll
      ? this.healthCareCardsData
      : this.functions.removeEmpty(this.healthCareCardsData);

    const validateHC: IResponseAPI = await this.patientService
      .validateHealthCards(healthcareCardData, true)
      .catch((err: any) => {
        // console.log('Unable to validate healthcare card details. Error:', this.functions.getErrorMessage(err));
        this.handleHealthcareCardValidationError(err);
        this.showErrorMessage(this.functions.getErrorMessage(err), 'healthcareCards');
        // return null;
        throw err;
      })
      .finally(() => {
        this.medicareFormLoading = false;
        this.healthcareCardLoading = false;
        this.concessionCardLoading = false;
        this.safetyNetLoading = false;
        this.ihiNumberLoading = false;
      });

    if (validateHC?.success && validateHC.response) {
      valid = true;

      const patientData: Patient = saveAll ? patient : this.functions.removeEmpty(patient);

      await this.patientService
        .updatePatient(this.patientId, patientData)
        .then((response: IResponseAPI) => {
          if (response?.success) {
            if (!this.functions.deepCompare(this.patient, this.patientService.patient)) {
              this.patientUpdated();
            }
          }
        })
        .catch((err: any) => {
          valid = false;
          this.handleHealthcareCardValidationError(err);
          this.showErrorMessage(this.functions.getErrorMessage(err));
        });
    }

    return valid;
  }

  /**
   * @async
   * @function saveAll
   * @description Save all of the healthcare identifiers
   *
   * @returns {Promise<any>} returns 'true' if successful, 'false' or string error message if unsuccessful
   */
  async saveAll(): Promise<any> {
    this.updatePatientName();

    if (!this.checkDateOfBirthIsValid()) {
      return Promise.reject('Date of Birth is not valid');
    }

    this.saveAllLoading = true;

    return this.validateHealthCards(
      this.patientId,
      this.patientFirstName,
      this.patientLastName,
      this.dob,
      this.medicareCardToggle ? this.getMedicareCard() : null,
      this.concessionCardToggle ? this.getConcessionCard() : null,
      this.healthcareCardToggle ? this.getHealthcareCard() : null,
      this.safetyNetToggle ? this.getSafetyNetCard() : null,
      this.ihiNumberToggle ? this.getIHICardNumber() : null,
      true // cards set to null will be saved to the patient's profile
    ).finally(() => {
      this.saveAllLoading = false;
    });
  }

  /**
   * @async
   * @function saveMinimal
   * @description Save Medicare and IHI Number details only
   *
   * @returns {Promise<any>} returns 'true' if successful, 'false' or string error message if unsuccessful
   */
  async saveMinimal(): Promise<any> {
    this.updatePatientName();

    if (!this.checkDateOfBirthIsValid()) {
      return Promise.resolve('Date of Birth is not valid');
    }

    if (this.medicareCardToggle && !this.medicareCardForm.valid) {
      this.medicareCardForm.updateValueAndValidity();
      this.medicareCardForm.markAllAsTouched();
      return Promise.reject('Medicare card form is incomplete or invalid.');
    }

    if (this.ihiNumberToggle && !this.ihiNumberForm.valid) {
      this.ihiNumberForm.updateValueAndValidity();
      this.ihiNumberForm.markAllAsTouched();
      return Promise.reject('Individual Healthcare Identifier form is incomplete or invalid.');
    }

    this.saveAllLoading = true;

    return this.validateHealthCards(
      this.patientId,
      this.patientFirstName,
      this.patientLastName,
      this.dob,
      this.medicareCardToggle ? this.getMedicareCard() : null,
      null,
      null,
      null,
      this.ihiNumberToggle ? this.getIHICardNumber() : null,
      true // cards set to null will be saved to the patient's profile
    ).finally(() => {
      this.saveAllLoading = false;
    });
  }

  saveAllWithMessage(): Promise<any> {
    return this.saveAll()
      .catch((err: any) => {
        const errorMessage: string = this.functions.getErrorMessage(err);
        console.log('Unable to save Healthcare identifiers. Error:', errorMessage);

        if (errorMessage?.length) {
          this.functions.showToast(errorMessage);
        }
      })
      .then((response: any) => {
        if (response === true) {
          this.functions.showToast('Healthcare identifiers saved successfully!');
        } else if (typeof response === 'string' && response.length) {
          this.functions.showToast(response);
        }
      });
  }

  saveMinimalWithMessage(): Promise<any> {
    return this.saveMinimal()
      .then((response: any) => {
        if (response === true) {
          this.functions.showToast('Healthcare identifiers saved successfully!');
        } else if (typeof response === 'string' && response.length) {
          // this.functions.showToast(response);
          this.showErrorMessage(response);
        }
      })
      .catch((err: any) => {
        // Primary error messaging is done inside the healthcare carad validation function
        // This should only catch incomplete/invalid form value errors from saveMinimal()
        const errorMessage: string = this.functions.getErrorMessage(err);
        if (errorMessage?.length) {
          if (errorMessage === 'Server Error') {
            this.showErrorMessage('Unable to contact server. Our services may currently be unavailable.', 'server');
          } else {
            this.showErrorMessage(errorMessage);
          }
        }
      })
      .finally(() => {
        this.saveAllLoading = false;
      });
  }

  checkDateOfBirthIsValid(): boolean {
    const dob: moment.Moment | string = this.dateOfBirth.value ?? this.patient?.dateOfBirth;

    if (dob) {
      this.dob = moment(dob).format(Constants.YearFirstDate);

      if (!this.patientService.isDateOfBirthValid(this.dob)) {
        this.invalidDOB = true;
        this.displayDOBField = true;
        this.functions.showToast('Please provide a valid date of birth');
      } else {
        this.invalidDOB = false;
      }
    } else {
      this.invalidDOB = true;
    }

    return !this.invalidDOB;
  }

  updatePatientName(): void {
    if (this.firstName?.value?.length) {
      this.patientFirstName = this.firstName.value;
    }
    if (this.lastName?.value?.length) {
      this.patientLastName = this.lastName.value;
    }
  }

  handleHealthcareCardValidationError(res: any): void {
    const errorCode: string = this.functions.getErrorCode(res);
    // Focus on the relevant field if there is a validation issue
    if (errorCode) {
      switch (errorCode) {
        case 'MEDICARE_MULTIPLE_USE':
        case 'INVALID_MEDICARE_DETAILS_DONT_MATCH':
        case 'INVALID_MEDICARE_NUMBER':
          this.medicareCardNumberField?.nativeElement?.focus();
          break;
        case 'INVALID_MEDICARE_IRN':
          this.medicareIRNField?.nativeElement?.focus();
          break;
        case 'INVALID_MEDICARE_EXPIRY':
          this.medicareExpiryMonthField?.focus();
          break;
        case 'INVALID_HEALTHCARE_CARD_NUMBER':
          this.healthcareCardNumberField?.nativeElement?.focus();
          break;
        case 'INVALID_HEALTHCARE_CARD_EXPIRY':
          this.healthcareExpiryMonthField?.focus();
          break;
        case 'INVALID_PENSION_CARD_NUMBER':
          this.concessionCardNumberField?.nativeElement?.focus();
          break;
        case 'INVALID_PENSION_CARD_EXPIRY':
          this.concessionExpiryMonthField?.focus();
          break;
        case 'INVALID_SAFETYNET_CARD_NUMBER':
          this.safetyNetCardNumberField?.nativeElement?.focus();
          break;
        case 'INVALID_IHI_NUMBER':
          this.ihiNumberField?.nativeElement?.focus();
          break;
      }
    }
  }

  showErrorMessage(message: string, type?: string): void {
    let errorMessage: string = message;
    let errorType: string = type || 'patientProfile';

    // UR-1028 - Special case, error message override
    if (errorMessage.startsWith('Date of birth: Invalid date is not valid')) {
      errorMessage = `Invalid Date of Birth. Please review your date selection before continuing.
        If you've typed the date manually, please ensure it's in the Australian date format (DD-MM-YYYY)`;
      errorType = 'patientProfile';
    }

    let title: string = errorType === 'patientProfile' ? 'Patient Profile Not Saved' : 'Healthcare Cards Not Saved';

    this.errorModal.showErrorMessage(errorMessage, {
      title,
      isSpeakToDoctor: false,
      isClose: true
    });
  }

  get dateOfBirth() {
    return this.dateOfBirthForm.get('dateOfBirth');
  }
  get firstName() {
    return this.medicareCardForm.get('firstName');
  }
  get lastName() {
    return this.medicareCardForm.get('lastName');
  }
  get medicareCardNumber() {
    return this.medicareCardForm.get('medicareCardNumber');
  }
  get medicareIRN() {
    return this.medicareCardForm.get('medicareIRN');
  }
  get medicareExpiryMonth() {
    return this.medicareCardForm.get('medicareExpiryMonth');
  }
  get medicareExpiryYear() {
    return this.medicareCardForm.get('medicareExpiryYear');
  }
  get healthcareCardNumber() {
    return this.healthcareCardForm.get('healthcareCardNumber');
  }
  get healthcareExpiryMonth() {
    return this.healthcareCardForm.get('healthcareExpiryMonth');
  }
  get healthcareExpiryYear() {
    return this.healthcareCardForm.get('healthcareExpiryYear');
  }
  get concessionCardNumber() {
    return this.concessionCardForm.get('concessionCardNumber');
  }
  get concessionExpiryMonth() {
    return this.concessionCardForm.get('concessionExpiryMonth');
  }
  get concessionExpiryYear() {
    return this.concessionCardForm.get('concessionExpiryYear');
  }
  get safetyNetCardNumber() {
    return this.safetyNetForm.get('safetyNetCardNumber');
  }
  get safetyNetExpiryMonth() {
    return this.safetyNetForm.get('safetyNetExpiryMonth');
  }
  get safetyNetExpiryYear() {
    return this.safetyNetForm.get('safetyNetExpiryYear');
  }
  get ihiNumber() {
    return this.ihiNumberForm.get('ihiNumber');
  }
  get allFormsValid(): boolean {
    const medicareFormFilledOut: boolean = Boolean(
      this.medicareCardNumber.value &&
        this.medicareExpiryMonth.value &&
        this.medicareExpiryYear.value &&
        this.medicareIRN.value
    );

    return (
      (!this.healthcareCardToggle || this.healthcareCardForm.valid) &&
      (!this.concessionCardToggle || this.concessionCardForm.valid) &&
      // (!this.safetyNetToggle || this.safetyNetForm.valid) &&
      (!this.ihiNumberToggle || this.ihiNumberForm.valid) &&
      (!medicareFormFilledOut || this.medicareCardForm.valid)
    );
  }
}
