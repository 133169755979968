<div class="container-fluid">
  <form [formGroup]="formGroup">
    <div class="row content-section mt-4">
      <div class="col-12">
        <mat-card appearance="outlined" class="card-wrapper">
          <mat-card-content class="content">
            <div class="form-content">
              <div class="row">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-input w-100">
                    <mat-label>Email Address</mat-label>
                    <input
                      formControlName="patientEmail"
                      autocomplete="patientEmail"
                      matInput
                      maxlength="255"
                      placeholder="Enter the email address"
                      required
                    />
                    <mat-error *ngIf="patientEmail.invalid">
                      <span *ngIf="patientEmail.hasError('required')">Email Address is required.&nbsp;</span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-3 col-md-2 button-wrapper">
                  <button
                    (click)="submitEmail()"
                    [disabled]="isSubmitting"
                    class="save-btn"
                    mat-flat-button
                    color="accent"
                  >
                    <span *ngIf="!isSubmitting">Next</span>
                    <span *ngIf="isSubmitting">Validating...</span>
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>
