export const environment = {
  environmentName: 'test',
  production: false,
  useMockServer: true,
  version: '3.140.0',
  deployFolder: '/app',
  appFolder: '/app', // Used to load static assets
  baseUrl: 'https://test.doctorsondemand.com.au/app/',
  apiBaseUrl: 'https://api3test.doctorsondemand.com.au/api/',
  loginUrl: '/login',
  defaultLanguage: 'en-AU',
  supportedLanguages: ['en-AU'],
  agencyCode: 'dod',
  liveChatLicense: '10854167',
  hotjarId: 1194926,
  facebookPixelId: '6031284793320',
  analytics: {
    enabled: false,
    gtm: {
      id: 'GTM-PQH2V85',
      taggingServerURL: 'https://sstm.stage.doctorsondemand.com.au',
      googleTagManagerURL: 'https://www.googletagmanager.com',
      dataLayerName: 'dataLayer'
    },
    ga4: {
      measurementId: 'G-WE4S47ZDWK'
    }
  },
  appInsights: {
    instrumentationKey: 'f04a8a0d-316a-4fc0-8434-e8ccb32f6248',
    endpointUrl: 'https://api3test.doctorsondemand.com.au/appinsights/'
  },
  adminSite: 'https://admintest.doctorsondemand.com.au/',
  marketingSite: 'https://test.doctorsondemand.com.au/',
  pwaHostName: 'test.doctorsondemand.com.au',
  faqsAndGuidesUrl: 'https://test.doctorsondemand.com.au/wp-json/pwa/v1/faqs/user_guides?search=',
  issuesWithMedicare:
    'https://test.doctorsondemand.com.au/faqs/appointments/i-am-having-issues-completing-my-medicare-card-details-and-cannot-proceed/',
  aboutUs: 'https://test.doctorsondemand.com.au/about-us/',
  contactUs: 'https://test.doctorsondemand.com.au/contact-us/',
  medCerts: 'https://test.doctorsondemand.com.au/faqs/medical-certificates/',
  practitionerSignUp: 'https://test.doctorsondemand.com.au/practitioner/',
  pharmacySignUp: 'https://test.doctorsondemand.com.au/pharmacy/',
  corporate: 'https://test.doctorsondemand.com.au/corporate/',
  privacyPolicy: 'https://www.doctorsondemand.com.au/legal/#privacy-policy',
  termsOfService: 'https://www.doctorsondemand.com.au/legal/#terms-of-service',
  patResponsibility: 'https://www.doctorsondemand.com.au/legal/#patient-agreement',
  userGuides: 'https://test.doctorsondemand.com.au/user-guides/',
  guideAppointments: 'https://test.doctorsondemand.com.au/user-guides/appointments/',
  guideCertificates: 'https://test.doctorsondemand.com.au/user-guides/medical-certificates/',
  guidePrescriptions: 'https://test.doctorsondemand.com.au/user-guides/prescriptions/',
  guideReferrals: 'https://test.doctorsondemand.com.au/user-guides/referrals/',
  guideManageAccount: 'https://test.doctorsondemand.com.au/user-guides/managing-your-account/',
  helpWithWeightLoss: 'https://test.doctorsondemand.com.au/weight-loss-with-a-doctor/',
  medicatedWeightLoss: 'https://test.doctorsondemand.com.au/medicated-weight-loss/',
  helpQuickScript: 'https://test.doctorsondemand.com.au/repeat-prescriptions/',
  newPrescriptions: 'https://test.doctorsondemand.com.au/new-prescriptions/',
  helpQuitSmoking: 'https://test.doctorsondemand.com.au/quit-smoking/',
  pathologyReferral: 'https://test.doctorsondemand.com.au/pathology/',
  howDrApptsWork: 'https://test.doctorsondemand.com.au/online-doctors/',
  howScriptsWork: 'https://test.doctorsondemand.com.au/online-prescriptions/',
  howMedCertsWork: 'https://test.doctorsondemand.com.au/online-medical-certificates/',
  howReferralsWork: 'https://test.doctorsondemand.com.au/online-specialist-referral-letters/',
  conditionsWeTreat: 'https://test.doctorsondemand.com.au/conditions-we-treat/',
  qsMedsWePrescribe: 'https://test.doctorsondemand.com.au/quickscript-medications-we-prescribe/',
  faqs: 'https://test.doctorsondemand.com.au/faqs/',
  aboutDoD: 'https://test.doctorsondemand.com.au/faqs/about-doctors-on-demand/',
  faqAppointments: 'https://test.doctorsondemand.com.au/faqs/appointments/',
  faqMedCerts: 'https://test.doctorsondemand.com.au/faqs/medical-certificates/',
  faqPrescriptions: 'https://test.doctorsondemand.com.au/faqs/prescriptions/',
  faqPayments: 'https://test.doctorsondemand.com.au/faqs/payments/',
  faqTechnicalDiff: 'https://test.doctorsondemand.com.au/faqs/technical-difficulties/',
  faqMedications: 'https://test.doctorsondemand.com.au/faqs/medications/',
  faqSmokingCessation:
    'https://www.doctorsondemand.com.au/faqs/prescriptions/where-can-i-get-smoking-cessation-medications/',
  news: 'https://test.doctorsondemand.com.au/blog/',
  newsWhatsNew: 'https://test.doctorsondemand.com.au/blog/whats-new/',
  newsTelehealth: 'https://test.doctorsondemand.com.au/blog/telehealth-news/',
  newsHealth: 'https://test.doctorsondemand.com.au/blog/health-news/',
  helpMentalHealth: 'https://test.doctorsondemand.com.au/mental-health-care-plan-assessment/',
  storeListings: {
    iOS: 'https://apps.apple.com/au/app/doctors-on-demand-australia/id1610067140',
    Android: 'https://play.google.com/store/apps/details?id=com.doctorsondemand.app'
  },
  whitelabels: {
    Blua: {
      analytics: {
        enabled: false,
        gtm: {
          dataLayerName: '',
          id: ''
        },
        floodlight: {
          send_to: ''
        },
        facebook: {
          id: ''
        }
      }
    }
  },
  cyberSourceSecureAcceptanceScriptUrl: 'https://apitest.cybersource.com/up/v1/assets/0.15.0/SecureAcceptance.js',
  dodFunctionsModernBaseUrl: 'https://dodtest-functionsmodern.azurewebsites.net/'
};
