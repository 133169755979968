<span
  *ngIf="onDemandQueueSize !== null"
  class="badge badge-pill badge-accent"
  [title]="onDemandQueueSize + (onDemandQueueSize === 1 ? ' person' : ' people') + ' in the queue'"
  [ngClass]="{ 'badge-accent': onDemandQueueSize === 0,
    'badge-warning': onDemandQueueSize && onDemandQueueSize < 3,
    'badge-critical': onDemandQueueSize && onDemandQueueSize >= 3
    } ">
  {{ onDemandQueueSize }}
</span>
