import { Injectable, Injector } from "@angular/core";
import { environment } from "@env/environment";
import { WhitelabelAnalyticsHandler } from "./whitelabel-analytics-handler";

type WhiteLabelAnalyticsHandlerConstructor = (injector: Injector) => WhitelabelAnalyticsHandler;

@Injectable({
  providedIn: 'root'
})
export class WhitelabelAnalyticsProvider {
  private static ctorRegistrations: Record<string, WhiteLabelAnalyticsHandlerConstructor> = {};

  constructor(private injector: Injector) {}

  public GetInstance(agencyCode: string): WhitelabelAnalyticsHandler | null {
    agencyCode = agencyCode.toLowerCase();

    if (WhitelabelAnalyticsProvider.ctorRegistrations[agencyCode]) {
      return WhitelabelAnalyticsProvider.ctorRegistrations[agencyCode](this.injector);
    }

    return null;
  }

  public static Register(agencyCode: string, wlAnalyticsConstructor: WhiteLabelAnalyticsHandlerConstructor): void {
    this.ctorRegistrations[agencyCode.toLowerCase()] = wlAnalyticsConstructor;

    if (!environment.production) {
      console.log('[WhitelabelAnalyticsProvider]', 'Registered provider', agencyCode);
    }
  }
}
