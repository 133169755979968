import { Component, Inject, HostListener, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from '@app/shared/services/patient.service';
import { Functions } from '@app/shared/functions';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Constants } from '@app/shared/constants';
import { SendEmailDTO } from '@app/shared/models/sendEmailDTO';
import { IResponseAPI } from '@app/shared/models/api-response';

@Component({
  selector: 'medical-certificate-email',
  templateUrl: './medical-certificate-email.component.html',
  styleUrls: ['./medical-certificate-email.component.scss'],
})
export class MedicalCertificateEmailComponent implements OnInit {
  emailForm: FormGroup;
  isConfirm: boolean = false;
  isMobile: boolean;
  isLoading: boolean = false;
  certificateSent: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MedicalCertificateEmailComponent>,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private functions: Functions,
    private patientService: PatientService,
    private analytics: GoogleAnalyticsService
  ) {
    this.onResize();
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(Constants.emailRegexString)]],
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.isLoading && !this.isConfirm && this.email.valid) {
      const toEmail: string = this.email.value;
      const attachmentId: string = this.data.attachmentId;
      const appointmentId: string = this.data.appointmentId;

      const payload: SendEmailDTO = {
        attachmentId,
        appointmentId,
        toEmail,
      };

      try {
        this.isLoading = true;
        const response: IResponseAPI = await this.patientService
          .sendMedicalCertificateEmail(payload)
          .catch((err: any) => {
            console.log('Unable to email medical certificate. Error:', this.functions.getErrorMessage(err));
            return null;
          })
          .finally(() => {
            this.isLoading = false;
          });

        if (response?.success) {
          this.certificateSent = true;
          setTimeout(() => {
            this.ngZone.run(() => this.certificateSent = false);
          }, Constants.MILLISECONDS_IN_SECOND * 2.5);

          this.analytics.sendMedicalCertificateToEmployer(appointmentId);
          this.isConfirm = true;
        } else {
          this.functions.showToast('Failed to send email. Please contact support.');
        }
      } catch (err: any) {
        this.isLoading = false;
        console.warn('Error sending Email to Specialist: ', this.functions.getErrorMessage(err));
      }
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  get email() {
    return this.emailForm.get('email');
  }
}
