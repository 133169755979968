import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { BootstrapLogger, BootstrapLoggerMessage } from "./bootstrap-logger.service";
import { GoogleAnalyticsBlockingDetector } from "./telemetry/GoogleAnalyticsBlockingDetector";
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  public appInsights: ApplicationInsights;
  private gaBlockDetector: GoogleAnalyticsBlockingDetector;

  constructor(private versionService: VersionService) {
    this.init();
    this.appInsights = window['dod_appinsights'];
  }

  private init(): void {
    if (typeof window['dod_appinsights'] !== 'undefined') {
      return;
    }

    this.gaBlockDetector = new GoogleAnalyticsBlockingDetector();

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        // endpointUrl: environment.appInsights.endpointUrl,
        maxAjaxCallsPerView: 500,
        extensions: [this.gaBlockDetector]
      }
    });

    this.appInsights.loadAppInsights();

    this.appInsights.context.application.ver = this.versionService.getCurrentVersionString();

    if (!environment.production) {
      console.log('[AppInsights]', 'Loaded', this.appInsights.config);
    }

    window['dod_appinsights'] = this.appInsights;

    this.processBootstrapLogs();
  }

  private processBootstrapLogs(): void {
    const bootstrapLogs: Record<string, BootstrapLoggerMessage[]> = BootstrapLogger.retrieve();

    Object.keys(bootstrapLogs).forEach((k: string) => {
      const ctx: AIContext = this.createContext(k);
      const entries: BootstrapLoggerMessage[] = bootstrapLogs[k];

      entries.forEach((e: BootstrapLoggerMessage) => {
        e.properties['replayIndex'] = e.index;

        ctx.trackEvent(e.name, e.properties);
      });
    });

    BootstrapLogger.clear();
  }

  public trackEvent(name: string, properties: Record<string, any> = {}): void {
    if (!environment.production) {
      console.log('[AppInsights]', '[Event]', 'Name =', name, ' Properties =', properties);
    }

    this.appInsights.trackEvent({
      name,
      properties
    });
  }

  public createContext(name: string): AIContext {
    return new AIContext(this, name);
  }

  public onGACall(handler: ((success: boolean) => void)): void {
    if (this.gaBlockDetector) {
      this.gaBlockDetector.onGACall = handler;
    }
  }
}

export class AIContext {
  private ai: AppInsightsService;
  private prefix: string;

  constructor(ai: AppInsightsService, prefix: string) {
    this.ai = ai;
    this.prefix = prefix;
  }

  public reportSuccessStatus(
    name: string,
    success: boolean,
    reason?: string,
    properties: Record<string, any> = {}
  ): void {
    properties['success'] = success;
    properties['reason'] = reason;

    this.debug(name, properties);
  }

  public debugLog(name: string, properties: Record<string, any> = {}): void {
    properties['_level'] = 'debugLog';

    this.trackEvent(name, properties);
  }

  public debug(name: string, properties: Record<string, any> = {}): void {
    properties['_level'] = 'debug';

    this.trackEvent(name, properties);
  }

  public info(name: string, properties: Record<string, any> = {}): void {
    properties['_level'] = 'info';

    this.trackEvent(name, properties);
  }

  public warn(name: string, properties: Record<string, any> = {}): void {
    properties['_level'] = 'warn';

    this.trackEvent(name, properties);
  }

  public error(name: string, properties: Record<string, any> = {}): void {
    properties['_level'] = 'error';

    this.trackEvent(name, properties);
  }

  public critical(name: string, properties: Record<string, any> = {}): void {
    properties['_level'] = 'critical';

    this.trackEvent(name, properties);
  }

  public trackEvent(name: string, properties: Record<string, any> = {}): void {
    if (!environment.production) {
      switch (properties['_level']) {
        case 'debug':
          console.debug(`[${this.prefix}]`, '[Debug]', name, properties);
          break;
        case 'info':
          console.info(`[${this.prefix}]`, '[Info]', name, properties);
          break;
        case 'warn':
          console.warn(`[${this.prefix}]`, '[Warn]', name, properties);
          break;
        case 'error':
          console.error(`[${this.prefix}]`, '[Error]', name, properties);
          break;
        case 'critical':
          console.error(`[${this.prefix}]`, '[Critical]', name, properties);
          break;
        case 'debugLog':
          console.debug(`[${this.prefix}]`, '[Debug]', name, properties);
          return; // exit here
        default:
          console.log('[AppInsights]', '[Event]', name, properties);
          break;
      }
    }

    try {
      this.ai.appInsights.trackEvent({
        name: `${this.prefix}.${name}`,
        properties: {
          ...properties,
          context: this.prefix
        }
      });
    } catch (err: any) {
      console.warn('[AppInsights]', 'Error tracking AI event: ', err?.message);
    }
  }

  public trackPageView(url: string): void {
    if (!environment.production) {
      console.log('[AppInsights]', '[PageView]', 'Url =', url);
    }

    this.ai.appInsights.trackPageView({
      uri: url,
      properties: {
        context: this.prefix
      }
    });
  }

  public trackException(
    error: Error,
    correlatedEvent: string = null,
    correlatedProperties: Record<string, any> = {}
  ): void {
    if (!environment.production) {
      console.log('[AppInsights]', '[Exception]', 'Error =', error);
    }

    if (correlatedEvent) {
      this.error(correlatedEvent, correlatedProperties);
    }

    this.ai.appInsights.trackException({
      exception: error,
      properties: {
        context: this.prefix
      }
    });
  }
}
