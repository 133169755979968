import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpInterceptorService } from '@app/core/services/http-interceptor.service';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SpeedTestModule } from 'ng-speed-test';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Functions } from './shared/functions';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ErrorHandler, LOCALE_ID } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ExceptionHandlerService } from '@app/shared/services/exception-handler.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { BluaAnalyticsHandler } from './custom/whitelabels/blua/blua-analytics-handler';
import { Constants } from './shared/constants';
import { AnalyticsConfigResponseDTO } from './shared/models/analyticsConfigResponseDTO';
import { IResponseAPI } from './shared/models/api-response';
import { WhiteLabelConfigDTO } from './shared/models/whitelabel/WhiteLabelConfigDTO';
import { AIContext, AppInsightsService } from './shared/services/appinsights.service';
import { ModalsService } from './shared/services/modals.service';
import { MedicareValidationInterceptor } from './core/services/medicare-validation.interceptor';

function initialiseCSPViolationMonitor(aiService: AppInsightsService): void {
  const aiCtx: AIContext = aiService.createContext('CSPReporting');

  const normaliseCSPEvent: any = (event: SecurityPolicyViolationEvent) => {
    return {
      disposition: event.disposition,
      documentURI: event.documentURI,
      effectiveDirective: event.effectiveDirective,
      originalPolicy: event.originalPolicy,
      sourceFile: (event.sourceFile ?? '')?.length > 0 ? event.sourceFile : event.documentURI,
      lineNumber: event.lineNumber,
      columnNumber: event.columnNumber,
      violatedDirective: event.violatedDirective,
      blockedURI: event.blockedURI
    };
  };

  const reportCSPViolation: any = (violation: any) => {
    aiCtx.trackEvent('Violation', {
      ...violation
    });
  };

  const handleCSPEvent: any = (event: SecurityPolicyViolationEvent) => {
    const violation: any = normaliseCSPEvent(event);

    console.error(
      '[CSP Violation]',
      `${violation.sourceFile} ${violation.lineNumber}:${violation.columnNumber}`,
      'Failed to load',
      violation.blockedURI
    );

    reportCSPViolation(violation);
  };

  document.addEventListener('securitypolicyviolation', (e: SecurityPolicyViolationEvent) => handleCSPEvent(e));
}

async function initialiseDefaultLanguage(): Promise<any> {
  const lang: string = window.navigator.language;
  // if (/^en\b/.test(window.navigator.language)) {
  localStorage.setItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.defaultLanguage, lang);
  // }
}

async function initialiseWhiteLabelConfiguration(aiService: AppInsightsService): Promise<any> {
  BluaAnalyticsHandler.Register();

  const aiCtx: AIContext = aiService.createContext('WhiteLabelInit');

  let agencyCode: string = environment.agencyCode;
  let serviceType: string = null;

  const agencyPath: string = '/wl/agency/';
  const isAgencyLanding: number = window.location.href.indexOf(agencyPath);

  if (isAgencyLanding !== -1) {
    const queryStringIndex: number = window.location.href.indexOf('?');

    if (queryStringIndex !== -1) {
      agencyCode = window.location.href.substring(isAgencyLanding + agencyPath.length, queryStringIndex);

      try {
        serviceType = new URLSearchParams(window.location.search).get('service');
      } catch (err: any) {
        console.log('Failed to retrieve primary service type for agency: ', agencyCode);
      }
    } else {
      agencyCode = window.location.href.substring(isAgencyLanding + agencyPath.length);
    }

    aiCtx.debug('InitWLConfig', { agencyCode, source: 'URL', url: window.location.href });
  } else {
    try {
      const appointmentReason: string = new URLSearchParams(window.location.search)?.get('reason');

      if (appointmentReason) {
        agencyCode = null;

        // Remove any applied benefits since this is a brand new appointment session
        sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.benefit);
        sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.benefit_object);
      } else {
        agencyCode = JSON.parse(
          sessionStorage.getItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.sessionAgencyCode)
        );
        aiCtx.debug('InitWLConfig', { agencyCode, source: 'sessionStorage' });
      }
    } catch (err: any) {
      console.log('Unable to parse agency code from session storage. Error:', err);
    }
  }

  // If no agency is specified, default to DoD agency and remove any whitelabel configurations from session storage
  if (!agencyCode || agencyCode.toLowerCase() === environment.agencyCode) {
    try {
      sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.sessionAgencyCode);
      sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.agencyServiceConfiguration);
    } catch (_err: any) {}

    aiCtx.debug('ResetToDefaultWLConfig');

    return true;
  }

  // Manually instantiate an HttpClient
  let http: HttpClient = new HttpClient(
    new HttpXhrBackend({
      build: () => new XMLHttpRequest()
    })
  );

  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*'
  });

  const endpointPrefix: string = Constants.EndPoint_Prefix;
  const apiUrl: string = `${environment.apiBaseUrl}${endpointPrefix}`;

  const isValidAgencyCode: boolean = await http
    .get(`${apiUrl}/agency/${agencyCode}/validate`, { headers })
    .toPromise()
    .then((response: IResponseAPI) => {
      return Boolean(response?.success && response.response);
    })
    .catch((_err: any) => {
      console.log('Failed to validate agency with code "' + agencyCode + '"');
      return false;
    });

  if (isValidAgencyCode) {
    aiCtx.debug('ValidatedAgencyCode', { result: true, agencyCode });

    // Save Whitelabel agency code to session storage
    try {
      sessionStorage.setItem(
        Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.sessionAgencyCode,
        JSON.stringify(agencyCode)
      );
    } catch (err: any) {
      aiCtx.debug('FailedToStoreAgencyCode');
      console.log('Failed to add agency code to sessionStorage. Error =', err);
    }

    let agencyServiceConfiguration: WhiteLabelConfigDTO = null;

    try {
      const storedServiceConfig: string = sessionStorage.getItem(
        Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.agencyServiceConfiguration
      );
      if (storedServiceConfig) {
        agencyServiceConfiguration = JSON.parse(storedServiceConfig) as WhiteLabelConfigDTO;
      }
    } catch (_err: any) {
      console.log('Could not retrieve agency service configuration from sessionStorage');
    }

    // Retrieve service configuration from the API if none found in storage, or if stored
    // configuration is for a different agency
    if (!agencyServiceConfiguration || agencyServiceConfiguration.code?.toLowerCase() !== agencyCode.toLowerCase()) {
      agencyServiceConfiguration = await http
        .get(`${apiUrl}/agency/${agencyCode}/whitelabel`, { headers })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success && response.response) {
            let serviceConfiguration = response.response as WhiteLabelConfigDTO;
            if (!serviceConfiguration?.code) {
              serviceConfiguration.code = agencyCode;
            }
            if (!serviceConfiguration?.primaryAppointmentServiceType) {
              serviceConfiguration.primaryAppointmentServiceType = serviceType || Constants.SERVICE_TYPE.DOCTOR;
            }
            return serviceConfiguration;
          }
          return null;
        })
        .catch((err: any) => {
          console.log('Unable to retrieve whitelabel configuration for agency "' + agencyCode + '". Error =', err);
          return null;
        });
    }

    // Save Whitelabel service configuration to session storage
    if (agencyServiceConfiguration) {
      aiCtx.debug('GotAgencyServiceConfiguration');
      try {
        sessionStorage.setItem(
          Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.agencyServiceConfiguration,
          JSON.stringify(agencyServiceConfiguration)
        );
      } catch (_err: any) {}

      return true;
    }
  } else {
    aiCtx.warn('ValidatedAgencyCode', { result: false, agencyCode });

    try {
      // remove stored agency configuration
      sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.sessionAgencyCode);
      sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.agencyServiceConfiguration);

      // remove stored benefit
      sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.benefit);
      sessionStorage.removeItem(Constants.DefaultStoragePrefix + Constants.LocalStorage_Key.benefit_object);
    } catch (_err: any) {}
  }

  // No agency specified, use DoD default
  aiCtx.debug('NoAgencySpecified');

  return true;
}

async function loadAndInitialiseGTM(aiService: AppInsightsService) {
  const aiCtx: AIContext = aiService.createContext('GTMBootstrap');

  if (!environment.analytics.enabled) {
    aiCtx.info('AnalyticsDisabled');
    return;
  }

  const endpointPrefix: string = Constants.EndPoint_Prefix;
  const apiUrl: string = `${environment.apiBaseUrl}${endpointPrefix}`;

  let http: HttpClient = new HttpClient(
    new HttpXhrBackend({
      build: () => new XMLHttpRequest()
    })
  );

  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
    'X-Dod-CSRF-Protection': '1'
  });

  let analyticsConfig: AnalyticsConfigResponseDTO = await http
    .get(`${apiUrl}/analytics/config`, { headers })
    .toPromise()
    .then((response: IResponseAPI) => {
      if (response?.success && response.response) {
        return response.response as AnalyticsConfigResponseDTO;
      }
      return null;
    })
    .catch((err: any) => {
      aiCtx.trackException(err, 'ConfigLoadError');
      return null;
    });

  if (!analyticsConfig) {
    analyticsConfig = {
      useSSTM: false,
      iOSUseSSA: false
    };
    aiCtx.warn('ConfigurationFailed', { defaultConfig: analyticsConfig });
  }

  window['dod_gtm_config'] = analyticsConfig;

  aiCtx.debug('Init', {
    config: {
      local: environment.analytics,
      server: analyticsConfig
    }
  });

  window.dispatchEvent(new Event('dod.gtm.configured'));

  const dataLayerName: string = environment.analytics.gtm.dataLayerName;

  window[dataLayerName] = window[dataLayerName] || [];
  window[dataLayerName].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });

  const gtmLoadSuccess: boolean = await new Promise<boolean>((resolve) => {
    const scriptBaseUrl: string = analyticsConfig.useSSTM
      ? environment.analytics.gtm.taggingServerURL
      : environment.analytics.gtm.googleTagManagerURL;

    const scr: HTMLScriptElement = document.createElement('script');

    const timeout: any = setTimeout(() => {
      // If GTM hasn't loaded within 5s, we'll go ahead and assume it's not going to, and fallback to SSA
      scr.onload = null;
      scr.onerror = null;
      aiCtx.warn('LoadFailed', { reason: 'timeout', url: scr.src });
      window.dispatchEvent(new Event('dod.gtm.scriptload.fail'));
      resolve(false);
    }, 5e3);

    scr.onload = () => {
      clearTimeout(timeout);
      aiCtx.debug('Loaded', { url: scr.src });
      window.dispatchEvent(new Event('dod.gtm.scriptload.success'));
      resolve(true);
    };

    scr.onerror = () => {
      clearTimeout(timeout);
      aiCtx.warn('LoadFailed', { reason: 'error', url: scr.src });
      window.dispatchEvent(new Event('dod.gtm.scriptload.fail'));
      resolve(false);
    };

    scr.src = `${scriptBaseUrl}/gtm.js?id=${environment.analytics.gtm.id}&l=${dataLayerName}`;
    scr.async = true;

    document.body.appendChild(scr);
  });

  if (!gtmLoadSuccess) {
    aiCtx.debug('Abort', { reason: 'Load failed' });
    return;
  }

  aiCtx.info('InitialisationSuccessful');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SpeedTestModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    // PdfViewerModule,
    LiveChatWidgetModule,
    NgCircleProgressModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    // Translation service -- enable if site is translated into multiple languages
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),

    // Analytics
    // We are not using the angulartics page tracking feature
    // Angulartics2Module.forRoot(),
    // Angulartics2RouterlessModule.forRoot(),

    // Storage
    NgxWebstorageModule.forRoot({
      prefix: 'dod',
      separator: '.',
      caseSensitive: true
    }),

    // Modal Dialogs
    MatDialogModule,
    // Use mocking service and local data for API requests (if running unit tests pre-deployment)
    // environment.useMockServer
    //   ? HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
    //     passThruUnknownUrl: true,
    //   })
    //   : [],
    // PasswordStrengthMeterModule.forRoot()

    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule
  ],
  providers: [
    // Utility functions
    Functions,

    // Modal dialogs
    ModalsService,
    {
      provide: MatDialog,
      useClass: MatDialog
    },

    // Instantiate empty dialog reference and input data to allow modal dialogs across the site
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },

    // Intercept all HTTP requests and funnel through this service
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MedicareValidationInterceptor,
      multi: true
    },

    // Global JS error handler
    {
      provide: ErrorHandler,
      useClass: ExceptionHandlerService
    },

    // Material Stepper - do not use default icons
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: true,
        showError: false
      }
    },

    // Locale provider for material date picker / calendar
    // {
    //   provide: MAT_DATE_LOCALE,
    //   useValue: 'en-AU'
    // },

    // General locale provider (dates and currency)
    {
      provide: LOCALE_ID,
      useValue: 'en-AU'
    },

    // Initialise default language
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        initialiseDefaultLanguage();
      },
      multi: true
    },

    // Initialise Content Security Policy monitor
    {
      provide: APP_INITIALIZER,
      useFactory: (aiService: AppInsightsService) => () => {
        initialiseCSPViolationMonitor(aiService);
      },
      deps: [AppInsightsService],
      multi: true
    },

    // Initialise Server-Side Google Tag Manager
    {
      provide: APP_INITIALIZER,
      useFactory: (aiService: AppInsightsService) => () => {
        loadAndInitialiseGTM(aiService);
      },
      deps: [AppInsightsService],
      multi: true
    },

    // Initialise WhiteLabel configuration
    {
      provide: APP_INITIALIZER,
      useFactory: (aiService: AppInsightsService) => () => {
        initialiseWhiteLabelConfiguration(aiService);
      },
      deps: [AppInsightsService],
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: environment.deployFolder
    }

    // Moment adaptor for Material Datepicker
    // {
    //   provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    //   useValue: { useUtc: true }
    // },
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ],
    // },
    // {
    //   provide: MAT_DATE_FORMATS,
    //   useValue: MY_DATE_FORMATS
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
