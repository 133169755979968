import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Functions } from '@app/shared/functions';
import { Appointment } from '@app/shared/models/appointment';
import {
  Benefit,
  BenefitPractitioner,
  BenefitService,
  BenefitResponseDTO,
  PractitionerDiscount
} from '@app/shared/models/benefit';
import { AvailabilityService } from '@app/shared/services/availability.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { PatientService } from '@app/shared/services/patient.service';
import { ServiceTypeFunctions } from '../../service-type-functions';
import { Constants } from '../../constants';
import { WhitelabelService } from '../../services/whitelabel.service';

@Component({
  selector: 'benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitComponent implements OnInit {
  titles: any = Constants.MODAL_TITLES;
  inputForm: FormGroup;
  isCodeInvalid: boolean = false;
  isShowConfirm: boolean;
  isShowRemove: boolean;
  isMobile: boolean;
  isLoading: boolean = false;
  loadedBenefit: Benefit;
  benefitText: string;
  benefitDisclaimerText: string;
  benefitImage: string;
  customerType: string;
  doNotApplyBenefit: boolean = false;
  showBenefitCode: boolean = true;
  invalidCodeErrorMessage: string = 'This is not a valid Voucher Code / Policy Number';
  serviceType: string = null;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<BenefitComponent>,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private patientService: PatientService,
    private functions: Functions,
    private serviceTypeFunctions: ServiceTypeFunctions,
    private availabilityService: AvailabilityService,
    private analytics: GoogleAnalyticsService,
    private whiteLabelService: WhitelabelService
  ) {
    this.onResize();
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.doNotApplyBenefit = this.data?.doNotApplyBenefit;
    this.isShowRemove = true;
    this.isCodeInvalid = false;
    this.inputForm = this.formBuilder.group({
      benefit: ['', [Validators.required]]
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
  }

  ngOnInit(): void {
    const appointment: Appointment = this.data?.appointment || null;
    const benefitCode: string = this.data?.benefitCode;
    this.serviceType = this.data?.serviceType;

    if (appointment?.policyNumber) {
      this.benefit.setValue(appointment.policyNumber.toUpperCase());
      this.onSubmit();
    } else if (benefitCode === '') {
      this.benefit.setValue('');
    } else if (benefitCode) {
      this.benefit.setValue(benefitCode.toUpperCase());
      this.onSubmit();
    } else {
      this.isLoading = true;
      this.patientService
        .retrieveBenefit()
        .then((benefit: Benefit) => {
          if (benefit?.policyId) {
            this.setBenefitConfirm(benefit);
          }
        })
        .catch((err: any) => {
          console.log('Failed to retrieve benefit details from API. Error: ', this.functions.getErrorMessage(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  async onSubmit(): Promise<void> {
    let errorMessage: string = null;

    if (
      !this.loadedBenefit ||
      String(this.benefit.value).toLowerCase() === this.patientService.benefit?.name?.toLowerCase()
    ) {
      this.loadedBenefit = this.patientService.benefit;
    }

    if (!this.loadedBenefit) {
      this.isLoading = true;
      const benefitResponse: BenefitResponseDTO = await this.patientService.getBenefit(
        this.benefit.value,
        this.serviceType
      );
      this.isLoading = false;

      this.loadedBenefit = benefitResponse?.benefit;
      errorMessage = benefitResponse?.errorMessage;
    }

    if (String(this.loadedBenefit?.name).toLowerCase() === String(this.benefit.value).toLowerCase()) {
      this.setBenefitConfirm(this.loadedBenefit);
    } else {
      this.invalidCodeErrorMessage = errorMessage;
      this.setInvalidCode();
    }

    this.showBenefitCode = !this.loadedBenefit?.hidePolicyNumber;
  }

  onClose(value?: string): void {
    try {
      if (value === 'remove') {
        this.onRemove();
        this.dialogRef.close();
      } else if (value === 'invalid') {
        if (this.whiteLabelService.getWhiteLabelConfig()?.isDefault) {
          this.patientService.resetBenefit();
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(value);
      }
    } catch (err: any) {
      console.log('Failed to close benefit dialog. Value = ', value, ' Error = ', this.functions.getErrorMessage(err));
    }
  }

  onRemove(): void {
    this.isShowConfirm = false;
    this.isShowRemove = false;
    this.benefitText = null;
    this.benefitImage = null;
    this.patientService.resetBenefit();
    this.changeDetectorRef.detectChanges();
    this.benefit.setValue('');
    this.benefit.setErrors(null);
    this.benefit.markAsPristine();

    // Close the benefit dialog after removing the benefit
    this.dialogRef.close();
  }

  setBenefitConfirm(benefit: Benefit): void {
    if (benefit) {
      this.benefitImage = benefit.b2BCustomerLogoLink;
      this.customerType = benefit.customerType;
      this.showBenefitCode = !benefit.hidePolicyNumber;

      if (!this.doNotApplyBenefit) {
        this.benefit.setValue(benefit.name.toUpperCase());
      }

      const appointment: Appointment = this.data?.appointment || null;

      if (appointment && this.serviceType) {
        const serviceType: string = this.serviceType;

        let hours: number;
        if (appointment.startTime instanceof Date) {
          hours = appointment.startTime.getHours();
        } else if (appointment.startTimeUTC) {
          hours = new Date(appointment.startTimeUTC).getHours();
        }

        const isBusinessHours: boolean = this.availabilityService.isBusinessHours(hours);

        // Check practitioner is valid
        if (appointment.practitionerId && Array.isArray(benefit.validPractitioners)) {
          const validPractitioner: BenefitPractitioner = benefit.validPractitioners.find(
            (prac: BenefitPractitioner) => prac.practitionerId === appointment.practitionerId
          );

          if (validPractitioner && Array.isArray(validPractitioner.practitionerDiscountsForServices)) {
            // this.isShowRemove = true; //appointment.appointmentId ? false : true;
            const discount: PractitionerDiscount = validPractitioner.practitionerDiscountsForServices.find(
              (prac: PractitionerDiscount) => prac.serviceType === serviceType
            );

            if (discount) {
              this.benefitText = `Discount of ${
                isBusinessHours ? discount.bhDiscountPercentage : discount.ahDiscountPercentage
              }% for ${this.serviceTypeFunctions.getDisplayFriendlyServiceTypeName(serviceType)}`;

              this.setValidDiscount(benefit);
            } else {
              this.setInvalidDiscount();
            }
          } else {
            this.setInvalidDiscount();
          }

          // Check services the benefit affects
        } else if (serviceType && Array.isArray(benefit.services)) {
          const service: BenefitService = benefit.services.find(
            (bService: BenefitService) => bService.serviceType === serviceType
          );
          if (service) {
            try {
              if (
                (isBusinessHours &&
                  !service.totalDiscountPercentageBH &&
                  service.originalPriceBH !== service.discountedPriceBH) ||
                (!isBusinessHours &&
                  !service.totalDiscountPercentageAH &&
                  service.originalPriceAH !== service.discountedPriceAH)
              ) {
                this.benefitText = `Discount of $${
                  isBusinessHours
                    ? service.originalPriceBH - service.discountedPriceBH
                    : service.originalPriceAH - service.discountedPriceAH
                } for ${this.serviceTypeFunctions.getDisplayFriendlyServiceTypeName(serviceType)}`;
              } else {
                this.benefitText = `Discount of ${
                  isBusinessHours ? service.totalDiscountPercentageBH : service.totalDiscountPercentageAH
                }% for ${this.serviceTypeFunctions.getDisplayFriendlyServiceTypeName(serviceType)}`;
              }

              this.setValidDiscount(benefit);
            } catch (err: any) {
              console.log('Failed to calculate discount for benefit: ', benefit, ' from service: ', service);
              this.benefitText = 'Unable to determine discount - benefit data is invalid!';
              this.setInvalidDiscount();
            }
          } else {
            this.setInvalidDiscount();
          }
        } else {
          this.benefitText = '';
          this.setValidDiscount(benefit);
        }
      } else {
        this.benefitText = '';
        this.setValidDiscount(benefit);
      }
    } else {
      this.isShowConfirm = false;
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * @function setBenefit
   * @description If benefit has been updated, apply the new benefit in the patient service
   *
   * @param {Benefit} benefit benefit to be applied
   */
  setBenefit(benefit: Benefit) {
    const patientBenefit: Benefit = this.patientService.benefit;
    if (!patientBenefit || (patientBenefit && patientBenefit.policyId !== benefit.policyId)) {
      this.patientService.setBenefit(benefit);
    }
  }

  /**
   * @function setValidDiscount
   * @description Clear any errors and display the applied benefit/discount
   */
  setValidDiscount(benefit: Benefit) {
    this.isCodeInvalid = false;
    this.isShowConfirm = true;

    this.benefitDisclaimerText = benefit.b2BCustomerPolicyDisclaimer || null;

    if (!this.doNotApplyBenefit) {
      this.analytics.applyBenefit(benefit);
      this.isShowRemove = true;
      this.setBenefit(benefit);
    } else {
      this.isShowRemove = false;
    }

    this.changeDetectorRef.detectChanges();

    this.benefit.setErrors(null);
    this.benefit.markAsDirty();
  }

  /**
   * @function setInvalidDiscount
   * @description Benefit/discount is invalid within the provided context, display error
   */
  setInvalidDiscount(): void {
    this.analytics.applyBenefit(null, this.benefit.value);

    this.isShowConfirm = false;
    this.isShowRemove = false;
    this.isCodeInvalid = true;
    this.changeDetectorRef.detectChanges();

    this.benefit.setErrors({ invalidDiscount: true });
    this.benefit.markAsDirty();
    this.benefit.markAllAsTouched();
  }

  /**
   * @function setInvalidCode
   * @description The provided discount code is invalid, display error
   */
  setInvalidCode(): void {
    this.analytics.applyBenefit(null, this.benefit.value);

    this.isShowConfirm = false;
    this.isShowRemove = false;
    this.changeDetectorRef.detectChanges();

    this.benefit.setErrors({ invalidCode: true });
    this.benefit.markAsDirty();
    this.benefit.markAllAsTouched();
  }

  get benefit() {
    return this.inputForm.get('benefit');
  }
}
