import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  ONDEMAND_STEP_PATH_MAPPING,
  SERVICE_STEP_PATH_MAPPING,
  STEP_CONFIGURATION,
  STEP_PATH
} from '@app/shared/step-configuration';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants, Labels } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { Medication } from '@app/shared/models/medication';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { MedicationService } from '@app/shared/services/medication.service';
import { SpecialPaths, StepService } from '@app/shared/services/step.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Option } from '@app/shared/models/medication-option';
import { QuickscriptSharedDataMedication } from '@app/shared/models/quickscriptSharedData';
import {
  AssociatedMedicationDetails,
  AssociatedMedications,
  MedicationDosage
} from '@app/shared/models/associated-medications';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { AnalyticsCustomDimensionsService } from '@app/shared/services/custom-dimensions.service';
import { AvailabilityService } from '@app/shared/services/availability.service';
import { MedicationMessage } from '@app/shared/models/medication-message';
import { WhitelabelService } from '@app/shared/services/whitelabel.service';
import { QuickscriptService } from '@src/app/shared/services/quickscript.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import externalUrls from '@app/shared/config/external-urls.json';
import { QuickscriptFunctions } from '@src/app/shared/quickscript-functions';

enum Stage {
  askUser,
  userAnswerNo,
  userAnswerYes
}

export interface MessageOptions {
  blackListed?: string;
  notFound?: string;
  available?: string;
}

@Component({
  selector: 'repeat-prescription-search',
  templateUrl: './repeat-prescription.component.html',
  styleUrls: ['./repeat-prescription.component.scss']
})
export class RepeatPrescriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  // messaging
  labels = Labels;
  medicationCategoryName: string;
  messages: MedicationMessage[] = [];
  smokingCessationMedicationWarning: string = Constants.MedicationWarnings.smokingCessationMedicationWarning;
  controlledDrugWarning: string = Constants.MedicationWarnings.controlledDrugs;
  controlledDrugsProceed: string = Constants.MedicationWarnings.controlledDrugsProceed;
  messageOptions: MessageOptions = {
    blackListed: this.controlledDrugWarning + ' ' + this.controlledDrugsProceed,
    notFound: 'No medications match your search.',
    available: 'prescription is eligible for our <strong>QuickScript</strong> service.'
  };

  // state
  isValid: boolean = false;
  isNoticed: boolean = false;
  isDuplicate: boolean = false;
  isLoading: boolean = false;
  isMobile: boolean;
  isNarrowMobile: boolean;
  isOnline: boolean = false;
  onDemandQueueSize: number = 0;
  queueSize: string;
  isAddMedication: boolean = false;
  stageOption = Stage;
  stage: Stage = Stage.askUser;

  // data
  dialogData: any;

  // medications
  options: Medication[];
  filteredOptions: Observable<Medication[]>[] = [];
  _medicationData: QuickscriptSharedDataMedication;

  // form
  searchForm: FormGroup;
  numDropDownItems: number = 0;

  // config
  maxMedicationsPerSession: number = Constants.Medications_Configuration.maxMedicationsPerSession;
  enableAppointments: boolean = false;
  enableSmokingCessation: boolean = false;
  enableOnDemand: boolean = false;
  appointmentStepType: string = 'APPOINTMENT_TYPE_DOCTOR';
  appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;

  // mobile
  isNative: boolean = Boolean(Capacitor.isNativePlatform());

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<RepeatPrescriptionComponent>,
    private medicationService: MedicationService,
    private quickScriptService: QuickscriptService,
    private formBuilder: FormBuilder,
    private router: Router,
    private appointmentService: AppointmentService,
    private stepService: StepService,
    private functions: Functions,
    private analytics: GoogleAnalyticsService,
    private availabilityService: AvailabilityService,
    private whiteLabelService: WhitelabelService,
    private customDimensionsService: AnalyticsCustomDimensionsService,
    private qsFunctions: QuickscriptFunctions
  ) {
    this.onResize();

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this._init();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.isMobile = this.functions.checkMobile();
    this.isNarrowMobile = window.innerWidth < 514;
  }

  private _init(): void {
    this.dialogData = this.data;
    this.isAddMedication = this.dialogData.title === 'Add Medication' ? true : false;

    this.createForm();
    // this._checkItemChanges();
  }

  ngOnInit(): void {
    // WHITELABEL CONFIGURATION
    this.updateWhiteLabelConfiguration();
    this.subscription.add(
      this.whiteLabelService.whitelabelConfigObs.subscribe(() => {
        this.updateWhiteLabelConfiguration();
      })
    );

    this.initData();
  }

  ngAfterViewInit(): void {
    // ON-DEMAND AVAILABILITY
    this.subscription.add(
      this.availabilityService.isPractitionerOnlineChangeObs.subscribe(() => {
        this.getOnlinePractitioner();
      })
    );

    // ON-DEMAND QUEUE SIZE
    this.subscription.add(
      this.customDimensionsService.onDemandQueueSizeChangeObs.subscribe((queueSize: number) => {
        this.updateQueueSizeDisplay(queueSize);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateWhiteLabelConfiguration(serviceType?: string): void {
    this.enableAppointments = this.whiteLabelService.canProvideAppointments();
    this.appointmentServiceType = serviceType || this.whiteLabelService.getPrimaryServiceType();
    this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
    this.enableSmokingCessation = this.whiteLabelService.smokingCessationServiceEnabled();

    if (this.enableAppointments) {
      this.enableOnDemand =
        this.whiteLabelService.onDemandEnabledForServiceType(this.appointmentServiceType) &&
        ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType];

      this.getOnlinePractitioner();

      if (this.enableOnDemand) {
        this.updateQueueSizeDisplay(this.customDimensionsService?.allCustomDimensions?.onDemandAppointmentQueueSize);
      }
    }
  }

  initData(): void {
    // MEDICATION DATA
    this.setSharedData();

    // FORM VALUE CHANGES
    this._checkItemChanges();

    // GET LIST OF MEDICATIONS
    this._initMedications();
  }

  private _initMedications(): void {
    this.isLoading = true;

    this.medicationService
      .getMedicationOptions((medications: Medication[]) => {
        if (this.enableSmokingCessation) {
          this.options = medications;
        } else {
          this.options = medications?.filter((med: Medication) => med.categoryName !== Labels.smokingCessation);
        }
        this.ManageNameControl(0);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  changeStage(stage: Stage): void {
    if (stage == this.stageOption.userAnswerYes && this.dialogData.noMedSearch) {
      this.onClose('yes');
    } else {
      this.stage = stage;
    }
  }

  /**
   * @function validityCheck
   * @description Validate selected medication
   */
  validityCheck(): void {
    // this.isLoading = true;
    this.isNoticed = false;
    this.isValid = true;
    this.isDuplicate = false;

    for (let index = 0; index < this.messages?.length; index++) {
      const message: MedicationMessage = this.messages[index];

      if (message.isDuplicate) {
        this.isDuplicate = true;
        break;
      }

      if (message.isNoticed) {
        this.isNoticed = true;
      }

      if (!message.isAllowed) {
        this.isValid = false;
        break;
      }
    }

    // this.isLoading = false;
  }

  private _checkItemChanges(): void {
    this.items.valueChanges.subscribe(async (res: any) => {
      for (let index = 0; index < res?.length; index++) {
        const medication: Medication | string = res[index].name;

        // Until user selects a medication from the drop-down list, element.name is not an object, it's a string
        if (typeof medication === 'object') {
          if (medication.redirectService) {
            this.showRedirectService(medication, index);
          } else {
            if (!medication.allowed) {
              this.messages.splice(index, 1, {
                msg: this.messageOptions.blackListed,
                blacklist: true,
                isNoticed: false,
                isAllowed: false,
                isDuplicate: false
              });
            } else if (medication.allowed) {
              const medicationName: string = medication.name;
              const availableMessage: string =
                medication.categoryName == Labels.smokingCessation
                  ? this.qsFunctions.getAppointmentRequiredForMedicationCategoryMessage(Labels.smokingCessation)
                  : `Your <a class="externalLink" target="_blank"
                    href="${externalUrls.medicationInfo + medicationName}#">${medicationName}</a>&nbsp;${this.messageOptions.available}`;

              this.messages.splice(index, 1, {
                msg: availableMessage,
                blacklist: false,
                isNoticed: false,
                isAllowed: true,
                isDuplicate: false
              });
            }

            //this.validityCheck();
          }
        } else if (typeof medication === 'string') {
          if (medication.length >= Constants.Search_Configuration.medication_minSearchChars && !this.numDropDownItems) {
            this.messages.splice(index, 1, {
              msg: this.messageOptions.notFound,
              blacklist: false,
              isNoticed: false,
              isAllowed: false,
              isDuplicate: false
            });
          } else {
            this.messages.splice(index, 1, {
              msg: '',
              blacklist: false,
              isNoticed: false,
              isAllowed: false,
              isDuplicate: false
            });
          }
        }
      }

      this.validityCheck();
    });
  }

  createForm(): void {
    this.searchForm = this.formBuilder.group({
      items: this.initItems()
    });
  }

  showRedirectService(medication: Medication, index: number): void {
    this.messages.splice(index, 1, {
      msg: `An appointment is required for the selected medication.`,
      blacklist: false,
      isNoticed: false,
      isAllowed: false,
      isDuplicate: false,
      redirectServiceType: medication.redirectService
    });
  }

  initItems(): FormArray {
    let formArray: FormArray = this.formBuilder.array([]);

    formArray.push(
      this.formBuilder.group({
        name: ['']
      })
    );

    return formArray;
  }

  addItem(): void {
    const controls = <FormArray>this.searchForm.controls['items'];
    const formGroup: FormGroup = this.formBuilder.group({ name: [''] });

    controls.push(formGroup);

    this.ManageNameControl(controls.length - 1);
  }

  removeItem(i: number): void {
    const controls = <FormArray>this.searchForm.controls['items'];
    controls.removeAt(i);

    this.messages.splice(i, 1);
    this.filteredOptions.splice(i, 1);

    this.validityCheck();
  }

  /**
   * @function ManageNameControl
   * @description Standardise the format of medicine items for use in a drop-down list and
   * Filter out medicines that do not match the search criteria
   *
   * @param {number} index Index of active drop-down item
   */
  ManageNameControl(index: number): void {
    let arrayControl: FormArray = this.items;
    this.filteredOptions[index] = arrayControl
      .at(index)
      .get('name')
      .valueChanges.pipe(
        startWith<string | Medication>(''),
        map((value: any) => (typeof value === 'string' ? value : value?.name ?? '')),
        map((name: any) =>
          name?.length >= Constants.Search_Configuration.medication_minSearchChars ? this._filter(name) : []
        )
      );
  }

  onMedicationSelected($event: MatAutocompleteSelectedEvent): void {
    const medication: Medication = $event.option && $event.option.value ? $event.option.value : null;
    this.analytics.medicationSearch(medication);
    this.medicationCategoryName = medication.categoryName;

    if (this.medicationCategoryName === Labels.smokingCessation) {
      this.updateWhiteLabelConfiguration(Constants.SERVICE_TYPE.SMOKING_CESSATION);
    } else {
      this.updateWhiteLabelConfiguration();
    }

    // Modify hyperlinks for native app users to open in a new browser window
    this.modifyHyperlinks();
  }

  removeDuplicates(options: Medication[], selected: any): Medication[] {
    let selectedIds: string[] = [];
    for (const property in selected) {
      const selectedMedicationId: string = selected[property].medicationId;
      if (selectedMedicationId) {
        selectedIds.push(selectedMedicationId);
      }
    }

    let result: Medication[] = options;

    // Filter out just the selected medicine
    for (let index = 0; index < selectedIds.length; index++) {
      const sId: string = selectedIds[index];
      if (sId) {
        result = options.filter((med: Medication) => med.medicationId !== sId);
      }
    }
    return result;
  }

  setSharedData(): void {
    this.medicationData =
      this.stepService.getServiceData(STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_DETAIL.path) || null;
  }

  private _filter(value: string): Medication[] {
    const filterValue: string = (value || '').toLowerCase();

    let filteredOptions: Medication[] = [...this.options];
    let selected: any;
    let options: Medication[];

    if (
      this.dialogData.isAdditionalMedication &&
      this.medicationData &&
      Array.isArray(this.medicationData.qsMedications)
    ) {
      try {
        const medicationOnSession: MedicationDosage = this.medicationData.qsMedications[0]
          ? this.medicationData.qsMedications[0].qsMedicationDosage
          : null;

        if (medicationOnSession) {
          selected = {
            0: {
              name: {
                name: medicationOnSession.name,
                medicationId: medicationOnSession.medicationId
              }
            }
          };
        } else {
          selected = {
            0: {
              name: {
                name: '',
                medicationId: ''
              }
            }
          };
        }
      } catch (err: any) {
        console.log(
          '[REPEAT-PRESCRIPTION-COMPONENT] qsMedications could not be accessed or contains no data. ',
          this.medicationData
        );
      }
    } else {
      selected = { ...this.items.value };
    }

    filteredOptions = this.removeDuplicates(filteredOptions, selected);
    if (filteredOptions) {
      options = filteredOptions;
    } else {
      options = this.options;
    }

    const result: Medication[] = this.medicationService.filterMedicationSearchResults(filterValue, options);

    this.numDropDownItems = result?.length || 0;

    return result;
  }

  displayFn(option?: Option): string {
    if (option?.name && option?.shortDescription && option.name !== option.shortDescription) {
      return `${option.name} - ${option.shortDescription}`;
    } else if (option) {
      return option.name;
    } else {
      return undefined;
    }
  }

  getOnlinePractitioner(): void {
    this.isOnline = this.enableOnDemand && this.availabilityService.isOnline(this.appointmentServiceType);
  }

  resetAppointment(): void {
    this.stepService.resetService();
    this.appointmentService.resetService();
  }

  scheduleAppointment($event?: any, serviceType?: string): void {
    if ($event) {
      $event.stopImmediatePropagation();
    }

    this.resetAppointment();
    this.stepService.setList(serviceType || this.appointmentServiceType);

    this.onClose($event ? false : true); // a 'truthy' value will continue on with the current flow

    this.router.navigate(SERVICE_STEP_PATH_MAPPING[serviceType || this.appointmentServiceType]);
  }

  onDemandAppointment(): void {
    this.resetAppointment();
    this.onClose(true);

    this.router.navigate([STEP_PATH.APPOINTMENT, STEP_PATH[ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType]]]);
  }

  speakToDoctor(): void {
    this.getOnlinePractitioner();

    if (this.isOnline) {
      this.onDemandAppointment();
    } else {
      this.scheduleAppointment();
    }
  }

  onClose(value?: any): void {
    this.dialogRef.close(value);
  }

  updateQueueSizeDisplay(queueSize: number = 0): void {
    this.onDemandQueueSize = queueSize;
    this.queueSize =
      typeof this.onDemandQueueSize === 'number'
        ? ' (' + this.onDemandQueueSize + ' patient' + (this.onDemandQueueSize === 1 ? '' : 's') + ' in the queue)'
        : '';
  }

  resetQuickScriptService(): void {
    this.stepService.removeQuickScriptFromStorage();
    this.stepService.resetService();
    this.medicationService.resetService();
    this.stepService.setList(STEP_PATH.QUICK_SCRIPT);
  }

  async onSubmit(): Promise<void> {
    // Medication Search
    if (!this.isAddMedication) {
      // this.resetQuickScriptService();
      this.medicationService.resetService();

      if (this.items?.value?.length) {
        if (this.items.value[0].name?.name && this.items.value[0].name?.medicationId) {
          const medication: Medication = this.items.value[0].name;
          const medicationId: string = medication.medicationId;
          const medicationName: string = medication.name.toLowerCase();
          const categoryName: string = medication.categoryName;

          this.quickScriptService.saveCurrentlyTakingMedicationToStorage(medicationId);

          if (categoryName === Labels.smokingCessation) {
            this.router.navigate(SERVICE_STEP_PATH_MAPPING.smokingcessation);
          } else {
            this.router.navigate([
              STEP_PATH.QUICK_SCRIPT,
              'medication',
              this.functions.sanitiseMedicationName(medicationName)
            ]);
          }
        }
      }

      // Add Medication
    } else {
      for (const property in this.items.value) {
        try {
          const medicationId: string = this.items.value[property].name?.medicationId;
          if (medicationId) {
            await this.getQuickScriptMedication(medicationId);
          }
        } catch (_err: any) {
          console.log('Could not retrieve medication details from ', this.items.value[property]);
        }
      }
    }
    this.onClose(true);
  }

  async getQuickScriptMedication(medicationId: string): Promise<boolean> {
    const medications: AssociatedMedications = await this.medicationService.getAssociatedMedications(medicationId);
    if (medications) {
      const associatedMeds: Map<string, AssociatedMedications> = this.medicationService.addQSMedication(
        medicationId,
        medications
      );

      let isSmokingCessationMedicationItem: boolean = false;

      if (medications.medicationCategories && Object.keys(medications.medicationCategories)?.length) {
        for (const categoryId in medications.medicationCategories) {
          if (medications.medicationCategories[categoryId] === Labels.smokingCessation) {
            isSmokingCessationMedicationItem = true;
          }
        }
      }

      // Exit here if this is a Smoking Cessation product
      if (isSmokingCessationMedicationItem) {
        return false;
      }

      this.stepService.setServiceData(SpecialPaths.regularMeds, [...associatedMeds.keys()]);
      this.stepService.showQuickScriptMedication();

      if (this.medicationService.qsMedications?.size > 0) {
        this.stepService.setMedicationLabel(
          STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_1.path,
          this.medicationService.getQSMedicationAtIndex(0).medicationBrands[0]
        );
      }

      return true;
    }

    return false;
  }

  formRedirectUrl(ids: string[]): string {
    let url: string = STEP_PATH.QUICK_SCRIPT + '/medication/';
    for (let index = 0; index < ids.length; index++) {
      const medicationId: string = ids[index];
      url += medicationId;
    }
    return url;
  }

  /**
   * @function modifyHyperlinks
   * @description For mobile app only - alter the onClick event of hyperlinks found within
   * consent terms, forcing them to open in a browser window
   */
  modifyHyperlinks(): void {
    if (this.isNative) {
      setTimeout(() => {
        const hyperlinkSelector: string = '.message a.externalLink';
        document.querySelectorAll(hyperlinkSelector).forEach((anchor: HTMLAnchorElement) => {
          anchor.onclick = (e: MouseEvent) => {
            e.preventDefault();
            Browser.open({ url: anchor.href });
          };
        });
      }, 250);
    }
  }

  get medicationData(): QuickscriptSharedDataMedication {
    return this._medicationData;
  }
  set medicationData(medData: QuickscriptSharedDataMedication) {
    this._medicationData = medData;
  }
  get items() {
    return this.searchForm?.get('items') as FormArray;
  }
}
