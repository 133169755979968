import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { B2BBookingPatientInputType, B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { takeWhile } from 'rxjs';
import { B2B_STEP_PATH } from '../../../business-portal-routing.module';
import {
  B2BCustomerPortalBookingPatient,
  B2BCustomerBookingStatus
} from '@src/app/shared/models/b2b-customer-portal/models';

@Component({
  selector: 'business-portal-patient-booking-patient-select',
  templateUrl: './business-portal-patient-booking-patient-select.component.html',
  styleUrls: ['./business-portal-patient-booking-patient-select.component.scss']
})
export class BusinessPortalPatientBookingPatientSelectComponent implements OnInit {
  isSubmitting: boolean;
  patients: B2BCustomerPortalBookingPatient[];
  loading: boolean;

  B2BBookingPatientInputType = B2BBookingPatientInputType;

  constructor(
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (
      this.b2bCustomerService.currentPatientBookingStatus === B2BCustomerBookingStatus.PatientSendCode ||
      this.b2bCustomerService.currentPatientBookingStatus == B2BCustomerBookingStatus.PatientVerifyCode ||
      this.b2bCustomerService.currentPatientBookingStatus == B2BCustomerBookingStatus.PatientAdd
    ) {
      this.b2bCustomerService.setPatient(null);
    }
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientSelect);
    this.getPatientsList();
  }

  async getPatientsList() {
    this.loading = true;
    this.patients = await this.b2bCustomerService.getPatientsForEmailOrPolicy();
    this.loading = false;
  }

  selectPatient(patient: B2BCustomerPortalBookingPatient) {
    this.b2bCustomerService.setPatient(patient);
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientSendCode);
    this.router.navigate(['../', B2B_STEP_PATH.PATIENT_CODE], { relativeTo: this.activatedRoute });
  }

  patientMissing() {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientAdd);
    this.router.navigate(['../', B2B_STEP_PATH.PATIENT_ADD], { relativeTo: this.activatedRoute });
  }
}
