import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from '@app/shared/functions';
import { B2BBookingPatientInputType, B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';
import { first } from 'rxjs';
import { B2B_STEP_PATH } from '../../../business-portal-routing.module';
import { B2BCustomerBookingStatus } from '@src/app/shared/models/b2b-customer-portal/models';

@Component({
  selector: 'business-portal-patient-booking-patient-email',
  templateUrl: './business-portal-patient-booking-patient-email.component.html',
  styleUrls: ['./business-portal-patient-booking-patient-email.component.scss']
})
export class BusinessPortalPatientBookingPatientEmailComponent implements OnInit {
  formGroup: FormGroup;

  isSubmitting: boolean;

  constructor(
    private functions: Functions,
    public b2bCustomerService: B2BCustomerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.b2bCustomerService.setCurrentPatientBookingStatus(B2BCustomerBookingStatus.PatientEnterEmail);
    this.b2bCustomerService.setPatient(null);
    this.createForm();
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      patientEmail: [this.b2bCustomerService.getPatientEmail, [Validators.required]]
    });
  }

  get patientEmail() {
    return this.formGroup.get('patientEmail');
  }

  submitEmail() {
    if (!this.formGroup.valid) {
      return;
    } else {
      this.formGroup.setErrors(null);
      this.formGroup.markAsPristine();
    }

    const patientEmail: string = this.patientEmail.value;

    this.b2bCustomerService.b2bCustomerPortalInfo.pipe(first()).subscribe(async (info) => {
      if (info) {
        this.isSubmitting = true;
        const valid = await this.b2bCustomerService.validatePatientEmail(patientEmail);
        this.isSubmitting = false;
        this.b2bCustomerService.setPatientManuallyTypedEmail(patientEmail);

        this.b2bCustomerService.setPatientSource(B2BBookingPatientInputType.email);

        if (valid) {
          this.b2bCustomerService.setPatientManuallyTypedEmail(patientEmail);
          this.router.navigate(['../', B2B_STEP_PATH.PATIENT_SELECT], { relativeTo: this.activatedRoute });
        } else {
          this.router.navigate(['../', B2B_STEP_PATH.PATIENT_ADD], { relativeTo: this.activatedRoute });
        }
      } else {
        console.error('ERROR in submitEmail');
      }
    });
  }
}
