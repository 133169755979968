<form [formGroup]="weightLossCheckInForm" (submit)="$event.preventDefault()">
  <div id="cancellation-modal-wrapper" class="modal-wrapper">
    <div class="modal-header container-fluid align-items-center">
      <div class="col-10 pl-0">
        <div class="subtitle">Weight loss check in</div>
        <div class="text-primary title">
          <span *ngIf="isGoalEdit && !checkInComplete">Update your goals</span>
          <span *ngIf="!isGoalEdit && !checkInComplete">How are you going this week?</span>
          <span *ngIf="!isGoalEdit && checkInComplete">Thanks for checking in</span>
          <span *ngIf="isGoalEdit && checkInComplete">Your goals have been updated</span>
        </div>
      </div>
      <div class="col-2 pr-0">
        <button type="button" class="close" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div *ngIf="!checkInComplete" class="modal-body container-fluid text-secondary">
      <div *ngIf="isLoading" class="my-5">
        <loading-spinner></loading-spinner>
      </div>
      <div [class.d-none]="isLoading" class="grey-box-question-wrapper">
        <p class="col-12 px-0">
          <span *ngIf="isGoalEdit">Please provide your goal weight and waist measurements.</span>
          <small *ngIf="isGoalEdit">
            <strong>Disclaimer: </strong>
            We want to help you be the healthiest version of yourself. If you would like to update your goals, consider
            speaking with your doctor to establish a healthy and sustainable target.
          </small>
          <span *ngIf="!isGoalEdit">Please provide your current weight and waist measurements.</span>
        </p>

        <div *ngIf="!isGoalEdit" class="list-item has-validators">
          <div class="row list-item-question">
            <div class="col-sm-12 col-xl-6 list-item-question-container">
              <div class="label mb-2 mb-xl-4">What is your weight this week?</div>
            </div>
            <div class="col-sm-12 col-xl-6 my-auto custom-col">
              <mat-form-field
                class="center default-input"
                appearance="outline"
                [class.extra-space-for-errors]="
                  currentWeight && currentWeight.invalid && (currentWeight.dirty || currentWeight.touched)
                "
              >
                <input
                  formControlName="currentWeight"
                  name="currentWeight"
                  autocomplete="off"
                  type="number"
                  matInput
                  (keydown)="validateNumber($event)"
                />
                <mat-error
                  *ngIf="
                    currentWeight.invalid && currentWeight.errors && (currentWeight.dirty || currentWeight.touched)
                  "
                >
                  <span *ngIf="currentWeight.errors.required"> This field is required </span>
                  <span *ngIf="currentWeight.errors.min && !currentWeight.errors.invalid">
                    Invalid weight - value too low
                  </span>
                  <span *ngIf="currentWeight.errors.max && !currentWeight.errors.invalid">
                    Invalid weight - value too high
                  </span>
                </mat-error>
              </mat-form-field>
              <span class="end-text">kg&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>

        <div *ngIf="isGoalEdit" class="list-item has-validators">
          <div class="row list-item-question">
            <div class="col-sm-12 col-xl-6 list-item-question-container">
              <div class="label mb-2 mb-xl-4">What is your goal weight?</div>
            </div>
            <div class="col-sm-12 col-xl-6 my-auto custom-col">
              <mat-form-field
                class="center default-input"
                appearance="outline"
                [class.extra-space-for-errors]="
                  goalWeight && goalWeight.invalid && (goalWeight.dirty || goalWeight.touched)
                "
              >
                <input
                  formControlName="goalWeight"
                  name="goalWeight"
                  autocomplete="off"
                  type="number"
                  matInput
                  (keydown)="validateNumber($event)"
                />
                <mat-error *ngIf="goalWeight.invalid && goalWeight.errors && (goalWeight.dirty || goalWeight.touched)">
                  <span *ngIf="goalWeight.errors.required"> This field is required </span>
                  <span *ngIf="goalWeight.errors.min && !goalWeight.errors.invalid">
                    Invalid weight - value too low
                  </span>
                  <span *ngIf="goalWeight.errors.max && !goalWeight.errors.invalid">
                    Invalid weight - value too high
                  </span>
                </mat-error>
              </mat-form-field>
              <span class="end-text">kg&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>

        <div *ngIf="!isGoalEdit" class="list-item has-validators">
          <div class="row list-item-question">
            <div class="col-sm-12 col-xl-6 list-item-question-container">
              <div class="label mb-2 mb-xl-4">What is your waist measurement this week?</div>
            </div>
            <div class="col-sm-12 col-xl-6 my-auto custom-col">
              <mat-form-field
                class="center default-input"
                appearance="outline"
                [class.extra-space-for-errors]="
                  currentWaist && currentWaist.invalid && (currentWaist.dirty || currentWaist.touched)
                "
              >
                <input
                  formControlName="currentWaist"
                  name="currentWaist"
                  autocomplete="off"
                  type="number"
                  matInput
                  (keydown)="validateNumber($event)"
                />
                <mat-error
                  *ngIf="currentWaist.invalid && currentWaist.errors && (currentWaist.dirty || currentWaist.touched)"
                >
                  <span *ngIf="currentWaist.errors.required"> This field is required </span>
                  <span *ngIf="currentWaist.errors.min && !currentWaist.errors.invalid">
                    Invalid waist measurement - value too low
                  </span>
                  <span *ngIf="currentWaist.errors.max && !currentWaist.errors.invalid">
                    Invalid waist measurement - value too high
                  </span>
                </mat-error>
              </mat-form-field>
              <span class="end-text">cm&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>

        <div *ngIf="isGoalEdit" class="list-item has-validators">
          <div class="row list-item-question">
            <div class="col-sm-12 col-xl-6 list-item-question-container">
              <div class="label mb-2 mb-xl-4">What is your goal waist measurement?</div>
            </div>
            <div class="col-sm-12 col-xl-6 my-auto custom-col">
              <mat-form-field
                class="center default-input"
                appearance="outline"
                [class.extra-space-for-errors]="
                  goalWaist && goalWaist.invalid && (goalWaist.dirty || goalWaist.touched)
                "
              >
                <input
                  formControlName="goalWaist"
                  name="goalWaist"
                  autocomplete="off"
                  type="number"
                  matInput
                  (keydown)="validateNumber($event)"
                />
                <mat-error *ngIf="goalWaist.invalid && goalWaist.errors && (goalWaist.dirty || goalWaist.touched)">
                  <span *ngIf="goalWaist.errors.required"> This field is required </span>
                  <span *ngIf="goalWaist.errors.min && !goalWaist.errors.invalid">
                    Invalid waist measurement - value too low
                  </span>
                  <span *ngIf="goalWaist.errors.max && !goalWaist.errors.invalid">
                    Invalid waist measurement - value too high
                  </span>
                </mat-error>
              </mat-form-field>
              <span class="end-text">cm&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkInComplete" class="modal-body-confirmation container-fluid text-secondary">
      <p>Here is your goal progress!</p>
      <div class="row d-flex extra-margin">
        <div class="col-12 content-title">Your BMI<br /></div>
        <weight-loss-bmi-progress class="d-flex" [bmiCalculation]="bmiCalculation"></weight-loss-bmi-progress>
      </div>

      <weight-loss-goal-detail
        [cssClass]="bmiCalculation?.cssClass"
        [currentMeasurement]="weightLossData?.currentWeight"
        [goalMeasurement]="weightLossData?.goalWeight"
        [goalLabel]="weightGoalLabel"
        [goalReached]="weightGoalReached"
        [measurementLabel]="'weight'"
        [measurementUnits]="'kg'"
      >
      </weight-loss-goal-detail>

      <weight-loss-goal-detail
        [cssClass]="currentWaistCssClass"
        [currentMeasurement]="weightLossData?.currentWaist"
        [goalMeasurement]="weightLossData?.goalWaist"
        [goalLabel]="waistGoalLabel"
        [goalReached]="waistGoalReached"
        [measurementLabel]="'waist size'"
        [measurementUnits]="'cm'"
      >
      </weight-loss-goal-detail>
    </div>

    <div *ngIf="!checkInComplete" class="d-none d-xl-flex modal-footer button-wrapper container-fluid bg-muted">
      <div class="first-button col-xl-6 col-xs-12 mx-0">
        <button mat-stroked-button type="button" color="accent" class="btn-block" (click)="onClose()">Cancel</button>
      </div>
      <div class="second-button col-xl-6 col-xs-12 mx-0">
        <button mat-flat-button type="button" color="accent" class="btn-block" (click)="submitCheckIn()">Submit</button>
      </div>
    </div>
    <div *ngIf="!checkInComplete" class="d-flex d-xl-none modal-footer button-wrapper container-fluid bg-muted">
      <div class="first-button col-xl-6 col-xs-12 mx-0">
        <button mat-flat-button type="button" color="accent" class="btn-block" (click)="submitCheckIn()">Submit</button>
      </div>
      <div class="second-button col-xl-6 col-xs-12 mx-0">
        <button mat-stroked-button type="button" color="accent" class="btn-block" (click)="onClose()">Cancel</button>
      </div>
    </div>
    <div *ngIf="checkInComplete" class="modal-footer button-wrapper single-button container-fluid bg-muted">
      <div class="second-button col-12 col-xl-6 mx-0">
        <button mat-stroked-button type="button" color="accent" class="btn-block" (click)="goToDashboard()">
          View Dashboard
        </button>
      </div>
    </div>
  </div>
</form>
