import { ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MedicationService } from '@app/shared/services/medication.service';
import { QuickScriptServiceType } from '@app/shared/models/quickscriptServiceType';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressService } from '@app/shared/services/address.service';
import { StepService } from '@app/shared/services/step.service';
import { STEP_CONFIGURATION } from '@app/shared/step-configuration';
import { Constants } from '@app/shared/constants';
import { PatientService } from '@app/shared/services/patient.service';
import { Functions } from '@app/shared/functions';
import { ReceivePrescriptionType } from '@app/shared/models/prescription-type';
import { Pharmacy } from '@app/shared/models/pharmacy';
import { HealthCareCard } from '@app/shared/models/health-care-card';
import { IResponseAPI } from '@app/shared/models/api-response';
import { Address } from '@app/shared/models/address';
import { ValidateHealthCardsRequestDTO } from '@app/shared/models/validateHealthCardsRequestDTO';
import {
  QuickscriptSharedDataMedication,
  QuickScriptSharedDataPharmacy
} from '@app/shared/models/quickscriptSharedData';
import { Patient } from '@app/shared/models/patient';
import { ModalsService } from '@app/shared/services/modals.service';
import { Subscription } from 'rxjs';
import { PharmacyStepData } from '@src/app/shared/models/appointmentSharedData';
import { environment } from '@env/environment';

@Component({
  selector: 'change-address-pharmacy',
  templateUrl: './change-address-pharmacy.component.html',
  styleUrls: ['./change-address-pharmacy.component.scss']
})
export class ChangeAddressPharmacyComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  marketingURL: any = environment;
  modalType: string;
  deliveryMethod = QuickScriptServiceType;
  deliveryMethodSelected: QuickScriptServiceType;
  etp2Selected: boolean;
  sendPharmacySelected: boolean;
  pharmacies: Pharmacy[] = [];
  addressId: string;
  medicationDetailsData: QuickscriptSharedDataMedication;
  selectedPharmacy: Pharmacy;
  selectedAddress: Address;
  isMobilePhoneValid: boolean = false;
  isHealthCardsValid: boolean = false;
  isLoading: boolean = false;
  quickscriptForm: FormGroup;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ChangeAddressPharmacyComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private addressService: AddressService,
    private medicationService: MedicationService,
    private stepService: StepService,
    private patientService: PatientService,
    private functions: Functions,
    private modalService: ModalsService
  ) {
    this._init();
  }

  private _init() {
    this.medicationDetailsData = this.stepService.getServiceData(
      STEP_CONFIGURATION.QUICK_SCRIPT.MEDICATION_DETAIL.path
    );

    if (this.medicationDetailsData) {
      this.deliveryMethodSelected = this.medicationDetailsData.qsDetailMedicationDelivery;
    }
  }

  ngOnInit(): void {
    if (this.data?.type?.length) {
      this.modalType =
        this.data.type === 'address'
          ? Constants.MODAL_TITLES.changeAddress
          : this.data.type === 'pharmacy'
            ? Constants.MODAL_TITLES.changePharmacy
            : 'Change ' + this.data.type;
    }
    this.createForm();
    this.checkPrescription();
    this.setSelectedPharmacy();
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.patientService.patientChangeObs.subscribe((patient: Patient) => {
        this.checkPatientData(patient).then(() => {
          this.changeDetectorRef.detectChanges();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm(): void {
    this.quickscriptForm = new FormGroup({
      receivePrescription: new FormControl(''),
      address: new FormControl<Address>(null),
      pharmacy: new FormControl<Pharmacy>(null),
      deliveryInstruction: new FormControl('')
    });
  }

  async onSubmit(): Promise<any> {
    const spReceivePrescriptionMethod: string = this.receivePrescription.value;
    const spAddress: Address = this.address.value;
    const spPharmacy: Pharmacy = this.pharmacy.value;
    const spDeliveryInstruction: string = this.deliveryInstruction.value;

    let spAddressId: string = null;

    if (
      spAddress &&
      typeof spAddress === 'object' &&
      this.deliveryMethodSelected == QuickScriptServiceType.quickscriptdeliver
    ) {
      const addressId: string = await this.createAddress(spAddress);

      if (addressId) {
        this.addressId = addressId;
        this.address.value.addressId = addressId;
        spAddressId = addressId;
      } else {
        // Let the user continue even if an addressId could not be created
        // return;
      }
    }

    const pharmacyData: PharmacyStepData = {
      spReceivePrescriptionMethod,
      spAddressId,
      spAddress,
      spPharmacy,
      spDeliveryInstruction
    };

    // const healthcareStepData: QuickScriptSharedDataPharmacy =
    //   this.stepService.getServiceData(STEP_CONFIGURATION.QUICK_SCRIPT.HEALTHCARE_CARD_DETAILS.path) || {};

    this.stepService.setServiceData(
      STEP_CONFIGURATION.QUICK_SCRIPT.HEALTHCARE_CARD_DETAILS.path,
      { pharmacyData },
      false,
      true
    );

    if (spReceivePrescriptionMethod == QuickScriptServiceType.quickscriptdeliver) {
      this.stepService.completeStep(STEP_CONFIGURATION.QUICK_SCRIPT.CONFIRM_ADDRESS.path, pharmacyData, true);
    }

    this.onClose();
  }

  async createAddress(address: Address): Promise<string> {
    try {
      const addressId: string = await this.addressService.createAddress(this.functions.removeEmpty(address));
      if (addressId) {
        return addressId;
      } else {
        this.functions.showToast('Unable to create address!');
        return null;
      }
    } catch (err: any) {
      let errorMessage: string = this.functions.getErrorMessage(err);
      if (errorMessage === 'The AdministrativeAreaLevel2 field is required.') {
        errorMessage = Constants.ErrorMessages.NO_POSTAL_DROPOFF;
      }
      this.functions.showToast(errorMessage);
      return null;
    }
  }

  async checkPatientData(currentPatient?: Patient): Promise<any> {
    const patient: Patient = currentPatient ?? (await this.patientService.setDefaultPatient());

    if (patient) {
      const patientId: string = patient.patientId;
      const dateOfBirth: string = this.functions.formatDateYearFirst(patient.dateOfBirth);
      const patientFirstName: string = patient.firstName.trim();
      const patientLastName: string = patient.lastName.trim();
      const medicareCard: HealthCareCard = patient.medicareCard || null;
      const ihiNumber: string = patient.ihiNumber || null;
      const phoneNumber: string = patient.mobilePhone;

      this.validatePhoneNumber(phoneNumber);

      if ((medicareCard && medicareCard.cardNumber) || ihiNumber) {
        this.isHealthCardsValid = await this.validateHealthCards(
          patientId,
          patientFirstName,
          patientLastName,
          dateOfBirth,
          medicareCard,
          null,
          null,
          null,
          ihiNumber
        );
      } else {
        this.isHealthCardsValid = false;
      }
    }
  }

  async validateHealthCards(
    patientId: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    medicareCard: HealthCareCard,
    pensionCard: HealthCareCard,
    healthCareCard: HealthCareCard,
    safetyNetCardNumber: string,
    individualHealthcareNumber: string
  ): Promise<boolean> {
    const healthCareCardsData: ValidateHealthCardsRequestDTO = {
      patientId,
      firstName,
      lastName,
      dateOfBirth,
      medicareCard,
      healthCareCard,
      pensionCard,
      safetyNetCardNumber,
      individualHealthcareNumber
    };

    let isValid: boolean = false;
    const healthCareCards: ValidateHealthCardsRequestDTO = this.functions.removeEmpty(healthCareCardsData);

    const response: IResponseAPI = await this.patientService
      .validateHealthCards(healthCareCards, true)
      .catch((err: any) => {
        console.warn(this.functions.getErrorMessage(err));
        return null;
      });

    if (response) {
      isValid = Boolean(response.success && response.response);
    }

    return isValid;
  }

  getDistanceText(km?: number): string {
    return this.functions.getDistanceText(km);
  }

  validatePhoneNumber(phoneNumber: string): void {
    this.isMobilePhoneValid = Constants.mobilePhoneRegex.test(phoneNumber);
    // this.isMobilePhoneValid = /^[4]{1}[0-9]{8}$/.test(phoneNumber);
  }

  checkPrescription(): void {
    this.receivePrescription.valueChanges.subscribe((value: ReceivePrescriptionType) => {
      if (value == ReceivePrescriptionType.eScript) {
        this.etp2Selected = true;
        this.sendPharmacySelected = false;
        this.deliveryMethodSelected = QuickScriptServiceType.quickscriptpharm;
        this.address.setValidators(null);
        this.pharmacy.setValidators(null);
        this.address.setErrors(null);
        this.pharmacy.setErrors(null);
      } else if (value == ReceivePrescriptionType.sendScriptToPharmacy) {
        this.etp2Selected = false;
        this.sendPharmacySelected = true;
        this.deliveryMethodSelected = QuickScriptServiceType.quickscriptpharm;
        this.address.setValidators([Validators.required]);
        this.pharmacy.setValidators([Validators.required]);
      } else if (value == null) {
        this.etp2Selected = false;
        this.sendPharmacySelected = false;
        this.deliveryMethodSelected = QuickScriptServiceType.quickscriptdeliver;
        this.address.setValidators([Validators.required]);
        this.pharmacy.setValidators(null);
      }
      this.quickscriptForm.updateValueAndValidity();
    });
  }

  openHealthcareModal(): void {
    this.modalService.healthCareCards();
  }

  async setSelectedPharmacy(): Promise<any> {
    const stepData: QuickScriptSharedDataPharmacy =
      this.stepService.getServiceData(STEP_CONFIGURATION.QUICK_SCRIPT.HEALTHCARE_CARD_DETAILS.path) || {};

    if (!stepData?.pharmacyData) {
      return false;
    }

    const { spReceivePrescriptionMethod, spAddress, spAddressId, spPharmacy, spDeliveryInstruction } =
      stepData.pharmacyData;

    await this.checkPatientData();

    this.addressId = spAddressId;

    if (spPharmacy) {
      this.selectedPharmacy = spPharmacy;

      // If stored pharmacy is not in the pharmacies list, add it at the end
      if (!this.pharmacies.find((pharm: Pharmacy) => pharm.pharmacyId === this.selectedPharmacy?.pharmacyId)) {
        this.pharmacies.unshift(this.selectedPharmacy);
      }
    } else if (this.patientService.patient?.pharmacyId) {
      const selectedPharmacy: Pharmacy = this.pharmacies.find(
        (pharm: Pharmacy) => pharm.pharmacyId === this.patientService.patient.pharmacyId
      );
      if (selectedPharmacy) {
        this.selectedPharmacy = selectedPharmacy;
      }
    }

    if (spAddress) {
      this.selectedAddress = spAddress;
    } else if (spAddressId || this.patientService.patient?.addressId) {
      this.selectedAddress = await this.addressService.getAddressById(
        spAddressId || this.patientService.patient.addressId
      );
    }

    const receivePrescription: string =
      this.deliveryMethodSelected === QuickScriptServiceType.quickscriptdeliver
      ? null
      : spReceivePrescriptionMethod || ReceivePrescriptionType.sendScriptToPharmacy;

    this.quickscriptForm.patchValue({
      receivePrescription,
      // address: this.selectedAddress || null,
      pharmacy: this.selectedPharmacy || null,
      deliveryInstruction: spDeliveryInstruction || null
    });

    if (this.selectedAddress) {
      await this.addressUpdate({ address: this.selectedAddress, isError: false });
    }
  }

  async addressUpdate(data: any): Promise<void> {
    if (data && data.address) {
      const originalAddress: string = data.address.originalAddress;

      // Seny - set to object, not string
      if (!this.functions.deepCompare(data.address, this.address.value)) {
        this.address.setValue(data.address);
      }

      if (originalAddress) {
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();

        const pharmacies: Pharmacy[] = await this.medicationService.findPharmacy(originalAddress).catch((err) => {
          console.warn('Failed to fetch pharmacies! Error: ', this.functions.getErrorMessage(err));
          return null;
        });

        this.pharmacies = pharmacies ?? [];
      }

      this.isLoading = false;

      if (this.pharmacies?.length) {
        this.pharmacies.sort((a: Pharmacy, b: Pharmacy) => {
          if (typeof a.distance === 'number' && typeof b.distance === 'number' && a.distance !== b.distance) {
            return a.distance - b.distance; // closest pharmacy first
          } else {
            return a.name > b.name ? 1 : -1; // alphanumeric (asc)
          }
        });

        if (!this.selectedPharmacy && this.patientService.patient?.pharmacyId) {
          const selectedPharmacy: Pharmacy = this.pharmacies.find(
            (pharm: Pharmacy) => pharm.pharmacyId === this.patientService.patient.pharmacyId
          );
          if (selectedPharmacy) {
            this.selectedPharmacy = selectedPharmacy;
          }
        }
      }

      if (this.selectedPharmacy) {
        // Seny - if user changes the address, the distance of the previously selected pharmacy is no longer valid
        if (this.pharmacies.find((pharm: Pharmacy) => pharm.pharmacyId === this.selectedPharmacy.pharmacyId)) {
          this.pharmacy.setValue(this.selectedPharmacy);
        } else {
          // this.pharmacies.unshift(this.selectedPharmacy);
          this.selectedPharmacy = null;
          this.pharmacy.setValue(null);
        }
      } else {
        this.pharmacy.setValue(null);
      }

      this.changeDetectorRef.detectChanges();

      this.pharmacy.setErrors(null);
      this.pharmacy.markAsPristine({ onlySelf: true });
    } else {
      this.address.setValue(null);
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.pharmacyId === c2.pharmacyId : c1 === c2;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  get receivePrescription() {
    return this.quickscriptForm.get('receivePrescription');
  }
  get address() {
    return this.quickscriptForm.get('address');
  }
  get pharmacy() {
    return this.quickscriptForm.get('pharmacy');
  }
  get deliveryInstruction() {
    return this.quickscriptForm.get('deliveryInstruction');
  }
}
