<div id="verify-email-wrapper" class="verify-patient-method-wrapper grey-box-question-wrapper text-center">
  <div class="card mt-4">
    <div class="floating-banner-icon-wrapper">
      <div class="floating-banner-icon card">
        <div class="floating-banner-icon-inner">
          <img src="assets/icons/ico-email.svg" matSuffix alt="Email icon" />
        </div>
      </div>
    </div>

    <div class="row header-section header offset-for-header-icon">
      <div class="col-12">
        <span class="title d-inline">One-time Email Verification</span>
        <span class="desc">
          We require you to verify the account's email address number to proceed. We have sent an email to your email
          address. Please enter the received six-digit PIN below to complete the verification process.
        </span>
      </div>
    </div>

    <hr class="m-0" />

    <div class="mt-4 mx-auto code-input-wrapper" [class.d-none]="!codeSent || saveSuccessful">
      <code-input
        [isCodeHidden]="false"
        [isCharsCode]="false"
        [codeLength]="Constants.VERIFICATION_INPUT_CHAR_LENGTH"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)">
      </code-input>
    </div>

    <div *ngIf="isLoading && !saveSuccessful" class="my-5">
      <loading-spinner [loadingText]="'Sending...'"></loading-spinner>
    </div>

    <ng-container *ngIf="(codeSent && !saveSuccessful) || error">
      <p class="mt-5">
        <span *ngIf="!error && !invalidInput"
          >If you do not receive an email shortly, use the below button to send another email to your email
          account.</span
        >
        <span *ngIf="invalidInput" class="text-danger"
          >The code you have entered is incorrect or expired. Please try and re-enter it.</span
        >
        <span *ngIf="error" class="text-danger">An error occurred. Click below to try again shortly.</span>
      </p>
    </ng-container>

    <div *ngIf="saveSuccessful" class="my-5">
      <img src="assets/icons/ico-redeem-valid.svg" matSuffix alt="Email verified" class="mb-2 mr-2" />
      <span class="text-accent verified-text">Email Verified</span>
    </div>

    <div class="row footer-section button-wrapper mt-3">
      <div class="col-6 mx-auto" *ngIf="(codeSent && !saveSuccessful) || error">
        <button type="button" mat-stroked-button color="primary" class="save-btn w-100" (click)="resendCode()">
          Resend Code
        </button>
      </div>
    </div>
  </div>
</div>
