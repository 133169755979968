<div class="modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10 px-0">
      <h4 class="text-primary title mb-0">{{ data.dialogTitle }}</h4>
    </div>
    <div class="col-2 px-0">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body container-fluid text-secondary">
    <div class="row m-0">
      <div class="col-12 card-message">
        <span class="message" [innerHtml]="data.text"></span>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-muted">
    <div class="row m-0">
      <span [innerHtml]="data.footerHeading || ''"></span>
      <div class="col-6" [ngSwitch]="data.buttonToggle">
        <button
          *ngSwitchCase="true"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(true)">
          Yes
        </button>
        <button
          *ngSwitchDefault
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(true)">
          Yes
        </button>
      </div>
      <div class="col-6" [ngSwitch]="data.buttonToggle">
        <button
          *ngSwitchCase="false"
          mat-flat-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(false)">
          No
        </button>
        <button
          *ngSwitchDefault
          mat-stroked-button
          color="accent"
          class="btn-block text-wrap"
          (click)="onClose(false)">
          No
        </button>
      </div>
    </div>
  </div>
</div>
