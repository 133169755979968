import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Constants } from '@app/shared/constants';
import { Credentials } from '@app/shared/models/credentials';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  // tslint:disable-next-line: variable-name
  public _credentials: Credentials = null;

  private _credentialsChange: Subject<Credentials> = new Subject<Credentials>();
  public credentialsChangeObs: Observable<Credentials> = this._credentialsChange.asObservable();

  private isAdmin: boolean = false;

  // local storage variable names
  emailKey: string = Constants.LocalStorage_Key.email;
  /**
   * @deprecated Security credentials are now stored in HTTP-only cookie
   */
  accessTokenKey: string = Constants.LocalStorage_Key.accessTokenKey;
  /**
   * @deprecated Security credentials are now stored in HTTP-only cookie
   */
  refreshTokenKey: string = Constants.LocalStorage_Key.refreshTokenKey;
  expiresInSecondsKey: string = Constants.LocalStorage_Key.expiresInSecondsKey;
  associatedEmailKey: string = Constants.LocalStorage_Key.associatedAccountEmail;
  benefitKey: string = Constants.LocalStorage_Key.benefit;
  benefitObjKey: string = Constants.LocalStorage_Key.benefit_object;
  patientKey: string = Constants.LocalStorage_Key.patientId;
  agencyCodeKey: string = Constants.LocalStorage_Key.sessionAgencyCode;
  isAuthenticatedKey: string = Constants.LocalStorage_Key.isAuthenticated;

  constructor(
    private storageSession: SessionStorageService
  ) {}

  /**
   * @deprecated Security credentials are now stored in HTTP-only cookie
   * @function retrieveStoredCredentials
   * @description Retrieved stored credentials from local storage
   *
   * @returns {string} email address associated with this account
   */
  retrieveStoredCredentials(): string {
    let email: string = null;

    try {
      email = sessionStorage.getItem(this.emailKey);
    } catch (_err: any) {
      console.log('Failed to read local storage. Likely blocked by browser extension.');
    }

    // TODO We probably shouldn't be performing a side effect when performing a 'read' operation (i.e., CQRS)
    this.updateCredentials(email);

    return email;
  }

  private updateCredentials(email: string): void {
    if (email) {
      const credential: Credentials = {
        email: '',
        accessToken: '',
        refreshToken: '',
        expiresInSeconds: 0
      };

      credential.email = email;

      if (this.useAuthHeader()) {
        credential.accessToken = sessionStorage.getItem(this.accessTokenKey);
        credential.refreshToken = sessionStorage.getItem(this.refreshTokenKey);
      }

      const expires: number | string = sessionStorage.getItem(this.expiresInSecondsKey);
      if (expires) {
        if (typeof expires === 'string') {
          credential.expiresInSeconds = parseFloat(expires);
        } else if (typeof expires === 'number') {
          credential.expiresInSeconds = expires;
        }
      }

      if (
        !this._credentials ||
        this._credentials.email !== credential.email ||
        this._credentials.accessToken !== credential.accessToken ||
        this._credentials.refreshToken !== credential.refreshToken
      ) {
        this._credentials = credential;
        this._credentialsChange.next(null);
      }

      // If there is no email, clear all the credentials
    } else {
      this._credentials = null;
    }
  }

  getEmailAddressAssociatedWithAccount(): string {
    try {
      return this.storageSession.retrieve(this.associatedEmailKey) || null;
    } catch (_err: any) {
      return null;
    }
  }

  /**
   * @function isAuthenticated
   * @description Determine whether the current user is authenticated
   *
   * @returns {boolean} true if authentication credentials exist, otherwise false
   */
  isAuthenticated(): boolean {
    // if (!this.credentials?.email) {
    //   return false;
    // }

    if (this.useAuthHeader()) {
      return !!this.credentials.accessToken && !!this.credentials.refreshToken;
    }

    return this.checkIfAuthenticatedInLocalStorage();
  }

  checkIfAuthenticatedInLocalStorage(): boolean {
    return localStorage.getItem(this.isAuthenticatedKey) === 'true';
  }

  private setAuthenticatedInLocalStorage(isAuthenticated: boolean): void {
    if (isAuthenticated) {
      localStorage.setItem(this.isAuthenticatedKey, 'true');
    } else {
      localStorage.removeItem(this.isAuthenticatedKey);
    }
  }

  isAdminUser(): boolean {
    return Boolean(this.isAdmin);
  }

  /**
   * @function setCredentials
   * @description Save user credentials to localStorage
   *
   * @param {Credentials?} [credentials]
   * @param {boolean?} [remember=true] if true, stores credentials in local storage; if false, in session storage
   */
  setCredentials(credentials?: Credentials): void {
    this._credentials = credentials || null;

    if (this._credentials?.email && (this._credentials.accessToken || !this.useAuthHeader())) {
      const storage: Storage = sessionStorage;

      this.isAdmin = this._credentials.isAdmin;

      try {
        storage.setItem(this.emailKey, this._credentials.email);
        this.setAuthenticatedInLocalStorage(true);
        if (this.useAuthHeader()) {
          storage.setItem(this.accessTokenKey, this._credentials.accessToken);
          storage.setItem(this.refreshTokenKey, this._credentials.refreshToken);
        }

        storage.setItem(this.expiresInSecondsKey, this._credentials.expiresInSeconds.toString());

        // Remember account holder email
        this.storageSession.store(this.associatedEmailKey, this._credentials.email);
      } catch (err: any) {
        console.log('Unable to write user credentials to storage! Error: ', err);
      }

      // Note: patientId (_p) is set by calling patientService.setDefaultPatient() during
      // the initialisation phase of app.component
    } else {
      try {
        // patientId (_p) is removed from local storage only when logging out via authenticationService.logout().
        // Retaining patientId in storage prevents existing patients who have logged in before
        // from re-entering the site in 'unauthenticated' mode.
        // localStorage.removeItem(this.patientKey);

        sessionStorage.removeItem(this.emailKey);
        sessionStorage.removeItem(this.accessTokenKey);
        sessionStorage.removeItem(this.refreshTokenKey);
        sessionStorage.removeItem(this.expiresInSecondsKey);
        this.setAuthenticatedInLocalStorage(false);
      } catch (err: any) {
        console.warn('Unable to remove items from localStorage! Error: ', err);
      }
    }

    this._credentialsChange.next(null);
  }

  /**
   * @function removePatientData
   * @description Remove current patient data from local storage
   *
   * @param {boolean} [clearStoredBenefit=true] clear dod._benefit and dod._benefit_object from session storage
   */
  removePatientData(clearStoredBenefit: boolean = true): void {
    try {
      // Must remove stored patientId when switching users (legacy)
      sessionStorage.removeItem(this.patientKey);

      const agencyCode: string = this.storageSession.retrieve(this.agencyCodeKey) || null;

      // Remove saved Benefit (except for B2B agencies)
      if (clearStoredBenefit && (!agencyCode || agencyCode === environment.agencyCode)) {
        this.storageSession.clear(this.benefitKey);
        this.storageSession.clear(this.benefitObjKey);
      }

      // Clear all DoD storage keys
      Object.keys(window.localStorage).forEach((key: string) => {
        if (key.indexOf('dod._') === 0) {
          localStorage.removeItem(key);
        }
      });

      Object.keys(window.sessionStorage).forEach((key: string) => {
        if (key.indexOf('dod._') === 0) {
          sessionStorage.removeItem(key);
        }
      });
    } catch (_err: any) {}
  }

  /**
   * @function removeCredentials
   * @description Remove user credentials stored in localStorage
   */
  removeCredentials(): void {
    this.setCredentials(null);

    try {
      sessionStorage.removeItem(Constants.LocalStorage_Key.email);
      sessionStorage.removeItem(this.refreshTokenKey);
      sessionStorage.removeItem(Constants.LocalStorage_Key.expiresInSecondsKey);
      sessionStorage.removeItem(this.accessTokenKey);
      this.setAuthenticatedInLocalStorage(false);
    } catch (_err: any) {}
  }

  // This and all code related to storing credentials in the client side will be removed in the future
  useAuthHeader(): boolean {
    // Just in case we need to make this configurable
    return false;
  }

  get credentials(): Credentials {
    return this._credentials || null;
  }
}
