<div class="healthcard-info-wrapper modal-wrapper">
  <div class="modal-header container-fluid align-items-center">
    <div class="col-10">
      <h4 class="title text-primary">Health Card Information</h4>
    </div>
    <div class="col-2">
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="content">

      <span class="subject">Private:</span>
      <span class="explanation">
        Private price allows you to choose to have multiple quantities of your prescription
        supplied up to a maximum of three (3) times. No repeats will be issued and you will
        not receive Prescription Record Form stickers to count towards your Safety Net entitlement.
      </span>

      <span class="subject">PBS:</span>
      <span class="explanation">
        By selecting this price option you will receive
        a Pharmaceutical Benefits Scheme (PBS) prescription with up to a maximum of
        two (2) repeats at the doctors discretion. Prescription Record Form stickers will be supplied
        to enable you to count towards your Safety Net entitlement.
      </span>

      <span class="subject">Pensioner Concession / Healthcare Card:</span>
      <span class="explanation">
        You are entitled to Concessional pricing if you hold one of the following cards:
      </span>
      <ul>
        <li>Pensioner Concession Card</li>
        <li>Australian Seniors Health Card</li>
        <li>Healthcare Card</li>
        <li>Department of Veterans' Affairs (DVA) Gold, Orange, or White Card</li>
      </ul>

      <span class="subject">Safety Net:</span>
      <span class="explanation">
        The Safety Net is a scheme designed to protect you and your family from the high total cost
        of large numbers of <span title="Pharmaceutical Benefits Scheme">PBS</span> medicines. Once your
        or your family's applicable total out-of-pocket expenses
        for <span title="Pharmaceutical Benefits Scheme">PBS</span> medicines have reached a threshold
        amount during a calendar year, the costs of your medicines are generally reduced for the
        remainder of that year.
      </span>

    </div>
  </div>
  <div class="modal-footer mt-3">
    <button mat-flat-button color="accent" (click)="onClose()">Close</button>
  </div>
</div>
