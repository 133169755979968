<mat-card appearance="outlined" class="template-card-wrapper">
  <div class="row mx-0" [ngClass]="isDesktop ? 'justify-content-between' : 'justify-content-center'">
    <div class="col-xl-6 col-12 px-0 row align-items-center mx-0"
      [ngClass]="isDesktop ? 'justify-content-between' : 'justify-content-start'">
      <ng-content select="[card-type=image]"></ng-content>

      <div class="d-flex flex-column justify-content-center">
        <div [ngClass]="subtitleBottom ? 'd-flex flex-column justify-content-center' : 'd-flex flex-row' ">
          <div class="title">
            <ng-content select="[card-type=title]"></ng-content>
          </div>
          <span *ngIf="!subtitleBottom" class="title-subtitle-separator">|</span>
          <div class="subtitle my-auto">
            <ng-content select="[card-type=subtitle]"></ng-content>
          </div>
        </div>

        <div class="viewall-link">
          <ng-content select="[card-type=view-link]"></ng-content>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-12 px-0 row align-items-center mx-0 px-0"
      [ngClass]="isDesktop ? 'justify-content-end' : 'justify-content-start mt-28'">
      <div [ngClass]="isDesktop ? '' : 'col-6 pl-0 pr-9'">
        <ng-content select="[card-type=button-header1]"></ng-content>
      </div>
      <div [ngClass]="isDesktop ? '' : 'col-6 pr-0 pl-9'">
        <ng-content select="[card-type=button-header2]"></ng-content>
      </div>
    </div>
  </div>

  <mat-card-content [ngClass]="content ? subtitleBottom ? 'mt-4' : 'mt-3' : ''">
    <ng-content select="[card-type=content]"></ng-content>
  </mat-card-content>
</mat-card>
