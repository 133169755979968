import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from '@app/shared/constants';
import { TestDeviceType } from '@app/shared/models/test-device';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { SpeedTestFileModel, SpeedTestService, SpeedTestSettingsModel } from 'ng-speed-test';
import { Subscription } from 'rxjs';

@Component({
  selector: 'test-internet',
  templateUrl: './test-internet.component.html',
  styleUrls: ['./test-internet.component.scss'],
})
export class TestInternetComponent implements OnInit, OnDestroy {
  internetSpeed: string;
  dialogData: any;
  isLoading: boolean;
  speedTest: Subscription;
  speedTestSettings: SpeedTestSettingsModel;
  speedTestFile: SpeedTestFileModel;
  isSufficient: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TestInternetComponent>,
    private speedTestService: SpeedTestService,
    private changeDetectorRef: ChangeDetectorRef,
    private analytics: GoogleAnalyticsService
  ) {
    this.dialogData = data;
    const vh: number = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.speedTestFile = {
      path: Constants.EXTERNAL_RESOURCES.ngSpeedTestFile.path,
      size: Constants.EXTERNAL_RESOURCES.ngSpeedTestFile.sizeInBytes,
      shouldBustCache: true
    };
    this.speedTestSettings = {
      iterations: 3,
      file: this.speedTestFile,
      retryDelay: 750
    };
  }

  ngOnInit(): void {
    this.analytics.testDevice(TestDeviceType.speed);

    this.isLoading = true;
    this.speedTest = this.speedTestService.getMbps(this.speedTestSettings).subscribe((speed: number) => {
      this.internetSpeed = speed.toFixed(2);
      this.isSufficient = speed > 1 ? true : false;
      this.toggleLoading();
    });
  }

  ngOnDestroy(): void {
    this.speedTest.unsubscribe();
  }

  onClose(): void {
    try {
      this.dialogRef.close();
    } catch (err: any) {
      console.log('Failed to close Test Internet modal. Error = ', err?.message);
    }
  }

  toggleLoading(): void {
    this.isLoading = !this.isLoading;
    this.changeDetectorRef.detectChanges();
  }
}
