import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { WhitelabelService } from './whitelabel.service';
import { AgencyService } from './agency.service';
import { Benefit } from '../models/benefit';
import { SessionStorageService } from 'ngx-webstorage';
import { ONDEMAND_STEP_PATH_MAPPING } from '../step-configuration';
import { PromiseHelperService } from './promise-helper.service';
import { PractitionerOnlineDTO } from '../models/practitionerOnlineDTO';
import { SegmentedAvailabilitiesFilter } from '../models/availabilities/segmented/SegmentedAvailabilitiesFilter';
import { TimeZone } from '../models/time-zone';
import { AIContext, AppInsightsService } from './appinsights.service';
import { AvailabilitySynthService } from './availability-synth.service';
import { SegmentedAvailabilities } from '../models/availabilities/segmented/SegmentedAvailabilities';
import { environment } from '@env/environment';
import { CreateAvailabilityLockRequestModel } from '../models/availabilities/lock/Create/CreateAvailabilityLockRequestModel';
import { CreateAvailabilityLockResponseModel } from '../models/availabilities/lock/Create/CreateAvailabilityLockResponseModel';
import { ReleaseAvailabilityLockResponseModel } from '../models/availabilities/lock/Release/ReleaseAvailabilityLockResponseModel';
import { ReleaseAvailabilityLockRequestModel } from '../models/availabilities/lock/Release/ReleaseAvailabilityLockRequestModel';
import { RenewAvailabilityLockResponseModel } from '../models/availabilities/lock/Renew/RenewAvailabilityLockResponseModel';
import { RenewAvailabilityLockRequestModel } from '../models/availabilities/lock/Renew/RenewAvailabilityLockRequestModel';
import { AvailabilityLockInfo } from '../models/availabilities/lock/AvailabilityLockInfo';
import { ExtendAvailabilityLockResponseModel } from '../models/availabilities/lock/Extend/ExtendAvailabilityLockResponseModel';
import { ExtendAvailabilityLockRequestModel } from '../models/availabilities/lock/Extend/ExtendAvailabilityLockRequestModel';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  private subscription = new Subscription();
  private pollAvailability_intervalObs: Subscription;
  private whiteLabelServiceUpdateObs: Subscription;

  private _isPractitionerOnlineChange: Subject<any> = new Subject<any>();
  public isPractitionerOnlineChangeObs = this._isPractitionerOnlineChange.asObservable();

  private _availabilityDataChange: Subject<any> = new Subject<any>();
  public availabilityDataChangeObs = this._availabilityDataChange.asObservable();

  private _availabilityLockAcquiredSubject: Subject<AvailabilityLockInfo> = new Subject<AvailabilityLockInfo>();
  public availabilityLockAcquiredObs = this._availabilityLockAcquiredSubject.asObservable();

  private _availabilityLockReleasedSubject: Subject<AvailabilityLockInfo> = new Subject<AvailabilityLockInfo>();
  public availabilityLockReleasedObs = this._availabilityLockReleasedSubject.asObservable();

  private _availabilityLockAbsoluteExpirationDateSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    sessionStorage.getItem(Constants.LocalStorage_Key.availabilityLockAbsoluteExpirationDateUtc)
  );
  public availabilityLockAbsoluteExpirationDateObs = this._availabilityLockAbsoluteExpirationDateSubject.asObservable();

  isLoading: boolean = false;
  enableOnDemand: boolean = false;
  appointmentServiceType: string = Constants.SERVICE_TYPE.DOCTOR;
  appointmentStepType: string = 'APPOINTMENT_TYPE_DOCTOR';

  private _isOnline: any = {};
  private aiCtx: AIContext;

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private whiteLabelService: WhitelabelService,
    private agencyService: AgencyService,
    private sessionStore: SessionStorageService,
    private promiseHelperService: PromiseHelperService,
    private aiService: AppInsightsService,
    private availabilitySynthService: AvailabilitySynthService
  ) {
    this.aiCtx = this.aiService.createContext('AvailabilityService');
  }

  init(): void {
    this.updateServiceType();

    // ON-DEMAND AVAILABILITY POLLING INTERVAL
    if (!this.pollAvailability_intervalObs) {
      this.subscription.add(
        (this.pollAvailability_intervalObs = interval(
          Constants.API_Polling_Times.pollAvailability_seconds * Constants.MILLISECONDS_IN_SECOND
        ).subscribe(() => {
          // this.getAllPractitionersOnlineStatus();
          this.getPractitionerOnlineStatus(this.appointmentServiceType);
        }))
      );
    }

    if (!this.whiteLabelServiceUpdateObs) {
      // WhiteLabel configuration updates
      this.subscription.add(
        (this.whiteLabelServiceUpdateObs = this.whiteLabelService.whitelabelConfigObs.subscribe(() => {
          this.appointmentServiceType = this.whiteLabelService.getPrimaryServiceType();
          this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
          this.enableOnDemand =
            this.whiteLabelService.onDemandEnabledForServiceType(this.appointmentServiceType) &&
            ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType];

          if (this.enableOnDemand) {
            this.getPractitionerOnlineStatus(this.appointmentServiceType);
          }
        }))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateServiceType(serviceType?: string): void {
    this.appointmentServiceType = serviceType ?? this.whiteLabelService.getPrimaryServiceType();
    this.appointmentStepType = this.whiteLabelService.getAppointmentStepType(this.appointmentServiceType);
    this.enableOnDemand =
      this.whiteLabelService.onDemandEnabledForServiceType(this.appointmentServiceType) &&
      ONDEMAND_STEP_PATH_MAPPING[this.appointmentStepType];
  }

  isOnline(serviceType: string): boolean {
    if (!serviceType || (this._isOnline[serviceType] && typeof this._isOnline[serviceType]?.online !== 'boolean')) {
      return false;
    }
    return Boolean(this._isOnline[serviceType]?.online);
  }

  getCurrentHour(): number {
    return new Date().getHours();
  }

  isBusinessHours(hour?: number): boolean {
    const currentHour: number = typeof hour === 'number' ? hour : this.getCurrentHour();

    return (
      currentHour >= Constants.SERVICE_TIME_CONFIG.BUSINESS_HOUR &&
      currentHour < Constants.SERVICE_TIME_CONFIG.AFTER_HOURS
    );
  }

  /**
   * @function isRecent
   * @description Check whether the timestamp for the last API execution
   * occurred within a set time period (eg. within the last 5 seconds)
   *
   * @param {Date} date
   *
   * @returns {boolean} true if timestamp is within the specified time period
   */
  isRecent(date: Date): boolean {
    return this.functions.isRecent(date, Constants.API_Polling_Times.practitionerOnline_SecondsBetweenRequests);
  }

  /**
   * @async
   * @function getAllPractitionersOnlineStatus
   * @description Retrieve oneline status for all the available service types (doctor, psychologist, dietitian etc.)
   *
   * @returns {Promise<void>}
   */
  async getAllPractitionersOnlineStatus(): Promise<void> {
    this.isLoading = true;
    await this.getPractitionerOnlineStatus(Constants.SERVICE_TYPE.DOCTOR);
    this.isLoading = false;
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/appointment/practitionerOnline
  getPractitionerOnlineStatus(serviceType: string, policyId?: string): Promise<boolean | null> {
    if (!serviceType) {
      return Promise.resolve(false);
    }

    if (!this._isOnline[serviceType]) {
      this._isOnline[serviceType] = { online: null };
    }

    const promiseStorageKey: string = 'practitionerOnline_' + serviceType;

    let params = new HttpParams();
    params = params.append('serviceType', serviceType);

    let agencyCode: string = this.agencyService.agencyCode;
    if (agencyCode && agencyCode !== environment.agencyCode) {
      params = params.append('agencyCode', agencyCode);
    } else {
      agencyCode = null;
    }

    if (!policyId) {
      const storedBenefit: Benefit = this.sessionStore.retrieve(Constants.LocalStorage_Key.benefit_object);
      policyId = storedBenefit?.policyId;
    }

    if (policyId) {
      params = params.append('policyId', policyId);
    } else {
      policyId = null;
    }

    const newParams: PractitionerOnlineDTO = {
      serviceType,
      agencyCode,
      policyId
    };

    const returnExistingPromise: boolean = this.promiseHelperService.validatePromise<PractitionerOnlineDTO>(
      promiseStorageKey,
      newParams,
      Constants.API_Polling_Times.practitionerOnline_SecondsBetweenRequests
    );

    if (!returnExistingPromise) {
      this.promiseHelperService.storePromiseParams<PractitionerOnlineDTO>(promiseStorageKey, newParams);

      const newPromise: Promise<boolean> = this.http
        .get(`${this.url}/appointment/practitionerOnline`, { params })
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response?.success) {
            this.promiseHelperService.resetErrorState(promiseStorageKey);
          } else {
            this.promiseHelperService.setErrorState(promiseStorageKey, response?.error || 'Request failed');
          }

          const storedServiceType: string =
            this.promiseHelperService.retrieveParams<PractitionerOnlineDTO>(promiseStorageKey)?.serviceType ||
            serviceType;

          const online = Boolean(response?.response);
          const currentIsOnlineValue = Boolean(this._isOnline[storedServiceType]?.online);

          this._isOnline[storedServiceType] = { online };

          if (currentIsOnlineValue !== online) {
            this._isPractitionerOnlineChange.next(null);
          }

          return this._isOnline[storedServiceType].online;
        })
        .catch((err: any) => {
          const errMessage: string = this.functions.getErrorMessage(err);
          const errCode: string = this.functions.getErrorCode(err);

          console.warn('Unable to retrieve practitioner on-demand availability. Error:', errCode, '::', errMessage);

          this.promiseHelperService.setErrorState(promiseStorageKey, err);

          const storedServiceType: string =
            this.promiseHelperService.retrieveParams<PractitionerOnlineDTO>(promiseStorageKey)?.serviceType ||
            serviceType;

          this._isOnline[storedServiceType] = { online: false };

          // Clear invalid benefit object stored in session storage so that this API request does not fail next time
          if (errCode === Constants.API_ERROR_CODES.INVALID_POLICY_NUMBER) {
            this.agencyService.resetSessionAgencyToDefault(true);
            this.whiteLabelService.setWhiteLabelConfig(null);
          }

          return false;
        })
        .finally(() => {
          this.promiseHelperService.resetLoadingState(promiseStorageKey);
        });

      this.promiseHelperService.storePromise<boolean>(promiseStorageKey, newPromise, newParams);
    }

    return this.promiseHelperService.getPromiseByKey<boolean | null>(promiseStorageKey);
  }

  /**
   * @async
   * @function calculateAvailabilities
   * @description New method of calculating appointment availabilities
   *
   * @param {SegmentedAvailabilitiesFilter} filter availability filter params
   * @param {TimeZone} timezone patient's timezone
   *
   * @returns {Promise<any>}
   */
  async calculateAvailabilities(
    filter: SegmentedAvailabilitiesFilter,
    timezone: TimeZone,
    haltUpdateEvent: boolean = false
  ): Promise<SegmentedAvailabilities> {
    return await this.availabilitySynthService
      .calculateAvailabilities(filter, timezone)
      .then((result: any) => {
        if (!haltUpdateEvent) {
          this._availabilityDataChange.next(result);
        }

        return result;
      })
      .catch((err: any) => {
        console.log('Error calculating availabilities:', this.functions.getErrorMessage(err));
        throw err;
      });
  }

  /**
   * @async
   * @function onModifiedLockUnavilabilitiesEventReceived
   * @description used to do a targeted update of the availabilities affected by an availability lock being acquired or released
   *
   * @param {SegmentedAvailabilitiesFilter} filter availability filter params
   * @param {TimeZone} timezone patient's timezone
   *
   * @returns {Promise<any>}
   */
  async onModifiedLockUnavilabilitiesEventReceived(
    modifiedLockUnavailabilitiesEvent: any,
    haltUpdateEvent: boolean = false
  ): Promise<SegmentedAvailabilities> {
    return await this.availabilitySynthService
      .onModifiedLockUnavilabilitiesEventReceived(modifiedLockUnavailabilitiesEvent)
      .then((result: any) => {
        // TODO: consider if we want to do this or trigger some other kind of event
        // if (!haltUpdateEvent) {
        //   this._availabilityDataChange.next(result);
        // }

        return result;
      })
      .catch((err: any) => {
        console.log('Error calculating availabilities:', this.functions.getErrorMessage(err));
        throw err;
      });
  }

  /**
   * @async
   * @function getQueueSizes
   * @description Retrieve queue sizes for all available on-demand practitioners
   *
   * @param {string} serviceType
   * @param {TimeZone} timezone
   * @param {string} [policyId]
   *
   * @returns {Promise<Record<string, number>>}
   */
  async getQueueSizes(serviceType: string, timezone: TimeZone, policyId?: string): Promise<Record<string, number>> {
    const queueSizeMapping: Record<string, number> = await this.availabilitySynthService.getQueueSizes(
      serviceType,
      timezone,
      policyId
    );

    const success = Boolean(queueSizeMapping);
    const doctorCount: number = success ? Object.keys(queueSizeMapping).length : 0;

    this.aiCtx.reportSuccessStatus('QueueSizeMappingUpdated', success, 'Retrieving queue sizes from API', {
      serviceType,
      policyId,
      doctorCount,
      queueSizeMapping
    });

    return queueSizeMapping;
  }

  // AvailabilityLocks

  // https://api3<environment>.doctorsondemand.com.au/api/v1/availabilities/locks/unavailabilities
  acquireAvailabilityLock(
    createAvailabilityLockRequestModel: CreateAvailabilityLockRequestModel
  ): Promise<CreateAvailabilityLockResponseModel | null> {
    return (
      this.http
        //.post(`${this.url}/availabilities/lock`, createAvailabilityLockRequestModel)
        .post(`${this.url}/availabilities/lock`, createAvailabilityLockRequestModel)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response) {
            const responseModel = response.response as CreateAvailabilityLockResponseModel;
            if (responseModel.wasLockCreatedSuccessfully && responseModel.lockResourceAccessToken) {
              this.setAcquiredAvailabilityLock(createAvailabilityLockRequestModel, responseModel);
              return responseModel;
            }
          }
          return null;
        })
        .catch((err: any) => {
          //TODO: check
          this.functions.handleError(err);
          return null;
        })
    );
  }

  setAcquiredAvailabilityLock(
    createAvailabilityLockRequestModel: CreateAvailabilityLockRequestModel,
    acquiredAvailabilityLock: CreateAvailabilityLockResponseModel
  ) {
    // Store the lockResourceAccessToken in local storage.
    // We only want to allow the user to have 1 active lockResourceAccessToken at a time.
    sessionStorage.setItem(
      Constants.LocalStorage_Key.availabilityLockResourceAccessToken,
      acquiredAvailabilityLock.lockResourceAccessToken
    );
    sessionStorage.setItem(Constants.LocalStorage_Key.availabilityLockId, acquiredAvailabilityLock.availabilityLockId);
    sessionStorage.setItem(
      Constants.LocalStorage_Key.availabilityLockAcquiredLockDateUtc,
      createAvailabilityLockRequestModel.startUTC
    );
    sessionStorage.setItem(
      Constants.LocalStorage_Key.availabilityLockUserInactivityPromptThresholdSeconds,
      acquiredAvailabilityLock.userInactivityPromptThresholdSeconds.toString()
    );
    this.setAvailabilityLockAbsoluteExpirationDateUtc(acquiredAvailabilityLock.absoluteExpirationDateUtc);

    this._availabilityLockAcquiredSubject.next({
      availabilityLockId: acquiredAvailabilityLock.availabilityLockId,
      availabilityLockResourceAccessToken: acquiredAvailabilityLock.lockResourceAccessToken
    });
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/availabilities/{availabilityLockId}/renew
  renewAvailabilityLock(
    availabilityLockId: string,
    availabilityLockResourceAccessToken: string
  ): Promise<RenewAvailabilityLockResponseModel | null> {
    const renewAvailabilityLockRequestModel: RenewAvailabilityLockRequestModel = {
      lockResourceAccessToken: availabilityLockResourceAccessToken
    };
    return (
      this.http
        //.post(`${this.url}/availabilities/lock`, createAvailabilityLockRequestModel)
        .put(`${this.url}/availabilities/${availabilityLockId}/renew`, renewAvailabilityLockRequestModel)
        .toPromise()
        .then((response: IResponseAPI) => {
          if (response) {
            const responseModel = response.response as RenewAvailabilityLockResponseModel;
            if (responseModel.wasLockReleasedSuccessfully) {
              this.setAvailabilityLockAbsoluteExpirationDateUtc(responseModel.absoluteExpirationDateUtc);
              return responseModel;
            }
          }
          return null;
        })
    );
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/availabilities/{availabilityLockId}/extend
  extendAvailabilityLock(
    availabilityLockId: string,
    availabilityLockResourceAccessToken: string,
    isExtendingFromSignUpFlow: boolean
  ): Promise<ExtendAvailabilityLockResponseModel | null> {
    const extendAvailabilityLockRequestModel: ExtendAvailabilityLockRequestModel = {
      lockResourceAccessToken: availabilityLockResourceAccessToken,
      isExtendingFromSignUpFlow: isExtendingFromSignUpFlow
    };
    return this.http
      .put(`${this.url}/availabilities/${availabilityLockId}/extend`, extendAvailabilityLockRequestModel)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response) {
          const responseModel = response.response as ExtendAvailabilityLockResponseModel;

          // Only set the expiration date if we still have an availabilityLockResourceAccessToken in storage
          if (sessionStorage.getItem(Constants.LocalStorage_Key.availabilityLockResourceAccessToken)) {
            this.setAvailabilityLockAbsoluteExpirationDateUtc(responseModel.absoluteExpirationDateUtc);
          }
          return responseModel;
        }
        return null;
      });
  }

  releaseAvailabilityLockViaBeaconRemoveLockResourcesFromLocalStorage(): void {
    const availabilityLockId = sessionStorage.getItem(Constants.LocalStorage_Key.availabilityLockId);
    const availabilityLockRAT = sessionStorage.getItem(Constants.LocalStorage_Key.availabilityLockResourceAccessToken);

    if (availabilityLockId) {
      this.releaseAvailabilityLockViaBeacon(availabilityLockId, availabilityLockRAT);
    } else {
      // Even if the availabilityLockId has been cleared, emit an event so that any existing AvailabilityLockRenew or UserInactivity
      // intervals are destroyed
      this._availabilityLockReleasedSubject.next(null);
    }

    this.clearAvailabilityLock();
  }

  // https://api3<environment>.doctorsondemand.com.au/api/v1/availabilities/{availabilityLockId}/release
  releaseAvailabilityLockViaBeacon(availabilityLockId: string, availabilityLockResourceAccessToken: string): void {
    const releaseAvailabilityLockRequestModel: ReleaseAvailabilityLockRequestModel = {
      lockResourceAccessToken: availabilityLockResourceAccessToken
    };

    // navigator.SendBeacon requires the request body to be a Blob type
    const releaseAvailabilityLockRequestBlob = new Blob([JSON.stringify(releaseAvailabilityLockRequestModel)], {
      type: 'text/plain'
    });

    // The beacon request is gauranteed to be sent before the browser is closed
    // (in the event that we call this code in a beforeUnload handler for example),
    // but it does not wait for or return a response
    navigator.sendBeacon(
      `${this.url}/availabilities/${availabilityLockId}/release`,
      releaseAvailabilityLockRequestBlob
    );

    this._availabilityLockReleasedSubject.next({ availabilityLockId, availabilityLockResourceAccessToken });
  }

  setAvailabilityLockAbsoluteExpirationDateUtc(absoluteExpirationDateUtc: string) {
    sessionStorage.setItem(
      Constants.LocalStorage_Key.availabilityLockAbsoluteExpirationDateUtc,
      absoluteExpirationDateUtc
    );
    this._availabilityLockAbsoluteExpirationDateSubject.next(absoluteExpirationDateUtc);
  }

  clearAvailabilityLock() {
    sessionStorage.removeItem(Constants.LocalStorage_Key.availabilityLockId);
    sessionStorage.removeItem(Constants.LocalStorage_Key.availabilityLockResourceAccessToken);
    sessionStorage.removeItem(Constants.LocalStorage_Key.availabilityLockAbsoluteExpirationDateUtc);
    sessionStorage.removeItem(Constants.LocalStorage_Key.availabilityLockAcquiredLockDateUtc);
    this._availabilityLockAbsoluteExpirationDateSubject.next('');
  }

  get isDoctorOnline(): boolean {
    return Boolean(this._isOnline[Constants.SERVICE_TYPE.DOCTOR]?.online);
  }
  get isPsychologistOnline(): boolean {
    return Boolean(this._isOnline[Constants.SERVICE_TYPE.PSYCHOLOGY]?.online);
  }
  get isDietitianOnline(): boolean {
    return Boolean(this._isOnline[Constants.SERVICE_TYPE.DIETITIAN]?.online);
  }
  get isWellnessDoctorOnline(): boolean {
    return Boolean(this._isOnline[Constants.SERVICE_TYPE.WELLNESS]?.online);
  }
  get isSleepSpecialistOnline(): boolean {
    return Boolean(this._isOnline[Constants.SERVICE_TYPE.SLEEP_SPECIALIST]?.online);
  }
  get isSleepGPOnline(): boolean {
    return Boolean(this._isOnline[Constants.SERVICE_TYPE.SLEEP_GP]?.online);
  }
  get practitionerMapping(): any {
    return window['DoD'].AvailabilitySynth.Data.PractitionerMapping;
  }
}
