import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Constants } from '../constants';
import { Functions } from '../functions';
import { IResponseAPI } from '../models/api-response';
import { CreateWhitelabelPolicyFromTokenResponseDTO } from '../models/createWhitelabelPolicyFromTokenResponseDTO';
import { AgencyService } from './agency.service';
import { AIContext, AppInsightsService } from './appinsights.service';
import { PolicyToken } from '../models/policy-token';
import { Benefit } from '../models/benefit';
import { PatientService } from './patient.service';
import { Patient } from '../models/patient';
import { DataTransferTokenBody, PolicyMetadataTokenDTO } from '../models/policies/policyMetadataTokenDTO';
import { HashAlgorithm } from '../models/HashAlgorithm';
import { Subscription } from 'rxjs';
import { ErrorModalService } from './error-modal.service';
import { WhitelabelService } from './whitelabel.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService implements OnDestroy {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;
  private readonly tokenUrl: string = `${this.url}/policy/token`;
  aiContext: AIContext;

  private readonly subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private agencyService: AgencyService,
    private patientService: PatientService,
    private aiService: AppInsightsService
  ) {
    this.aiContext = aiService.createContext('PolicyService');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createPolicyFromToken(token: string): Promise<CreateWhitelabelPolicyFromTokenResponseDTO> {
    return this.http
      .post(this.tokenUrl, { token })
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response.success && response.response) {
          const policy = response.response as CreateWhitelabelPolicyFromTokenResponseDTO;
          return policy;
        }
        return null;
      })
      .catch((err: any) => {
        console.log('Error creating policy from token: ', this.functions.getErrorMessage(err));
        return null;
      });
  }

  async generateAndApplyMedicareAutoPolicy(): Promise<boolean> {
    this.aiContext = this.aiService.createContext('PolicyService');

    const agencyCode: string = this.agencyService.agencyCode.toLowerCase();

    this.aiContext.debug('GeneratingMedicareAutoPolicy', { agencyCode });

    const generatedPolicy: PolicyToken = await this.agencyService.generateMedicarePolicyForB2BAgency(agencyCode);

    if (generatedPolicy) {
      this.aiContext.debug('PolicyGenerated', { result: true, policyNumber: generatedPolicy.policyNumber });

      let generatedBenefit: Benefit = (await this.patientService.getBenefit(generatedPolicy.policyNumber, null, false))
        ?.benefit;

      if (generatedBenefit) {
        generatedBenefit.hidePolicyNumber = true;
        if (generatedPolicy.medicareRequiredForRebate && !generatedBenefit.medicareRequiredForRebate) {
          // We need to do this because getBenefit is sent with a null serviceType
          generatedBenefit.medicareRequiredForRebate = generatedPolicy.medicareRequiredForRebate;
        }

        this.patientService.setBenefit(generatedBenefit);

        this.aiContext.debug('BenefitApplied', {
          result: true,
          policyNumber: generatedPolicy.policyNumber,
          benefitCode: generatedBenefit.name
        });

        return true;
      } else {
        this.aiContext.debug('BenefitApplied', { result: false, policyNumber: generatedPolicy.policyNumber });
      }
    } else {
      this.aiContext.warn('PolicyGenerated', { result: false });
    }

    return false;
  }

  private parseMetadataToken(metadataToken: string): PolicyMetadataTokenDTO {
    if (!metadataToken) return null;

    const split = decodeURIComponent(metadataToken).split('.');

    if (split.length != 3) throw new Error('Invalid metadata token');

    const payload: DataTransferTokenBody<PolicyMetadataTokenDTO> = JSON.parse(atob(split[1]));

    return payload.Data;
  }

  async validatePolicyPatientConstraints(patient: Patient): Promise<{ valid: boolean; reason?: string }> {
    const policy: Benefit = this.patientService.benefit;

    if (!patient) return { valid: true };
    if (!policy) return { valid: true };

    const metadataToken: string = policy.policyMetadataToken;
    if (!metadataToken) return { valid: true };

    let metadata: PolicyMetadataTokenDTO;
    try {
      metadata = this.parseMetadataToken(metadataToken);
    } catch (err: any) {
      this.aiContext.trackException(err, 'ValidatePolicyPatientConstraints', {
        success: false,
        reason: 'InvalidMetadataToken',
        patientId: patient.patientId
      });

      return { valid: false, reason: 'the data supplied was not valid' };
    }

    this.aiContext.debug('ValidatePolicyPatientConstraints', {
      constraints: metadata.PatientConstraints,
      patientId: patient.patientId
    });

    if (metadata.PatientConstraints.DateOfBirth) {
      if (metadata.PatientConstraints.DateOfBirth.Algorithm !== HashAlgorithm.SHA256) {
        this.aiContext.error('ValidatePolicyPatientConstraints', {
          success: false,
          reason: 'UnsupportedHashAlgorithm',
          patientId: patient.patientId
        });

        return { valid: false, reason: 'the data supplied was not valid' };
      }

      if (!patient.dateOfBirth) {
        this.aiContext.error('ValidatePolicyPatientConstraints', {
          success: false,
          reason: 'MissingPatientDOB',
          patientId: patient.patientId
        });

        return { valid: false, reason: "the selected patient's date of birth was not specified" };
      }

      let calculatedHash: string;
      try {
        calculatedHash = await this.calculatePatientDOBHashWithSalt(patient, metadata.IdempotencyKey);
      } catch (err: any) {
        this.aiContext.trackException(err, 'ValidatePolicyPatientConstraints', {
          success: false,
          reason: 'HashCalculationError',
          patientId: patient.patientId
        });

        return {
          valid: false,
          reason: "a problem occurred while validating the patient's date of birth"
        };
      }

      if (calculatedHash !== metadata.PatientConstraints.DateOfBirth.Hash) {
        this.aiContext.info('ValidatePolicyPatientConstraints', {
          success: false,
          reason: 'DateOfBirthHashMismatch',
          patientId: patient.patientId,
          expectedValue: metadata.PatientConstraints.DateOfBirth.Hash
        });

        return {
          valid: false,
          reason: "the selected patient's date of birth does not match the policy"
        };
      }
    }

    this.aiContext.info('ValidatePolicyPatientConstraints', { success: true, patientId: patient.patientId });

    return { valid: true };
  }

  async calculatePatientDOBHashWithSalt(patient: Patient, idempotencyKey: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(`${idempotencyKey}${patient.dateOfBirth}`);
    const hash = await window.crypto.subtle.digest('SHA-256', data);

    return btoa(String.fromCharCode(...new Uint8Array(hash)));
  }
}
