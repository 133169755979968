import { computed, effect, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { StorageService } from '../storage.service';
import { Pharmacy } from '../../models/pharmacy';
import { MedicationService } from '../medication.service';
import { NullOrUndefined } from '../../types';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs';
import { Address } from '../../models/address';
import { AddressService } from '../address.service';

/**
 * This service contains appointment data (as signals) that are synced with the local/session storage.
 * Note that "appointment data" here doesn't refer to the appointment data in the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class AppointmentDataService {
  readonly initialPharmacyId: WritableSignal<string | NullOrUndefined> = signal<string | NullOrUndefined>(undefined);

  readonly initialPharmacy: Signal<Pharmacy | NullOrUndefined> = toSignal(
    toObservable(this.initialPharmacyId).pipe(
      switchMap((pharmacyId) => this.medicationService.getPharmacyById(pharmacyId))
    )
  );

  readonly initialPharmacyAddress: Signal<Address | NullOrUndefined> = toSignal(
    toObservable(this.initialPharmacy).pipe(
      filter((x) => !!x?.addressId),
      switchMap((x) => this.addressService.getAddressById(x.addressId))
    )
  );

  readonly pharmacyOverride: WritableSignal<Pharmacy | NullOrUndefined> = signal<Pharmacy | NullOrUndefined>(undefined);

  readonly selectedPharmacy: Signal<Pharmacy | NullOrUndefined> = computed<Pharmacy | NullOrUndefined>(() => {
    const pharmacy = this.pharmacyOverride();
    if (pharmacy) {
      return pharmacy;
    }

    return this.initialPharmacy();
  });

  constructor(
    private readonly storageService: StorageService,
    private readonly medicationService: MedicationService,
    private readonly addressService: AddressService
  ) {
    this.initialPharmacyId.set(storageService.retrieve('Appointment:InitialPharmacyId'));
    storageService.storeOnSignalChange('Appointment:InitialPharmacyId', this.initialPharmacyId);
  }

  reset() {
    this.initialPharmacyId.set(undefined);
    this.storageService.delete('Appointment:InitialPharmacyId');
  }
}
