<div class="page-wrap d-flex flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <h1 class="display-1 d-block mb-4">404</h1>
        <div class="mb-4 lead">The page you are looking for was not found.</div>
        <a [routerLink]="['']" class="btn btn-link">Back to Home</a>
      </div>
    </div>
  </div>
</div>
