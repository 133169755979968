import { Component, NgZone, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Constants, liveChatVisibilityStatus } from './shared/constants';
import { STEP_PATH } from './shared/step-configuration';
import { Functions } from './shared/functions';
import { CredentialsService } from './core/services/credentials.service';
import { PatientService } from './shared/services/patient.service';
import { DeepLinkRouterService } from './shared/services/deep-link-router.service';
import { Patient } from './shared/models/patient';
import { TimezoneService } from './shared/services/timezone.service';
import { VersionService } from './shared/services/version.service';
import { WhitelabelService } from './shared/services/whitelabel.service';
import { AgencyService } from './shared/services/agency.service';
import { HotjarService } from './shared/services/hotjar.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { AnalyticsCustomDimensionsService } from './shared/services/custom-dimensions.service';
import { CustomDimensions } from './shared/models/customDimensions';
import { LiveChatService } from './shared/services/live-chat.service';
import { EventHandlerPayload } from '@livechat/widget-angular';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { App, AppLaunchUrl, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from '@env/environment';
import { WhiteLabelUIConfig } from './shared/models/whitelabel/WhiteLabelUIConfigModel';
import { AIContext, AppInsightsService } from './shared/services/appinsights.service';
import { PublicHolidayService } from './shared/services/public-holiday.service';
import { AvailabilityService } from './shared/services/availability.service';
import { B2BCustomerService } from './shared/services/b2b-customer.service';
import { DebugService } from './shared/services/debug.service';
import { PatientVerificationService } from './shared/services/patient-verification.service';
// import { TranslateService } from '@ngx-translate/core';
// import { I18nService } from './core/services/i18n.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private _prevRouteUrl: string;

  virtualKeyboardVisible: boolean = false;
  liveChatLicense: string = environment.liveChatLicense;
  liveChatVisibility: liveChatVisibilityStatus = liveChatVisibilityStatus.closed;
  isAgentOnline: boolean = false;
  showLiveChatWidget: boolean = false;
  liveChatWidgetOpen: boolean = false;
  userInteractedWithChatWidget: boolean = false;
  patientName: string;
  patientEmail: string;
  patient: Patient = null;
  patientId: string = null;
  customDimensionsSet: boolean = false;
  pageViewRecorded: boolean = false;
  pageViewURLs: any[] = [];
  aiContext: AIContext;

  isInAppointment: boolean = window.location.pathname.indexOf('/in-appointment') !== -1;
  isInAppointmentMobile: boolean = window.location.pathname.indexOf('/appointment-mobile') !== -1;

  isNative: boolean = Capacitor.isNativePlatform();
  isIOS: boolean = Capacitor.getPlatform() === 'ios';

  constructor(
    // private translateService: TranslateService,
    // private i18nService: I18nService,
    private router: Router,
    private titleService: Title,
    private functions: Functions,
    private changeDetectorRef: ChangeDetectorRef,
    private credentialsService: CredentialsService,
    private patientService: PatientService,
    private hotjarService: HotjarService,
    private timezoneService: TimezoneService,
    private analytics: GoogleAnalyticsService,
    private customDimensionsService: AnalyticsCustomDimensionsService,
    private liveChatService: LiveChatService,
    private deepLinkRouter: DeepLinkRouterService,
    private versionService: VersionService,
    private zone: NgZone,
    private whiteLabelService: WhitelabelService,
    private agencyService: AgencyService,
    private aiService: AppInsightsService,
    private publicHolidayService: PublicHolidayService,
    private availabilityService: AvailabilityService,
    public b2bCustomerService: B2BCustomerService,
    private debugService: DebugService,
    private patientVerificationService: PatientVerificationService // need this injected here to instantiate the service early!!!!
  ) {
    if (!environment.production) {
      this.debugService.printDebugInfo();
    }

    this.aiContext = this.aiService.createContext('App');

    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // If the navigator (aka device) is offline, we will show the user an offline warning
    if (!navigator.onLine && !this.isInAppointment && !this.isInAppointmentMobile) {
      this.router.navigate([STEP_PATH.FALLBACK, 'navigator-offline']);
    }

    // For mobile app only - check app version for required updates
    if (this.isNative) {
      this.versionService.checkNeedsUpdate().then((needsUpdate: boolean) => {
        if (needsUpdate) {
          this.router.navigate([STEP_PATH.FALLBACK, 'update-required']);
        }
      });
    }

    if (window.location.protocol === 'capacitor:') {
      this.router.navigate([STEP_PATH.FALLBACK, 'redir-splash']);
      return;
    }

    // Make sure patient credentials/data are reset when logging in to patient account via token
    if (location.href.indexOf('/login/transfer') !== -1) {
      this.credentialsService.removeCredentials();
      this.credentialsService.removePatientData();
    }
  }

  async ngOnInit(): Promise<void> {
    const serviceType: string = new URLSearchParams(window.location.search).get('service');

    // WHITELABEL SERVICE CONFIGURATION ENTRY POINT
    // Determine active agency and set whitelabel configuration
    await this.agencyService.initialiseService(serviceType);

    this.updateAppWithBrand(this.whiteLabelService.getUIConfig());

    // Subscribe to whitelabel UI configuration updates (at this point, no configuration is loaded)
    this.subscription.add(
      this.whiteLabelService.whitelabelUIConfigObs.subscribe((config: WhiteLabelUIConfig) => {
        this.updateAppWithBrand(config);
      })
    );

    // PATIENT
    this.updatePatientDetails(this.patientService.patient);

    // If we are on a native mobile platform, set the isNativeMobile class on the body
    if (this.isNative) {
      this.initialiseMobileAppConfiguration();
    }

    // Set initial browser title (before any route updates take place)
    this.setBrowserTitle();

    this.analytics.trackUTMParameters(window.location.search);

    // Internationalisation
    // this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    // TIME ZONES
    await this.timezoneService.getTimezoneData();

    // CREDENTIALS
    // For routes that don't require authentication, we still want to retrieve the patient's profile
    // if the patient had logged-in in an earlier session.
    await this.processStoredCredentials();

    // ANALYTICS
    if (environment.analytics.enabled) {
      // Subscribe to Custom Dimensions Service updates
      this.subscription.add(
        this.customDimensionsService.customDimensionsChangeObs.subscribe((dimensions: CustomDimensions) => {
          this.checkCustomDimensionsAreSet(dimensions);
          this.processPageViewQueue();
        })
      );

      // Initialise all the custom dimension parameters
      this.customDimensionsService.resetCustomDimensionsProcessedStatus();
      this.customDimensionsService.init();

      // Facebook Pixel (initialisation event)
      this.analytics.recordFacebookPixel(environment.facebookPixelId);
    }

    // ROUTING
    this.subscription.add(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          const url: string = event.urlAfterRedirects;

          if (url !== this.prevRouteUrl) {
            // Set browser title based on titles assigned within the route hierarchy
            this.setBrowserTitle();

            if (environment.analytics.enabled) {
              this.checkCustomDimensionsAreSet(this.customDimensionsService.allCustomDimensions);
              this.updateAnalyticsCustomDimensions();

              // Check for special routes, we need to treat them differently
              if (url.indexOf('/medication/') !== -1 || url.indexOf(STEP_PATH.ONDEMAND_TYPE_DOCTOR) !== -1) {
                this.pageViewTracker(url);
              } else {
                this.pageViewTracker(url);
                this.prevRouteUrl = url;
              }
            } else {
              this.prevRouteUrl = url;
            }
          }
        }
      })
    );

    // META TAG MUTATION (by Live Chat widget)
    this.setupViewportMetaTagMutationObserver();

    this.aiContext.trackEvent('Initialised', {
      platform: Capacitor.getPlatform(),
      version: this.versionService.getCurrentVersionString()
    });

    // Retrieve list of public holidays from storage or API and save in the PublicHolidayService
    await this.publicHolidayService.retrievePublicHolidays();
    this.publicHolidayService.initPublicHolidayStatusForToday();

    // Retrieve any stored benefit upfront so that the data is ready before initialisation of sub-components
    await this.patientService.retrieveBenefit();

    // Initialise availability service observables
    this.availabilityService.init();

    // Get online status for all supported practitioner types
    await this.availabilityService.getAllPractitionersOnlineStatus();
  }

  ngAfterViewInit(): void {
    if (environment.analytics.enabled) {
      this.customDimensionsService.subscribeToUpdates();
    }

    // PATIENT
    this.subscription.add(
      this.patientService.patientChangeObs.subscribe(async (patient: Patient) => {
        const patientId: string = patient?.patientId || null;

        if (patientId && patientId !== this.patientId) {
          this.updatePatientDetails(patient);

          this.patientService.getUnreadMessages();

          if (environment.analytics.enabled) {
            this.customDimensionsService.userId = patientId;
            this.analytics.userId = patientId;

            const hotjarCookieSessionId: string = this.hotjarService.getSessionIdFromCookie();
            this.customDimensionsService.updateHotJarSessionId(hotjarCookieSessionId);
            this.updateAnalyticsCustomDimensions();

            this.analytics.recordHotJarUserId(hotjarCookieSessionId, patientId);
          }
        }
      })
    );

    // LIVE CHAT
    this.subscription.add(
      this.liveChatService.liveChatStatusChangeObs.subscribe(() => {
        if (this.isAgentOnline !== this.liveChatService.isAgentOnline) {
          this.isAgentOnline = this.liveChatService.isAgentOnline;
        }

        this.showLiveChatWidget = this.liveChatService.showLiveChatButton;
      })
    );

    this.subscription.add(
      this.liveChatService.liveChatVisibilityChangeObs.subscribe((isOpen: boolean) => {
        this.liveChatWidgetOpen = isOpen;

        if (isOpen && this.liveChatVisibility != liveChatVisibilityStatus.open && this.showLiveChatWidget) {
          this.liveChatVisibility = liveChatVisibilityStatus.open;
        } else {
          this.liveChatVisibility = liveChatVisibilityStatus.closed;
        }

        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    // this.i18nService.destroy();
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.removeAllListeners();
    }
  }

  checkCustomDimensionsAreSet(dimensions: CustomDimensions): void {
    if (dimensions?.userType && !this.customDimensionsSet) {
      try {
        if (
          // session availabilty custom dimensions retrieved from API, AND
          typeof this.customDimensionsService.availabilityDataRetrieved === 'boolean' &&
          typeof dimensions.onDemandAppointmentAvailable === 'string' &&
          // user unauthenticated OR
          (!this.credentialsService.isAuthenticated() ||
            // authenticated AND
            (this.patientService.isAuthenticatedPatient() &&
              // patientId set
              dimensions.userId &&
              // patient profile custom dimensions have been populated
              this.customDimensionsService.isProfileProcessedForPatient(dimensions.userId) &&
              // patient medical history custom dimensions have been populated
              this.customDimensionsService.isMedicalHistoryProcessedForPatient(dimensions.userId) &&
              // patient benefit has been processed and userCustomerType set
              this.customDimensionsService.isBenefitProcessedForPatient()))
        ) {
          this.customDimensionsSet = true;
          this.pageViewRecorded = false;
        }
        // else {
        //   this.customDimensionsSet = false;
        // }
      } catch (err: any) {
        this.customDimensionsSet = false;
        console.log(
          'Error reading custom dimensions for authenticated user. Error: ',
          this.functions.getErrorMessage(err)
        );
      }
    }
  }

  pageViewTracker(url: string): void {
    if (this.customDimensionsSet) {
      console.log('[APP-COMPONENT] Recording normal pageview: ', url);
      this.recordPageView(url);
    } else {
      console.log('[APP-COMPONENT] Pushing URL for pageview tracker: ', url);
      this.pageViewURLs.push({ url, referrer: this.analytics.referrerPageURL });
    }
  }

  recordPageView(url: string, referrer?: string): void {
    this.pageViewRecorded = true;
    this.analytics.pageView(url, referrer);
    this.analytics.referrerPagePath = url;
  }

  processPageViewQueue(): void {
    if (this.customDimensionsSet && this.pageViewURLs?.length) {
      this.updateAnalyticsCustomDimensions();

      for (var i = 0; i < this.pageViewURLs.length; i++) {
        console.log(
          '[APP-COMPONENT] Record async pageview. URL: ' +
            this.pageViewURLs[i].url +
            ', referrer: ' +
            this.pageViewURLs[i].referrer
        );
        this.recordPageView(this.pageViewURLs[i].url, this.pageViewURLs[i].referrer);
      }
      // console.log('[APP-COMPONENT] Async pageviews have been recorded.');
      // this.pageViewRecorded = true;
      this.pageViewURLs = [];
      this.customDimensionsSet = false;
    }
  }

  initialiseMobileAppConfiguration(): void {
    try {
      const bodyElement: HTMLElement = document.body;

      if (!bodyElement.classList.contains('isNativeMobile')) {
        bodyElement.classList.add('isNativeMobile');
      }
    } catch (err: any) {
      console.warn('Could not set body class to isNativeMobile! Error:', this.functions.getErrorMessage(err));
    }

    // Add native (virtual) keyboard listeners
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', () => {
        if (!this.virtualKeyboardVisible) {
          this.virtualKeyboardVisible = true;
          this.setVirtualKeyboardOpenStyling(true);
          this.changeDetectorRef.detectChanges();
        }
      });

      Keyboard.addListener('keyboardDidShow', () => {
        if (!this.virtualKeyboardVisible) {
          this.virtualKeyboardVisible = true;
          this.setVirtualKeyboardOpenStyling(true);
          this.changeDetectorRef.detectChanges();
        }
      });

      Keyboard.addListener('keyboardDidHide', () => {
        if (this.virtualKeyboardVisible) {
          this.virtualKeyboardVisible = false;
          this.setVirtualKeyboardOpenStyling(false);
          this.changeDetectorRef.detectChanges();
        }
      });
    }

    // DEEP-LINKING
    App.addListener('appUrlOpen', (e: URLOpenListenerEvent) => {
      this.zone.run(() => {
        this.deepLinkRouter.handleLinkOpened(e?.url).catch((err: any) => {
          console.warn('Failed to navigate to deep link: ', this.functions.getErrorMessage(err));
        });
      });
    });

    App.getLaunchUrl()
      .then((launchUrl: AppLaunchUrl) => {
        if (launchUrl && launchUrl.url?.length) {
          this.zone.run(() => {
            this.deepLinkRouter.handleLinkOpened(launchUrl.url).catch((err: any) => {
              console.warn('Failed to navigate to deep link: ', this.functions.getErrorMessage(err));
            });
          });
        }
      })
      .catch((err: any) => {
        console.warn('Failed to get deep link launch url: ', this.functions.getErrorMessage(err));
      });

    // Seny - asking for 3rd party cookie permissions does not solve the iOS App problem of blocking analytics!
    // if (this.isIOS) {
    //   try {
    //     // Get 3rd party cookie tracking permission status - ask for permissions if unset
    //     AppTrackingTransparency.getTrackingStatus().then((trackingStatus: IOSAppTrackingResponse) => {
    //       console.log('trackingStatus: ', trackingStatus.code, trackingStatus.status);
    //       if (trackingStatus.status === 'unrequested') {
    //         AppTrackingTransparency.requestPermission().then((permission: IOSAppTrackingResponse) => {
    //           console.log('Third party cookie permissions set to: ', permission.status);
    //           console.log('permissionsUpdated: ', permission.code, permission.status);
    //         });
    //       }
    //     });
    //   } catch (err: any) {
    //     console.log('Error getting or setting tracking status: ', this.functions.getErrorMessage(err));
    //   }
    // }
  }

  updateAppWithBrand(config: WhiteLabelUIConfig): void {
    this.zone.run(() => {
      document.body.style.setProperty(
        '--brand-fg',
        config?.header?.colours?.logoBgComplement || Constants.DEFAULT_THEME.logoBgComplement
      );
      document.body.style.setProperty('--brand-bg', config?.header?.colours?.logoBg || Constants.DEFAULT_THEME.logoBg);
      // this.changeDetectorRef.detectChanges();
    });
  }

  updatePatientDetails(patient: Patient): void {
    this.patient = patient || null;
    this.patientId = patient?.patientId || null;
    this.patientEmail = patient?.email || '';
    this.patientName = patient?.preferredName
      ? patient.preferredName
      : patient?.firstName || patient?.lastName
        ? ''.concat((patient?.firstName || '') + ' ' + (patient?.lastName || '')).trim()
        : '';
  }

  /**
   * @function setupViewportMetaTagMutationObserver
   * @description Sets up a MutationObserver to watch for when a change is made to the viewport meta tag,
   * if the mutation is to the content attribute, and the new content doesn't contain viewport-fit=cover,
   * we change the content back so that it does include viewport-fit=cover.
   *
   * This is only necessary because the LiveChat widget changes the content of this meta tag.
   */
  setupViewportMetaTagMutationObserver(): void {
    const viewportMetaTag = document.head.querySelectorAll('meta[name=viewport]')[0] as HTMLMetaElement;

    const metaTagObserver = new MutationObserver((mutations: MutationRecord[]) => {
      const hasContentMutation: boolean = mutations.some(
        (mutation: MutationRecord) => mutation.attributeName === 'content'
      );

      if (!hasContentMutation) {
        return;
      }

      if (viewportMetaTag.content.indexOf('viewport-fit=cover') === -1) {
        viewportMetaTag.content =
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover';
      }
    });

    metaTagObserver.observe(viewportMetaTag, { attributes: true });
  }

  updateAnalyticsCustomDimensions(): void {
    this.analytics.customDimensions = this.customDimensionsService.getCustomDomensionsForTagManager();
  }

  setVirtualKeyboardOpenStyling(keyboardOpen: boolean = true): void {
    try {
      const bodyElement: HTMLElement = document.body;

      if (!keyboardOpen) {
        bodyElement.classList.remove('virtualKeyboardOpen');
      } else if (!bodyElement.classList.contains('virtualKeyboardOpen')) {
        bodyElement.classList.add('virtualKeyboardOpen');
      }
    } catch (err: any) {}
  }

  /**
   * @function setBrowserTitle
   * @description Set the title in the browser tab based on current position in the route hierarchy
   *
   * @returns {string} return the title
   */
  setBrowserTitle(): string {
    const defaultTitle: string = Constants.DefaultPageTitle;
    const titleArray: string[] = this.getTitle(this.router?.routerState, this.router?.routerState?.root);

    let title: string = defaultTitle;

    if (Array.isArray(titleArray) && titleArray.length) {
      title = titleArray.filter((str: string, index: number, arr: string[]) => arr.indexOf(str) === index).join(' - ');
      title = title.concat(' - ', defaultTitle);
    }

    // title = this.translateService.instant(title.concat(' - ', defaultTitle));
    this.titleService.setTitle(title);

    return title;
  }

  /**
   * @function getTitle
   * @description Traverse the active route hierarchy to create a list of titles from top down
   *
   * @param {RouterState|any} state
   * @param {ActivatedRoute|any} parent
   *
   * @returns {string[]} Array of titles from the route hierarchy (parent -> child -> child)
   */
  getTitle(state: any, parent: any): string[] {
    const data: string[] = [];

    if (parent && parent.snapshot?.data && parent.snapshot.data.title) {
      data.unshift(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.unshift(...this.getTitle(state, state.firstChild(parent)));
    }

    return data;
  }

  async processStoredCredentials(): Promise<void> {
    const userEmail: string = this.credentialsService.retrieveStoredCredentials();

    if (userEmail) {
      const isAuthenticatedAndPatientProfileLoaded: boolean = this.patientService.isAuthenticatedPatient();

      // If the user is authenticated and their patient has been set in the patient service, don't set it again
      // otherwise, if the user is authenticated and their patient has not been set in the patient service, set it.
      if (!isAuthenticatedAndPatientProfileLoaded && this.patientService.getStoredPatientId()) {
        // This will trigger the patient change observer
        await this.patientService.setDefaultPatient();
      }
    } else {
      console.info('No user credentials found. Proceeding unauthenticated.');
    }
  }

  /**
   * @function handleLiveChatNewEvent
   * @description An event is fired when the agent or user interacts with the chat widget.
   * eg. agent greeting or user message
   *
   * @param {EventHandlerPayload<onNewEvent>} event
   */
  handleLiveChatNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
    if (event.author.type === 'customer') {
      if (!this.userInteractedWithChatWidget) {
        this.analytics.startLiveChat();
      }
      this.userInteractedWithChatWidget = true;
    }
  }

  /**
   * @function handleLiveChatVisibility
   * @description An event is fired when user interacts with the chat widget's 'visibility', eg. minimise
   *
   * Notes:
   * 1. After adding *ngIf="liveChatWidgetOpen" to the <livechat-widget> component, this function no
   * longer gets triggered! However, the issue with container height was resolved by this change, so
   * manually updating the height is no longer required.
   * 2. With the upgrade to chat widget v1.2, this method fires on visibility change. We need to manually
   * set the liveChatVisibility param based on feedback from the widget.
   *
   * @param {EventHandlerPayload<onVisibilityChanged>} event
   */
  handleLiveChatVisibility(event: EventHandlerPayload<'onVisibilityChanged'>) {
    switch (event.visibility) {
      case liveChatVisibilityStatus.open:
        this.liveChatVisibility = liveChatVisibilityStatus.open;
        break;
      case liveChatVisibilityStatus.showMin:
        this.liveChatVisibility = liveChatVisibilityStatus.showMin;
        break;
      case liveChatVisibilityStatus.closed:
        this.liveChatService.closeLiveChat();
        break;
    }
  }

  get prevRouteUrl(): string {
    return this._prevRouteUrl || null;
  }
  set prevRouteUrl(url: string) {
    this._prevRouteUrl = url;
  }
}
