<div class="container-fluid">
  <form [formGroup]="formGroup">
    <div class="row content-section mt-4">
      <div class="col-12">
        <mat-card appearance="outlined" class="card-wrapper">
          <mat-card-content class="content">
            <div class="form-content">
              <div class="row content-section">
                <div class="col-md-6 content-box">
                  <mat-form-field appearance="outline" class="default-input w-100">
                    <mat-label>Membership / Policy Number</mat-label>
                    <input
                      formControlName="policyNumber"
                      autocomplete="policyNumber"
                      matInput
                      maxlength="255"
                      placeholder="Enter their membership or policy number to redeem the benefit"
                      required
                    />
                    <mat-error *ngIf="policyNumber.invalid">
                      <span *ngIf="policyNumber.hasError('required')"
                        >Membership / Policy Number is required.&nbsp;</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <p class="error-message mb-3" *ngIf="invalidPolicy">
                  The membership or policy number is incorrect or invalid. Please try again or contact an administrator.
                </p>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4 col-md-3 mb-2 button-wrapper">
                  <button
                    (click)="patientDoesntKnowPolicy()"
                    [disabled]="isSubmitting"
                    class="save-btn"
                    mat-stroked-button
                    color="accent"
                  >
                    <span *ngIf="!isSubmitting">Patient doesn't know Policy Number</span>
                    <span *ngIf="isSubmitting">Patient doesn't know Policy Number...</span>
                  </button>
                </div>
                <div class="col-12 col-sm-3 col-md-2 mb-2 button-wrapper">
                  <button
                    (click)="completePolicyNumberInput()"
                    [disabled]="isSubmitting"
                    class="save-btn"
                    mat-flat-button
                    color="accent"
                  >
                    <span *ngIf="!isSubmitting">Next</span>
                    <span *ngIf="isSubmitting">Next...</span>
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>
