import { throwError as observableThrowError, Observable, BehaviorSubject, Subject } from 'rxjs';
import { take, filter, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { CredentialsService } from './credentials.service';
import { environment } from '@env/environment';
import { Functions } from '@app/shared/functions';
import { Constants, PREVENT_EXPIRED_TOKEN_LOGOUT } from '@app/shared/constants';
import { Logger } from './logger.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { AgencyService } from '@app/shared/services/agency.service';
import { B2BCustomerService } from '@src/app/shared/services/b2b-customer.service';

const log = new Logger('HTTPInterceptor');

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string | true | null> = new BehaviorSubject<string | true | null>(null);
  errorTracker: string[] = [];

  private _authenticationFailed: Subject<any> = new Subject<any>();
  public authenticationFailedObs = this._authenticationFailed.asObservable();

  constructor(
    private functions: Functions,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService,
    private analytics: GoogleAnalyticsService,
    private agencyService: AgencyService,
    private b2bCustomerService: B2BCustomerService
  ) {}

  intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    if (this.credentialsService.credentials && this.credentialsService.credentials.accessToken) {
      request = this.addToken(request, this.credentialsService.credentials.accessToken);
    }

    if (!this.credentialsService.useAuthHeader()) {
      request = request.clone({
        withCredentials: true
      });
    }

    if (this.b2bCustomerService.resourceAccessToken) {
      request = request.clone({
        setHeaders: { 'X-Dod-B2BResourceAccessToken': this.b2bCustomerService.resourceAccessToken }
      });
    }

    // Do not add an X-Dod-Agency header for retrieving Wordpress content such as faqs/user_guides
    if (request.url.indexOf('/wp-json/') === -1) {
      request = request.clone({
        setHeaders: { 'X-Dod-Agency': this.agencyService.retrieveAgencyCode() }
      });
    }

    // When retrieving assets we need to ensure that the virtual folder is included in the request
    if (request.url?.startsWith('/assets/') /* && environment.production */) {
      request = request.clone({
        url: environment.baseUrl + request.url.substring(1)
      });
    }

    // if (request.url.indexOf('/static/') !== -1) {
    //   console.log('Request contains /static/ folder. all headers: ', request.headers);
    // }

    if (request.headers?.has('Cache-Control')) {
      const cacheControl: string = request.headers.get('Cache-Control');
      // console.log('Request contains Cache-Control header:', cacheControl);

      if (cacheControl.indexOf('no-cache') !== -1) {
        request.headers.delete('Cache-Control');
      }
    }

    if (request.url.indexOf(environment.apiBaseUrl) === 0) {
      request = request.clone({
        setHeaders: {
          'X-Dod-CSRF-Protection': '1'
        }
      });
    }

    return httpHandler.handle(request).pipe(
      catchError((error: any) => {
        // error instancesof HttpErrorResponse
        if (error?.status === 401) {
          // Authentication has failed, record failed attempt
          this._authenticationFailed.next(null);
          return this.handle401Error(request, httpHandler);
        } else {
          const url: string = error?.url || null;

          const errorLabel: string = this.functions.getErrorMessage(error) + (url ? ' | ' + url : '');

          if (!this.errorTracker) {
            this.errorTracker = [];
          }

          // Do not fire the same event over and over (in case of disconnection)
          if (this.errorTracker.indexOf(errorLabel) === -1) {
            this.errorTracker.push(errorLabel);
            this.analytics.recordHTTPError(errorLabel);
          }

          // After recording 6 errors, reset error tracker array to the last recorded error.
          if (this.errorTracker.length > 6) {
            this.errorTracker = [this.errorTracker[this.errorTracker.length - 1]];
          }

          return observableThrowError(() => error);
        }
      })
    );
  }

  /**
   * @deprecated Security credentials are now stored in HTTP-only cookies
   */
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      // tslint:disable-next-line: object-literal-key-quotes
      setHeaders: { Authorization: `Bearer ${token}` }
    });
  }

  /**
   * @function handle401Error
   * @description Handle Unauthorized (401) error from API
   *
   * @param {HttpRequest<any>} request original request that threw the 401 error
   * @param {HttpHandler} httpHandler transform an HTTP request into a stream of HTTP Events
   *
   * @returns {Observable<HttpEvent<any>>} Observable of an HTTP Event
   */
  private handle401Error(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshingToken) {
      this.isRefreshingToken = false;
      // If we're in the middle of refreshing an authentication token, get the most recently
      // saved token and complete/handle the original HTTP request
      return this.tokenSubject.pipe(
        filter((token: string) => token !== null),
        take(1),
        switchMap((jwt: string | boolean) => {
          if (typeof jwt === 'string') {
            return httpHandler.handle(this.addToken(request, jwt));
          } else {
            return httpHandler.handle(request);
          }
        })
      );
    } else {
      this.tokenSubject.next(null);

      // Check credentials in memory, if missing, retrieve credentials from storage
      if (!this.credentialsService.credentials) {
        this.credentialsService.retrieveStoredCredentials();
      }

      if (
        this.credentialsService.credentials &&
        (this.credentialsService.useAuthHeader()
          ? this.credentialsService.credentials.refreshToken
          : this.credentialsService.checkIfAuthenticatedInLocalStorage()) &&
        this.authService.getAccountHolderEmailAddress()
      ) {
        this.isRefreshingToken = true;

        return this.authService.refreshToken(this.credentialsService.credentials.refreshToken).pipe(
          switchMap((token: string | boolean | null) => {
            this.isRefreshingToken = false;
            // Check response is a valid token
            if (token && token !== Constants.API_ERROR_CODES.INVALID_TOKEN) {
              if (typeof token === 'string') {
                this.tokenSubject.next(token);
                return httpHandler.handle(this.addToken(request, token));
              } else {
                this.tokenSubject.next(true);
                return httpHandler.handle(request);
              }
            } else {
              // Reset user credentials
              this.analytics.userId = null;
              this.credentialsService.removeCredentials();

              if (!request.context || request.context.get(PREVENT_EXPIRED_TOKEN_LOGOUT) !== true) {
                this.functions.navigateToLogin(false); // login page with redirect params back to current page
              }

              // Forward the authentication error message down the chain of handlers
              return observableThrowError(() => new Error('Authentication failed - your refreshToken has expired!'));
            }
          })
        );
      } else {
        // log.warn('[handle401Error] Email or refresh token missing. Aborting token refresh!');

        // Reset user credentials
        this.analytics.userId = null;
        this.credentialsService.removeCredentials();

        if (!request.context || request.context.get(PREVENT_EXPIRED_TOKEN_LOGOUT) !== true) {
          this.analytics.recordUnauthorisedAccess(request.urlWithParams || request.url);
          this.functions.navigateToLogin(false); // login page with redirect params back to current page
        }

        // Forward the authentication error message down the chain of handlers
        return observableThrowError(() => new Error('User credentials missing or expired. Login is required!'));
      }
    }
  }
}
