import { AfterViewInit, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Practitioner } from '@app/shared/models/practitioner';
import { AppointmentService } from '@app/shared/services/appointment.service';
import { PractitionerService } from '@app/shared/services/practitioner.service';
import { Appointment } from '@app/shared/models/appointment';
import { ModalsService } from '@app/shared/services/modals.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Functions } from '@app/shared/functions';
import { TimezoneService } from '../../services/timezone.service';
import { PatientService } from '@app/shared/services/patient.service';
import { Constants } from '@app/shared/constants';
import { AppointmentHours } from '@app/shared/models/appointment-hours';
import { AvailabilitySearch } from '@app/shared/models/availabilitySearch';
import { Language } from '@app/shared/models/language';
import { PractitionerGender } from '@app/shared/models/practitionerGender';
import { AvailabilityService } from '@app/shared/services/availability.service';
import moment from 'moment';
import { CreateAvailabilityLockRequestModel } from '../../models/availabilities/lock/Create/CreateAvailabilityLockRequestModel';
import { CreateAvailabilityLockResponseModel } from '../../models/availabilities/lock/Create/CreateAvailabilityLockResponseModel';

@Component({
  selector: 'appointment-select-practitioner',
  templateUrl: './appointment-select-practitioner.component.html',
  styleUrls: ['./appointment-select-practitioner.component.scss']
})
export class AppointmentSelectPractitionerComponent implements OnInit, AfterViewInit, OnDestroy {
  private _originalPractitionerId: string = null;
  private _finalPractitionerId: string = null;
  private subscription = new Subscription();

  appointment: Appointment;
  practitionerList: Practitioner[];
  selectedPractitioner: Practitioner = null;
  onDemandQueueSize: number = 0;
  isFilteredData: boolean = false;
  timeZoneOffset: string;
  appointmentDayDate: string;
  appointmentDayMonth: string;
  appointmentDayWeekday: string;
  appointmentDayTime: string;
  availability: AvailabilitySearch;
  appointmentTimeZoneLabel: string;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<AppointmentSelectPractitionerComponent>,
    private appointmentService: AppointmentService,
    private availabilityService: AvailabilityService,
    private practitionerService: PractitionerService,
    private modalService: ModalsService,
    private analytics: GoogleAnalyticsService,
    private timezoneService: TimezoneService,
    private patientService: PatientService,
    private functions: Functions
  ) {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    this.appointment = this.appointmentService.appointment || null;
    this.availability = this.appointmentService.availabilityParameter
      ? { ...this.appointmentService.availabilityParameter }
      : null;

    this._init(true);
  }

  ngAfterViewInit(): void {
    // APPOINTMENT
    this.subscription.add(
      this.appointmentService.sessionChangeObs.subscribe((appointment: Appointment) => {
        this.appointment = appointment;
        // this._init(false);
      })
    );

    // PRACTITIONER LIST
    this.subscription.add(
      this.practitionerService.practitionerListChangeObs.subscribe((practitioners: Practitioner[]) => {
        this.practitionerList = practitioners || [];
      })
    );

    // PRACTITIONER
    this.subscription.add(
      this.practitionerService.practitionerChangeObs.subscribe((practitioner: Practitioner) => {
        this.selectedPractitioner = practitioner || null;
      })
    );

    // ON-DEMAND AVAILABILITY
    this.subscription.add(
      this.availabilityService.isPractitionerOnlineChangeObs.subscribe(() => {
        this._init(true);
      })
    );

    // AVAILABILITY PARAMS
    this.subscription.add(
      this.appointmentService.availabilityParamsChangeObs.subscribe((availability: AvailabilitySearch) => {
        this.availability = availability;

        // if (this.functions.deepCompare(this.availability, availability)) {
        //   this._init(false);
        // } else {
        //   this.availability = availability;
        //   this._init(true);
        // }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async _init(availabilityParamsUpdated: boolean = false): Promise<void> {
    // Make sure availability parameter is initialised
    if (!this.availability) {
      this.initAvailabilityParams();
    }

    this.isFilteredData = Boolean(
      (this.availability?.sex && this.availability.sex != PractitionerGender.All) ||
        (this.availability?.languageId && this.availability?.languageId !== 'All') ||
        this.availability?.practitionerIdsToLimitBy ||
        this.availability?.shouldLimitByAgencyCode
    );

    if (
      this.appointment?.practitionerId &&
      this.selectedPractitioner?.practitionerId !== this.appointment.practitionerId
    ) {
      this.selectedPractitioner = {
        practitionerId: this.appointment?.practitionerId || null,
        practitionerName: this.appointment?.practitionerName || null,
        profileImageUrl: this.appointment?.practitionerPic || null
      };

      // Keep track of which practitioner was originally selected
      this._originalPractitionerId = this.selectedPractitioner.practitionerId;
    } else {
      this._originalPractitionerId = null;
    }

    const timezoneId: string =
      this.appointment?.patientTimezoneOffsetId ||
      this.availability?.timezoneId ||
      this.patientService.getPatientTimeZoneId();
    const timezoneOffset: string =
      this.appointment?.patientTimezoneOffsetHours ||
      this.availability?.timezoneOffSetHours ||
      this.patientService.getPatientTimeZoneOffset(this.appointment?.startTimeUTC || this.appointment?.start);

    let appointmentStartEndDatesLocal: [string, string] = this.appointmentService.getLocalDatesFromAppointment(
      this.appointment,
      timezoneOffset
    );

    const startDatePatientLocal: string = appointmentStartEndDatesLocal[0];
    const endDatePatientLocal: string = appointmentStartEndDatesLocal[1];

    if (
      this.availability?.startDatePatientLocal !== startDatePatientLocal ||
      this.availability?.endDatePatientLocal !== endDatePatientLocal
    ) {
      this.appointmentService.setAvailabilityParameter({
        ...this.availability,
        startDatePatientLocal,
        endDatePatientLocal
      });
    }

    this.updateTimeZoneLabel();

    if (availabilityParamsUpdated) {
      this.availability = {
        ...this.availability,
        appointmentBookingType: this.appointment?.isOnDemand
          ? Constants.APPOINTMENT_BOOKING_TYPE.ON_DEMAND
          : Constants.APPOINTMENT_BOOKING_TYPE.SCHEDULED,
        billingType: this.availability.billingType || Constants.APPOINTMENT_BILLING_TYPE.ALL,
        serviceTypes: this.availability.serviceTypes || Constants.DEFAULT_SERVICE_TYPES,
        timezoneId,
        timezoneOffSetHours: timezoneOffset,
        startDatePatientLocal,
        endDatePatientLocal,
        practitionerIdsToLimitBy: null,
        shouldGetOnDemandQueueSizes: Boolean(this.appointment?.isOnDemand),
        sex: this.availability.sex || PractitionerGender.All,
        hours: this.availability.hours || AppointmentHours.All
      } as AvailabilitySearch;
    }

    // console.log('[SELECT-PRACTITIONER] this.practitionerList = await this.practitionerService.getAvailabilityPractitioners()');
    this.practitionerList = await this.practitionerService.getAvailabilityPractitioners(
      this.functions.removeEmpty(this.availability),
      true, // store practitioner list in sessionStorage
      true // set practitioner list
    );

    if (this.practitionerList?.length) {
      if (this.availability?.sex != PractitionerGender.All || this.availability?.languageId) {
        this.practitionerList = this.practitionerList.filter((practitioner: Practitioner) => {
          const genderMatch: boolean =
            !this.availability?.sex ||
            !practitioner.sex ||
            this.availability?.sex == PractitionerGender.All ||
            this.availability?.sex === practitioner.sex;

          let languageMatch: boolean = true;
          if (this.availability?.languageId && this.availability.languageId !== 'All') {
            languageMatch = Boolean(
              practitioner.languagesSpoken?.findIndex(
                (lang: Language) => lang.languageId === this.availability.languageId
              ) !== -1
            );
          }

          return genderMatch && languageMatch;
        });
      }

      // if (this.appointment?.isOnDemand) {
      // Calculate on-demand queue size based on all the practitioners in the filtered practitioner list
      let onDemandQueue: number = Number.MAX_SAFE_INTEGER;

      for (let practitioner of this.practitionerList) {
        if (
          practitioner.practitionerId !== Constants.BlankGUID &&
          typeof practitioner.onDemandQueueSize === 'number' &&
          (typeof practitioner.checked === 'undefined' || practitioner.checked) &&
          practitioner.onDemandQueueSize < onDemandQueue
        ) {
          onDemandQueue = practitioner.onDemandQueueSize;
        }
      }

      if (onDemandQueue === Number.MAX_SAFE_INTEGER) {
        onDemandQueue = null;
      }

      this.onDemandQueueSize = onDemandQueue;
    }

    if (this.appointment) {
      this.timeZoneOffset = this.getTimeZoneOffset();

      const numericTimezoneOffset: number = this.timeZoneOffset
        ? parseFloat(this.timeZoneOffset)
        : Constants.Default_TimeZone_Offset;

      const appointmentStartTime: string = this.appointment.startTimeUTC || this.appointment.start;

      const displayDate: moment.Moment = this.functions.getUTCMoment(
        this.timeZoneOffset,
        false,
        false,
        appointmentStartTime
      );

      this.appointmentDayDate = displayDate.clone().utcOffset(numericTimezoneOffset).format('DD');
      this.appointmentDayMonth = displayDate.clone().utcOffset(numericTimezoneOffset).format('MMM').toUpperCase();
      this.appointmentDayWeekday = displayDate.clone().utcOffset(numericTimezoneOffset).format('dddd');
      this.appointmentDayTime = this.timezoneService.getFormattedSessionDisplayTime(
        appointmentStartTime,
        this.timeZoneOffset
      );
    }
  }

  initAvailabilityParams(): void {
    this.appointment = this.appointmentService.appointment;

    const availabilityParam: AvailabilitySearch = this.appointmentService.createAvailabilityParameter(
      this.appointment?.serviceType,
      false, // on demand
      this.patientService.benefit?.policyId, // policy
      AppointmentHours.All,
      null, // limit practitioners
      this.appointment?.patientTimezoneOffsetId ?? this.patientService.getPatientTimeZoneId(),
      this.appointment?.patientTimezoneOffsetHours ?? this.patientService.getPatientTimeZoneOffset(),
      true // all booking types
    );

    // Triggers observer
    this.appointmentService.setAvailabilityParameter(availabilityParam, true);
  }

  updateTimeZoneLabel(): void {
    this.appointmentTimeZoneLabel = this.functions.removeCountryFromTimeZone(this.getTimeZoneOffsetLabel());
  }

  getTimeZoneOffset(): string {
    return (
      this.appointment?.patientTimezoneOffsetHours ||
      this.availability?.timezoneOffSetHours ||
      this.patientService.getPatientTimeZoneOffset(this.appointment?.startTimeUTC || this.appointment?.start)
    );
  }

  getTimeZoneOffsetLabel(): string {
    if (this.appointment?.patientTimezoneLabel) {
      return this.appointment.patientTimezoneLabel;
    } else if (this.appointment.patientTimezoneOffsetId) {
      return this.timezoneService.getTimezoneLabelFromTimezoneIdAndUtcOffsetHours(
        this.appointment.patientTimezoneOffsetId,
        this.getTimeZoneOffset()
      );
    }

    return this.patientService.getPatientTimeZoneLabel(this.appointment?.startTimeUTC || this.appointment?.start);
  }

  async selectPractitioner(index: number): Promise<void> {
    if (this.practitionerList?.length > index) {
      this.selectedPractitioner = this.practitionerList[index];
    }

    await this.onClose();
  }

  async selectFirstAvailable(): Promise<void> {
    this.selectedPractitioner = null;
    this._finalPractitionerId = null;
    await this.onClose();
  }

  /**
   * @function resetFilters
   * @description Patient clicked on the reset filters link - set availability params to their defaults
   * and re-initialise the component's data
   *
   * @param {Event} $event click event
   */
  resetFilters($event: any): void {
    if ($event) {
      $event.stopImmediatePropagation();
    }

    this.appointmentService.setAvailabilityParameter({
      ...this.appointmentService.availabilityParameter,
      sex: PractitionerGender.All,
      hours: AppointmentHours.All,
      billingType: Constants.APPOINTMENT_BILLING_TYPE.ALL,
      appointmentBookingType: Constants.APPOINTMENT_BOOKING_TYPE.ALL,
      practitionerIdsToLimitBy: null,
      // shouldLimitByAgencyCode: false,
      languageId: null
    });

    this._init(true);
  }

  async onClose(): Promise<void> {
    this._finalPractitionerId = this.selectedPractitioner?.practitionerId ?? null;

    // The practitioner has not been changed, so simply close the modal
    if (this._finalPractitionerId == this._originalPractitionerId) {
      this.dialogRef.close(this._finalPractitionerId);
      return;
    }

    if (this._finalPractitionerId) {
      const previousAvailabilityLockId = sessionStorage.getItem(Constants.LocalStorage_Key.availabilityLockId);
      const previousAvailabilityLockRAT = sessionStorage.getItem(
        Constants.LocalStorage_Key.availabilityLockResourceAccessToken
      );

      const sessionTimeEndUTC = moment
        .utc(this.appointment.startTimeUTC)
        .add(this.appointment.duration, 'minutes')
        .toISOString();

      if (!this.appointment.isOnDemand) {
        // Attempt to acquire an availability lock for the new practitioner for the appointment duration.
        // If a lock can not be acquired, show an error message and return.

        let availabilityConstraints = await this.appointmentService.getAppointmentPricingSearchDTO(
          this.appointment,
          this.appointmentService.availabilityParameter
        );
        availabilityConstraints.practitionerId = this._finalPractitionerId;

        const createAvailabilityLockRequestModel: CreateAvailabilityLockRequestModel = {
          startUTC: this.appointment.startTimeUTC,
          endUTC: sessionTimeEndUTC,
          // If the practitionerId is null the BE will attempt to acquire a lock for the best weighted practitioner for the given time and constraints
          practitionerId: this._finalPractitionerId,
          availabilityConstraints: availabilityConstraints
        };

        const createAvailabilityLockResponseModel: CreateAvailabilityLockResponseModel = await this.availabilityService
          .acquireAvailabilityLock(createAvailabilityLockRequestModel)
          .catch((err: any) => {
            //TODO: consider whether we need to have special cases for different errors or whether
            // we can just show a generic error message
            return null;
          });

        if (!createAvailabilityLockResponseModel?.wasLockCreatedSuccessfully) {
          this.functions.showToast('The selected time was taken by another user, please select another time.');
          return;
        }

        // Release the lock we held for the previous practitioner
        this.availabilityService.releaseAvailabilityLockViaBeacon(
          previousAvailabilityLockId,
          previousAvailabilityLockRAT
        );

        // Set the acquired availabilityLock with the newly acquired lock data as the previous release call would
        // have cleared the existing renew intervals and local storage settings
        this.availabilityService.setAcquiredAvailabilityLock(
          createAvailabilityLockRequestModel,
          createAvailabilityLockResponseModel
        );
      }

      this.appointment = this.appointmentService.setAppointment({
        ...this.appointment,
        practitionerId: this._finalPractitionerId, // this.selectedPractitioner.practitionerId,
        practitionerName: this.selectedPractitioner?.practitionerName || null,
        practitionerPic: this.selectedPractitioner?.profileImageUrl || null,
        isPractitionerMBSCapable: Boolean(
          this.selectedPractitioner.isMBSCapable /* || this.selectedPractitioner.isMbsCapable*/
        )
      });

      const prac: Practitioner = this.practitionerService.setPractitionerById(this._finalPractitionerId);

      if (prac) {
        this.appointmentService.setAvailabilityParameter({
          ...this.appointmentService.availabilityParameter,
          practitionerIdsToLimitBy: JSON.stringify([this._finalPractitionerId])
        });

        this.practitionerList.forEach((practitioner: Practitioner) => {
          practitioner.checked = practitioner.practitionerId === this._finalPractitionerId;
        });

        this.practitionerService.setPractitionerList(this.practitionerList);
      }
    } else {
      this.appointment = this.appointmentService.setAppointment({
        ...this.appointment,
        practitionerId: null,
        practitionerName: null,
        practitionerPic: null,
        isPractitionerMBSCapable: null
      });

      // Set selected practitioner to null (First available)
      this.practitionerService.setPractitionerById(null);

      this.appointmentService.setAvailabilityParameter({
        ...this.appointmentService.availabilityParameter,
        practitionerIdsToLimitBy: null
      });

      // Tick all the practitioners in the list
      this.practitionerList.forEach((practitioner: Practitioner) => {
        practitioner.checked = true;
      });

      this.practitionerService.setPractitionerList(this.practitionerList);
    }

    if (this._originalPractitionerId !== this._finalPractitionerId) {
      this.analytics.changeAppointmentPractitioner(this._originalPractitionerId + ' => ' + this._finalPractitionerId);
    }

    this.dialogRef.close(this._finalPractitionerId);
  }

  onShowProfile(practitionerId: string): void {
    if (practitionerId) {
      this.modalService.viewPractitionerProfile(practitionerId, null, (selectedPractitioner: Practitioner) => {
        if (selectedPractitioner) {
          this.selectedPractitioner = selectedPractitioner;
          this.onClose();
        }
      });
    }
  }

  getPractitionerChangeFriendlyLabel(): string {
    return this.appointment?.serviceType &&
      this.appointment.serviceType !== Constants.SERVICE_TYPE.WEIGHT_LOSS &&
      this.appointment.serviceType !== Constants.SERVICE_TYPE.MENTAL_HEALTH
      ? this.functions.getAppointmentType(this.appointment.serviceType)
      : 'practitioner';
  }
}
