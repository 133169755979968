import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { Constants } from '@app/shared/constants';
import { Functions } from '@app/shared/functions';
import { IResponseAPI } from '@app/shared/models/api-response';
import { AnalyticsCustomDimensionsService } from '@app/shared/services/custom-dimensions.service';
import { ErrorModalService } from '@app/shared/services/error-modal.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { PatientService } from '@app/shared/services/patient.service';
import { STEP_PATH } from '@app/shared/step-configuration';
import { PasswordStrengthMeterService } from '../password-strength-meter/password-strength-meter.service';
import { PasswordValidationResponse } from '../password-strength-meter/password-strength-meter.types';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  resetPasswordForm: FormGroup;
  resetPassword = {
    newPassword: '',
    newPasswordConfirm: ''
  };

  passwordScore: number = 0;
  passwordScoreString: string = '';
  passwordScoreStringClass: string = '';
  passwordPatternErrorMessage: string = Constants.Password_Strength_Configuration.patternErrorMessage;

  currentPassHide: boolean = true;
  passHide: boolean = true;
  confirmPassHide: boolean = true;
  isLoading: boolean = false;
  isConfirm: boolean = false;
  email: string;

  // Reset password encrypted and decrypted token
  token: string;
  parsedToken: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthenticationService,
    private functions: Functions,
    private errorModal: ErrorModalService,
    private activatedRoute: ActivatedRoute,
    private analytics: GoogleAnalyticsService,
    private customDimensions: AnalyticsCustomDimensionsService,
    private passwordStrengthMeterService: PasswordStrengthMeterService,
    private patientService: PatientService,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot?.queryParamMap?.get('token');

    if (this.token) {
      this.parsedToken = this.functions.parseJWT<any>(this.token);

      if (this.parsedToken === null) {
        this.invalidTokenMessage(false);
        return;
      } else {
        this.email = this.parsedToken?.body?.ActionData?.Email || '';
      }
    } else {
      this.invalidTokenMessage(true);
      return;
    }

    this.subscription.add(
      this.newPasswordConfirm.valueChanges.subscribe((value: string) => {
        // Is confirm password the same as the new password?
        if (this.newPassword.value !== value) {
          this.newPasswordConfirm.setErrors({ notEquivalent: true });
        } else if (!this.newPasswordConfirm.value) {
          this.newPasswordConfirm.setErrors({ required: true });
          // Does confirm password match password regular expression?
          // } else if (!Constants.passwordRegex.test(value)) {
          //   this.newPasswordConfirm.setErrors({ pattern: true });
        } else {
          this.newPasswordConfirm.setErrors(null);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  passwordValidationResultUpdated($event: any): void {
    const value: string = this.newPassword.value || '';
    this.passwordScore = $event?.score || 0;

    const customValidation: PasswordValidationResponse = this.passwordStrengthMeterService.validatePassword(value);

    if (value?.length && !customValidation?.isValid) {
      this.newPassword.setErrors({ pattern: true });
      // console.log('Password error codes:', customValidation?.errorCodes);
    } else if (!value?.length) {
      this.newPassword.setErrors({ required: true });
    } else if (this.passwordScore < 2) {
      // The password is too weak
      this.newPassword.setErrors({ tooWeak: true });
    } else {
      this.newPassword.setErrors(null);
    }

    this.checkConfirmPasswordMatchesPassword(value);
    this.changeDetectorRef.detectChanges();
  }

  checkConfirmPasswordMatchesPassword(value: string): void {
    if (this.newPasswordConfirm.value === value) {
      this.newPasswordConfirm.setErrors(null);
    } else {
      this.newPasswordConfirm.setErrors({ notEquivalent: true });
    }

    this.changeDetectorRef.detectChanges();
  }

  async onSubmit($event?: any): Promise<void> {
    // Pressing ENTER triggers the password visibility button, so force hide it
    if ($event && ($event.keyCode === 13 || $event.key === 'Enter')) {
      $event.preventDefault();
      this.confirmPassHide = !this.confirmPassHide ? false : true;
    }

    const newPassword: string = String(this.newPassword.value || '').trim();
    const newPasswordConfirm: string = String(this.newPasswordConfirm.value || '').trim();

    if (newPassword !== newPasswordConfirm) {
      this.showErrorMessage('New Password and Confirm Password do not match!');
    } else if (newPassword === newPasswordConfirm && this.resetPasswordForm.valid) {
      this.isLoading = true;

      try {
        const response: IResponseAPI = await this.authService.completePasswordReset(
          this.token,
          this.email,
          newPassword
        );

        if (response?.success) {
          if (response.response) {
            this.isConfirm = true;

            this.doLogin(this.email, newPassword);
          } else {
            this.errorModal.showErrorMessage('This password reset link is invalid or has expired.', {
              title: 'Password Reset Problem',
              isSpeakToDoctor: false,
              isClose: true
            });
          }
        } else {
          this.showErrorMessage(this.functions.getErrorMessage(response));
        }
      } catch (err: any) {
        this.showErrorMessage(this.functions.getErrorMessage(err));
      }

      this.isLoading = false;
    }
  }

  doLogin(email: string, password: string): void {
    if (!email?.length || !password?.length) {
      this.showErrorMessage('Missing email or password.');
      return;
    }

    this.isLoading = true;

    this.authService.login({ email, password }).subscribe(
      (result: string) => {
        this.isLoading = false;

        // 'result' will come back as a string when there is a login error. Display custom message instead.
        if (result) {
          this.showErrorMessage('Email or password is incorrect.');
        } else {
          // Remove account data associated with previous login from memory and storage
          this.patientService.clearPatientListByEmail();
          this.patientService.removePatientData();
          this.patientService.resetFailedLoginCounter();

          this.onLoginCompleted();
        }
      },
      (err: any) => {
        this.showErrorMessage(this.functions.getErrorMessage(err));
        this.isLoading = false;
      }
    );
  }

  async onLoginCompleted(): Promise<void> {
    await this.patientService.setDefaultPatient();

    this.customDimensions.patientLoggedIn();
    this.analytics.patientLoggedIn();

    if (this.activatedRoute.snapshot.queryParamMap?.keys?.length) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        const redirectUrl: string = params['redirect'];
        if (
          redirectUrl &&
          !redirectUrl.startsWith(environment.loginUrl) &&
          !redirectUrl.startsWith('/' + STEP_PATH.SIGN_UP)
        ) {
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate([STEP_PATH.DASHBOARD]);
        }
      });
    } else {
      this.router.navigate([STEP_PATH.DASHBOARD]);
    }
  }

  invalidTokenMessage(missing: boolean = true): void {
    this.onClose();
    this.showErrorMessage(
      'Password reset token is ' +
        (missing ? 'missing' : 'invalid') +
        '! Please make sure to use the entire link URL provided in your Reset Password email.'
    );
  }

  showErrorMessage(message: string): void {
    this.errorModal.showErrorMessage(message, {
      title: 'Change Password Problem',
      isSpeakToDoctor: false,
      isClose: true
    });
  }

  createForm(): void {
    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl(this.resetPassword.newPassword, [
        Validators.required,
        Validators.pattern(Constants.passwordRegexString)
      ]),
      newPasswordConfirm: new FormControl(this.resetPassword.newPasswordConfirm, [
        Validators.required,
        Validators.pattern(Constants.passwordRegexString)
      ])
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  toggleMyPasswordFieldType($event: any, isConfirmPassword: boolean = false): void {
    $event.preventDefault();

    if (isConfirmPassword) {
      this.confirmPassHide = !this.confirmPassHide;
    } else {
      this.passHide = !this.passHide;
    }
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }
  get newPasswordConfirm() {
    return this.resetPasswordForm.get('newPasswordConfirm');
  }
  get userInputs() {
    return ['Password', 'P@ssword1', 'Password1@', this.email || ''];
  }
}
