import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '@env/environment';
import { Constants } from "../constants";
import { Functions } from "../functions";
import { IResponseAPI } from "../models/api-response";
import { VersionInfo } from '../models/VersionInfo';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private readonly endpointPrefix: string = Constants.EndPoint_Prefix;
  private readonly url: string = `${environment.apiBaseUrl}${this.endpointPrefix}`;

  constructor(
    private functions: Functions,
    private http: HttpClient
  ) {}

  getCurrentVersion(): VersionInfo {
    let version: VersionInfo = {
      major: PWA_VERSION_MAJOR ?? 0,
      minor: PWA_VERSION_MINOR ?? 0,
      patch: PWA_VERSION_PATCH ?? 0
    };

    // Seny - if the above environment variables are not available, it means we did not build the
    // mobile app in a standard way. This is most likely to be a local test environment, so continue without error.
    if (version.major === 0 && version.minor === 0 && version.patch === 0) {
      version.major = 999;
      version.minor = 999;
      version.patch = 999;
    }

    return version;
  }

  getCurrentVersionString(version?: VersionInfo): string {
    version = version ?? this.getCurrentVersion();

    return `${version.major}.${version.minor}.${version.patch}`;
  }

  getPWAMinimumVersion(): Promise<any> {
    return this.http
      .get(`${this.url}/versioning/pwa/minimum`)
      .toPromise()
      .then((response: IResponseAPI) => {
        if (response?.success && response.response) {
          const version = response.response as VersionInfo;
          return version;
        }
        return null;
      })
      .catch((err) => this.functions.handleError(err));
  }

  checkNeedsUpdate(): Promise<boolean> {
    return this.getPWAMinimumVersion().then((minimumVersion: VersionInfo) => {
      if (!minimumVersion) {
        return false;
      }

      const currentVersion: VersionInfo = this.getCurrentVersion();

      const minimumVersionNumber: number =
        1000000 * minimumVersion.major + 1000 * minimumVersion.minor + minimumVersion.patch;

      const currentVersionNumber: number =
        1000000 * currentVersion.major + 1000 * currentVersion.minor + currentVersion.patch;

      const needsUpdate: boolean = minimumVersionNumber > currentVersionNumber;

      if (!environment.production) {
        console.log('[VersionService]', 'checkNeedsUpdate', {
          needsUpdate,
          currentVersion: this.getCurrentVersionString(currentVersion),
          minimumVersion: this.getCurrentVersionString(minimumVersion),
          reqVersionNum: minimumVersionNumber,
          currentVersionNumber
        });
      }

      return needsUpdate;
    });
  }
}
