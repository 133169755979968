export class PatientVerificationContent {
  emailCode: string;
  emailVerified: boolean;

  mobileCode: string;
  mobileVerified: boolean;

  constructor(init?: Partial<PatientVerificationContent>) {
    Object.assign(this, init);
  }
}
